import { Button, CSSGrid, NotificationHub } from "@nef/core";
import { FieldLoop, riskConfigMPIDs } from "components/fields";
import {
  Forms,
  PTR_HOLD_ACTION_OPTIONS,
  PTR_PER_TRADE_ACTION_OPTIONS,
} from "components/fields/fieldConstants";
import {
  getConvertedRiskLimitsForDB,
  getConvertedRiskLimitsForUI,
} from "components/limitMonitor/constants";
import { getFormValidation } from "components/standardTable";
import { useBellDispatch } from "components/topBar";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SettingsWrapper } from ".";
import { formatUrl } from "../../utils/js.utils";
import { AckTypes, FirmType } from "../../wksConstants";
import { ApiResponseNames, FieldNames, SelectOptions } from "../fields/fieldConstants";
import { useFormContext, useFormDispatch } from "../form/formContext";
import { useRefDataContext } from "../refData/context";
import { KeycloakRoles, useUserContext } from "../user";
import { LimitSettingForm } from "./limitSettingForm";
import { FooterFlex } from "./styled";

export const LimitMonitorSettings = () => {
  const [user] = useUserContext();
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [refData] = useRefDataContext();
  const [riskConfigDto, setRiskConfig] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [active, setActive] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const bellDispatch = useBellDispatch();

  const isReadOnly = useMemo(() => {
    if (user.entitlements[KeycloakRoles.PTR_RISK_CONFIG_RO]) {
      return true;
    } else {
      return false;
    }
  }, [user.entitlements]);

  useEffect(() => {
    const clearingFirmMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
    const correspondentMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
    if (!clearingFirmMPID || !correspondentMPID) {
      setActive(null);
    }
  }, [formData]);

  useEffect(() => {
    if (active === null) {
      formDispatch([
        {
          type: "RESET_FORM_VALIDATION",
          payload: { form: Forms.SETTINGS_LIMO },
        },
        {
          type: "ALLOW_SHOW_ERROR",
          payload: { form: Forms.SETTINGS_LIMO, allowShowError: true },
        },
      ]);
    }
  }, [active, formDispatch]);

  useEffect(() => {
    if (riskConfigDto) {
      const dataSources = [];
      if (Array.isArray(riskConfigDto[ApiResponseNames.dataSources])) {
        riskConfigDto[ApiResponseNames.dataSources].forEach(source => {
          const dataSourceOption = SelectOptions[FieldNames.dataSources].find(
            option => option.value === source
          );
          if (dataSourceOption) {
            dataSources.push(dataSourceOption);
          }
        });
      }
      const formValues = {
        [FieldNames.lastUpdated]: riskConfigDto[ApiResponseNames.lastUpdated],
        [FieldNames.clearingFirmMPID]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID],
        [FieldNames.correspondentMPID]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID],
        [FieldNames.isPostTradeRiskServiceActive]:
          riskConfigDto[ApiResponseNames.isRiskConfigActive],
        [FieldNames.dataSources]: dataSources,
        [FieldNames.holdDefaultAction]:
          PTR_HOLD_ACTION_OPTIONS[riskConfigDto[ApiResponseNames.buyDefaultAction]], // sellDefaultAction should be the same..?
        [FieldNames.useNetTrading]: riskConfigDto[ApiResponseNames.useNetTrading].toString(),
        [FieldNames.isAlertActive]: riskConfigDto[ApiResponseNames.isAlertActive],
        [FieldNames.buyAlertLimit]: riskConfigDto[ApiResponseNames.buyAlertLimit],
        [FieldNames.sellAlertLimit]: riskConfigDto[ApiResponseNames.sellAlertLimit],
        [FieldNames.isHoldActive]: riskConfigDto[ApiResponseNames.isHoldActive],
        [FieldNames.buyHoldLimit]: riskConfigDto[ApiResponseNames.buyHoldLimit],
        [FieldNames.sellHoldLimit]: riskConfigDto[ApiResponseNames.sellHoldLimit],
        [FieldNames.isRejectActive]: riskConfigDto[ApiResponseNames.isRejectActive],
        [FieldNames.buyRejectLimit]: riskConfigDto[ApiResponseNames.buyRejectLimit],
        [FieldNames.sellRejectLimit]: riskConfigDto[ApiResponseNames.sellRejectLimit],
        [FieldNames.netAlertLimit]: riskConfigDto[ApiResponseNames.netAlertLimit],
        [FieldNames.netHoldLimit]: riskConfigDto[ApiResponseNames.netHoldLimit],
        [FieldNames.netRejectLimit]: riskConfigDto[ApiResponseNames.netRejectLimit],
        [FieldNames.isPerTradeActive]: riskConfigDto[ApiResponseNames.isPerTradeActive],
        [FieldNames.perTradeBuyLimit]: riskConfigDto[ApiResponseNames.perTradeBuyLimit],
        [FieldNames.perTradeSellLimit]: riskConfigDto[ApiResponseNames.perTradeSellLimit],
        [FieldNames.perTradeBuyAction]:
          PTR_PER_TRADE_ACTION_OPTIONS[riskConfigDto[ApiResponseNames.perTradeBuyAction]], // perTradeSellAction should be the same..?
        [FieldNames.isMaxTradeActive]: riskConfigDto[ApiResponseNames.isMaxTradeActive],
        [FieldNames.maxTradeLimit]: riskConfigDto[ApiResponseNames.maxTradeLimit],
        [FieldNames.trfLimits]: riskConfigDto[ApiResponseNames.trfLimits],
      };

      const formActions = [
        {
          type: "SET_FORM_VALUES",
          payload: {
            form: Forms.SETTINGS_LIMO,
            fields: formValues,
          },
        },
        {
          type: "INIT_FORM_VALIDATION",
          payload: { form: Forms.SETTINGS_LIMO },
        },
      ];
      formDispatch(formActions);
    }
  }, [
    riskConfigDto,
    formDispatch /* the form data we need will be up-to-date when riskConfigDto changes */,
  ]);

  const getConfigCallback = useCallback(json => {
    const dto = getConvertedRiskLimitsForUI(json);
    setRiskConfig(dto);
    setLoading(false);
  }, []);

  const getConfigError = useCallback(() => {
    const clearingFirmMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
    const correspondentMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
    NotificationHub.send("danger", "Error retrieving risk configuration", {
      subtitle: `Clearer: ${clearingFirmMPID}, Correspondent: ${correspondentMPID}`,
    });
    formDispatch({
      type: "SET_FORM_VALUES",
      payload: {
        form: Forms.SETTINGS_LIMO,
        fields: {
          [FieldNames.correspondentMPID]: null,
        },
      },
    });
    setLoading(false);
  }, [formData, formDispatch]);

  useEffect(() => {
    const clearingFirmMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
    const correspondentMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
    if (clearingFirmMPID && correspondentMPID) {
      if (
        active?.clearingFirmMPID !== clearingFirmMPID ||
        active?.correspondentMPID !== correspondentMPID
      ) {
        setActive({ clearingFirmMPID, correspondentMPID });
        const headers = getHeaders();
        if (Array.isArray(refData?.firm)) {
          setLoading(true);
          const clearingFirm = refData.firm.find(
            firm => firm.firmType === FirmType.CLEARING && firm.firmMPID === clearingFirmMPID
          );
          const clearingFirmNum = clearingFirm?.clearingNum;

          if (abortController) {
            abortController.abort();
          }
          const controller = new AbortController();
          setAbortController(controller);

          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_LIMO_WS, "getrcamriskconfig"),
            {
              method: "post",
              headers,
              body: JSON.stringify({ clearingFirmNum, correspondentMPID }),
              signal: controller?.signal,
            },
            getConfigCallback,
            getConfigError
          );
        }
      }
    }
  }, [active, formData, refData, getConfigError, getConfigCallback, abortController]);

  const setConfigCallback = useCallback(
    json => {
      setDisabled(false);
      const clearingFirmMPID =
        formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
      const correspondentMPID =
        formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
      switch (json.ackType) {
        case AckTypes.SUCCESS: {
          bellDispatch({
            type: "REMOVE_CLEARING_RELATIONSHIP",
            payload: { clearingMPID: clearingFirmMPID, correspondentMPID },
          });
          NotificationHub.send("success", "Risk Configuration Saved Successfully", {
            subtitle: `Clearer: ${clearingFirmMPID}, Correspondent: ${correspondentMPID}`,
          });
          break;
        }
        case AckTypes.REJECT: {
          NotificationHub.send("danger", "Risk Configuration Save Rejected", {
            subtitle: `Clearer: ${clearingFirmMPID}, Correspondent: ${correspondentMPID}, Reject: ${json.rejectText}`,
          });
          break;
        }
        default: {
          NotificationHub.send("danger", "Risk Configuration Saving Error", {
            subtitle: `Clearer: ${clearingFirmMPID}, Correspondent: ${correspondentMPID}, Error: No acknowledgement`,
          });
          break;
        }
      }
    },
    [formData, bellDispatch]
  );

  const setConfigError = useCallback(() => {
    const clearingFirmMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
    const correspondentMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
    NotificationHub.send("danger", "Error saving Risk Configuration", {
      subtitle: `Clearer: ${clearingFirmMPID}, Correspondent: ${correspondentMPID}`,
    });
    setDisabled(false);
  }, [formData]);

  const handleSave = useCallback(() => {
    const valid = getFormValidation(Forms.SETTINGS_LIMO, formData, formDispatch);
    if (!valid) {
      return;
    }
    const clearingFirmMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.clearingFirmMPID]?.value;
    const correspondentMPID =
      formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.correspondentMPID]?.value;
    if (clearingFirmMPID && correspondentMPID) {
      setDisabled(true);
      const clearingFirm = refData.firm.find(
        firm => firm.firmType === FirmType.CLEARING && firm.firmMPID === clearingFirmMPID
      );
      const clearingFirmNum = clearingFirm?.clearingNum;

      let riskConfigDto = {
        clearingFirmMPID,
        clearingFirmNum,
        correspondentMPID,
        active: formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isPostTradeRiskServiceActive],
        [ApiResponseNames.dataSources]: formData[Forms.SETTINGS_LIMO.key].fields[
          FieldNames.dataSources
        ]?.map(option => option.value),
        [ApiResponseNames.buyDefaultAction]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.holdDefaultAction]?.value,
        [ApiResponseNames.sellDefaultAction]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.holdDefaultAction]?.value,
        [ApiResponseNames.useNetTrading]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.useNetTrading],
        [ApiResponseNames.isAlertActive]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isAlertActive],
        [ApiResponseNames.buyAlertLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.buyAlertLimit],
        [ApiResponseNames.sellAlertLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.sellAlertLimit],
        [ApiResponseNames.isHoldActive]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isHoldActive],
        [ApiResponseNames.buyHoldLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.buyHoldLimit],
        [ApiResponseNames.sellHoldLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.sellHoldLimit],
        [ApiResponseNames.isRejectActive]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isRejectActive],
        [ApiResponseNames.buyRejectLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.buyRejectLimit],
        [ApiResponseNames.sellRejectLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.sellRejectLimit],
        [ApiResponseNames.netAlertLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.netAlertLimit],
        [ApiResponseNames.netHoldLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.netHoldLimit],
        [ApiResponseNames.netRejectLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.netRejectLimit],
        [ApiResponseNames.isPerTradeActive]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isPerTradeActive],
        [ApiResponseNames.perTradeBuyLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.perTradeBuyLimit],
        [ApiResponseNames.perTradeSellLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.perTradeSellLimit],
        [ApiResponseNames.perTradeBuyAction]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.perTradeBuyAction]?.value,
        // derived from buy action
        [ApiResponseNames.perTradeSellAction]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.perTradeBuyAction]?.value,
        [ApiResponseNames.isMaxTradeActive]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.isMaxTradeActive],
        [ApiResponseNames.maxTradeLimit]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.maxTradeLimit],
        [ApiResponseNames.trfLimits]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.trfLimits],
        [ApiResponseNames.lastUpdated]:
          formData[Forms.SETTINGS_LIMO.key].fields[FieldNames.lastUpdated],
      };
      riskConfigDto = getConvertedRiskLimitsForDB(riskConfigDto);

      const headers = getHeaders();

      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_LIMO_WS, "setrcamriskconfig"),
        {
          method: "post",
          headers,
          body: JSON.stringify(riskConfigDto),
        },
        setConfigCallback,
        setConfigError
      );
    }
  }, [formData, formDispatch, refData.firm, setConfigCallback, setConfigError]);

  return (
    <>
      <SettingsWrapper
        header="Post - Trade Risk"
        subheader={
          <CSSGrid cols="1fr 1fr" gap="1rem">
            <FieldLoop
              style={{ marginRight: "0px !important" }}
              isReactFragment={true}
              form={Forms.SETTINGS_LIMO}
              fields={riskConfigMPIDs}
              isDisabled={isDisabled}
            />
          </CSSGrid>
        }
        errorMessage={formData[Forms.SETTINGS_LIMO.key].globalErrorMessage}
      >
        {active?.clearingFirmMPID && active?.correspondentMPID ? (
          <LimitSettingForm
            isLoading={isLoading}
            form={Forms.SETTINGS_LIMO}
            showEnabled={true}
            showDataSources={true}
            showToggleBuySellNet={true}
          />
        ) : (
          <div>
            Please select a Clearing Correspondent Relationship to update the Risk Configuration for
            next day.
          </div>
        )}
      </SettingsWrapper>
      <FooterFlex justifyContent="flex-end" flexDirection="rows">
        {isReadOnly ? (
          "READ ONLY"
        ) : (
          <Button disabled={isLoading || isDisabled || !active} onClick={handleSave}>
            Save
          </Button>
        )}
      </FooterFlex>
    </>
  );
};
