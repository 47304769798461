import styled from "styled-components";
import { Form } from "types";

export const ADD_EMAIL_BTN_ID = "ADD_PTRA_EMAIL_FORM_BUTTON";

export const AlertForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
`;

export const AlertEditForm = styled.form`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 1rem;
  }

  > * > * {
    margin-bottom: 0px !important;
  }
`;

export const Divider = styled.div``;

export const SectionHeader = styled.h5`
  margin: 0px 0px 1rem 0px !important;
  font-size: 1rem;
  font-weight: bold;
`;

export const SectionFieldsWrapper = styled.div`
  display: grid;
  grid-gap: 0.25rem;
`;

export interface PtraFieldSection {
  form: Form;
}
