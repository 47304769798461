import { Submit, useFormContext, useFormDispatch } from "components/form";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  errorCallbackCB,
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { useUserContext } from "components/user";
import { handleCancel, setCellsLoading, setFormLoading } from ".";
import { useViewModelDispatch, useViewModelContext } from "../../context";
import { actionCallbacks, payload } from "components/standardTable/actionHooks";
import { network } from "components/reportScan/network";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const accentName = "secondary";
const buttonText = "Repair";
export const RepairSubmit = ({ table, form }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const viewDispatch = useViewModelDispatch();
  const [viewData] = useViewModelContext();
  const history = useHistory();
  const errorCallback = useCallback(
    errorCallbackCB({ formDispatch, standardTableDispatch, table, form }),
    [formDispatch, standardTableDispatch]
  );

  const onCancel = useCallback(() => {
    handleCancel({ viewDispatch, formDispatch, form, history });
  }, [viewDispatch, formDispatch, form, history]);

  const repairCallback = useCallback(
    (row, originalWksID) => {
      actionCallbacks.repair({
        row,
        viewDispatch,
        formDispatch,
        userData,
        standardTableDispatch,
        originalWksID,
        form,
        table,
        history,
      });
    },
    [form, formDispatch, standardTableDispatch, table, userData, viewDispatch, history]
  );

  const handleSubmit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: form,
      userData,
    });
    if (!valid) {
      return;
    }

    const selectedRow = standardTableData[table].clickedRow || {};
    const fieldData = [{ ...formData[form.key].fields }];

    const reqData = payload.repair({
      userData,
      form,
      formDispatch,
      selectedRow,
      fieldData: fieldData[0],
    });
    network(userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]).doRepair(
      reqData,
      repairCallback,
      errorCallback
    );
    setFormLoading(formDispatch, form);
    setCellsLoading(standardTableDispatch, table, fieldData);
  }, [
    formData,
    formDispatch,
    form,
    userData,
    standardTableData,
    table,
    repairCallback,
    errorCallback,
    standardTableDispatch,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDisabled={standardTableData[table]?.isLoading || viewData[`${form.key}Disabled`]}
      isLoading={formData[form.key]?.isLoading}
      errors={Object.keys(formData[form.key].totalFormErrors).length}
    />
  );
};
