import React, { useMemo } from "react";
import { FieldNames, WORKX_THEME_VALUE } from "components/fields";
import { getCookie } from "utils/js.utils";

const darkColor = "#0092bc";
const lightColor = "#002029";
export const AuthHeader = ({ message }) => {
  const { wrapStyle, headerStyle } = useMemo(() => {
    const mode = getCookie(FieldNames.workXTheme) || WORKX_THEME_VALUE.LIGHT;
    return {
      wrapStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: mode === WORKX_THEME_VALUE.DARK ? "#1b1b1b" : "white",
        color: mode === WORKX_THEME_VALUE.DARK ? darkColor : lightColor,
      },
      headerStyle: {
        color: mode === WORKX_THEME_VALUE.DARK ? darkColor : lightColor,
        fontSize: "2rem",
        fontFamily: "Rubik, Arial, Helvetica, sans-serif",
        fontWeight: 300,
        textRendering: "optimizelegibility",
      },
    };
  }, []);

  return (
    <div style={wrapStyle}>
      <h1 style={headerStyle}>{message}</h1>
    </div>
  );
};
