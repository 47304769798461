import { CSSGrid, FormTimeField } from "@nef/core";
import { FieldBox } from "components/styled";
import React, { memo, useCallback, useMemo } from "react";
import { execOrReturn, padTimeArrayValue } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import styled from "styled-components";

const TimeRangeDash = styled.div`
  margin: 27px 0;
`;

const ToTimeField = styled(FormTimeField)`
  margin-top: 21.5px;
`;

const WorkXTimeRangeField = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    id,
    handleInputChange: onInputChange,
    portalRef,
  } = props;

  const fieldDataKey = id ? id : name;
  let fieldData;
  if (fieldDataKey && final[fieldDataKey]) {
    fieldData = execOrReturn(final[fieldDataKey], {
      form,
      entitlements: userData.entitlements,
    });
  }

  const numberOfTrailingDigits = useMemo(() => {
    let number = 3;
    if (typeof fieldData.props?.maxLength?.[form.key]?.[3] === "number") {
      number = fieldData.props?.maxLength?.[form.key]?.[3];
    }
    return number;
  }, [fieldData, form.key]);

  const handleChangeGeneric = useCallback(
    (valueIn, position) => {
      const forceUpperCase = fieldData && fieldData.props.forceUpperCase;
      if (forceUpperCase && typeof value === "string") {
        valueIn = valueIn.toUpperCase();
      }

      const valueOut = [];
      if (position === 1) {
        valueOut[0] = value?.[0] || undefined;
        valueOut[1] = valueIn;
      } else {
        valueOut[1] = value?.[1] || undefined;
        valueOut[0] = valueIn;
      }
      if (onInputChange) {
        onInputChange({
          id,
          name,
          value: valueOut,
          fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
        });
      }
    },
    [fieldData, value, onInputChange, id, name, form.key]
  );
  const handleChangeTo = useCallback(
    e => {
      handleChangeGeneric(e.value, 1);
    },
    [handleChangeGeneric]
  );

  const handleChangeFrom = useCallback(
    e => {
      handleChangeGeneric(e.value, 0);
    },
    [handleChangeGeneric]
  );

  const handleBlur = useCallback(
    (e, position) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        if (onInputChange) {
          if (Array.isArray(value) && value.length === 2) {
            const paddedValue = [
              padTimeArrayValue(value[0], numberOfTrailingDigits),
              padTimeArrayValue(value[1], numberOfTrailingDigits),
            ];
            onInputChange({
              id,
              name,
              value: paddedValue,
              fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
            });
          }
        }
      }
    },
    [
      onInputChange,
      value,
      numberOfTrailingDigits,
      id,
      name,
      fieldData.props.augmentOnChange,
      form.key,
    ]
  );

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <CSSGrid cols="1fr auto 1fr" gap={".37rem"}>
        <FormTimeField
          {...props}
          name={`${props.name}_from`}
          aria-label="from time"
          menuPortalTarget={portalRef?.current}
          value={value?.[0]}
          onBlur={handleBlur}
          onChange={handleChangeFrom}
          numberOfTrailingDigits={numberOfTrailingDigits}
          autoComplete="off"
        />
        <TimeRangeDash>-</TimeRangeDash>
        <ToTimeField
          {...props}
          name={`${props.name}_to`}
          label={undefined}
          aria-label="to time"
          menuPortalTarget={portalRef?.current}
          value={value?.[1]}
          onBlur={handleBlur}
          onChange={handleChangeTo}
          numberOfTrailingDigits={numberOfTrailingDigits}
          autoComplete="off"
        />
      </CSSGrid>
    </FieldBox>
  );
};

export default memo(WorkXTimeRangeField);
