import { Progress } from "@nef/core";
import { StandardHeader, StandardTable } from "components/standardTable";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { useCallback, useMemo } from "react";
import TableButtons from "components/standardTable/tableButtons";

const LeftTableWrapper = styled.div`
  grid-column: span 1;
`;

const StyledProgress = styled(Progress)`
  margin-top: 7px;
  width: 100%;
`;
export const PorTable = ({ toggle, title }: { toggle?: () => void | undefined; title: string }) => {
  const TheHeader = useCallback(() => {
    return <StandardHeader title={title} />;
  }, [title]);

  const TheOtherHeader = useCallback(() => {
    return (
      <StyledProgress value={80} color="success">
        Approval Window - Closing in 4:59
      </StyledProgress>
    );
  }, []);

  const disabled = true;
  const tableButtons = useMemo(() => {
    const buttons = [
      {
        icon: "check",
        text: "Approve",
        actionId: "approve",
        requireSelect: true,
        allowMultiSelect: false,
        onClick: () => {},
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        disable: disabled,

        // header: exportHeader,
      },
      {
        icon: "times",
        text: "Deny",
        actionId: "reject",
        requireSelect: true,
        allowMultiSelect: false,
        onClick: () => {},
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        disable: disabled,

        // header: exportHeader,
      },
    ];

    return <TableButtons table={StandardTables.PV_SUPERVISOR_MONITOR2} buttons={buttons} />;
  }, [disabled]);

  return (
    <LeftTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR2}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={true}
        headerMenu={tableButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={TheOtherHeader}
      />
    </LeftTableWrapper>
  );
};
