import { Box, FormField } from "@nef/core";
import React, { memo, useCallback } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import NumberFormat from "react-number-format";
import { FieldBox } from "components/styled";

const WorkXNumber = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    portalRef,
    handleInputChange: onInputChange,
    maxValue,
    allowNegative,
    allowDecimal,
    id,
  } = props;
  const handleChange = useCallback(
    e => {
      let { value: changeValue } = e;
      const numRegex = allowDecimal ? /[^0-9-.]/g : /[^0-9-]/g;
      const negativeNumRegex = allowDecimal ? /[^0-9.]/g : /[^0-9]/g;

      if (allowNegative) {
        changeValue = changeValue.replace(negativeNumRegex, "");
        if (changeValue.indexOf("-") !== -1) {
          changeValue = "-" + changeValue.replace(/-/g, "");
        }
      } else {
        changeValue = changeValue.replace(numRegex, "");
      }

      const fieldDataKey = id ? id : name;
      let fieldData;

      if (fieldDataKey && final[fieldDataKey]) {
        fieldData = execOrReturn(final[fieldDataKey], {
          form,
          entitlements: userData.entitlements,
        });
      }

      if (onInputChange) {
        onInputChange({
          id,
          name,
          value: changeValue,
          fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
        });
      }
    },
    [allowDecimal, allowNegative, id, name, onInputChange, form, userData.entitlements]
  );

  const withValueLimit = useCallback(
    ({ floatValue }) => {
      if (typeof maxValue === "number" && floatValue > 0) {
        return floatValue <= maxValue;
      } else if (typeof maxValue === "number" && floatValue < 0) {
        return floatValue >= maxValue * -1;
      }
      return true;
    },
    [maxValue]
  );

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <NumberFormat
        customInput={FormField}
        {...props}
        menuPortalTarget={portalRef?.current}
        value={value !== undefined && value !== null ? value.toString() : ""}
        onChange={handleChange}
        thousandSeparator={true}
        isAllowed={withValueLimit}
        decimalSeparator={"."}
        allowNegative={allowNegative}
      />
    </FieldBox>
  );
};

export default memo(WorkXNumber);
