import React from "react";
import { Box, Header, Alert, Flex } from "@nef/core";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import { Divider } from "components/global";

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  ${props =>
    props.hasTabs
      ? `
      border-bottom: 2px solid ${getColor("gray", 50)(props)};
      padding-bottom: 1rem;
  `
      : ``}
`;

const SubHeader = styled.span`
  color: ${getColor("gray", 500)};
`;

const FormWrapper = styled(Box)`
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding-bottom: 2px !important;
  ${props => (props.$hasSave ? "margin-bottom: 70px;" : "")}
`;

const dividerMargin = "";
export const SettingsWrapper = ({
  children,
  header,
  headerMenu,
  subheader,
  errorMessage,
  RightSide,
  hasSave = true,
  disableFormWrapperClass = false,
  tabs,
}) => {
  return (
    <>
      <Box padding={2} paddingBottom={tabs ? 0 : 2}>
        <HeaderWrapper hasTabs={tabs !== undefined}>
          <Flex justifyContent="space-between" alignItems="top">
            <StyledFlex justifyContent="space-between" alignItems="center">
              <Header size={2}>{header}</Header>
              {headerMenu}
            </StyledFlex>
            {!!RightSide ? RightSide : <></>}
          </Flex>
          <SubHeader>{subheader}</SubHeader>
          {(typeof errorMessage === "object" || !!errorMessage?.length) && (
            <Alert color="danger">
              <p>{errorMessage}</p>
            </Alert>
          )}
        </HeaderWrapper>
        {tabs}
      </Box>
      <Divider />
      <FormWrapper padding={2} $hasSave={hasSave}>
        {children}
      </FormWrapper>
    </>
  );
};
