import { FieldNames, Forms, WORKX_NOTIFICATION_PLACEMENT_VALUES, WORKX_THEME_VALUE } from "components/fields";
import { useUserContext } from "components/user";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { getCookie } from "utils/js.utils";
import { UserData, USER_PREFEREENCE_NAME } from "./initialDataModelTS";

export type UserPreferenceAction =
  | {
      type: "SET_PREFERENCE";
      payload: {
        name: USER_PREFEREENCE_NAME;
        value: string;
      };
    }

export interface UserPreferenceState {
  [USER_PREFEREENCE_NAME.THEME]: string;
  [USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT]: string;
}
  
const defaultState: UserPreferenceState = {
  [USER_PREFEREENCE_NAME.THEME]: getCookie(FieldNames.workXTheme) || WORKX_THEME_VALUE.LIGHT,
  [USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT]: getCookie(FieldNames.workXNotificationPlacement) || WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT
};

const userPreferenceDispatch = createContext<React.Dispatch<UserPreferenceAction | UserPreferenceAction[]>>(
    () => null
  );
  userPreferenceDispatch.displayName = "userPreferenceDispatch";
  export const useUserPreferenceDispatch = () => useContext(userPreferenceDispatch);

const userPreferenceContext = createContext<
  [UserPreferenceState, React.Dispatch<UserPreferenceAction | UserPreferenceAction[]>]
>([defaultState, () => null]);
userPreferenceContext.displayName = "userPreferenceContext";
export const useUserPreferenceContext = () => useContext(userPreferenceContext);

const DispatchFn = (state: UserPreferenceState, actions: UserPreferenceAction | UserPreferenceAction[]) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (
  state: UserPreferenceState,
  action: UserPreferenceAction
): UserPreferenceState => {
  switch (action.type) {
    case "SET_PREFERENCE": {
      const { name, value } = action.payload
      document.cookie = `${name}=${value};expires=2147483647`;
      if (state[name] !== value) {
        return { ...state, [name]: value };
      }
      return state;
    }
    default:
      return { ...state };
  }
};

interface UserPreferenceContextProps {
  children: React.ReactNode;
  defaultData?: UserPreferenceState;
}

export const UserPreferenceProvider: React.FC<UserPreferenceContextProps> = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    if (Array.isArray(user.userData)) {
        user.userData.forEach((entry: UserData) => {
            if (entry.name === Forms.SETTINGS_USER_PREFERENCE.key) {
                const data = JSON.parse(entry.data);
                const actions: UserPreferenceAction[] = [];
                if (data?.[FieldNames.workXTheme] && data[FieldNames.workXTheme].value !== undefined) {
                    actions.push({
                        type: "SET_PREFERENCE",
                        payload: { name: USER_PREFEREENCE_NAME.THEME, value: data[FieldNames.workXTheme].value }

                    });
                }
                if (data?.[FieldNames.workXNotificationPlacement] && data[FieldNames.workXNotificationPlacement].value !== undefined) {
                    actions.push({
                        type: "SET_PREFERENCE",
                        payload: { name: USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT, value: data[FieldNames.workXNotificationPlacement].value }

                    });
                }
                if (actions.length > 0) {
                    dispatchF(actions);
                }
            }
        })

    }
  }, [user])

  return (
    <userPreferenceDispatch.Provider value={dispatchF}>
      <userPreferenceContext.Provider value={[state, dispatchF]}>{children}</userPreferenceContext.Provider>
    </userPreferenceDispatch.Provider>
  );
};
