import { getKeycloak } from "./keycloak";

const getAuthHeaders = () => ({
  Authorization: `Bearer ${getKeycloak().token}`,
});

const getJSONHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json;charset=utf-8",
});

export const getHeaders = () => {
  return Object.assign({}, getAuthHeaders(), getJSONHeaders());
};
