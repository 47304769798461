import { Box } from "@nef/core";
import React, { useCallback, useMemo } from "react";
import { StatsButtons } from ".";
import { StandardHeader, StandardTable } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { useStatsContext } from "./context";

const Table = () => {
  const [statsData] = useStatsContext();
  const HeaderFn = useCallback(
    () => <StandardHeader title={statsData.currentTitle} />,
    [statsData.currentTitle]
  );

  const headerMenuFn = useMemo(() => <StatsButtons />, []);
  return (
    <Box style={{ maxHeight: "100%" }}>
      <StandardTable
        table={StandardTables.ST_MIDDLE}
        enableHeaderContextMenu={true}
        header={HeaderFn}
        multiSort={true}
        multiSelectRows={true}
        headerMenu={headerMenuFn}
        enableLayoutExport={true}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={false}
      />
    </Box>
  );
};
export default Table;
