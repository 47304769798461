import React from "react";
import { StandardTables } from "../../../wksConstants";
import { StandardTable } from "../../standardTable";

export const RiskAlertEditValidation = () => {
  return (
    <StandardTable
      header={"Bulk Edit Validation"}
      table={StandardTables.PTRA_EDIT_VALIDATION}
      enableLayoutExport={false}
      id={"ptra-validation-table"}
      isSingleSelect={false}
      isColumnsVirtualized={false}
      isFilterable={true}
      isRowSelectEnabled={false}
    />
  );
};
