import { Flex, FontAwesomeIcon, Indicator, NotificationHub } from "@nef/core";
import {
  ApiResponseNames,
  getSettlementLabelFromDTO,
  SettlementLabels,
  SettlementValues,
} from "components/fields";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { TableButton } from "components/standardTable";
import { formatNumberToCurrencyString, getFieldLabel, multiplyFloats } from "utils/js.utils";
import { isExecPartyBuyer, Messages, TableButtonAction, TableButtonLabel } from "wksConstants";
import { getIsButtonDisabled } from "./util";
import { colors } from "../../../colors";
import { network } from "../../reportScan/network";
import {
  extendedHoursAndLateLabel,
  modifierInLegend,
  modifierOutLegend,
  settlementLabel,
  statusColor,
  statusType,
  tradeModiferLabel,
  TradeRecapTag,
  tradeThroughXLabel,
} from "../../tradeDetailRecap";
import { useRecapContext, useRecapDispatch } from "../../tradeDetailRecap/recapContext";
import {
  getNumSelectedRows,
  getSelectedRows,
  useStandardTableContext,
} from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { TR_SHORT_SALE_INDICATOR, TR_SIDE } from "components/fields/constants";

const maxRows = 4;
const recapButtonConfig = {
  icon: "clipboard-list",
  text: TableButtonLabel.RECAP,
  actionId: TableButtonAction.RECAP,
  requireSelect: true,
  allowMultiSelect: true,
  maxSelectedRows: maxRows,
  disabledMessage: Messages.MAX_ROWS(maxRows),
  header: TradesHeader,
};

export const RecapButton = ({ table }) => {
  const [standardTableData] = useStandardTableContext();
  const [recapData] = useRecapContext();
  const recapDispatch = useRecapDispatch();

  const handleToggleLegend = useCallback(
    e => {
      recapDispatch({
        type: "SET_LEGEND",
        payload: e.target.dataset.name,
      });
    },
    [recapDispatch]
  );

  const getRecapDataFromRow = useCallback(
    rowData => {
      const { id } = rowData;
      const statusText = `${
        rowData[ApiResponseNames.tradeStatus]?.value
          ? `(${rowData[ApiResponseNames.tradeStatus]?.value}) `
          : ""
      }${rowData[ApiResponseNames.tradeStatus]?.label}`;

      const legendIconClassName = "fa-info-circle";
      const legendIconStyle = {
        color: colors.primary,
        marginLeft: ".5rem",
        cursor: "pointer",
        fontSize: "1rem",
      };

      const getCBS = (tradeStatus, reportingParty, side, breakState) => {
        if (tradeStatus === "N") {
          return "No";
        }
        switch (breakState) {
          case "":
          case "None":
          case "0":
          case 0:
            return " ";
          case "Part MM":
          case "1":
          case 1:
            if (isExecPartyBuyer(reportingParty, side)) {
              return "B";
            } else {
              return "S";
            }
          case "Part OE":
          case "2":
          case 2:
            if (isExecPartyBuyer(reportingParty, side)) {
              return "S";
            } else {
              return "B";
            }
          case "Done MM":
          case "3":
          case 3:
          case "Done OE":
          case "4":
          case 4:
            return "B+S";
          default:
            return "";
        }
      };

      let tapeStatus = "";
      switch (rowData[ApiResponseNames.publishFlag]) {
        case " ":
          tapeStatus = "Yes";
          break;
        case "N":
          tapeStatus = "No";
          break;
        default:
          break;
      }

      let mktCenter = "";
      switch (rowData[ApiResponseNames.originalMarketFlag]) {
        case "T":
          mktCenter = "NASDAQ CQS";
          break;
        case "Q":
          mktCenter = "NASDAQ NNM";
          break;
        case "A":
          mktCenter = "AMEX";
          break;
        case "M":
          mktCenter = "MWSE";
          break;
        case "D":
          mktCenter = "Portal";
          break;
        case "P":
          mktCenter = "Pacific";
          break;
        case "X":
          mktCenter = "PHLX";
          break;
        case "U":
          mktCenter = "NNOTC OTCBB";
          break;
        case "u":
          mktCenter = "Pink Sheet";
          break;
        case "L":
          mktCenter = "Away";
          break;
        default:
          break;
      }

      const shortSaleSides = [
        TR_SIDE.BUY_CUSTOMER_SOLD_SHORT,
        TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT,
        TR_SIDE.SELL_SHORT,
        TR_SIDE.SHORT_SELL_EXEMPT,
        TR_SIDE.CROSS_SELL_SHORT,
        TR_SIDE.CROSS_SELL_SHORT_EXEMPT,
      ];
      const shortSaleIndicators = [
        TR_SHORT_SALE_INDICATOR.SOLD_SHORT,
        TR_SHORT_SALE_INDICATOR.SOLD_SHORT_EXEMPT,
      ];

      let SSI = "No";
      if (
        shortSaleSides.includes(rowData[ApiResponseNames.side]?.value) ||
        shortSaleIndicators.includes(rowData[ApiResponseNames.contraShortSaleInd]?.value)
      ) {
        SSI = "Yes";
      }

      const isExecPartyBuySide = isExecPartyBuyer(
        rowData[ApiResponseNames.reportingParty]?.value,
        rowData[ApiResponseNames.side]?.value
      );
      const crossSideValues = ["X", "C", "K"];
      const isSideCross = crossSideValues.includes(rowData[ApiResponseNames.side]?.value);

      let tradeVenue;
      switch (rowData[ApiResponseNames.trf]?.value) {
        case "1": // carteret
          tradeVenue = "NQ TRF Carteret";
          break;
        case "2": // chicago
          tradeVenue = "NQ TRF Chicago";
          break;
        default:
          tradeVenue = "";
      }

      const recapDataLeft = [
        {
          id: ApiResponseNames.tradeStatus,
          label: (
            <>
              Status
              <FontAwesomeIcon
                data-name="status"
                onClick={handleToggleLegend}
                iconClassName={legendIconClassName}
                style={legendIconStyle}
              />
            </>
          ),
          value: (
            <Flex alignItems="center" alignSelf="center">
              <Indicator
                id={`statusIndicator_${id}`}
                color={statusColor[rowData[ApiResponseNames.tradeStatus]?.value]}
                size="sm"
              >
                {statusType[statusColor[rowData[ApiResponseNames.tradeStatus]?.value]]}
              </Indicator>
              <span style={{ marginLeft: "5px" }}>{statusText}</span>
            </Flex>
          ),
        },
        {
          id: ApiResponseNames.reportingParty,
          label: "Buyer",
          value: (
            <Flex alignItems="center" style={{ height: "16px" }}>
              {((isExecPartyBuySide && rowData[ApiResponseNames.reportingParty]?.value === "M") ||
                (!isExecPartyBuySide &&
                  rowData[ApiResponseNames.reportingParty]?.value === "O")) && (
                <TradeRecapTag
                  id={id}
                  reportingPartyValue={rowData[ApiResponseNames.reportingParty].value}
                />
              )}
              {isExecPartyBuySide
                ? getFieldLabel(rowData[ApiResponseNames.executingMPID])
                : getFieldLabel(rowData[ApiResponseNames.contraMPID])}
            </Flex>
          ),
        },
        {
          label: "Security",
          value: rowData[ApiResponseNames.symbol],
        },
        {
          id: ApiResponseNames.price,
          label: "Price",
          value: (
            <Flex>
              {rowData[ApiResponseNames.priceTradeDigit] === "B" && (
                <TradeRecapTag id={id} isContractPrice={true} />
              )}
              {formatNumberToCurrencyString(rowData[ApiResponseNames.price])}
            </Flex>
          ),
        },
        {
          label: "Fee",
          value: formatNumberToCurrencyString(rowData[ApiResponseNames.fee]),
        },
        {
          label: "Clearing Price",
          value: rowData[ApiResponseNames.fee] ? (
            <Flex>
              {rowData[ApiResponseNames.priceTradeDigit] === "B" && (
                <TradeRecapTag id={id} isContractPrice={true} />
              )}
              {formatNumberToCurrencyString(rowData[ApiResponseNames.clearingPrice])}
            </Flex>
          ) : (
            ""
          ),
        },
        {
          label: "Reported Time",
          value: rowData[ApiResponseNames.actTime],
        },
        {
          label: "BCL #",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.executingClearingNum]
            : rowData[ApiResponseNames.contraClearingNum],
        },
        {
          label: "Trade Venue",
          value: tradeVenue,
        },
        {
          label: "Buy Cap",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.executingCapacity]?.label
            : rowData[ApiResponseNames.contraCapacity]?.label,
        },
        {
          label: "Buy G/U",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.executingGiveUpMPID]
            : rowData[ApiResponseNames.contraGiveUpMPID],
        },
        {
          label: `Branch Seq # (${getFieldLabel(rowData[ApiResponseNames.executingMPID])})`,
          value: rowData[ApiResponseNames.executingBranchSeqNum],
        },
        {
          label: "Match Control #",
          value: rowData[ApiResponseNames.matchControlNum],
        },
        {
          label: "Tape Status",
          value: tapeStatus,
        },
        {
          label: "CBS",
          value: getCBS(
            rowData[ApiResponseNames.tradeStatus]?.value,
            rowData[ApiResponseNames.reportingParty]?.value,
            rowData[ApiResponseNames.side]?.value,
            rowData[ApiResponseNames.breakState]
          ),
        },
        {
          label: "Special",
          value: rowData[ApiResponseNames.special],
        },
        {
          label: "Reversal",
          value: rowData[ApiResponseNames.reversalFlag],
        },
        {
          label: "Override Price",
          value: rowData[ApiResponseNames.priceOverride] === "O" ? "Yes" : "No",
        },
        {
          label: "Ref #",
          value: rowData[ApiResponseNames.referenceNum],
        },
        {
          label: "Mkt. Center",
          value: mktCenter,
        },
        {
          label: "Seller Sale Days",
          value: rowData[ApiResponseNames.sellerDays],
        },
        {
          label: "Trade Ref #",
          value: rowData[ApiResponseNames.tradeReferenceNum],
        },
        {
          label: "Reference Facility",
          value: rowData[ApiResponseNames.referenceReportingVenue]?.label,
        },
        {
          label: "Orig. Control #",
          value: rowData[ApiResponseNames.originalControlNum],
        },
        {
          label: "Orig. Control # Date",
          value:
            rowData[ApiResponseNames.originalControlDate] &&
            moment(rowData[ApiResponseNames.originalControlDate]).format("MM/DD/YYYY"),
        },
        {
          label: "Intended Mkt. Center",
          value: rowData[ApiResponseNames.intendedMarketFlag]?.label,
        },
      ];

      let clearingStatus = "";
      switch (rowData[ApiResponseNames.clearingFlag]) {
        case " ":
          clearingStatus = "Clear";
          break;
        case "G":
          clearingStatus = "G - AGU";
          break;
        case "N":
          clearingStatus = "N - None";
          break;
        case "Q":
          clearingStatus = "Q - QSR";
          break;
        case "Z":
          clearingStatus = "Z - QSR Clear";
          break;
        default:
          break;
      }

      const recapDataRight = [
        {
          label: "Control #",
          value: rowData[ApiResponseNames.controlNum],
        },
        {
          id: ApiResponseNames.reportingParty,
          label: (
            <>
              Seller
              <FontAwesomeIcon
                data-name="seller"
                onClick={handleToggleLegend}
                iconClassName={legendIconClassName}
                style={legendIconStyle}
              />
            </>
          ),
          value: (
            <Flex alignItems="center" style={{ height: "16px" }}>
              {((isExecPartyBuySide && rowData[ApiResponseNames.reportingParty]?.value === "O") ||
                (!isExecPartyBuySide &&
                  rowData[ApiResponseNames.reportingParty]?.value === "M")) && (
                <TradeRecapTag
                  id={id}
                  reportingPartyValue={rowData[ApiResponseNames.reportingParty].value}
                />
              )}
              {!isExecPartyBuySide
                ? getFieldLabel(rowData[ApiResponseNames.executingMPID])
                : getFieldLabel(rowData[ApiResponseNames.contraMPID])}
            </Flex>
          ),
        },
        {
          label: "Volume",
          value: rowData[ApiResponseNames.quantity],
        },
        {
          label: "Total Trade Amount",
          value: formatNumberToCurrencyString(
            rowData[ApiResponseNames.priceTradeDigit] === "B"
              ? rowData[ApiResponseNames.price]
              : multiplyFloats(
                  rowData[ApiResponseNames.price] || 0,
                  rowData[ApiResponseNames.quantity] || 0
                )
          ),
        },
        {
          label: "Final Money",
          value: formatNumberToCurrencyString(
            rowData[ApiResponseNames.priceTradeDigit] === "B"
              ? rowData[ApiResponseNames.clearingPrice] || rowData[ApiResponseNames.price]
              : multiplyFloats(
                  rowData[ApiResponseNames.clearingPrice] || rowData[ApiResponseNames.price] || 0,
                  rowData[ApiResponseNames.quantity] || 0
                )
          ),
        },
        {
          label: "T-Date",
          value:
            rowData[ApiResponseNames.executionDate] &&
            moment(rowData[ApiResponseNames.executionDate]).format("MM/DD/YYYY"),
        },
        {
          label: "Time",
          value:
            rowData[ApiResponseNames.executionTime] &&
            `${rowData[ApiResponseNames.executionTime].slice(0, 3).join(":")}${
              rowData[ApiResponseNames.executionTime].slice(3).length > 0
                ? `.${rowData[ApiResponseNames.executionTime].slice(3)}`
                : ""
            }`,
        },
        {
          label: "SCL #",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.contraClearingNum]
            : rowData[ApiResponseNames.executingClearingNum],
        },
        {
          label: "SSI",
          value: SSI,
        },
        {
          label: "Sell Cap",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.contraCapacity]?.label
            : rowData[ApiResponseNames.executingCapacity]?.label,
        },
        {
          label: "Sell G/U",
          value: isExecPartyBuySide
            ? rowData[ApiResponseNames.contraGiveUpMPID]
            : rowData[ApiResponseNames.executingGiveUpMPID],
        },
        {
          label: `Contra Branch Seq # (${getFieldLabel(rowData[ApiResponseNames.contraMPID])})`,
          value: rowData[ApiResponseNames.contraBranchSeqNum],
        },
        {
          label: "Clearing Status",
          value: clearingStatus,
        },
        {
          label: "Additional Instructions",
          value: rowData[ApiResponseNames.memo],
        },
        {
          label: "Step Out/In",
          value: rowData[ApiResponseNames.stepInOut]?.label,
        },
        {
          label: "As Of",
          value: rowData[ApiResponseNames.asOf],
        },
        {
          id: ApiResponseNames.tradeStatus,
          label: (
            <>
              Modifier (In/Out)
              <FontAwesomeIcon
                data-name="modifier"
                onClick={handleToggleLegend}
                iconClassName={legendIconClassName}
                style={legendIconStyle}
              />
            </>
          ),
          value: `${rowData[ApiResponseNames.entryTradeModifiers]} | ${
            rowData[ApiResponseNames.tradeStatus]?.value !== "-"
              ? rowData[ApiResponseNames.tradeModifiers]
              : ""
          }`,
        },
        {
          label: "Trade Modifier 1",
          value: getSettlementLabelFromDTO(
            rowData,
            rowData[ApiResponseNames.settlement] === SettlementValues.Other
          ),
        },
        {
          label: "Trade Modifier 2",
          value:
            (rowData[ApiResponseNames.modifier2Input]?.value &&
            rowData[ApiResponseNames.modifier2Input].value !== " "
              ? `
            ${rowData[ApiResponseNames.modifier2Input].value} - ${
                  modifierInLegend[tradeThroughXLabel][
                    rowData[ApiResponseNames.modifier2Input].value
                  ]
                }
           | `
              : "") +
            (rowData[ApiResponseNames.modifier2]?.value &&
            rowData[ApiResponseNames.modifier2].value !== " "
              ? ` 
                ${rowData[ApiResponseNames.modifier2].value} - ${
                  modifierOutLegend[tradeThroughXLabel][rowData[ApiResponseNames.modifier2].value]
                }
              `
              : ""),
        },
        {
          label: "Trade Modifier 3",
          value:
            (rowData[ApiResponseNames.modifier3Input]?.value &&
            rowData[ApiResponseNames.modifier3Input].value !== " "
              ? `
            ${rowData[ApiResponseNames.modifier3Input].value} - ${
                  modifierInLegend[extendedHoursAndLateLabel][
                    rowData[ApiResponseNames.modifier3Input].value
                  ]
                }
           | `
              : "") +
            (rowData[ApiResponseNames.modifier3]?.value &&
            rowData[ApiResponseNames.modifier3].value !== " "
              ? ` 
                ${rowData[ApiResponseNames.modifier3].value} - ${
                  modifierOutLegend[extendedHoursAndLateLabel][
                    rowData[ApiResponseNames.modifier3].value
                  ]
                }
              `
              : ""),
        },
        {
          label: "Trade Modifier 4",
          value:
            (rowData[ApiResponseNames.modifier4Input]?.value &&
            rowData[ApiResponseNames.modifier4Input].value !== " "
              ? `
            ${rowData[ApiResponseNames.modifier4Input].value} - ${
                  modifierInLegend[tradeModiferLabel][
                    rowData[ApiResponseNames.modifier4Input].value
                  ]
                }
           | `
              : "") +
            (rowData[ApiResponseNames.modifier4]?.value &&
            rowData[ApiResponseNames.modifier4].value !== " "
              ? ` 
                ${rowData[ApiResponseNames.modifier4].value} - ${
                  modifierOutLegend[tradeModiferLabel][rowData[ApiResponseNames.modifier4].value]
                }
              `
              : ""),
        },
        {
          label: "Modifier 2 Time",
          value:
            rowData[ApiResponseNames.modifier2Time] &&
            `${rowData[ApiResponseNames.modifier2Time].slice(0, 3).join(":")}${
              rowData[ApiResponseNames.modifier2Time].slice(3).length > 0
                ? `.${rowData[ApiResponseNames.modifier2Time].slice(3)}`
                : ""
            }`,
        },
        {
          label: "Modifier 4 Time",
          value:
            rowData[ApiResponseNames.modifier4Time] &&
            `${rowData[ApiResponseNames.modifier4Time].slice(0, 3).join(":")}${
              rowData[ApiResponseNames.modifier4Time].slice(3).length > 0
                ? `.${rowData[ApiResponseNames.modifier4Time].slice(3)}`
                : ""
            }`,
        },
        {
          label: "Trade Thru Exempt",
          value: rowData[ApiResponseNames.tradeThrough],
        },
        {
          label: "Related Mkt. Center",
          value: rowData[ApiResponseNames.relatedMarketFlag]?.label,
        },
        {
          label: "I1I2",
          value:
            rowData[ApiResponseNames.entryI1I2] !== undefined
              ? rowData[ApiResponseNames.entryI1I2]
              : rowData[ApiResponseNames.lastUpdateI1I2],
        },
      ];
      return { id, data: [recapDataLeft, recapDataRight] };
    },
    [handleToggleLegend]
  );

  const [rows, numSelected] = useMemo(() => {
    return [
      getSelectedRows(standardTableData[table]),
      getNumSelectedRows(standardTableData[table]),
    ];
  }, [table, standardTableData]);

  const recapSuccess = useCallback(
    rowData => {
      const recapData = rowData.map(row => getRecapDataFromRow(row));
      recapDispatch([
        {
          type: "ADD_TRADES",
          payload: recapData,
        },
        {
          type: "SET_LOADING",
          payload: false,
        },
      ]);
    },
    [getRecapDataFromRow, recapDispatch]
  );

  const recapError = useCallback(() => {
    NotificationHub.send(
      "danger",
      "The server had problems retrieving the trade recap(s). Please try again."
    );
    recapDispatch([
      {
        type: "SET_OPEN",
        payload: false,
      },
      {
        type: "SET_LOADING",
        payload: false,
      },
    ]);
  }, [recapDispatch]);

  const handleRecap = useCallback(async () => {
    const controller = new AbortController();
    const recapActions = [
      {
        type: "SET_LOADING",
        payload: true,
      },
      {
        type: "SET_OPEN",
        payload: true,
      },
      {
        type: "SET_ABORT_CONTROLLER",
        payload: controller,
      },
    ];

    if (!recapData.isMaxHeight) {
      recapActions.push(
        { type: "SET_MAX_HEIGHT", payload: true },
        {
          type: "SET_HEIGHT",
          payload: recapData.maxHeight,
        }
      );
    }
    recapDispatch(recapActions);
    const tradeIds = rows.map(row => {
      return row.id;
    });
    network().doRecap(tradeIds, recapSuccess, recapError, controller);
  }, [rows, recapSuccess, recapError, recapDispatch, recapData]);

  return (
    <TableButton
      table={table}
      {...recapButtonConfig}
      disabled={getIsButtonDisabled(recapButtonConfig, numSelected)}
      loading={recapData.isLoading}
      onClick={handleRecap}
      key="tableButton_recap"
    />
  );
};
