import React, { useCallback, useMemo, memo } from "react";
import { StandardHeader, StandardTable } from "components/standardTable";
import { StandardTables } from "../../../wksConstants";

export const DynamicTable = memo(({ table, title, TableButtons }) => {
  const buttons = useMemo(() => (TableButtons ? <TableButtons /> : null), [TableButtons]);
  const Header = useCallback(() => <StandardHeader title={title} />, [title]);

  return (
    <StandardTable
      header={Header}
      table={table}
      multiSelectRows={true}
      headerMenu={buttons}
      enableLayoutExport={true}
      hideRowCount={false}
      hideSelectedCount={false}
      hideQueryDate={false}
      isRowSelectEnabled={
        ![
          StandardTables.RD_AGU,
          StandardTables.RD_CLEARING,
          StandardTables.RD_CUSIP,
          StandardTables.RISK_UPLOAD_SUCCESSES,
        ].includes(table)
      }
    />
  );
});
