import React, { useEffect, useReducer, createContext, useContext } from "react";
import { NotificationHub } from "@nef/core";

export const recapDispatch = createContext();
recapDispatch.displayName = "RecapDispatch";
export const useRecapDispatch = () => {
  return useContext(recapDispatch);
};

export const recapContext = createContext();
recapContext.displayName = "RecapContext";
export const useRecapContext = () => {
  return useContext(recapContext);
};

const maxHeight = "0";
export const defaultState = {
  trades: [],
  isOpen: false,
  isLegendOpen: false,
  currentLegend: null,
  isMaxHeight: false,
  height: 0,
  isLoading: false,
  controller: null,
  maxRecaps: 4,
  maxHeight,
  minHeight: 60,
};

export const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "ADD_SELF":
      return { ...state, dispatch: action.payload };
    case "EMPTY_OUT_TRADES":
      return { ...state, trades: [] };
    case "ADD_TRADES": {
      const newTrades = action.payload;
      const trades = [...state.trades];
      const knownIds = trades.map(trade => trade.id);
      let repeatCount = 0;
      let maxExceeded = false;
      newTrades.forEach(trade => {
        if (trades.length >= state.maxRecaps) {
          maxExceeded = true;
          return;
        }
        if (!knownIds.includes(trade.id)) {
          trades.push(trade);
        } else {
          repeatCount++;
        }
      });
      if (maxExceeded) {
        NotificationHub.send(
          "warning",
          `A maximum of ${state.maxRecaps} trade details can be shown in the recap window.`
        );
      }
      if (repeatCount > 0) {
        NotificationHub.send(
          "warning",
          "At least one trade that was added is already shown in the recap window. No duplicates allowed."
        );
      }
      return { ...state, trades };
    }
    case "REMOVE_TRADE": {
      const id = action.payload;
      const trades = [...state.trades];
      const idx = trades.findIndex(trade => {
        return trade?.id?.toString() === id?.toString();
      });
      if (idx !== -1) {
        trades.splice(idx, 1);
      }
      const newState = { ...state, trades };
      return newState;
    }
    case "SET_OPEN": {
      return { ...state, isOpen: action.payload };
    }
    case "SET_HEIGHT": {
      return { ...state, height: action.payload };
    }
    case "TOGGLE_HEIGHT": {
      let height;
      if (action.payload /* isMaxHeight */) {
        height = state.maxHeight;
      } /* !isMaxHeight */ else {
        height = state.minHeight;
      }
      return { ...state, height };
    }
    case "SET_MAX_HEIGHT": {
      return {
        ...state,
        isMaxHeight: action.payload,
      };
    }
    case "SET_MAX_HEIGHT_VALUE":
      if (state.maxHeight === action.payload) {
        return state;
      }
      return { ...state, maxHeight: action.payload, height: action.payload };

    case "SET_LEGEND_OPEN": {
      return { ...state, isLegendOpen: action.payload };
    }
    case "SET_LEGEND": {
      return { ...state, currentLegend: action.payload };
    }
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_ABORT_CONTROLLER": {
      return { ...state, controller: action.payload };
    }
    case "ABORT_REQUEST": {
      if (state.controller !== null) {
        state.controller.abort();
      }
      return { ...state, controller: null, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export const RecapProvider = ({ children }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState));

  useEffect(() => {
    dispatchF({
      type: "ADD_SELF",
      payload: dispatchF,
    });
  }, []);

  return (
    <recapDispatch.Provider value={dispatchF}>
      <recapContext.Provider value={[state, dispatchF]}>{children}</recapContext.Provider>
    </recapDispatch.Provider>
  );
};
