import React, { useRef, useState } from "react";
import { Status } from "wksConstants";
import { doFetch } from "network";
import { getHeaders } from "keycloak";
import { useCallback, useEffect } from "react";
import {
  BREACHES,
  EXPOSURE_BREACHES,
  EXPOSURE_LEVELS,
  ExposureCacheData,
  SingleOrderCacheData,
} from "./eqrc.types";
import { useFormContext } from "components/form";
import { Forms } from "components/fields";
import { EqrcFields } from "components/settings/eqrc/constants";
import { enumKeys, isFulfilled } from "./utils";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { formatUrl } from "utils/js.utils";
import { SelectOption } from "types";
import { Charts_Or_Table, useEqrcCacheDispatch } from "./dataCacheContext";
import { SingleOrderMap } from "./cache.types";
import { Args } from "./useTableData";
import { EqrcCacheAction } from "./dataCacheContext.types";

type SingleOrderCollections = [
  alertTypeMpidPort: string,
  data: {
    items: any;
    numItemsInCollection: number;
    numItemsInRange: number;
    offset: number;
  }
][];

export const useChartsData = ({
  shouldRequest,
  isRequesting,
  shouldMakeFirstRequest,
  isWindowFocused,
  singleOrders,
  globalError,
}: {
  shouldRequest: boolean;
  isRequesting: boolean;
  shouldMakeFirstRequest: boolean;
  isWindowFocused: boolean;
  singleOrders: SingleOrderMap;
  globalError: boolean;
}) => {
  const [formData] = useFormContext();
  const abort: { current: AbortController | null } = useRef(null);
  const [user] = useUserContext();
  const [args, setArgs] = useState<Args>({ mpid: undefined, ports: undefined });
  const eqrcCacheDispatch = useEqrcCacheDispatch();

  const onChartsPollSuccess = useCallback(
    (
      data: {
        singleOrder: {
          [kindTypeMpidPort: string]: {
            offset: number;
            items: any[];
            numItemsInCollection: number;
          };
        };
        exposure: {
          [key: string]: {
            offset: number;
            items: any[];
            numItemsInCollection: number;
          };
        };
      },
      isSuccess: boolean
    ) => {
      const singleOrder = Object.entries(data.singleOrder).reduce<SingleOrderCacheData>(
        (acc: SingleOrderCacheData, [alertType, alertData]) => {
          acc[alertType] = {
            offset: alertData.offset,
            data: alertData.items,
          };

          return acc;
        },
        {}
      );

      const exposures = Object.entries(data.exposure).reduce<ExposureCacheData>(
        (acc: ExposureCacheData, [k, exposureData]) => {
          const level = k.split("-")[2];
          if (!acc[k]) {
            acc[k] = {};
          }

          acc[k][level] = {
            offset: exposureData.offset,
            data: exposureData.items,
          };
          return acc;
        },
        {} as ExposureCacheData
      );

      const eqrcDispatchs = [
        {
          type: "SET_DATA_CHARTS",
          payload: {
            dataToSet: {
              singleOrders: singleOrder,
              exposure: exposures,
            },
          },
        },
        {
          type: "SET_IS_REQUESTING",
          payload: { isRequesting: false, view: Charts_Or_Table.charts },
        },
        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { view: Charts_Or_Table.charts, shouldMake: false },
        },
        isSuccess
          ? {
              type: "SET_STATUS",
              payload: { status: Status.SUCCESS, view: Charts_Or_Table.charts },
            }
          : {},
      ] as EqrcCacheAction[];

      eqrcCacheDispatch(eqrcDispatchs);
    },
    [eqrcCacheDispatch]
  );

  const onSingleOrderPollFail = useCallback(() => {
    const eqrcCacheDispatchs = [
      { type: "SET_STATUS", payload: { view: Charts_Or_Table.charts, status: Status.ERROR } },
      { type: "SET_IS_REQUESTING", payload: { view: Charts_Or_Table.charts, isRequesting: false } },
      {
        type: "SET_SHOULD_MAKE_FIRST_REQUEST",
        payload: { view: Charts_Or_Table.charts, shouldMake: false },
      },
    ] as EqrcCacheAction[];

    eqrcCacheDispatch(eqrcCacheDispatchs);
  }, [eqrcCacheDispatch]);

  type outboundPayload = { [key: string]: { [key: string]: {} } };

  const setData = useCallback(
    (
      kind: string,
      data: {
        type:
          | (typeof BREACHES)[keyof typeof BREACHES]
          | (typeof EXPOSURE_BREACHES)[keyof typeof EXPOSURE_BREACHES];

        mpid: string;
        port: string | number | null;
        level?: (typeof EXPOSURE_LEVELS)[keyof typeof EXPOSURE_LEVELS] | null;
      },

      payloadObj: outboundPayload
    ): void => {
      const { type, mpid, port, level } = data;
      switch (kind) {
        case "singleOrder": {
          const key = `${kind}-${type}-${mpid}-${port}`;
          const offset = singleOrders.get(key)?.offset;

          payloadObj[type][key] = {
            start: offset !== undefined ? offset + 1 : 0,
            end: -1,
            keys: {
              mpid: mpid,
              ...(port !== null ? { port: port } : {}),
              alertType: type,
            },
          };
          break;
        }
        default:
          const key = `${kind}-${type}-${level}-${mpid}-${port}`;
          const offset = singleOrders.get(key)?.offset;

          payloadObj[type][key] = {
            start: offset !== undefined ? offset + 1 : 0,
            end: -1,
            keys: {
              mpid: mpid,
              ...(port !== null ? { port: port } : {}),
              alertType: type,
              level: level,
            },
          };
          break;
      }
    },
    [singleOrders]
  );

  const getCacheData = useCallback(() => {
    if (args.mpid !== undefined) {
      let mpid = (args.mpid as SelectOption).value as string;
      let ports = args.ports;

      const outboundRequests: outboundPayload = {};

      if (mpid === undefined) {
        return;
      }

      for (const type of enumKeys(BREACHES)) {
        if (!outboundRequests[BREACHES[type]]) {
          outboundRequests[BREACHES[type]] = {};
        }
        if (ports === undefined) {
          setData("singleOrder", { type: BREACHES[type], mpid, port: null }, outboundRequests);
        } else {
          ports.forEach((port: SelectOption) => {
            setData(
              "singleOrder",
              {
                type: BREACHES[type],
                mpid,
                port: port.value,
              },
              outboundRequests
            );
          });
        }
      }

      for (const type of enumKeys(EXPOSURE_BREACHES)) {
        if (!outboundRequests[EXPOSURE_BREACHES[type]]) {
          outboundRequests[EXPOSURE_BREACHES[type]] = {};
        }

        for (const level of enumKeys(EXPOSURE_LEVELS)) {
          if (ports === undefined) {
            setData(
              "exposure",
              {
                type: EXPOSURE_BREACHES[type],
                level: EXPOSURE_LEVELS[level],
                mpid,
                port: null,
              },
              outboundRequests
            );
          } else {
            ports.forEach((port: SelectOption) => {
              setData(
                "exposure",
                {
                  type: EXPOSURE_BREACHES[type],
                  level: EXPOSURE_LEVELS[level],
                  mpid,
                  port: port.value,
                },
                outboundRequests
              );
            });
          }
        }
      }

      const cacheKeys: string[] = [];
      Object.values(outboundRequests).forEach(breachPayload => {
        Object.keys(breachPayload).forEach(cacheKey => cacheKeys.push(cacheKey));
      });

      eqrcCacheDispatch([
        {
          type: "SET_CURRENTLY_USED_CACHE_KEYS",
          payload: { data: cacheKeys, view: Charts_Or_Table.charts },
        },
      ]);

      if (abort.current) {
        abort.current.abort();
      }

      // one request per tile type
      // use the same abort controller for all tiles
      const abortController = new AbortController();
      abort.current = abortController;

      Promise.allSettled(
        Object.entries(outboundRequests).map(([k, o]) => {
          return doFetch(
            formatUrl(
              user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.configApiCacheUrl],
              "cache/eqrc/config-api-cache/collection/range/eqrc-alert"
            ),
            {
              method: "post",
              mode: "cors",
              headers: getHeaders(),
              body: JSON.stringify(o),
              signal: abortController.signal,
            },
            () => {}
          );
        })
      )
        .then(results => {
          type ParsedResponse = {
            success: { singleOrder: { [typeMpidPort: string]: any }; exposure: {} };
            fail: number;
          };

          const parsed = results.reduce<ParsedResponse>(
            (acc, curr) => {
              if (isFulfilled(curr)) {
                if (curr?.value?.response?.ok) {
                  let parsedKey = "no";

                  const hasCollections = Object.keys(curr?.value?.json.collections).length > 0;
                  const collections: SingleOrderCollections = Object.entries(
                    curr?.value?.json.collections
                  );

                  if (hasCollections) {
                    collections.forEach(([k, v]) => {
                      parsedKey = k.split("-")?.[0];
                      if (parsedKey === "singleOrder") {
                        Object.assign(acc.success.singleOrder, {
                          [k]: v,
                        });
                      } else if (parsedKey === "exposure") {
                        Object.assign(acc.success.exposure, {
                          [k]: v,
                        });
                      }
                    });
                  }
                } else {
                  acc.fail++;
                }
              } else {
                // reason.code===20 is after calling abort on an xhr
                // we don't want to handle these as errors, we just ignore
                if (curr.status === "rejected" && curr.reason.code !== 20) {
                  acc.fail++;
                }
              }

              return acc;
            },
            { success: { singleOrder: {}, exposure: {} }, fail: 0 }
          );

          if (parsed.fail > 0) {
            onSingleOrderPollFail();

            // if we get ANY data back, process it,
            // if not, just set the flags
          } else if (
            Object.keys(parsed.success.singleOrder).length > 0 ||
            Object.keys(parsed.success.exposure).length > 0
          ) {
            onChartsPollSuccess(parsed.success, parsed.fail === 0);
          } else {
            eqrcCacheDispatch([
              {
                type: "SET_IS_REQUESTING",
                payload: { isRequesting: false, view: Charts_Or_Table.charts },
              },
              {
                type: "SET_SHOULD_MAKE_FIRST_REQUEST",
                payload: { view: Charts_Or_Table.charts, shouldMake: false },
              },
              {
                type: "SET_STATUS",
                payload: { status: Status.SUCCESS, view: Charts_Or_Table.charts },
              },
            ]);
          }
        })
        .catch(e => {
          console.error(e);
          onSingleOrderPollFail();
        });
    }
  }, [
    args.mpid,
    args.ports,
    setData,
    user,
    eqrcCacheDispatch,
    onSingleOrderPollFail,
    onChartsPollSuccess,
  ]);

  useEffect(() => {
    if (!isWindowFocused) {
      eqrcCacheDispatch([
        {
          type: "CLEAR_TIMEOUT",
          payload: { view: Charts_Or_Table.charts },
        },
        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { view: Charts_Or_Table.charts, shouldMake: true },
        },
        {
          type: "SET_IS_REQUESTING",
          payload: { view: Charts_Or_Table.charts, isRequesting: false },
        },
      ]);

      if (abort.current) {
        abort.current.abort();
      }
    }
  }, [isWindowFocused, eqrcCacheDispatch]);

  useEffect((): void => {
    if (globalError) {
      eqrcCacheDispatch({
        type: "SET_STATUS",
        payload: { view: Charts_Or_Table.charts, status: Status.ERROR },
      });
      return;
    }

    let mpid = args.mpid;
    // this needs to run when ports change, even though we don't directly use ports
    let ports = args.ports;

    if (mpid === undefined) {
      eqrcCacheDispatch({
        type: "SET_STATUS",
        payload: { view: Charts_Or_Table.charts, status: Status.NO_STATUS },
      });
    }

    if (!isRequesting && shouldRequest && mpid !== undefined && isWindowFocused) {
      if (shouldMakeFirstRequest) {
        getCacheData();
        eqrcCacheDispatch({
          type: "SET_IS_REQUESTING",
          payload: { view: Charts_Or_Table.charts, isRequesting: true },
        });
      } else {
        eqrcCacheDispatch([
          {
            type: "SET_REQUEST_TIMEOUT",
            payload: {
              view: Charts_Or_Table.charts,
              timeOut: setTimeout(() => {
                getCacheData();
                eqrcCacheDispatch({
                  type: "SET_IS_REQUESTING",
                  payload: { view: Charts_Or_Table.charts, isRequesting: true },
                });
              }, 8000),
            },
          },
        ]);
      }
    }
  }, [
    args.mpid,
    args.ports,
    eqrcCacheDispatch,
    getCacheData,
    globalError,
    isRequesting,
    isWindowFocused,
    shouldMakeFirstRequest,
    shouldRequest,
  ]);

  const eqrcFormFields = formData[Forms.EQRC_TOP.key].fields;
  const formMpid = eqrcFormFields[EqrcFields.mpid];
  const formPorts = eqrcFormFields[EqrcFields.port];
  useEffect(() => {
    if (formMpid === args.mpid && formPorts === args.ports) {
      return;
    }

    setArgs({
      mpid: formMpid,
      ports: formPorts,
    });

    eqrcCacheDispatch([
      {
        type: "SET_SHOULD_MAKE_FIRST_REQUEST",
        payload: { view: Charts_Or_Table.charts, shouldMake: true },
      },
      {
        type: "CLEAR_TIMEOUT",
        payload: { view: Charts_Or_Table.charts },
      },
    ]);

    if (abort.current) {
      abort.current.abort();
    }
  }, [args.mpid, args.ports, eqrcCacheDispatch, formMpid, formPorts]);
};
