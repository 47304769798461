import React, { useEffect, useReducer, createContext, useContext } from "react";
import { produce } from "immer";
import { safeParseJSON } from "utils/js.utils";
import { RequestResult } from "../../wksConstants";
import { SelectOptions } from "../fields";
import { useUserContext, INITIAL_DATA_MODEL } from "../user";
import { Views } from "../../viewConstants";
const layoutContextDispatch = createContext();
layoutContextDispatch.displayName = "LayoutDispatch";
export const useLayoutDispatch = () => {
  return useContext(layoutContextDispatch);
};

export const layoutContext = createContext();
layoutContext.displayName = "LayoutContext";
export const useLayoutContext = () => {
  return useContext(layoutContext);
};
const RISK = SelectOptions.rightViewsContainer.risk;
const STATS = SelectOptions.rightViewsContainer.stats;
const thinLeft = "300px";
const fatLeft = "570px";
const rightWidth = "308px";
const noWidth = "0px";

const defaultState = {
  isToggleRightDisabled: true,
  isRightOpen: false,
  rightView: RISK,
  isLeftExpanded: false,
  hideLeft: false,
  leftHasError: false,
  leftWidth: thinLeft,
  rightWidth: noWidth,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_TOGGLE_RIGHT_DISABLED": {
      return {
        ...state,
        isToggleRightDisabled: action.payload,
      };
    }
    case "DUMP_SETTINGS": {
      const { user } = action.payload;
      const userData = user[INITIAL_DATA_MODEL.userData];

      const isOpen =
        safeParseJSON(userData.find(item => item?.type === "rightPanelVisibility")?.data)
          .isVisible || state.isVisible;

      const rightView =
        safeParseJSON(userData.find(item => item?.type === "rightViewSelection")?.data)
          .rightViewSelection || state.rightView;

      let translatedView = RISK;
      translatedView =
        Object.values(SelectOptions.rightViewsContainer).find(v => v.label === rightView?.label) ||
        rightView;

      // don't allow loading of a right view if the user doesn't have the right permission
      if (translatedView === STATS && !user.allowed.views[Views.REAL_TIME_STATS]) {
        translatedView = RISK;
      } else if (translatedView === RISK && !user.allowed.views[Views.POST_TRADE_RISK]) {
        translatedView = STATS;
      }
      const shouldRightBeDisabled = !user.allowed.views[Views.RIGHT];
      return {
        ...state,
        isToggleRightDisabled: shouldRightBeDisabled,
        isRightOpen: !shouldRightBeDisabled && isOpen,
        rightWidth: !shouldRightBeDisabled && isOpen ? rightWidth : noWidth,
        rightView: translatedView || SelectOptions.rightViewsContainer.risk,
      };
    }
    case "SET_HIDE_LEFT": {
      return {
        ...state,
        hideLeft: action.payload,
        leftWidth: action.payload ? noWidth : state.isLeftExpanded ? fatLeft : thinLeft,
      };
    }
    case "TOGGLE_LEFT_SIDE": {
      return {
        ...state,
        isLeftExpanded: !state.isLeftExpanded,
        leftWidth: !state.isLeftExpanded ? fatLeft : thinLeft,
      };
    }
    case "SET_HIDE_RIGHT": {
      return produce(state, draft => {
        draft.isRightOpen = !action.payload;
        draft.rightWidth = action.payload ? noWidth : rightWidth;
      });
    }
    case "TOGGLE_RIGHT": {
      return produce(state, draft => {
        draft.isRightOpen = !state.isRightOpen;
        draft.rightWidth = !state.isRightOpen ? rightWidth : noWidth;
      });
    }
    case "SET_ENABLE_RIGHT": {
      const { disable } = action.payload;
      return produce(state, draft => {
        draft.isRightOpen = disable;
        draft.rightWidth = disable ? rightWidth : noWidth;
      });
    }
    case "SET_LEFT_ERRORED": {
      return {
        ...state,
        leftHasError: action.payload,
      };
    }
    case "SET_RIGHT_VIEW": {
      if (action.payload.view) {
        return { ...state, ...(action.payload.view && { rightView: action.payload.view }) };
      } else {
        return state;
      }
    }
    default:
      return { ...state };
  }
};

const LayoutProvider = ({ children, defaultData }) => {
  const [user] = useUserContext();
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.userData]
    ) {
      dispatchF({ type: "DUMP_SETTINGS", payload: { user } });
    } else if (user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.error) {
      dispatchF({ type: "SET_TOGGLE_RIGHT_DISABLED", payload: true });
    }
  }, [user]);

  return (
    <layoutContextDispatch.Provider value={dispatchF}>
      <layoutContext.Provider value={[state, dispatchF]}>{children}</layoutContext.Provider>
    </layoutContextDispatch.Provider>
  );
};

export default LayoutProvider;
