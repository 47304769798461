import React from "react";
import { Box } from "@nef/core";

const Middle = ({ children }) => {
  return (
    <Box
      style={{
        overflow: "hidden",
        // width: `calc(100vw - ${layoutData.leftWidth} - ${layoutData.rightWidth})`,
        width: "auto",
        boxSizing: "border-box",
      }}
      padding={3}
    >
      {children}
    </Box>
  );
};
export default Middle;
