import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  Button,
  Flex,
  NotificationHub,
  FontAwesomeIcon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Box,
  Tabs,
  Tab,
  CSSGrid,
} from "@nef/core";
import { network } from "./network";
import { Forms } from "components/fields/fieldConstants";
import { SettingsWrapper } from "./settingsWrapper";
import { useFormContext } from "components/form";
import { useSettingsContext, RiskSettingsUploadInput, PTR_UPLOAD_STATUS } from ".";
import { StandardHeader, StandardTable } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { useStandardTableContext } from "../standardTable";
import { DownloadMenuItem } from "components/upload";
import { formatUrl } from "../../utils/js.utils";
import { ErrorSuccessIssueCount, TabText } from "components/upload/constants";

export const RiskUploadSettings = ({ augmentOnChange }) => {
  const [tableData, tableDispatch] = useStandardTableContext();
  const [formData] = useFormContext();
  const [settings] = useSettingsContext();
  const [currentTabTable, setCurrentTabTable] = useState(StandardTables.RISK_UPLOAD_SUCCESSES);

  const handleClick = useCallback(() => document.getElementById("risk-file-upload").click(), []);
  const selectedRow = useMemo(() => {
    const returnValue = {};
    returnValue.index = Object.entries(tableData[StandardTables.RISK_UPLOAD_UPLOADS].selected).find(
      ([k, v]) => v === true
    )?.[0];
    returnValue.row = tableData[StandardTables.RISK_UPLOAD_UPLOADS].data[returnValue.index];
    returnValue.status = returnValue.row?.status;
    returnValue.fileName = returnValue.row?.file;

    return returnValue;
  }, [tableData]);

  const table = useMemo(() => {
    switch (selectedRow.status) {
      case PTR_UPLOAD_STATUS.Reject:
        return StandardTables.RISK_UPLOAD_REJECTS;
      case PTR_UPLOAD_STATUS.Success:
        return StandardTables.RISK_UPLOAD_SUCCESSES;
      case PTR_UPLOAD_STATUS.Partial:
        return currentTabTable ? currentTabTable : StandardTables.RISK_UPLOAD_SUCCESSES;
      case PTR_UPLOAD_STATUS.Validation:
      default:
        return StandardTables.RISK_UPLOAD_FILE_ISSUES;
    }
  }, [selectedRow.status, currentTabTable]);

  const exportFileName = useMemo(() => {
    let suffix = "_reject.csv";
    if (table === StandardTables.RISK_UPLOAD_SUCCESSES) {
      suffix = "_success.csv";
    } else if (table === StandardTables.RISK_UPLOAD_FILE_ISSUES) {
      suffix = "_validation.csv";
    }
    let name = undefined;
    if (selectedRow.fileName) {
      name = selectedRow.fileName.replace(".csv", "") + suffix;
    }
    return name;
  }, [table, selectedRow.fileName]);

  const additionalRowClick = useCallback(
    e => {
      if (e.row.original.status === "uploading") {
        NotificationHub.send("warning", "Please allow this upload to complete");
        return;
      }

      if (e.row.original.status === PTR_UPLOAD_STATUS.Error) {
        NotificationHub.send("danger", "There was an error uploading this file");
        return;
      }

      let whichTable = table;
      if (e.row.original.status === PTR_UPLOAD_STATUS.Validation) {
        whichTable = StandardTables.RISK_UPLOAD_FILE_ISSUES;
      } else {
        whichTable = StandardTables.RISK_UPLOAD_SUCCESSES;
      }

      const tableDispatches = [
        {
          type: "HANDLE_ROWS_SELECTED",
          payload: {
            table: StandardTables.RISK_UPLOAD_UPLOADS,
            rows: { [e.row.id.toString()]: true },
            isSingleSelect: true,
          },
        },
      ];

      if (whichTable !== StandardTables.RISK_UPLOAD_FILE_ISSUES) {
        // reduce the list of successes and rejects into two lists,
        // so we can populate both tables
        const [successes, rejects] = settings.riskConfigFilesData[
          e.row.original.id.toString()
        ].reduce(
          (acc, cur) => {
            cur.rejectText ? acc[1].push(cur) : acc[0].push(cur);
            return acc;
          },
          [[], []]
        );

        tableDispatches.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.RISK_UPLOAD_SUCCESSES,
            data: successes,
            allTheData: successes,
            isLoading: false,
          },
        });

        tableDispatches.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.RISK_UPLOAD_REJECTS,
            data: rejects,
            allTheData: rejects,
            isLoading: false,
          },
        });
      } else {
        tableDispatches.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.RISK_UPLOAD_FILE_ISSUES,
            data: settings.riskConfigFilesData[e.row.original.id.toString()],
            allTheData: settings.riskConfigFilesData[e.row.original.id.toString()],
            isLoading: false,
          },
        });
      }

      tableDispatch(tableDispatches);
    },
    [table, tableDispatch, settings.riskConfigFilesData]
  );

  const RightSide = () => {
    const [isOpen, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const toggle = useCallback(() => {
      setOpen(!isOpen);
    }, [setOpen, isOpen]);

    useEffect(() => {
      if (files?.length === 0) {
        const fileCallback = files => {
          setFiles(files);
        };
        const fileErrorCallback = console.log;
        network().getDocsList(fileCallback, fileErrorCallback);
      }
    }, [files]);

    return (
      <Flex alignItems="center">
        <Box marginRight={2}>
          <Button outline onClick={handleClick}>
            Upload
          </Button>
        </Box>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle size="md" color="primary" outline caret>
            <FontAwesomeIcon className="fa-file-download" style={{ marginRight: "8px" }} />
            Download Template
          </DropdownToggle>
          <DropdownMenu>
            {files.map(file => (
              <DownloadMenuItem
                key={file}
                filename={file}
                url={formatUrl(
                  process.env.REACT_APP_URL_LIMO_WS,
                  `getdownloadabledoc?fileName=${file}`
                )}
              />
            ))}
          </DropdownMenu>
        </Dropdown>
      </Flex>
    );
  };

  const HeaderFiles = useCallback(() => <StandardHeader title="Uploaded Files" />, []);
  const HeaderIssues = useCallback(
    () => (
      <TableHeader
        fileIssuesLength={tableData[StandardTables.RISK_UPLOAD_FILE_ISSUES].data.length}
        errorLength={tableData[StandardTables.RISK_UPLOAD_REJECTS].data.length}
        successLength={tableData[StandardTables.RISK_UPLOAD_SUCCESSES].data.length}
        handleClick={setCurrentTabTable}
        table={table}
      />
    ),
    [table, tableData]
  );
  return (
    <>
      <SettingsWrapper
        header="Post - Trade Risk Settings Upload"
        subheader="Upload Post - Trade Risk Settings in bulk"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        RightSide={<RightSide />}
        hasSave={false}
      >
        <RiskSettingsUploadInput />

        {/* the height of the tables is further specified below via style.height  */}
        <CSSGrid rows="3fr 7fr" gap="1rem">
          <StandardTable
            header={HeaderFiles}
            table={StandardTables.RISK_UPLOAD_UPLOADS}
            headerMenu={<></>}
            enableLayoutExport={false}
            id={"uploads"}
            style={{ height: "250px" }}
            additionalRowClick={additionalRowClick}
            isSingleSelect={true}
            isColumnsVirtualized={false}
            isFilterable={false}
          />
          <StandardTable
            header={HeaderIssues}
            table={table}
            multiSelectRows={false}
            isColumnsVirtualized={table !== StandardTables.RISK_UPLOAD_FILE_ISSUES}
            headerMenu={<></>}
            enableLayoutExport={table !== StandardTables.RISK_UPLOAD_FILE_ISSUES}
            useIdAsHeaders={true}
            exportFileName={exportFileName}
            id={"rows"}
          />
        </CSSGrid>
      </SettingsWrapper>
    </>
  );
};

const TableHeader = ({ fileIssuesLength, successLength, errorLength, handleClick, table }) => {
  const onClick = useCallback(table => () => handleClick(table), [handleClick]);
  return (
    <Tabs style={{ marginTop: 0 }}>
      {table === StandardTables.RISK_UPLOAD_FILE_ISSUES && (
        <Tab active={true} onClick={onClick(StandardTables.RISK_UPLOAD_FILE_ISSUES)}>
          <ErrorSuccessIssueCount color="warning">{fileIssuesLength}</ErrorSuccessIssueCount>
          <TabText>File Issues</TabText>
        </Tab>
      )}
      {(table === StandardTables.RISK_UPLOAD_SUCCESSES ||
        table === StandardTables.RISK_UPLOAD_REJECTS) && (
        <>
          <Tab
            active={table === StandardTables.RISK_UPLOAD_REJECTS}
            onClick={onClick(StandardTables.RISK_UPLOAD_REJECTS)}
          >
            <ErrorSuccessIssueCount color="danger">{errorLength}</ErrorSuccessIssueCount>
            <TabText>Errors</TabText>
          </Tab>

          <Tab
            active={table === StandardTables.RISK_UPLOAD_SUCCESSES}
            onClick={onClick(StandardTables.RISK_UPLOAD_SUCCESSES)}
          >
            <ErrorSuccessIssueCount color="success">{successLength}</ErrorSuccessIssueCount>
            <TabText>Success</TabText>
          </Tab>
        </>
      )}
    </Tabs>
  );
};
