import { byMpid, bySymbol } from "components/pvRejects/finra/constant";
import {
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  usePORCacheContext,
} from "../context";
import { DT } from "./dataArray";
import { HeaderBox } from "./tile.container";
import { TileFront } from "./tile.front";
import { symbolDataArray, toDataArray } from "../fakeData";
import { REJECTS_BY_MPID, REJECTS_BY_SYMBOL } from "components/pvRejects/member/constant";

export const Front = ({
  toggle,
  isMpidOrSymbol,
}: {
  toggle: () => void;
  isMpidOrSymbol: Mpid_Or_Security;
}) => {
  const { state } = usePORCacheContext();

  return (
    <TileFront
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={false}
          hasMpidOrSymbol={false}
          isMpidOrSymbol={isMpidOrSymbol}
          total={state[PorOrPvr.PVR].total}
          isPorOrPvr={PorOrPvr.PVR}
          title={"Rejects"}
        />
      }
      title1="By Symbol"
      title2="By MPID"
      data1={
        <DT data={symbolDataArray(REJECTS_BY_SYMBOL)} isMpidOrSymbol={MpidOrSymbol.SECURITY} />
      }
      data2={<DT data={symbolDataArray(REJECTS_BY_MPID)} isMpidOrSymbol={MpidOrSymbol.MPID} />}
    />
  );
};
