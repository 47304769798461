import React, { useCallback, useEffect, useMemo, memo } from "react";
import { StandardHeader } from "components/standardTable";
import { TabTable } from "components/tabTable";
import { useUserContext } from "components/user";
import { StandardTables, Status } from "wksConstants";
import { HeldTableButtons, LimoLVCIndicator } from ".";
import { getTabsForHKTable } from "./constants";
import { useStandardTableDispatch } from "../standardTable";
import { useHKCacheContext, useHKCacheDispatch } from "./heldKilledCache";
import { useTabTableDispatch } from "../tabTable";
import { useRefDataContext } from "components/refData";
import { ApiResponseNames } from "../fields/fieldConstants";
import { ViewActions } from "../../viewConstants";
import { CSSGrid } from "@nef/core";

export const HeldTable = memo(() => {
  const [user] = useUserContext();
  const [hkCache] = useHKCacheContext();
  const hkCacheDispatch = useHKCacheDispatch();
  const standardTableDispatch = useStandardTableDispatch();
  const tabTableDispatch = useTabTableDispatch();
  const [refData] = useRefDataContext();

  useEffect(() => {
    let type = "SET_LOADING";
    if (!hkCache.isHeldLoading) {
      type = "SET_NOT_LOADING";
    }
    standardTableDispatch({
      type,
      payload: { table: StandardTables.CV_HELD },
    });
  }, [hkCache.isHeldLoading, standardTableDispatch]);

  useEffect(() => {
    if (Object.keys(hkCache.counts).length > 0 && !hkCache.isLoading && !hkCache.shouldAbort) {
      const { active, tabs } = getTabsForHKTable(
        ApiResponseNames.activeHeldId,
        ApiResponseNames.heldTrades,
        ApiResponseNames.heldCount,
        hkCache,
        refData
      );
      tabTableDispatch({
        type: "POST_TRADE_RISK_SUCCESS",
        payload: { table: StandardTables.CV_HELD, tabs, active },
      });
    } else if (Object.keys(hkCache.counts).length === 0) {
      tabTableDispatch({
        type: "RESET_TABLE",
        payload: {
          table: StandardTables.CV_HELD,
        },
      });
    }
  }, [
    hkCache.heldTrades,
    hkCache.isLoading,
    standardTableDispatch,
    tabTableDispatch,
    hkCache.counts,
    refData.clearing,
    hkCache.activeHeldId,
    hkCache.shouldAbort,
    hkCache,
    refData,
  ]);

  const handleTabClick = useCallback(
    id => {
      hkCacheDispatch([
        { type: "SET_ACTIVE_HELD_ID_AND_ABORT", payload: id },
        { type: "SET_HELD_LOADING", payload: true },
      ]);
      standardTableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.CV_HELD },
      });
    },
    [hkCacheDispatch, standardTableDispatch]
  );

  const heldHeaderMenu = useMemo(() => {
    if (!user.allowed.actions[ViewActions.PTR_TRADES]) {
      return undefined;
    } else {
      return <HeldTableButtons />;
    }
  }, [user.allowed.actions]);

  const heldTableStyle = useMemo(() => {
    return { gridColumn: "1 / 3" };
  }, []);

  const indicatorStatus = useMemo(() => {
    if (refData.clearing !== null) {
      return hkCache.status;
    }
    return Status.ERROR;
  }, [hkCache, refData.clearing]);

  const Header = useCallback(
    () => (
      <StandardHeader
        title={
          <CSSGrid cols="auto auto" gap="1rem" alignItems="center">
            Held
            <LimoLVCIndicator id="risk-held-data-feed-status" status={indicatorStatus} />
          </CSSGrid>
        }
      />
    ),
    [indicatorStatus]
  );
  return (
    <TabTable
      table={StandardTables.CV_HELD}
      header={Header}
      enableLayoutExport={true}
      headerMenu={heldHeaderMenu}
      showRowCountOnTab={false}
      onTabClick={handleTabClick}
      hideRowCount={true}
      hideQueryDate={true}
      style={heldTableStyle}
    />
  );
});
