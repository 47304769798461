import { NotificationHub } from "@nef/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { doAction } from "components/standardTable/network";
import { useUserContext } from "components/user";
import { StandardTables } from "wksConstants";
import { ViewActions } from "../../viewConstants";
import { FirmType, TableButtonAction, TableButtonLabel } from "../../wksConstants";
import { useRefDataContext, CLEARING_DATA_MODEL, FIRM_DATA_MODEL } from "../refData";
import { TableButtons, useStandardTableDispatch, TradesHeader } from "../standardTable";
import { useHKCacheContext, useHKCacheDispatch } from "./heldKilledCache";
import { getEntryMPIDsFromHeldRecord } from "./constants";

export const HeldTableButtons = () => {
  const [user] = useUserContext();
  const [isAllowLoading, setAllowLoading] = useState(false);
  const [isInhibitLoading, setInhibitLoading] = useState(false);
  const [refData] = useRefDataContext();
  const [hkData] = useHKCacheContext();
  const hkCacheDispatch = useHKCacheDispatch();
  const standardTableDispatch = useStandardTableDispatch();

  const { activeMPID, firmData } = useMemo(() => {
    if (Array.isArray(refData.clearing)) {
      return refData.clearing.reduce(
        (acc, curr) => {
          if (!acc.firmData[curr[CLEARING_DATA_MODEL.clearingNum]]) {
            acc.firmData[curr[CLEARING_DATA_MODEL.clearingNum]] = {
              [FIRM_DATA_MODEL.firmMPID]: curr[CLEARING_DATA_MODEL.clearingMPID],
              [FIRM_DATA_MODEL.clearingNum]: curr[CLEARING_DATA_MODEL.clearingNum],
              [FIRM_DATA_MODEL.firmType]: FirmType.CLEARING,
            };
          }
          if (curr[CLEARING_DATA_MODEL.id].toString() === hkData.activeHeldId?.toString()) {
            acc.activeMPID = curr[CLEARING_DATA_MODEL.correspondentMPID];
          }
          return acc;
        },
        { activeMPID: null, firmData: {} }
      );
    }
    return { activeMPID: null, firmData: null };
  }, [refData.clearing, hkData]);

  const handleSuccess = useCallback(() => {
    hkCacheDispatch([
      {
        type: "SET_SHOULD_ABORT",
        payload: true,
      },
      {
        type: "SET_LOADING",
        payload: true,
      },
    ]);
    standardTableDispatch([
      {
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.CV_HELD },
      },
      {
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.CV_KILL_REJECT },
      },
    ]);
  }, [hkCacheDispatch, standardTableDispatch]);

  const handleAllow = useCallback(
    async (e, { valid }) => {
      if (Array.isArray(valid) && valid.length) {
        setAllowLoading(true);
        const noop = () => {};

        const validBtFlagValues = ["B", "S"];
        const requestBodies = [];
        valid.forEach(trade => {
          const { btExecFlag, btContraFlag, executingClearingNum, contraClearingNum } = trade;
          let isFound = false;
          const { execEntryMPID, contraEntryMPID } = getEntryMPIDsFromHeldRecord(trade);
          if (activeMPID === execEntryMPID) {
            if (firmData[executingClearingNum] && validBtFlagValues.includes(btExecFlag)) {
              const execBody = {
                ...trade,
                mpid: firmData[executingClearingNum][FIRM_DATA_MODEL.firmMPID],
              };
              requestBodies.push(execBody);
              isFound = true;
            }
          }

          if (activeMPID === contraEntryMPID && !isFound) {
            if (firmData[contraClearingNum] && validBtFlagValues.includes(btContraFlag)) {
              const contraBody = {
                ...trade,
                mpid: firmData[contraClearingNum][FIRM_DATA_MODEL.firmMPID],
              };
              requestBodies.push(contraBody);
            }
          }
        });
        const { success, fail } = await doAction(
          TableButtonAction.ALLOW,
          requestBodies,
          noop,
          noop,
          TableButtonLabel.ALLOW
        );
        setAllowLoading(false);

        if (success.length) {
          NotificationHub.send("success", `${success.length} Trade(s) Allowed Successfully`, {
            subtitle: `Control#: ${success.controlNums.join(", ")}`,
          });
          handleSuccess();
        }

        if (fail.length) {
          NotificationHub.send("danger", `Failed to Allow ${fail.length} Trade(s)`, {
            subtitle: `Control#: ${fail.controlNums.join(", ")}`,
          });
        }
      }
    },
    [activeMPID, firmData, handleSuccess]
  );
  const handleInhibit = useCallback(
    async (e, { valid }) => {
      if (Array.isArray(valid) && valid.length) {
        setInhibitLoading(true);
        const noop = () => {};

        const validBtFlagValues = ["B", "S"];
        const requestBodies = [];
        valid.forEach(trade => {
          const { btExecFlag, btContraFlag, executingClearingNum, contraClearingNum } = trade;
          let isFound = false;
          const { execEntryMPID, contraEntryMPID } = getEntryMPIDsFromHeldRecord(trade);
          if (activeMPID === execEntryMPID) {
            if (firmData[executingClearingNum] && validBtFlagValues.includes(btExecFlag)) {
              const execBody = {
                ...trade,
                mpid: firmData[executingClearingNum][FIRM_DATA_MODEL.firmMPID],
              };
              requestBodies.push(execBody);
              isFound = true;
            }
          }

          if (activeMPID === contraEntryMPID && !isFound) {
            if (firmData[contraClearingNum] && validBtFlagValues.includes(btContraFlag)) {
              const contraBody = {
                ...trade,
                mpid: firmData[contraClearingNum][FIRM_DATA_MODEL.firmMPID],
              };
              requestBodies.push(contraBody);
            }
          }
        });
        const { success, fail } = await doAction(
          TableButtonAction.INHIBIT,
          requestBodies,
          noop,
          noop,
          TableButtonLabel.INHIBIT
        );
        setInhibitLoading(false);
        if (success.length) {
          NotificationHub.send("success", `${success.length} Trade(s) Inhibited Successfully`, {
            subtitle: `Control#: ${success.controlNums.join(", ")}`,
          });
          handleSuccess();
        }

        if (fail.length) {
          NotificationHub.send("danger", `Failed to Inhibit ${fail.length} Trade(s)`, {
            subtitle: `Control#: ${fail.controlNums.join(", ")}`,
          });
        }
      }
    },
    [activeMPID, firmData, handleSuccess]
  );

  const getTableButtons = useCallback(() => {
    if (!user.allowed.actions[ViewActions.PTR_TRADES]) {
      return [];
    } else {
      return [
        {
          icon: "paper-plane",
          text: TableButtonLabel.ALLOW,
          actionId: TableButtonAction.ALLOW,
          canConfirm: true,
          allowConfirm: true,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handleAllow,
          loading: isAllowLoading,
          header: TradesHeader,
        },
        {
          icon: "times",
          text: TableButtonLabel.INHIBIT,
          actionId: TableButtonAction.INHIBIT,
          canConfirm: true,
          allowConfirm: true,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handleInhibit,
          loading: isInhibitLoading,
          header: TradesHeader,
        },
      ];
    }
  }, [user.allowed.actions, handleAllow, isAllowLoading, handleInhibit, isInhibitLoading]);

  const [tableButtons, setTableButtons] = useState(getTableButtons());

  useEffect(() => setTableButtons(getTableButtons()), [getTableButtons]);

  return <TableButtons table={StandardTables.CV_HELD} buttons={tableButtons} />;
};
