import React, { useRef } from "react";
import { FieldLoop } from "components/fields";
import styled from "styled-components";
import { EqrcExchangeMpidPort } from "./exchangeMpidPort";

const numCols = 12;
const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(${numCols}, 1fr);
  grid-gap: 1rem;
  align-items: center;
`;

const Divider = styled.div`
  grid-column: span ${numCols};
`;

const SectionHeader = styled.h5`
  font-size: 1rem;
  font-weight: bold;
  grid-column: span 6;
  margin: 0;
`;

export const EQRCForm = ({ fieldSets, form, includeExchMpidPort, disableMpidPort = false }) => {
  return (
    <Form>
      {includeExchMpidPort ? (
        <>
          <EqrcExchangeMpidPort form={form} isDisabled={disableMpidPort} />
          <Divider />
        </>
      ) : (
        <></>
      )}
      {fieldSets?.map(({ fields, header }, index) => (
        <React.Fragment key={`${form}_${index}`}>
          {header && <SectionHeader>{header}</SectionHeader>}
          <FieldLoop
            style={{ marginRight: "0px !important" }}
            isReactFragment={true}
            form={form}
            fields={fields}
          />
          {index < fieldSets.length - 1 ? <Divider /> : <></>}
        </React.Fragment>
      ))}
    </Form>
  );
};
