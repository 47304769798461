import momentTz from "moment-timezone";
import moment from "moment";

export const EST_TIMEZONE = momentTz.tz("America/New_York").format("Z");

export const FROM_DATE_SUFFIX = `T00:00:00.000${EST_TIMEZONE}`;
export const TO_DATE_SUFFIX = `T23:59:59.999${EST_TIMEZONE}`;
type DateSuffix = typeof FROM_DATE_SUFFIX | typeof TO_DATE_SUFFIX;
const DateFmt = "YYYY-MM-DD";

export const formatDateForRequest = (date: string | moment.Moment, suffix: DateSuffix) => {
  if (typeof date === "string") {
    return moment(date).format(`${DateFmt}${suffix}`);
  }
  if (typeof date === "object") {
    return date.format(`${DateFmt}${suffix}`);
  }
  return undefined;
};
