import { NotificationHub } from "@nef/core";
import { useViewModelDispatch } from "components/context";
import { Forms } from "components/fields/fieldConstants";
import { useFormDispatch } from "components/form";
import React, { useCallback, useMemo } from "react";
import {
  getNumSelectedRows,
  getSelectedRow,
  TableButton,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { getIsButtonDisabled } from "components/standardTable/buttons/util";
import { Messages, StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { convertUploadTableToForm, network } from ".";
import { ViewActions } from "../../viewConstants";
import { useUserContext } from "../user";

const repairButtonConfig = {
  requireSelect: true,
  allowMultiSelect: false,
  icon: "tools",
  text: TableButtonLabel.REPAIR,
  actionId: TableButtonAction.REPAIR,
  disabledMessage: Messages.ONE_ROW,
};

export const UploadRepairButton = ({ table }) => {
  const standardTableDispatch = useStandardTableDispatch();
  const formDispatch = useFormDispatch();
  const viewDispatch = useViewModelDispatch();
  const [standardTableData] = useStandardTableContext();
  const [userData] = useUserContext();

  const numSelected = useMemo(() => {
    return getNumSelectedRows(standardTableData[table]);
  }, [table, standardTableData]);

  const handleRepair = useCallback(() => {
    standardTableDispatch([
      {
        type: "SET_LOADING",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
        },
      },
      {
        type: "SET_LOADING",
        payload: {
          table: StandardTables.UPLOAD_FILES,
        },
      },
    ]);

    const stopLoading = () => {
      standardTableDispatch([
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_FILES,
          },
        },
      ]);
    };
    const original = getSelectedRow(standardTableData[StandardTables.UPLOAD_ERRORS]);
    if (original.status !== "R") {
      NotificationHub.send("warning", "This trade was ignored due to invalid state", {
        subtitle: `Control#: ${original.controlNum ? original.controlNum : "N/A"}`,
      });
      stopLoading();
      return;
    }
    if (original.recordType !== "T") {
      NotificationHub.send("warning", "Cannot repair trades with this record type", {});
      stopLoading();
      return;
    }
    const tradeCallback = (data, uploadDetailId) => {
      viewDispatch({
        type: "ENABLE_FORM",
        payload: { form: Forms.UPLOAD_REPAIR },
      });
      const fields = {
        ...convertUploadTableToForm(data),
        workstationId: data.workstationID,
        userId: data.workstationUserId?.value,
        uploadDetailId,
        objVer: data.objver,
      };
      stopLoading();
      formDispatch({
        type: "SET_FORM_VALUES",
        payload: {
          form: Forms.UPLOAD_REPAIR,
          fields,
        },
      });
      standardTableDispatch({
        type: "SET_CLICKED_ROW",
        payload: { row: original, table },
      });
    };
    const tradeErrorCallback = reject => {
      let message = "Something went wrong when fetching details for the selected trade.";
      if (reject?.message) {
        message = reject.message;
      }
      NotificationHub.send("danger", message);
      stopLoading();
    };
    network().getRejectedTrade(original, tradeCallback, tradeErrorCallback);
  }, [standardTableDispatch, standardTableData, viewDispatch, formDispatch, table]);

  if (!userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
    return <></>;
  }
  return (
    <TableButton
      table={table}
      {...repairButtonConfig}
      disabled={getIsButtonDisabled(repairButtonConfig, numSelected)}
      onClick={handleRepair}
      key="tableButton_repair"
    />
  );
};
