import React, { memo, useCallback, useMemo } from "react";
import { useStandardTableDispatch } from ".";
import ColumnSelector from "@nef/core/lib/components/RichTable/components/ColumnSelector";
import { useViewModelContext } from "components/context";
import { ErrorBoundary } from "react-error-boundary";
import { NotificationHub } from "@nef/core";
import _ from "lodash";

export const ColWrapper = memo(
  ({ table, toggleFn, isOpen, hiddenColumns, currentColumnSet, columnsOrder, allColumns }) => {
    const standardTableDispatch = useStandardTableDispatch();
    const [layoutData] = useViewModelContext();

    const derivedTable = useMemo(() => {
      return table ? table : layoutData.activeTable;
    }, [table, layoutData.activeTable]);

    const onColumnOrder = useCallback(
      columns => {
        standardTableDispatch({
          type: "SET_CURRENT_COLUMNS_ORDER",
          payload: { table: derivedTable, columns },
        });
      },
      [standardTableDispatch, derivedTable]
    );

    const onShowHideColumns = useCallback(
      columns => {
        standardTableDispatch({
          type: "SET_CURRENT_COLUMNS",
          payload: {
            table: derivedTable,
            columns: Array.isArray(columns) ? columns : columns(hiddenColumns),
          },
        });
      },
      [standardTableDispatch, derivedTable, hiddenColumns]
    );

    const debounced = _.debounce(id => {
      standardTableDispatch({
        type: "ADD_TO_CURRENT_COLUMNS",
        payload: { table: derivedTable, id },
      });
    }, 1);

    const toggleHideColumn = useCallback(debounced, [debounced]);

    const onError = useCallback(
      error => {
        NotificationHub.send("warning", "Default Column Set Loaded", {
          subtitle: `Unable to load Saved Column Set '${currentColumnSet}'. Please remove Saved Column Set and recreate`,
        });

        standardTableDispatch([
          {
            type: "LOAD_DEFAULT_COLUMN_SET",
            payload: { table: derivedTable },
          },
        ]);
      },
      [currentColumnSet, derivedTable, standardTableDispatch]
    );

    const resetKeys = useMemo(() => {
      return [currentColumnSet];
    }, [currentColumnSet]);

    return (
      <ErrorBoundary FallbackComponent={Fallback} resetKeys={resetKeys} onError={onError}>
        <ColumnSelector
          toggle={toggleFn}
          isOpen={isOpen}
          columns={allColumns}
          hiddenColumns={hiddenColumns}
          columnOrder={columnsOrder}
          setColumnOrder={onColumnOrder}
          setHiddenColumns={onShowHideColumns}
          toggleHideColumn={toggleHideColumn}
        />
      </ErrorBoundary>
    );
  }
);

// if the column selector throws, just don't render it
const Fallback = memo(({ error, table, resetErrorBoundary }) => {
  return <></>;
});
