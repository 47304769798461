import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { SettingsWrapper } from "components/settings";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import TableButtons from "components/standardTable/tableButtons";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useMemo } from "react";
import { formatUrl } from "utils/js.utils";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { KeycloakExportDTO } from "./constants";
import moment from "moment-timezone";

export const KeycloakExportView = () => {
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();

  const handleError = useCallback(() => {
    NotificationHub.send("danger", "Error retrieving data for the keycloak user report", {
      subtitle: "Use the refresh button to try again",
    });
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.KEYCLOAK_EXPORT, isLoading: false },
    });
  }, [tableDispatch]);

  const handleSuccess = useCallback(
    (dto: KeycloakExportDTO) => {
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.KEYCLOAK_EXPORT, data: dto.keycloakExportData },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: {
            table: StandardTables.KEYCLOAK_EXPORT,
            lastQueryDate: moment(new Date(dto.createdAt)),
          },
        },
        {
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.KEYCLOAK_EXPORT, isLoading: false },
        },
      ]);
    },
    [tableDispatch]
  );

  const makeRequest = useCallback(() => {
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.KEYCLOAK_EXPORT, isLoading: true },
    });
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_QUERY_WS, "keycloak-export"),
      {
        headers: getHeaders(),
        mode: "cors",
      },
      handleSuccess,
      handleError
    );
  }, [handleError, handleSuccess, tableDispatch]);

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  const headerMenu = useMemo(() => {
    const buttons = [
      {
        icon: "redo",
        text: TableButtonLabel.REFRESH,
        actionId: TableButtonAction.KEYCLOAK_REFRESH,
        onClick: makeRequest,
        confirm: false,
        requireSelect: false,
      },
    ];
    return <TableButtons table={StandardTables.KEYCLOAK_EXPORT} buttons={buttons} />;
  }, [makeRequest]);

  const Header = useCallback(() => <StandardHeader title="Keycloak Users" />, []);
  return (
    <>
      <SettingsWrapper
        header="Keycloak Export"
        subheader="View and export WorkX users from keycloak"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
        headerMenu={undefined}
        RightSide={undefined}
        tabs={undefined}
      >
        <StandardTable
          header={Header}
          table={StandardTables.KEYCLOAK_EXPORT}
          enableLayoutExport={true}
          exportCallback={undefined}
          isSingleSelect={false}
          isColumnsVirtualized={false}
          isFilterable={true}
          style={undefined}
          subHeader={undefined}
          hideRowCount={false}
          hideSelectedCount={true}
          hideQueryDate={false}
          headerMenu={headerMenu}
          exportFileName={undefined}
          additionalRowClick={undefined}
          isEditableTableEnabled={undefined}
          isRowSelectEnabled={undefined}
          Header={undefined}
        />
      </SettingsWrapper>
    </>
  );
};
