import React, { useCallback } from "react";
import { useStandardTableDispatch } from "../standardTable";
import { StandardTables } from "wksConstants";

import { network } from "./network";
import { useSettingsDispatch } from "./settingsContext";
import { AckTypes } from "../../wksConstants";
import { NotificationHub } from "@nef/core";
import { PTR_UPLOAD_STATUS } from ".";
import { getConvertedRiskLimitsForUI } from "components/limitMonitor/constants";

const uuidGen = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const RiskSettingsUploadInput = () => {
  const tableDispatch = useStandardTableDispatch();
  const settingsDispatch = useSettingsDispatch();

  const clearInput = useCallback(e => (e.currentTarget.value = ""), []);

  const successCallback = useCallback(
    ({ data, status }, uuid) => {
      let uploadStatus = PTR_UPLOAD_STATUS.Validation;
      if (status === AckTypes.FAILED) {
        NotificationHub.send("danger", "Uploaded file did not pass validation");
        uploadStatus = PTR_UPLOAD_STATUS.Validation;
      } else if (status === AckTypes.REJECT) {
        NotificationHub.send("danger", "Error processing upload file");
        uploadStatus = PTR_UPLOAD_STATUS.Error;
      } else {
        data = data.map(dto => getConvertedRiskLimitsForUI(dto));
        const rejects = data
          .filter(dto => dto.rejectText)
          .map(dto => `${dto.clearingFirmNum} - ${dto.correspondentMPID}`);

        if (rejects.length > 0) {
          if (rejects.length < data.length) {
            uploadStatus = PTR_UPLOAD_STATUS.Partial;
            NotificationHub.send("danger", "Some records rejected from file upload", {
              subtitle: rejects.length ? `${rejects.length} Rejected Rows` : "",
            });
          } else {
            uploadStatus = PTR_UPLOAD_STATUS.Reject;
            NotificationHub.send("danger", "All records rejected from file upload ", {
              subtitle: rejects.length ? `${rejects.length} Rejected Rows` : "",
            });
          }
        } else {
          uploadStatus = PTR_UPLOAD_STATUS.Success;
          NotificationHub.send("success", "File upload complete");
        }
      }

      settingsDispatch({ type: "ADD_TO_RISK_CONFIG_FILE_TABLE", payload: { id: uuid, data } });
      tableDispatch({
        type: "UPDATE_CELLS",
        payload: {
          table: StandardTables.RISK_UPLOAD_UPLOADS,
          key: { key: "id", value: uuid },
          values: [{ key: "status", value: uploadStatus }],
        },
      });
    },
    [tableDispatch, settingsDispatch]
  );

  const errorCallback = useCallback(
    (row, uuid) => {
      tableDispatch({
        type: "UPDATE_CELLS",
        payload: {
          key: { key: "id", value: uuid },
          values: [
            { key: "status", value: "Error" },
            { key: "statusText", value: row.rejectText },
          ],
          table: StandardTables.RISK_UPLOAD_UPLOADS,
        },
      });
    },
    [tableDispatch]
  );

  const handleFileUpload = useCallback(
    e => {
      const uuid = uuidGen();

      const tableDispatches = [
        {
          type: "ADD_TABLE_DATA",
          payload: {
            table: StandardTables.RISK_UPLOAD_UPLOADS,
            data: [{ status: "Uploading", file: e.target.files[0].name, id: uuid }],
          },
        },
      ];

      tableDispatch(tableDispatches);

      network({ uuid }).uploadRiskSettings(e.target.files[0], successCallback, errorCallback);
    },
    [tableDispatch, successCallback, errorCallback]
  );
  return (
    <input
      id="risk-file-upload"
      type="file"
      accept=".csv"
      style={{ display: "none" }}
      onClick={clearInput}
      onChange={handleFileUpload}
    />
  );
};
