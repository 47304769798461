import React, { useMemo } from "react";
import { TableButton } from "components/standardTable";
import { Messages, TableButtonAction, TableButtonLabel } from "wksConstants";
import { getIsButtonDisabled } from "./util";
import { getNumSelectedRows, useStandardTableContext } from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { useIsConfirmTableHotButtons } from "./";

export const ReverseButton = ({ table }) => {
  const [standardTableData] = useStandardTableContext();
  const reverseButtonConfig = {
    icon: "history",
    text: TableButtonLabel.REVERSE,
    actionId: TableButtonAction.REVERSE,
    canConfirm: true,
    allowConfirm: useIsConfirmTableHotButtons(),
    requireSelect: true,
    allowMultiSelect: true,
    disabledMessage: Messages.MULTI_ROW,
    header: TradesHeader,
  };
  const numSelected = useMemo(() => {
    return getNumSelectedRows(standardTableData[table]);
  }, [table, standardTableData]);

  return (
    <TableButton
      table={table}
      {...reverseButtonConfig}
      disabled={getIsButtonDisabled(reverseButtonConfig, numSelected)}
      key="tableButton_reverse"
    />
  );
};
