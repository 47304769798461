import React, { useState, useCallback } from "react";
import { Tag, Tooltip } from "@nef/core";

const tagStyle = { marginRight: "5px", height: "16px" };

export const TradeRecapTag = ({ id, reportingPartyValue, isContractPrice }) => {
  const [text] = useState(reportingPartyValue === "M" ? "EPR" : "CR");
  const [type] = useState(reportingPartyValue === "M" ? "warning" : "success");
  const [tooltipText] = useState(
    reportingPartyValue === "M" ? "Executing Party Reported" : "Contra Party Reported"
  );
  const [isOpen, setOpen] = useState(false);

  const handleMouseOver = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleMouseOut = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (isContractPrice) {
    return (
      <div id={`tradeRecapTagCP_${id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <Tag style={tagStyle} color={"secondary"}>
          {/* <span
          id={`tradeRecapTagCP_${id}`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        > */}
          CP
          {/* </span> */}
          <Tooltip target={`tradeRecapTagCP_${id}`} isOpen={isOpen}>
            Contract Price
          </Tooltip>
        </Tag>
      </div>
    );
  }

  if (reportingPartyValue !== "M" && reportingPartyValue !== "O") {
    return <></>;
  }

  return (
    <div id={`tradeRecapTagRP_${id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Tag style={tagStyle} color={type}>
        {/* <span
        id={`tradeRecapTagRP_${id}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      > */}
        {text}
        {/* </span> */}
        <Tooltip target={`tradeRecapTagRP_${id}`} isOpen={isOpen}>
          {tooltipText}
        </Tooltip>
      </Tag>
    </div>
  );
};
