import React from "react";
import { CSSGrid, Box } from "@nef/core";
import { useLayoutContext } from "../context";
import styled from "styled-components";

const StyledGrid = styled(CSSGrid)`
  height: unset;
`;

// This is the container for everything that isn't nav
const ContentWrapper = ({ children }) => {
  const [layoutData] = useLayoutContext();
  const leftColumnWidth = `${layoutData.leftWidth === "0px" ? "" : "auto"}`;
  const middle = "1fr";
  const rightColumnWidth = `${layoutData.rightWidth}`;
  // should move all of these width const to the layout context so they are a shared var
  // // let LeftColWidth = isLeftExpanded ? "594px" : "311px";
  // const RightColWidth = isRightOpen ? "308px" : "0px";
  // const cols = hideLeft
  //   ? `calc(100vw - ${RightColWidth}) ${RightColWidth}`
  //   : `auto auto ${RightColWidth}`;
  // const cols = `${layoutData.leftWidth} calc(100vw - ${layoutData.leftWidth} - ${layoutData.rightWidth}) ${layoutData.rightWidth}`;
  return (
    <Box
      style={{
        marginTop: "var(--top-bar-offset)",
        height: "calc(100vh - var(--top-bar-offset))",
        display: "flex",
      }}
    >
      <StyledGrid cols={`${leftColumnWidth} ${middle} ${rightColumnWidth}`} rows="100%">
        {children}
      </StyledGrid>
    </Box>
  );
};

export default ContentWrapper;
