import { Flex, Box, Progress, Header } from "@nef/core";
import { colors } from "colors";
import React, { memo, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { LayoutExportButtons, useStandardTableContext } from ".";

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  width: "100%",
};

const headerMenuStyle = {
  borderRight: `1px solid ${colors.lightgray}`,
  marginLeft: "1rem",
  height: "32px",
};

const width100 = { width: "100%", maxWidth: "100%" };

const formatLastQueryDate = momentDate => {
  return momentDate?.format("MM/DD/YYYY HH:mm:ss");
};
const StyledProgress = styled(Progress)`
  width: calc(100% - 600px);
`;
export const StandardTableHeader = memo(
  ({
    Header,
    header,
    subHeader,
    table,
    headerMenu,
    enableLayoutExport,
    useIdAsHeaders,
    exportCallback,
    exportFileName,
    hideRowCount,
    hideSelectedCount,
    hideQueryDate,
    isExpandingRowsEnabled,
  }) => {
    const [standardTableData] = useStandardTableContext();
    const selectedColumnSet = standardTableData[table].currentColumnSet
      ? `| Layout: ${standardTableData[table].currentColumnSet.slice(0, 20)}${
          standardTableData[table].currentColumnSet.length > 20 ? "..." : ""
        }`
      : "";

    const selectedRowLength = Object.keys(standardTableData[table].selected).length;

    const tableDataLength = useMemo(() => {
      if (!isExpandingRowsEnabled) {
        return Object.keys(standardTableData[table]?.data).length;
      } else {
        let count = 0;

        Object.values(standardTableData[table]?.data || {}).forEach(parent => {
          count++;
          (parent?.subRows || []).forEach(child => {
            count++;
          });
        });

        return count;
      }
    }, [isExpandingRowsEnabled, standardTableData, table]);

    return (
      <Flex flexDirection="column" wrap="wrap" style={width100}>
        {(header || headerMenu || enableLayoutExport) && (
          <div key={`${table}_header`} style={headerStyle}>
            <Box>
              {!!Header && <Header />}
              {
                <div style={{ color: colors.midgray }}>
                  <HeaderBSThatShouldNotBeHere
                    table={table}
                    selectedColumnSet={selectedColumnSet}
                    tableDataLength={tableDataLength}
                    selectedRowLength={selectedRowLength}
                    hideRowCount={hideRowCount}
                    hideSelectedCount={hideSelectedCount}
                    hideQueryDate={hideQueryDate}
                  ></HeaderBSThatShouldNotBeHere>
                </div>
              }{" "}
            </Box>

            <Flex flexDirection="row" wrap="wrap" alignItems="start">
              {headerMenu}
              {headerMenu && enableLayoutExport && <div style={headerMenuStyle}></div>}
              {enableLayoutExport && (
                <LayoutExportButtons
                  useIdAsHeaders={useIdAsHeaders}
                  table={table}
                  exportCallback={exportCallback}
                  fileName={exportFileName}
                />
              )}
            </Flex>
          </div>
        )}
        {subHeader && subHeader}
      </Flex>
    );
  }
);

const HeaderBSThatShouldNotBeHere = ({
  table,
  selectedColumnSet,
  tableDataLength,
  selectedRowLength,
  hideRowCount = true,
  hideQueryDate = true,
  hideSelectedCount = true,
}) => {
  const [standardTableData] = useStandardTableContext();

  let text;
  switch (table) {
    case StandardTables.TR_REPORT:
      text = `My entered trades: ${tableDataLength} `;
      break;
    case StandardTables.TR_SCAN:
      text = `My scanned trades: ${tableDataLength} `;
      break;
    default:
      text = `Rows: ${tableDataLength} `;
      break;
  }
  const selectedRows = `| Selected: ${selectedRowLength}`;
  return (
    <StyledUl>
      {!hideRowCount || !hideSelectedCount ? (
        <StyledLi>
          {hideRowCount ? "" : text} {hideSelectedCount ? "" : selectedRows} {selectedColumnSet}
        </StyledLi>
      ) : (
        <></>
      )}
      {!hideQueryDate && (
        <StyledLi>
          Queried at: {formatLastQueryDate(standardTableData[table]?.lastQueryDate)}
        </StyledLi>
      )}
    </StyledUl>
  );
};

const StyledUl = styled.ul`
  padding-left: 0;
  margin: 0;
`;

const StyledLi = styled.li`
  list-style-type: none;
`;
