import styled from "styled-components";
import { getColor, Color } from "@nef/style-utils";

export const ErrorSuccessIssueCount = styled.span<{ color: Color }>`
  ${props => `
    color: ${getColor("gray", 0)(props)};
    padding: 0.1rem 0.5rem;
    margin-right: .5rem;
    align-items: center; 
    font-size: .8rem;
    background-color: ${getColor(props.color, 500)(props)};
  `}
`;

export const TabText = styled.span`
  vertical-align: middle;
`;
