import { CSSGrid, Flex, FontAwesomeIcon } from "@nef/core";
import { colors } from "colors";
import { FieldNames, Forms } from "components/fields/fieldConstants";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "components/user";
import { useFormDispatch } from "../form";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const StyledCSSGrid = styled(CSSGrid)`
  padding: 1rem;
  cursor: pointer;
  max-width: 350px;
  border-top: 1px solid ${getColor("gray", 25)};
`;

const SubText = styled.span`
  font-size: 0.9rem;
  color: ${getColor("gray", 500)};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${getColor("danger", 500)};
  font-size: 1.5rem;
`;

export const BellNotification = ({ clearingFirmMPID, correspondentMPID, text, subtext }) => {
  let history = useHistory();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const handleUpdateRiskConfig = useCallback(() => {
    history.push("/settings/limitmonitor");
    const formActions = [
      {
        type: "UPDATE_FORM_VALUE",
        payload: {
          form: Forms.SETTINGS_LIMO,
          field: FieldNames.clearingFirmMPID,
          value: { label: clearingFirmMPID, value: clearingFirmMPID },
          entitlements: userData.entitlements,
        },
      },
      {
        type: "UPDATE_FORM_VALUE",
        payload: {
          form: Forms.SETTINGS_LIMO,
          field: FieldNames.correspondentMPID,
          value: { label: correspondentMPID, value: correspondentMPID },
          entitlements: userData.entitlements,
        },
      },
    ];
    formDispatch(formActions);
  }, [formDispatch, history, userData, clearingFirmMPID, correspondentMPID]);

  return (
    <StyledCSSGrid cols="auto 1fr" gap=".5rem" onClick={handleUpdateRiskConfig}>
      <StyledIcon iconClassName="fa-exclamation-triangle" />
      <Flex flexDirection="column">
        <span>{text}</span>
        <SubText>{subtext}</SubText>
      </Flex>
    </StyledCSSGrid>
  );
};
