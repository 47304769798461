import { useReducer } from "react";
import { DispatchFn, DispatchFnSwitch, ContextProviderProps } from "components/context/constants";
import { PvMemberState, PvMemberAction, PV_REJECT_TOP } from "./constant";
import ContextFactory from "components/context/factory";

const FACTORY = new ContextFactory<PvMemberState, PvMemberAction>();

const DEFAULT_STATE: PvMemberState = {
  por: [],
  [PV_REJECT_TOP.mpid]: [],
  [PV_REJECT_TOP.rejectDate]: [],
  filter: {},
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<PvMemberState, PvMemberAction> = (
  prevState: PvMemberState,
  action: PvMemberAction
) => {
  switch (action.type) {
    case "ADD_PARAM_OPEN_REQ": {
      return { ...prevState, por: [...action.payload, ...prevState.por] };
    }
    case "SET_FILTER": {
      return { ...prevState, filter: action.payload };
    }
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<PvMemberState, PvMemberAction>(DISPATCH_FN_SWITCH);

interface PvMemberProviderProps extends ContextProviderProps {}

export const PvMemberProvider: React.FC<PvMemberProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<PvMemberState, PvMemberAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const usePvMemberDispatch =
  FACTORY.createContextHook<React.Dispatch<PvMemberAction | PvMemberAction[]>>(DISPATCH_CONTEXT);
export const usePvMemberState = FACTORY.createContextHook<PvMemberState>(STATE_CONTEXT);
