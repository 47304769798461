import {
  Button,
  CSSGrid,
  CircleButton,
  Flex,
  FontAwesomeIcon,
  Header,
  Indicator,
  SegmentedControls,
} from "@nef/core";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  AllOrPending,
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  Por_Or_Pvr,
  usePORCacheDispatch,
} from "../context";
import { has } from "lodash";
import { useStandardTableContext } from "components/standardTable";
import { StandardTables } from "wksConstants";

export const SizedHeader = styled(Header)`
  width: 100%;
`;

export const HeaderBox = ({
  toggle,
  hasAllOrPending = true,
  hasMpidOrSymbol = true,
  isAllOrPending,
  isMpidOrSymbol,
  isPorOrPvr,
  total = 0,
  title = "Price Override Requests",
}: {
  toggle: () => void;
  hasAllOrPending: boolean;
  hasMpidOrSymbol: boolean;
  isAllOrPending?: All_Or_Pending;
  isMpidOrSymbol?: Mpid_Or_Security;
  isPorOrPvr: Por_Or_Pvr;
  total: number;
  title: string;
}) => {
  const dispatch = usePORCacheDispatch();
  const [data2, tableDispatch] = useStandardTableContext();

  const handleChange = useCallback(
    (val: any) => {
      dispatch({
        type: "SET_ALL_OR_PENDING",
        payload: { allOrPending: val, view: isPorOrPvr },
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
      });
    },
    [dispatch, isPorOrPvr, tableDispatch]
  );

  const handleChange2 = useCallback(
    (val: any) => {
      dispatch({
        type: "SET_MPID_OR_SECURITY",
        payload: { MpidOrSymbol: val, view: isPorOrPvr },
      });
    },
    [dispatch, isPorOrPvr]
  );

  const buttonCols = useMemo(() => {
    let colString = "";
    if (hasAllOrPending) {
      colString += "max-content ";
    }

    if (hasMpidOrSymbol) {
      colString += "max-content ";
    }

    colString += "40px";

    return colString;
  }, [hasAllOrPending, hasMpidOrSymbol]);
  return (
    <SizedHeader size={3}>
      <CSSGrid cols={"max-content auto"} justifyContent="between" gap=".85rem">
        <CSSGrid
          gap=".85rem"
          cols={"max-content 30px"}
          justifyContent="between"
          alignItems="center"
        >
          <span>
            {hasAllOrPending ? isAllOrPending : ""} {title} -{" "}
            {hasAllOrPending && isAllOrPending === AllOrPending.PENDING
              ? Math.floor(total - 10)
              : total}
          </span>
          <Indicator color={"success"} />
        </CSSGrid>
        <CSSGrid gap=".85rem" cols={buttonCols} justifyContent="end">
          {hasAllOrPending && (
            <SegmentedControls size="sm" defaultValue={isAllOrPending} onClick={handleChange}>
              <Button value={AllOrPending.PENDING} color="primary" outline size="sm">
                {AllOrPending.PENDING}
              </Button>
              <Button value={AllOrPending.ALL} color="primary" outline size="sm">
                {AllOrPending.ALL}
              </Button>
            </SegmentedControls>
          )}
          {hasMpidOrSymbol && (
            <SegmentedControls size="sm" defaultValue={isMpidOrSymbol} onClick={handleChange2}>
              <Button value={MpidOrSymbol.MPID} color="primary" outline size="sm">
                {MpidOrSymbol.MPID}
              </Button>
              <Button value={MpidOrSymbol.SECURITY} color="primary" outline size="sm">
                {MpidOrSymbol.SECURITY}
              </Button>
            </SegmentedControls>
          )}
          <CircleButton onClick={toggle} ghost={true} size="xs">
            <FontAwesomeIcon iconClassName="fa-table" />
          </CircleButton>
        </CSSGrid>
      </CSSGrid>
    </SizedHeader>
  );
};
