import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AllTheContexts, Content } from "components/content";
import { ViewContext } from "components/context";
import { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { HashRouter, Route } from "react-router-dom";
import { TopBar } from "components/topBar";
import { AuthHeader, HandleLogInCloak, UserProvider } from "components/user";
import "./wdyr";

import "./App.css";
import { LayoutProvider } from "./components/context";
import { getKeycloak, initializeKeycloak } from "./keycloak";
import { enableMapSet } from "immer";
import { UserPreferenceProvider } from "components/user/preferenceContext";
import { MPIDOptionsProvider } from "components/user/mpidContext";
import { FieldOptionProvider } from "components/fields/loadingContext";

const appStyle = {
  minWidth: "1024px",
};

function App() {
  enableMapSet();
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    const initKeycloak = async () => {
      const isKeycloakInitialized = await initializeKeycloak();
      if (!isKeycloakInitialized) {
        console.error("Error reaching authentication server.");
      } else {
        setKeycloak(getKeycloak());
      }
    };
    initKeycloak();
  }, []);

  return (
    <div className="App" style={appStyle}>
      <WithCloak keycloak={keycloak} />
    </div>
  );
}
export default App;
const onKeycloakEvent = (event, error) => {
  // console.log("onKeycloakEvent", event, error);
};

const onKeycloakTokens = tokens => {
  // console.log("onKeycloakTokens", tokens);
};

const WithCloak = ({ keycloak }) => {
  return (
    <>
      {!keycloak ? (
        <AuthHeader message="Initializing WorkX..." />
      ) : (
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={onKeycloakEvent}
          onTokens={onKeycloakTokens}
          initConfig={{ onLoad: "check-sso" }}
        >
          <UserProvider>
            <FieldOptionProvider>
              <MPIDOptionsProvider>
                <UserPreferenceProvider>
                  <LayoutProvider>
                    <HandleLogInCloak>
                      <HashRouter basename="/">
                        <CookiesProvider>
                          <Route path="/:view?/:form?">
                            <ViewContext>
                              <AllTheContexts>
                                <TopBar />
                                <Content />
                              </AllTheContexts>
                            </ViewContext>
                          </Route>
                        </CookiesProvider>
                      </HashRouter>
                    </HandleLogInCloak>
                  </LayoutProvider>
                </UserPreferenceProvider>
              </MPIDOptionsProvider>
            </FieldOptionProvider>
          </UserProvider>
        </ReactKeycloakProvider>
      )}
    </>
  );
};
