import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useSettingsContext } from "components/settings";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { useViewModelDispatch } from "../context";
import { useFormDispatch } from "../form";
import { useStandardTableContext, useStandardTableDispatch } from "../standardTable";
import { actionCallbacks } from "../standardTable/actionHooks";
import { useUserContext } from "../user";
import { useStatsContext, useStatsDispatch } from "./context";
import { network } from "./network";
import { getColor } from "@nef/style-utils";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const BlueTextSpan = styled.span`
  ${props => `
  color: ${props.isDisabled ? getColor("gray", 300)(props) : getColor("primary", 600)(props)};
  cursor: ${props.isDisabled ? "not-allowed" : "pointer"};
`}
`;

export const TradesForScanLink = ({ children, statType }) => {
  const standardTableDispatch = useStandardTableDispatch();
  const [standardTableData] = useStandardTableContext();
  const [statsData] = useStatsContext();
  const statsDispatch = useStatsDispatch();
  const [settings] = useSettingsContext();
  const formDispatch = useFormDispatch();
  const dispatch = useViewModelDispatch();
  const [userData] = useUserContext();
  const [isRequestPending, setRequestPending] = useState(false);

  const maxTableSize = useMemo(() => {
    return standardTableData[StandardTables.ST_MIDDLE].maxTableSize;
  }, [standardTableData]);

  const successCallback = useCallback(
    jsonData => {
      setRequestPending(false);
      const tableData = jsonData.slice(0, maxTableSize);
      const truncated = jsonData.slice(maxTableSize, jsonData.length);
      actionCallbacks.genericScan(
        formDispatch,
        maxTableSize,
        standardTableDispatch,
        tableData,
        truncated,
        StandardTables.ST_MIDDLE,
        false,
        userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS]
      );
      const dispatchActions = [
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_MODIFY },
        },
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_COPY },
        },
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_REPAIR },
        },
      ];
      dispatch(dispatchActions);

      const formActions = [
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_MODIFY, entitlements: userData.entitlements },
        },
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_COPY, entitlements: userData.entitlements },
        },
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_REPAIR, entitlements: userData.entitlements },
        },
      ];
      formDispatch(formActions);

      statsDispatch({
        type: "SET_TABLE_TITLE",
        payload: "Late Trades (10 Sec Rule)",
      });
    },
    [
      dispatch,
      formDispatch,
      maxTableSize,
      standardTableDispatch,
      statsDispatch,
      userData.entitlements,
      userData.mpidAttributes,
    ]
  );

  const errorCallback = useCallback(() => {
    setRequestPending(false);
    NotificationHub.send("danger", "Your scanned trades did not load", {
      subtitle: "Please try again later",
    });

    standardTableDispatch({
      type: "SET_NOT_LOADING",
      payload: { table: Forms.ST_MIDDLE.key },
    });
  }, [standardTableDispatch]);

  const handleStatType = useCallback(() => {
    if (isRequestPending) {
      NotificationHub.send(
        "warning",
        "Please wait for the pending request to finish before attempting to send another."
      );
    } else {
      setRequestPending(true);
      const dispatchs = [
        {
          type: "SET_LOADING",
          payload: { table: StandardTables.ST_MIDDLE, isLoading: true },
        },
        {
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { ask: false, table: StandardTables.ST_MIDDLE },
        },
      ];

      standardTableDispatch(dispatchs);
      network().tradesForStats({
        statType,
        successCallback,
        errorCallback,
        context: {
          mpidvals: statsData.requestPayload.mpids,
          trfVals: statsData.requestPayload.trf,
          incContraGUP: settings.incContraGUP,
          incContraMPID: settings.incContraMPID,
          incExecGUP: settings.incExecGUP,
          incExecMPID: settings.incExecMPID,
          fromUpdateDate: `${moment().format("Y-MM-DD")}T00:00:00.000-05:00`,
          toUpdateDate: `${moment().format("Y-MM-DD")}T23:59:59.998-05:00`,
        },
      });
    }
  }, [
    isRequestPending,
    errorCallback,
    settings.incContraGUP,
    settings.incContraMPID,
    settings.incExecGUP,
    settings.incExecMPID,
    standardTableDispatch,
    statType,
    statsData.requestPayload.mpids,
    statsData.requestPayload.trf,
    successCallback,
  ]);

  return (
    <BlueTextSpan onClick={handleStatType} isDisabled={isRequestPending}>
      {children}
    </BlueTextSpan>
  );
};
