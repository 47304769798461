import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@nef/core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { EQRCForm } from ".";
import styled from "styled-components";

const FormWrapper = styled.div`
  min-height: 250px;
`;

export const ActiveForm = ({
  table,
  header,
  form,
  fieldSets,
  isOpen,
  onClose,
  onSave,
  isSaving,
  includeExchMpidPort,
  disableMpidPort,
  currentStep = 0,
  setStep = () => {},
  totalSteps = 1,
  validate,
  onSecondary,
}) => {
  const containerRef = useRef();

  const handleSave = useCallback(() => {
    if (typeof onSave === "function") {
      onSave();
    }
  }, [onSave]);

  const onPrimary = useCallback(() => {
    if (!validate()) {
      return;
    }

    if (currentStep + 1 >= totalSteps) {
      handleSave();
    } else {
      setStep(currentStep + 1);
    }
  }, [currentStep, handleSave, setStep, totalSteps, validate]);

  const handleOnSecondary = useCallback(() => {
    if (onSecondary) {
      onSecondary();
    }
    setStep(currentStep - 1);
  }, [currentStep, onSecondary, setStep]);

  return (
    <Modal isOpen={isOpen} closeOnOutsideClick={false} toggle={onClose}>
      <ModalHeader toggle={onClose} title={header} />
      <ModalBody ref={containerRef}>
        <FormWrapper>
          <EQRCForm
            fieldSets={fieldSets}
            form={form}
            containerRef={containerRef}
            includeExchMpidPort={includeExchMpidPort}
            disableMpidPort={disableMpidPort || currentStep + 1 === totalSteps}
          />
        </FormWrapper>
      </ModalBody>
      <ModalFooter>
        {currentStep > 0 ? (
          <Button
            outline={true}
            onClick={handleOnSecondary}
            disabled={currentStep + 1 < totalSteps}
          >
            Back
          </Button>
        ) : (
          <Button outline={true} onClick={onClose}>
            Close
          </Button>
        )}
        <Button disabled={isSaving} onClick={onPrimary}>
          {currentStep + 1 < totalSteps ? "Next" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
