import { Flex, SectionBody, SectionHeader, NotificationHub } from "@nef/core";
import { FormFields } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { Form2 } from "components/form";
import Left from "components/left";
import React, { useCallback, useMemo } from "react";
import { OpenClose, ReportOrScan } from ".";
import { useUserContext } from "../user";
import { useFormDispatch, useFormContext } from "../form";
import { ViewTable } from "../../viewConstants";
import { useLayoutContext, useViewModelContext } from "../context";
import * as fields from "components/fields/fields";
import _ from "lodash";
import { ReportSubmit, CopySubmit, ModifySubmit, RepairSubmit, GenericScanSubmit } from "./submits";
import { UploadRepairSubmit } from "components/upload";
import { MatchSubmit } from "./submits/match";

const styles = { width: "100%" };
export const LeftView = ({ view, form, toggles }) => {
  const [userData] = useUserContext();
  const formDispatch = useFormDispatch();
  const [layoutData] = useLayoutContext();
  const [formData] = useFormContext();
  const [viewData] = useViewModelContext();

  const handleClearAll = useCallback(() => {
    if ([Forms.TR_REPAIR, Forms.TR_MODIFY, Forms.ST_REPAIR, Forms.ST_MODIFY].includes(form)) {
      formDispatch({
        type: "REVERT_FORM",
        payload: { form, entitlements: userData.entitlements },
      });
    } else {
      formDispatch({
        type: "RESET_FORM",
        payload: { form, entitlements: userData.entitlements },
      });
    }
  }, [form, formDispatch, userData.entitlements]);

  const handleInitForm = useCallback(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: {
        entitlements: userData.entitlements,
        form,
        fields: _.pick(fields, [
          "fields",
          "fields2",
          "fields3",
          "fields3b",
          "hiddenFields",
          "hiddenFields2",
          "scanOnly",
          "scanOnly2",
          "scanOnly3",
        ]),
      },
    });
  }, [form, formDispatch, userData.entitlements]);

  const handleDisabled = useCallback(() => {
    NotificationHub.send("warning", "No record selected");
  }, []);

  const Submit = useMemo(() => {
    const currentTable = ViewTable[view];
    switch (form) {
      case Forms.TR_REPORT:
        return <ReportSubmit />;
      case Forms.TR_REPAIR:
      case Forms.ST_REPAIR:
        return <RepairSubmit table={currentTable} form={form} />;
      case Forms.UPLOAD_REPAIR:
        return <UploadRepairSubmit table={currentTable} form={form} />;
      case Forms.TR_COPY:
      case Forms.ST_COPY:
        return <CopySubmit table={currentTable} form={form} />;
      case Forms.TR_MATCH:
      case Forms.ST_MATCH:
        return <MatchSubmit table={currentTable} form={form} />;
      case Forms.TR_MODIFY:
      case Forms.ST_MODIFY:
        return <ModifySubmit table={currentTable} form={form} />;
      case Forms.TR_SCAN:
      case Forms.TR_REJECTS:
      case Forms.RD_CLEARING:
      case Forms.RD_AGU:
      case Forms.RD_CUSIP:
        return <GenericScanSubmit table={currentTable} form={form} action={form.key} />;

      default:
        return;
    }
  }, [form, view]);

  const Toggles = useMemo(() => {
    if (toggles?.length > 0) {
      return <ReportOrScan toggles={toggles} />;
    } else {
      return undefined;
    }
  }, [toggles]);

  return (
    <Left>
      <SectionHeader>
        <Flex flexDirection={"column"} style={styles}>
          <OpenClose form={form} handleClearAll={handleClearAll} />
          {Toggles}
        </Flex>
      </SectionHeader>
      <SectionBody>
        <Form2
          form={form}
          isLeftExpanded={layoutData.isLeftExpanded}
          onInit={handleInitForm}
          leftFieldSet={FormFields[form.key].left}
          rightFieldSet={FormFields[form.key].right}
          onDisabledClick={handleDisabled}
          globalErrors={form ? formData[form.key].globalErrorMessage : ""}
          isDisabled={viewData[`${form.key}Disabled`]}
        />
      </SectionBody>
      {Submit}
    </Left>
  );
};
