import { CircleButton, FontAwesomeIcon, Tab, Tabs } from "@nef/core";
import React, { useCallback, useMemo } from "react";
import { useTabTableContext, useTabTableDispatch } from ".";
import { useStandardTableDispatch } from "../standardTable";
import { LoadingIcon } from "components/loadingIcon";

const spinnerStyle = {
  position: "static",
  marginRight: "3px",
};

export const TableTabs = ({ table, removableTabs, onTabClick, showRowCountOnTab }) => {
  const [tabTableData] = useTabTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const tabTableDispatch = useTabTableDispatch();

  const handleClickNoTabs = useCallback(() => {}, []);

  const tabs = useMemo(() => {
    return Object.entries(tabTableData[table].tabs);
  }, [tabTableData, table]);

  const handleTabClick = useCallback(
    id => () => {
      if (!tabTableData[table].loadingList.includes(id)) {
        if (typeof onTabClick === "function") {
          onTabClick(id);
        } else {
          standardTableDispatch([
            {
              type: "SET_TABLE_DATA",
              payload: { table, data: tabTableData[table].tabs[id].data },
            },
            {
              type: "SET_LAST_QUERY_DATE",
              payload: {
                table,
                lastQueryDate: tabTableData[table].tabs[id].lastQueryDate,
              },
            },
            {
              type: "DESELECT_ALL_ROWS",
              payload: { table },
            },
          ]);
          tabTableDispatch({
            type: "SET_ACTIVE_TAB",
            payload: { table, key: id },
          });
        }
      }
    },
    [standardTableDispatch, tabTableDispatch, tabTableData, table, onTabClick]
  );

  const removeTab = useCallback(
    id => e => {
      e.stopPropagation();
      tabTableDispatch({
        type: "REMOVE_TAB",
        payload: { table, tab: id },
      });
    },
    [tabTableDispatch, table]
  );

  const tabAction = useCallback(
    id => {
      if (removableTabs && !tabTableData[table].loadingList.includes(id)) {
        return (
          <CircleButton size="xs" ghost={true} onClick={removeTab(id)}>
            <FontAwesomeIcon iconClassName="fa-times" />
          </CircleButton>
        );
      } else {
        return undefined;
      }
    },
    [removableTabs, tabTableData, table, removeTab]
  );

  return (
    <Tabs>
      {tabs.length > 0 ? (
        tabs.map(([id, { name, data }]) => (
          <Tab
            key={id}
            id={id}
            active={tabTableData[table].activeTab === id}
            onClick={handleTabClick(id)}
            action={tabAction(id)}
          >
            <LoadingIcon
              isLoading={tabTableData[table].loadingList.includes(id)}
              style={spinnerStyle}
            >
              {`${name}${showRowCountOnTab ? ` (${data?.length || 0})` : ""}`}
            </LoadingIcon>
          </Tab>
        ))
      ) : (
        <Tab onClick={handleClickNoTabs}>{"<No Tabs>"}</Tab>
      )}
    </Tabs>
  );
};
