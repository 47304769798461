import React from "react";
import { ContentWrapper } from ".";
import { SpinnerIcon, SuspenseLoading, SuspenseText } from "components/styled";

export const PageLoader = () => {
  return (
    <ContentWrapper isLeftExpanded={false} isRightOpen={false} hideLeft={true}>
      <SuspenseLoading>
        <SpinnerIcon />
        <SuspenseText>loading</SuspenseText>
      </SuspenseLoading>
    </ContentWrapper>
  );
};
