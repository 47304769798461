import React from "react";
import { Flex } from "@nef/core";
import { useStatsContext, NarrowBarItem } from "components/stats";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const NumberWidget = styled(Flex)`
  ${props => `
    & :nth-child(2) {
      margin-bottom: 0;
    }
    border: ${getColor("gray", 50)(props)} solid 0;
    border-top-width: ${props.$noBorder ? "0px" : "2px"};
    border-bottom-width: ${props.$noBorder ? "0px" : "2px"};
    padding: 1em 0;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-bottom: 1rem;

    & div > div {
      margin: 0.5rem 0.25rem 0.5rem 0.5rem;

      & :last-child {
        margin-bottom: 0;
      }
      & :first-child {
        margin-top: 0;
      }
    }
    & p {
      color: ${getColor("gray", 300)(props)};
      font-size: 0.85rem;
      margin: 0;
    }
  `}
`;

const BigNarrowBarItem = styled(NarrowBarItem)`
  flex-direction: column;
  & p {
    font-size: 1rem;
  }
`;

const Numbers = () => {
  const [data] = useStatsContext();
  const {
    "Total<br />Trades": totalTrades,
    "Locked<br />In": lockedIn,
    "As Of": asOf,
    "Match<br />Compare": match,
    "TRF<br />Carteret": carteret,
    "TRF<br />Chicago": chicago,
  } = data.narrowBarTop;

  return (
    <>
      <NumberWidget flexDirection="row" justifyContent="space-evenly" alignItems="center">
        <BigNarrowBarItem text="Trades" value={totalTrades?.value} isAbbreviated={true} />
        <Flex flexDirection="column" justifyContent="space-evenly">
          <NarrowBarItem
            text="Locked In"
            value={lockedIn?.value}
            isAbbreviated={true}
            headerSize={4}
          />
          <NarrowBarItem
            text="Match Compare"
            value={match?.value}
            isAbbreviated={true}
            headerSize={4}
          />
          <NarrowBarItem text="As Of" value={asOf?.value} isAbbreviated={true} headerSize={4} />
        </Flex>
      </NumberWidget>
      <NumberWidget justifyContent="space-evenly" $noBorder={true}>
        <BigNarrowBarItem text="Carteret" value={carteret?.value} isAbbreviated={true} />
        <BigNarrowBarItem text="Chicago" value={chicago?.value} isAbbreviated={true} />
      </NumberWidget>
    </>
  );
};

export default Numbers;
