import { CircleButton, FontAwesomeIcon } from "@nef/core";
import React, { useCallback } from "react";
import styled from "styled-components";

export const SELECT_CREATABLE_FILE_UPLOAD_ID = "select-createable-file-upload";

const StyledImportCircleButton = styled(CircleButton)`
  margin-top: 7px;
`;

const StyledInput = styled.input`
  display: none;
`;

export const Importer = ({ onImportSet }) => {
  const clearInput = useCallback(e => (e.currentTarget.value = ""), []);

  const handleFileUpload = useCallback(
    e => {
      e.preventDefault();

      const reader = new FileReader();
      const file = e.currentTarget.files[0];

      reader.onload = event => {
        const t = event.target.result;
        onImportSet(t);
      };

      reader.readAsText(file);
    },
    [onImportSet]
  );

  const handleImportClick = useCallback(
    () => document.getElementById(SELECT_CREATABLE_FILE_UPLOAD_ID).click(),
    []
  );

  return (
    <>
      <StyledImportCircleButton onClick={handleImportClick} ghost size="sm" color="secondary">
        <FontAwesomeIcon iconClassName="fa-file-upload" />
      </StyledImportCircleButton>
      <StyledInput
        id={SELECT_CREATABLE_FILE_UPLOAD_ID}
        type="file"
        accept=".csv"
        onClick={clearInput}
        onChange={handleFileUpload}
      />
    </>
  );
};
