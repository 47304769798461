export const NON_CLEARING_NUM = "0000";

export const getClearingMpidFromClearingNum = (firm, clearingNum) => {
  let clearingMpid = null;
  if (Array.isArray(firm)) {
    if (
      typeof clearingNum === "string" &&
      clearingNum !== NON_CLEARING_NUM /* correspondent firm */
    ) {
      const clearer = firm.find(clearingFirm => clearingFirm.clearingNum === clearingNum);
      if (clearer?.firmType === "Clearing" && typeof clearer?.firmMPID === "string") {
        clearingMpid = clearer.firmMPID;
      }
    }
  }
  return clearingMpid;
};
