import {
  FieldLoop,
  riskAlertFieldsAguMPID,
  riskAlertFieldsAguCorrespondentMPIDs,
} from "components/fields";
import { PtraFieldSection, SectionHeader } from "../constants";
import React from "react";

export const PtrAguMPIDOptions: React.FC<PtraFieldSection> = ({ form }) => {
  return (
    <section>
      <SectionHeader>MPID Options</SectionHeader>
      <FieldLoop
        isReactFragment={true}
        form={form}
        fields={riskAlertFieldsAguMPID}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
      <FieldLoop
        isReactFragment={true}
        form={form}
        fields={riskAlertFieldsAguCorrespondentMPIDs}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </section>
  );
};
