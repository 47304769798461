import { Button, FormField, Popover, PopoverBody, PopoverHeader } from "@nef/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { handlePersistCreatableOptions } from "./network";
import { useUserContext } from "../../user/context";

const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5rem;
  max-height: 100px;
  overflow-y: overlay;
  padding-right: 15px;
  margin-bottom: 0.5rem;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  justify-content: end;
`;

const getManagedOptions = options => {
  return options.reduce((acc, curr) => {
    acc[curr.label] = curr;
    return acc;
  }, {});
};

export const ManagePopover = ({ target, isOpen, onToggle, containerRef, options, persistKey }) => {
  const [user, userDispatch] = useUserContext();
  const [managedOptions, setManagedOptions] = useState(getManagedOptions(options));
  const [deleted, setDeleted] = useState({});

  const handleClose = useCallback(() => {
    setDeleted({});
    if (typeof onToggle === "function") {
      onToggle();
    }
  }, [onToggle]);

  const handleCancel = useCallback(() => {
    setManagedOptions(getManagedOptions(options));
    handleClose();
  }, [handleClose, options]);

  const handleSave = useCallback(() => {
    const updatedManagedOptions = { ...managedOptions };
    Object.entries(deleted).forEach(([label, isDeleted]) => {
      if (isDeleted) {
        delete updatedManagedOptions[label];
      }
    });
    handlePersistCreatableOptions({
      persistKey,
      user,
      userDispatch,
      newOptionsString: JSON.stringify(Object.values(updatedManagedOptions)),
    });
    setManagedOptions(updatedManagedOptions);
    handleClose();
  }, [managedOptions, persistKey, user, userDispatch, handleClose, deleted]);

  const handleOptionChange = useCallback(
    label =>
      ({ value }) => {
        managedOptions[label] = { value, label: value };
        setManagedOptions({ ...managedOptions });
      },
    [managedOptions]
  );

  const handleDelete = useCallback(
    (isDelete, label) => () => {
      setDeleted({ ...deleted, [label]: isDelete });
    },
    [deleted]
  );

  return (
    <Popover
      isOpen={isOpen}
      target={target}
      placement="bottom-end"
      toggle={onToggle}
      width={350}
      height={170}
      fixed
      containInParent={true}
      containerRef={containerRef}
    >
      <PopoverHeader>Manage Options</PopoverHeader>
      <PopoverBody>
        <OptionWrapper>
          {Object.entries(managedOptions).map(([ogLabel, option]) => {
            return (
              <React.Fragment key={ogLabel}>
                <FormField
                  key={ogLabel}
                  value={option.value}
                  size="sm"
                  onChange={handleOptionChange(ogLabel)}
                  disabled={deleted[ogLabel]}
                  helpText={deleted[ogLabel] ? "This option will be deleted upon save" : undefined}
                />
                {deleted[ogLabel] ? (
                  <Button onClick={handleDelete(false, ogLabel)} color="light" size="sm">
                    +
                  </Button>
                ) : (
                  <Button onClick={handleDelete(true, ogLabel)} color="danger" size="sm">
                    X
                  </Button>
                )}
              </React.Fragment>
            );
          })}
        </OptionWrapper>
        <ButtonWrapper>
          <Button onClick={handleCancel} color="primary" size="sm" outline={true}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" size="sm">
            Save
          </Button>
        </ButtonWrapper>
      </PopoverBody>
    </Popover>
  );
};
