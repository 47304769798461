import React from "react";
import { Section, SectionBody, SectionHeader } from "@nef/core";

const Login = () => {
  return (
    <>
      <Section
        style={{
          height: "auto",
          width: "350px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <SectionHeader title="Yikes!" />
        <SectionBody>
          <p>You can't access that and this is your 403</p>
        </SectionBody>
      </Section>
    </>
  );
};

export default Login;
