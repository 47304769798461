import { NotificationHub } from "@nef/core";
import React, { useCallback, useState, useEffect } from "react";
import { TableButtons, useStandardTableContext } from ".";
import { convertToCSV, createAndDownloadTemplate } from "./utils";
import { useUserContext } from "../user";
import { TableButtonAction, TableButtonLabel } from "../../wksConstants";
import { getFileNameDate } from "../../utils/js.utils";

export const getColumnHeadersAndAccessors = (table, tableData, useIdAsHeaders) => {
  const accessors = {};
  const headers = tableData[table].columnsOrder
    .filter(c => !tableData[table].hiddenColumns.includes(c))
    .map(c => {
      const a = tableData[table].allColumns.find(colDef => {
        return colDef.id === c;
      });
      const key = useIdAsHeaders ? a.id : a.Header;
      accessors[key] = a.accessor;
      return key;
    });
  return { accessors, headers };
};

export const getColumnHeadersAndAccessorsFromColumns = (columns, useIdAsHeaders) => {
  return columns.reduce(
    (acc, curr) => {
      acc.headers.push(curr.Header);
      const key = useIdAsHeaders ? curr.id : curr.Header;
      acc.accessors[key] = curr.accessor;
      return acc;
    },
    { accessors: {}, headers: [] }
  );
};

export const LayoutExportButtons = ({ table, exportCallback, fileName, useIdAsHeaders }) => {
  const [standardTableData] = useStandardTableContext();
  const [buttons, setButtons] = useState([]);
  const [{ isUserDataLoading }] = useUserContext();

  const handleExport = useCallback(() => {
    NotificationHub.send("success", "Export request received");
    setTimeout(() => {
      if (exportCallback) {
        exportCallback();
      } else {
        const { accessors, headers } = getColumnHeadersAndAccessors(
          table,
          standardTableData,
          useIdAsHeaders
        );

        createAndDownloadTemplate(
          fileName
            ? fileName
            : `${table}${
                standardTableData[table]?.lastQueryDate
                  ? getFileNameDate(standardTableData[table]?.lastQueryDate)
                  : ""
              }.csv`,
          convertToCSV(headers, standardTableData[table].allTheData, accessors)
        ).then(() => NotificationHub.send("success", "Export request has been completed"));
      }
    }, 20);
  }, [table, standardTableData, exportCallback, fileName, useIdAsHeaders]);

  useEffect(() => {
    setButtons([
      {
        icon: "grip-lines-vertical",
        text: TableButtonLabel.LAYOUT,
        actionId: TableButtonAction.LAYOUT,
        tag: "tag", // TODO: wtf kind of name is this?
        //style: memoedStyle,
        requireSelect: false,
        // onClick: SEE TABLE DROPDOWN
        loading: isUserDataLoading,
        isDisabled: standardTableData.isColumnSavingDisabled,
      },
      {
        icon: "arrow-down",
        text: TableButtonLabel.EXPORT,
        actionId: TableButtonAction.EXPORT,
        requireSelect: false,
        onClick: handleExport,
        isDisabled: standardTableData[table].isExportDisabled,
      },
    ]);
  }, [handleExport, setButtons, standardTableData, table, isUserDataLoading]);

  return <TableButtons table={table} buttons={buttons} />;
};
