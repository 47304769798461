import { NotificationHub } from "@nef/core";
import { NotificationColor } from "@nef/core/lib/components/NotificationHub";
import { isFulfilled } from "components/eqrc/utils";
import { Forms } from "components/fields";
import { useFormContext } from "components/form";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useMemo, useState } from "react";
import { DispatchAction } from "types";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { formatUrl } from "../../utils/js.utils";
import {
  getSelectedRow,
  getSelectedRows,
  TableButtons,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../standardTable";
import {
  RashOrder,
  RASH_EXCHANGE_VALUE,
  RASH_ORDER,
  convertRashOrderPrices,
  MATH_OPERATOR,
} from "./constants";

const getUpdateActionForOrder = (order: RashOrder) => ({
  type: "UPDATE_TABLE_ROW_WITH_ID",
  payload: {
    table: StandardTables.ORDER_ENTRY,
    row: order,
    idField: RASH_ORDER.orderId,
  },
});

export const OrderEntryTableButtons = () => {
  const [isCancelLoading, setCancelLoading] = useState(false);
  const [isKillLoading, setKillLoading] = useState(false);
  const [tableData] = useStandardTableContext();
  const [formData] = useFormContext();
  const tableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();

  const handleCancelOrder = useCallback(() => {
    const cancelErrorCb = () => {
      NotificationHub.send("danger", "Order failed to cancel");
      setCancelLoading(false);
    };

    const cancelSuccessCb = (order: RashOrder) => {
      if (!order.isReject) {
        NotificationHub.send("success", "Order Cancelled Successfully");
        convertRashOrderPrices(order, MATH_OPERATOR.DIVIDE);
        tableDispatch(getUpdateActionForOrder(order));
      } else {
        NotificationHub.send("danger", "Order Cancel Rejected", { subtitle: order.rejectMsg });
      }
      setCancelLoading(false);
    };

    setCancelLoading(true);
    const selected: RashOrder = getSelectedRow(tableData[StandardTables.ORDER_ENTRY]);
    const body = {
      [RASH_ORDER.id]: selected.id,
      [RASH_ORDER.orderId]: selected.orderId,
      [RASH_ORDER.orderQuantity]: formData[Forms.ORDER_CANCEL.key].fields[RASH_ORDER.orderQuantity],
    };
    doFetchWrapper(
      formatUrl(
        `${userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.rashWebServiceUrl]}`,
        `order/${selected[RASH_ORDER.exchange]}/cancel`
      ),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      },
      cancelSuccessCb,
      cancelErrorCb
    );
  }, [tableData, formData, userData, tableDispatch]);

  const handleKillOrders = useCallback(() => {
    setKillLoading(true);
    const makeRequests = async () => {
      const selected: RashOrder[] = getSelectedRows(tableData[StandardTables.ORDER_ENTRY]);
      const bodies = selected.reduce(
        (acc, curr) => {
          if (typeof curr.exchange === "string") {
            acc[curr.exchange].push(curr.id);
          }
          return acc;
        },
        {
          [RASH_EXCHANGE_VALUE.BX]: [],
          [RASH_EXCHANGE_VALUE.PSX]: [],
          [RASH_EXCHANGE_VALUE.NASDAQ]: [],
        } as {
          [exchange in RASH_EXCHANGE_VALUE]: string[];
        }
      );

      const responseToExchange: { [index: number]: RASH_EXCHANGE_VALUE } = {};
      const requests: Promise<Response>[] = [];
      let idx = 0;
      Object.entries(bodies).forEach(([exchange, body]) => {
        if (body.length > 0) {
          responseToExchange[idx++] = exchange as RASH_EXCHANGE_VALUE;
          requests.push(
            fetch(
              formatUrl(
                `${userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.rashWebServiceUrl]}`,
                `order/${exchange}/kill`
              ),
              {
                method: "POST",
                mode: "cors",
                headers: getHeaders(),
                body: JSON.stringify(bodies[exchange as RASH_EXCHANGE_VALUE]),
              }
            )
          );
        }
      });

      const result = await Promise.allSettled(requests);
      type Message = { type: NotificationColor; message: string };
      const messages: Message[] = [];
      for (let index = 0; index < result.length; index++) {
        const response = result[index];
        let msg: Message;
        const tableActions: DispatchAction[] = [];
        if (isFulfilled(response) && response.value.ok) {
          const dtos: RashOrder[] = await response.value.json();
          let success = 0,
            rejected = 0;
          dtos.forEach(dto => {
            if (dto.isReject) {
              rejected++;
            } else {
              convertRashOrderPrices(dto, MATH_OPERATOR.DIVIDE);
              tableActions.push(getUpdateActionForOrder(dto));
              success++;
            }
          });
          let addedMsg = "";
          let msgType: NotificationColor = "success";
          if (rejected > 0) {
            msgType = "danger";
            addedMsg = `, ${rejected} Rejected`;
          }
          msg = {
            type: msgType,
            message: `${responseToExchange[index]}: ${success} Canceled${addedMsg}`,
          };
        } else {
          msg = { type: "danger", message: `Error Canceling ${responseToExchange[index]} Orders` };
        }
        tableDispatch(tableActions);
        messages.push(msg);
      }
      messages.forEach(msg => NotificationHub.send(msg.type, msg.message));
      setKillLoading(false);
    };
    makeRequests();
  }, [tableData, tableDispatch, userData]);

  const getTableButtons = useMemo(() => {
    const buttons = [
      {
        icon: "times",
        text: TableButtonLabel.CANCEL,
        actionId: TableButtonAction.RASH_CANCEL,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleCancelOrder,
        canConfirm: true,
        allowConfirm: true,
        loading: isCancelLoading,
      },
      {
        icon: "minus",
        text: TableButtonLabel.RASH_MASS_CANCEL,
        actionId: TableButtonAction.RASH_MASS_CANCEL,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleKillOrders,
        canConfirm: false,
        allowConfirm: false,
        loading: isKillLoading,
      },
    ];
    return buttons;
  }, [handleCancelOrder, handleKillOrders, isCancelLoading, isKillLoading]);

  return <TableButtons table={StandardTables.ORDER_ENTRY} buttons={getTableButtons} />;
};
