export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}
export const SINGLE_ORDER_PREFIX = "SINGLE_ORDER_";
export enum BREACHES {
  RESTRICTED_STOCK = "RESTRICTED_STOCK",
  SHORT_SALE = "SHORT_SALE", //easy to borrow
  MIN_CADV = "MIN_CADV", //single order adv
  FAT_FINGER_LIMIT = "FAT_FINGER_LIMIT",
  DUPLICATIVE_MSG_RATE = "DUPLICATIVE_MSG_RATE",
  ORDER_TYPE = "ORDER_TYPE",
  PORT_MSG_RATE = "PORT_MSG_RATE",
  SYMBOL_MSG_RATE = "SYMBOL_MSG_RATE",
  MARKET_IMPACT_CHECK = "MARKET_IMPACT_CHECK",
  SHARES_LOCATED = "SHARES_LOCATED",
  MAX_NOTIONAL_ORDER = "MAX_NOTIONAL_ORDER",
  MAX_SHARES_ORDER = "MAX_SHARES_ORDER",
  EQRC_ISO_NOT_ALLOWED = "EQRC_ISO_NOT_ALLOWED",
  SHORT_SELL_NOT_ALLOWED = "SHORT_SELL_NOT_ALLOWED",
  MARKET_ORDER_NOT_ALLOWED = "MARKET_ORDER_NOT_ALLOWED",
  PRE_MARKET_NOT_ALLOWED = "PRE_MARKET_NOT_ALLOWED",
  POST_MARKET_NOT_ALLOWED = "POST_MARKET_NOT_ALLOWED",
  SHORT_SELL_EXEMPT_NOT_ALLOWED = "SHORT_SELL_EXEMPT_NOT_ALLOWED",
}

export const ORDER_TYPE_BREACHES: { [key in BREACHES]?: string } = {
  [BREACHES.EQRC_ISO_NOT_ALLOWED]: BREACHES.EQRC_ISO_NOT_ALLOWED,
  [BREACHES.SHORT_SELL_NOT_ALLOWED]: BREACHES.SHORT_SELL_NOT_ALLOWED,
  [BREACHES.MARKET_ORDER_NOT_ALLOWED]: BREACHES.MARKET_ORDER_NOT_ALLOWED,
  [BREACHES.PRE_MARKET_NOT_ALLOWED]: BREACHES.PRE_MARKET_NOT_ALLOWED,
  [BREACHES.POST_MARKET_NOT_ALLOWED]: BREACHES.POST_MARKET_NOT_ALLOWED,
  [BREACHES.SHORT_SELL_EXEMPT_NOT_ALLOWED]: BREACHES.SHORT_SELL_EXEMPT_NOT_ALLOWED,
};

export enum ORDER_TYPE_BREACHES_WITH_ORDER_TYPE {
  EQRC_ISO_NOT_ALLOWED = "EQRC_ISO_NOT_ALLOWED",
  SHORT_SELL_NOT_ALLOWED = "SHORT_SELL_NOT_ALLOWED",
  MARKET_ORDER_NOT_ALLOWED = "MARKET_ORDER_NOT_ALLOWED",
  PRE_MARKET_NOT_ALLOWED = "PRE_MARKET_NOT_ALLOWED",
  POST_MARKET_NOT_ALLOWED = "POST_MARKET_NOT_ALLOWED",
  SHORT_SELL_EXEMPT_NOT_ALLOWED = "SHORT_SELL_EXEMPT_NOT_ALLOWED",
  ORDER_TYPE = "ORDER_TYPE",
}

export enum ORDER_TYPE_BREACHES_LABELS {
  EQRC_ISO_NOT_ALLOWED = "EQRC ISO Not Allowed",
  SHORT_SELL_NOT_ALLOWED = "Short Sell Not Allowed",
  MARKET_ORDER_NOT_ALLOWED = "Market Order Not Allowed",
  PRE_MARKET_NOT_ALLOWED = "Pre Market Not Allowed",
  POST_MARKET_NOT_ALLOWED = "Post Market Not Allowed",
  SHORT_SELL_EXEMPT_NOT_ALLOWED = "Short Sell Exempt Not Allowed",
}
export enum UNBREACH_NAMES {
  MESSAGE_RATE = "MESSAGE_RATE",
  DUPLICATE_RATE = "DUPLICATE_RATE",
  SYMBOL_RATE = "SYMBOL_RATE",
}

export enum BREACHES_LABELS {
  RESTRICTED_STOCK = "Restricted Stock",
  SHORT_SALE = "Hard To Borrow", //easy to borrow
  MIN_CADV = "Single Order Adv", //single order adv
  FAT_FINGER_LIMIT = "Fat Finger",
  DUPLICATIVE_MSG_RATE = "Duplicative Order",
  ORDER_TYPE = "Order Type",
  MARKET_IMPACT_CHECK = "Market Impact Check",
  PORT_MSG_RATE = "Port Message Rate",
  SYMBOL_MSG_RATE = "Symbol Message Rate",
  SHARES_LOCATED = "Shares Located",
  MAX_NOTIONAL_ORDER = "Max Notional Order",
  MAX_SHARES_ORDER = "Max Shares Order",
  EQRC_ISO_NOT_ALLOWED = "ISO Not Allowed",
  SHORT_SELL_NOT_ALLOWED = "Short Sell Not Allowed",
  MARKET_ORDER_NOT_ALLOWED = "Market Order Not Allowed",
  PRE_MARKET_NOT_ALLOWED = "Pre Market Not Allowed",
  POST_MARKET_NOT_ALLOWED = "Post Market Not Allowed",
  SHORT_SELL_EXEMPT_NOT_ALLOWED = "Short Sell Exempt Not Allowed",
  SINGLE_ORDER_NOTIONAL_EXCEEDED = "Single Order Notional Exceeded",
}

export enum EXPOSURE_BREACHES {
  GROSS_EXECUTED_EXPOSURE = "GROSS_EXECUTED_EXPOSURE",
  GROSS_NOTIONAL_EXPOSURE = "GROSS_NOTIONAL_EXPOSURE",
  GROSS_OPEN_EXPOSURE = "GROSS_OPEN_EXPOSURE",
}

export enum EXPOSURE_BREACHES_LABELS_SHORT {
  GROSS_EXECUTED_EXPOSURE = "Executed",
  GROSS_NOTIONAL_EXPOSURE = "Notional",
  GROSS_OPEN_EXPOSURE = "Open",
}

export enum EXPOSURE_BREACHES_LABELS {
  GROSS_EXECUTED_EXPOSURE = "Gross Executed Exposure",
  GROSS_NOTIONAL_EXPOSURE = "Gross Notional Exposure",
  GROSS_OPEN_EXPOSURE = "Gross Open Exposure",
}

const ALL_BREACHES_COMBINED = { ...BREACHES, ...EXPOSURE_BREACHES };
export type ALL_BREACHES = keyof typeof ALL_BREACHES_COMBINED;

export const UNBREACHABLE_ALERT_TYPES = [
  BREACHES.PORT_MSG_RATE,
  BREACHES.SYMBOL_MSG_RATE,
  BREACHES.DUPLICATIVE_MSG_RATE,
];

export const BREACHES_TO_UNBREACHES: { [type: string]: string } = {
  [BREACHES.DUPLICATIVE_MSG_RATE]: "DUPLICATE_RATE",
  [BREACHES.SYMBOL_MSG_RATE]: "SYMBOL_RATE",
  [BREACHES.PORT_MSG_RATE]: "MESSAGE_RATE",
};
export const ALL_BREACHES_LABELS = {
  ...BREACHES_LABELS,
  ...EXPOSURE_BREACHES_LABELS,
};

export type ALL_BREACHES_LABEL = keyof typeof ALL_BREACHES_LABELS;

export enum EXPOSURE_LEVELS {
  WATCH = "WATCH",
  WARN = "WARN",
  ACTION = "ACTION",
}

export type MonitoringData = {
  mpid: string;
  port?: string;
  alertType: BREACHES | EXPOSURE_BREACHES;
  level?: EXPOSURE_LEVELS;
};

export type SingleOrderCacheData = { [alertMpidPort: string]: { offset: number; data: {} } };
export type ExposureCacheData = {
  [alertMpidPort: string]: {
    [level: string]: {
      offset: number;
      data: {}[];
    };
  };
};

export type ChartsData = {
  singleOrder: SingleOrderCacheData;
  exposure: ExposureCacheData;
};

export type CurrentGrossExposure = {
  mpid: string;
  port: string;
  groupId: number;
  exchange: string;
  status?: string;
  creationTime?: string;
  lastModifiedTime?: string;
  clientId?: string;
  openExposure: number;
  execExposure: number;
  notionalExposure: number;
  breachAction?: string;
  synthetic?: boolean;
};
