export const PTRA_FIELD_PREFIX = "PTRA_";

export const PTRA_CONFIGURATION_ENTITY = {
  id: "id",
  isAgu: "agu",
  mpid: "mpidvals", // this isn't actually on the entity
  aguMPID: "aguMPID", // this isn't actually on the entity
  participantMPID: "participantMPID", // this isn't actually on the entity
  clearingNum: "clearingFirmNum",
  clearingMPID: "clearingFirmMPID",
  correspondentMPID: "correspondentMPID",
  limitBreachSide: "limitBreachSide",
  includeGiveups: "giveupsActive",
  intradayNextDay: "intradayNextDay",
  isAggSettingActive: "aggSettingActive",
  isPerTradeSettingActive: "perTradeSettingActive",
  isMaxTradeSettingActive: "maxTradeSettingActive",
  isDefaultActionSettingActive: "defaultActionSettingActive",
  settingChangeAlertFrequency: "settingChangeAlertFrequency",
  breachAlertFrequency: "breachAlertFrequency",
  isAggregateAlertActive: "aggAlertActive",
  isAggregateHoldActive: "aggHoldActive",
  isAggregateKillActive: "aggRejectActive",
  isPerTradeAlertActive: "perTradeAlertActive",
  isPerTradeHoldActive: "perTradeHoldActive",
  isMaxTradeAlertActive: "maxTradeActive",
  isHeldTradeActive: "heldTradeActive",
  isKillLimitRejectActive: "killLimitRejectActive",
  isEndOfDayAlertActive: "isEndOfDayAlertActive",
  isAlertLogActive: "logActive",
  isAlertPopupActive: "popupActive",
  isAlertSoundActive: "soundActive",
  isEmailRealTimeActive: "emailRealTimeActive",
  isEmailEndOfDayActive: "emailEndOfDayActive",
  email: "email",
  emailId: "emailId",
  includeWorkXUserRecipient: "includeWorkXUserRecipient",
  isEnabled: "enabled",
  creator: "creator",
  lastUpdate: "lastUpdate",
};

export const PTRA_AGU_ENTITY = {
  id: "id",
  executingMPID: "executingMPID",
  participantMPID: "participantMPID",
};

export const PTRA_VALIDATION_RESULT = {
  errors: "errors",
  hasError: "hasError",
  hasWarning: "hasWarning",
  warnings: "warnings",
  entities: "entities",
  global: "global",
};

export const PTRA_ALERT_TYPE = {
  MAX_TRADE: 1,
  AGG_ALERT: 2,
  AGG_HOLD: 3,
  AGG_KILL: 4,
  PER_TRADE_ALERT: 5,
  KILL_LIMIT_REJECT: 6,
  HELD_TRADE: 7,
  PER_TRADE_HOLD: 8,

  SETTING_AGGREGATE: 101,
  SETTING_PER_TRADE: 102,
  SETTING_MAX_TRADE: 103,
  SETTING_DEFAULT_ACTION: 104,
};

export const PTR_ALERT_TYPE_LABEL = {
  [PTRA_ALERT_TYPE.MAX_TRADE]: "Max Trade Limit Reject",
  [PTRA_ALERT_TYPE.AGG_ALERT]: "Aggregate Alert Limit Breach",
  [PTRA_ALERT_TYPE.AGG_HOLD]: "Aggregate Hold Limit Breach",
  [PTRA_ALERT_TYPE.AGG_KILL]: "Aggregate Kill Limit Breach",
  [PTRA_ALERT_TYPE.HELD_TRADE]: "Held Trade Alert (Aggregate Risk)",
  [PTRA_ALERT_TYPE.KILL_LIMIT_REJECT]: "Aggregate Kill Limit Reject",
  [PTRA_ALERT_TYPE.PER_TRADE_ALERT]: "Per Trade Limit Alert",
  [PTRA_ALERT_TYPE.PER_TRADE_HOLD]: "Per Trade Limit Hold",
  [PTRA_ALERT_TYPE.SETTING_AGGREGATE]: "Aggregate Settings",
  [PTRA_ALERT_TYPE.SETTING_PER_TRADE]: "Per Trade Settings",
  [PTRA_ALERT_TYPE.SETTING_MAX_TRADE]: "Max Trade Settings",
  [PTRA_ALERT_TYPE.SETTING_DEFAULT_ACTION]: "Default Action Settings",
};
