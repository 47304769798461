import { RichTable } from "@nef/core";
import React, { useCallback, memo, useMemo } from "react";
import { useStandardTableContext, useStandardTableDispatch } from ".";

export const StandardTableBody = memo(
  ({
    table,
    additionalRowClick,
    isSingleSelect = false,
    isColumnsVirtualized = true,
    isFilterable,
    isEditableTableEnabled,
    isRowSelectEnabled = true,
    Header,
    maxHeight,
    isExpandingRowsEnabled,
  }) => {
    const standardTableDispatch = useStandardTableDispatch();
    const [standardTableData] = useStandardTableContext();

    const onRowsSelected = useCallback(
      rows => {
        standardTableDispatch({
          type: "HANDLE_ROWS_SELECTED",
          payload: { table, rows, isSingleSelect },
        });
      },
      [table, standardTableDispatch, isSingleSelect]
    );

    const onReorderColumns = useCallback(
      columns => {
        standardTableDispatch({
          type: "SET_CURRENT_COLUMNS_ORDER",
          payload: { table, columns },
        });
      },
      [standardTableDispatch, table]
    );

    const onSortColumns = useCallback(
      sortData => {
        standardTableDispatch({
          type: "SET_SORT_DATA",
          payload: {
            data: sortData,
            table,
          },
        });
      },
      [standardTableDispatch, table]
    );

    const onRowsExpanded = useCallback(
      rows => {
        standardTableDispatch({
          type: "SET_EXPANDED_ROWS",
          payload: {
            rows,
            table,
          },
        });
      },
      [standardTableDispatch, table]
    );

    const defaultFilter = useCallback((filter, row, column) => {
      const id = filter.pivotId || filter.id;
      return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true;
    }, []);

    const columns = useMemo(() => {
      return standardTableData[table]?.allColumns;
    }, [standardTableData, table]); //this is 'all the columns' not 'current columns' this shoudn't change

    const memoData = useMemo(() => {
      return standardTableData[table]?.data;
    }, [table, standardTableData]);

    const initialTableState = useMemo(() => {
      return {
        selectedRowIds: { ...standardTableData[table]?.selected },
        hiddenColumns: [...standardTableData[table]?.hiddenColumns],
        columnOrder: [...standardTableData[table]?.columnsOrder],
        sortBy: [...standardTableData[table]?.sortData],
        disabledRowIds: standardTableData[table]?.disabledRows,
        expanded: standardTableData[table]?.expandedRows,
      };
    }, [standardTableData, table]);

    const tableOptions = {
      data: memoData,
      columns: columns,
      initialState: initialTableState,
    };

    const isLoading = useMemo(() => {
      return standardTableData[table].isLoading;
    }, [standardTableData, table]);

    return (
      <RichTable
        options={tableOptions}
        loading={isLoading}
        showPageSizeOptions={false}
        isFilterable={isFilterable}
        isDraggable={false}
        isRowSelectEnabled={isRowSelectEnabled && !isSingleSelect}
        defaultFilterMethod={defaultFilter}
        onRowsSelected={isRowSelectEnabled ? onRowsSelected : null}
        isColumnsVirtualized={isColumnsVirtualized}
        onRowClick={isRowSelectEnabled && additionalRowClick ? additionalRowClick : undefined}
        onReorderColumns={onReorderColumns}
        onSortColumns={onSortColumns}
        isEditableTableEnabled={isEditableTableEnabled}
        isVerticalLinesEnabled={true}
        maxBodyHeight={maxHeight}
        isExpandingRowsEnabled={isExpandingRowsEnabled}
        onRowsExpanded={onRowsExpanded}
        rowBgPattern="zebra"
        isExpandingGroupStyleEnabled={false}
        isSelectSubRowsEnabled={false}
      />
    );
  }
);
