import { FieldLoop, riskAlertFields3a } from "components/fields";
import { PtraFieldSection, SectionFieldsWrapper, SectionHeader } from "../constants";

export const PtrBreachAlerts: React.FC<PtraFieldSection> = ({ form }) => {
  return (
    <section>
      <SectionHeader>Breach Alerts</SectionHeader>
      <SectionFieldsWrapper>
        <FieldLoop
          isReactFragment={true}
          form={form}
          fields={riskAlertFields3a}
          classNames={undefined}
          augmentOnChange={undefined}
          augmentOnCreate={undefined}
          portalRef={undefined}
          isDisabled={undefined}
          containerRef={undefined}
          showLabel={undefined}
        />
      </SectionFieldsWrapper>
    </section>
  );
};
