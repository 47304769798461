import { Box, Button, Flex } from "@nef/core";
import { colors } from "colors";
import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useFormContext } from "./formContext";
import { SubmitConfirmation } from "./submitConfirmation";

const dispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_TIMER": {
      return { ...state };
    }
    case "SET_ANIMATION": {
      return { ...state, animation: action.payload };
    }
    case "SET_ABORT_AVAILABLE": {
      return { ...state, isAbortAvailable: action.payload };
    }
    case "SET_SUBMIT_AVAILABLE": {
      return { ...state, isSubmitAvailable: action.payload };
    }
    case "SET_PROPERTIES": {
      return Object.assign({ ...state }, action.payload);
    }
    default: {
      return { ...state };
    }
  }
};

const defaultState = {
  isAbortAvailable: false,
  timer: null,
  animation: null,
  isSubmitAvailable: true,
};

const Submit = ({
  form,
  accentName,
  buttonText,
  isLoading,
  isDisabled,
  onSubmit,
  onCancel,
  onAbort,
  abortText,
}) => {
  const [{ isAbortAvailable, timer, animation, isSubmitAvailable }, dispatch] = useReducer(
    dispatchFn,
    defaultState
  );
  const [formData] = useFormContext();
  const isLoadingRef = useRef(isLoading);
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    isLoadingRef.current = isLoading;
    if (!isLoading) {
      clearTimeout(timer);
      const properties = {
        animation: null,
        isAbortAvailable: false,
        timer: null,
      };
      dispatch({
        type: "SET_PROPERTIES",
        payload: properties,
      });
    }
  }, [isLoading, timer]);

  useEffect(() => {
    if (typeof onAbort === "function") {
      if (isLoading && timer === null) {
        const timeoutCallback = () => {
          if (isLoadingRef.current) {
            const properties = { isAbortAvailable: true };
            if (timer !== null) {
              properties["timer"] = null;
            }
            dispatch({
              type: "SET_PROPERTIES",
              payload: properties,
            });
          }
        };
        dispatch({
          type: "SET_TIMER",
          payload: setTimeout(timeoutCallback, 5000),
        });
      }
    } else {
      dispatch({
        type: "SET_ABORT_AVAILABLE",
        payload: false,
      });
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isLoading, timer, onAbort]);

  const handleAbort = useCallback(() => {
    onAbort();
  }, [onAbort]);

  const handleMouseDown = useCallback(
    e => {
      if (isAbortAvailable) {
        const properties = {
          animation: "whiteToBlack 3s linear",
          isSubmitAvailable: false,
        };
        dispatch({
          type: "SET_PROPERTIES",
          payload: properties,
        });
      } else {
        dispatch({
          type: "SET_SUBMIT_AVAILABLE",
          payload: true,
        });
      }
    },
    [isAbortAvailable]
  );

  const handleSubmit = useCallback(
    e => {
      setConfirmOpen(false);
      if (!isAbortAvailable && isSubmitAvailable && onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit, isSubmitAvailable, isAbortAvailable]
  );

  const handleMouseOut = useCallback(() => {
    if (animation !== null || isSubmitAvailable !== true) {
      const properties = {
        isSubmitAvailable: true,
        animation: null,
      };
      dispatch({
        type: "SET_PROPERTIES",
        payload: properties,
      });
    }
  }, [animation, isSubmitAvailable]);

  useEffect(() => {
    if (Object.keys(formData[form.key]?.confirmMessages)?.length === 0) {
      setConfirmOpen(false);
    }
  }, [formData, form]);

  const handleMouseUp = useCallback(
    e => {
      if (isSubmitAvailable) {
        if (Object.keys(formData[form.key].confirmMessages).length > 0) {
          setConfirmOpen(true);
        } else {
          handleSubmit(e);
        }
      } else {
        handleMouseOut();
      }
    },
    [handleSubmit, isSubmitAvailable, handleMouseOut, form.key, formData]
  );

  const handleAnimationEnd = useCallback(() => {
    const properties = {
      isAbortAvailable: false,
      animation: null,
    };
    dispatch({
      type: "SET_PROPERTIES",
      payload: properties,
    });
    handleAbort();
  }, [handleAbort]);

  const handleConfirmCancel = useCallback(() => {
    setConfirmOpen(false);
  }, []);

  const ButtonLoadingFn = useMemo(() => {
    return () => <Button.Loading>PROCESSING</Button.Loading>;
  }, []);

  const buttonWidth = "100%";

  const CancelButtonStyle = useMemo(() => {
    return { flexBasis: buttonWidth, marginRight: ".5rem" };
  }, []);

  const SubmitButtonStyle = useMemo(() => {
    return {
      flexBasis: buttonWidth,
      alignSelf: "flex-end",
      animation,
    };
  }, [animation]);

  const buttonId = useMemo(() => {
    return `${form.key}_submit_btn`;
  }, [form]);

  return (
    <Box marginTop={3}>
      <Flex justifyContent="space-between" id={buttonId}>
        {/* there is definitely a better way to check to add cancel button, add attribute to the form? */}
        {onCancel && !isLoading && (
          <Button
            color={accentName}
            outline
            size="md"
            onClick={onCancel}
            style={CancelButtonStyle}
            disabled={isDisabled}
          >
            Cancel
          </Button>
        )}
        {/* https://codepen.io/BoringCode/pen/FfzLs */}
        <Button
          color={isAbortAvailable ? "danger" : accentName}
          size="md"
          style={SubmitButtonStyle}
          className="workXSubmitBtn"
          isLoading={isLoading && !isAbortAvailable}
          disabled={(isDisabled && !isAbortAvailable) || isConfirmOpen}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseOut}
          onAnimationEnd={handleAnimationEnd}
          outline={isAbortAvailable ? true : false}
          loadingComponent={ButtonLoadingFn}
        >
          {isAbortAvailable ? abortText || "Abort" : buttonText}
        </Button>
        <SubmitConfirmation
          form={form}
          buttonId={buttonId}
          isOpen={isConfirmOpen}
          onSubmit={handleSubmit}
          onCancel={handleConfirmCancel}
        />
      </Flex>
    </Box>
  );
};

export default memo(Submit);
