import { FormDropdown } from "@nef/core";
import { FieldBox } from "components/styled";
import { memo, useCallback, useEffect, useMemo } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import { useFieldOptionContext, useFieldOptionDispatch } from "./loadingContext";

const WorkXDropdown = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    handleInputChange: onInputChange,
    isCountingParentsEnabled,
    id,
  } = props;
  const [{ loadingMap }] = useFieldOptionContext();
  const loadingDispatch = useFieldOptionDispatch();

  useEffect(
    () => () => {
      loadingDispatch({
        type: "RESET_OPTIONS_CACHE",
        payload: { form: form.id },
      });
    },
    [form.id, loadingDispatch]
  );

  const handleChange = useCallback(
    (e, other) => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        let { value } = e;
        if (
          (other && other.action === "clear") ||
          !e ||
          e.clearSelection ||
          e.value?.clearSelection ||
          (Array.isArray(e.value) && e.value.length === 0)
        ) {
          value = [];
        }

        let options;
        if (fieldData?.props?.options) {
          if (typeof fieldData.props.options === "function") {
            options = Array.from(fieldData.props.options(form));
          } else {
            options = Array.from(fieldData.props.options);
          }
          let childrenOptions = [];
          options.forEach(opt => {
            if (Array.isArray(opt.children)) {
              childrenOptions = childrenOptions.concat(opt.children);
            }
          });
          options = options.concat(childrenOptions);
        }
        value = value.map(v => ({
          label: options ? options.find(opt => opt.value === v)?.label : v,
          value: v,
        }));
        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [name, form, userData.entitlements, onInputChange, id]
  );

  const { helpText, disabled } = useMemo(() => {
    let { helpText, disabled } = props;
    if (loadingMap[props.id]?.has(form.id)) {
      helpText = "Loading...";
      disabled = true;
    }
    return { helpText, disabled };
  }, [props, loadingMap, form.id]);

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <FormDropdown
        {...props}
        id={`${props.id || props.name}_${form.key}`}
        headers={true}
        pageSize={1000}
        value={(value || []).map(v => v.value)}
        onChange={handleChange}
        placement="bottom-start"
        oppositeOnOverflow={true}
        isCountingParentsEnabled={isCountingParentsEnabled}
        helpText={helpText}
        disabled={disabled}
      />
    </FieldBox>
  );
};

export default memo(WorkXDropdown);
