import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { getHeaders } from "keycloak";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { EmailManagerForm, usePtraEmailDispatch, handleSaveNewPtraEmail } from ".";
import { doFetchWrapper } from "../../../../network";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "../../../user";
import { PTRA_EMAIL_ENTITY } from "./fields";
import {
  AGU_REQUESTING_KEY,
  CLR_CORR_REQUESTING_KEY,
  PTRA_SET_REQUESTING_TYPE,
  usePtraConfigDispatch,
} from "../configContext";

const StyledModalBody = styled(ModalBody)`
  overflow: hidden;
`;

export const PtraEmailModal = ({ isVisible, onClose, selectedEmail }) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData, formDispatch] = useFormContext();
  const [userData] = useUserContext();
  const emailDispatch = usePtraEmailDispatch();
  const configDispatch = usePtraConfigDispatch();

  useEffect(() => {
    if (selectedEmail) {
      const form = Forms.PTRA_EMAIL_MANAGER;
      const fields = { [PTRA_EMAIL_ENTITY.recipientEmail]: selectedEmail };
      formDispatch({
        type: "SET_FORM_VALUES",
        payload: { form, fields },
      });
    }
  }, [formDispatch, selectedEmail, userData.entitlements]);

  const handleCloseModal = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const handleDone = useCallback(() => {
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PTRA_EMAIL_MANAGER },
    });
    handleCloseModal();
  }, [handleCloseModal, tableDispatch]);

  const handleSaveNew = useCallback(() => {
    const newEmail = {
      [PTRA_EMAIL_ENTITY.recipientEmail]:
        formData[Forms.PTRA_EMAIL_MANAGER.key].fields[PTRA_EMAIL_ENTITY.recipientEmail],
    };
    handleSaveNewPtraEmail({
      baseUrl: userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.ptraUrl],
      emailDispatch,
      onDone: handleDone,
      newEmail,
    });
  }, [emailDispatch, formData, handleDone, userData]);

  const handleSaveEdit = useCallback(() => {
    const success = row => {
      NotificationHub.send("success", "Edited email");
      tableDispatch([
        {
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: { table: StandardTables.PTRA_EMAIL_MANAGER, row, idField: PTRA_EMAIL_ENTITY.id },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PTRA_EMAIL_MANAGER },
        },
      ]);
      emailDispatch({
        type: "START_REFRESH",
      });
      configDispatch([
        { type: PTRA_SET_REQUESTING_TYPE[AGU_REQUESTING_KEY], payload: true },
        { type: PTRA_SET_REQUESTING_TYPE[CLR_CORR_REQUESTING_KEY], payload: true },
      ]);
      handleCloseModal();
    };
    const error = () => {
      NotificationHub.send("danger", "Error editing email");
      handleCloseModal();
    };
    const selected = getSelectedRows(tableData[StandardTables.PTRA_EMAIL_MANAGER]);
    if (Array.isArray(selected) && selected.length === 1) {
      const emailUpdate = {
        [PTRA_EMAIL_ENTITY.id]: selected[0][PTRA_EMAIL_ENTITY.id],
        [PTRA_EMAIL_ENTITY.recipientEmail]:
          formData[Forms.PTRA_EMAIL_MANAGER.key].fields[PTRA_EMAIL_ENTITY.recipientEmail],
      };
      doFetchWrapper(
        formatUrl(userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.ptraUrl], "alert/email"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(emailUpdate),
        },
        success,
        error
      );
    } else {
      NotificationHub.send("danger", "Could not find selected record.");
    }
  }, [
    configDispatch,
    emailDispatch,
    formData,
    handleCloseModal,
    tableData,
    tableDispatch,
    userData,
  ]);

  const handleSave = useCallback(() => {
    if (selectedEmail) {
      handleSaveEdit();
    } else {
      handleSaveNew();
    }
  }, [selectedEmail, handleSaveEdit, handleSaveNew]);

  const Header = useMemo(() => {
    if (typeof selectedEmail === "string") {
      return "Edit Post - Trade Risk Recipient";
    } else {
      return "Add New Recipient for Post - Trade Risk Alerts";
    }
  }, [selectedEmail]);

  return (
    <Modal isOpen={isVisible} closeOnOutsideClick={true} toggle={handleCloseModal} size={"md"}>
      <ModalHeader toggle={handleCloseModal} title={Header} />
      <StyledModalBody>
        <EmailManagerForm />
      </StyledModalBody>
      <ModalFooter>
        <Button outline={true} onClick={handleCloseModal}>
          Close
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};
