import React, { useReducer, createContext, useContext } from "react";

export const uploadDispatch = createContext();
uploadDispatch.displayName = "UploadDispatch";
export const useUploadDispatch = () => {
  return useContext(uploadDispatch);
};

export const uploadContext = createContext();
uploadContext.displayName = "UploadContext";
export const useUploadContext = () => {
  return useContext(uploadContext);
};

const defaultState = {
  activeUploadId: 0,
  activeFilename: "",
  fileIssues: {},
  errors: {},
  success: {},
  notifications: [],
  isLeftDisabled: true,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_ID": {
      const newState = { ...state };
      newState.activeUploadId = action.payload.uploadId;
      newState.activeFilename = action.payload.filename;
      return newState;
    }
    case "ADD_FILE_ISSUES_FOR_FILE": {
      const { id, data } = action.payload;
      const newState = { ...state };
      const fileIssues = { ...newState.fileIssues };
      fileIssues[id] = data;
      newState.fileIssues = fileIssues;
      return newState;
    }
    case "ADD_ERRORS_SUCCESS_FOR_FILE": {
      const { id, data } = action.payload;
      const fileErrors = data.errors;
      const fileSuccess = data.success;
      const errors = { ...state.errors };
      errors[id] = fileErrors;
      const success = { ...state.success };
      success[id] = fileSuccess;
      const a = { ...state, errors, success };
      return a;
    }
    case "SUBMIT_MODIFIED_ERROR":
      const newState = { ...state };
      const fileId = newState.miniTableData[newState.activeUpload].id;
      const modifiedError = newState.uploadedData[fileId].errors[newState.activeError];
      newState.uploadedData[fileId].errors.splice(newState.activeError, 1);
      newState.uploadedData[fileId].success.push(modifiedError);
      // shouldn't the ...state spread already do this for me?
      // the mini table doesn't re-render without this..
      newState.miniTableData = [...newState.miniTableData];
      newState.miniTableData[newState.activeUpload].errors--;
      newState.activeError = undefined;
      return newState;
    case "INIT_FORMS":
      alert("Deprecated: Upload - INIT_FORMS");
      break;
    default:
      return { ...state };
  }
};

const UploadProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, defaultState, defaultData)
  );

  return (
    <uploadDispatch.Provider value={dispatchF}>
      <uploadContext.Provider value={[state, dispatchF]}>{children}</uploadContext.Provider>
    </uploadDispatch.Provider>
  );
};

export default UploadProvider;
