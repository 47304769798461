import React from "react";
import { OrderEntryLeft, OrderEntryMiddle } from "./";

export const OrderEntry = () => {
  return (
    <>
      <OrderEntryLeft />
      <OrderEntryMiddle />
    </>
  );
};
