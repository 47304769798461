import { FontAwesomeIcon, Progress, Tooltip } from "@nef/core";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useState } from "react";

const height = 14; //px
const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: ${height}px;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
`;

interface triangleProps {
  percent: number;
}
const Triangle = styled.div<triangleProps>`
  ${({ percent }) => `
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid black;
    left: ${Math.min(100, Math.max(0, percent))}%;
    margin-left: -7px;
    position: absolute;
    top: 0px;
  `}
`;

interface EQRCProgressProps {
  id: string;
  percentTop: number;
  percentToTop: number;
  check: string;
  alertActive: number;
  holdActive: number;
  rejectActive: number;
  hideAlert: boolean;
  type: string;
}

export const EQRCProgress = ({
  id,
  percentTop,
  percentToTop,
  check,
  alertActive,
  holdActive,
  rejectActive,
  hideAlert,
  type,
}: EQRCProgressProps) => {
  const [isHovered, setHovered] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const idTop = useMemo(() => `${id}_top`, [id]);

  
  const numActive = useMemo(() => {
    return alertActive + holdActive + rejectActive;
  }, [alertActive, holdActive, rejectActive]);

  const Indicator = useMemo((): ReturnType<typeof Triangle> => {
    return <Triangle key="top" id={idTop} percent={percentTop} />;
  }, [idTop, percentTop]);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Progress multi={true}>
        {alertActive > 0 && !hideAlert && (
          <Progress bar={true} color="primary" value={100 / numActive}>
            <StyledFontAwesomeIcon iconSetClassName="far" iconClassName="fa-eye" />
          </Progress>
        )}
        {holdActive > 0 && (
          <Progress
            bar={true}
            color="warning"
            value={100 / (numActive - (alertActive && hideAlert ? 1 : 0))}
          >
            <StyledFontAwesomeIcon iconSetClassName="far" iconClassName="fa-bell" />
          </Progress>
        )}
        {rejectActive > 0 && (
          <Progress
            bar={true}
            color="danger"
            value={100 / (numActive - (alertActive && hideAlert ? 1 : 0))}
          >
            A
          </Progress>
        )}
      </Progress>
      {Indicator}
      <Tooltip target={idTop} isOpen={isHovered} placement="top">
        {
          <>
            <span>Current {check} Exposure</span>
            <br />
            <span>{`${percentToTop}% of ${type}`}</span>
          </>
        }
      </Tooltip>
    </Wrapper>
  );
};
