import { CSSGrid } from "@nef/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { StandardTables } from "wksConstants";
import { LimoLVCIndicator, useAggregateLimitContext, useLimitMonitorContext } from ".";
import { useUserContext } from "../user";
import { SummaryTableButtons } from "./summaryTableButtons";
import { Views, ViewActions } from "viewConstants";
import { PtrIntradayLimitModal } from "./IntradayLimitModal";
import { ApiResponseNames } from "components/fields";

export const SummaryTable = () => {
  const standardTableDispatch = useStandardTableDispatch();
  const [standardTableData] = useStandardTableContext();
  const [limo] = useLimitMonitorContext();
  const [aggregates] = useAggregateLimitContext();
  const [user] = useUserContext();
  const [editRelationshipId, setEditRelationshipId] = useState(null);

  useEffect(() => {
    let type = "SET_LOADING";
    if (!limo.isLoading) {
      type = "SET_NOT_LOADING";
    }
    standardTableDispatch({
      type,
      payload: { table: StandardTables.CV_SUMMARY },
    });
  }, [limo.isLoading, standardTableDispatch]);

  useEffect(() => {
    if (!limo.isLoading) {
      standardTableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.CV_SUMMARY, data: Object.values(limo.data) },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: { table: StandardTables.CV_SUMMARY },
        },
      ]);
    }
  }, [limo.data, limo.isLoading, standardTableDispatch]);

  const additionalRowClick = useCallback(
    e => {
      standardTableDispatch({
        type: "HANDLE_ROWS_SELECTED",
        payload: {
          table: StandardTables.CV_SUMMARY,
          rows: { [e.row.id.toString()]: true },
          isSingleSelect: true,
        },
      });
    },
    [standardTableDispatch]
  );

  const summaryTableStyle = useMemo(() => {
    return { gridColumn: "1 / 5" };
  }, []);

  const StandardHeaderFn = useCallback(
    () => (
      <StandardHeader
        title={
          <CSSGrid cols="auto auto" gap="1rem" alignItems="center">
            Risk Limit Summary
            <LimoLVCIndicator id="risk-limit-summary-data-feed-status" status={aggregates.status} />
          </CSSGrid>
        }
      />
    ),
    [aggregates.status]
  );

  const handleOpenEditLimits = useCallback(rows => {
    if (rows?.valid?.length === 1) {
      const row = rows.valid[0];
      setEditRelationshipId(row[ApiResponseNames.clearingRelationshipId]);
    }
  }, []);

  const handleCloseEdit = useCallback(() => {
    setEditRelationshipId(null);
  }, []);

  const headerMenu = useMemo(() => {
    if (user.allowed.views[Views.SETTINGS_LIMO] || user.allowed.actions[ViewActions.PTR_CONFIG]) {
      return <SummaryTableButtons onClickEditLimits={handleOpenEditLimits} />;
    } else {
      return undefined;
    }
  }, [handleOpenEditLimits, user.allowed.actions, user.allowed.views]);

  return (
    <>
      <StandardTable
        style={summaryTableStyle}
        header={StandardHeaderFn}
        headerMenu={headerMenu}
        table={StandardTables.CV_SUMMARY}
        enableLayoutExport={true}
        isSingleSelect={true}
        additionalRowClick={additionalRowClick}
        isLoading={standardTableData[StandardTables.CV_SUMMARY].isLoading}
        isColumnsVirtualized={false} // must be false for single select
        isFilterable={true}
        hideQueryDate={true}
      />
      <PtrIntradayLimitModal
        isVisible={editRelationshipId !== null}
        onClose={handleCloseEdit}
        relationshipId={editRelationshipId}
      />
    </>
  );
};
