import { Box, FormField } from "@nef/core";
import React, { memo, useMemo, useReducer, useCallback } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import { KEY_CODES } from "./keyCodes";
import { ALL_FORMS, CONTROLLED_INPUT_TYPES } from "./constants";
import { FieldBox } from "components/styled";

// undefined used for selecting a cached value
const IGNORE_INVERSE_KEYS = [
  KEY_CODES.CTRL,
  KEY_CODES.CMD,
  KEY_CODES.BACKSPACE,
  KEY_CODES.DELETE,
  undefined,
];

const inverseCharacter = character => {
  if (character === character.toUpperCase()) {
    return character.toLowerCase();
  } else {
    return character.toUpperCase();
  }
};

const dispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      console.warn(`Invalid WorkXField dispatch type: ${action.type}`);
      return state;
  }
};

const STATE_KEY = {
  ignoreInverseCasing: "ignoreInverseCasing",
  selectionStart: "selectionStart",
};

const defaultState = {
  ignoreInverseCasing: false,
  selectionStart: 0,
};

const WorkXField = props => {
  const [userData] = useUserContext();
  const {
    value = "",
    style,
    isRequired,
    form,
    name,
    portalRef,
    handleInputChange: onInputChange,
    id,
  } = props;
  const [state, dispatch] = useReducer(dispatchFn, defaultState);

  const fieldData = useMemo(() => {
    const fieldDataKey = id ? id : name;
    if (fieldDataKey) {
      return execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
    }
    return false;
  }, [form, id, name, userData.entitlements]);

  const controlledInputType = useMemo(() => {
    if (fieldData) {
      let ctrlInputTypeMemo = fieldData && fieldData.props.fieldType;
      if (typeof ctrlInputTypeMemo === "string") {
        ctrlInputTypeMemo = [{ type: ctrlInputTypeMemo, forms: ALL_FORMS }];
      }
      if (Array.isArray(ctrlInputTypeMemo)) {
        return ctrlInputTypeMemo;
      }
    }
    return false;
  }, [fieldData]);

  const handleChange = useCallback(
    e => {
      let { value: newValue } = e;
      if (fieldData) {
        if (typeof e === "object") {
          if (Array.isArray(controlledInputType)) {
            for (let idx in controlledInputType) {
              const { type, forms } = controlledInputType[idx];
              if (forms === ALL_FORMS || (Array.isArray(forms) && forms.includes(form.key))) {
                switch (type) {
                  case CONTROLLED_INPUT_TYPES.PRICE:
                    if (
                      e.target.value.length > 0 &&
                      !/^\d{0,6}(\.\d{0,6})?$/.test(e.target.value)
                    ) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.CONTRACT:
                    if (
                      e.target.value.length > 0 &&
                      !/^\d{0,9}(\.\d{0,2})?$/.test(e.target.value)
                    ) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.FEE_PRICE:
                    if (
                      e.target.value.length > 0 &&
                      !/^-{0,1}\d{0,6}(\.\d{0,6})?$/.test(e.target.value)
                    ) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.FEE_CONTRACT:
                    if (
                      e.target.value.length > 0 &&
                      !/^-{0,1}\d{0,9}(\.\d{0,2})?$/.test(e.target.value)
                    ) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.RASH_PRICE:
                    if (
                      e.target.value.length > 0 &&
                      !/^-{0,1}\d{0,6}(\.\d{0,4})?$/.test(e.target.value)
                    ) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.NUMERIC:
                    if (!e.target.value.length <= 0 && !/^\d+$/.test(e.target.value)) {
                      return;
                    }
                    break;

                  case CONTROLLED_INPUT_TYPES.DECIMAL:
                    if (!e.target.value.length <= 0 && !/^\d*\.?\d*$/.test(e.target.value)) {
                      return;
                    }
                    break;

                  case CONTROLLED_INPUT_TYPES.ALPHA:
                    if (!e.target.value.length <= 0 && !/^[a-zA-Z]+$/.test(e.target.value)) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.SYMBOL:
                    if (!e.target.value.length <= 0 && !/^[^"']+$/.test(e.target.value)) {
                      return;
                    }
                    break;
                  case CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES: {
                    if (!e.target.value.length <= 0 && !/^\d*\.?\d{0,4}$/.test(e.target.value)) {
                      return;
                    }
                    break;
                  }
                  case CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE:
                    if (!state[STATE_KEY.ignoreInverseCasing]) {
                      newValue =
                        newValue.substr(0, state[STATE_KEY.selectionStart]) +
                        inverseCharacter(newValue.charAt(state[STATE_KEY.selectionStart])) +
                        newValue.substr(state[STATE_KEY.selectionStart] + 1, newValue.length);
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }

          let maxLength = fieldData && fieldData.props.maxLength;
          if (maxLength && newValue?.length > maxLength) {
            return;
          }

          const forceUpperCase = fieldData && fieldData.props.forceUpperCase;
          if (forceUpperCase && typeof newValue === "string") {
            newValue = newValue.toUpperCase();
          }
        }
      }

      if (onInputChange) {
        onInputChange({
          id,
          name,
          value: newValue,
          fieldAugment: fieldData?.props?.augmentOnChange?.[form?.key],
        });
      }
    },
    [fieldData, onInputChange, controlledInputType, form, state, id, name]
  );

  const handleKeyDown = useCallback(
    e => {
      if (Array.isArray(controlledInputType)) {
        const nextState = { [STATE_KEY.selectionStart]: e.target.selectionStart };
        if (IGNORE_INVERSE_KEYS.includes(e.keyCode)) {
          nextState[STATE_KEY.ignoreInverseCasing] = true;
        }
        dispatch({
          type: "SET_STATE",
          payload: nextState,
        });
      }
    },
    [controlledInputType, dispatch]
  );

  const handleKeyUp = useCallback(
    e => {
      if (Array.isArray(controlledInputType) && IGNORE_INVERSE_KEYS.includes(e.keyCode)) {
        dispatch({
          type: "SET_STATE",
          payload: { [STATE_KEY.ignoreInverseCasing]: false },
        });
      }
    },
    [controlledInputType]
  );

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <FormField
        {...props}
        menuPortalTarget={portalRef?.current}
        value={value || ""}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
    </FieldBox>
  );
};

export default memo(WorkXField);
