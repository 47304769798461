import { StandardHeader, StandardTable, useStandardTableContext } from "components/standardTable";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { useCallback, useEffect, useMemo, useState } from "react";
import TableButtons from "components/standardTable/tableButtons";
import { REJECTS_BY_MPID } from "components/pvRejects/member/constant";
import { pvRejectsByMpidSecurity } from "components/pvRejects/finra/constant";
import { AllOrPending, All_Or_Pending, Mpid_Or_Security } from "../context";

const RightTableWrapper = styled.div`
  grid-column: span 1;
`;

type Row = {
  symbol: string;
  mpid: string;
  price: string;
  quantity: number;
  rejectReason: string;
};

export const RejectsTable = ({
  isAllOrPending,
  selected,
}: {
  isAllOrPending: All_Or_Pending;
  selected?: string;
}) => {
  const [data2, dispatch] = useStandardTableContext();
  const [title, setTitle] = useState<string | null>(null);

  const TheHeader = useCallback(() => <StandardHeader title={title} />, [title]);

  const tableButtons = useMemo(() => {
    const buttons = [
      {
        icon: "clipboard-list",
        text: "Recap",
        actionId: "approve",
        requireSelect: false,
        allowMultiSelect: true,
        onClick: () => {},
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        // header: exportHeader,
        isDisabled: true,
      },
    ];

    return <TableButtons table={StandardTables.PV_SUPERVISOR_MONITOR2} buttons={buttons} />;
  }, []);

  const rows = Object.keys(data2[StandardTables.PV_SUPERVISOR_MONITOR2].selected);
  const rowsData = rows.map(row => data2[StandardTables.PV_SUPERVISOR_MONITOR2].allTheData[row]);

  const data3 = useMemo(() => {
    let returnData: Row[] = [];

    if (rows.length) {
      rowsData.forEach(row => {
        for (let i = 0; i < 5; i++) {
          returnData.push({
            symbol: row.symbol,
            mpid: row.mpid,
            price: `$${Math.floor(Math.random() * 1000)}`,
            quantity: Math.floor(Math.random() * 100),
            rejectReason: "!REJ - PRICE OUT OF OVERRIDE RANGE",
          });
        }
      });
    }
    return returnData;
  }, [rows, rowsData]);

  useEffect(() => {
    const numSelectedRows = rows.length;
    let newTitle = "";
    if (numSelectedRows > 0 && selected) {
      newTitle = `Rejects for ${numSelectedRows} ${selected} POR`;
    } else if (numSelectedRows === 0 && selected) {
      newTitle = `Rejects for ${selected}  POR`;
    } else if (numSelectedRows === 0 && !selected) {
      newTitle = `Rejects for ${isAllOrPending} POR`;
    } else if (numSelectedRows > 0 && !selected) {
      newTitle = `Rejects for ${numSelectedRows} POR`;
    }

    if (newTitle !== title) {
      setTitle(newTitle);
    } else {
      return;
    }

    dispatch({
      type: "SET_TABLE_DATA",
      payload: {
        table: StandardTables.PV_SUPERVISOR_MONITOR,
        data: data3,
      },
    });
  }, [data3, dispatch, rows.length, title, selected, isAllOrPending]);

  return (
    <RightTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={undefined}
        headerMenu={tableButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={undefined}
      />
    </RightTableWrapper>
  );
};
