import { final } from "./fields";
import { comparators } from "./fields.comparators";

const DIRECTION = {
  GREATER_THAN: "greater",
  LESS_THAN: "less",
};
const formatters = {
  greaterOrLessThanOrEqual: ({ limitValues, direction, orEqualTo }) => {
    return `Must be ${direction} than${orEqualTo ? " or equal to" : ""} ${limitValues}`;
  },
};

// is (the current field blank) and  (its related fields blank)
const areOtherFieldsValid = (value, relatedValues, payload) => {
  const { form, state } = payload;
  const requiredBcOfRelated = Object.entries(relatedValues).map(([key, value]) => {
    const valueToCheck = Array.isArray(value.currentValue)
      ? value.currentValue[0]
        ? value.currentValue[0].label
        : undefined
      : value.currentValue;
    return value.comparator(valueToCheck, state, form);
  });

  return requiredBcOfRelated.filter(required => required === false).length > 0;
};

const isEmptyTimeArray = array => {
  if (!Array.isArray(array)) {
    return true;
  } else if (array.every(item => item === "" || item === undefined)) {
    return true;
  } else {
    return false;
  }
};
// Validation Rule Definitions
// key: name to reference rule in field definition
// message: if the rule is static text, we can define a fixed error messsage
// fn: the function that does the actual validation, chefs choice
//     - return null for valid value
//     - if message defined, return false for invalid
//     - if message is not defined return the desired error message,
//       perhaps from a template literal
//     - (more) - if an object is passed to as the value of a rule key in
//       a field definition, that object will be passed as more
const validationRules = {
  validTimeRange: {
    message: (name, rule) => "Both fields required",
    fn: (value, payload) => {
      const [value1, value2] = value || [];

      if (isEmptyTimeArray(value1) && isEmptyTimeArray(value2)) {
        return null;
      } else if (isEmptyTimeArray(value) || isEmptyTimeArray(value2)) {
        return false;
      } else {
        return null;
      }
    },
  },
  requiredValues: {
    message: (name, rule) =>
      `${name} must contain the value(s): ${rule.requiredOptions
        .map(o => (o.valueLabel ? o.valueLabel : o.label))
        .join(", ")}`,
    fn: (value, payload) => {
      if (payload.currentValue === undefined) {
        return false;
      } else {
        const currentValues = Array.isArray(payload.currentValue)
          ? payload.currentValue
          : [payload.currentValue];

        return (payload.rules.requiredValues.requiredOptions || []).every(findValue => {
          return currentValues.find(lookOption => findValue.value === lookOption.value);
        })
          ? null
          : false;
      }
    },
  },
  mustPerformMathCheck: {
    message: (name, rule) => {
      return `${name} must be ${rule.messageFragment}`;
    },
    fn: (value, payload) => {
      const { mathPredicate } = payload.rules.mustPerformMathCheck;

      return mathPredicate(value) ? null : false;
    },
  },
  allowedValue: {
    message: name => `${name} is not an available option`,
    fn: (value, payload) => {
      const { valueList } = payload.rules.allowedValue;

      if (value === undefined || value === null) {
        return null;
      }
      const parsedValue = Array.isArray(value)
        ? value.map(v => v.value)
        : value.value
        ? [value.value]
        : [value];
      const mappedValueList = valueList.map(v => (v.value ? v.value : v));
      return parsedValue.every(v => mappedValueList.includes(v)) ? null : false;
    },
  },
  maxSelected: {
    message: (name, rule) => `${name} cannot have more than ${rule.max} items selected`,
    fn: (value, payload) =>
      !payload.currentValue || payload.currentValue?.length <= payload.rules.maxSelected.max
        ? null
        : false,
  },
  // the string entered for this field, must be within the list of values defined in
  // payload.
  // loop over myself and my related fields to see if i should be required
  // if the value of the other field (i) does not match the criteria to be required
  //  - indicate this rule does not cause required state
  // else
  //  - indicate this rule causes required state
  inValueList: {
    message: name => `Invalid ${name}`,
    fn: (value, payload) => {
      const rule = payload.rules.inValueList;
      const valueToCheck = Array.isArray(value) ? value[0]?.value : value;
      const amIBlank = Array.isArray(value)
        ? value.length === 0
        : ["", null, undefined].includes(value);

      // all fields are blank, error
      if (!areOtherFieldsValid(valueToCheck, rule.otherFields, payload) && amIBlank) {
        return false;
      }
      // our value is in the value list
      if (rule.comparator(valueToCheck, payload.state, payload.form)) {
        return null;
      }
      // one of the other fields is not blank and in the list, and we are not in the list
      if (areOtherFieldsValid(valueToCheck, rule.otherFields, payload)) {
        return null;
      }

      // one of the other fields is not blank, and we are not in the list, error
      if (areOtherFieldsValid(valueToCheck, rule.otherFields, payload)) {
        return false;
      }

      return false;
    },
  },
  requiredIfAllFieldValues: {
    message: name => `Missing ${name}`,
    fn: (value, payload) => {
      if (
        !["", undefined, null, false].includes(value) &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "object" && Object.keys(value).length === 0)
      ) {
        return null;
      }
      let isValid = false;
      Object.entries(payload.rules.requiredIfAllFieldValues.otherFields).forEach(
        ([name, field]) => {
          if (isFunction(field.comparator)) {
            isValid = isValid || !field.comparator(field.currentValue, payload.state, payload.form);
          } else {
            isValid = isValid || !(field?.comparator === field?.currentValue);
          }
        }
      );
      return isValid ? null : false;
    },
  },
  // if the value of a related field, returns true, when passed to my
  // comparator for that related field, then i am invalid
  // - if i have multiple related fields, any one of them failing the comparator
  //   results in this field being invalid
  requiredIfAnyFieldValue: {
    message: name => `Missing ${name}`,
    fn: (value, payload) => {
      const shouldIBeRequired = [];
      Object.entries(payload.rules.requiredIfAnyFieldValue.otherFields).forEach(([name, field]) => {
        if (isFunction(field.comparator)) {
          // check value
          shouldIBeRequired.push(field.comparator(field.currentValue, payload.state, payload.form));
        } else {
          shouldIBeRequired.push(field?.comparator === field?.currentValue);
        }
      });

      if (
        shouldIBeRequired.filter(v => !v).length > 0 ||
        (!["", undefined, null].includes(value) && !(Array.isArray(value) && value.length === 0))
      ) {
        return null;
      }

      // regular required validation
      if ([null, undefined].includes(value)) {
        return false;
      }
      if (typeof value === "object" && Object.keys(value).length === 0) {
        return false;
      }

      if (typeof value === "string" && value.length === 0) {
        return false;
      }

      if (Array.isArray(value) && value.length === 0) {
        return false;
      }

      if (Array.isArray(value)) {
        const a = value.every(v => {
          return v === ("" && "0");
        });
        if (a) {
          return false;
        }
      }

      if (value === undefined) {
        return false;
      }

      return null;
    },
  },
  required: {
    message: name => `Missing ${name}`,
    fn: (value, payload) => {
      if ([null, undefined, ""].includes(payload.currentValue)) {
        return false;
      }
      if (
        typeof payload.currentValue === "object" &&
        Object.keys(payload.currentValue).length === 0
      ) {
        return false;
      }
      if (
        (typeof payload.currentValue === "string" || Array.isArray(payload.currentValue)) &&
        payload.currentValue.length === 0
      ) {
        return false;
      }

      if (Array.isArray(payload.currentValue)) {
        if (payload.fieldType === "FormTimeField") {
          // if every time slice is empty string, we are invalid
          const tempArray = [null, null, null, null];
          const time = tempArray
            .map((v, i) =>
              [null, undefined].includes(payload.currentValue[i]) ? "" : payload.currentValue[i]
            )
            .join(":");

          let passed = /\d{0,2}:\d{0,2}:\d{0,2}:\d{0,3}/.test(time) && /[1-9]/.test(time);
          let allZeros = /0{1,2}:0{1,2}:0{1,2}:0{1,3}/.test(time);

          if (!passed && !allZeros) {
            return false;
          }
        } else if (payload.fieldType === "FormSelect") {
          if (payload.currentValue.length === 0) {
            return false;
          }
        } else if (payload.fieldType === "DateRange") {
          if (!payload.currentValue?.[0]?._d || !payload.currentValue?.[1]?._d) {
            return false;
          }
        }
      } else if (Array.isArray(payload.currentValue)) {
      }

      return null;
    },
  },
  greaterThan: {
    message: (name, rule) => {
      const { valueToCheck = false } = rule;
      let fields;
      let value;
      fields = (rule.fields || [])
        .map(field => {
          return final[field].props.label;
        })
        .join(", ");
      value = valueToCheck;

      return formatters.greaterOrLessThanOrEqual({
        limitValues: fields ? fields : value,
        direction: DIRECTION.GREATER_THAN,
      });
    },
    fn: (value, payload) => {
      const { fields, valueToCheck, zeroMeansDisabled } = payload.rules.greaterThan;
      let { orEqualToField, orEqualToValue } = payload.rules.greaterThan;

      let isValid = true;

      if (comparators.nullUndefinedOrEmptyString(value)) {
        return null;
      }

      value = parseFloat(value);

      if (value === 0 && zeroMeansDisabled === true) {
        return null;
      }

      if (Array.isArray(fields)) {
        fields.forEach(field => {
          const fieldKey = final[field].props.name;
          const fieldValue = parseInt(payload?.state?.fields[fieldKey]);

          if (isNaN(fieldValue) || isNaN(value)) {
            return false;
          } else {
            if (orEqualToField) {
              isValid = isValid && value >= fieldValue;
            } else {
              isValid = isValid && value > fieldValue;
            }
          }
        });
      }
      if (typeof valueToCheck === "number") {
        if (orEqualToValue) {
          isValid = isValid && value >= valueToCheck;
        } else {
          isValid = isValid && value > valueToCheck;
        }
      }
      return isValid ? null : false;
    },
  },
  lessThan: {
    message: (name, rule) => {
      const { valueToCheck = false } = rule;
      let fields;
      let value;
      fields = (rule.fields || [])
        .map(field => {
          return final[field].props.label;
        })
        .join(", ");
      value = valueToCheck;

      return formatters.greaterOrLessThanOrEqual({
        limitValues: fields ? fields : value,
        direction: DIRECTION.LESS_THAN,
      });
    },

    fn: (value, payload) => {
      const { fields, valueToCheck } = payload.rules.lessThan;
      let { orEqualToField, orEqualToValue } = payload.rules.lessThan;

      let isValid = true;

      if (comparators.nullUndefinedOrEmptyString(value)) {
        return null;
      }

      value = parseFloat(value);

      if (Array.isArray(fields)) {
        fields.forEach(field => {
          const fieldKey = final[field].props.name;
          const fieldValue = parseInt(payload?.state?.fields[fieldKey]);
          if (isNaN(fieldValue) || isNaN(value)) {
            return false;
          } else {
            if (orEqualToField) {
              isValid = isValid && value <= fieldValue;
            } else {
              isValid = isValid && value < fieldValue;
            }
          }
        });
      }
      if (typeof valueToCheck === "number") {
        if (orEqualToValue) {
          isValid = isValid && value <= valueToCheck;
        } else {
          isValid = isValid && value < valueToCheck;
        }
      }
      return isValid ? null : false;
    },
  },
};

function isFunction(functionToCheck) {
  return (
    functionToCheck &&
    functionToCheck !== undefined &&
    {}.toString.call(functionToCheck) === "[object Function]"
  );
}

// take a field value and an array of rule names, break on the first failure and return
// that message
// any reason this is a fn instead of just being the obj it returns?
const validationEngine = () => {
  return {
    getRuleText: rule => validationRules[rule].message,
    validate: payload => {
      const { label, rules, currentValue, fieldType } = payload;
      let value = currentValue;

      let returnText = undefined;

      if (Array.isArray(currentValue)) {
        value = currentValue;
      } else if (
        currentValue !== null &&
        typeof currentValue === "object" &&
        fieldType !== "DatePicker"
      ) {
        value = currentValue?.value;
      }

      const failedRules = Object.entries(rules || {}).reduce(
        (curr, [ruleName]) => {
          const { instant, submit } = curr;
          const result = validationRules[ruleName].fn(value, payload);
          if (result === false) {
            let derivedText = isFunction(validationRules[ruleName].message)
              ? validationRules[ruleName].message(label, rules[ruleName])
              : validationRules[ruleName].message;
            if (rules[ruleName].instant) {
              instant.push(derivedText);
            } else {
              submit.push(derivedText);
            }
          }
          return curr;
        },
        { instant: [], submit: [] }
      );

      if (failedRules.instant.length > 0) {
        return [failedRules.instant[0], true];
      } else if (failedRules.submit.length > 0) {
        return [failedRules.submit[0], false];
      } else {
        return [undefined, false];
      }
    },
  };
};

export default validationEngine;
