import { NotificationHub } from "@nef/core";
import { ApiResponseNames } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { TableButton } from "components/standardTable";
import { useUserContext } from "components/user";
import { hasValidMPIDFromReportingParty, getFieldValue, isReversal } from "utils/js.utils";
import { ViewForm, ViewForms } from "viewConstants";
import { Messages, TableButtonAction, TableButtonLabel } from "wksConstants";
import { getIsButtonDisabled } from "./util";
import { useViewModelContext, useViewModelDispatch } from "../../context";
import { useFormDispatch } from "../../form";
import {
  convertTableToForm,
  getNumSelectedRows,
  getSelectedRow,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";
const modifyButtonConfig = {
  icon: "pencil-alt",
  text: TableButtonLabel.MODIFY,
  actionId: TableButtonAction.MODIFY,
  requireSelect: true,
  allowMultiSelect: false,
  disabledMessage: Messages.ONE_ROW,
  header: TradesHeader,
};

export const ModifyButton = ({ table, form }) => {
  const [user] = useUserContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [viewData] = useViewModelContext();
  const formDispatch = useFormDispatch();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const [standardTableData] = useStandardTableContext();

  const [row, numSelected] = useMemo(() => {
    return [getSelectedRow(standardTableData[table]), getNumSelectedRows(standardTableData[table])];
  }, [table, standardTableData]);

  const handleModify = useCallback(() => {
    let message = "This trade was ignored due to invalid state";
    const subtitle = `Control#: ${row.controlNum ? row.controlNum : "N/A"}`;
    let validRow =
      hasValidMPIDFromReportingParty(
        getFieldValue(row?.reportingParty),
        row,
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
      ) && !["P" /*Pending Modify*/, "-" /*Rejected*/].includes(row.tradeStatus.value);
    if (validRow && isReversal(row[ApiResponseNames.reversalFlag])) {
      validRow = false;
      message = "Modify action is not supported for reversal trades";
    }

    if (!validRow) {
      NotificationHub.send("warning", message, {
        subtitle,
      });

      standardTableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: table },
      });
      return;
    }

    formDispatch([
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_COPY, entitlements: user.entitlements },
      },
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_REPAIR, entitlements: user.entitlements },
      },
      {
        type: "SET_FORM_VALUES_AND_PREMODIFIED",
        payload: {
          form: ViewForms[viewData.view][ViewForm.MODIFY],
          fields: convertTableToForm(row, user),
        },
      },
      {
        type: "UPDATE_CLEARING_PRICE",
        payload: {
          form: ViewForms[viewData.view][ViewForm.MODIFY],
        },
      },
    ]);

    viewDispatch([
      {
        type: "CHANGE_FORM",
        payload: { form: ViewForm.MODIFY, history },
      },
      {
        type: "ENABLE_FORM",
        payload: { form },
      },
    ]);

    standardTableDispatch({
      type: "SET_CLICKED_ROW",
      payload: { row, table: table },
    });
  }, [
    row,
    user.mpidAttributes,
    user.entitlements,
    formDispatch,
    viewData.view,
    viewDispatch,
    history,
    form,
    standardTableDispatch,
    table,
  ]);

  return (
    <TableButton
      table={table}
      {...modifyButtonConfig}
      disabled={getIsButtonDisabled(modifyButtonConfig, numSelected)}
      onClick={handleModify}
      key="tableButton_modify"
    />
  );
};
