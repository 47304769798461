import React from "react";
import { Section, Box } from "@nef/core";
import { useLayoutContext } from "../context";

let boxStyles = {
  boxSizing: "border-box",
  width: 0,
};
// TODO: overflow here seems like a hack
const Left = ({ children, isDisabled, onDisabledClick }) => {
  const [layoutData] = useLayoutContext();

  const newBoxStyles = { ...boxStyles, width: layoutData?.leftWidth || 0 };
  const disabled = isDisabled
    ? {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        cursor: "no-drop",
      }
    : { display: "none" };

  return (
    <Box paddingLeft={3} paddingVertical={3} style={newBoxStyles}>
      <Section className="bleep">
        {children}
        <div style={disabled} onClick={onDisabledClick}></div>
      </Section>
    </Box>
  );
};

export default Left;
