import { SpinnerIcon } from "components/styled";
import React from "react";

export const LoadingIcon: React.FC<{
  isLoading: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ isLoading, children, style }) => {
  if (isLoading) {
    return <SpinnerIcon style={style} />;
  }
  return <>{children}</>;
};
