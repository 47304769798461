import { Forms } from "components/fields/fieldConstants";
import { useUserContext } from "components/user";
import { produce } from "immer";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { generatePath, useParams } from "react-router";
import { ViewForm, ViewForms } from "viewConstants";
import { StandardTables } from "wksConstants";
import { Views } from "../../viewConstants";

const viewModelDispatch = createContext();
viewModelDispatch.displayName = "ViewModelDispatch";
export const useViewModelDispatch = () => {
  return useContext(viewModelDispatch);
};

const viewModelContext = createContext();
viewModelContext.displayName = "ViewModelContext";
export const useViewModelContext = () => {
  return useContext(viewModelContext);
};

const defaultState = {
  [`${Forms.ST_COPY.key}Disabled`]: true,
  [`${Forms.ST_MODIFY.key}Disabled`]: true,
  [`${Forms.ST_REPAIR.key}Disabled`]: true,
  [`${Forms.UPLOAD_REPAIR.key}Disabled`]: true,
  form: null,
  activeTableForm: Forms.TR_REPORT,
  activeTable: StandardTables.TR_REPORT,
  notifications: [],
  history: null,
  userData: {},
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_USER_DATA": {
      return { ...state, userData: action.payload };
    }
    case "CHANGE_VIEW": {
      const { history } = action.payload;
      let { view: nextView } = action.payload;

      if (!nextView) {
        return state;
      }
      if (state.userData.profileLoaded && !state.userData?.allowed?.views?.[nextView]) {
        nextView = Views.FOUR_OH_THREE;
      }
      if (history.location.pathname !== `/${nextView}`) {
        history.push(`/${nextView}`);
      }
      const form = ViewForms?.[nextView]?.[ViewForm.DEFAULT];
      return { ...state, form };
    }
    case "CHANGE_FORM": {
      const { history, form } = action.payload;
      const nextPath = generatePath(`/${state.view}/:form?`, {
        form,
      });
      if (history?.location?.pathname !== nextPath)
        history.push({
          pathname: nextPath,
        });
      return state;
    }
    case "ENABLE_FORM": {
      const { form } = action.payload;
      return produce(state, draft => {
        draft[`${form.key}Disabled`] = false;
      });
    }
    case "DISABLE_FORM": {
      const { form } = action.payload;
      return produce(state, draft => {
        draft[`${form.key}Disabled`] = true;
      });
    }
    case "CANCEL_FORM":
      const { history } = action.payload;
      history.push(`/${state.view}`);
      return state;
    case "SET_VIEW": {
      let { view = Views.REPORT, form = ViewForm.DEFAULT } = action.payload;

      if (ViewForms[view]) {
        return {
          ...state,
          view,
          form: ViewForms[view][form],
        };
      } else {
        return {
          ...state,
          view,
          form: null,
        };
      }
    }
    case "SET_FORM": {
      const { form = ViewForm.DEFAULT } = action.payload;
      return {
        ...state,
        form: ViewForms[state.view][form],
      };
    }
    case "SET_HISTORY": {
      return {
        ...state,
        history: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

const ViewContext = ({ children }) => {
  const [state, dispatchF] = useReducer(DispatchFn, defaultState);
  const { view, form } = useParams();
  const [userData] = useUserContext();

  useEffect(() => {
    dispatchF({
      type: "SET_VIEW",
      payload: { view, form },
    });
  }, [view, form]);

  useEffect(() => {
    dispatchF({
      type: "SET_USER_DATA",
      payload: userData,
    });
  }, [userData]);

  return (
    <viewModelDispatch.Provider value={dispatchF}>
      <viewModelContext.Provider value={[state, dispatchF]}>{children}</viewModelContext.Provider>
    </viewModelDispatch.Provider>
  );
};

export default ViewContext;
