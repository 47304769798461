import React, { useCallback, useEffect, useState } from "react";
import { ActiveForm, EqrcRuleStatus, handleImplicitDecimals } from ".";
import {
  EQRC_FIELD_PREFIX,
  EQRC_SHARES_LOCATED_VALUES,
  EqrcFields,
  OPPOSITE_ROW,
  getEqrcIdFromResponse,
} from "./constants";
import { NotificationHub } from "@nef/core";
import { useFormContext, useFormDispatch } from "../../form";
import { getFormValidation, useStandardTableDispatch } from "../../standardTable";
import { createRule, createBody } from "./network";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { StandardTables } from "wksConstants";
import { addPrefixToField } from "utils/js.utils";
import { Forms } from "components/fields";

export const EQRCNewForm = ({
  table,
  header,
  form,
  fieldSets,
  isOpen,
  onClose,
  setStep,
  currentStep,
  totalSteps,
}) => {
  const standardTableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [isSaving, setSaving] = useState(false);
  const [userData] = useUserContext();

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const handleCreate = useCallback(
    (table, json, showNotifications = true) => {
      const eqrcId = getEqrcIdFromResponse(json);
      json[EqrcFields.eqrcId] = eqrcId;

      let data = { [EqrcFields.eqrcId]: eqrcId, [json[EqrcFields.status]]: json };
      if (table === StandardTables.EQRC_ALERT_CONFIG) {
        data = { [EqrcFields.eqrcId]: eqrcId, [EqrcRuleStatus.active]: json };
      }
      data.status = json[EqrcFields.status];
      handleImplicitDecimals(data[EqrcRuleStatus.configured]);
      handleImplicitDecimals(data[EqrcRuleStatus.active]);
      standardTableDispatch([
        {
          type: "ADD_TABLE_DATA",
          payload: { table, data },
        },
        { type: "SET_IS_LOADING", payload: { table, isLoading: false } },
      ]);

      setSaving(false);
      onClose();
      formDispatch({ type: "RESET_FORM", payload: { form } });
      NotificationHub.send("success", "Rule Creation Success");
    },
    [form, formDispatch, standardTableDispatch, onClose]
  );

  const handleCreateError = useCallback(
    (table, error, cbArgs) => {
      const message = error?.message ? (
        error.message
      ) : (
        <ul>
          {Object.entries(error).map(([field, message]) => (
            <li key={`${field}_${message}`}>{`${field} ${message}`}</li>
          ))}
        </ul>
      );

      NotificationHub.send("danger", "Rule creation failed", { subtitle: message });
      standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
      setSaving(false);
    },
    [standardTableDispatch]
  );

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );

  const handleSave = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);

    if (!valid) {
      return;
    }

    standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: true } });
    setSaving(true);
    let fields = formData[form.key].fields;
    let body = createBody(fields);

    createRule(
      userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
      false,
      body,
      table,
      handleCreate,
      handleCreateError
    );
  }, [
    form,
    formData,
    formDispatch,
    standardTableDispatch,
    table,
    userData,
    handleCreate,
    handleCreateError,
  ]);

  const clearBrokers = useCallback(() => {
    formDispatch({
      type: "UPDATE_FORM_VALUE",
      payload: {
        form,
        field: EqrcFields.brokersListRule,
        value: undefined,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule),
      },
    });
  }, [form, formDispatch]);

  const brokerList = formData[form.key].fields[EqrcFields.allowBrokerList];
  const isDisabled =
    formData[form.key].fields[EqrcFields.sharesLocatedOption]?.value !==
    EQRC_SHARES_LOCATED_VALUES.DISABLED;

  useEffect(() => {
    formDispatch({
      type: "WRITE_FORM_VALUE",
      payload: {
        form,
        fields: {
          [`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`]: brokerList || isDisabled,
        },
      },
    });
  }, [brokerList, isDisabled, form, formDispatch, currentStep]);

  return (
    <ActiveForm
      table={table}
      header={header}
      form={form}
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      isSaving={isSaving}
      includeExchMpidPort={true}
      setStep={setStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      validate={validate}
      onSecondary={clearBrokers}
    />
  );
};
