import { FieldNames, SelectOptions } from "./fieldConstants";

export const comparators = {
  inMPIDList: (v, state, form) => {
    const options = SelectOptions[FieldNames.mpid](form.key) || [];
    const valueToCheck = Array.isArray(v) ? v?.[0]?.label : v;

    return options.map(o => o.label).includes(valueToCheck);
  },
  notInMPIDList: (v, state, form) => {
    const options = SelectOptions[FieldNames.mpid](form.key) || [];
    const valueToCheck = Array.isArray(v) ? v?.[0]?.label : v;

    return !options.map(o => o.label).includes(valueToCheck);
  },

  emptyArrayorString: v => {
    return (Array.isArray(v) && v.length === 0) || ["", undefined].includes(v);
  },
  nullUndefinedOrEmptyString: v => v === null || v === undefined || v === "",
  falseNullOrUndefined: v => {
    return v === false || v === null || v === undefined;
  },
  falseNullUndefinedOrEmpty: v => {
    return (
      v === false ||
      v === null ||
      v === undefined ||
      (Array.isArray(v) && v.length === 0) ||
      v === ""
    );
  },
};
