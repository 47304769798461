import { Button } from "@nef/core";
import { FieldLoop, riskAlertFields5a, riskAlertFields5b } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { useFormDispatch } from "components/form/formContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  ADD_EMAIL_BTN_ID,
  PtraFieldSection,
  SectionFieldsWrapper,
  SectionHeader,
} from "../constants";
import { usePtraEmailContext } from "../email";
import { PTRAddEmailPopover } from "./emailPopover";

const EmailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
`;

const AddEmailButton = styled(Button)`
  margin-top: 21.5px;
`;

export const PtrEmailDeliveryOptions: React.FC<PtraFieldSection> = ({ form }) => {
  const containerRef = useRef<HTMLElement>(null);
  const [emailData] = usePtraEmailContext();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const formDispatch = useFormDispatch();

  useEffect(() => {
    return () => {
      formDispatch({
        type: "RESET_FORM",
        payload: { form: Forms.PTRA_EMAIL_MANAGER },
      });
    };
  }, [formDispatch]);

  const handleTogglePopover = useCallback(() => {
    if (isPopoverOpen) {
      formDispatch({
        type: "RESET_FORM",
        payload: { form: Forms.PTRA_EMAIL_MANAGER },
      });
    }
    setPopoverOpen(!isPopoverOpen);
  }, [formDispatch, isPopoverOpen]);

  const EmailField = useMemo(() => {
    return () => (
      <FieldLoop
        key={emailData.refreshCount}
        isReactFragment={true}
        form={form}
        fields={riskAlertFields5b}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    );
  }, [form, emailData.refreshCount]);

  return (
    <section ref={containerRef} style={{ position: "relative" }}>
      <SectionHeader>Email Delivery Options</SectionHeader>
      <SectionFieldsWrapper>
        <FieldLoop
          isReactFragment={true}
          form={form}
          fields={riskAlertFields5a}
          classNames={undefined}
          augmentOnChange={undefined}
          augmentOnCreate={undefined}
          portalRef={undefined}
          isDisabled={undefined}
          containerRef={undefined}
          showLabel={undefined}
        />
        <EmailWrapper>
          {EmailField()}
          <AddEmailButton
            onClick={handleTogglePopover}
            color="secondary"
            size="sm"
            id={ADD_EMAIL_BTN_ID}
          >
            New Email
          </AddEmailButton>
        </EmailWrapper>
        <PTRAddEmailPopover
          isOpen={isPopoverOpen}
          onToggle={handleTogglePopover}
          containerRef={containerRef}
        />
      </SectionFieldsWrapper>
    </section>
  );
};
