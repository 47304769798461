import moment from "moment-timezone";
import { TableColumn } from "../../eqrc/columnDefinitions";
import { Button, Popover, Tag } from "@nef/core";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import {
  StandardHeader,
  StandardTable,
  columnDefinitions,
  useStandardTableDispatch,
} from "components/standardTable";
import { useCallback } from "react";

export type Row = {
  symbol: string;
  requestId: string;
  requestStatus: string;
  lastUpdate: string;
};

export enum PORModel {
  SYMBOL = "symbol",
  MPID = "mpid",
  PRICE = "price",
  QUANTITY = "quantity",
  REJECT_REASON = "rejectReason",
}
const WrappedButton = styled(Button)`
  padding: 3px !important;
  height: 15px;
  width: 41px;
`;

export const columns = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 100 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 100 },
  { Header: "Price", id: "price", accessor: "price", width: 100 },
  { Header: "Quantity", id: "quantity", accessor: "quantity", width: 100 },
  { Header: "Reject Reason", id: "rejectReason", accessor: "rejectReason", width: 200 },
];

export enum PORModel2 {
  SYMBOL = "symbol",
  MPID = "mpid",
  REQUEST_STATUS = "requestStatus",
  REQUESTOR = "requestor",
  REQUEST_ID = "requestId",
  LAST_UPDATE_TIME = "lastUpdateTime",
}

export const columns3 = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 60 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 60 },
  {
    Header: "Request Status",
    id: "requestStatus",
    accessor: "requestStatus",
    width: 100,
    Cell: ({ row }: { row: any }) => {
      let color = "success";
      if (row.original.requestStatus === "Denied") {
        color = "danger";
      } else if (row.original.requestStatus === "Pending") {
        color = "primary";
      }
      return <Tag color={color}>{row.original.requestStatus}</Tag>;
    },
  },
  { Header: "Parameter Status", id: "openUntil", accessor: "openUntil", width: 80 },
  { Header: "Requestor", id: "requestor", accessor: "requestor", width: 150 },
  { Header: "Request ID", id: "requestId", accessor: "requestId", width: 80 },
  { Header: "Last Update Time", id: "lastUpdateTime", accessor: "lastUpdateTime", width: 160 },
];
export const columns2 = columns3;
