import { CSSGrid, NotificationHub } from "@nef/core";
import Middle from "components/middle";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import { RequestResult, StandardTables } from "wksConstants";
import React, { useCallback, useEffect } from "react";
import { OrderEntryTableButtons } from "./orderEntryTableButtons";
import { formatUrl } from "utils/js.utils";
import { doFetchWrapper } from "network";
import { getHeaders } from "keycloak";
import { convertRashOrderPrices, MATH_OPERATOR, RashOrder } from "./constants";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";

const title = "Order Entry";
export const OrderEntryMiddle = () => {
  const [userData] = useUserContext();
  const tableDispatch = useStandardTableDispatch();
  const Header = useCallback(() => <StandardHeader title={title} />, []);

  useEffect(() => {
    if (userData[INITIAL_DATA_MODEL.configDataResult] === RequestResult.success) {
      const success = (data: RashOrder[]) => {
        data.forEach(order => {
          convertRashOrderPrices(order, MATH_OPERATOR.DIVIDE);
        });

        tableDispatch([
          { type: "SET_TABLE_DATA", payload: { table: StandardTables.ORDER_ENTRY, data } },
          {
            type: "SET_IS_LOADING",
            payload: { table: StandardTables.ORDER_ENTRY, isLoading: false },
          },
        ]);
      };

      const error = () => {
        NotificationHub.send("danger", "Error retrieving orders");
        tableDispatch({
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.ORDER_ENTRY, isLoading: false },
        });
      };

      tableDispatch({
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.ORDER_ENTRY, isLoading: true },
      });
      doFetchWrapper(
        formatUrl(
          `${userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.rashWebServiceUrl]}`,
          `order/orders`
        ),
        {
          headers: getHeaders(),
        },
        success,
        error
      );
    }
  }, [tableDispatch, userData]);

  return (
    <Middle>
      <CSSGrid rows="100%" cols="100%">
        <StandardTable
          table={StandardTables.ORDER_ENTRY}
          header={Header}
          enableLayoutExport={true}
          isSingleSelect={false}
          additionalRowClick={undefined}
          isColumnsVirtualized={false}
          isFilterable={true}
          hideQueryDate={true}
          subHeader={undefined}
          hideRowCount={undefined}
          headerMenu={<OrderEntryTableButtons />}
          exportCallback={undefined}
          exportFileName={undefined}
          isEditableTableEnabled={undefined}
          Header={undefined}
          isRowSelectEnabled={true}
          style={undefined}
          hideSelectedCount={undefined}
        />
      </CSSGrid>
    </Middle>
  );
};
