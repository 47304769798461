import React, { useCallback } from "react";
import { REJ_PRICE_OO_OVERRIDE_RANGE } from "./constant";
import { usePvMemberDispatch } from "./context";

export const RejectCol: React.FC<{ numRejects: number; symbol: string }> = ({
  numRejects,
  symbol,
}) => {
  const pvMemberDispatch = usePvMemberDispatch();

  const handleClickRejects = useCallback(() => {
    pvMemberDispatch({
      type: "SET_FILTER",
      payload: {
        symbol,
        reject: REJ_PRICE_OO_OVERRIDE_RANGE,
      },
    });
  }, [pvMemberDispatch, symbol]);

  return <a onClick={handleClickRejects}>{numRejects}</a>;
};
