import { NotificationHub } from "@nef/core";
import { ApiResponseNames } from "../fields";
import React from "react";
import { TableButtonAction } from "../../wksConstants";
import { NotificationParagraph } from "components/styled";

export const notifyInvalid = (rows, excluded) => {
  const controlNums = rows
    .filter(r => {
      return !!r.controlNum;
    })
    .map(r => r.controlNum)
    .join(", ");

  NotificationHub.send("warning", `${excluded} trades(s) ignored due to invalid state`, {
    subtitle: `Control#(s): ${controlNums.length ? controlNums : "N/A"}`,
  });
};

export const notifyFail = (rows, fail, actionId) => {
  const controlNums = rows
    .filter(r => !!r.controlNum)
    .filter(r => fail.controlNums.includes(r.controlNum))
    .map(r => r.controlNum)
    .join(", ");

  let title = `${fail.length} trade(s) did not succeed.`;
  let subtitle = `Control#(s): ${controlNums.length ? controlNums : "N/A"}`;
  switch (actionId) {
    case TableButtonAction.REVERSE:
      const badRequestMessages = Object.keys(fail.badRequestCount);
      let count = 0;
      if (badRequestMessages.length > 0) {
        let badRequestSubTitle = badRequestMessages.map(message => {
          count += fail.badRequestCount[message];
          return <NotificationParagraph>{message}</NotificationParagraph>;
        });
        NotificationHub.send("danger", `${count} invalid trade reversal(s).`, {
          subtitle: badRequestSubTitle,
        });
      }
      if (fail.length > 0) {
        title = `${fail.length} trade reversal(s) did not succeed.`;
        subtitle = (
          <>
            Control#(s): {controlNums.length ? controlNums : "N/A"}
            <br />
            Please go to My Reported Trades or Reject Scan to repair.
          </>
        );
        NotificationHub.send("danger", title, {
          subtitle,
        });
      }
      break;
    default:
      NotificationHub.send("danger", title, {
        subtitle,
      });
      break;
  }
};

export const notifySuccess = (rows, success, actionId) => {
  const controlNumsArr = [];
  rows.forEach(row => {
    if (row.controlNum) {
      if (success.controlNums.includes(row[ApiResponseNames.controlNum])) {
        controlNumsArr.push(row.controlNum);
      } else if (
        Object.keys(success.originalControlNums).includes(row[ApiResponseNames.controlNum])
      ) {
        controlNumsArr.push(success.originalControlNums[row[ApiResponseNames.controlNum]]);
      }
    }
  });
  const controlNums = controlNumsArr.join(", ");

  let title = `${success.length} trade(s) processed successfully.`;
  let subtitle = `Control#(s): ${controlNums.length ? controlNums : "N/A"}`;
  switch (actionId) {
    case TableButtonAction.REVERSE:
      title = `${success.length} trade reversal(s) processed successfully.`;
      break;
    default:
      break;
  }

  NotificationHub.send("success", title, {
    subtitle,
  });
};
