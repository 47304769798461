import { Flex, Section, SectionBody } from "@nef/core";
import { getColor } from "@nef/style-utils";
import React, { useMemo } from "react";
import { StandardTableBody, StandardTableHeader } from ".";
import styled from "styled-components";

const SectionComponent = styled(Section)`
  background-color: ${props => getColor("gray", 0)(props)};
  height: 100%;
  box-sizing: border-box;
`;

const StyledFlexHeaderWrapper = styled(Flex)`
  padding-bottom: 0rem !important;
  width: 100%;
  box-sizing: border-box;
`;

const StyledSectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 20px);
  padding: 1rem 1rem 0rem 1rem;
  box-sizing: border-box;
`;

export const StandardTable = ({
  subHeader,
  table,
  hideRowCount,
  hideQueryDate,
  hideSelectedCount,
  headerMenu,
  enableLayoutExport,
  useIdAsHeaders = false,
  exportCallback,
  exportFileName,
  additionalRowClick,
  isSingleSelect = false,
  isColumnsVirtualized = true,
  isEditableTableEnabled,
  isFilterable,
  style,
  isRowSelectEnabled,
  Header,
  header,
  isExpandingRowsEnabled = false,
}) => {
  const wrapperStyle = useMemo(() => {
    return {
      height: "100%",
      ...style,
    };
  }, [style]);

  const HeaderFn = useMemo(() => {
    return (
      <StyledFlexHeaderWrapper flexDirection="column">
        <StandardTableHeader
          headerMenu={headerMenu}
          enableLayoutExport={enableLayoutExport}
          useIdAsHeaders={useIdAsHeaders}
          exportCallback={exportCallback}
          Header={header}
          subHeader={subHeader}
          table={table}
          exportFileName={exportFileName}
          hideRowCount={hideRowCount}
          hideSelectedCount={hideSelectedCount}
          hideQueryDate={hideQueryDate}
          isExpandingRowsEnabled={isExpandingRowsEnabled}
        />
        {Header && <Header />}
      </StyledFlexHeaderWrapper>
    );
  }, [
    headerMenu,
    enableLayoutExport,
    useIdAsHeaders,
    exportCallback,
    header,
    subHeader,
    table,
    exportFileName,
    hideRowCount,
    hideQueryDate,
    isExpandingRowsEnabled,
    Header,
  ]);

  if (!table) {
    return <></>;
  }

  return (
    <div style={wrapperStyle}>
      <SectionComponent key={table} id={table}>
        <StyledSectionHeader>{HeaderFn}</StyledSectionHeader>
        <SectionBody className="heightOverride">
          <StandardTableBody
            table={table}
            additionalRowClick={additionalRowClick}
            isSingleSelect={isSingleSelect}
            isColumnsVirtualized={isColumnsVirtualized}
            isFilterable={isFilterable}
            isDraggable={true}
            isEditableTableEnabled={isEditableTableEnabled}
            isRowSelectEnabled={isRowSelectEnabled}
            isExpandingRowsEnabled={isExpandingRowsEnabled}
          />
        </SectionBody>
      </SectionComponent>
    </div>
  );
};
