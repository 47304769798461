import { Box, FormTimeField } from "@nef/core";
import { FieldBox } from "components/styled";
import React, { memo, useCallback, useMemo, useState } from "react";
import { execOrReturn, padTimeArrayValue } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";

const WorkXTimeField = props => {
  const [userData] = useUserContext();
  const { value, style, isRequired, form, name, id, handleInputChange: onInputChange } = props;

  const fieldDataKey = id ? id : name;
  let fieldData;
  if (fieldDataKey && final[fieldDataKey]) {
    fieldData = execOrReturn(final[fieldDataKey], {
      form,
      entitlements: userData.entitlements,
    });
  }

  const numberOfTrailingDigits = useMemo(() => {
    let number = 3;
    if (typeof fieldData.props?.maxLength?.[form.key]?.[3] === "number") {
      number = fieldData.props?.maxLength?.[form.key]?.[3];
    }
    return number;
  }, [fieldData, form.key]);

  const handleChange = useCallback(
    e => {
      let { value } = e;

      const forceUpperCase = fieldData && fieldData.props.forceUpperCase;
      if (forceUpperCase && typeof value === "string") {
        value = value.toUpperCase();
      }

      if (onInputChange) {
        onInputChange({
          id,
          name,
          value,
          fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
        });
      }
    },
    [fieldData, onInputChange, id, name, form.key]
  );

  const handleBlur = useCallback(
    e => {
      const { value } = e;
      if (!e.currentTarget.contains(e.relatedTarget)) {
        if (onInputChange) {
          onInputChange({
            id,
            name,
            value: padTimeArrayValue(value, numberOfTrailingDigits),
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [onInputChange, id, name, numberOfTrailingDigits, fieldData.props.augmentOnChange, form.key]
  );

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <FormTimeField
        {...props}
        value={value || ["", "", "", ""]}
        onChange={handleChange}
        onBlur={handleBlur}
        numberOfTrailingDigits={numberOfTrailingDigits}
        use24hours={true}
        autoComplete="off"
      />
    </FieldBox>
  );
};

export default memo(WorkXTimeField);
