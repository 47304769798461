import { NavbarDropDownContent, NavbarIcon, NavbarIconContainer, Tooltip } from "@nef/core";
import { NotificationNavbarIcon } from "components/styled";
import moment from "moment";
import React, { useCallback, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { BellNotification } from ".";
import { useBellContext } from "./bellContext";

export const Bell: React.FC<{ id: string; tooltip: string }> = ({ id, tooltip }) => {
  const [bellData] = useBellContext();
  const [isHovered, setHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHovered(false);
  }, []);

  return (
    <NavbarIconContainer onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} title={tooltip}>
      <NotificationNavbarIcon
        id={id}
        iconClassName="fa-exclamation-circle"
        $hasNotification={bellData.clearingRelationships.length > 0}
      />
      <NavbarDropDownContent>
        {bellData.clearingRelationships.slice(0, 20).map((relationship: any) => {
          const validOn = moment(relationship.firstValidDay, "YYYYMMDD");
          return (
            <BellNotification
              key={`${relationship.clearingMPID}_${relationship.correspondentMPID}`}
              clearingFirmMPID={relationship.clearingMPID}
              correspondentMPID={relationship.correspondentMPID}
              text={`Please update Risk settings for ${
                relationship.correspondentMPID
              }, Active as of ${validOn.format("MM/DD/YYYY")}`}
              subtext={undefined}
            />
          );
        })}
      </NavbarDropDownContent>
      {/* <Tooltip target={id} isOpen={isHovered} offsetTop={4}>
        {tooltip}
      </Tooltip> */}
    </NavbarIconContainer>
  );
};
