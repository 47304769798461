import React, { memo } from "react";
import { TableButton, TableDropDown } from ".";
import { useStandardTableContext } from "./standardTableContext";

const TableButtons = props => {
  const { table, buttons, identifier } = props;

  const [standardTableData] = useStandardTableContext();

  // TODO: jeff - sort out this race condition
  if (!table) {
    return;
  }

  const selectedRows = standardTableData[table].selected;
  const id = identifier ? identifier : table;

  return (
    <>
      {buttons.map((props, i) => (
        <ButtonWrapper
          table={table}
          {...props}
          selectedRows={selectedRows}
          key={`id_${i}`}
          identifier={id}
        />
      ))}
    </>
  );
};

export default memo(TableButtons);

const ButtonWrapper = props => {
  const {
    table,
    identifier,
    text,
    tag,
    icon,
    isDisabled,
    requireSelect,
    allowMultiSelect,
    selectedRows,
    allowMenuOverride,
    children,
  } = props;

  const id = `${identifier}_${text}`;
  const disabled =
    isDisabled ||
    (requireSelect &&
      (Object.keys(selectedRows).length === 0 ||
        (!allowMultiSelect && Object.keys(selectedRows).length > 1)));
  // this button received a flag to render the drop down
  if (tag) {
    return (
      <TableDropDown
        table={table}
        key={`${table}_${text}_dropdown`}
        id={id}
        IconButton={{ thing: TableButton, ...props, disabled }}
        allowMenuOverride={allowMenuOverride}
        selectedRows={selectedRows}
      />
    );
  }
  return (
    <TableButton
      table={table}
      {...props}
      disabled={disabled}
      key={`tableButton_${icon}`}
      allowMenuOverride={allowMenuOverride}
      requireSelect={requireSelect}
    >
      {children}
    </TableButton>
  );
};
