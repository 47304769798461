import { Button, CSSGrid, NotificationHub } from "@nef/core";
import { FieldLoop, LimitMonitorSelect } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { getConvertedRiskLimitsForUI } from "components/limitMonitor/constants";
import {
  exportRiskLimitConfigs,
  EXPORT_RISK_LIMIT_FAILED_MESSAGE,
  EXPORT_RISK_LIMIT_RECEIVED_MESSAGE,
} from "components/limitMonitor/constants";
import { getHeaders } from "keycloak";
import moment from "moment-timezone";
import { doFetchWrapper } from "network";
import React, { useCallback, useMemo, useState } from "react";
import { SettingsWrapper } from ".";
import { formatUrl, getFileNameDate } from "../../utils/js.utils";
import { FieldNames } from "../fields/fieldConstants";
import { useFormContext } from "../form/formContext";
import { FooterFlex } from "./styled";

export const LimitSettingExport = () => {
  const [formData] = useFormContext();
  const [isLoading, setLoading] = useState(false);

  const clearingRelationshipIds = useMemo(() => {
    const mpids = formData[Forms.SETTINGS_PTR_LIMIT_EXPORT.key].fields[FieldNames.mpid];
    if (Array.isArray(mpids)) {
      return mpids.reduce((acc, curr) => {
        acc.push(JSON.parse(curr.value)?.id);
        return acc;
      }, []);
    } else {
      return [];
    }
  }, [formData]);

  const handleGetConfigsCb = useCallback(data => {
    const configs = data.map(config => getConvertedRiskLimitsForUI(config));
    const fileName = `LIMIT_SETTING_EXPORT${getFileNameDate(new moment())}.csv`;
    exportRiskLimitConfigs(fileName, configs);
    setLoading(false);
  }, []);

  const handleErrorCb = useCallback(() => {
    NotificationHub.send("danger", EXPORT_RISK_LIMIT_FAILED_MESSAGE);
    setLoading(false);
  }, []);

  const handleSave = useCallback(() => {
    NotificationHub.send("success", EXPORT_RISK_LIMIT_RECEIVED_MESSAGE);
    setLoading(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_LIMO_WS, "getrcamriskconfigs"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ ids: clearingRelationshipIds }),
      },
      handleGetConfigsCb,
      handleErrorCb
    );
  }, [clearingRelationshipIds, handleErrorCb, handleGetConfigsCb]);

  return (
    <>
      <SettingsWrapper
        header="Post - Trade Risk: Limit Setting Export"
        errorMessage={formData[Forms.SETTINGS_LIMO.key].globalErrorMessage}
      >
        <FieldLoop form={Forms.SETTINGS_PTR_LIMIT_EXPORT} fields={LimitMonitorSelect} />
      </SettingsWrapper>
      <FooterFlex justifyContent="flex-end" flexDirection="rows">
        <Button
          disabled={isLoading || clearingRelationshipIds.length === 0}
          isLoading={isLoading}
          onClick={handleSave}
        >
          Export
        </Button>
      </FooterFlex>
    </>
  );
};
