import { Box, FontAwesomeIcon, NavbarIcon, Indicator, Flex } from "@nef/core";
import styled, { css, keyframes } from "styled-components";
import { getColor } from "@nef/style-utils";

export const NotificationParagraph = styled.p`
  margin: 0.5rem 0rem;
`;

export const SpinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerIcon = styled(FontAwesomeIcon).attrs({ iconClassName: "fa-spinner" })`
  ${css`
    animation: ${SpinnerKeyframes} 1s infinite linear;
  `};
`;

export const FieldBox = styled(Box)<{ $isRequired: boolean }>`
  ${props =>
    props.$isRequired
      ? `
      & label {
        &::before {
        content: "●";
        color: ${getColor("warning", 500)(props)};
    
        font: size 1.1rem;
        margin-right: 3px;
      }
    `
      : ""}
`;

export const SuspenseLoading = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  position: absolute;
  flex-direction: column;
  color: ${props => getColor("primary", 500)(props)};
`;

export const SuspenseText = styled.span`
  margin-top: 45px;
`;

export const NotificationNavbarIcon = styled(NavbarIcon)<{ $hasNotification: boolean }>`
  position: relative;
  ${props => {
    return `${
      props.$hasNotification
        ? `
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      right: 4px;
      background-color: ${getColor("danger", 500)(props)};
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      color: ${getColor("gray", 0)(props)};
      border: 1px solid ${getColor("gray", 0)(props)};
      `
        : ""
    }}`;
  }}
`;

const pulse = (props: any) => keyframes`
  0% {
    background: ${getColor("gray", 300)(props)};
    transform: scale(0);
    opacity: 0.9;
  }

  100% {
    background: ${getColor("gray", 900)(props)};
    opacity: 0;
    transform: scale(1.1);
  }
`;

export const CenteredIndicator = styled(Indicator)<{ $isLoading: boolean }>`
  margin: 0 auto;
  ${props =>
    props.$isLoading
      ? css`
          animation: 1.25s cubic-bezier(0.1, -0.31, 0.58, 1) infinite ${css`
              ${pulse}`};
          outline: 1px solid transparent;
        `
      : ""}
`;

export const FlexNarrowBar = styled(Flex)`
  height: 68px;
  width: 100%;
  position: relative;
  color: ${getColor("gray", 500)};
  & > div {
    height: 68px;
    overflow-x: scroll;
    overflow-y: hidden;
    & > div {
      height: 68px;
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }
  & p,
  h1 {
    margin: 0 0.25rem;
  }

  & h1 > span {
    white-space: nowrap;
  }
  & p {
    font-size: 1.1rem;
    white-space: nowrap;
  }

  & p:first-child {
    padding-left: 1rem;
  }

  & > div > div > div > div {
    height: 32px;

    margin: 0.75rem;

    &:last-child {
      border-right-width: 0;
    }
  }
`;
