import { Box, Indicator } from "@nef/core";
import { CenteredIndicator } from "components/styled";
import React, { memo, useCallback } from "react";
import { FieldNames, SelectOptions } from "../fields/fieldConstants"; // use full path to avoid circular dependency, maybe just move const files to const dir

export const statusOptions = [
  ...[
    { id: 0, value: null, label: "" },
    { id: -1, value: "Successes", label: "Successes" },
    { id: -2, value: "Rejected", label: "Rejected" },
  ],
  ...(SelectOptions?.[FieldNames["tradeStatusVals"]] || []),
].slice(0, -1);

export const statusFilter = (filter, row) => {
  switch (filter.value) {
    case "All": {
      return true;
    }
    case "Rejected": {
      return row[filter.id] === "Rejected";
    }
    case "Successes": {
      return row[filter.id] !== "Rejected";
    }
    default:
      break;
  }
  return row[filter.id] === filter.value;
};
export const StatusFilter = ({ filter, onChange }) => {
  const handleOnChange = useCallback(event => onChange(event.target.value), [onChange]);

  return (
    <select onChange={handleOnChange} style={{ width: "100%" }} value={filter ? filter.value : ""}>
      {statusOptions.map(o => (
        <option value={o.label} key={`statusFiler_${o.label}`}>
          {o.label}
        </option>
      ))}
    </select>
  );
};
export const Good = memo(({ tradeActionType, ackType }) => {
  return (
    <>
      <h4>
        Action {tradeActionType}, Result: {ackType}
      </h4>
    </>
  );
});
export const Bad = memo(({ tradeActionType, ackType, statusText = "" }) => {
  return (
    <>
      <h4>
        Action {tradeActionType}, Result: {ackType}
      </h4>
      <p>{statusText && statusText}</p>
    </>
  );
});
export const IndicatorWrapper = memo(({ status, statusText, tradeActionType, index, ackType }) => {
  if (!status) {
    return <></>;
  }
  const color = ["disabled", "loading"].includes(status) ? "primary" : status;
  if (!statusText && status === "loading") {
    return (
      <CenteredIndicator
        tooltipColor="danger"
        $isLoading={true}
        color={color}
        size="md"
        id={`bubble_${index}`}
      ></CenteredIndicator>
    );
  }

  if (!Array.isArray(statusText)) {
    statusText = [statusText];
  }
  return (
    <CenteredIndicator tooltipColor="danger" color={color} size="md" id={`bubble_${index}`}>
      <Box paddingHorizontal={3}>
        {
          // this is a loop. this is a loop.
          statusText.map(s => {
            const { statusText, tradeActionType, ackType } = s;
            return (
              <>
                {!statusText && <Good tradeActionType={tradeActionType} ackType={ackType} />}
                {!!statusText?.length && (
                  <Bad
                    tradeActionType={tradeActionType}
                    ackType={ackType}
                    statusText={statusText}
                  />
                )}
              </>
            );
          })
        }
      </Box>
    </CenteredIndicator>
  );
});
