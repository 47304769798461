import React from "react";
import { Section, CSSGrid } from "@nef/core";
import Middle from "components/middle";
import { FileTable, ErrorSuccessTables } from ".";

export const UploadMiddle = () => {
  return (
    <Middle>
      <CSSGrid rows="calc(40% - 8px) calc(60% - 8px);" cols="100%" gap="16px">
        <FileTable />
        <ErrorSuccessTables />
      </CSSGrid>
    </Middle>
  );
};
