import faker from "faker";
import { Factory } from "rosie";
import moment from "moment";
import * as refaker from "utils/faker.utils";

// generate realistic fake data that can be used to populate tables or forms
// - first param to attr is the NAME of the input for which data is created
Factory.define("table_demo")
  .sequence("id")
  .attr("status", () => null)
  .attr("statusText", () => faker.hacker.phrase())
  .attr("mpid", () =>
    faker.random.arrayElement([[{ value: "AUTO", label: "AUTO" }], [{ value: "BARD", label: "BARD" }]])
  )
  .attr("trf", () =>
    faker.random.arrayElement([[{ value: 2, label: "Chicago" }], [{ value: 1, label: "Carteret" }]])
  )
  .attr("reportingParty", () =>
    faker.random.arrayElement([
      { value: "M", label: "Executing" },
      { value: "O", label: "Counterparty" },
    ])
  )
  .attr("clearReportRiskVals", () =>
    faker.random.arrayElement([
      [{ value: "CLR", label: "Clear" }],
      [{ value: "RPT", label: "Report" }],
      [{ value: "RSK", label: "Risk" }],
    ])
  )
  .attr("tradeReportFlag", () =>
    // aka tradeReportFlag
    faker.random.arrayElement([
      { value: "QSR", label: "QSR" },
      { value: "AGU", label: "AGU" },
    ])
  )
  .attr("contraMPID", () => refaker.arrayElements(refaker.letters, 4).toUpperCase())
  .attr("correspondentMPID", () => refaker.alpha(4))
  .attr("side", () =>
    faker.random.arrayElement([
      { value: "B", label: "Buy" },
      { value: "S", label: "Sell" },
      { value: "X", label: "Cross" },
      { value: "D", label: "Short" },
      { value: "E", label: "Short Exempt" },
    ])
  )
  .attr("symbol", () => refaker.arrayElements(refaker.letters, 4).toUpperCase())
  .attr("quantity", () => refaker.numbers(4))
  .attr("price", () => "$" + refaker.numbers(7))
  // .attr("priceOverride", () => "$" + refaker.numbers(7))
  .attr("actDate", () => moment(faker.date.recent(1)))
  .attr("actTime", () => moment(faker.date.recent(1)))
  .attr("settlement", () =>
    faker.random.arrayElement([
      // { value: 1, label: "T" },
      // { value: 2, label: "T+2" },
      // { value: 3, label: "T+1" },
      { value: 4, label: "other" },
    ])
  )
  .attr("settlementOther", "12/25/19")
  .attr("counterCapacity", () =>
    faker.random.arrayElement([
      { value: " ", label: "[blank]" },
      { value: "A", label: "Agency" },
      { value: "P", label: "Principal" },
      { value: "R", label: "Riskless" },
    ])
  )
  .attr("counterGiveUpMPID", () => refaker.alpha(4))
  .attr("counterBranchSeqNum", () => refaker.alpha(4))
  .attr("counterClearingNum", () => refaker.numbers(3))
  .attr("capacity", () =>
    faker.random.arrayElement([
      { value: " ", label: "[blank]" },
      { value: "A", label: "Agency" },
      { value: "P", label: "Principal" },
      { value: "R", label: "Riskless" },
    ])
  )
  .attr("giveUpMPID", () => refaker.alpha(4))
  .attr("branchSeqNum", () => refaker.alpha(4))
  .attr("clearingNum", () => refaker.numbers(3))
  .attr("modifier2", () =>
    faker.random.arrayElement([
      { value: "F", label: "Intermkt sweep" },
      { value: "4", label: "Derivatively Priced" },
      { value: "2", label: "Self Help" },
      { value: "J", label: "Sub Penny" },
      { value: "V", label: "Contingent Trade" },
      { value: "3", label: "ISO" },

      { value: "7", label: "Error Correct" },
      { value: "8", label: "Print Protect" },
    ])
  )
  .attr("modifier2Time", () => moment(faker.date.recent()))
  .attr("modifier3", () =>
    faker.random.arrayElement([
      { value: "T", label: "Pre/Post Trd" },
      { value: "U", label: "Pre/Post SOOS" },
      { value: "Z", label: "Sold" },
    ])
  )
  .attr("modifier4", () =>
    faker.random.arrayElement([
      { value: "P", label: "Prior Ref Price" },
      { value: "W", label: "Avg Price" },
      { value: "1", label: "Stop Stock" },
      { value: "R", label: "RA px away" },
      { value: "X", label: "RX Options Ex" },
    ])
  )
  .attr("modifier4Time", () => moment(faker.date.recent()))
  .attr("relatedMarketFlag", () =>
    faker.random.arrayElement([
      { value: "Q", label: "Nasdaq" },
      { value: "N", label: "NYSE" },
      { value: "B", label: "BOSX" },
      { value: "I", label: "ISE" },
      { value: "V", label: "IEX" },
      { value: "A", label: "ARCA" },
      { value: "W", label: "CBOE" },
      { value: "X", label: "PHLX" },
      { value: "H", label: "BATS" },
      { value: "J", label: "EDGA" },
      { value: "K", label: "EDGX" },
      { value: "0", label: "ADF" },
      { value: "1", label: "TRF Carteret" },
      { value: "2", label: "TRF Chicago" },
      { value: "3", label: "NYSE TRF" },
      { value: "L", label: "LTSE" },
    ])
  )
  .attr("intendedMarketFlag", () =>
    faker.random.arrayElement([
      { value: "Exchange", label: "Exchange" },
      { value: "TRF", label: "TRF" },
    ])
  )
  .attr("StepInOut", () =>
    faker.random.arrayElement([
      { value: "SO", label: "Step Out trade" },
      { value: "SOWF", label: "Step out trade w/fee" },
      { value: "SI", label: "Step In" },
    ])
  )
  .attr("fee", () => `$ ${refaker.numbers(9)}`)
  .attr("clearingPrice", `$ ${refaker.numbers(9)}`)
  .attr("Special", () => faker.random.arrayElement(["true", "false"]))
  .attr("specialInstructions", () =>
    faker.random.arrayElement([
      { value: "CC", label: "Clearing Copy" },
      { value: "PT", label: "Position Transfer" },
      { value: "CNR", label: "Clearing Non Reg" },
      { value: "NSO", label: "Nasdaq Step Out" },
      { value: "NSFX", label: "Nasdaq Sales Fee Transfer" },
    ])
  )
  // aka additional instructions
  .attr("memo", () => faker.random.words(5))
  .attr("tradeReferenceNum", () => faker.random.alphaNumeric(10))
  .attr("dateRange", () => [moment(faker.date.recent(2)), moment(faker.date.recent(1))])
  .attr("controlNum", () => "")
  .attr("tradeStatus", { value: "A", label: "Accepted" })
  .attr("Port/User", { value: 1, label: "???!" })
  .attr("lateTrades", () => Math.floor(Math.random() * 4))
  .attr("dissemination", () => Math.floor(Math.random() * 4))
  .attr("tradeAccuracy", () => Math.floor(Math.random() * 5));

const tableData = Factory.buildList("table_demo", 1);
export default tableData;

export const scanTableData = Factory.buildList("table_demo", 5);
export const makeMeData = (length) => Factory.buildList("table_demo", length);

export const nRows = (n) => Factory.buildList("table_demo", n);

export const uploadErrorTableData = (numRows) =>
  Factory.buildList("table_demo", numRows, { errorMessage: faker.hacker.phrase() });

export const uploadSuccessTableData = (numRows) =>
  Factory.buildList("table_demo", numRows, { status: "Success" });
