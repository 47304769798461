import { CircleButton, FontAwesomeIcon } from "@nef/core";
import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { ManagePopover } from "./managePopover";

const ManageButton = styled(CircleButton)`
  margin-top: 7px;
`;

export const WorkXSelectPersistCreatableManageButton = ({
  form,
  name,
  id,
  options,
  persistKey,
}) => {
  const [isOpen, setOpen] = useState(false);

  const manageButtonId = useMemo(() => {
    return `PERSIST_CREATABLE_DELETE_BUTTON_${form.key}_${id ? id : name}`;
  }, [form, id, name]);

  const togglePopover = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const disabledMessage = useMemo(() => {
    if (options?.length > 0) {
      return undefined;
    } else {
      return { message: "No options have been created." };
    }
  }, [options]);

  return (
    <>
      <ManageButton
        onClick={togglePopover}
        id={manageButtonId}
        size="sm"
        color="secondary"
        disabled={disabledMessage}
        ghost
      >
        <FontAwesomeIcon iconClassName="fa-cog" />
      </ManageButton>
      <ManagePopover
        isOpen={isOpen}
        onToggle={togglePopover}
        target={manageButtonId}
        options={options}
        persistKey={persistKey}
      />
    </>
  );
};
