import { Tag } from "@nef/core";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { POR, PORModel, PORStatus, PvRejectModel } from "./constant";
import { RejectCol } from "./rejectColumn";
import { SymbolCol } from "./symbolColumn";

export const pvRejectColumns = [
  {
    Header: "Reject Reason",
    id: PvRejectModel.REJECT_REASON,
    accessor: PvRejectModel.REJECT_REASON,
    Cell: ({ row }: { row: { original: any } }) => {
      return (
        <TooltipTableCell
          id={`error_message_${row.original[PvRejectModel.ID]}`}
          tooltipContent={undefined}
          align={undefined}
        >
          {row.original[PvRejectModel.REJECT_REASON]}
        </TooltipTableCell>
      );
    },
    width: 200,
  },
  {
    Header: "MPID",
    id: PvRejectModel.MPID,
    accessor: PvRejectModel.MPID,
  },
  {
    Header: "Trade Venue",
    id: PvRejectModel.TRADE_VENUE,
    accessor: PvRejectModel.TRADE_VENUE,
  },
  {
    Header: "Symbol",
    id: PvRejectModel.SYMBOL,
    accessor: PvRejectModel.SYMBOL,
    Cell: ({ row }: { row: { original: any } }) => (
      <SymbolCol id={row.original.id} symbol={row.original.symbol} />
    ),
  },
  {
    Header: "Side",
    id: PvRejectModel.SIDE,
    accessor: PvRejectModel.SIDE,
  },
  {
    Header: "Quantity",
    id: PvRejectModel.QUANTITY,
    accessor: PvRejectModel.QUANTITY,
  },
  {
    Header: "Price",
    id: PvRejectModel.PRICE,
    accessor: PvRejectModel.PRICE,
  },
  {
    Header: "Date/Time",
    id: PvRejectModel.DATE_TIME,
    accessor: PvRejectModel.DATE_TIME,
  },
];

export const pvRequestColumns = [
  {
    Header: "Request Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 100,
    Cell: ({ row }: { row: any }) => {
      let color = "success";
      if (row.original[PORModel.REQUEST_STATUS] === PORStatus.DENIED) {
        color = "danger";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
        color = "primary";
      }
      return <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>;
    },
  },
  {
    Header: "Symbol",
    id: PORModel.SYMBOL,
    accessor: PORModel.SYMBOL,
    width: 75,
  },
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 75 },
  {
    Header: "Rejects",
    id: PORModel.REJECTS,
    accessor: PORModel.REJECTS,
    Cell: ({ row }: { row: { original: POR } }) => (
      <RejectCol numRejects={row.original.rejects} symbol={row.original.symbol} />
    ),
    width: 75,
  },
  {
    Header: "Parameter Status",
    id: PORModel.PARAMETER_STATUS,
    accessor: PORModel.PARAMETER_STATUS,
    width: 80,
  },
  { Header: "Requester", id: PORModel.REQUESTER, accessor: PORModel.REQUESTER, width: 150 },
  { Header: "Request ID", id: PORModel.REQUEST_ID, accessor: PORModel.REQUEST_ID, width: 80 },
  {
    Header: "Last Update Time",
    id: PORModel.LAST_UDPATE,
    accessor: PORModel.LAST_UDPATE,
    width: 160,
  },
];
