import { BarChart } from "@nef/charts";
import { byMpid } from "../../finra/constant";
import { HeaderBox } from "./tile.container";
import { TileBack } from "./tile.back";
import {
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  usePORCacheContext,
} from "../context";
import {
  REJECTS_BY_MPID,
  REJECTS_BY_SYMBOL,
  Reject_By_Mpid,
  Reject_By_Symbol,
} from "components/pvRejects/member/constant";
import { useMemo } from "react";

export const Back = ({
  toggle,
  isAllOrPending,
  isMpidOrSymbol,
}: {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
  isMpidOrSymbol: Mpid_Or_Security;
}) => {
  const { state } = usePORCacheContext();

  const chartData = useMemo(() => {
    if (isMpidOrSymbol === MpidOrSymbol.MPID) {
      return REJECTS_BY_MPID;
    } else {
      return REJECTS_BY_SYMBOL;
    }
  }, [isMpidOrSymbol]);

  const getChartData = (data: (Reject_By_Mpid | Reject_By_Symbol)[]) => {
    const data2 = data.reduce((acc, curr) => {
      if (
        curr.rej === "!REJ - PRICE OUT OF RANGE" ||
        curr.rej === "!REJ - PRICE OUT OF OVERRIDE RANGE"
      ) {
        if (!acc[(curr as Reject_By_Symbol).symbol || (curr as Reject_By_Mpid).mpid]) {
          acc[(curr as Reject_By_Symbol).symbol || (curr as Reject_By_Mpid).mpid] = {};
        }
        acc[(curr as Reject_By_Symbol).symbol || (curr as Reject_By_Mpid).mpid][curr.rej] =
          parseInt(curr.count);
      }
      return acc;
    }, {} as { [symbol: string]: { [rejMsg: string]: number } });
    const arrData = Object.entries(data2).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    arrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const chartData = [];
    for (let i = 0; i < 5 && i < arrData.length; i++) {
      chartData.push({
        name: arrData[i].symbol,
        values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
          name: rejMsg,
          value: count,
        })),
      });
    }
    const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const vals = Object.values(countOfAllOthers);
    if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
      chartData.push({
        name: "Other",
        values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
          name: rejMsg,
          value: count,
        })),
      });
    }
    return chartData;
  };
  return (
    <TileBack
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={false}
          hasMpidOrSymbol={true}
          isAllOrPending={isAllOrPending}
          isMpidOrSymbol={isMpidOrSymbol}
          isPorOrPvr={PorOrPvr.PVR}
          total={state[PorOrPvr.PVR].total}
          title={"Rejects"}
        />
      }
      title1={`Top 5 By ${isMpidOrSymbol}`}
      chart={
        <BarChart
          data={getChartData(chartData)}
          // legend={true}
          // onClick={doTableChart}
        />
      }
    />
  );
};
