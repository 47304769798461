import { FieldLoop, riskAlertEditFields2b } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { AlertEditForm, SectionHeader } from "../constants";

export const AguRiskAlertEditForm = () => {
  return (
    <AlertEditForm>
      <SectionHeader>Limit Setting Change Alerts</SectionHeader>
      <FieldLoop
        isReactFragment={true}
        form={Forms.PTRA_AGU_EDIT}
        fields={riskAlertEditFields2b}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </AlertEditForm>
  );
};
