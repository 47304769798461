import { useCallback, useState } from "react";
import { FlipCard } from "components/flipCard";
import { ArraySection } from "../../member";
import { Front } from "./por.front";
import { Back } from "./por.back";
import { PorOrPvr, usePORCacheContext } from "../context";

export const PORs = ({ setTitle }: { setTitle: (title: string) => void }) => {
  const { state } = usePORCacheContext();

  const [isFlipped, setIsFlipped] = useState(true);
  const toggle = useCallback(() => {
    setIsFlipped(!isFlipped);
  }, [isFlipped]);

  return (
    <ArraySection>
      <FlipCard
        front={Front({
          toggle,
          isAllOrPending: state[PorOrPvr.POR].allOrPending,
        })}
        back={Back({
          toggle,
          isAllOrPending: state[PorOrPvr.POR].allOrPending,
          isMpidOrSymbol: state[PorOrPvr.POR].mpidOrSymbol,
        })}
        isFlipped={isFlipped}
      />
    </ArraySection>
  );
};
