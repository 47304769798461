import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { TableButton } from "components/standardTable";
import { useUserContext } from "components/user";
import { hasValidMPIDFromReportingParty, getFieldValue } from "utils/js.utils";
import { ViewForm, ViewForms } from "viewConstants";
import { Messages, TableButtonAction, TableButtonLabel } from "wksConstants";
import { getIsButtonDisabled } from "./util";
import { useViewModelContext, useViewModelDispatch } from "../../context";
import { useFormDispatch } from "../../form";
import {
  convertTableToForm,
  getNumSelectedRows,
  getSelectedRow,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const copyButtonConfig = {
  icon: "copy",
  text: TableButtonLabel.COPY,
  actionId: TableButtonAction.COPY,
  requireSelect: true,
  allowMultiSelect: false,
  disabledMessage: Messages.ONE_ROW,
  header: TradesHeader,
};

export const CopyButton = ({ table, form }) => {
  const [user] = useUserContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [viewData] = useViewModelContext();
  const formDispatch = useFormDispatch();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const [standardTableData] = useStandardTableContext();

  const [row, numSelected] = useMemo(() => {
    return [getSelectedRow(standardTableData[table]), getNumSelectedRows(standardTableData[table])];
  }, [table, standardTableData]);

  const handleCopy = useCallback(async () => {
    const validMPID = hasValidMPIDFromReportingParty(
      getFieldValue(row?.reportingParty),
      row,
      user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
    );
    if (["P" /*Pending Repair*/, "-" /*Rejected*/].includes(row.tradeStatus.value) || !validMPID) {
      NotificationHub.send("warning", "This trade was ignored due to invalid state", {
        subtitle: `Control#: ${row.controlNum ? row.controlNum : "N/A"}`,
      });

      standardTableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: table },
      });
      return;
    }

    const rowData = convertTableToForm(row, user);
    // Copy Action only: field removal
    delete rowData.controlNum;
    delete rowData.WorkstationID;
    delete rowData.rejectText;
    delete rowData.originalControlNum;
    Object.assign(rowData, { objVer: row.objver });

    formDispatch([
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_MODIFY, entitlements: user.entitlements },
      },
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_REPAIR, entitlements: user.entitlements },
      },
      {
        type: "SET_FORM_VALUES",
        payload: {
          form: ViewForms[viewData.view][ViewForm.COPY],
          fields: rowData,
        },
      },
      {
        type: "UPDATE_CLEARING_PRICE",
        payload: {
          form: ViewForms[viewData.view][ViewForm.COPY],
        },
      },
    ]);

    viewDispatch([
      {
        type: "DISABLE_FORM",
        payload: { form: Forms.ST_MODIFY },
      },
      {
        type: "CHANGE_FORM",
        payload: { form: ViewForm.COPY, history },
      },
      {
        type: "ENABLE_FORM",
        payload: { form },
      },
    ]);

    standardTableDispatch({
      type: "SET_CLICKED_ROW",
      payload: { row },
    });
  }, [
    row,
    user.mpidAttributes,
    user.entitlements,
    formDispatch,
    viewData.view,
    viewDispatch,
    history,
    form,
    standardTableDispatch,
    table,
  ]);

  return (
    <TableButton
      table={table}
      {...copyButtonConfig}
      disabled={getIsButtonDisabled(copyButtonConfig, numSelected)}
      onClick={handleCopy}
      key="tableButton_copy"
    />
  );
};
