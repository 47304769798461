import { NotificationHub } from "@nef/core";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { Status } from "wksConstants";
import { formatUrl } from "utils/js.utils";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { Views } from "viewConstants";
import { PTRA_CONFIGURATION_ENTITY, PTRA_ALERT_TYPE } from "./fields";
import { LIMIT_SIDE_BREACH_VALUES } from "components/fields";
import { getConfigHashKey, getHashKey } from "components/topBar/alerts/ptra/constants";

export const AGU_REQUESTING_KEY = true;
export const CLR_CORR_REQUESTING_KEY = false;
export const PTRA_SET_REQUESTING_TYPE = {
  [CLR_CORR_REQUESTING_KEY]: "SET_CLR_CORR_REQUESTING",
  [AGU_REQUESTING_KEY]: "SET_AGU_REQUESTING",
};

const ptraConfigDispatch = createContext();
ptraConfigDispatch.displayName = "PtraConfigDispatch";
export const usePtraConfigDispatch = () => {
  return useContext(ptraConfigDispatch);
};

const ptraConfigContext = createContext();
ptraConfigContext.displayName = "PtraConfigContext";
export const usePtraConfigContext = () => {
  return useContext(ptraConfigContext);
};
const defaultState = {
  aguData: [],
  clearCorrespondentData: [],
  hashMap: null,
  aguStatus: Status.NO_STATUS,
  isAguRequesting: false,
  clearCorrespondentStatus: Status.NO_STATUS,
  isClearCorrespondentRequesting: false,
  isAguLoading: false,
  isClearCorrespondentLoading: false,
};

const getActiveBreachAlertTypes = config => {
  const alertTypes = [];
  if (config[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.MAX_TRADE);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.AGG_ALERT);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.AGG_HOLD);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.AGG_KILL);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.HELD_TRADE);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.KILL_LIMIT_REJECT);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.PER_TRADE_ALERT);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.PER_TRADE_HOLD);
  }
  return alertTypes;
};

const getActiveConfigAlertTypes = config => {
  const alertTypes = [];
  if (config[PTRA_CONFIGURATION_ENTITY.isAggSettingActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.SETTING_AGGREGATE);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.SETTING_PER_TRADE);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.SETTING_MAX_TRADE);
  }
  if (config[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]) {
    alertTypes.push(PTRA_ALERT_TYPE.SETTING_DEFAULT_ACTION);
  }
  return alertTypes;
};

const getLimitBreachSides = config => {
  switch (config[PTRA_CONFIGURATION_ENTITY.limitBreachSide]) {
    case LIMIT_SIDE_BREACH_VALUES.Own:
    case LIMIT_SIDE_BREACH_VALUES.Counter:
      return [config[PTRA_CONFIGURATION_ENTITY.limitBreachSide]];
    case undefined:
    case null:
      return [LIMIT_SIDE_BREACH_VALUES.Own, LIMIT_SIDE_BREACH_VALUES.Counter];
    default:
      console.error(
        `Invalid limit breach side provided with PTR alert configuration: ${
          config[PTRA_CONFIGURATION_ENTITY.id]
        }`
      );
      return [];
  }
};

const getGiveUps = config => {
  switch (config[PTRA_CONFIGURATION_ENTITY.includeGiveups]) {
    case true:
      return [true, false];
    case false:
      return [false];
    default:
      console.error("Invalid giveup flag found on a PTRA alert configuration");
      return [];
  }
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_AGU_LOADING": {
      return { ...state, isAguLoading: action.payload };
    }
    case "SET_CLR_CORR_LOADING": {
      return { ...state, isClearCorrespondentLoading: action.payload };
    }
    case "SET_AGU_REQUESTING": {
      const isAguLoading = action.payload === true ? true : state.isAguLoading;
      return {
        ...state,
        isAguRequesting: action.payload,
        isAguLoading,
      };
    }
    case "SET_CLR_CORR_REQUESTING": {
      const isClearCorrespondentLoading =
        action.payload === true ? true : state.isClearCorrespondentLoading;
      return {
        ...state,
        isClearCorrespondentRequesting: action.payload,
        isClearCorrespondentLoading,
      };
    }
    case "SET_AGU_REQUEST_STATUS": {
      return { ...state, aguStatus: action.payload };
    }
    case "SET_CLR_CORR_REQUEST_STATUS": {
      return { ...state, clearCorrespondentStatus: action.payload };
    }
    case "SET_CLR_CORR_DATA": {
      const clearCorrespondentData = action.payload;
      const hashMap = clearCorrespondentData.reduce((acc, curr) => {
        const isConfigEnabled = curr[PTRA_CONFIGURATION_ENTITY.isEnabled];
        const isPopupActive = curr[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive];
        const isSoundActive = curr[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive];
        const isLogActive = curr[PTRA_CONFIGURATION_ENTITY.isAlertLogActive];
        if (isConfigEnabled && (isPopupActive || isSoundActive || isLogActive)) {
          const breachAlertTypes = getActiveBreachAlertTypes(curr);
          const sides = getLimitBreachSides(curr);
          const giveUps = getGiveUps(curr);
          breachAlertTypes.forEach(type => {
            sides.forEach(side => {
              giveUps.forEach(giveUp => {
                const key = getHashKey(
                  curr[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
                  curr[PTRA_CONFIGURATION_ENTITY.clearingNum],
                  side,
                  giveUp,
                  type
                );
                acc[key] = {
                  [PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]: !!(
                    isPopupActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]
                  ),
                  [PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]: !!(
                    isSoundActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]
                  ),
                  [PTRA_CONFIGURATION_ENTITY.isAlertLogActive]: !!(
                    isLogActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]
                  ),
                };
              });
            });
          });
          const configAlertTypes = getActiveConfigAlertTypes(curr);
          let intradayNextDays = ["I", "N"];
          if (curr[PTRA_CONFIGURATION_ENTITY.intradayNextDay]) {
            intradayNextDays = [curr[PTRA_CONFIGURATION_ENTITY.intradayNextDay]];
          }
          configAlertTypes.forEach(type => {
            intradayNextDays.forEach(intradayNextDay => {
              const key = getConfigHashKey(
                type,
                curr[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
                intradayNextDay,
                curr[PTRA_CONFIGURATION_ENTITY.clearingNum]
              );
              acc[key] = {
                [PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]: !!(
                  isPopupActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]
                ),
                [PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]: !!(
                  isSoundActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]
                ),
                [PTRA_CONFIGURATION_ENTITY.isAlertLogActive]: !!(
                  isLogActive || acc[key]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]
                ),
              };
            });
          });
        }
        return acc;
      }, {});
      return { ...state, clearCorrespondentData, hashMap };
    }
    case "SET_AGU_DATA": {
      return { ...state, aguData: action.payload };
    }
    default:
      return { ...state };
  }
};

export const PtraConfigProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  const getPtraClrCorrConfigData = useCallback(() => {
    const getPtraClrCorrConfigSuccess = json => {
      dispatchF([
        { type: "SET_CLR_CORR_LOADING", payload: false },
        { type: "SET_CLR_CORR_DATA", payload: json },
      ]);
    };

    const getPtraClrCorrConfigError = () => {
      const actions = [
        {
          type: "SET_CLR_CORR_LOADING",
          payload: false,
        },
      ];
      actions.push({
        type: "SET_CLR_CORR_REQUEST_STATUS",
        payload: Status.ERROR,
      });
      if (state.status !== Status.ERROR) {
        NotificationHub.send(
          "danger",
          "An error occurred while retrieving Post - Trade Risk alert configurations"
        );
      }

      dispatchF(actions);
    };

    doFetchWrapper(
      formatUrl(
        user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.ptraUrl],
        "alert/configuration/findByCreator?aguFilter=0"
      ),
      {
        method: "get",
        headers: getHeaders(),
      },
      getPtraClrCorrConfigSuccess,
      getPtraClrCorrConfigError
    );
  }, [state.status, user]);

  const getPtraAguConfigData = useCallback(() => {
    const getPtraAguConfigSuccess = json => {
      dispatchF([
        { type: "SET_AGU_LOADING", payload: false },
        { type: "SET_AGU_DATA", payload: json },
      ]);
    };

    const getPtraAguConfigError = () => {
      const actions = [
        {
          type: "SET_AGU_LOADING",
          payload: false,
        },
      ];
      actions.push({
        type: "SET_AGU_REQUEST_STATUS",
        payload: Status.ERROR,
      });
      if (state.status !== Status.ERROR) {
        NotificationHub.send(
          "danger",
          "An error occurred while retrieving Post - Trade Risk alert configurations"
        );
      }

      dispatchF(actions);
    };

    doFetchWrapper(
      formatUrl(
        user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.ptraUrl],
        "alert/configuration/findByCreator?aguFilter=1"
      ),
      {
        method: "get",
        headers: getHeaders(),
      },
      getPtraAguConfigSuccess,
      getPtraAguConfigError
    );
  }, [state.status, user]);

  useEffect(() => {
    if (user.allowed.views[Views.PTR_ALERTS] && user[INITIAL_DATA_MODEL.config]) {
      dispatchF({
        type: "SET_CLR_CORR_REQUESTING",
        payload: true,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.allowed.views[Views.PTR_ALERTS_AGU] && user[INITIAL_DATA_MODEL.config]) {
      dispatchF({
        type: "SET_AGU_REQUESTING",
        payload: true,
      });
    }
  }, [user]);

  useEffect(() => {
    if (state.isClearCorrespondentRequesting) {
      dispatchF({ type: "SET_CLR_CORR_REQUESTING", payload: false });
      getPtraClrCorrConfigData();
    }
  }, [getPtraClrCorrConfigData, state.isClearCorrespondentRequesting]);

  useEffect(() => {
    if (state.isAguRequesting) {
      dispatchF({ type: "SET_AGU_REQUESTING", payload: false });
      getPtraAguConfigData();
    }
  }, [getPtraAguConfigData, state.isAguRequesting]);

  return (
    <ptraConfigDispatch.Provider value={dispatchF}>
      <ptraConfigContext.Provider value={[state, dispatchF]}>{children}</ptraConfigContext.Provider>
    </ptraConfigDispatch.Provider>
  );
};
