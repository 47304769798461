import { DatePicker } from "@nef/core";
import {} from "components/form";
import { FieldBox } from "components/styled";
import React, { memo, useCallback, useEffect } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import { DATE_DISPLAY_FORMAT, DATE_RANGE_DISPLAY_FORMAT } from "./constants";

const WorkXDateRange = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    handleInputChange: onInputChange,
    portalRef,
    id,
  } = props;

  const handleChange = useCallback(
    e => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        let { value } = e;

        const forceUpperCase = fieldData && fieldData.props.forceUpperCase;
        if (forceUpperCase && typeof value === "string") {
          value = value.toUpperCase();
        }

        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [name, form, userData.entitlements, onInputChange, id]
  );

  useEffect(() => {
    const input = document.getElementById(props.id);
    if (input) {
      input.readOnly = true;
    }
  }, [props.id]);

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <DatePicker
        {...props}
        type="range"
        menuPortalTarget={portalRef?.current}
        value={value || []}
        onChange={handleChange}
        autoComplete="off"
        format={DATE_DISPLAY_FORMAT}
        placeholder={DATE_RANGE_DISPLAY_FORMAT}
      />
    </FieldBox>
  );
};

export default memo(WorkXDateRange);
