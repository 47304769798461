import React from "react";
import { Flex } from "@nef/core";
import { FieldLoop, rightSidePanelFields } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { colors } from "colors";

export const RightSidePanelSettings = () => {
  return (
    <Flex alignItems="start">
      <div>
        <FieldLoop form={Forms.SETTINGS_RSP} fields={rightSidePanelFields} />
      </div>
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ backgroundColor: colors.lightestgray, width: "150px", height: "100%" }}
      >
        <img
          style={{ backgroundColor: colors.lightgray, height: "30%", width: "90%" }}
          alt="img1"
        />
        <img
          style={{ backgroundColor: colors.lightgray, height: "30%", width: "90%" }}
          alt="img2"
        />
        <img
          style={{ backgroundColor: colors.lightgray, height: "30%", width: "90%" }}
          alt="img3"
        />
      </Flex>
    </Flex>
  );
};
