export enum KeycloakExportUserModel {
  configApiMpids = "configApiMpids",
  configApiPorts = "configApiPorts",
  creationDate = "creationDate",
  email = "email",
  environment = "environment",
  firmName = "firmName",
  firstName = "firstName",
  group = "group",
  i1i2 = "i1i2",
  lastName = "lastName",
  mpid = "mpid",
  roles = "roles",
  username = "username",
}

export interface KeycloakExportDTO {
  createdAt: string;
  keycloakExportData: KeycloakExportUser[];
}

export interface KeycloakExportUser {
  [KeycloakExportUserModel.configApiMpids]: string;
  [KeycloakExportUserModel.configApiPorts]: string;
  [KeycloakExportUserModel.creationDate]: string;
  [KeycloakExportUserModel.email]: string;
  [KeycloakExportUserModel.environment]: string;
  [KeycloakExportUserModel.firmName]: string;
  [KeycloakExportUserModel.firstName]: string;
  [KeycloakExportUserModel.group]: string;
  [KeycloakExportUserModel.i1i2]: string;
  [KeycloakExportUserModel.lastName]: string;
  [KeycloakExportUserModel.mpid]: string;
  [KeycloakExportUserModel.roles]: string;
  [KeycloakExportUserModel.username]: string;
}
