import styled from "styled-components";
import { Flex } from "@nef/core";
import { getColor } from "@nef/style-utils";

export const FooterFlex = styled(Flex)`
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${getColor("gray", 50)};
  background-color: ${getColor("gray", 0)};
`;
