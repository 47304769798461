import { NotificationHub } from "@nef/core";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { formatUrl } from "utils/js.utils";
import { EqrcRuleStatus, handleImplicitDecimals } from "./constants2";
import { EqrcRules, EqrcRulesLabelMap, RequestStatus } from "./types.consts";
import {
  AdvCheck,
  AlertConfig,
  FatFinger,
  GrossExposure,
  MarketImpactCheck,
  MaxNotionalOrder,
  MaxSharesPerOrder,
  OrderRateThresholds,
  OrderType,
  RestrictedStockList,
  SharesLocatedCheck,
  ShortSale,
} from "./types.rules";
import { EqrcRuleAction } from "../types.actions";
import _ from "lodash";
import { Status } from "wksConstants";

interface EqrcRuleData<RuleModel> {
  [EqrcRuleStatus.active]?: RuleModel[];
  [EqrcRuleStatus.configured]?: RuleModel[];
}

export interface EqrcRuleContextState {
  [EqrcRules.fatFinger]: EqrcRuleData<FatFinger>;
  [EqrcRules.restrictedStockList]: EqrcRuleData<RestrictedStockList>;
  [EqrcRules.grossExposure]: EqrcRuleData<GrossExposure>;
  [EqrcRules.marketImpactCheck]: EqrcRuleData<MarketImpactCheck>;
  [EqrcRules.orderType]: EqrcRuleData<OrderType>;
  [EqrcRules.advCheck]: EqrcRuleData<AdvCheck>;
  [EqrcRules.orderRateThresholds]: EqrcRuleData<OrderRateThresholds>;
  [EqrcRules.shortSale]: EqrcRuleData<ShortSale>;
  [EqrcRules.alertConfig]: EqrcRuleData<AlertConfig>;
  [EqrcRules.maxNotionalOrder]: EqrcRuleData<MaxNotionalOrder>;
  [EqrcRules.maxSharesPerOrder]: EqrcRuleData<MaxSharesPerOrder>;
  [EqrcRules.sharesLocatedBroker]: EqrcRuleData<SharesLocatedCheck>;
  statusMap: { [key in EqrcRules]: RequestStatus };
  isPolling: boolean;
  pollingMap: { [key in EqrcRules]?: Set<EqrcRuleStatus> };
  requestingMap: { [key in EqrcRules]?: Set<EqrcRuleStatus> };
  loadingSet: Set<EqrcRules>;
}

const defaultState: EqrcRuleContextState = {
  [EqrcRules.fatFinger]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.restrictedStockList]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.grossExposure]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.marketImpactCheck]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.orderType]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.advCheck]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.orderRateThresholds]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.shortSale]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.alertConfig]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.maxNotionalOrder]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.maxSharesPerOrder]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  [EqrcRules.sharesLocatedBroker]: { [EqrcRuleStatus.active]: [], [EqrcRuleStatus.configured]: [] },
  statusMap: {
    [EqrcRules.fatFinger]: RequestStatus.NO_STATUS,
    [EqrcRules.restrictedStockList]: RequestStatus.NO_STATUS,
    [EqrcRules.grossExposure]: RequestStatus.NO_STATUS,
    [EqrcRules.marketImpactCheck]: RequestStatus.NO_STATUS,
    [EqrcRules.orderType]: RequestStatus.NO_STATUS,
    [EqrcRules.advCheck]: RequestStatus.NO_STATUS,
    [EqrcRules.orderRateThresholds]: RequestStatus.NO_STATUS,
    [EqrcRules.shortSale]: RequestStatus.NO_STATUS,
    [EqrcRules.alertConfig]: RequestStatus.NO_STATUS,
    [EqrcRules.maxNotionalOrder]: RequestStatus.NO_STATUS,
    [EqrcRules.maxSharesPerOrder]: RequestStatus.NO_STATUS,
    [EqrcRules.sharesLocatedBroker]: RequestStatus.NO_STATUS,
  },
  isPolling: false,
  pollingMap: {},
  requestingMap: {},
  loadingSet: new Set<EqrcRules>(),
};

const eqrcRuleDispatch = createContext<React.Dispatch<EqrcRuleAction | EqrcRuleAction[]>>(
  () => null
);
eqrcRuleDispatch.displayName = "eqrcRuleDispatch";
export const useEqrcRuleDispatch = () => useContext(eqrcRuleDispatch);

const eqrcRuleContext = createContext<
  [EqrcRuleContextState, React.Dispatch<EqrcRuleAction | EqrcRuleAction[]>]
>([defaultState, () => null]);
eqrcRuleContext.displayName = "eqrcRuleContext";
export const useEqrcRuleContext = () => useContext(eqrcRuleContext);

const DispatchFn = (state: EqrcRuleContextState, actions: EqrcRuleAction | EqrcRuleAction[]) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (
  state: EqrcRuleContextState,
  action: EqrcRuleAction
): EqrcRuleContextState => {
  switch (action.type) {
    case "SET_POLLING": {
      const {
        isPolling,
        pollMap,
      }: { isPolling: boolean; pollMap: { [key in EqrcRules]?: Set<EqrcRuleStatus> } } =
        action.payload;
      const pollingMap = { ...state.pollingMap };
      Object.entries(pollMap).forEach(([key, statusSet]) => {
        const ruleType = key as EqrcRules;
        const curr = pollingMap[ruleType];
        const set = new Set(curr);
        statusSet.forEach(status => {
          if (isPolling) {
            set.add(status);
          } else {
            set.delete(status);
          }
        });
        if (set.size > 0) {
          pollingMap[ruleType] = set;
        } else {
          delete pollingMap[ruleType];
        }
      });
      return { ...state, pollingMap };
    }
    case "SET_IS_POLLING": {
      return { ...state, isPolling: action.payload };
    }
    case "SET_LOADING": {
      const { isLoading, ruleTypes } = action.payload;
      const loadingSet = new Set(state.loadingSet);
      ruleTypes.forEach((ruleType: EqrcRules) => {
        if (isLoading) {
          loadingSet.add(ruleType);
        } else {
          loadingSet.delete(ruleType);
        }
      });
      return { ...state, loadingSet };
    }
    case "SET_REQUESTING": {
      const {
        isRequesting,
        requestMap,
      }: { isRequesting: boolean; requestMap: { [key in EqrcRules]?: Set<EqrcRuleStatus> } } =
        action.payload;
      let loadingSet = new Set(state.loadingSet);
      const requestingMap = { ...state.requestingMap };
      Object.entries(requestMap).forEach(([key, statusSet]) => {
        const ruleType = key as EqrcRules;
        const curr = requestingMap[ruleType];
        const set = new Set(curr);
        statusSet.forEach(status => {
          if (isRequesting) {
            set.add(status);
            loadingSet.add(ruleType);
          } else {
            set.delete(status);
          }
        });
        if (set.size > 0) {
          requestingMap[ruleType] = set;
        } else {
          delete requestingMap[ruleType];
        }
      });
      return { ...state, requestingMap: requestMap, loadingSet };
    }
    case "SET_REQUEST_STATUS": {
      const { status, ruleTypes } = action.payload;
      const statusMap = { ...state.statusMap };
      const messages: Set<string> = new Set();
      ruleTypes.forEach((ruleType: EqrcRules) => {
        statusMap[ruleType] = status;
        if (status === RequestStatus.ERROR && state.statusMap[ruleType] !== RequestStatus.ERROR) {
          messages.add(
            `An error occurred while retrieving ${EqrcRulesLabelMap[ruleType]} rules for EQRC`
          );
        }
      });
      if (messages.size > 0 && messages.size > 2) {
        NotificationHub.send("danger", "An error occurred while retrieving EQRC rules");
      } else if (messages.size > 0) {
        messages.forEach(message => {
          NotificationHub.send("danger", message);
        });
      }
      return { ...state, statusMap };
    }
    case "SET_DATA": {
      const { data, status, ruleType }: { data: any; status: EqrcRuleStatus; ruleType: EqrcRules } =
        action.payload;

      const converted = [...data];
      converted.forEach(d => {
        handleImplicitDecimals(d);
      });

      return { ...state, [ruleType]: { ...state[ruleType], [status]: converted } };
    }
    default:
      return { ...state };
  }
};

interface RequestMap {
  [EqrcRules.fatFinger]?: RequestMapValue;
  [EqrcRules.restrictedStockList]?: RequestMapValue;
  [EqrcRules.grossExposure]?: RequestMapValue;
  [EqrcRules.marketImpactCheck]?: RequestMapValue;
  [EqrcRules.orderType]?: RequestMapValue;
  [EqrcRules.advCheck]?: RequestMapValue;
  [EqrcRules.orderRateThresholds]?: RequestMapValue;
  [EqrcRules.shortSale]?: RequestMapValue;
  [EqrcRules.alertConfig]?: RequestMapValue;
  [EqrcRules.maxNotionalOrder]?: RequestMapValue;
  [EqrcRules.maxSharesPerOrder]?: RequestMapValue;
  [EqrcRules.sharesLocatedBroker]?: RequestMapValue;
}

interface RequestMapValue {
  [EqrcRuleStatus.active]?: Promise<Response>;
  [EqrcRuleStatus.configured]?: Promise<Response>;
}

interface EqrcRuleContextProps {
  children: React.ReactNode;
  defaultData?: EqrcRuleContextState;
}

export const EqrcRuleProvider: React.FC<EqrcRuleContextProps> = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [user] = useUserContext();

  const getEqrcRuleData = useCallback(
    (requestingMap: { [key in EqrcRules]?: Set<EqrcRuleStatus> }): Promise<void> => {
      return new Promise(resolve => {
        const getEqrcRuleCallback = async (requestMap: RequestMap) => {
          const actions: any[] = [];
          const ruleTypes = Object.getOwnPropertyNames(requestMap) as EqrcRules[];
          const successTypes: EqrcRules[] = [];
          const errorTypes: EqrcRules[] = [];
          for (let i = 0; i < ruleTypes.length; i++) {
            const ruleType = ruleTypes[i];
            const statuses = Object.getOwnPropertyNames(requestMap[ruleType]) as EqrcRuleStatus[];
            for (let j = 0; j < statuses.length; j++) {
              const status = statuses[j];
              const responsePromise = requestMap?.[ruleType]?.[status];
              if (responsePromise !== undefined) {
                const response = await responsePromise;
                if (response.ok) {
                  const data = await response?.json().catch(() => {
                    console.error(`Encountered an error parsing EQRC ${ruleType} response data`);
                    errorTypes.push(ruleType);
                  });
                  if (data) {
                    actions.push({
                      type: "SET_DATA",
                      payload: { data, status, ruleType },
                    });

                    successTypes.push(ruleType);
                  }
                } else {
                  errorTypes.push(ruleType);
                }
              }
            }
          }

          actions.push({ type: "SET_LOADING", payload: { isLoading: false, ruleTypes } });
          actions.push({
            type: "SET_REQUEST_STATUS",
            payload: { status: RequestStatus.SUCCESS, ruleTypes: successTypes },
          });
          actions.push({
            type: "SET_REQUEST_STATUS",
            payload: { status: RequestStatus.ERROR, ruleTypes: errorTypes },
          });
          dispatchF(actions);
        };

        const getEqrcRuleError = (requestMap: RequestMap) => {
          const ruleTypes = Object.getOwnPropertyNames(requestMap) as EqrcRules[];
          const actions: any[] = [
            {
              type: "SET_LOADING",
              payload: { isLoading: false, ruleTypes },
            },
          ];
          actions.push({
            type: "SET_REQUEST_STATUS",
            payload: { status: RequestStatus.ERROR, ruleTypes },
          });

          dispatchF(actions);
        };
        const requests: Promise<Response>[] = [];
        const eqrcRequestMap: Promise<RequestMap> = new Promise(resolve => {
          const requestMap: RequestMap = {};
          Object.entries(requestingMap).forEach(([key, statusSet]) => {
            const ruleType = key as EqrcRules;
            switch (ruleType) {
              case EqrcRules.alertConfig: {
                const activeRequest = fetch(
                  formatUrl(
                    user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
                    ruleType
                  ),
                  {
                    mode: "cors",
                    headers: getHeaders(),
                  }
                );
                requests.push(activeRequest);
                requestMap[ruleType] = {
                  [EqrcRuleStatus.active]: activeRequest,
                };
                break;
              }
              default: {
                if (
                  statusSet.has(EqrcRuleStatus.active) &&
                  statusSet.has(EqrcRuleStatus.configured)
                ) {
                  const activeRequest = fetch(
                    formatUrl(
                      user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
                      `${ruleType}?status=${EqrcRuleStatus.active}`
                    ),
                    {
                      mode: "cors",
                      headers: getHeaders(),
                    }
                  );
                  const configuredRequest = fetch(
                    formatUrl(
                      user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
                      `${ruleType}?status=${EqrcRuleStatus.configured}`
                    ),
                    {
                      mode: "cors",
                      headers: getHeaders(),
                    }
                  );
                  requestMap[ruleType] = {
                    [EqrcRuleStatus.active]: activeRequest,
                    [EqrcRuleStatus.configured]: configuredRequest,
                  };
                } else {
                  const status = statusSet.values().next().value as EqrcRuleStatus;
                  const request = fetch(
                    formatUrl(
                      user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
                      `${ruleType}?status=${status}`
                    ),
                    {
                      mode: "cors",
                      headers: getHeaders(),
                    }
                  );
                  requests.push(request);
                  if (requestMap[ruleType] === undefined) {
                    requestMap[ruleType] = {
                      [status]: request,
                    };
                  }
                }
                break;
              }
            }
          });
          resolve(requestMap);
        });

        eqrcRequestMap.then(
          requestMap => {
            Promise.all(requests).then(
              async () => {
                getEqrcRuleCallback(requestMap);
                resolve();
              },
              async () => {
                getEqrcRuleError(requestMap);
                resolve();
              }
            );
          },
          () => {
            NotificationHub.send(
              "danger",
              "An error occurred while creating requests for EQRC rules"
            );
            resolve();
          }
        );
      });
    },
    [user]
  );

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl] &&
      Object.keys(state.requestingMap).length > 0
    ) {
      dispatchF({
        type: "SET_REQUESTING",
        payload: { isRequesting: false, requestMap: state.requestingMap },
      });
      getEqrcRuleData(state.requestingMap);
    }
  }, [state.requestingMap, getEqrcRuleData, user]);

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl] &&
      Object.keys(state.pollingMap).length > 0 &&
      !state.isPolling
    ) {
      dispatchF({
        type: "SET_IS_POLLING",
        payload: true,
      });
      setPollingTimeout(
        setTimeout(() => {
          const promise = getEqrcRuleData(state.pollingMap);
          promise.then(() => {
            dispatchF({
              type: "SET_IS_POLLING",
              payload: false,
            });
          });
        }, 120000)
      );
    } else if (Object.keys(state.pollingMap).length === 0 && state.isPolling && pollingTimeout) {
      clearTimeout(pollingTimeout);
      dispatchF({
        type: "SET_IS_POLLING",
        payload: false,
      });
    }
  }, [user, state.pollingMap, getEqrcRuleData, state.isPolling, pollingTimeout]);

  return (
    <eqrcRuleDispatch.Provider value={dispatchF}>
      <eqrcRuleContext.Provider value={[state, dispatchF]}>{children}</eqrcRuleContext.Provider>
    </eqrcRuleDispatch.Provider>
  );
};
