import React, { useCallback } from "react";
import { TableTabs } from ".";
import { StandardTable } from "components/standardTable";
import { StandardTables } from "../../wksConstants";

export const TabTable = ({
  table,
  header,
  headerMenu,
  enableLayoutExport,
  showRowCountOnTab,
  removableTabs,
  onTabClick,
  hideRowCount,
  hideQueryDate,
  style,
}) => {
  const Header = useCallback(() => {
    return (
      <TableTabs
        table={table}
        removableTabs={removableTabs}
        showRowCountOnTab={showRowCountOnTab}
        onTabClick={onTabClick}
      />
    );
  }, [onTabClick, showRowCountOnTab, removableTabs, table]);
  return (
    <StandardTable
      style={style}
      header={header}
      table={table}
      enableLayoutExport={enableLayoutExport}
      headerMenu={headerMenu}
      Header={Header}
      hideRowCount={hideRowCount}
      hideQueryDate={hideQueryDate}
      isRowSelectEnabled={
        ![
          StandardTables.QUERY_TOOL,
          StandardTables.CV_KILL_REJECT,
          StandardTables.UPLOAD_SUCCESSES,
        ].includes(table)
      }
    />
  );
};
