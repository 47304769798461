import { CSSGrid } from "@nef/core";
import { useViewModelContext } from "components/context";
import Middle from "components/middle";
import { useMemo } from "react";
import { tableButtons, tableTitles, ViewTable } from "../../viewConstants";
import { RecapProvider, TradeDetailRecap, useRecapContext } from "../tradeDetailRecap";
import "./reportScanMiddle.css";
import { DynamicTable } from "./tables";

export const ReportScanMiddleInner = () => {
  const [viewData] = useViewModelContext();
  const [recapData] = useRecapContext();

  const table = useMemo(() => ViewTable[viewData.view], [viewData.view]);
  const title = useMemo(() => tableTitles[viewData.view], [viewData.view]);
  const TableButtons = useMemo(() => tableButtons[viewData.view], [viewData.view]);

  return (
    <Middle>
      <CSSGrid rows="100%" cols="100%">
        <DynamicTable table={table} title={title} TableButtons={TableButtons} />
      </CSSGrid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};

export const ReportScanMiddle = () => {
  return (
    <RecapProvider>
      <ReportScanMiddleInner />
    </RecapProvider>
  );
};
