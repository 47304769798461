import { NotificationHub, Header } from "@nef/core";
import { FieldNames } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { getHeaders } from "keycloak";
import { getConvertedRiskLimitsForUI } from "components/limitMonitor/constants";
import { doFetchWrapper } from "network";
import React, { useCallback, useMemo, useState } from "react";
import { StandardTables, TableButtonLabel, TableButtonAction } from "wksConstants";
import { formatUrl, getFileNameDate } from "../../utils/js.utils";
import { ViewActions, Views } from "../../viewConstants";
import { ExportLimitTypes } from "../fields";
import { TableButtons } from "../standardTable";
import { useUserContext } from "../user";
import { useHKCacheContext } from "./heldKilledCache";
import moment from "moment";
import {
  exportRiskLimitConfigs,
  EXPORT_RISK_LIMIT_FAILED_MESSAGE,
  EXPORT_RISK_LIMIT_RECEIVED_MESSAGE,
} from "./constants";

const handleExportRiskConfigs = (configs, exportLimitType) => {
  let fileName = "INTRADAY_LIMITS";
  if (exportLimitType === ExportLimitTypes.NEXTDAY) {
    fileName = "NEXTDAY_LIMITS";
  }
  fileName += `${getFileNameDate(new moment())}.csv`;
  exportRiskLimitConfigs(fileName, configs);
};

export const SummaryTableButtons = ({ onClickEditLimits }) => {
  const [formData, formDispatch] = useFormContext();
  const [user] = useUserContext();
  const [isDisabled, setDisabled] = useState(false);
  const [hkCache] = useHKCacheContext();

  const handleErrorCb = useCallback(() => {
    NotificationHub.send("danger", EXPORT_RISK_LIMIT_FAILED_MESSAGE);
  }, []);

  const handleIntraCb = useCallback(json => {
    const configs = json?.data?.map(agg => getConvertedRiskLimitsForUI(agg.riskConfig));
    handleExportRiskConfigs(configs, ExportLimitTypes.INTRADAY);
  }, []);

  const handleNextCb = useCallback(data => {
    const configs = data.map(config => getConvertedRiskLimitsForUI(config));
    handleExportRiskConfigs(configs, ExportLimitTypes.NEXTDAY);
  }, []);

  const handleExportLimits = useCallback(
    e => {
      const exportLimitType =
        formData[Forms.PTR_EXPORT_LIMITS.key].fields[FieldNames.exportLimitType];
      const headers = getHeaders();
      NotificationHub.send("success", EXPORT_RISK_LIMIT_RECEIVED_MESSAGE);
      switch (exportLimitType) {
        case ExportLimitTypes.INTRADAY:
          const bodyArgs = {
            timeStamp: 0,
            instId: 0,
            riskClearingIds: Object.keys(hkCache.clearingRelationshipIds),
          };
          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_LIMO_LVC, "aggregatesByIdList"),
            {
              method: "post",
              headers,
              body: JSON.stringify(bodyArgs),
            },
            handleIntraCb,
            handleErrorCb
          );
          break;
        case ExportLimitTypes.NEXTDAY:
          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_LIMO_WS, "getrcamriskconfigs"),
            {
              method: "post",
              headers,
              body: JSON.stringify({ ids: Object.keys(hkCache.clearingRelationshipIds) }),
            },
            handleNextCb,
            handleErrorCb
          );
          break;
        default:
          handleErrorCb();
          break;
      }
    },
    [formData, handleErrorCb, handleIntraCb, handleNextCb, hkCache.clearingRelationshipIds]
  );

  const handleEditLimits = useCallback(
    (e, rows) => {
      if (onClickEditLimits) {
        onClickEditLimits(rows);
      }
      // const valid = getFormValidation(Forms.PTR_INTRADAY_LIMITS, formData, formDispatch);
      // if (!valid) {
      //   return true;
      // }
      // const { fields } = formData[Forms.PTR_INTRADAY_LIMITS.key];
      // // if FieldNames !== ApiResponseNnames this could break
      // const dto = getConvertedRiskLimitsForDB(fields);

      // const setIntradayCallback = json => {
      //   setDisabled(false);
      //   switch (json.ackType) {
      //     case AckTypes.SUCCESS: {
      //       NotificationHub.send("success", "Intraday Limit Update Complete", {
      //         subtitle: `Correspondent: ${dto[FieldNames.correspondentMPID]}, Clearer: ${
      //           dto[FieldNames.clearingFirmNum]
      //         }`,
      //       });
      //       break;
      //     }
      //     case AckTypes.REJECT: {
      //       NotificationHub.send("danger", "Intraday Limit Update Rejected", {
      //         subtitle: `Correspondent: ${dto[FieldNames.correspondentMPID]}, Clearer: ${
      //           dto[FieldNames.clearingFirmNum]
      //         }, Reject: ${json.rejectText}`,
      //       });
      //       break;
      //     }
      //     default: {
      //       NotificationHub.send("danger", "Intraday Limit Update Error", {
      //         subtitle: `Correspondent: ${dto[FieldNames.correspondentMPID]}, Clearer: ${
      //           dto[FieldNames.clearingFirmNum]
      //         }, Error: No acknowledgement`,
      //       });
      //       break;
      //     }
      //   }
      // };

      // const setIntradayError = () => {
      //   setDisabled(false);
      //   NotificationHub.send("danger", "Intraday Limit Update Failed", {
      //     subtitle: `Correspondent: ${dto[FieldNames.correspondentMPID]}, Clearer: ${
      //       dto[FieldNames.clearingFirmNum]
      //     }`,
      //   });
      // };

      // const headers = getHeaders();
      // setDisabled(true);
      // doFetchWrapper(
      //   formatUrl(process.env.REACT_APP_URL_LIMO_WS, "setintradayriskconfig"),
      //   {
      //     method: "post",
      //     headers,
      //     body: JSON.stringify(dto),
      //   },
      //   setIntradayCallback,
      //   setIntradayError
      // );
      // return false;
      // copy row to new object, update limit values in riskConfig, send as body
    },
    [onClickEditLimits]
  );

  const exportHeader = useMemo(() => () => <Header size={3}>{`Export Limits`}</Header>, []);

  const getTableButtons = useMemo(() => {
    const buttons = [];
    if (user.allowed.views[Views.SETTINGS_LIMO]) {
      buttons.unshift({
        icon: "download",
        text: TableButtonLabel.EXPORT_LIMITS,
        actionId: TableButtonAction.EXPORT_LIMITS,
        requireSelect: false,
        allowMultiSelect: true,
        onClick: handleExportLimits,
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        header: exportHeader,
      });
    }
    if (user.allowed.actions[ViewActions.PTR_CONFIG]) {
      buttons.unshift({
        icon: "align-left",
        text: TableButtonLabel.EDIT_LIMITS,
        actionId: TableButtonAction.EDIT_LIMITS,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleEditLimits,
        loading: isDisabled,
      });
    }
    return buttons;
  }, [
    user.allowed.views,
    user.allowed.actions,
    handleExportLimits,
    exportHeader,
    handleEditLimits,
    isDisabled,
  ]);

  return <TableButtons table={StandardTables.CV_SUMMARY} buttons={getTableButtons} />;
};
