export const constructQueryPayload = (chart, dataPoint, context) => {
  const query = payloads[chart]
    ? payloads[chart][
        dataPoint.replace(/\W?\(\d?\d?\d?\.?\d?\d%\)/i, "").replace(/[<>]\W?/, "")
      ]()
    : {};
  return Object.assign({}, query, context);
};

const payloads = {
  "Trade Accuracy": {
    Breaks: () => ({ statType: "Breaks" }),
    Rejects: () => ({ statType: "Rejects" }),
    Mods: () => ({ statType: "Modifies" }),
    Cancels: () => ({ statType: "Cancels" }),
    Reversals: () => ({ statType: "Reversals" }),
  },
  "Late Trades": {
    "5 min": () => ({ statType: "5min" }),
    "10 min": () => ({ statType: "10min" }),
    "15 min": () => ({ statType: "15min" }),
    "20 min": () => ({ statType: "20min" }),
  },
  Statuses: {
    Matched: () => ({ statType: "Matched" }),
    Declined: () => ({ statType: "Declined" }),
    Open: () => ({ statType: "Open" }),
    Unmatched: () => ({ statType: "Unmatched" }),
    Accepted: () => ({ statType: "Accepted" }),
    Cancelled: () => ({ statType: "Cancelled" }),
  },
  Dissemination: {
    "Clearing Only": () => ({ statType: "ClearingOnly" }),
    "Tape Only": () => ({ statType: "TapeOnly" }),
    "Clearing Tape": () => ({ statType: "ClearingTape" }),
    Regulatory: () => ({ statType: "Regulatory" }),
  },
};
