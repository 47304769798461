import { BarChart, PieChart } from "@nef/charts";
import { CircleButton, DataArray, FontAwesomeIcon, FormField, Header, Indicator } from "@nef/core";
import { getColor, getShadow } from "@nef/style-utils";
import { Forms, PVMemberMonitorTop } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { FlipCard } from "components/flipCard";
import { Middle } from "components/middle";
import {
  getSelectedRows,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import TableButtons from "components/standardTable/tableButtons";
import { useUserContext } from "components/user";
import moment from "moment";
import React, { EffectCallback, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { useLayoutContext } from "../../context";
import {
  POR,
  PORModel,
  PvRejectModel,
  REJECTS_BY_COUNT,
  REJECTS_BY_SYMBOL,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
} from "./constant";
import { usePvMemberDispatch, usePvMemberState } from "./context";

const borderRadius = "5px";
const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 30px ${CHART_HEIGHT_PX}px calc(100% - ${CHART_HEIGHT_PX}px - 30px - 2rem);
`;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

const InputWrapper = styled.div`
  grid-column: span 4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const ArraySection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    border: 1px solid black;
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    border-radius: ${borderRadius};
    padding: 1rem;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 2rem);
`;

const ChartSection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    padding: 1rem;
    box-sizing: border-box;
    border-radius: ${borderRadius};
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PieChartWrapper = styled.div`
  aspect-ratio: 1 / 1;
  width: ${CHART_HEIGHT_PX - 75}px;
`;

const TableWrapper = styled.div`
  grid-column: span 4;
`;

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

const RequestTableWrapper = styled.div`
  grid-column: span 2;
`;

const FlipChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const sortByCount = (a: any, b: any) => {
  return b.count - a.count;
};

const mpids = ["DBAB", "DAIN", "BCAP", "MLCO", "PERS", "BIDS", "BOFA"];
const sides = ["Buy", "Sell"];
const trfs = ["Carteret", "Chicago"];

const symbolTableDataCache: { [key: string]: any[] } = {};

type names = typeof REJ_PRICE_OO_RANGE | typeof REJ_PRICE_OO_OVERRIDE_RANGE;
const nameMap: { [key in names]: string } = {
  [REJ_PRICE_OO_RANGE]: "Price",
  [REJ_PRICE_OO_OVERRIDE_RANGE]: "Price Override",
};

let nextPorId = 1;
export const PvRejectMonitor: React.FC = () => {
  const [rejectsByCount, setRejectsByCount] = useState(REJECTS_BY_COUNT);
  const [rejectsBySymbol, setRejectsBySymbol] = useState(REJECTS_BY_SYMBOL);
  const [filterSymbol, setFilterSymbol] = useState("");
  const [, layoutDispatch] = useLayoutContext();
  const tableDispatch = useStandardTableDispatch();
  const [isFlipped, setFlipped] = useState(false);
  const pvMemberData = usePvMemberState();
  const [tableData] = useStandardTableContext();
  const pvMemberDispatch = usePvMemberDispatch();
  const [userData] = useUserContext();

  const getRejsForSymbol = useCallback(
    (input: {
      label: string;
      value: React.ReactNode;
      count: number;
      subRows: any[];
      rejects: { [rejectMsg: string]: string };
    }) => {
      let data: any[];
      const key = `${input.label}_${Object.keys(input.rejects).join("_")}`;
      if (symbolTableDataCache[key] !== undefined) {
        data = symbolTableDataCache[key];
      } else {
        data = [];
        Object.entries(input.rejects).forEach(([rej, count]) => {
          for (let i = 0; i < parseInt(count); i++) {
            data.push({
              [PvRejectModel.ID]: i,
              [PvRejectModel.REJECT_REASON]: rej,
              [PvRejectModel.MPID]: mpids[Math.floor(Math.random() * mpids.length)],
              [PvRejectModel.TRADE_VENUE]: trfs[Math.floor(Math.random() * trfs.length)],
              [PvRejectModel.SYMBOL]: input.label,
              [PvRejectModel.SIDE]: sides[Math.floor(Math.random() * sides.length)],
              [PvRejectModel.PRICE]: Math.random() * 200,
              [PvRejectModel.QUANTITY]: Math.ceil(Math.random() * 10000),
              [PvRejectModel.DATE_TIME]: moment(new Date().getTime()).format(
                "YYYY-MM-DD HH:mm:ss.SSS"
              ),
            });
          }
        });
        symbolTableDataCache[key] = data;
      }
      return data;
    },
    []
  );

  const handleSetFilter = useCallback(
    ({ symbol, reject }: { symbol?: string; reject: string }) =>
      () => {
        pvMemberDispatch({
          type: "SET_FILTER",
          payload: { symbol, reject },
        });
      },
    [pvMemberDispatch]
  );

  useEffect(() => {
    if (pvMemberData?.filter?.reject) {
      const rejects = rejectsBySymbol.reduce((acc, curr) => {
        if (
          (!pvMemberData.filter?.symbol || curr.symbol === pvMemberData.filter.symbol) &&
          curr.rej === pvMemberData.filter.reject
        ) {
          acc = acc.concat(
            getRejsForSymbol({
              label: curr.symbol,
              value: <></>,
              count: parseInt(curr.count),
              subRows: [],
              rejects: { [pvMemberData.filter.reject]: curr.count },
            })
          );
        }
        return acc;
      }, [] as any[]);

      tableDispatch([
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_REJECTS },
        },
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.PV_REJECTS, data: rejects },
        },
      ]);
    }
  }, [getRejsForSymbol, pvMemberData, pvMemberData.filter, rejectsBySymbol, tableDispatch]);

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const symbolDataArray = useMemo(
    () =>
      Object.values(
        rejectsBySymbol.reduce((acc, curr) => {
          if ([REJ_PRICE_OO_RANGE, REJ_PRICE_OO_OVERRIDE_RANGE].includes(curr.rej)) {
            if (acc[curr.symbol] === undefined) {
              acc[curr.symbol] = {
                label: curr.symbol,
                count: parseInt(curr.count),
                rejects: { [curr.rej]: curr.count },
                value: curr.count,
                subRows: [
                  {
                    label: curr.rej,
                    value: (
                      <ArrayButton
                        onClick={handleSetFilter({ symbol: curr.symbol, reject: curr.rej })}
                      >
                        <CircleButton ghost={true} size="xs">
                          <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
                        </CircleButton>
                        {curr.count}
                      </ArrayButton>
                    ),
                    count: curr.count,
                  },
                ],
              };
            } else {
              acc[curr.symbol].count += parseInt(curr.count);
              acc[curr.symbol].rejects[curr.rej] = curr.count;
              acc[curr.symbol].value = acc[curr.symbol].count;
              acc[curr.symbol].subRows.push({
                label: curr.rej,
                value: (
                  <ArrayButton onClick={handleSetFilter({ symbol: curr.symbol, reject: curr.rej })}>
                    <CircleButton ghost={true} size="xs">
                      <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
                    </CircleButton>
                    {curr.count}
                  </ArrayButton>
                ),
                count: curr.count,
              });
            }
          }
          return acc;
        }, {} as { [symbol: string]: { label: string; value: React.ReactNode; count: number; subRows: any[]; rejects: { [rejectMsg: string]: string } } })
      ).sort(sortByCount),
    [handleSetFilter, rejectsBySymbol]
  );

  const filteredSymbolArray = useMemo(() => {
    return symbolDataArray.filter(entry => entry.label.includes(filterSymbol));
  }, [symbolDataArray, filterSymbol]);

  const setSymbolFilter = useCallback((e: any) => {
    setFilterSymbol(e.value);
  }, []);

  const pieChartData = useMemo(() => {
    return rejectsByCount.reduce((acc, curr) => {
      if (curr.rej === REJ_PRICE_OO_RANGE || curr.rej === REJ_PRICE_OO_OVERRIDE_RANGE) {
        acc.push({
          value: parseInt(curr.count),
          name: curr.rej,
        });
      }
      return acc;
    }, [] as { value: number; name: string }[]);
  }, [rejectsByCount]);

  const barChartData = useMemo(() => {
    const data = rejectsBySymbol.reduce((acc, curr) => {
      if (curr.rej === REJ_PRICE_OO_RANGE || curr.rej === REJ_PRICE_OO_OVERRIDE_RANGE) {
        if (!acc[curr.symbol]) {
          acc[curr.symbol] = {};
        }
        acc[curr.symbol][curr.rej] = parseInt(curr.count);
      }
      return acc;
    }, {} as { [symbol: string]: { [rejMsg: string]: number } });
    const arrData = Object.entries(data).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    arrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const chartData = [];
    for (let i = 0; i < 5 && i < arrData.length; i++) {
      chartData.push({
        name: arrData[i].symbol,
        values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
          name: nameMap[rejMsg as names],
          value: count,
        })),
      });
    }
    const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const vals = Object.values(countOfAllOthers);
    if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
      chartData.push({
        name: "Other",
        values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
          name: nameMap[rejMsg as names],
          value: count,
        })),
      });
    }
    return chartData;
  }, [rejectsBySymbol]);

  const createPOR = useCallback(
    ({ symbol, mpid }: { symbol: string; mpid: string }): POR => {
      return {
        [PORModel.REQUEST_ID]: nextPorId++,
        [PORModel.SYMBOL]: symbol,
        [PORModel.MPID]: mpid,
        [PORModel.REJECTS]: symbolDataArray
          .find(item => item.label === symbol)
          ?.subRows.find(row => row.label === REJ_PRICE_OO_OVERRIDE_RANGE).count,
        [PORModel.REQUEST_STATUS]: "Pending",
        [PORModel.PARAMETER_STATUS]: "Closed",
        [PORModel.REQUESTER]: userData.username,
        [PORModel.LAST_UDPATE]: new Date().toDateString(),
      };
    },
    [symbolDataArray, userData.username]
  );

  const PORHeader = useCallback(
    () => (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            Parameter Requests
            <Indicator color="success" />
          </ServiceIndicatorWrapper>
        }
      />
    ),
    []
  );

  const createPORs = useCallback(() => {
    const rows = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const porData: any[] = [];
    const symbols = new Set();
    rows.forEach(row => {
      if (!symbols.has(row.symbol)) {
        symbols.add(row.symbol);
        porData.push(createPOR({ symbol: row.symbol, mpid: row.mpid }));
      }
    });
    pvMemberDispatch({
      type: "ADD_PARAM_OPEN_REQ",
      payload: porData,
    });
    tableDispatch({
      type: "ADD_TABLE_DATA",
      payload: { table: StandardTables.PV_REQUESTS, data: porData },
    });
  }, [createPOR, pvMemberDispatch, tableData, tableDispatch]);

  const RejectButtons = useMemo(() => {
    const tableButtons = [
      {
        icon: "slash",
        text: TableButtonLabel.CLOSE,
        actionId: TableButtonAction.PVR_CLOSE,
        canConfirm: false,
        allowConfirm: false,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: () => alert("This button will close the reject."),
      },
      {
        icon: "lock-open",
        text: TableButtonLabel.POR,
        actionId: TableButtonAction.POR,
        canConfirm: false,
        allowConfirm: false,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: createPORs,
        isDisabled: pvMemberData.filter?.reject === REJ_PRICE_OO_RANGE,
        disabledMessage:
          pvMemberData.filter?.reject === REJ_PRICE_OO_RANGE
            ? "Invalid reject(s) selected, must resubmit first"
            : undefined,
      },
      {
        icon: "arrow-up",
        text: TableButtonLabel.RESUBMIT,
        actionId: TableButtonAction.PVR_RESUBMIT,
        canConfirm: false,
        allowConfirm: false,
        requireSelect: false,
        onClick: () => alert("Resubmit selected rejects with Price Override flag enabled."),
      },
      {
        icon: "clipboard-list",
        text: TableButtonLabel.RECAP,
        actionId: TableButtonAction.RECAP,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: () => alert("Opens trade recap for the selected reject(s)."),
      },
    ];
    return <TableButtons table={StandardTables.PV_REJECTS} buttons={tableButtons} />;
  }, [createPORs, pvMemberData.filter]);

  const handleChartClick = useCallback(
    (event: React.MouseEvent, data: { name: string }) => handleSetFilter({ reject: data.name })(),
    [handleSetFilter]
  );

  const RejectTableHeader = useCallback(() => {
    let title = "Price Rejects";
    if (pvMemberData.filter?.reject === REJ_PRICE_OO_OVERRIDE_RANGE) {
      title = "Price Override Rejects";
    }
    if (pvMemberData.filter?.symbol) {
      title += ` for ${pvMemberData.filter.symbol}`;
    }

    return (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            {title}
            <Indicator color="success" />
          </ServiceIndicatorWrapper>
        }
      />
    );
  }, [pvMemberData.filter]);

  const totalRejectCount = useMemo(() => {
    return `${pieChartData
      .reduce((acc, curr) => {
        return acc + curr.value;
      }, 0)
      .toLocaleString()} Total`;
  }, [pieChartData]);

  return (
    <Middle>
      <Grid>
        <InputWrapper>
          <FieldLoop
            isReactFragment={true}
            form={Forms.PV_MONITOR_MEMBER_TOP}
            fields={PVMemberMonitorTop}
            classNames={undefined}
            augmentOnChange={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
          />
        </InputWrapper>

        <ChartSection>
          <Header size={3}>
            <ServiceIndicatorWrapper>
              PV Rejects By Reason - {totalRejectCount}
              <Indicator color="success" />
            </ServiceIndicatorWrapper>
          </Header>
          <ChartContainer>
            <PieChartWrapper>
              <PieChart
                data={pieChartData}
                donut={false}
                legend={false}
                ratio="1:1"
                onClick={handleChartClick}
              />
            </PieChartWrapper>
          </ChartContainer>
        </ChartSection>

        <ArraySection>
          <FlipCard
            isFlipped={isFlipped}
            front={
              <>
                <FlipCardHeader size={3}>
                  <ServiceIndicatorWrapper>
                    PV Rejects By Symbol - {totalRejectCount}
                    <Indicator color="success" />
                  </ServiceIndicatorWrapper>
                  <CircleButton onClick={() => setFlipped(!isFlipped)} ghost={true} size="xs">
                    <FontAwesomeIcon iconClassName="fa-chart-bar" />
                  </CircleButton>
                </FlipCardHeader>
                <FormField
                  value={filterSymbol}
                  onChange={setSymbolFilter}
                  size={"sm"}
                  placeholder="Symbol"
                />
                <ArrayWrapper>
                  <DataArray data={filteredSymbolArray} expandable={true} />
                </ArrayWrapper>
              </>
            }
            back={
              <>
                <FlipCardHeader size={3}>
                  <ServiceIndicatorWrapper>
                    PV Rejects By Symbol - {totalRejectCount}
                    <Indicator color="success" />
                  </ServiceIndicatorWrapper>
                  <CircleButton onClick={() => setFlipped(!isFlipped)} ghost={true} size="xs">
                    <FontAwesomeIcon iconClassName="fa-table" />
                  </CircleButton>
                </FlipCardHeader>
                <FlipChartContainer>
                  <ChartContainer>
                    <BarChart
                      data={barChartData}
                      legend={true}
                      onClick={() => alert("Display rejects for selected symbol.")}
                    />
                  </ChartContainer>
                </FlipChartContainer>
              </>
            }
          />
        </ArraySection>

        <RequestTableWrapper>
          <StandardTable
            Header={undefined}
            table={StandardTables.PV_REQUESTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            isFilterable={true}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={undefined}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={undefined}
            header={PORHeader}
          />
        </RequestTableWrapper>

        <TableWrapper>
          <StandardTable
            header={RejectTableHeader}
            table={StandardTables.PV_REJECTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            isFilterable={true}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={RejectButtons}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={undefined}
            Header={undefined}
          />
        </TableWrapper>
      </Grid>
    </Middle>
  );
};
