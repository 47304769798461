import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { KeycloakExportUserModel, KeycloakExportUser } from "./constants";

interface CellRow {
  original: KeycloakExportUser;
}

const caseInsensitiveStringSort = (a: string, b: string) => {
  if (a === b) {
    return 0;
  }
  if (typeof a === "string") {
    if (typeof b === "string") {
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    } else {
      return 1;
    }
  } else {
    return -1;
  }
};

export const KeycloakExportColumns = [
  {
    Header: "First Name",
    id: KeycloakExportUserModel.firstName,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.firstName],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.firstName, b.original.firstName),
  },
  {
    Header: "Last Name",
    id: KeycloakExportUserModel.lastName,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.lastName],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.lastName, b.original.lastName),
  },
  {
    Header: "Username",
    id: KeycloakExportUserModel.username,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.username],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.username, b.original.username),
  },
  {
    Header: "Email",
    id: KeycloakExportUserModel.email,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.email],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.email, b.original.email),
  },
  {
    Header: "Firm",
    id: KeycloakExportUserModel.firmName,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.firmName],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.firmName, b.original.firmName),
  },
  {
    Header: "MPID",
    id: KeycloakExportUserModel.mpid,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.mpid],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.mpid, b.original.mpid),
  },
  {
    Header: "I1I2",
    id: KeycloakExportUserModel.i1i2,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.i1i2],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.i1i2, b.original.i1i2),
  },
  {
    Header: "Child MPIDs",
    id: KeycloakExportUserModel.configApiMpids,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.configApiMpids],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.configApiMpids, b.original.configApiMpids),
  },
  {
    Header: "Ports",
    id: KeycloakExportUserModel.configApiPorts,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.configApiPorts],
  },
  {
    Header: "Group",
    id: KeycloakExportUserModel.group,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.group],
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.group, b.original.group),
  },
  {
    Header: "Roles",
    id: KeycloakExportUserModel.roles,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.roles],
    Cell: ({ row }: { row: CellRow }) => {
      return (
        <TooltipTableCell
          id={`${row.original[KeycloakExportUserModel.username]}|${
            row.original[KeycloakExportUserModel.group]
          }`}
          tooltipContent={undefined}
          align={undefined}
        >
          {row.original[KeycloakExportUserModel.roles]}
        </TooltipTableCell>
      );
    },
    sortType: (a: { original: KeycloakExportUser }, b: { original: KeycloakExportUser }) =>
      caseInsensitiveStringSort(a.original.roles, b.original.roles),
  },
  {
    Header: "Created On",
    id: KeycloakExportUserModel.creationDate,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.creationDate],
  },
  {
    Header: "Environment",
    id: KeycloakExportUserModel.environment,
    accessor: (row: KeycloakExportUser) => row[KeycloakExportUserModel.environment],
  },
];
