import React, { useCallback, useState, useEffect } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, FontAwesomeIcon } from "@nef/core";
import { network } from ".";
import { useUserContext } from "../user";
import { formatUrl } from "../../utils/js.utils";
import { Views } from "../../viewConstants";
import { DownloadMenuItem } from "components/upload";

export const FileTableHeaderMenu = () => {
  const [user] = useUserContext();
  const [isOpen, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [setOpen, isOpen]);

  useEffect(() => {
    if (files?.length === 0) {
      const fileCallback = files => {
        setFiles(files);
      };
      const fileErrorCallback = console.log;
      network().getDocsList(fileCallback, fileErrorCallback);
    }
  }, [files]);

  const handleClick = useCallback(() => {
    document.getElementById("file-upload").click();
  }, []);

  return (
    <>
      {!user.allowed.views[Views.UPLOAD] ? (
        <></>
      ) : (
        <Button
          style={{ marginRight: "16px" }}
          size="md"
          color="primary"
          outline
          onClick={handleClick}>
          <FontAwesomeIcon className="fa-file-upload" style={{ marginRight: "8px" }} />
          Upload A File
        </Button>
      )}
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle size="md" color="primary" outline>
          <FontAwesomeIcon className="fa-file-download" style={{ marginRight: "8px" }} />
          Download Template
        </DropdownToggle>
        <DropdownMenu>
          {files.map(file => (
            <DownloadMenuItem
              key={file}
              filename={file}
              url={formatUrl(
                process.env.REACT_APP_URL_ACT_WS,
                `uploader/getdownloadabledoc?fileName=${file}`
              )}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
