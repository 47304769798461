import React, { useEffect, useState, useReducer } from "react";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { useUserContext } from "../user";
import { RequestResult } from "../../wksConstants";
import { formatUrl } from "../../utils/js.utils";

const urls = {
  write: formatUrl(process.env.REACT_APP_URL_USER_WS, "saveud"),
  read: formatUrl(process.env.REACT_APP_URL_USER_WS, "getud"),
  writeColumnSets: formatUrl(process.env.REACT_APP_URL_USER_WS, "saveud"),
  getInitialData: formatUrl(process.env.REACT_APP_URL_USER_WS, "getinitialdata"),
  uploadRiskSettings: formatUrl(process.env.REACT_APP_URL_LIMO_WS, "upload"),
  getDocsList: formatUrl(process.env.REACT_APP_URL_LIMO_WS, "getdocslist"),
};

export const network = params => ({
  writeSettings: async (settings, cb, errorCallback, cbArgs) => {
    await doFetchWrapper(
      `${urls.write}`,
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify(settings),
      },

      json => {
        cb(cbArgs);
      },
      () => errorCallback(cbArgs)
    );
  },
  read: async (settings, cb, errorCallback) => {
    try {
      await doFetchWrapper(
        `${window.location.host}${urls.read}`,
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
        },
        cb,
        errorCallback
      );
    } catch (e) {
      errorCallback(e);
    }
  },
  uploadRiskSettings: async (file, callback, errorCallback) => {
    const { uuid } = params;
    const f = new FormData();
    f.append("file", file);

    try {
      await doFetchWrapper(
        urls.uploadRiskSettings,
        {
          method: "post",
          mode: "cors",
          headers: (function () {
            const headers = getHeaders();
            delete headers.Accept;
            delete headers["Content-Type"];
            return headers;
          })(),
          body: f,
        },
        json => callback(json, uuid),
        json => errorCallback(json, uuid)
      );
    } catch (e) {
      errorCallback(e);
    }
  },

  getDocsList: async (callback, errorCallback) => {
    try {
      await doFetchWrapper(
        urls.getDocsList,
        {
          method: "get",
          mode: "cors",
          headers: getHeaders(),
        },

        json => {
          callback(json);
        },
        errorCallback
      );
    } catch {
      errorCallback();
    }
  },
});

const initialDataState = {
  response: null,
  loading: false,
  error: false,
};

const initialDataDispatch = (state, action) => {
  switch (action.type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "SET_RESPONSE": {
      const response = action.payload;
      return {
        ...state,
        response,
        loading: false,
      };
    }
    case "SET_ERROR": {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const useGetInitialData = ({ username }) => {
  const [{ response, loading, error }, stateDispatch] = useReducer(
    initialDataDispatch,
    initialDataState
  );
  // const [, userDispatch] = useUserContext();

  // useEffect(() => {
  //   userDispatch({
  //     type: "SET_USER_DATA",
  //     payload: {
  //       isUserDataLoading: loading,
  //       userData: response?.userData,
  //       userDataError: error || response?.userDataResult === RequestResult.error,
  //     },
  //   });
  // }, [response, error, loading, userDispatch]);

  useEffect(() => {
    const getInitialData = async () => {
      if (username && response === null && !loading && !error) {
        stateDispatch({
          type: "SET_LOADING",
          payload: true,
        });
        try {
          await doFetchWrapper(
            urls.getInitialData,
            {
              method: "post",
              mode: "cors",
              headers: getHeaders(),
              body: JSON.stringify({ user: username }),
            },

            json => {
              stateDispatch({
                type: "SET_RESPONSE",
                payload: json,
              });
            },
            () => {
              stateDispatch({
                type: "SET_ERROR",
                payload: true,
              });
            }
          );
        } catch {
          stateDispatch({
            type: "SET_ERROR",
            payload: true,
          });
        }
      }
    };
    getInitialData();
  }, [username, error, loading, response]);

  return { response, error, loading };
};
