export const colors = {
  primary: "#0092bc",
  primaryRgba: opacity => `rgba(0,146,188,${opacity})`,
  secondary: "#b0008e",
  success: "#00bf3f",
  danger: "#f8333c",
  warning: "#f2a900",
  white: "#fff",
  lightgray: "#e6e6e6",
  lightestgray: "#f4f4f5",
  lightmidgray: "#d1d1d1",
  midgray: "#818181",
  darkgray: "#424242",
  black: "#1d1e25",
  background: "#f8f8f8",

  blue: "#0092bc",
  darkblue: "#00789A",

  disabled: "#c0c0c0",
};
