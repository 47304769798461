import React, { useReducer, createContext, useContext } from "react";
import { Status } from "wksConstants";

const alertDispatch = createContext("AlertDispatch");
export const useAlertDispatch = () => {
  return useContext(alertDispatch);
};

const alertContext = createContext("AlertContext");
export const useAlertContext = () => {
  return useContext(alertContext);
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "START_FORCE_REFRESH": {
      return { ...state, forceRefresh: true };
    }
    case "FINISH_FORCE_REFRESH": {
      return { ...state, forceRefresh: false };
    }
    case "SET_ALERTS": {
      const { alerts, totalNumAlerts } = action.payload;
      return { ...state, alerts, totalNumAlerts };
    }
    case "SET_FOOTER": {
      state.footer = action.payload;
      return { ...state, footer: action.payload };
    }
    case "SET_STATUS": {
      return { ...state, status: action.payload };
    }
    default:
      return { ...state };
  }
};

const defaultState = {
  alerts: [],
  totalNumAlerts: 0,
  footer: <></>,
  status: Status.NO_STATUS,
  forceRefresh: false,
};

export const AlertProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return (
    <alertDispatch.Provider value={dispatchF}>
      <alertContext.Provider value={[state, dispatchF]}>{children}</alertContext.Provider>
    </alertDispatch.Provider>
  );
};
