import React, { createContext, memo, useContext, useReducer } from "react";
import { produce } from "immer";
import { PORModel2 } from "./columns";
import moment from "moment-timezone";
import { byMpid, bySymbol } from "../finra/constant";

export enum AllOrPending {
  PENDING = "Pending",
  ALL = "All",
}

export type All_Or_Pending = `${AllOrPending}`;

export enum MpidOrSymbol {
  MPID = "MPID",
  SECURITY = "Symbol",
}
export type Mpid_Or_Security = `${MpidOrSymbol}`;

export enum PorOrPvr {
  POR = "POR",
  PVR = "PVR",
}
export type Por_Or_Pvr = `${PorOrPvr}`;

type PvrOrPor = "POR" | "PVR";
type FlipCard = {
  allOrPending: All_Or_Pending;
  mpidOrSymbol: Mpid_Or_Security;
  total: number;
  pending?: number;
  tableData: any[];
};

export type PORCacheAction =
  | {
      type: "SET_OTHER_CHART_DATA";
      payload: { others: any[] };
    }
  | { type: "SET_SELECTED"; payload: { selected: string } }
  | {
      type: "SET_ALL_OR_PENDING";
      payload: { allOrPending: AllOrPending; view: PvrOrPor };
    }
  | { type: "SET_MPID_OR_SECURITY"; payload: { MpidOrSymbol: MpidOrSymbol; view: PvrOrPor } }
  | { type: "SET_REQUEST_TABLE"; payload: { count: number; id: string } };

type PORCacheState = {
  [PorOrPvr.POR]: FlipCard;
  [PorOrPvr.PVR]: FlipCard;
  porTable: {};
  rejectsTable: {};
  hasSelected: boolean;
  selected: string;
  otherData: any[];
};

const defaultPORCacheState: PORCacheState = {
  [PorOrPvr.POR]: {
    allOrPending: AllOrPending.ALL,
    mpidOrSymbol: MpidOrSymbol.MPID,
    total: 67,
    pending: 12,
    tableData: [],
  },
  [PorOrPvr.PVR]: {
    allOrPending: AllOrPending.PENDING,
    mpidOrSymbol: MpidOrSymbol.MPID,
    total: 19577,
    tableData: [],
  },
  porTable: {},
  rejectsTable: {},
  hasSelected: false,
  selected: "",
  otherData: [],
};

const DispatchFn = (
  state: PORCacheState,
  actions: PORCacheAction | PORCacheAction[]
): PORCacheState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), {
    ...state,
  });
};

const mpids = ["DBAB", "DAIN", "BCAP", "MLCO", "PERS", "BIDS", "BOFA"];
const symbols = ["AAPL", "TSLA", "MSFT"];

const DispatchFnSwitch = (state: PORCacheState, action: PORCacheAction): PORCacheState => {
  switch (action.type) {
    case "SET_OTHER_CHART_DATA": {
      const { others } = action.payload;
      return produce(state, draft => {
        draft.otherData = others;
      });
    }
    case "SET_SELECTED": {
      const { selected } = action.payload;
      return produce(state, draft => {
        draft.hasSelected = true;
        draft.selected = selected === "All" ? "" : selected;
      });
    }
    case "SET_ALL_OR_PENDING": {
      const { view, allOrPending } = action.payload;

      return produce(state, draft => {
        draft[view].allOrPending = allOrPending;
      });
    }
    case "SET_MPID_OR_SECURITY": {
      const { view, MpidOrSymbol } = action.payload;

      return produce(state, draft => {
        draft[view].mpidOrSymbol = MpidOrSymbol;
      });
    }
    case "SET_REQUEST_TABLE": {
      let count = action.payload.count;
      let id = action.payload.id;
      const { mpidOrSymbol, allOrPending } = state[PorOrPvr.POR];

      let mpid, symbol;
      let key;
      if (mpidOrSymbol === MpidOrSymbol.SECURITY) {
        symbol = key;
      } else {
        mpid = key;
      }

      let keysIndex = 0;
      let countWithinKey = 0;
      const data = [];
      for (let i = 0; i < count; i++) {
        let randomDate = (start: any) => {
          return new Date(start.getTime() + Math.random() * 100000);
        };

        const date = randomDate(new Date());
        let state2 = Math.floor(Math.random() * 3);
        if (allOrPending === AllOrPending.PENDING) {
          state2 = 2;
        }
        key = id;
        const keys = Object.entries(mpidOrSymbol === MpidOrSymbol.SECURITY ? bySymbol : byMpid);

        if (id === "All") {
          const [mpidOrSymbol, num] = keys[keysIndex];
          key = mpidOrSymbol;
          if (countWithinKey <= num) {
            ++countWithinKey;
          } else {
            ++keysIndex;
            countWithinKey = 0;
          }
        } else if (id === "other") {
          const { value, name } = state.otherData[keysIndex];
          key = name;
          if (countWithinKey < value - 1) {
            ++countWithinKey;
          } else {
            ++keysIndex;
            countWithinKey = 0;
          }
        }

        data.push({
          [PORModel2.SYMBOL]:
            mpidOrSymbol === MpidOrSymbol.SECURITY && key !== "All"
              ? key
              : symbols[Math.floor(Math.random() * symbols.length)],
          [PORModel2.MPID]:
            mpidOrSymbol === MpidOrSymbol.MPID && key !== "All"
              ? key
              : mpids[Math.floor(Math.random() * mpids.length)],
          [PORModel2.REQUESTOR]: [
            "Santa@northPole.co",
            "Mrs.Clause@workShop.ntteamsp",
            "Blitzen@NSA.gov",
          ][Math.floor(Math.random() * 3)],
          [PORModel2.REQUEST_STATUS]: ["Approved", "Denied", "Pending"][state2],
          [PORModel2.LAST_UPDATE_TIME]: moment(randomDate(new Date())).format(
            "YYYY/MM/DD:hh:mm:ss"
          ),
          [PORModel2.REQUEST_ID]: Math.floor(Math.random() * 1000000),
          openUntil:
            state2 === 0
              ? moment(
                  date.getMilliseconds() + Math.random() * (1000 * Math.random() * 1000)
                ).format("hh:mm:ss")
              : "Closed",
        });
      }

      return { ...state, [PorOrPvr.POR]: { ...state[PorOrPvr.POR], tableData: data } };
    }

    default:
      return produce(state, draft => {});
  }
};

const PORCacheDispatch = createContext<React.Dispatch<PORCacheAction | PORCacheAction[]>>(
  () => null
);
PORCacheDispatch.displayName = "PORCacheDispatch";
export const usePORCacheDispatch = () => useContext(PORCacheDispatch);

const PORCacheContext = createContext<{
  state: PORCacheState;
  dispatch: React.Dispatch<PORCacheAction | PORCacheAction[]>;
}>({ state: defaultPORCacheState, dispatch: () => null });

PORCacheContext.displayName = "PORCacheContext";
export const usePORCacheContext = () => useContext(PORCacheContext);

const PORCacheProvider = ({
  defaultData,
  children,
}: {
  defaultData?: PORCacheState;
  children: React.ReactNode;
}) => {
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, defaultPORCacheState, defaultData)
  );

  return (
    <>
      <PORCacheDispatch.Provider value={dispatchF}>
        <PORCacheContext.Provider value={{ state, dispatch: dispatchF }}>
          {children}
        </PORCacheContext.Provider>
      </PORCacheDispatch.Provider>
    </>
  );
};

export const PORProvider = memo(PORCacheProvider);
