import { Button, Header, Popover, PopoverBody } from "@nef/core";
import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  justify-content: end;
  padding: 1rem 1rem 0rem 1rem;
`;

type PlacementType = "top" | "bottom";

interface ConfirmPopoverProps {
  children: React.ReactNode;
  isOpen: boolean;
  target: string;
  onNo: () => void;
  onYes: () => void;
  width?: number;
  placement?: PlacementType;
}

export const ConfirmPopover: React.FC<ConfirmPopoverProps> = ({
  children,
  isOpen,
  target,
  width,
  placement = "top",
  onNo,
  onYes,
}) => {
  return (
    <Popover target={target} isOpen={isOpen} width={width} placement={placement}>
      <PopoverBody>
        <Header size={3}>Are you sure?</Header>
        {children}
      </PopoverBody>
      <ButtonWrapper>
        <Button outline onClick={onNo}>
          No
        </Button>
        <Button onClick={onYes}>Yes</Button>
      </ButtonWrapper>
    </Popover>
  );
};
