import { CSSGrid, Section, SectionBody, SectionHeader } from "@nef/core";
import { memo } from "react";
import styled from "styled-components";

export const PaddedSectionHeader = styled(SectionHeader)`
  padding: 0 !important;
  padding-bottom: 1rem !important;
`;

export const PaddedSectionHeader2 = styled(SectionHeader)`
  padding: 0.5rem 1rem !important;
  display: block;
  & > div > div {
    display: block !important;
  }

  /* padding-bottom: 1rem !important; */
`;

export const SizedGrid = styled(CSSGrid)`
  height: calc(100% - 44px);
`;

export const TileFront = memo(
  ({
    HeaderBox,
    title1,
    title2,
    data1,
    data2,
  }: {
    HeaderBox: any;
    title1: string;
    title2: string;
    data1: any;
    data2: any;
  }) => {
    return (
      <>
        <PaddedSectionHeader style={{ width: "100%" }}>{HeaderBox}</PaddedSectionHeader>
        <SizedGrid cols={2} gap={".85rem"}>
          <Section>
            <PaddedSectionHeader2>{title1}</PaddedSectionHeader2>
            <SectionBody>{data1}</SectionBody>
          </Section>
          <Section>
            <PaddedSectionHeader2>{title2}</PaddedSectionHeader2>
            <SectionBody>{data2}</SectionBody>
          </Section>
        </SizedGrid>
      </>
    );
  }
);
