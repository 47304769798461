import { FieldNames } from "components/fields/fieldConstants";
import { Submit, useFormContext, useFormDispatch } from "components/form";
import { network } from "components/reportScan/network";
import {
  errorCallbackCB,
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { actionCallbacks, payload } from "components/standardTable/actionHooks";
import { useUserContext } from "components/user";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { handleCancel, setFormLoading, setTableLoading } from ".";
import { useViewModelDispatch } from "../../context";

const accentName = "secondary";
const buttonText = "Send";

interface SubmitButtonProps {
  table: string;
  form: { key: string; label: string };
}

export const MatchSubmit: React.FC<SubmitButtonProps> = ({ table, form }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const errorCallback = useCallback(
    errorCallbackCB({ formDispatch, standardTableDispatch, table, form }),
    [formDispatch, standardTableDispatch, table, form]
  );

  const onCancel = useCallback(() => {
    handleCancel({ viewDispatch, formDispatch, form, history });
  }, [viewDispatch, formDispatch, form, history]);

  const matchCallback = useCallback(
    (responseJson: any) => {
      actionCallbacks.match({
        formDispatch,
        standardTableDispatch,
        viewDispatch,
        form,
        table,
        ack: responseJson[0],
        history,
        userData,
      });
    },
    [form, formDispatch, history, standardTableDispatch, table, userData, viewDispatch]
  );

  const handleSubmit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: form,
      userData,
    });
    if (!valid) {
      return;
    }

    const fieldData = [{ ...formData[form.key].fields, [FieldNames.waitForTradeUpdate]: true }];

    const reqData = payload.report({
      fieldData: fieldData[0],
      username: userData.username,
    });
    network(userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS]).doReport(
      [reqData],
      matchCallback,
      errorCallback
    );

    setFormLoading(formDispatch, form);
    setTableLoading(standardTableDispatch, table);
  }, [
    formData,
    formDispatch,
    form,
    userData,
    matchCallback,
    errorCallback,
    standardTableDispatch,
    table,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      isDisabled={standardTableData[table]?.isLoading}
      isLoading={formData[form.key]?.isLoading}
      onCancel={onCancel}
      onAbort={undefined}
      abortText={undefined} // errors={Object.keys(formData[form.key].totalFormErrors).length}
    />
  );
};
