import { Indicator } from "@nef/core";
import React from "react";
import styled from "styled-components";
import { usePvMemberState } from "./context";

const Wrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`;

export const SymbolCol: React.FC<{ id: string; symbol: string }> = ({ id, symbol }) => {
  const pvMemberData = usePvMemberState();

  return (
    <Wrapper>
      <Indicator
        id={id}
        color={pvMemberData.por.find(p => p.symbol === symbol) ? "primary" : "danger"}
        tooltip={true}
      >
        {pvMemberData.por.find(p => p.symbol === symbol) ? "Pending" : "Closed"}
      </Indicator>
      {symbol}
    </Wrapper>
  );
};
