import { FORM_KEY } from "components/fields";

export const originatingFormKey = "originatingForm";

// TODO: collapse id and key into one, will need migration script
// to update persisted user data
export interface Form {
  id: FORM_KEY;
  key: string;
  label: string;
}
