import React, { useMemo } from "react";
import styled from "styled-components";
import {
  EQRC_RULE_STATUS,
  EqrcDoubleStatusContainer,
  getEqrcIdFromResponse,
  EqrcFields,
  getPropertyFromArray,
} from "./constants";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { getColor } from "@nef/style-utils";

const ActiveDiv = styled.div`
  ${props => `
    color: ${getColor("success", 700)(props)};
  `}
`;

const ConfiguredOnlyDiv = styled.div`
  ${props => `
    color: ${getColor("warning", 700)(props)};
  `}
`;

export const EqrcTableCell = ({ row, property, formatFn, tooltipContent }) => {
  const status = row.status;
  const { id, value } = useMemo(() => {
    let value;
    if (Array.isArray(property)) {
      value = getPropertyFromArray(row?.[status], property, 0);
    } else {
      value = row?.[status]?.[property];
    }
    return {
      id: getEqrcIdFromResponse(row[status]),
      value: typeof formatFn === "function" ? formatFn(value) : value,
    };
  }, [property, row, status, formatFn]);

  if (status === EQRC_RULE_STATUS.Active) {
    const content = <ActiveDiv>{value?.toString().replace(/,/g, ", ")}</ActiveDiv>;
    return tooltipContent ? (
      <TooltipTableCell id={`tooltip_${property}_${id}`}>{content}</TooltipTableCell>
    ) : (
      content
    );
  } else if (status === EQRC_RULE_STATUS.Configured) {
    const content = <ConfiguredOnlyDiv>{value?.toString().replace(/,/g, ", ")}</ConfiguredOnlyDiv>;
    return tooltipContent ? (
      <TooltipTableCell id={`tooltip_${property}_${id}`}>{content}</TooltipTableCell>
    ) : (
      content
    );
  } else {
    return "";
  }
};
