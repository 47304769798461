import React from "react";
import { PTRA_AGU_ENTITY, PTRA_CONFIGURATION_ENTITY, PTRA_VALIDATION_RESULT } from "./fields";
import { StatusIndicator } from "components/standardTable/statusIndicator";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { LIMIT_SIDE_BREACH_VALUES } from "components/fields/fieldConstants";
import { PTR_INTRADAY_NEXT_DAY_OPTIONS } from "components/fields/fieldConstants";
import moment from "moment-timezone";
import styled from "styled-components";
import { colors } from "../../../colors";
import { PTRA_EMAIL_ENTITY } from "./email/fields";

export const getPtraConfigIdentifier = config => {
  const id = `${config[PTRA_CONFIGURATION_ENTITY.id]}_${
    config[PTRA_CONFIGURATION_ENTITY.clearingNum]
  }${config[PTRA_CONFIGURATION_ENTITY.correspondentMPID]}_${
    config[PTRA_CONFIGURATION_ENTITY.email]?.[PTRA_EMAIL_ENTITY.id]
  }`;
  return id;
};

const MessageCount = styled.span`
  ${({ color }) => `
    text-decoration: underline;
    color: ${color};
  `}
`;

const YES = "Yes";
const NO = "No";
export const getYesOrNo = value => {
  return value ? YES : NO;
};

const PtraValidationResultColumn = (result, attribute) => {
  let color;
  switch (attribute) {
    case PTRA_VALIDATION_RESULT.warnings:
      color = colors.warning;
      break;
    case PTRA_VALIDATION_RESULT.errors:
      color = colors.danger;
      break;
    default:
      color = colors.black;
      break;
  }
  const messages = result[attribute]?.global;
  const attrJsx = messages?.map((attr, index) => {
    if (index < messages.length - 1) {
      return (
        <React.Fragment key={attr}>
          {attr}
          <br />
        </React.Fragment>
      );
    } else {
      return <React.Fragment key={attr}>{attr}</React.Fragment>;
    }
  });
  return (
    <TooltipTableCell
      align="center"
      id={`${attribute}_${getPtraConfigIdentifier(result)}`}
      tooltipContent={attrJsx ? attrJsx : `No ${attribute}`}
    >
      {messages?.length ? <MessageCount color={color}>{messages.length}</MessageCount> : "None"}
    </TooltipTableCell>
  );
};

const RiskAlertSharedColumns = {
  [PTRA_CONFIGURATION_ENTITY.isEnabled]: {
    Header: "Status",
    accessor: PTRA_CONFIGURATION_ENTITY.isEnabled,
    width: 90,
    id: PTRA_CONFIGURATION_ENTITY.isEnabled,
    Cell: ({ row }) => {
      return (
        <StatusIndicator
          color={row.original[PTRA_CONFIGURATION_ENTITY.isEnabled] ? "success" : "danger"}
          id={`${row.original[PTRA_CONFIGURATION_ENTITY.id]}_status`}
        >
          {row.original[PTRA_CONFIGURATION_ENTITY.isEnabled] ? "Enabled" : "Disabled"}
        </StatusIndicator>
      );
    },
    sortType: (a, b) => {
      if (
        a.original[PTRA_CONFIGURATION_ENTITY.isEnabled] >
        b.original[PTRA_CONFIGURATION_ENTITY.isEnabled]
      ) {
        return -1;
      } else if (
        a.original[PTRA_CONFIGURATION_ENTITY.isEnabled] <
        b.original[PTRA_CONFIGURATION_ENTITY.isEnabled]
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  [PTRA_CONFIGURATION_ENTITY.clearingMPID]: {
    Header: "Clr MPID",
    accessor: PTRA_CONFIGURATION_ENTITY.clearingMPID,
    width: 90,
    id: PTRA_CONFIGURATION_ENTITY.clearingMPID,
  },
  [PTRA_CONFIGURATION_ENTITY.correspondentMPID]: {
    Header: "Corr MPID",
    accessor: PTRA_CONFIGURATION_ENTITY.correspondentMPID,
    width: 90,
    id: PTRA_CONFIGURATION_ENTITY.correspondentMPID,
  },
  [PTRA_CONFIGURATION_ENTITY.limitBreachSide]: {
    Header: "Counter Party Alerts",
    accessor: row => {
      const lbs = row[PTRA_CONFIGURATION_ENTITY.limitBreachSide];
      if (lbs !== undefined && lbs !== null && lbs === LIMIT_SIDE_BREACH_VALUES.Own) {
        return NO;
      } else {
        return YES;
      }
    },
    width: 110,
    id: PTRA_CONFIGURATION_ENTITY.limitBreachSide,
  },
  [PTRA_CONFIGURATION_ENTITY.includeGiveups]: {
    Header: "Include Giveups",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.includeGiveups]),
    width: 90,
    id: PTRA_CONFIGURATION_ENTITY.includeGiveups,
  },
  [PTRA_CONFIGURATION_ENTITY.emailId]: {
    Header: "Email",
    accessor: row => row[PTRA_CONFIGURATION_ENTITY.email]?.recipientEmail,
    width: 150,
    id: PTRA_CONFIGURATION_ENTITY.emailId,
  },
  [PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]: {
    Header: "Email Real-Time",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]: {
    Header: "Email End of Day",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAlertLogActive]: {
    Header: "UI Log",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAlertLogActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]: {
    Header: "Pop-ups",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAlertPopupActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]: {
    Header: "Sound",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAlertSoundActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive]: {
    Header: "Next Day",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive]: {
    Header: "Intraday",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]: {
    Header: "Agg. Alert",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]: {
    Header: "Agg. Hold",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]: {
    Header: "Agg. Kill",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isAggregateKillActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]: {
    Header: "Per Trade Alert",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]: {
    Header: "Per Trade Hold",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]: {
    Header: "Max Trade",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]: {
    Header: "Held Trade",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isHeldTradeActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]: {
    Header: "Kill Limit Reject",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive]: {
    Header: "Bulk EOD Update",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive,
  },
  [PTRA_CONFIGURATION_ENTITY.creator]: {
    Header: "Alert Owner",
    accessor: row => row[PTRA_CONFIGURATION_ENTITY.creator]?.[PTRA_EMAIL_ENTITY.recipientEmail],
    width: 110,
    id: PTRA_CONFIGURATION_ENTITY.creator,
  },
  [PTRA_CONFIGURATION_ENTITY.lastUpdate]: {
    Header: "Last Update",
    accessor: row =>
      row[PTRA_CONFIGURATION_ENTITY.lastUpdate]
        ? moment(row[PTRA_CONFIGURATION_ENTITY.lastUpdate]).format("MM/DD/YYYY HH:mm:SS A")
        : "N/A",
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.lastUpdate,
  },
  [PTRA_VALIDATION_RESULT.hasError]: {
    Header: "Valid",
    accessor: PTRA_VALIDATION_RESULT.errors,
    width: 90,
    id: PTRA_VALIDATION_RESULT.hasError,
    Cell: ({ row }) => {
      let color = "success";
      let text = "This is a valid configuration";
      if (row.original[PTRA_VALIDATION_RESULT.errors]) {
        color = "danger";
        text = "Validation error(s) detected";
      } else if (row.original[PTRA_VALIDATION_RESULT.warnings]) {
        color = "warning";
        text = "This is a valid configuration with at least one warning";
      }
      return (
        <StatusIndicator
          color={color}
          id={`${getPtraConfigIdentifier(row.original)}_${[PTRA_VALIDATION_RESULT.hasError]}`}
        >
          {text}
        </StatusIndicator>
      );
    },
    sortType: (a, b) => {
      const getVal = result => {
        return result?.[PTRA_VALIDATION_RESULT.errors]?.[PTRA_VALIDATION_RESULT.global]?.length
          ? 2
          : result?.[PTRA_VALIDATION_RESULT.warnings]?.[PTRA_VALIDATION_RESULT.global]?.length
          ? 1
          : 0;
      };
      const aVal = getVal(a.original);
      const bVal = getVal(b.original);
      return aVal - bVal;
    },
  },
  [PTRA_VALIDATION_RESULT.errors]: {
    Header: "Error(s)",
    accessor: PTRA_VALIDATION_RESULT.errors,
    width: 90,
    id: [PTRA_VALIDATION_RESULT.errors],
    Cell: ({ row }) => PtraValidationResultColumn(row.original, PTRA_VALIDATION_RESULT.errors),
    sortType: (a, b) => {
      return (
        (a.original?.[PTRA_VALIDATION_RESULT.errors]?.[PTRA_VALIDATION_RESULT.global]?.length ||
          0) -
        (b.original?.[PTRA_VALIDATION_RESULT.errors]?.[PTRA_VALIDATION_RESULT.global]?.length || 0)
      );
    },
  },
  [PTRA_VALIDATION_RESULT.warnings]: {
    Header: "Warning(s)",
    accessor: PTRA_VALIDATION_RESULT.warnings,
    width: 90,
    id: [PTRA_VALIDATION_RESULT.warnings],
    Cell: ({ row }) => PtraValidationResultColumn(row.original, PTRA_VALIDATION_RESULT.warnings),
    sortType: (a, b) => {
      return (
        (a.original?.[PTRA_VALIDATION_RESULT.warnings]?.[PTRA_VALIDATION_RESULT.global]?.length ||
          0) -
        (b.original?.[PTRA_VALIDATION_RESULT.warnings]?.[PTRA_VALIDATION_RESULT.global]?.length ||
          0)
      );
    },
  },
  [PTRA_AGU_ENTITY.executingMPID]: {
    Header: "MPID",
    accessor: PTRA_AGU_ENTITY.executingMPID,
    width: 90,
    id: PTRA_AGU_ENTITY.executingMPID,
  },
  [PTRA_CONFIGURATION_ENTITY.intradayNextDay]: {
    Header: "Intraday / Next Day",
    accessor: row =>
      row[PTRA_CONFIGURATION_ENTITY.intradayNextDay]
        ? PTR_INTRADAY_NEXT_DAY_OPTIONS[row[PTRA_CONFIGURATION_ENTITY.intradayNextDay]]?.label
        : "<All>",
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.intradayNextDay,
  },
  [PTRA_CONFIGURATION_ENTITY.isAggSettingActive]: {
    Header: "Agg. Settings",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isAggSettingActive]),
    width: 90,
    id: PTRA_CONFIGURATION_ENTITY.isAggSettingActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]: {
    Header: "Per Trade Settings",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]: {
    Header: "Max Trade Settings",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]),
    width: 100,
    id: PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive,
  },
  [PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]: {
    Header: "Default Action Settings",
    accessor: row => getYesOrNo(row[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]),
    width: 120,
    id: PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive,
  },
};

export const RiskAlertColumns = [
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isEnabled],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.limitBreachSide],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.includeGiveups],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertLogActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive],
  // RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.lastUpdate],
];

export const RiskAlertCreateValidationColumns = [
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.hasError],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.errors],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.warnings],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.limitBreachSide],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.includeGiveups],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertLogActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive],
  // RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
];

export const RiskAlertEditValidationColumns = [
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.hasError],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.errors],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.warnings],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.limitBreachSide],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.includeGiveups],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertLogActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive],
  // RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
];

export const AguRiskAlertColumns = [
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isEnabled],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.lastUpdate],
];

export const AguRiskAlertCreateValidationColumns = [
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.hasError],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.errors],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.warnings],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
];

export const AguRiskAlertEditValidationColumns = [
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.hasError],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.errors],
  RiskAlertSharedColumns[PTRA_VALIDATION_RESULT.warnings],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.clearingMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.correspondentMPID],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.emailId],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.intradayNextDay],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
  RiskAlertSharedColumns[PTRA_CONFIGURATION_ENTITY.creator],
];
