import { Forms } from "components/fields";
import { AlertForm, Divider } from "../constants";
import { PtrEmailDeliveryOptions } from "../form/emailDeliveryOptions";
import { PtrSettingAlerts } from "../form/riskLimitSettingAlerts";
import { PtrAguMPIDOptions } from "./mpidOptionsAgu";

export const AguRiskAlertForm = () => {
  return (
    <AlertForm>
      <PtrAguMPIDOptions form={Forms.PTRA_AGU_CONFIG} />
      <Divider />
      <PtrSettingAlerts form={Forms.PTRA_AGU_CONFIG} />
      <Divider />
      <PtrEmailDeliveryOptions form={Forms.PTRA_AGU_CONFIG} />
    </AlertForm>
  );
};
