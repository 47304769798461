import { NotificationHub } from "@nef/core";
import React, { useCallback, useEffect, useState } from "react";
import { ActiveForm, EqrcRuleStatus, handleImplicitDecimals, useEqrcRuleDispatch } from ".";
import { useFormContext, useFormDispatch } from "../../form";
import {
  getFormValidation,
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../../standardTable";
import { createBody, updateRule } from "./network";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { EqrcFields, getEqrcIdFromResponse, EQRC_RULE_STATUS } from "./constants";
import { Forms } from "components/fields";
import { EqrcRules } from "./types.consts";

export const EQRCEditForm = ({
  table,
  form,
  header,
  fieldSets,
  isOpen,
  onClose,
  setStep,
  currentStep,
  totalSteps,
}) => {
  const [tableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [isSaving, setSaving] = useState(false);
  const [userData] = useUserContext();
  const eqrcRuleDispatch = useEqrcRuleDispatch();

  const handleSaveSuccess = useCallback(
    (
      table,
      json,
      { originalRow },
      { showNotification = true, resetForm = true, doOnClose = true, deselectRows = true } = {
        showNotification: true,
        resetForm: true,
        doOnClose: true,
        deselectRows: true,
      }
    ) => {
      const tableDispatches = [{ type: "SET_IS_LOADING", payload: { table, isLoading: false } }];

      if (json) {
        json[EqrcFields.eqrcId] = getEqrcIdFromResponse(json);
        const newRow = { ...originalRow };

        const type = newRow[EQRC_RULE_STATUS.Configured]
          ? EQRC_RULE_STATUS.Configured
          : EQRC_RULE_STATUS.Active;

        handleImplicitDecimals(json);
        newRow[type] = json;
        const newRowOfType = newRow[type];

        if (newRowOfType[EqrcFields.brokers]) {
          newRowOfType[EqrcFields.brokersListRule] = {
            [EqrcFields.brokers]: newRowOfType[EqrcFields.brokers],
          };
        } else {
          newRowOfType[EqrcFields.brokersListRule] = originalRow[type][EqrcFields.brokersListRule];
        }

        newRowOfType[EqrcFields.status] = originalRow[type][EqrcFields.status];
        if (newRowOfType[EqrcFields.sharesLocatedOption]) {
          let value = newRowOfType[EqrcFields.sharesLocatedOption];

          newRowOfType[EqrcFields.sharesLocateRule] = {
            [EqrcFields.sharesLocatedOption]: value,
          };
          newRowOfType[EqrcFields.sharesLocatedOption] = value;
        }

        if (originalRow[type][EqrcFields.hasBrokerList] !== undefined) {
          newRowOfType[EqrcFields.hasBrokerList] = originalRow[type][EqrcFields.hasBrokerList];
        }

        newRow[type] = newRowOfType;

        tableDispatches.push({
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: { table: table, row: newRow, idField: EqrcFields.eqrcId },
        });
      }

      setSaving(false);
      if (showNotification) {
        NotificationHub.send("success", "The rule has been successfully edited");
      }
      if (resetForm) {
        formDispatch({ type: "RESET_FORM", payload: { form } });
      }
      if (doOnClose) {
        onClose();
      }

      if ([Forms.EQRC_GROSS_EXPOSURE, Forms.EQRC_EDIT_GROSS_EXPOSURE].includes(form)) {
        const requestMap = {
          [EqrcRules.grossExposure]: new Set([EqrcRuleStatus.active]),
        };

        eqrcRuleDispatch([
          {
            type: "SET_REQUESTING",
            payload: { isRequesting: true, requestMap },
          },
          {
            type: "SET_POLLING",
            payload: { isPolling: true, pollMap: requestMap },
          },
        ]);
      }

      if (deselectRows) {
        tableDispatches.push({
          type: "DESELECT_ALL_ROWS",
          payload: { table: table },
        });
      }

      standardTableDispatch(tableDispatches);
    },
    [standardTableDispatch, formDispatch, form, eqrcRuleDispatch, onClose]
  );

  const handleSaveError = useCallback(
    (
      table,
      error,
      originalRow,
      { showNotification = true } = {
        showNotification: true,
      }
    ) => {
      if (showNotification) {
        const message = error?.message ? (
          error.message
        ) : (
          <ul>
            {Object.entries(error).map(([field, message]) => (
              <li key={`${field}_${message}`}>{`${field} ${message}`}</li>
            ))}
          </ul>
        );

        NotificationHub.send("danger", "Edit failed for the rule", { subtitle: message });
      }
      standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });

      setSaving(false);
    },
    [standardTableDispatch]
  );

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );

  const handleSave = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);

    if (!valid) {
      return;
    }

    standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: true } });
    const rows = getSelectedRows(tableData[table]);
    if (rows.length === 1) {
      setSaving(true);
      const body = createBody(formData[form.key].fields);

      updateRule(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
        formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status] ===
          EQRC_RULE_STATUS.Active,
        body,
        table,
        { eqrcId: body[EqrcFields.eqrcId], originalRow: rows[0] },
        handleSaveSuccess,
        handleSaveError,
        false
      );
      // }
    } else {
      NotificationHub.send("danger", "Unable to edit", {
        subtitle: "More than one row is selected",
      });
    }
  }, [
    form,
    formData,
    formDispatch,
    standardTableDispatch,
    table,
    tableData,
    userData,
    handleSaveSuccess,
    handleSaveError,
  ]);

  return (
    <ActiveForm
      table={table}
      form={form}
      header={header}
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      isSaving={isSaving}
      includeExchMpidPort={true}
      setStep={setStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      validate={validate}
      disableMpidPort={true}
    />
  );
};
