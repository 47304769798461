import { Button } from "@nef/core";
import {
  eqrcMpidPortExchangeFields,
  eqrcMpidPortFields,
  FieldLoop,
  FORM_KEY,
  SelectOptions,
} from "components/fields";
import { useFormContext, useFormDispatch } from "components/form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addPrefixToField } from "utils/js.utils";
import { EqrcInputContextState, EXCHANGES, useEqrcInputContext } from "./eqrcInputContext";
import { EqrcFields, EQRC_FIELD_PREFIX } from "./constants";
import styled from "styled-components";
import { Form, SelectOption } from "types";
import { useFieldOptionDispatch } from "components/fields/loadingContext";

const StyledButton = styled(Button)`
  grid-column: span 2;
  margin-bottom: -7px !important;
`;

interface EqrcExchangeMpidPortProps {
  form: Form;
  btnProps?: ButtonComponentProps;
  ButtonComponent?: typeof Button;
  children?: React.ReactNode;
  hideExchange?: boolean;
  hideClearButton?: boolean;
  onClear?: () => void;
  isDisabled?: boolean;
}

const getRefreshKey = (
  exchangeValue: string,
  mpidValue: string,
  portValue: string | SelectOption[]
) => {
  return `${exchangeValue}|${mpidValue}|${Array.isArray(portValue) ? undefined : portValue}`;
};

const addPortsFromExchangeAndMpid = (
  portsToUpdate: { [key: string]: SelectOption },
  inputData: EqrcInputContextState,
  exchangeValue: string | null,
  mpidValue: string
) => {
  let exchanges: { [key: string]: string } = EXCHANGES;
  if (exchangeValue !== null) {
    exchanges = { exchange: exchangeValue };
  }
  Object.values(exchanges).forEach(exchange => {
    Object.entries(inputData.mpids[mpidValue].ports).forEach(([key, option]) => {
      if (inputData.exchanges[exchange].ports.hasOwnProperty(key)) {
        portsToUpdate[key] = option;
      }
    });
  });
  return portsToUpdate;
};

interface ButtonComponentProps {
  color: string;
  size: string;
  outline: boolean;
}

const defaultBtnProps = {
  color: "secondary",
  size: "sm",
  outline: false,
};

export const EqrcExchangeMpidPort: React.FC<EqrcExchangeMpidPortProps> = ({
  children,
  form,
  btnProps = defaultBtnProps,
  ButtonComponent = StyledButton,
  hideExchange = false,
  hideClearButton = false,
  isDisabled = false,
  onClear,
}) => {
  const [inputData] = useEqrcInputContext();
  const [formData]: [{ [key: string]: any }] = useFormContext();
  const formDispatch = useFormDispatch();
  const [refreshKey, setRefresh] = useState("first_load");
  const fieldOptionsDispatch = useFieldOptionDispatch();
  useEffect(() => {
    const exchangeValue = formData[form.key].fields[EqrcFields.exchange]?.value;
    const mpidValue = formData[form.key].fields[EqrcFields.mpid]?.value;
    let portValue: string | SelectOption[];
    if (Array.isArray(formData[form.key].fields[EqrcFields.port])) {
      portValue = formData[form.key].fields[EqrcFields.port];
    } else {
      portValue = formData[form.key].fields[EqrcFields.port]?.value;
    }
    const keyFromValues = getRefreshKey(exchangeValue, mpidValue, portValue);
    if (refreshKey !== keyFromValues) {
      if (Array.isArray(portValue) && portValue.length > 0) {
        // if ports is multi-select then all available ports will
        // be related to the selected exchange+mpid
        portValue = portValue[0].value as string;
      }
      if (portValue) {
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)}${form.key}`] =
          () => Object.values(inputData.ports[portValue as string].exchanges);
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)}${form.key}`] = () =>
          Object.values(inputData.ports[portValue as string].mpids);
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)}${form.key}`] =
          () => {
            if (mpidValue) {
              return Object.values(
                addPortsFromExchangeAndMpid(
                  {},
                  inputData,
                  exchangeValue ? exchangeValue : null,
                  mpidValue as string
                )
              );
            } else {
              const ports = Object.values(inputData.ports[portValue as string].mpids).reduce(
                (acc, mpidOption) => {
                  // updates acc with new ports
                  addPortsFromExchangeAndMpid(
                    acc,
                    inputData,
                    exchangeValue ? exchangeValue : null,
                    mpidOption.value as string
                  );
                  return acc;
                },
                {} as { [key: string]: SelectOption }
              );
              return Object.values(ports);
            }
          };
      } else {
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)}${form.key}`] =
          () => {
            if (mpidValue) {
              return Object.values(inputData.mpids[mpidValue].exchanges);
            } else {
              return Object.values(inputData.allExchangeOptions);
            }
          };
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)}${form.key}`] =
          () => {
            if (exchangeValue) {
              return Object.values(inputData.exchanges[exchangeValue].mpids);
            } else {
              return Object.values(inputData.allMpidOptions);
            }
          };
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)}${form.key}`] =
          () => {
            if (mpidValue && exchangeValue) {
              return Object.values(
                addPortsFromExchangeAndMpid({}, inputData, exchangeValue, mpidValue)
              );
            } else if (mpidValue) {
              return Object.values(inputData.mpids[mpidValue].ports);
            } else if (exchangeValue) {
              return Object.values(inputData.exchanges[exchangeValue].ports);
            } else {
              return Object.values(inputData.allPortOptions);
            }
          };
      }
      // hacky way to refresh the fields
      fieldOptionsDispatch({
        type: "RESET_OPTIONS_CACHE",
        payload: { form: form.key as FORM_KEY },
      });
      setRefresh(keyFromValues);
    }
  }, [inputData, formData, form, formDispatch, refreshKey, fieldOptionsDispatch]);

  const resetFields = useCallback(() => {
    if (onClear) {
      onClear();
    } else {
      formDispatch([
        {
          type: "SET_FORM_VALUES",
          payload: {
            form,
            fields: {
              [EqrcFields.exchange]: null,
              [EqrcFields.mpid]: null,
              [EqrcFields.port]: null,
            },
          },
        },
        {
          type: "INIT_FORM_VALIDATION",
          payload: { form },
        },
      ]);
    }
  }, [form, formDispatch, onClear]);

  const fields = useMemo(() => {
    if (hideExchange) {
      return eqrcMpidPortFields;
    } else {
      return eqrcMpidPortExchangeFields;
    }
  }, [hideExchange]);

  return (
    <>
      <FieldLoop
        form={form}
        fields={fields}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isReactFragment={true}
        isDisabled={isDisabled}
        containerRef={undefined}
        showLabel={undefined}
      />
      {children}
      {hideClearButton ? (
        <></>
      ) : (
        <ButtonComponent
          type="button"
          {...btnProps}
          onClick={resetFields}
          block
          disabled={isDisabled}
        >
          Clear
        </ButtonComponent>
      )}
    </>
  );
};
