import { Forms } from "components/fields/fieldConstants";
import { StandardTables } from "wksConstants";
import { ClearCorrespondentRiskAlertEditForm, ClearCorrespondentRiskAlertForm } from "..";
import { RiskAlertSettingsTable } from "../riskAlertSettingsTable";

export const ClearCorrespondentRiskAlertSettingsTable = () => {
  return (
    <RiskAlertSettingsTable
      isAgu={false}
      table={StandardTables.PTRA_CONFIG}
      createTable={StandardTables.PTRA_CREATE_VALIDATION}
      editTable={StandardTables.PTRA_EDIT_VALIDATION}
      newForm={Forms.PTRA_CONFIG}
      editForm={Forms.PTRA_EDIT}
      NewForm={ClearCorrespondentRiskAlertForm}
      EditForm={ClearCorrespondentRiskAlertEditForm}
    />
  );
};
