import { NotificationHub } from "@nef/core";
import { EmptyState } from "@nef/core/lib";
import { FieldLoop, FieldNames, stats } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import {
  Dissemination,
  LateTrades,
  Statuses,
  TradeAccuracy,
  useStatsContext,
} from "components/stats";
import { DroppableItem, Numbers } from ".";
import { messages } from "../../viewConstants";
import { Status } from "../../wksConstants";
import { useFormDispatch } from "../form";
import { useStatsDispatch } from "../stats";
import { useUserContext } from "../user";

export const RightStats = ({ isOpen }) => {
  const [user] = useUserContext();
  const [data] = useStatsContext();
  const statsDispatch = useStatsDispatch();
  const [isHovered] = useState(false);
  const history = useHistory();
  const formDispatch = useFormDispatch();

  useEffect(() => {
    statsDispatch({ type: "SET_IS_POLLING_RIGHT", payload: true });
    return () => {
      statsDispatch({ type: "SET_IS_POLLING_RIGHT", payload: false });
    };
  }, [statsDispatch]);

  const augmentOnChange = useCallback(
    payload => {
      const statsActions = [
        {
          type: "SET_SHOULD_ABORT",
          payload: true,
        },
      ];
      switch (payload?.field) {
        case FieldNames.mpid:
          if (Array.isArray(payload?.value) && payload.value.length <= data.maxNumStatsMpid) {
            statsActions.push({
              type: "FORM_CHANGE",
              payload: payload,
            });
          } else {
            formDispatch({
              type: "UPDATE_FORM_VALUE",
              payload: {
                form: Forms.ST_MIDDLE,
                field: FieldNames.mpid,
                value: payload.value.slice(0, payload.value.length - 1),
                entitlements: user.entitlements,
              },
            });
            NotificationHub.send(
              "danger",
              `Cannot select more than ${data.maxNumStatsMpid} MPIDs for Real Time Stats`
            );
          }
          break;
        case FieldNames.trf:
        default:
          statsActions.push({
            type: "FORM_CHANGE",
            payload: payload,
          });
          break;
      }
      statsDispatch(statsActions);
    },
    [statsDispatch, data.maxNumStatsMpid, formDispatch, user.entitlements]
  );

  const rightChartClick = useCallback(
    chart => (e, data) => {
      history.push("/stats");
      statsDispatch({ type: "SET_CLICKED_CHART_SLICE", payload: { name: data.name, chart } });
    },
    [statsDispatch, history]
  );

  const [charts, setCharts] = useState([
    {
      id: "lateTrades",
      child: (
        <LateTrades legend={false} handleClick={rightChartClick("Late Trades")} isRight={true} />
      ),
    },
    {
      id: "laterTrades",
      child: (
        <Dissemination
          legend={false}
          handleClick={rightChartClick("Dissemination")}
          isRight={true}
        />
      ),
    },
    {
      id: "tradeAccuracy",
      child: (
        <TradeAccuracy
          legend={false}
          handleClick={rightChartClick("Trade Accuracy")}
          isRight={true}
        />
      ),
    },
    {
      id: "statuses",
      child: <Statuses handleClick={rightChartClick("Statuses")} isRight={true} />,
    },
  ]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setCharts(reorder(charts, result.source.index, result.destination.index));
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <>
      <FieldLoop
        form={Forms.ST_MIDDLE}
        fields={stats}
        augmentOnChange={augmentOnChange}
        showLabel={false}
      />

      {Status.SUCCESS === data.feedStatus ? (
        <>
          <Numbers />
          <DragDropContext onDragEnd={onDragEnd}>
            <DroppableItem id="chartDroppable" list={charts} showDragIcon={isHovered} />
          </DragDropContext>
        </>
      ) : Status.ERROR === data.feedStatus ? (
        <EmptyState titleSize={3} emptyStateTitle={messages.streamDown}></EmptyState>
      ) : (
        <EmptyState titleSize={3} emptyStateTitle={"Initializing Stream..."}></EmptyState>
      )}
    </>
  );
};
