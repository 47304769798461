import React, { useCallback } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import { createAndDownloadTemplate, convertToCSV } from "./utils";
import { useStandardTableContext } from "./standardTableContext";
import { LoadingIcon } from "components/loadingIcon";
import styled from "styled-components";

const iconStyle = { marginRight: "3px", color: "inherit" };
export const ExportNotificationButton = ({ table }) => {
  const [standardTableData] = useStandardTableContext();

  const handleExport = useCallback(() => {
    const accessors = {};
    const headers = standardTableData[table].columnsOrder
      .filter(c => !standardTableData[table].hiddenColumns.includes(c))
      .map(c => {
        const a = standardTableData[table].allColumns.find(colDef => {
          return colDef.id === c;
        });
        accessors[a.Header] = a.accessor;
        return a.Header;
      });

    createAndDownloadTemplate(
      `${table}.csv`,
      convertToCSV(headers, standardTableData[table].allTheData, accessors)
    );
  }, [standardTableData, table]);

  return (
    <Button disabled={standardTableData[table].isExportDisabled} onClick={handleExport} size={"sm"}>
      <LoadingIcon isLoading={standardTableData[table].isExportDisabled} style={iconStyle}>
        <FontAwesomeIcon iconClassName="fa-arrow-circle-down" style={iconStyle} />
      </LoadingIcon>
      Export complete data set
    </Button>
  );
};
