import { NotificationHub } from "@nef/core";
import {
  ApiResponseNames,
  FieldNames,
  Forms,
  MATCH_FIELD_PREFIX,
  OptionConstants,
  REPORTING_PARTY_OPTIONS,
  SIDE_OPTIONS,
} from "components/fields/fieldConstants";
import { ReportingParty, TR_SIDE } from "components/fields/constants";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { TableButton } from "components/standardTable";
import { useUserContext } from "components/user";
import { addPrefixToField, isReversal } from "utils/js.utils";
import { ViewForm, ViewForms } from "viewConstants";
import { buySideValues, Messages, TableButtonAction, TableButtonLabel } from "wksConstants";
import { getIsButtonDisabled } from "./util";
import { useViewModelContext, useViewModelDispatch } from "../../context";
import { useFormDispatch } from "../../form";
import {
  convertTableToForm,
  getNumSelectedRows,
  getSelectedRow,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";
import { Form } from "components/form/constants";
import { SelectOption } from "types";

const matchButtonConfig = {
  icon: "clone",
  text: TableButtonLabel.MATCH,
  actionId: TableButtonAction.MATCH,
  requireSelect: true,
  allowMultiSelect: false,
  disabledMessage: Messages.ONE_ROW,
  header: TradesHeader,
};

export const MatchButton: React.FC<{ table: string; form: Form }> = ({ table, form }) => {
  const [user] = useUserContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [viewData] = useViewModelContext();
  const formDispatch = useFormDispatch();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const [standardTableData] = useStandardTableContext();

  const [row, numSelected] = useMemo(() => {
    return [getSelectedRow(standardTableData[table]), getNumSelectedRows(standardTableData[table])];
  }, [table, standardTableData]);

  const handleMatch = useCallback(async () => {
    const rowData: { [field: string]: any } = convertTableToForm(row, user);
    Object.assign(rowData, { objVer: row.objver });
    if (isReversal(row[ApiResponseNames.reversalFlag])) {
      NotificationHub.send("warning", "The match action form cannot be used to match a reversal.");
    } else {
      const matchData: any = {};
      matchData[addPrefixToField(MATCH_FIELD_PREFIX, FieldNames.stepInOut)] =
        rowData[FieldNames.stepInOut];

      matchData[FieldNames.symbol] = rowData[FieldNames.symbol];
      matchData[FieldNames.price] = rowData[FieldNames.price];
      matchData[FieldNames.contract] = rowData[FieldNames.contract];
      matchData[FieldNames.priceTradeDigit] = rowData[FieldNames.priceTradeDigit];
      matchData[FieldNames.quantity] = rowData[FieldNames.quantity];
      matchData[FieldNames.clearReportRiskVals] = (
        rowData[FieldNames.clearReportRiskVals] as SelectOption[]
      ).reduce((acc, curr) => {
        if (curr?.value !== OptionConstants[FieldNames.clearReportRiskVals].report) {
          acc.push(curr);
        }
        return acc;
      }, [] as SelectOption[]);
      matchData[FieldNames.executionDate] = rowData[FieldNames.executionDate];
      matchData[FieldNames.executionTime] = rowData[FieldNames.executionTime];
      matchData[FieldNames.referenceReportingVenue] = rowData[FieldNames.referenceReportingVenue];
      matchData[FieldNames.trf] = rowData[FieldNames.trf];
      matchData[FieldNames.relatedMarketFlag] = rowData[FieldNames.relatedMarketFlag];
      matchData[FieldNames.intendedMarketFlag] = rowData[FieldNames.intendedMarketFlag];
      matchData[FieldNames.settlement] = rowData[FieldNames.settlement];
      matchData[FieldNames.sellerDays] = rowData[FieldNames.sellerDays];
      matchData[FieldNames.special] = rowData[FieldNames.special];

      const ogCounterMPID = rowData[FieldNames.counterMPID];
      const myMPID = { id: ogCounterMPID, value: ogCounterMPID, label: ogCounterMPID };
      const counterMPID = rowData[FieldNames.mpid]?.value
        ? rowData[FieldNames.mpid].value
        : rowData[FieldNames.mpid];

      matchData[FieldNames.mpid] = myMPID;
      matchData[FieldNames.counterMPID] = counterMPID;

      matchData[FieldNames.giveUpMPID] = rowData[FieldNames.counterGiveUpMPID];
      matchData[FieldNames.counterGiveUpMPID] = rowData[FieldNames.giveUpMPID];

      const myClearingNum = rowData[FieldNames.counterClearingNum];
      const counterClearingNum = rowData[FieldNames.clearingNum];

      const myRole =
        rowData[FieldNames.reportingParty]?.value === ReportingParty.Exec
          ? REPORTING_PARTY_OPTIONS[ReportingParty.Contra]
          : REPORTING_PARTY_OPTIONS[ReportingParty.Exec];
      matchData[FieldNames.reportingParty] = myRole;

      matchData[FieldNames.clearingNum] = myClearingNum;
      matchData[FieldNames.counterClearingNum] = counterClearingNum;

      const mySide = buySideValues.includes(rowData[FieldNames.side]?.value)
        ? SIDE_OPTIONS[TR_SIDE.SELL]
        : SIDE_OPTIONS[TR_SIDE.BUY];
      matchData[FieldNames.side] = mySide;

      formDispatch([
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_MODIFY, entitlements: user.entitlements },
        },
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_REPAIR, entitlements: user.entitlements },
        },
        {
          type: "SET_FORM_VALUES",
          payload: {
            form: ViewForms[viewData.view][ViewForm.MATCH],
            fields: matchData,
          },
        },
        {
          type: "UPDATE_CLEARING_PRICE",
          payload: {
            form: ViewForms[viewData.view][ViewForm.MATCH],
          },
        },
        {
          type: "INIT_FORM_VALIDATION",
          payload: { form, entitlements: user.entitlements },
        },
      ]);

      viewDispatch([
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_MODIFY },
        },
        {
          type: "CHANGE_FORM",
          payload: { form: ViewForm.MATCH, history },
        },
        {
          type: "ENABLE_FORM",
          payload: { form },
        },
      ]);
      standardTableDispatch({
        type: "SET_CLICKED_ROW",
        payload: { row },
      });
    }
  }, [row, user, formDispatch, viewData.view, viewDispatch, history, form, standardTableDispatch]);

  return (
    <TableButton
      canConfirm={undefined}
      allowConfirm={undefined}
      id={undefined}
      style={undefined}
      selectedRows={undefined}
      allowMenuOverride={undefined}
      loading={undefined}
      children={undefined}
      onCloseConfirm={undefined}
      table={table}
      {...matchButtonConfig}
      disabled={getIsButtonDisabled(matchButtonConfig, numSelected)}
      onClick={handleMatch}
      key="tableButton_match"
    />
  );
};
