import { Section } from "@nef/core";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

export const WarningSection = styled(Section)`
  ${props => `
  ${
    props.$isWarning
      ? ` 
        background: ${getColor("warning", 300)(props)};
        border-color: ${getColor("warning", 500)(props)};
        width: 100%;
      `
      : ""
  }
`}
`;
