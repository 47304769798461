import { FontAwesomeIcon, Header, Popover, PopoverBody, Progress } from "@nef/core";
import React, { useMemo, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// TODO: styled-components
export const CircleProgress = ({ id, filename, progress }) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const wrapStyle = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
    }),
    []
  );

  const progressStyle = useMemo(
    () => ({
      path: { stroke: "#02bf3f" },
      trail: { stroke: "rgba(0, 191, 63, .2)" },
      background: { fill: "rgba(0, 191, 63, .1)" },
    }),
    []
  );

  const textWrapStyle = useMemo(
    () => ({
      width: "100%",
      display: "grid",
      alignItems: "center",
      gridGap: ".5rem",
      gridTemplateColumns: "70% auto",
      overflow: "hidden",
    }),
    []
  );

  const fileTextStyle = useMemo(
    () => ({
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    []
  );

  const progTextStyle = useMemo(
    () => ({ fontSize: "18px", color: "#02bf3f", width: "50px", textAlign: "right" }),
    []
  );

  return (
    <div
      key={id}
      style={wrapStyle}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <div id={`progress${id}`} style={{ width: "25px" }}>
        <CircularProgressbar
          value={progress}
          strokeWidth={"18"}
          background={true}
          styles={progressStyle}
        />
      </div>
      <Popover
        body=""
        placement="bottom-start"
        isOpen={isMouseOver}
        target={`progress${id}`}
        width={250}
      >
        <PopoverBody>
          <Header size={3}>Upload Processing</Header>
          <div style={textWrapStyle}>
            <span style={fileTextStyle}>
              <FontAwesomeIcon iconSetClassName="far" iconClassName="fa-file" />
              {filename}
            </span>
            <span style={progTextStyle}>{`${Math.floor(progress)}%`}</span>
          </div>
          <Progress value={progress} color="success" />
        </PopoverBody>
      </Popover>
    </div>
  );
};
