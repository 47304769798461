import faker from "faker";

const fakeSummaryRow = mpid => {
  const totalBuy = faker.random.number(30000000);
  const totalSell = faker.random.number(30000000);
  const totalNet = faker.random.number(300000000000);
  const maxTradeLimit = faker.random.number(2000000);
  const buyAlertLimit = 5000000 + faker.random.number(5000000);
  const buyHoldLimit = 10000000 + faker.random.number(10000000);
  const buyRejectLimit = 20000000 + faker.random.number(10000000);
  const sellAlertLimit = 5000000 + faker.random.number(5000000);
  const sellHoldLimit = 10000000 + faker.random.number(10000000);
  const sellRejectLimit = 20000000 + faker.random.number(10000000);
  const netAlertLimit = 50000000000 + faker.random.number(50000000000);
  const netHoldLimit = 100000000000 + faker.random.number(100000000000);
  const netRejectLimit = 200000000000 + faker.random.number(100000000000);
  const useNetTrading = faker.random.boolean();

  return {
    mpid: { label: mpid },
    totalBuy: totalBuy,
    executing: Math.floor(totalBuy / 3),
    totalSell,
    totalNet,
    maxTradeLimit,
    fifteenMinDefault: "Allow",
    buyAlertLimit,
    buyHoldLimit,
    buyRejectLimit,
    sellAlertLimit,
    sellHoldLimit,
    sellRejectLimit,
    netAlertLimit,
    netHoldLimit,
    netRejectLimit,
    useNetTrading,
  };
};

export const getSummaryData = mpids => {
  return mpids.map(mpid => fakeSummaryRow(mpid));
};
