import { FieldNames } from "components/fields/fieldConstants";
import { produce } from "immer";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import { getQueryStringParams, safeParseJSON } from "utils/js.utils";
import { RequestResult } from "wksConstants";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { network } from "./network";
import { useSettingsContext } from "components/settings";
import useWindowFocus from "use-window-focus";
import { Status } from "../../wksConstants";
import { NotificationHub } from "@nef/core/lib";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

export const statsDispatch = createContext();
export const useStatsDispatch = () => {
  return useContext(statsDispatch);
};

export const statsContext = createContext();
export const useStatsContext = () => {
  return useContext(statsContext);
};

export const statsDefaultState = {
  feedStatus: Status.NO_STATUS,
  hasData: false,
  isPolling: false,
  makeRequest: false,
  isPollingMain: false,
  isPollingRight: false,
  isRequesting: false,
  requestTimeout: null,
  shouldAbort: false,
  interval: null,
  legends: true,
  [FieldNames.mpid]: null,
  [FieldNames.trf]: null,
  currentTitle: null,
  lateTradeLabels: ["5 min", "10 min", "15 min", "> 20 min"],
  lateTrades: [],
  lateTradesTable: [],
  disseminationLabels: ["Clearing Only", "Tape Only", "Clearing Tape", "Regulatory"],
  dissemination: [],
  disseminationTable: [],
  tradeAccuracyLabels: ["Breaks", "Rejects", "Mods", "Cancels", "Reversals"],
  tradeAccuracy: [],
  tradeAccuracyTable: [],
  statusLabels: ["Matched", "Unmatched", "Open", "Accepted", "Cancelled", "Declined"],
  statuses: [],
  narrowBarTop: {
    "Total<br />Trades": { value: "--" },
    "Locked<br />In": { value: "--" },
    "As Of": { value: "--" },
    "Non Compare<br />Accept Trade": { value: "--" },
    "Match<br />Compare": { value: "--" },
    "TRF<br />Carteret": { value: "--" },
    "TRF<br />Chicago": { value: "--" },
    "Late Trades<br />10 Sec Rule": { value: "--", statType: "ExecLate" },
  },
  narrowBarMiddle_meta: {
    eAgent: { text: "Agent" },
    ePrincipal: { text: "Principal" },
    eRiskless: { text: "Riskless", hasBorder: true },
    cAgent: { text: "Agent" },
    cPrincipal: { text: "Principal" },
    cRiskless: { text: "Riskless", hasBorder: true },
    long: { text: "Long" },
    short: { text: "Short", hasBorder: true },
    in: { text: "In" },
    out: { text: "Out" },
  },
  narrowBarMiddle_values: {
    eAgent: "--",
    ePrincipal: "--",
    eRiskless: "--",
    cAgent: "--",
    cPrincipal: "--",
    cRiskless: "--",
    long: "--",
    short: "--",
    in: "--",
    out: "--",
  },
  requestPayload: {
    trf: [],
    mpids: [],
    includeExecutingMMID: true,
    includeExecutingGiveUpMPID: false,
    includeContraMPID: true,
    includeContraGiveUpMPID: false,
  },
  poll: getQueryStringParams(window.location.search)?.poll !== "0",
  maxNumStatsMpid: 10,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const shouldBePolling = state => {
  const { isPollingMain, isPollingRight, requestPayload } = state;
  return (
    (isPollingMain || isPollingRight) &&
    requestPayload.trf.length > 0 &&
    requestPayload.mpids.length > 0
  );
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_FEED_STATUS": {
      return produce(state, draft => {
        draft.feedStatus = action.payload;
      });
    }
    case "SET_IS_POLLING_MAIN": {
      return produce(state, draft => {
        const isPollingMain = action.payload;
        const isPollingRight = state.isPollingRight;
        draft.isPollingMain = action.payload;
        draft.makeRequest = action.payload && !isPollingRight;
        draft.isPolling = shouldBePolling(
          Object.assign({}, state, {
            isPollingMain: isPollingMain,
          })
        );
      });
    }
    case "SET_IS_POLLING_RIGHT": {
      return produce(state, draft => {
        const isPollingRight = action.payload;
        const isPollingMain = state.isPollingMain;
        draft.isPollingRight = isPollingRight;
        draft.makeRequest = action.payload && !isPollingMain;
        draft.isPolling = shouldBePolling(
          Object.assign({}, state, {
            isPollingRight: isPollingRight,
          })
        );
      });
    }
    case "SET_MAKE_REQUEST": {
      return { ...state, makeRequest: action.payload };
    }
    case "SET_REQUEST_TIMEOUT": {
      return { ...state, requestTimeout: action.payload };
    }
    case "SET_SHOULD_ABORT": {
      return { ...state, shouldAbort: action.payload };
    }
    case "LEGENDS": {
      return produce(state, draft => {
        draft.legends = action.payload;
      });
    }
    case "ADD_DATA": {
      const d = action.payload;
      const lateTradesValues = [d.lateBy5, d.lateBy10, d.lateBy15, d.lateByMoreThan20];
      const lateTrades = state.lateTradeLabels.map((label, i) => {
        return { label, value: lateTradesValues[i], displayValue: lateTradesValues[i] };
      });

      const disseminationValues = [
        d.clearingOnly || 0,
        d.tapeOnly || 0,
        d.clearingAndTape || 0,
        d.regulatoryOnly || 0,
      ];
      const dissemination = state.disseminationLabels.map((label, i) => {
        return { label, value: disseminationValues[i], displayValue: disseminationValues[i] };
      });

      const tradeAccuracyValues = [
        d.breaks || 0,
        d.rejects || 0,
        d.modifications || 0,
        d.cancelled || 0,
        d.reversals || 0,
      ];
      const tradeAccuracy = state.tradeAccuracyLabels.map((label, i) => {
        return { label, value: tradeAccuracyValues[i], displayValue: tradeAccuracyValues[i] };
      });

      const statusValues = [];
      state.statusLabels.forEach((k, i) => {
        statusValues.push(d[k.toLowerCase()] || 0);
      });

      const statuses = state.statusLabels.map((label, i) => {
        return { label, value: statusValues[i], displayValue: statusValues[i] };
      });

      return produce(state, draft => {
        draft.narrowBarTop = {
          ...state.narrowBarTop,
          "Total<br />Trades": { value: d.totalTradesToDate || 0 },
          "Locked<br />In": { value: d.lockedIn || 0 },
          "As Of": { value: d.asOf || 0 },
          "Non Compare<br />Accept Trade": { value: d.nonCompareAccept || 0 },
          "Match<br />Compare": { value: d.matchCompare || 0 },
          "TRF<br />Chicago": { value: d.trf2TradeTotals || 0 },
          "TRF<br />Carteret": { value: d.trf1TradeTotals || 0 },
          "Late Trades<br />10 Sec Rule": { value: d.execLateTrade || 0, statType: "ExecLate" },
        };
        draft.narrowBarMiddle_values = {
          eAgent: d.executingCapacityAgent || 0,
          ePrincipal: d.executingCapacityPrincipal || 0,
          eRiskless: d.executingCapacityRiskless || 0,
          cAgent: d.contraCapacityAgent || 0,
          cPrincipal: d.contraCapacityPrincipal || 0,
          cRiskless: d.contraCapacityRiskless || 0,
          long: d.longSells || 0,
          short: d.shortSells || 0,
          in: d.stepIns || 0,
          out: d.stepOuts || 0,
        };
        draft.lateTrades = lateTrades;
        draft.dissemination = dissemination;
        draft.statuses = statuses;
        draft.tradeAccuracy = tradeAccuracy;
        draft.hasData = true;
      });
    }
    case "ADD_OPTION": {
      const { value, field } = action.payload;
      const newState = produce(state, draft => {
        switch (field) {
          case "mpidvals":
            draft.requestPayload.mpids = [...state.requestPayload.mpids, value];
            break;
          default:
            break;
        }
      });
      return newState;
    }

    case "FORM_CHANGE": {
      const { value, field } = action.payload;
      const newState = produce(state, draft => {
        draft[field] = (value || []).map(v => (v?.value ? v.value : undefined));
        draft.requestPayload[field === "mpidvals" ? "mpids" : "trf"] = (value || []).map(v =>
          v?.value ? v.value : undefined
        );

        draft.isPolling = shouldBePolling({ ...state, ...draft });
        if (!draft.mpidvals?.length || !draft.trfVals?.length) {
          //reset
          Object.entries(draft.narrowBarTop).forEach(
            ([key, value]) =>
              (draft.narrowBarTop[key] = {
                value: "--",
                statType: state.narrowBarTop[key].statType,
              })
          );
          Object.entries(draft.narrowBarMiddle_values).forEach(
            ([key, value]) => (draft.narrowBarMiddle_values[key] = "--")
          );

          draft.dissemination = [];
          draft.statuses = [];
          draft.tradeAccuracy = [];
          draft.lateTrades = [];
          draft.hasData = false;
        }
      });

      return newState;
    }
    case "CHANGE_TITLE": {
      const { name, chart } = action.payload;
      return produce(state, draft => {
        draft.currentTitle = `${chart} - ${name}`;
      });
    }
    case "SET_TABLE_TITLE": {
      return produce(state, draft => {
        draft.currentTitle = action.payload;
      });
    }
    case "DUMP_SETTINGS": {
      const { userData } = action.payload;
      const realSetting = userData.find(s => s.name === "statsSelection");

      const newState = produce(state, draft => {
        if (!realSetting) {
          return;
        }

        const settingData = safeParseJSON(realSetting.data);
        draft[FieldNames.mpid] = settingData[FieldNames.mpid] || [];
        draft[FieldNames.trf] = settingData[FieldNames.trf] || [];
        draft.requestPayload.mpids = (settingData[FieldNames.mpid] || []).map(s => s.value);
        draft.requestPayload.trf = (settingData[FieldNames.trf] || []).map(s => s.value);
        draft.isPolling = shouldBePolling({ ...state, ...draft });
      });

      return newState;
    }
    case "SET_MAX_NUM_STATS_MPID": {
      const { maxNumStatsMpid } = action.payload;
      return {
        ...state,
        maxNumStatsMpid,
      };
    }
    case "SET_CLICKED_CHART_SLICE": {
      const { name, chart } = action.payload;
      const a = { ...state, requestPayload: { ...state.requestPayload, name, chart } };
      return a;
    }
    default:
      return { ...state };
  }
};

const StatsProvider = ({ children, defaultData = {} }) => {
  const [user] = useUserContext();
  const [settings] = useSettingsContext();
  const [abort, setAbort] = useState(null);
  const isWindowFocused = useWindowFocus();

  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, statsDefaultState, defaultData)
  );

  useEffect(() => {
    const actions = [];
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.userData]
    ) {
      actions.push({
        type: "DUMP_SETTINGS",
        payload: { userData: user[INITIAL_DATA_MODEL.userData] },
      });
    }
    if (
      user[INITIAL_DATA_MODEL.configDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.config]
    ) {
      actions.push({
        type: "SET_MAX_NUM_STATS_MPID",
        payload: { maxNumStatsMpid: user[INITIAL_DATA_MODEL.config]?.maxNumStatsMpid },
      });
    }
    dispatchF(actions);
  }, [user]);

  const successCallback = useCallback(json => {
    dispatchF([
      { type: "SET_FEED_STATUS", payload: Status.SUCCESS },
      {
        type: "ADD_DATA",
        payload: json,
      },
      {
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 2000),
      },
    ]);
  }, []);

  const errorCallback = useCallback(() => {
    if (state.feedStatus !== Status.ERROR) {
      NotificationHub.send("danger", "Stats stream has been interuppted", {
        ...(state.hasData && { subtitle: "You are now viewing stale Stats data" }),
      });
    }
    dispatchF([
      {
        type: "SET_FEED_STATUS",
        payload: Status.ERROR,
      },
      { type: "SET_DATA", payload: [[], [], [], []] },
      {
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 2000),
      },
    ]);
  }, [state.feedStatus, state.hasData]);

  const getStats = useCallback(() => {
    const abortController = new AbortController();
    setAbort(abortController);
    network(user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS]).stats(
      {
        mpids: state.requestPayload.mpids,
        trf: state.requestPayload.trf,
        includeContraGiveUpMPID: settings.incContraGUP,
        includeContraMPID: settings.incContraMPID,
        includeExecutingGiveUpMPID: settings.incExecGUP,
        includeExecutingMMID: settings.incExecMPID,
      },
      successCallback,
      errorCallback,
      abortController.signal
    );
  }, [
    user.mpidAttributes,
    state.requestPayload.mpids,
    state.requestPayload.trf,
    settings.incContraGUP,
    settings.incContraMPID,
    settings.incExecGUP,
    settings.incExecMPID,
    successCallback,
    errorCallback,
  ]);

  useEffect(() => {
    if (isWindowFocused && state.isPolling && state.makeRequest && !state.shouldAbort) {
      getStats();
      dispatchF({ type: "SET_MAKE_REQUEST", payload: false });
    }
  }, [state.isPolling, state.makeRequest, getStats, state.shouldAbort, isWindowFocused]);

  useEffect(() => {
    if (state.shouldAbort) {
      if (abort) {
        abort.abort();
      }
      dispatchF([
        { type: "SET_SHOULD_ABORT", payload: false },
        { type: "SET_MAKE_REQUEST", payload: true },
      ]);
    }
  }, [state.activeKilledId, state.activeHeldId, abort, state.shouldAbort]);

  useEffect(() => {
    return () => clearTimeout(state.requestTimeout);
  }, [state.requestTimeout]);

  return (
    <statsDispatch.Provider value={dispatchF}>
      <statsContext.Provider value={[state, dispatchF]}>{children}</statsContext.Provider>
    </statsDispatch.Provider>
  );
};

export default StatsProvider;
