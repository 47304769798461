import { Indicator } from "@nef/core";
import { CenteredIndicator } from "components/styled";
import React, { memo } from "react";

export const StatusIndicator = memo(({ color, children, id, isLoading, tooltipColor }) => {
  return (
    <CenteredIndicator
      tooltipColor={tooltipColor}
      $isLoading={isLoading}
      color={color}
      size="md"
      id={id}
    >
      {children}
    </CenteredIndicator>
  );
});
