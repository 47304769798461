import { Forms } from "components/fields/fieldConstants";
import { StandardTables } from "wksConstants";
import { RiskAlertSettingsTable } from "../riskAlertSettingsTable";
import { AguRiskAlertEditForm } from "./riskAlertEditForm";
import { AguRiskAlertForm } from "./riskAlertForm";

export const AguRiskAlertSettingsTable = () => {
  return (
    <RiskAlertSettingsTable
      isAgu={true}
      table={StandardTables.PTRA_AGU_CONFIG}
      createTable={StandardTables.PTRA_AGU_CREATE_VALIDATION}
      editTable={StandardTables.PTRA_AGU_EDIT_VALIDATION}
      newForm={Forms.PTRA_AGU_CONFIG}
      editForm={Forms.PTRA_AGU_EDIT}
      NewForm={AguRiskAlertForm}
      EditForm={AguRiskAlertEditForm}
    />
  );
};
