import { Button, CSSGrid, Header, Popover, PopoverBody, PopoverHeader } from "@nef/core";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useFormContext } from "./formContext";

const dispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_PROPERTIES": {
      return Object.assign({ ...state }, action.payload);
    }
    default: {
      return { ...state };
    }
  }
};

const defaultState = {
  isAbortAvailable: false,
  timer: null,
  animation: null,
  isSubmitAvailable: true,
  confirmMessages: [],
  confirmJSX: null,
};

const headerFontSize = { fontSize: "1rem" };
export const SubmitConfirmation = ({ form, buttonId, isOpen, onCancel, onSubmit }) => {
  const [{ confirmMessages, confirmJSX }, dispatch] = useReducer(dispatchFn, defaultState);
  const [formData] = useFormContext();

  const confirmMemo = useMemo(() => {
    if (confirmMessages !== formData[form.key].confirmMessages) {
      return formData[form.key].confirmMessages;
    } else {
      return confirmMessages;
    }
  }, [formData, form, confirmMessages]);

  useEffect(() => {
    if (confirmMessages !== confirmMemo) {
      const properties = {
        confirmMessages: confirmMemo,
        confirmJSX: Object.entries(confirmMemo).map(([key, value]) => <li key={key}>{value}</li>),
      };
      dispatch({
        type: "SET_PROPERTIES",
        payload: properties,
      });
    }
  }, [confirmMemo, confirmMessages]);

  const handleCancel = useCallback(() => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    if (typeof onSubmit === "function") {
      onSubmit();
    }
  }, [onSubmit]);

  return (
    <Popover isOpen={isOpen} target={buttonId} placement={"top"}>
      <PopoverHeader style={headerFontSize}>Confirm Submission</PopoverHeader>
      <PopoverBody>
        <CSSGrid cols="auto auto" gap="1rem">
          <ul style={{ gridColumn: "1 / 3" }}>{confirmJSX}</ul>
          <Button onClick={handleCancel} outline>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Continue</Button>
        </CSSGrid>
      </PopoverBody>
    </Popover>
  );
};
