import React, { useCallback, useEffect, useMemo } from "react";
import { SegmentedControls, Box } from "@nef/core";
import { useViewModelContext } from "components/context";
import { Forms } from "components/fields/fieldConstants";
import { useHistory } from "react-router";
import { useSettingsContext } from "components/settings";
import { useFormDispatch } from "components/form";
import { useStandardTableDispatch } from "../standardTable";
import { useUserContext } from "../user";
import { useFormLayoutContext } from "../form/formLayoutContext";
import { ViewTable } from "viewConstants";
import { ViewForm, ViewForms, Views } from "../../viewConstants";
import styled from "styled-components";
// TODO: does this need to be all chopped up to get the benefits of memo?

const StyledBox = styled(Box)`
  width: 100%;

  & > div > button {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const StyledSegmentedControls = styled(SegmentedControls)`
  width: 100%;
`;

const ReportOrScan = ({ toggles }) => {
  const [viewData, viewDispatch] = useViewModelContext();
  const [settings] = useSettingsContext();
  const formDispatch = useFormDispatch();
  const [, formLayoutDispatch] = useFormLayoutContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [user] = useUserContext();
  const history = useHistory();

  const onUnmoumt = useCallback(() => {
    if (
      !settings.stickyFields_switch &&
      [Views.REPORT, Views.SCAN, Views.REJECTS].includes(viewData.view)
    ) {
      formDispatch({
        type: "RESET_FORM",
        payload: {
          form: ViewForms[viewData.view][ViewForm.DEFAULT],
          entitlements: user.entitlements,
        },
      });

      formLayoutDispatch({
        type: "SET_ACTIVE_FORM_LAYOUT",
        payload: { form: ViewForms[viewData.view][ViewForm.DEFAULT], key: null },
      });
    }
  }, [
    settings.stickyFields_switch,
    viewData.view,
    user.entitlements,
    formDispatch,
    formLayoutDispatch,
  ]);

  useEffect(() => {
    return onUnmoumt;
  }, [onUnmoumt]);

  const table = useMemo(() => {
    return ViewTable[viewData.view];
  }, [viewData.view]);

  const handleChange = useCallback(
    val => {
      const tableDispatchs = [
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: table },
        },
      ];

      if (val === Forms.TR_REPORT) {
        tableDispatchs.push({
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { ask: true, table: val },
        });
      }
      viewDispatch({
        type: "CHANGE_VIEW",
        payload: { history, view: val },
      });

      standardTableDispatch(tableDispatchs);
    },
    [viewDispatch, history, standardTableDispatch, table]
  );

  const defaultValue = useMemo(() => {
    return viewData.view;
  }, [viewData.view]);

  return (
    <StyledBox marginBottom={3} marginTop={3}>
      <StyledSegmentedControls
        defaultValue={defaultValue}
        type="radio"
        onClick={handleChange}
        size="sm"
      >
        {(toggles || []).map(t => ({
          ...t,
          key: `modeToggle_${t.props.value}`,
        }))}
      </StyledSegmentedControls>
    </StyledBox>
  );
};

export default ReportOrScan;
