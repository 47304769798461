export enum RequestStatus {
  NO_STATUS = "primary",
  ERROR = "danger",
  SUCCESS = "success",
}

export enum EqrcRules {
  fatFinger = "fatFinger/",
  restrictedStockList = "restrictedStockList/",
  grossExposure = "grossExposure/",
  marketImpactCheck = "marketImpactCheck/",
  orderType = "orderType/",
  advCheck = "advCheck/",
  orderRateThresholds = "orderRateThresholds/",
  shortSale = "shortSale/",
  alertConfig = "alert/configuration/",
  maxSharesPerOrder = "maxSharesPerOrder/",
  maxNotionalOrder = "maxNotionalOrder/",
  sharesLocatedBroker = "sharesLocatedBroker/",
}

export enum EqrcServerActionToRule {
  "FAT_FINGER" = "Fat Finger",
  "RESTRICTED_STOCK" = "Restricted Stock",
  "GROSS_EXPOSURE" = "Gross Exposure",
  "MARKET_IMPACT_CHECK" = "Market Impact Check",
  "ORDER_TYPE" = "Order Type",
  "AVERAGE_DAILY_VOLUME" = "Average Daily Volume",
  "ORDER_RATE_THRESHOLDS" = "Order Rate Threshold",
  "SHORT_SALE" = "Short Sale",
  "MAX_NOTIONAL_ORDER" = "Max Notional Order",
  "MAX_SHARES_PER_ORDER" = "Max Shares Per Order",
  "SHARES_LOCATED" = "Shares Located",
}

export const EqrcRulesLabelMap: { [key in EqrcRules]: string } = {
  [EqrcRules.fatFinger]: "Fat Finger",
  [EqrcRules.restrictedStockList]: "Restricted Stock List",
  [EqrcRules.grossExposure]: "Gross Exposure",
  [EqrcRules.marketImpactCheck]: "Market Impact Check",
  [EqrcRules.orderType]: "Order Type",
  [EqrcRules.advCheck]: "ADV Check",
  [EqrcRules.orderRateThresholds]: "Order Rate Thresholds",
  [EqrcRules.shortSale]: "Short Sale",
  [EqrcRules.alertConfig]: "Alert Configuration",
  [EqrcRules.maxNotionalOrder]: "Max Notional Order",
  [EqrcRules.maxSharesPerOrder]: "Max Shares Per Order",
  [EqrcRules.sharesLocatedBroker]: "Shares Located Check",
};

export type EqrcExchange = "NASDAQ" | "PSX" | "BX" | "ROUTED";

export type EqrcBreachAction = "REJECT" | "CANCEL_NON_CROSS" | "CANCEL_ALL";

export type EqrcRuleType =
  | "FAT_FINGER_LIMIT"
  | "DUPLICATIVE_MSG_RATE"
  | "ORDER_TYPE"
  | "PORT_MSG_RATE"
  | "RESTRICTED_STOCK"
  | "SHORT_SALE"
  | "SYMBOL_MSG_RATE"
  | "SHARES_LOCATED"
  | "MARKET_IMPACT_CHECK"
  | "MAX_NOTIONAL_ORDER"
  | "MAX_SHARES_PER_ORDER"
  | "MIN_CADV";

export enum All_History_Alert_Types {
  GROSS_OPEN_EXPOSURE = "Gross Open Exposure",
  SYMBOL_MSG_RATE = "Symbol Message Rate",
  SHARES_LOCATED = "Shares Located",
  SHORT_SALE = "Short Sale",
  ORDER_TYPE = "Order Type",
  GROSS_EXECUTED_EXPOSURE = "Gross Executed Exposure",
  MIN_CADV = "Average Daily Volume",
  MAX_SHARES_ORDER = "Max Shares Per Order",
  DUPLICATIVE_MSG_RATE = "Duplicate Message Rate",
  FAT_FINGER_LIMIT = "Fat Finger",
  RESTRICTED_STOCK = "Restricted Stock",
  PORT_MSG_RATE = "Port Message Rate",
  MARKET_IMPACT_CHECK = "Market Impact Check",
  MAX_NOTIONAL_ORDER = "Max Notional Order",
  GROSS_NOTIONAL_EXPOSURE = "Gross Notional Exposure",
  MARKET_ORDER_NOT_ALLOWED = "Market Order Not Allowed",
  EQRC_ISO_NOT_ALLOWED = "EQRC ISO Not Allowed",
  SHORT_SELL_EXEMPT_NOT_ALLOWED = "Short Sell Exempt Not Allowed",
  SHORT_SELL_NOT_ALLOWED = "Short Sell Not Allowed",
  PRE_MARKET_NOT_ALLOWED = "Pre Market Not Allowed",
  POST_MARKET_NOT_ALLOWED = "Post Market Not Allowed",
  REG_SHO_STATE_NOT_AVAILABLE = "Reg SHO State Not Available",
  EQRC_MAX_QUANTITY_EXCEEDED = "EQRC Max Quantity Exceeded",
  SINGLE_ORDER_NOTIONAL_EXCEEDED = "Single Order Notional Exceeded",
}

export enum Limited_History_Alert_Types {
  GROSS_OPEN_EXPOSURE = "Gross Open Exposure",
  SYMBOL_MSG_RATE = "Symbol Message Rate",
  SHARES_LOCATED = "Shares Located",
  SHORT_SALE = "Short Sale",
  ORDER_TYPE = "Order Type",
  GROSS_EXECUTED_EXPOSURE = "Gross Executed Exposure",
  MIN_CADV = "Average Daily Volume",
  MAX_SHARES_ORDER = "Max Shares Per Order",
  DUPLICATIVE_MSG_RATE = "Duplicate Message Rate",
  FAT_FINGER_LIMIT = "Fat Finger",
  RESTRICTED_STOCK = "Restricted Stock",
  PORT_MSG_RATE = "Port Message Rate",
  MARKET_IMPACT_CHECK = "Market Impact Check",
  MAX_NOTIONAL_ORDER = "Max Notional Order",
  GROSS_NOTIONAL_EXPOSURE = "Gross Notional Exposure",
}
