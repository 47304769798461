import React from "react";
import { UploadMiddle } from ".";
import { LeftView } from "components/reportScan";
import "./index.css";
import { Views } from "viewConstants";
import { Forms } from "components/fields/fieldConstants";

const Upload = () => {
  return (
    <>
      <LeftView view={Views.UPLOAD} form={Forms.UPLOAD_REPAIR} />
      <UploadMiddle />
    </>
  );
};

export default Upload;
