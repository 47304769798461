import React, { useCallback, useState } from "react";
import { Button, Flex, NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { FieldLoop, userPreferenceFields } from "components/fields";
import { SettingsWrapper } from "../settingsWrapper";
import { useFormContext } from "components/form";
import { useUserContext } from "components/user";
import { network } from "components/settings";
import { FooterFlex } from "../styled";
import { useUserPreferenceDispatch } from "components/user/preferenceContext";

export const UserPreferences = () => {
  const [formData] = useFormContext();
  const [user] = useUserContext();
  const userPrefDispatch = useUserPreferenceDispatch();
  const [isLoading, setLoading] = useState(false);

  const onSaveSuccess = useCallback(() => {
    setLoading(false);
    NotificationHub.send("success", "User preference update persisted");
  }, []);

  const onSaveError = useCallback(() => {
    setLoading(false);
    NotificationHub.send("danger", "Failed to persist user preference", {
      subtitle: "Please try again",
    });
  }, []);

  const handleSave = useCallback(() => {
    setLoading(true);
    const settingsData: { [key: string]: { value: string } } =
      formData[Forms.SETTINGS_USER_PREFERENCE.key].fields;
    network().writeSettings(
      {
        data: JSON.stringify(settingsData),
        name: Forms.SETTINGS_USER_PREFERENCE.key,
        type: Forms.SETTINGS_USER_PREFERENCE.key,
        user: user.username,
      },
      onSaveSuccess,
      onSaveError
    );
    const actions: any[] = [];
    Object.entries(settingsData).forEach(([key, field]) => {
      document.cookie = `${key}=${field.value};expires=2147483647`;
      actions.push({
        type: "SET_PREFERENCE",
        payload: { name: key, value: field.value },
      });
    });
    userPrefDispatch(actions);
  }, [formData, user.username, onSaveSuccess, onSaveError, userPrefDispatch]);

  return (
    <>
      <SettingsWrapper
        header="User Preferences"
        headerMenu={undefined}
        subheader={undefined}
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        RightSide={undefined}
        tabs={undefined}
      >
        <Flex flexDirection="column">
          <FieldLoop
            form={Forms.SETTINGS_USER_PREFERENCE}
            fields={userPreferenceFields}
            classNames={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isReactFragment={true}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
            augmentOnChange={undefined}
          />
        </Flex>
      </SettingsWrapper>
      <FooterFlex justifyContent="end" flexDirection="row">
        <Button disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
      </FooterFlex>
    </>
  );
};
