import { AlertForm, Divider } from "../constants";
import { PtrBreachAlerts } from "../form/breachAlerts";
import { PtrWorkXDeliveryOptions } from "../form/workxDeliveryOptions";
import { PtrClearCorrespondentMPIDOptions } from "./mpidOptionsClearCorr";
import { PtrSettingAlerts } from "../form/riskLimitSettingAlerts";
import { Forms } from "components/fields";
import { PtrEmailDeliveryOptions } from "../form/emailDeliveryOptions";

export const ClearCorrespondentRiskAlertForm = () => {
  return (
    <AlertForm>
      <PtrClearCorrespondentMPIDOptions form={Forms.PTRA_CONFIG} />
      <Divider />
      <PtrBreachAlerts form={Forms.PTRA_CONFIG} />
      <Divider />
      <PtrSettingAlerts form={Forms.PTRA_CONFIG} />
      <Divider />
      <PtrEmailDeliveryOptions form={Forms.PTRA_CONFIG} />
      <Divider />
      <PtrWorkXDeliveryOptions form={Forms.PTRA_CONFIG} />
    </AlertForm>
  );
};
