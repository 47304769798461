import { FieldNames } from "components/fields";
import _ from "lodash";

/**
 *
 * @param headers - [ <String> ]
 * @param data - [ { header1: value, header2: value, ... }, ... ]
 * @param accessors - { header1: () => <string> }
 */
export function convertToCSV(headers, data, accessors) {
  // can probably add headers as we are adding the data
  // for better performance
  let csv = headers.map(header => header).join(",") + "\n";

  data.forEach(entry => {
    csv +=
      headers
        .map((column, i) => {
          let value = "";
          let accessor = accessors[column];
          if (typeof accessor === "function") {
            value = accessor(entry);
          } else {
            value = _.get(entry, accessor);
          }
          if (typeof value === "string" && value.indexOf(",") !== -1) {
            value = `"${value}"`;
          }
          return value;
        })
        .join(",") + "\n";
  });

  return csv;
}

export async function createAndDownloadTemplate(filename, text) {
  const csvElement = await new Promise(resolve => {
    const blob = new Blob([text], { type: "text/csv" });
    const objectUrl = URL.createObjectURL(blob);
    const ele = document.createElement("a");
    ele.href = objectUrl;
    ele.target = "_blank";
    ele.download = filename;
    resolve(ele);
  });
  return Promise.resolve(csvElement.click());
}
