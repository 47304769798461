import { Forms } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import { SettingsWrapper } from "components/settings";
import { formValidationCheck, useStandardTableDispatch } from "components/standardTable";
import { useCallback, useEffect } from "react";
import { Button, NotificationHub } from "@nef/core";
import { FieldNames, ptrAuditFields1, ptrAuditFields2 } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { getConvertedRiskLimitsForUI } from "components/limitMonitor/constants";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import styled from "styled-components";
import { SelectOption } from "types";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { PtrAuditTable } from "./limitSettingAuditTable";
import { PTR_AUDIT_ACTION } from "./constant";
import { useUserContext } from "components/user";
import { FROM_DATE_SUFFIX, TO_DATE_SUFFIX, formatDateForRequest } from "utils/dateUtils";

const StyledButton = styled(Button)`
  margin-top: 21px !important;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  align-items: start;
  height: 75px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: span 2;
  margin-right: 1rem;
  grid-gap: 1rem;
`;

interface PtrAuditRequestDTO {
  action: PTR_AUDIT_ACTION[];
  clearerMPID: string;
  correspondentMPID: string[];
  fromDate?: string;
  toDate?: string;
}

interface AuditTrailDTO {
  action: PTR_AUDIT_ACTION;
  userId: string;
  message: any;
  timestamp: string;
}

export const LimitSettingAudit = () => {
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  const successCb = useCallback(
    (json: AuditTrailDTO[]) => {
      if (Array.isArray(json)) {
        const data = json.reduce((acc, curr) => {
          const item = { ...curr };
          item.message = getConvertedRiskLimitsForUI(item.message);
          acc.push(item);
          return acc;
        }, [] as AuditTrailDTO[]);
        tableDispatch([
          {
            type: "SET_TABLE_DATA",
            payload: { table: StandardTables.PTR_AUDIT_TRAIL, data },
          },
          {
            type: "SET_IS_LOADING",
            payload: { table: StandardTables.PTR_AUDIT_TRAIL, isLoading: false },
          },
          {
            type: "SET_LAST_QUERY_DATE",
            payload: { table: StandardTables.PTR_AUDIT_TRAIL },
          },
        ]);
        NotificationHub.send("success", `Retrieved ${data.length} audit trail result(s)`);
      } else {
        NotificationHub.send("danger", "Error parsing audit trail results");
      }
    },
    [tableDispatch]
  );

  const errorCb = useCallback(
    (err: any) => {
      NotificationHub.send(
        "danger",
        err?.message ? err.message : "Error retrieving audit trail results"
      );
      tableDispatch(
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.PTR_AUDIT_TRAIL, data: [] },
        },
        {
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.PTR_AUDIT_TRAIL, isLoading: false },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: { table: StandardTables.PTR_AUDIT_TRAIL },
        }
      );
    },
    [tableDispatch]
  );

  const handleAudit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: Forms.PTR_AUDIT_TRAIL,
      userData,
    });

    if (!valid) {
      return;
    }

    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.PTR_AUDIT_TRAIL, isLoading: true },
    });
    const formValues = formData[Forms.PTR_AUDIT_TRAIL.key].fields;
    const body: PtrAuditRequestDTO = {
      action: formValues[FieldNames.action]?.value
        ? [formValues[FieldNames.action].value]
        : [PTR_AUDIT_ACTION.PTR_NEXT_DAY_RISK_CONFIG, PTR_AUDIT_ACTION.PTR_INTRADAY_RISK_CONFIG],
      clearerMPID: formValues[FieldNames.clearingFirmMPID]?.value,
      correspondentMPID: formValues[FieldNames.correspondentMPID]?.map(
        (mpid: SelectOption) => mpid.value
      ),
      fromDate: formatDateForRequest(formValues[FieldNames.dateRange]?.[0], FROM_DATE_SUFFIX),
      toDate: formatDateForRequest(formValues[FieldNames.dateRange]?.[1], TO_DATE_SUFFIX),
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_QUERY_WS, "/ptrConfig"),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      },
      successCb,
      errorCb
    );
  }, [errorCb, formData, formDispatch, successCb, tableDispatch, userData]);

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.PTR_AUDIT_TRAIL },
    });
  }, [formDispatch]);

  return (
    <>
      <SettingsWrapper
        header="PTR Limit Setting Audit Trail"
        subheader={
          <Grid>
            <FieldLoop
              isReactFragment={true}
              form={Forms.PTR_AUDIT_TRAIL}
              fields={ptrAuditFields1}
              classNames={undefined}
              augmentOnChange={undefined}
              augmentOnCreate={undefined}
              portalRef={undefined}
              isDisabled={undefined}
              containerRef={undefined}
              showLabel={undefined}
            />
            <FieldWrapper>
              <FieldLoop
                isReactFragment={true}
                form={Forms.PTR_AUDIT_TRAIL}
                fields={ptrAuditFields2}
                classNames={undefined}
                augmentOnChange={undefined}
                augmentOnCreate={undefined}
                portalRef={undefined}
                isDisabled={undefined}
                containerRef={undefined}
                showLabel={undefined}
              />
            </FieldWrapper>
            <StyledButton onClick={handleAudit} size="sm">
              Scan
            </StyledButton>
          </Grid>
        }
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
        headerMenu={undefined}
        RightSide={undefined}
        tabs={undefined}
      >
        <PtrAuditTable />
      </SettingsWrapper>
    </>
  );
};
