import { DataArray, FormField } from "@nef/core";
import { toDataArray } from "../fakeData";
import { memo, useCallback, useMemo, useState } from "react";
import { FlipCard } from "components/flipCard";
import { ArraySection } from "../../member";
import { byMpid } from "../../finra/constant";
import { Front } from "./por.front";
import { Back } from "./por.back";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { MpidOrSymbol, Mpid_Or_Security, PorOrPvr, usePORCacheContext } from "../context";

export const DT = memo(
  ({
    data,
    isMpidOrSymbol,
  }: {
    data: { label: string; count: number; value: React.ReactNode }[];
    isMpidOrSymbol: Mpid_Or_Security;
  }) => {
    // { [mpidOrSymbol: string]: number } }) => {
    const [filterText, setFilterText] = useState<string | string[]>("");

    const wrappedGetFilterText = useCallback(() => {
      if (filterText === undefined) {
        return "";
      } else if (Array.isArray(filterText)) {
        return filterText[0];
      } else {
        return filterText;
      }
    }, [filterText]);

    const wrappedSetFilterText = useCallback(
      (event: CustomChangeEvent) => setFilterText(event?.value ? event.value.toString() : ""),
      [setFilterText]
    );
    // const data2 = useMemo(() => {
    //   return toDataArray(
    //     data || {},
    //     (key: string, count: number, type: "mpid" | "symbol") => () => {
    //       console.log(key, count);

    //       // tableDispatch({
    //       //   type: "SET_TABLE_DATA",
    //       //   payload: {
    //       //     table: StandardTables.PV_SUPERVISOR_MONITOR2,
    //       //     data: fillTable(key, count, type),
    //       //   },
    //       // });
    //       // setTitle(`POR for MPID '${key}'`);
    //     },
    //     "mpid"
    //   );
    // }, [data]);
    // console.log(data);
    // return <></>;
    const filteredDataArray = Object.values(data).filter(
      entry => entry.label.includes(wrappedGetFilterText())
      // true
    );
    // console.log(filteredDataArray);

    return (
      <>
        <FormField
          value={filterText?.toString()}
          onChange={wrappedSetFilterText}
          size={"sm"}
          placeholder={isMpidOrSymbol === MpidOrSymbol.MPID ? "MPID" : "Symbol"}
        />
        <DataArray data={filteredDataArray} expandable={true} />
      </>
    );
  }
);
