import React from "react";
import { ViewForm, ViewForms, ViewTable } from "viewConstants";
import {
  AcceptButton,
  BreakButton,
  CancelButton,
  CloseButton,
  CopyButton,
  DeclineButton,
  ModifyButton,
  RecapButton,
  RepairButton,
  ReverseButton,
} from "../../../standardTable/buttons";
import { Views } from "viewConstants";
import { MatchButton } from "components/standardTable/buttons/match";

export const ReportButtons = () => {
  return (
    <>
      <MatchButton table={ViewTable[Views.REPORT]} form={ViewForms[Views.REPORT][ViewForm.MATCH]} />
      <AcceptButton table={ViewTable[Views.REPORT]} />
      <DeclineButton table={ViewTable[Views.REPORT]} />
      <ModifyButton
        table={ViewTable[Views.REPORT]}
        form={ViewForms[Views.REPORT][ViewForm.MODIFY]}
      />
      <CancelButton table={ViewTable[Views.REPORT]} />
      <ReverseButton table={ViewTable[Views.REPORT]} />
      <BreakButton table={ViewTable[Views.REPORT]} />
      <CopyButton table={ViewTable[Views.REPORT]} form={ViewForms[Views.REPORT][ViewForm.COPY]} />
      <RepairButton
        table={ViewTable[Views.REPORT]}
        form={ViewForms[Views.REPORT][ViewForm.REPAIR]}
      />
      <CloseButton table={ViewTable[Views.REPORT]} />
      <RecapButton table={ViewTable[Views.REPORT]} />
    </>
  );
};
