import React, { useMemo } from "react";
import styled from "styled-components";

const List = styled.ul`
  margin-left: 1.5rem;
  padding: 0;
`;

interface SubtitleListProps {
  messages: string[];
}
export const SubtitleList: React.FC<SubtitleListProps> = ({ messages }) => {
  const ListItems = useMemo(() => messages.map(msg => <li>{msg}</li>), [messages]);

  return <List>{ListItems}</List>;
};
