import { FontAwesomeIcon, NotificationHub, Tooltip } from "@nef/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
const ControlNumCopy = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: auto 1fr;
  & > i {
    cursor: pointer;
  }
`;

interface CellCopy {
  value: any;
  id: any;
}
export const CellCopy: React.FC<CellCopy> = ({ value, id }) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      navigator.clipboard.writeText(value);
      document.execCommand("copy", false, value);
      NotificationHub.send("success", `${value} has been copied to the clipboard.`);
    },
    [value]
  );

  return (
    <ControlNumCopy>
      <span>{value}</span>
      <FontAwesomeIcon
        id={id}
        iconSetClassName="far"
        iconClassName="fa-copy"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      />
      <Tooltip target={id} isOpen={isHovered}>
        Copy
      </Tooltip>
    </ControlNumCopy>
  );
};
