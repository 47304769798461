import { NotificationHub } from "@nef/core";
import { useRefDataContext } from "components/refData";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { useMPIDOptionsContext } from "components/user/mpidContext";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { safeParseJSON } from "utils/js.utils";
import { RequestResult, SELECT_ALL, SettingNames, StandardTables, Status } from "wksConstants";
import { useAggregateLimitDispatch } from ".";
import { getRelationships } from "./constants";
import { formatUrl } from "../../utils/js.utils";
import { FieldNames, ApiResponseNames } from "../fields";
import { useStandardTableDispatch } from "../standardTable";

const hKCacheDispatch = createContext();
hKCacheDispatch.displayName = "HKCacheDispatch";
export const useHKCacheDispatch = () => {
  return useContext(hKCacheDispatch);
};

const hKCacheContext = createContext();
hKCacheContext.displayName = "HKCacheContext";
export const useHKCacheContext = () => {
  return useContext(hKCacheContext);
};

const defaultState = {
  counts: {},
  status: Status.NO_STATUS,
  isPolling: false,
  isSummaryPolling: false,
  makeRequest: false,
  activeKilledId: null,
  activeHeldId: null,
  heldTrades: [],
  killedTrades: [],
  isHeldLoading: false,
  isKilledLoading: false,
  requestTimeout: null,
  shouldAbort: false,
  clearingRelationshipIds: {},
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_POLLING": {
      const isPolling = action.payload;
      return { ...state, isPolling };
    }
    case "SET_CLEARING_RELATIONSHIP_IDS": {
      const clearingRelationshipIds = action.payload;
      const newState = { ...state, clearingRelationshipIds };
      const counts = {};
      let isActiveHeldValid = false;
      let isActiveKilledValid = false;
      Object.keys(clearingRelationshipIds).forEach(id => {
        if (newState.counts[id]) {
          counts[id] = newState.counts[id];
          if (newState.activeHeldId && id === newState.activeHeldId.toString()) {
            isActiveHeldValid = true;
          }
          if (newState.activeKilledId && id === newState.activeKilledId.toString()) {
            isActiveKilledValid = true;
          }
        }
      });
      newState.counts = counts;
      if (!isActiveHeldValid) {
        newState.activeHeldId = null;
        newState.heldTrades = [];
      }
      if (!isActiveKilledValid) {
        newState.activeKilledId = null;
        newState.killedTrades = [];
      }
      return newState;
    }
    case "SET_SUMMARY_POLLING": {
      const isSummaryPolling = action.payload;
      let isPolling = false;
      if (isSummaryPolling) {
        isPolling = true;
      }
      return {
        ...state,
        isSummaryPolling,
        isPolling,
        makeRequest: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...state, isHeldLoading: action.payload, isKilledLoading: action.payload };
    }
    case "SET_LOADING_AND_ABORT": {
      return {
        ...state,
        isHeldLoading: action.payload,
        isKilledLoading: action.payload,
        shouldAbort: true,
      };
    }
    case "SET_HELD_LOADING": {
      return { ...state, isHeldLoading: action.payload };
    }
    case "SET_KILLED_LOADING": {
      return { ...state, isKilledLoading: action.payload };
    }
    case "SET_MAKE_REQUEST": {
      return { ...state, makeRequest: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...state, status: action.payload };
    }
    case "SET_COUNTS": {
      return { ...state, counts: action.payload };
    }
    case "SET_ACTIVE_HELD_ID_AND_ABORT": {
      return { ...state, activeHeldId: action.payload, shouldAbort: true };
    }
    case "SET_ACTIVE_HELD": {
      const { relationshipId, trades } = action.payload;
      return { ...state, activeHeldId: relationshipId, heldTrades: trades };
    }
    case "SET_ACTIVE_KILLED_ID_AND_ABORT": {
      return { ...state, activeKilledId: action.payload, shouldAbort: true };
    }
    case "SET_ACTIVE_KILLED": {
      const { relationshipId, trades } = action.payload;
      return { ...state, activeKilledId: relationshipId, killedTrades: trades };
    }
    case "SET_ACTIVE_HELD_NULL": {
      return { ...state, activeHeldId: null, heldTrades: [] };
    }
    case "SET_SHOULD_ABORT": {
      return { ...state, shouldAbort: action.payload };
    }
    case "SET_REQUEST_TIMEOUT": {
      return { ...state, requestTimeout: action.payload };
    }
    case "SET_ACTIVE_KILLED_NULL": {
      return { ...state, activeKilledId: null, killedTrades: [] };
    }
    default:
      return { ...state };
  }
};

const HKCacheProvider = ({ children, defaultData }) => {
  const standardTableDispatch = useStandardTableDispatch();
  const aggregateDispatch = useAggregateLimitDispatch();
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign(defaultState, defaultData));
  const [abort, setAbort] = useState(null);
  const [user] = useUserContext();
  const [mpids] = useMPIDOptionsContext();
  const [refData] = useRefDataContext();

  const emptyTableData = useMemo(() => [], []);

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      Array.isArray(user[INITIAL_DATA_MODEL.userData]) &&
      mpids.clearCorrMPIDOptions.isReady &&
      refData.isClearingReady
    ) {
      const setting = user[INITIAL_DATA_MODEL.userData].find(
        userSetting => userSetting.name === SettingNames.LIMO_MPID
      );
      if (setting) {
        const fields = safeParseJSON(setting.data);
        let selected = fields[FieldNames.mpid];
        if (selected === SELECT_ALL) {
          selected = mpids.clearCorrMPIDOptions.activeRiskMpidOptions.reduce((acc, curr) => {
            return acc.concat(curr.children);
          }, []);
        }
        if (Array.isArray(selected)) {
          const { clearingRelationshipIds } = getRelationships(
            refData.correspondentMpidRelationships,
            selected
          );
          dispatchF({
            type: "SET_CLEARING_RELATIONSHIP_IDS",
            payload: clearingRelationshipIds,
          });
        }
      }
    }
  }, [
    mpids.clearCorrMPIDOptions.activeRiskMpidOptions,
    mpids.clearCorrMPIDOptions.isReady,
    refData.correspondentMpidRelationships,
    refData.isClearingReady,
    user,
  ]);

  useEffect(() => {
    if (state.shouldAbort) {
      if (abort) {
        abort.abort();
      }
      dispatchF([
        { type: "SET_SHOULD_ABORT", payload: false },
        { type: "SET_MAKE_REQUEST", payload: true },
      ]);
    }
  }, [state.activeKilledId, state.activeHeldId, abort, state.shouldAbort]);

  useEffect(() => {
    return () => clearTimeout(state.requestTimeout);
  }, [state.requestTimeout]);

  const getHKAbortCallback = useCallback(() => {
    dispatchF({
      type: "SET_LOADING",
      payload: false,
    });
  }, []);

  const getHKCacheData = useCallback(() => {
    const getHKCacheCallback = json => {
      const actions = [
        {
          type: "SET_LOADING",
          payload: false,
        },
      ];
      const standardTableActions = [];
      if (json.activeHeldTrades) {
        const heldId = json.activeHeldTrades?.relationshipId;
        actions.push({
          type: "SET_ACTIVE_HELD",
          payload: {
            relationshipId: heldId,
            trades: json.activeHeldTrades?.trades,
          },
        });
        const heldTrades = json.activeHeldTrades?.trades || emptyTableData;
        standardTableActions.push({
          type: "SET_TABLE_DATA_KEEP_SELECTED",
          payload: {
            table: StandardTables.CV_HELD,
            data: heldTrades,
            selectByColumn: ApiResponseNames.controlNum,
          },
        });
      } else {
        actions.push({
          type: "SET_ACTIVE_HELD_NULL",
        });
        standardTableActions.push({
          type: "SET_TABLE_DATA_KEEP_SELECTED",
          payload: {
            table: StandardTables.CV_HELD,
            data: emptyTableData,
            selectByColumn: ApiResponseNames.controlNum,
          },
        });
      }

      if (json.activeKilledTrades) {
        const killedId = json.activeKilledTrades?.relationshipId;
        actions.push({
          type: "SET_ACTIVE_KILLED",
          payload: {
            relationshipId: killedId,
            trades: json.activeKilledTrades?.trades,
          },
        });
        const killedTrades = json.activeKilledTrades?.trades || emptyTableData;
        standardTableActions.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.CV_KILL_REJECT,
            data: killedTrades,
          },
        });
      } else {
        actions.push({
          type: "SET_ACTIVE_KILLED_NULL",
        });
        standardTableActions.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.CV_KILL_REJECT,
            data: emptyTableData,
          },
        });
      }

      if (state.status !== Status.SUCCESS) {
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.SUCCESS,
        });
      }
      actions.push({
        type: "SET_COUNTS",
        payload: json.countsMap,
      });
      actions.push({
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 2000),
      });
      dispatchF(actions);
      standardTableDispatch(standardTableActions);
      aggregateDispatch({
        type: "UPDATE_COUNTS",
        payload: { counts: json.countsMap },
      });
    };

    const getHKCacheError = () => {
      const actions = [
        {
          type: "SET_LOADING",
          payload: false,
        },
      ];
      if (state.status !== Status.ERROR) {
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        actions.push({
          type: "SET_TIMESTAMP",
          payload: 0,
        });
        actions.push({
          type: "SET_INSTANCE_ID",
          payload: 0,
        });
        NotificationHub.send(
          "danger",
          "An error occurred while retrieving held, killed and rejected data."
        );
      }
      actions.push({
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 2000),
      });
      dispatchF(actions);
    };

    const bodyArgs = {
      activeKilledId: state.activeKilledId,
      activeHeldId: state.activeHeldId,
      relationshipIds: Object.keys(state.clearingRelationshipIds),
    };

    const headers = getHeaders();
    const abortController = new AbortController();
    setAbort(abortController);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_STATS_WS, "getStatsHK"),
      {
        method: "post",
        headers,
        body: JSON.stringify(bodyArgs),
        signal: abortController.signal,
      },
      getHKCacheCallback,
      getHKCacheError,
      getHKAbortCallback
    );
  }, [
    state.activeHeldId,
    state.activeKilledId,
    state.clearingRelationshipIds,
    standardTableDispatch,
    getHKAbortCallback,
    emptyTableData,
    aggregateDispatch,
    state.status,
  ]);

  useEffect(() => {
    if (state.isPolling && state.makeRequest && !state.shouldAbort) {
      getHKCacheData();
      dispatchF({ type: "SET_MAKE_REQUEST", payload: false });
    } else if (!state.isPolling) {
      dispatchF({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.makeRequest, getHKCacheData, state.shouldAbort]);

  return (
    <hKCacheDispatch.Provider value={dispatchF}>
      <hKCacheContext.Provider value={[state, dispatchF]}>{children}</hKCacheContext.Provider>
    </hKCacheDispatch.Provider>
  );
};

export default HKCacheProvider;
