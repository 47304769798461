import React from "react";
import { formatNumberToString } from "utils/js.utils";
import { EQRCProgress } from "./progress";
import { TableRow as Original } from "./cache.types";
import { formatPercentTo } from "wksConstants";
import { sortFormattedNumber } from "components/standardTable/columnDefinitions";
import { EqrcFields } from "components/settings/eqrc/constants";

type Cell = {
  row: TableRow;
};

type TableRow = {
  original: Original;
  id: string;
};

export interface TableColumn {
  Header: string;
  id: string;
  accessor?: ((row: Original) => React.ReactNode) | keyof Original | string;
  width?: number;
  Cell?: (cell: Cell) => React.ReactNode;
  sortType?: (a: TableRow, b: TableRow) => -1 | 0 | 1;
}

export const EQRCColumnDefinitions: TableColumn[] = [
  {
    Header: "Check",
    id: "check",
    accessor: "check",
    width: 100,
  },
  {
    Header: "MPID",
    id: "mpid",
    accessor: "clientId",
    width: 70,
  },
  {
    Header: "Exchange",
    id: "exchange",
    accessor: "exchange",
    width: 100,
  },
  {
    Header: "Group ID",
    id: "groupId",
    accessor: row => (row.groupId === 0 ? undefined : row.groupId),
    width: 85,
  },
  {
    Header: "Port",
    id: "port",
    accessor: "accountName",
    width: 85,
  },
  {
    Header: "Current Exposure",
    id: EqrcFields.currentExposure,
    accessor: row => formatNumberToString(row.currentExposure),
    sortType: (a, b) => {
      return sortFormattedNumber(a.original.currentExposure, b.original.currentExposure);
    },
    width: 130,
  },
  {
    Header: "Watch",
    id: EqrcFields.watch,
    accessor: row => formatNumberToString(row.watch),
    sortType: (a, b) => {
      return sortFormattedNumber(a.original.watch, b.original.watch);
    },
    width: 130,
  },
  {
    Header: "Warn",
    id: EqrcFields.warn,
    accessor: row => formatNumberToString(row.warn),
    width: 130,
    sortType: (a, b) => {
      return sortFormattedNumber(a.original.warn, b.original.warn);
    },
  },
  {
    Header: "Action",
    id: EqrcFields.action,
    accessor: row => formatNumberToString(row.action),
    width: 130,
    sortType: (a, b) => {
      return sortFormattedNumber(a.original.action, b.original.action);
    },
  },
  {
    Header: "Thresholds",
    id: "thresholds",
    accessor: row => true, // this doesn't really have a value, so no filtering
    width: 130,
    sortType: (a, b) => thresholdSort(a.original, b.original),
    Cell: ({ row }) => {
      const { watch, warn, action, currentExposure } = row.original;

      const { percent, percentTo } = percentsCalc(row.original);
      return (
        <EQRCProgress
          id={`exposure${row.id}`}
          percentTop={percent}
          percentToTop={percentTo}
          check={row.original.check}
          alertActive={watch > 0 ? 1 : 0}
          holdActive={warn > 0 ? 1 : 0}
          rejectActive={action > 0 ? 1 : 0}
          hideAlert={false}
          type={currentExposure <= watch ? "Watch" : currentExposure <= warn ? "Warn" : "Action"}
        />
      );
    },
  },
];

const thresholdSort = (a: Original, b: Original) => {
  const percentA = percentsCalc(a).percent;
  const percentB = percentsCalc(b).percent;

  if (percentA > percentB) {
    return 1;
  } else if (percentA === percentB) {
    return 0;
  } else {
    return -1;
  }
};
const percentsCalc = (row: Original) => {
  const { watch, warn, action, currentExposure } = row;
  const numActive = (watch > 0 ? 1 : 0) + (warn > 0 ? 1 : 0) + (action > 0 ? 1 : 0);
  let percents: { percent: number; percentTo: number } = { percent: 0, percentTo: 0 };

  if (currentExposure <= watch && watch > 0) {
    const percentToWatch = formatPercentTo((currentExposure / watch) * 100);

    percents = {
      percent: percentToWatch / numActive,
      percentTo: percentToWatch,
    };
  } else if (currentExposure <= warn && warn > 0) {
    const percentToWarn = formatPercentTo(((currentExposure - watch) / (warn - watch)) * 100);

    percents = {
      percent: (100 * (watch ? 1 : 0)) / numActive + percentToWarn / numActive,
      percentTo: percentToWarn,
    };
  } else if (action > warn && action > 0) {
    const percentToAction = formatPercentTo(((currentExposure - warn) / (action - warn)) * 100);
    percents = {
      percent: (100 * (numActive - 1)) / numActive + percentToAction / numActive,
      percentTo: percentToAction,
    };
  }
  percents.percent = Math.floor(percents.percent);
  percents.percentTo = Math.min(Math.floor(percents.percentTo), 100);

  return percents;
};
