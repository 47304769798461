import faker from "faker";
// Thanks for almost nothing faker
export const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z"
];
export const alpha = n => {
  let wholeString = "";
  for (let i = 0; i < n; i++) {
    wholeString += faker.random.arrayElement(letters);
  }
  return wholeString;
};
export const times = x => f => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

export const numbers = function(count) {
  let returnString = "";
  times(count)(() => {
    returnString += faker.random.number({ min: 1, max: 9 });
  });
  return returnString;
};

export const arrayElements = function(array, count) {
  let returnString = "";
  times(count)(() => {
    returnString +=
      array[faker.random.number({ min: 0, max: array.length - 1 })];
  });
  return returnString;
};
