export const pvRejectsByMPID = [
  {
    mpid: "BARD",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "BTIG",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    mpid: "CDRG",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "7555",
  },
  {
    mpid: "CSTI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "GTSM",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "HRBF",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "3",
  },
  {
    mpid: "JNST",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "385",
  },
  {
    mpid: "MAXM",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "MIRA",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "MSCO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "5",
  },
  {
    mpid: "NITE",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1535",
  },
  {
    mpid: "VNDM",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
];

export const pvRejectsBySecurity = [
  {
    symbol: "ADN",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "10",
  },
  {
    symbol: "AENTW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "3",
  },
  {
    symbol: "AERT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4481",
  },
  {
    symbol: "AKLI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "14",
  },
  {
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "27",
  },
  {
    symbol: "AUVI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "10",
  },
  {
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "865",
  },
  {
    symbol: "BKYI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "9",
  },
  {
    symbol: "BMRN",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "BOWL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "BTAI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    symbol: "BYU",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "83",
  },
  {
    symbol: "CDIO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "55",
  },
  {
    symbol: "CLDX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "CMSpC",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "CpJ",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1431",
  },
  {
    symbol: "CREVW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    symbol: "DDOG",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "5",
  },
  {
    symbol: "DRRX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "17",
  },
  {
    symbol: "EBAY",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "ENPH",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "EOLS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "FOUR",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    symbol: "HCWB",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "599",
  },
  {
    symbol: "JCSE",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "235",
  },
  {
    symbol: "LIFWW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "98",
  },
  {
    symbol: "MLTX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    symbol: "MTNB",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    symbol: "MTRYW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    symbol: "ONMD",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "19",
  },
  {
    symbol: "OPTX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "945",
  },
  {
    symbol: "SYBX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "17",
  },
  {
    symbol: "TGLS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "11",
  },
  {
    symbol: "THAR",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "374",
  },
  {
    symbol: "THS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "TOST",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    symbol: "TSLA",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "121",
  },
  {
    symbol: "ZAPPW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "10",
  },
];

export type PV_Reject_By_Mpid_Security = {
  count: string;
  rejectText: string;
  mpid: string;
  symbol: string;
};

export const pvRejectsByMpidSecurity: PV_Reject_By_Mpid_Security[] = [
  {
    mpid: "AEFA",
    symbol: "ENPH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "BARD",
    symbol: "EBAY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "BTIG",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    mpid: "BTIG",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "BTIG",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "ADN",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "ADN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "8",
  },
  {
    mpid: "CDRG",
    symbol: "AERT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4481",
  },
  {
    mpid: "CDRG",
    symbol: "AERT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "5024",
  },
  {
    mpid: "CDRG",
    symbol: "AKLI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "14",
  },
  {
    mpid: "CDRG",
    symbol: "AKLI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "19",
  },
  {
    mpid: "CDRG",
    symbol: "AMBP.WS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "CDRG",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "3",
  },
  {
    mpid: "CDRG",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "56",
  },
  {
    mpid: "CDRG",
    symbol: "AUVI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "10",
  },
  {
    mpid: "CDRG",
    symbol: "AUVI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "16",
  },
  {
    mpid: "CDRG",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "12",
  },
  {
    mpid: "CDRG",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "16",
  },
  {
    mpid: "CDRG",
    symbol: "BKYI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "BKYI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "BTAI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "25",
  },
  {
    mpid: "CDRG",
    symbol: "CDIO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "7",
  },
  {
    mpid: "CDRG",
    symbol: "CFIV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "10",
  },
  {
    mpid: "CDRG",
    symbol: "CLDX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1401",
  },
  {
    mpid: "CDRG",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1799",
  },
  {
    mpid: "CDRG",
    symbol: "CREVW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "CDRG",
    symbol: "DDOG",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "DDOG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "DRRX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "FOUR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "GAIA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "CDRG",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "497",
  },
  {
    mpid: "CDRG",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "593",
  },
  {
    mpid: "CDRG",
    symbol: "JCSE",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    mpid: "CDRG",
    symbol: "JCSE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "113",
  },
  {
    mpid: "CDRG",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "215",
  },
  {
    mpid: "CDRG",
    symbol: "LIFWW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "LIFWW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "NTRB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "OGI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "ONMD",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "19",
  },
  {
    mpid: "CDRG",
    symbol: "ONMD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "20",
  },
  {
    mpid: "CDRG",
    symbol: "OPTX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "OPTX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "CDRG",
    symbol: "QLI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "14",
  },
  {
    mpid: "CDRG",
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "929",
  },
  {
    mpid: "CDRG",
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1342",
  },
  {
    mpid: "CDRG",
    symbol: "ROI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "SLS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "SYBX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "9",
  },
  {
    mpid: "CDRG",
    symbol: "SYBX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "11",
  },
  {
    mpid: "CDRG",
    symbol: "TGLS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "TGLS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "CDRG",
    symbol: "VANI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CDRG",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "44",
  },
  {
    mpid: "CDRG",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "58",
  },
  {
    mpid: "CDRG",
    symbol: "WGSWW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "COWN",
    symbol: "CYN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "COWN",
    symbol: "NRSN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CPEM",
    symbol: "DISH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CSTI",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "CSTI",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CUGS",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "45",
  },
  {
    mpid: "CUGS",
    symbol: "EJH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "18",
  },
  {
    mpid: "CUGS",
    symbol: "INMD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CUGS",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "981",
  },
  {
    mpid: "CUGS",
    symbol: "LTHM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "CUGS",
    symbol: "NVOS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "CUGS",
    symbol: "PXMD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "8",
  },
  {
    mpid: "CUGS",
    symbol: "UPST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "CUGS",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "DFIN",
    symbol: "AERT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "43",
  },
  {
    mpid: "DFIN",
    symbol: "CFIV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "DFIN",
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "DRVW",
    symbol: "KRRO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "DRWW",
    symbol: "PLNT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EBXL",
    symbol: "AUID",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EBXL",
    symbol: "AXSM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EDJO",
    symbol: "AMZN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EDJO",
    symbol: "CVX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EDJO",
    symbol: "LEN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EDJO",
    symbol: "UBSI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "EDJO",
    symbol: "WBD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "ETMM",
    symbol: "ZWZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "FBCO",
    symbol: "NOVA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "GSCO",
    symbol: "CPNG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "GSCO",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "GSCO",
    symbol: "SANM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "GSCO",
    symbol: "TOST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "GSCO",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "GSCO",
    symbol: "ZFOXW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "GTSM",
    symbol: "AENTW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "GTSM",
    symbol: "AENTW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "AIRE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "9",
  },
  {
    mpid: "HOOD",
    symbol: "ALRN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "ALTO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "ASO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "AZTA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "BHF",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "CANO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "CASH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "CHTR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "5",
  },
  {
    mpid: "HOOD",
    symbol: "CINF",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "CNXN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "CRDO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "DPST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "EPSN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "EVGO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "FATBB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "FIVN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "FOX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "FOXF",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "FTNT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "GPRE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "HLMN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "HOLO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "IMCR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "INKT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "HOOD",
    symbol: "IRTC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "ISPR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "JJSF",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "JKHY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "KRRO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "LCID",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "22",
  },
  {
    mpid: "HOOD",
    symbol: "LNTH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "MTEM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "NRDY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "NRIX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "OCX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "OLMA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "OTTR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "PEGA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "PFG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "PHAR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "PI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "RSVR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "SLVO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "HOOD",
    symbol: "SSB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "STRL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "SYNA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "TH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "TMC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "TPG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "TSLA",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "HOOD",
    symbol: "TSLA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "HOOD",
    symbol: "VCSA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "VIA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "HOOD",
    symbol: "VKTX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "HOOD",
    symbol: "VRNA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "WRAP",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HOOD",
    symbol: "WTFC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "CMSpC",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "COFpI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "CpJ",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "CpK",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "ENPH",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "HRBF",
    symbol: "ENPH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "196",
  },
  {
    mpid: "JEFF",
    symbol: "ZNTL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "JNST",
    symbol: "AVDX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "311",
  },
  {
    mpid: "JNST",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "137",
  },
  {
    mpid: "JNST",
    symbol: "BYU",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "11",
  },
  {
    mpid: "JNST",
    symbol: "BYU",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "35",
  },
  {
    mpid: "JNST",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "JNST",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "JNST",
    symbol: "DRRX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "EVGO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "52",
  },
  {
    mpid: "JNST",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "52",
  },
  {
    mpid: "JNST",
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    mpid: "JNST",
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "8",
  },
  {
    mpid: "JNST",
    symbol: "MGIC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JNST",
    symbol: "MQ",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "JNST",
    symbol: "SNBR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "JPMS",
    symbol: "NRDY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "JPMS",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JPMS",
    symbol: "ZNTL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "JPMS",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "21",
  },
  {
    mpid: "LEHM",
    symbol: "NRDY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "LPCA",
    symbol: "WE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "115",
  },
  {
    mpid: "MAXM",
    symbol: "AENTW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "MAXM",
    symbol: "AENTW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MIRA",
    symbol: "UPST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MLCO",
    symbol: "FLYW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MLCO",
    symbol: "LI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "MLCO",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "20",
  },
  {
    mpid: "MLCO",
    symbol: "LIN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MLCO",
    symbol: "MRVI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MLCO",
    symbol: "NCL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "MLCO",
    symbol: "PXMD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "MLCO",
    symbol: "SANM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MLCO",
    symbol: "UPST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "AMRC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "BMRN",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "MSCO",
    symbol: "BMRN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "MSCO",
    symbol: "BOWL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "BOWL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "CPNG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "EOLS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "EOLS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "ERO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "NRDY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "PETQ",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "TOST",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "MSCO",
    symbol: "TOST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NFSC",
    symbol: "D",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "ADN",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "ADN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "9",
  },
  {
    mpid: "NITE",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "24",
  },
  {
    mpid: "NITE",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "42",
  },
  {
    mpid: "NITE",
    symbol: "BHIL.W",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "542",
  },
  {
    mpid: "NITE",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "351",
  },
  {
    mpid: "NITE",
    symbol: "BKYI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    mpid: "NITE",
    symbol: "BKYI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "12",
  },
  {
    mpid: "NITE",
    symbol: "BLEUW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "BTAI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    mpid: "NITE",
    symbol: "BTAI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "16",
  },
  {
    mpid: "NITE",
    symbol: "BYU",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "72",
  },
  {
    mpid: "NITE",
    symbol: "BYU",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "137",
  },
  {
    mpid: "NITE",
    symbol: "CDIO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "55",
  },
  {
    mpid: "NITE",
    symbol: "CDIO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "82",
  },
  {
    mpid: "NITE",
    symbol: "CLDX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "CLDX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "27",
  },
  {
    mpid: "NITE",
    symbol: "CREV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "17",
  },
  {
    mpid: "NITE",
    symbol: "CREVW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "CREVW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "DDOG",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "DDOG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "9",
  },
  {
    mpid: "NITE",
    symbol: "DISH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "DRRX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "17",
  },
  {
    mpid: "NITE",
    symbol: "DRRX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "21",
  },
  {
    mpid: "NITE",
    symbol: "FOUR",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "FOUR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "FTCI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "HCWB",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "HCWB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "50",
  },
  {
    mpid: "NITE",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "112",
  },
  {
    mpid: "NITE",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "122",
  },
  {
    mpid: "NITE",
    symbol: "LIFW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "102",
  },
  {
    mpid: "NITE",
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "90",
  },
  {
    mpid: "NITE",
    symbol: "LUMO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "48",
  },
  {
    mpid: "NITE",
    symbol: "LXEO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "MGIC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "7",
  },
  {
    mpid: "NITE",
    symbol: "MLTX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "MLTX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "NITE",
    symbol: "MTNB",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "MTNB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "NTRB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "NVVE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "OGI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "OLO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "OLPX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "OPTX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "PERF.W",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "PETWW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "16",
  },
  {
    mpid: "NITE",
    symbol: "RMCO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "8",
  },
  {
    mpid: "NITE",
    symbol: "SLS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "SNBR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "SNOW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "SYBX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "8",
  },
  {
    mpid: "NITE",
    symbol: "SYBX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "TGLS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "10",
  },
  {
    mpid: "NITE",
    symbol: "TGLS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "NITE",
    symbol: "TH",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "THAR",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "374",
  },
  {
    mpid: "NITE",
    symbol: "THAR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "200",
  },
  {
    mpid: "NITE",
    symbol: "THS",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "THS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "VANI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "71",
  },
  {
    mpid: "NITE",
    symbol: "VTYX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "76",
  },
  {
    mpid: "NITE",
    symbol: "ZAPPW",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "NITE",
    symbol: "ZAPPW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "NITE",
    symbol: "ZVV",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "2",
  },
  {
    mpid: "NITE",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "RILY",
    symbol: "BIOR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "SBSH",
    symbol: "PKX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "SBSH",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "9",
  },
  {
    mpid: "SPDR",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "STFL",
    symbol: "INSP",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "SUFI",
    symbol: "KD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "SUFI",
    symbol: "MASI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "SUFI",
    symbol: "SNBR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "AAPL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "10",
  },
  {
    mpid: "UBSS",
    symbol: "ADBE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "AMD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "10",
  },
  {
    mpid: "UBSS",
    symbol: "AMZN",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "ARRY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "BA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "BABA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "UBSS",
    symbol: "BAC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "BIDU",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "CCJ",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "CRSP",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "DB",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "DIS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "EL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "EXFY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "FLYW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "FUTU",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "GDS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "GDX",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "GOOG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "UBSS",
    symbol: "HOOD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "IOVA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "LI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "LPRO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "LZ",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "MA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "META",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "15",
  },
  {
    mpid: "UBSS",
    symbol: "MSFT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "12",
  },
  {
    mpid: "UBSS",
    symbol: "MU",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "NIO",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "NVDA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "27",
  },
  {
    mpid: "UBSS",
    symbol: "OM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "PDD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "5",
  },
  {
    mpid: "UBSS",
    symbol: "PG",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "POWI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "PYPL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "QQQ",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "SANM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "UBSS",
    symbol: "SE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "6",
  },
  {
    mpid: "UBSS",
    symbol: "SNBR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "SNOW",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "SPR",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "STNE",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "TLT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "TME",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "UBSS",
    symbol: "TOST",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "2",
  },
  {
    mpid: "UBSS",
    symbol: "TSLA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "19",
  },
  {
    mpid: "UBSS",
    symbol: "TSM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "5",
  },
  {
    mpid: "UBSS",
    symbol: "UBER",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "7",
  },
  {
    mpid: "UBSS",
    symbol: "UPWK",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "WFC",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "XOM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "UBSS",
    symbol: "YMM",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "VGRD",
    symbol: "EBAY",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "VLCT",
    symbol: "AERT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "28",
  },
  {
    mpid: "VLCT",
    symbol: "AULT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "68",
  },
  {
    mpid: "VLCT",
    symbol: "AUVI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "12",
  },
  {
    mpid: "VLCT",
    symbol: "BIOL",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "3",
  },
  {
    mpid: "VLCT",
    symbol: "HSCS",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "149",
  },
  {
    mpid: "VLCT",
    symbol: "QLI",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "12",
  },
  {
    mpid: "VNDM",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF OVERRIDE RANGE",
    count: "4",
  },
  {
    mpid: "VNDM",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "12",
  },
  {
    mpid: "WABR",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "4",
  },
  {
    mpid: "WCHV",
    symbol: "ZVZZT",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "WRET",
    symbol: "KD",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
  {
    mpid: "YLPL",
    symbol: "NVDA",
    rejectText: "!REJ - PRICE OUT OF RANGE",
    count: "1",
  },
];

export const { byMpid, bySymbol } = pvRejectsByMpidSecurity.reduce(
  (acc, curr) => {
    if (curr.rejectText === "!REJ - PRICE OUT OF OVERRIDE RANGE") {
      if (acc.byMpid[curr.mpid] === undefined) {
        acc.byMpid[curr.mpid] = 1;
      } else {
        acc.byMpid[curr.mpid]++;
      }
      if (acc.bySymbol[curr.symbol] === undefined) {
        acc.bySymbol[curr.symbol] = 1;
      } else {
        acc.bySymbol[curr.symbol]++;
      }
    }
    return acc;
  },
  { byMpid: {}, bySymbol: {} } as {
    byMpid: { [mpid: string]: number };
    bySymbol: { [symbol: string]: number };
  }
);
