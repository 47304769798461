import { Box, Switch } from "@nef/core";
import React, { memo, useCallback } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";

const WorkXSwitch = props => {
  const [userData] = useUserContext();
  const { id, value, style, form, name, handleInputChange: onInputChange } = props;

  const handleChange = useCallback(
    e => {
      const fieldDataKey = id ? id : name;
      let fieldData;
      if (fieldDataKey && final[fieldDataKey]) {
        fieldData = execOrReturn(final[fieldDataKey], {
          form,
          entitlements: userData.entitlements,
        });
      }
      if (onInputChange) {
        onInputChange({
          id,
          name,
          value,
          fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
        });
      }
    },
    [name, onInputChange, form, userData.entitlements, id, value]
  );

  return (
    <Box paddingBottom={3} marginRight={3} style={style}>
      <Switch {...props} checked={value} onChange={handleChange} />
    </Box>
  );
};

export default memo(WorkXSwitch);
