import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useDetailsForUpload } from "./useDetailsForUpload";

export const UploadSuccessNotification = ({ id, fileName }) => {
  const [{ uploadId, uploadName }, setFileDetails] = useState({});
  useDetailsForUpload(uploadId, uploadName);

  const handleClick = useCallback(() => {
    setFileDetails({ uploadId: id, uploadName: fileName });
  }, [fileName, id]);

  return (
    <span>
      {`${fileName} has uploaded successfully.`}
      <br />
      <Link to="/upload" onClick={handleClick}>
        View Results
      </Link>
    </span>
  );
};
