import { NotificationHub } from "@nef/core";
import { ApiResponseNames, ApiToFieldMap, FieldNames } from "components/fields/fieldConstants";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { safeParseJSON } from "utils/js.utils";
import {
  RequestResult,
  StandardTables,
  StandardTablesColumns,
  STANDARD_TABLE_DEFAULT_COLUMN_SET,
  translateSettlementWithT1RegularFlag,
} from "wksConstants";
import { PriceTradeDigit, SettlementOptions } from "../fields";
import { PTRA_CONFIGURATION_ENTITY } from "../settings/ptra/fields";

const standardTableDispatch = createContext();
standardTableDispatch.displayName = "TableDispatch";
export const useStandardTableDispatch = () => {
  return useContext(standardTableDispatch);
};

const standardTableContext = createContext();
standardTableContext.displayName = "TableContext";
export const useStandardTableContext = () => {
  return useContext(standardTableContext);
};

const defaultState = {
  isColumnSavingDisabled: false,
};

export const emptyState = {
  maxTableSize: 10000,
  isExportDisabled: false,
  data: [], //[maxTableSize] rows to load into table
  allTheData: [], // full data set from query
  total: 0,
  isLoading: false,
  selected: {},
  sortData: [],
  columnSets: {}, // user's saved column sets
  columnSetDefault: STANDARD_TABLE_DEFAULT_COLUMN_SET,
  currentColumnSet: STANDARD_TABLE_DEFAULT_COLUMN_SET, // currently loaded column set
  maxNumColumnSets: 20,
  // currentColumns: undefined, // the currently displayed columns - we will just figure this out based on hiddenCo0lumns
  allColumns: [], // every possible column
  hiddenColumns: [], // indicate to table which columns to hide
  columnsOrder: [],
  columnSavingList: [],
  someOneAskedThatWeRefresh: true,
  lastQueryDate: null,
  disabledRows: {},
  expandedRows: {},
};

const getDefaultColumnsOrder = table => {
  return StandardTablesColumns[table].map(col => {
    return col.id;
  });
};

// initial state for each standard table
Object.keys(StandardTables).forEach(key => {
  defaultState[StandardTables[key]] = {
    maxTableSize: 10000,
    isExportDisabled: false,
    data: [], //[maxTableSize] rows to load into table
    allTheData: [], // full data set from query
    total: 0,
    isLoading: false,
    selected: {},
    sortData: [],
    columnSets: {}, // user's saved column sets
    currentColumnSet: STANDARD_TABLE_DEFAULT_COLUMN_SET, // currently loaded column set
    maxNumColumnSets: 20,
    // currentColumns: undefined, // the currently displayed columns - we will just figure this out based on hiddenCo0lumns
    hiddenColumns: [], // indicate to table which columns to hide
    columnSavingList: [],
    someOneAskedThatWeRefresh: true,
    columnsOrder: getDefaultColumnsOrder(StandardTables[key]),
    columnSetDefault: STANDARD_TABLE_DEFAULT_COLUMN_SET,

    allColumns: (function (passedKey) {
      return StandardTablesColumns[StandardTables[passedKey || key]];
    })(),
    disabledRows: {},
    expandedRows: {},
  };

  const allColumnsById = {};
  defaultState[StandardTables[key]].allColumns.forEach(
    column => (allColumnsById[column.id] = column)
  );
  defaultState[StandardTables[key]].allColumnsById = allColumnsById;
});

defaultState[StandardTables.PV_SUPERVISOR_MONITOR].hiddenColumns = StandardTablesColumns[
  StandardTables.PV_SUPERVISOR_MONITOR
].filter(col => {
  //  SYMBOL = "symbol",
  // MPID = "mpid",
  // REQUEST_STATUS = "requestStatus",
  // REQUESTOR = "requestor",
  // REQUEST_ID = "requestId",
  // LAST_UPDATE_TIME = "lastUpdateTime",

  return !["mpid", ApiResponseNames.symbol, "price", "quantity", "rejectReason"].includes(col.id);
});
// .map(col => col.id);
defaultState[StandardTables.CV_SUMMARY].hiddenColumns = [
  ApiResponseNames.totalBuy,
  ApiResponseNames.totalSell,
  ApiResponseNames.totalNet,
  "clearingFirmMPID",
  "buyAlertLimit",
  "sellAlertLimit",
  "netAlertLimit",
  "buyDefaultAction",
  "perTradeBuyAction",
  "perTradeBuyLimit",
  "perTradeSellLimit",
];

defaultState[StandardTables.CV_HELD].hiddenColumns = [
  "execgiveup",
  "side",
  "tradeStatus",
  "controlNum",
  "tradeSource",
  "contragiveup",
  ApiResponseNames.priceTradeDigit,
];

defaultState[StandardTables.CV_KILL_REJECT].hiddenColumns = [
  "execgiveup",
  "side",
  "controlNum",
  "tradeSource",
  "contragiveup",
  ApiResponseNames.priceTradeDigit,
];

defaultState[StandardTables.PTRA_CONFIG].hiddenColumns = [
  PTRA_CONFIGURATION_ENTITY.clearingMPID,
  PTRA_CONFIGURATION_ENTITY.creator,
  PTRA_CONFIGURATION_ENTITY.lastUpdate,
];

Object.freeze(defaultState);

const deriveVisibleColumns = (columnsToSet, notVisible) => {
  columnsToSet.forEach(c => {
    c.isVisible = !notVisible.includes(c.id);
  });
};

Object.keys(StandardTables).forEach(key => {
  deriveVisibleColumns(
    defaultState[StandardTables[key]].allColumns,
    defaultState[StandardTables[key]].hiddenColumns
  );
});

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "ADD_SELF":
      return { ...state, dispatch: action.payload };
    case "SET_IS_LOADING": {
      const { table, isLoading } = action.payload;
      const standardTable = { ...state[table] };

      standardTable.isLoading = isLoading;
      return { ...state, [table]: standardTable };
    }
    case "DISABLE_COLUMN_SAVING": {
      return {
        ...state,
        isColumnSavingDisabled: action.payload,
      };
    }
    case "DUMP_SETTINGS": {
      const { userData } = action.payload;
      const newState = { ...state };
      newState.isColumnSavingDisabled = false;

      const parsed = userData.reduce(
        (acc, cur) => {
          if (cur.type === "tableColumnSet") {
            acc.columnSets.push(cur);
          } else if (cur.type === "tableColumnSetDefault") {
            acc.columnSetDefaults.push(cur);
          }
          return acc;
        },
        { columnSets: [], columnSetDefaults: [] }
      );

      parsed.columnSets.forEach((d, i) => {
        if (newState[d.name] !== undefined) {
          const json = safeParseJSON(d.data);

          Object.entries(json).forEach(([setName, set]) => {
            //filter out column ID strings that do not match with a currently available column
            // if any columns are filtered out, taint this columnSet
            json[setName].tainted = false;
            const orderWithGoodColumns = (set.order || []).filter(column => {
              return state[d.name].allColumnsById.hasOwnProperty(column);
            });

            if (orderWithGoodColumns.length !== (set.order || []).length) {
              json[setName].tainted = true;
            }

            json[setName].order = orderWithGoodColumns;

            const hiddenWithGoodColumns = (set.hidden || []).filter(column => {
              return state[d.name].allColumnsById.hasOwnProperty(column);
            });

            if (hiddenWithGoodColumns.length !== (set.hidden || []).length) {
              json[setName].tainted = true;
            }

            json[setName].hiddenColumns = hiddenWithGoodColumns;
          });

          newState[d.name].columnSets = {
            ...newState[d.name].columnSets,
            ...json,
          };
        }
      });
      parsed.columnSetDefaults.forEach((d, i) => {
        if (newState[d.name] !== undefined) {
          const defaultColumnSetName = safeParseJSON(d.data).default;

          newState[d.name] = {
            ...newState[d.name],
            columnSetDefault: defaultColumnSetName,
            currentColumnSet: defaultColumnSetName,
            hiddenColumns:
              (defaultColumnSetName === STANDARD_TABLE_DEFAULT_COLUMN_SET
                ? defaultState[d.name]?.hiddenColumns
                : state[d.name].columnSets[defaultColumnSetName]?.hidden) || [],
            columnsOrder:
              (defaultColumnSetName === STANDARD_TABLE_DEFAULT_COLUMN_SET
                ? getDefaultColumnsOrder(d.name)
                : state[d.name].columnSets[defaultColumnSetName]?.order) || [],
          };

          if (defaultColumnSetName === STANDARD_TABLE_DEFAULT_COLUMN_SET) {
            deriveVisibleColumns(
              newState[d.name].allColumns,
              defaultState[d.name].hiddenColumns || []
            );
          } else {
            deriveVisibleColumns(newState[d.name].allColumns, newState[d.name].hiddenColumns);
          }

          return newState;
        }
      });

      return newState;
    }

    case "ADD_TABLE_DATA": {
      const { table, data } = action.payload;
      const newState = { ...state };

      newState[table] = {
        ...newState[table],
        data: newState[table].data.concat(data),
        allTheData: newState[table].allTheData.concat(data),
        isLoading: false,
      };
      return newState;
    }
    case "ADD_TABLE_DATA_FIRST": {
      const { table, data } = action.payload;
      const newState = { ...state };
      newState[table] = {
        ...newState[table],
        data: [data, ...newState[table].data],
        allTheData: [data, ...newState[table].allTheData],
        isLoading: false,
      };
      return newState;
    }
    case "ADD_ROW_BEFORE": {
      const { table, row, key } = action.payload;
      const index = state[table].data.findIndex(r => key.value === r[key.key]);
      const tableData = state[table].data;
      const newTableData = [...tableData.slice(0, index), row, ...tableData.slice(index)];
      const allData = [...state[table].allTheData];
      const newAllData = [...allData.slice(0, index), row, ...allData.slice(index)];
      const newState = { ...state, [table]: { ...state[table], data: newTableData } };

      newState[table] = {
        ...newState[table],
        data: newTableData,
        allTheData: newAllData,
        isLoading: false,
      };

      return newState;
    }
    case "ADD_ROW_BEFORE_GENERIC": {
      const { key, value, row, table } = action.payload;
      const index = state[table].data.findIndex(r => r[key] === value);
      const tableData = state[table].data;
      const newTableData = [...tableData.slice(0, index), row, ...tableData.slice(index)];
      const allData = [...state[table].allTheData];
      const newAllData = [...allData.slice(0, index), row, ...allData.slice(index)];

      return {
        ...state,
        [table]: {
          ...state[table],
          data: newTableData,
          allTheData: newAllData,
        },
      };
    }
    case "DELETE_ROW_WITH_ID": {
      const { table, idField, idValue } = action.payload;
      const newState = { ...state };
      const index = newState[table].data.findIndex(row => row[idField] === idValue);
      if (index > -1) {
        newState[table].data = [...newState[table].data];
        const removed = newState[table].data.splice(index, 1);
        const selectedRowIdx = newState[table].selected.rows.findIndex(
          r => r[idField] === removed[idField]
        );
        const selectedOriginalsIdx = newState[table].selected.originals.findIndex(
          r => r[idField] === removed[idField]
        );
        if (selectedRowIdx > -1) {
          newState[table].selected.rows.splice(selectedRowIdx, 1);
        }
        if (selectedOriginalsIdx > -1) {
          newState[table].selected.originals.splice(selectedOriginalsIdx, 1);
        }
      }
      return newState;
    }
    case "REMOVE_SELECTED_ROWS": {
      const { table } = action.payload;
      const newState = { ...state };
      const indices = Object.keys(newState[table].selected).reduce((acc, key) => {
        if (newState[table].selected[key] === true) {
          acc.push(key);
        }
        return acc;
      }, []);
      // sort highest to lowest to ensure correct items are removed
      indices.sort((a, b) => b - a);
      indices.forEach(index => {
        newState[table].data = Array.from(newState[table].data);
        newState[table].data.splice(index, 1);
        newState[table].allTheData = Array.from(newState[table].allTheData);
        newState[table].allTheData.splice(index, 1);
      });
      newState[table].selected = {};
      newState[table].internalSelectedRows = {};
      return newState;
    }
    case "DELETE_ROW_BY_VALUE": {
      const { table, key, value } = action.payload;
      return {
        ...state,
        [table]: {
          ...state[table],
          data: state[table].data.filter(row => row[key] !== value),
          allTheData: state[table].allTheData.filter(row => row[key] !== value),
        },
      };
    }
    case "UPDATE_TABLE_ROW": {
      const { table, row } = action.payload;
      const newState = {
        ...state,
        [table]: { ...state[table], data: [...state[table].data] },
      };
      const index = newState[table].data.findIndex(r => {
        return r.workstationId === row.workstationId;
      });
      newState[table].data[index] = Object.assign({}, newState[table].data[index], row);
      return newState;
    }

    case "UPDATE_TABLE_ROW_WITH_ID": {
      const { table, row, idField } = action.payload;
      let { idValue } = action.payload;

      if (idValue === undefined) {
        idValue = row[idField];
      }
      const newState = {
        ...state,
        [table]: { ...state[table], data: [...state[table].data] },
      };
      const index = newState[table].data.findIndex(r => {
        if (r[idField] === undefined || idValue === undefined) {
          return false;
        }
        return r[idField] === idValue;
      });

      newState[table].data[index] = Object.assign({}, newState[table].data[index], row);
      return newState;
    }
    case "SWAP_IN_ROW": {
      const { table, row, originalWksID } = action.payload;
      const newState = {
        ...state,
        [table]: { ...state[table], data: [...state[table].data] },
      };

      const index = newState[table].data.findIndex(r => r.workstationID === originalWksID);

      newState[table].data[index] = row;
      return newState;
    }
    case "UPDATE_OR_INSERT_ROWS": {
      // rows: {id:<row>,...}
      const { rows, table } = action.payload;

      // transform state to be rows:{id:<row>...}
      // track array order
      const remappedRows = {};
      const order = [];
      state[table].data.forEach(r => {
        remappedRows[r.id] = r;
        order.push(r.id);
      });

      // loop over incoming rows and insert/update state
      Object.entries(rows).forEach(([k, v]) => {
        if (!remappedRows[v.id]) {
          order.push(v.id);
        }
        remappedRows[v.id] = v;
      });

      // write a new state array with the updated rows in correct order
      const unmappedRows = order.map(id => remappedRows[id]);

      return { ...state, [table]: { ...state[table], data: unmappedRows } };
    }
    case "UPDATE_CELLS": {
      const { key, values, table } = action.payload;
      const newTableData = [...state[table].data];
      const row = [
        newTableData.find(r => {
          if (r[key.key] === key.value) {
            values.forEach(v => (r[v.key] = v.value));
            return true;
          }
          return false;
        }),
      ];

      const returnv = {
        ...state,
        [table]: {
          ...state[table],
          data: newTableData,
        },
      };

      return returnv;
    }
    case "SET_SELECTED_ROWS_FROM_VALUE": {
      const { table, column, value } = action.payload;
      const newState = { ...state };
      const selected = newState[table].data.reduce((acc, curr, idx) => {
        if (curr[column] === value) {
          acc[idx] = true;
        }
        return acc;
      }, {});
      newState[table].selected = selected;
      newState[table].internalSelectedRows = selected;
      return newState;
    }
    case "DESELECT_ALL_ROWS": {
      const { table } = action.payload;
      return {
        ...state,
        [table]: {
          ...state[table],
          selected: {},
          internalSelectedRows: {},
        },
      };
    }
    case "SET_TABLE_DATA": {
      const { table, data } = action.payload;
      const newState = { ...state };

      let dataToSet = data;
      if (!Array.isArray(data)) {
        dataToSet = Object.entries(data).map(([k, v]) => {
          return v;
        });
      }

      newState[table] = {
        ...newState[table],
        data: dataToSet.slice(0, state[table].maxTableSize) || [],
        allTheData: dataToSet || [],
        isLoading: false,
        total: (dataToSet && dataToSet.length) || "0",
      };

      return newState;
    }
    case "SET_TABLE_DATA_KEEP_SELECTED": {
      const { table, data, selectByColumn } = action.payload;
      const selectedIndices = Object.keys(state[table].selected);
      const selectedValues = [];
      selectedIndices.forEach(index => {
        if (state[table].data && state[table].data[index]) {
          selectedValues.push(state[table].data[index][selectByColumn]);
        }
      });

      const displayData = data.slice(0, state[table].maxTableSize) || [];
      const newIndices = state[table].selected;

      Object.keys(newIndices).forEach(key => delete newIndices[key]);

      displayData.forEach((row, index) => {
        if (selectedValues.includes(row[selectByColumn])) {
          newIndices[index] = true;
        }
      });

      const newState = { ...state };
      newState[table] = {
        ...newState[table],
        data: displayData,
        allTheData: data || [],
        isLoading: false,
        total: (data && data.length) || "0",
        selected: newIndices,
        internalSelectedRows: newIndices,
      };
      return newState;
    }
    case "SET_ALL_THE_DATA": {
      const { table, data } = action.payload;
      const newState = { ...state };
      newState[table] = {
        ...newState[table],
        allTheData: data || [],
        total: (data && data.length) || "0",
      };
      return newState;
    }
    case "SET_DATA_EMPTY": {
      const { table } = action.payload;
      return {
        ...state,
        [table]: {
          ...state[table],
          data: [],
          allTheData: [],
          total: 0,
        },
      };
    }
    case "SET_IS_EXPORT_DISABLED": {
      const { table, isDisabled } = action.payload;
      const newState = { ...state };
      newState[table] = {
        ...newState[table],
        isExportDisabled: isDisabled,
      };
      return newState;
    }
    case "SET_COLUMN": {
      const { table, rowId, column, data } = action.payload;
      const newState = { ...state };
      const row = newState[table].data.find(r => r.id === rowId);

      if (row) {
        row[column] = data;
      }

      return newState;
    }
    case "SET_LOADING": {
      const { table } = action.payload;
      const tableName = typeof table === "object" ? table.key : table;

      const a = {
        ...state,
        [tableName]: { ...state[tableName], isLoading: true },
      };

      return a;
    }
    case "SET_NOT_LOADING": {
      const { table } = action.payload;

      return {
        ...state,
        [table]: { ...state[table], isLoading: false },
      };
    }
    case "HANDLE_ROWS_SELECTED": {
      const { table, rows, isSingleSelect } = action.payload;
      const newState = { ...state };
      let newRows = {};
      const rowKeys = Object.entries(rows);

      // make sure we have only one row selected
      if (isSingleSelect) {
        if (rowKeys.length === 1) {
          newRows = rows;
        } else {
          Object.entries(rows).forEach(([k, v]) => {
            if (Object.entries(newRows).length === 0 && v === true && !state[table].selected[k]) {
              newRows[k] = v;
            } else {
              newRows[k] = false;
            }
          });
        }
      } else {
        // this has to be the same object for performance reasons
        Object.keys(state[table].selected).forEach(k => delete state[table].selected[k]);
        Object.keys(rows).forEach(k => {
          state[table].selected[k] = true;
        });
        newRows = state[table].selected;
      }
      newState[table] = {
        ...newState[table],
        selected: newRows,
        internalSelectedRows: newRows,
      };

      return newState;
    }
    case "CLEAR_SELECTED": {
      const { table } = action.payload;
      return {
        ...state,
        [table]: { ...state[table], selected: {}, internalSelectedRows: {} },
      };
    }
    case "SET_CURRENT_COLUMNS": {
      const { table, columns } = action.payload;
      const newState = { ...state };

      newState[table].hiddenColumns = columns || [];

      deriveVisibleColumns(newState[table].allColumns, columns || []);

      return newState;
    }

    case "ADD_TO_CURRENT_COLUMNS": {
      const { table, id } = action.payload;
      const newState = { ...state };

      const found = state[table].hiddenColumns.findIndex(c => c === id);
      if (found !== -1) {
        newState[table].hiddenColumns.splice(found, 1);
        const i = newState[table].allColumns.findIndex(c => c.id === id);
        newState[table].allColumns[i].isVisible = true;
      } else {
        newState[table].hiddenColumns.push(id);
        const i = newState[table].allColumns.findIndex(c => c.id === id);
        newState[table].allColumns[i].isVisible = false;
      }
      newState[table].hiddenColumns = [...newState[table].hiddenColumns];
      return newState;
    }
    case "SET_CURRENT_COLUMNS_ORDER": {
      const { table, columns } = action.payload;
      // this filtering is used in several places within NEF, so we duplicate here
      // to filter out these pseudo-rows
      const filteredColumns = columns.filter(column => {
        return (
          column !== "row-selection" &&
          column !== "row-expander" &&
          column !== "row-indicator" &&
          !column?.hideHeader
        );
      });
      const newState = { ...state };
      newState[table].columnsOrder = filteredColumns;
      return newState;
    }
    case "WRITE_COLUMN_SET": {
      const { table, columnSetName } = action.payload;
      const newState = { ...state };
      newState[table].columnSets = { ...newState[table].columnSets };
      newState[table].columnSets[columnSetName] = {
        hidden: newState[table].hiddenColumns || [],
        order: newState[table].columnsOrder || [],
        tainted: false,
      };

      const index = newState[table].columnSavingList.indexOf(columnSetName);
      if (index !== -1) {
        newState[table].columnSavingList.splice(index, 1);
      }
      return newState;
    }
    case "SET_COLUMN_SAVING": {
      const { table, column, isSaving } = action.payload;
      const newState = { ...state };
      const columnSavingList = [...newState[table].columnSavingList];
      const index = columnSavingList.indexOf(column);
      if (isSaving && index === -1) {
        columnSavingList.push(column);
      } else if (!isSaving && index !== -1) {
        columnSavingList.splice(index, 1);
      }
      return { ...state, [table]: { ...newState[table], columnSavingList } };
    }
    case "LOAD_DEFAULT_COLUMN_SET": {
      const { table } = action.payload;

      const newState = { ...state };
      newState[table] = {
        ...newState[table],
        currentColumnSet: STANDARD_TABLE_DEFAULT_COLUMN_SET,
        hiddenColumns: defaultState[table]?.hiddenColumns || [],
        columnsOrder: getDefaultColumnsOrder(table),
      };

      deriveVisibleColumns(newState[table].allColumns, defaultState[table].hiddenColumns);

      return newState;
    }
    case "WRITE_COLUMN_SET_DEFAULT": {
      const { table, columnSetName } = action.payload;

      return { ...state, [table]: { ...state[table], columnSetDefault: columnSetName } };
    }
    case "LOAD_COLUMN_SET": {
      const { table, columnSetName } = action.payload;
      const newState = { ...state };

      if (newState[table].columnSets[columnSetName].tainted === true) {
        NotificationHub.send("warning", "Saved Table Column Set partial load", {
          subtitle:
            "The requested Saved Table Column Set did not load in its entirety. Please review the loaded result. You can save over the Column Set, delete it, or create a new one",
        });
      }

      newState[table].currentColumnSet = columnSetName;
      newState[table].hiddenColumns = state[table].columnSets[columnSetName].hidden || [];
      let columnsOrder = state[table].columnSets[columnSetName].order;

      if (!columnsOrder) {
        columnsOrder = state[table].columnSets[columnSetName].visible?.map(col => col.id);
      }
      newState[table].columnsOrder = columnsOrder || [];
      deriveVisibleColumns(newState[table].allColumns, newState[table].hiddenColumns);

      return newState;
    }
    case "REMOVE_COLUMN_SET": {
      const { table, columnSetName } = action.payload;
      const newState = { ...state };
      if (newState[table].currentColumnSet === columnSetName) {
        newState[table].currentColumnSet = undefined;
      }
      delete newState[table].columnSets[columnSetName];
      return newState;
    }
    case "SET_SOMEONE_ASKED_THAT_WE_REFRESH": {
      const { table, ask } = action.payload;
      const a = { ...state, [table]: { ...state[table], someOneAskedThatWeRefresh: ask } };
      return a;
    }

    case "SET_SORT_DATA": {
      const { data, table } = action.payload;
      return { ...state, [table]: { ...state[table], sortData: data } };
    }

    case "SET_CLICKED_ROW": {
      const { table } = action.payload;

      return { ...state, [table]: { ...state[table], clickedRow: action.payload.row } };
    }

    case "SET_LAST_QUERY_DATE": {
      const { table, lastQueryDate } = action.payload;
      return {
        ...state,
        [table]: { ...state[table], lastQueryDate: lastQueryDate || new moment() },
      };
    }
    case "SET_DISABLED_ROWS": {
      const { table, disabledRows } = action.payload;

      return {
        ...state,
        [table]: { ...state[table], disabledRows },
      };
    }
    case "SET_EXPANDED_ROWS": {
      const { table, rows } = action.payload;

      return {
        ...state,
        [table]: { ...state[table], expandedRows: rows },
      };
    }
    default:
      return { ...state };
  }
};

const StandardTableProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.userData]
    ) {
      dispatchF({
        type: "DUMP_SETTINGS",
        payload: { userData: user[INITIAL_DATA_MODEL.userData] },
      });
    } else if (user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.error) {
      dispatchF({ type: "DISABLE_COLUMN_SAVING", payload: true });
    }
  }, [user]);

  useEffect(() => {
    dispatchF({
      type: "ADD_SELF",
      payload: dispatchF,
    });
  }, []);

  return (
    <standardTableDispatch.Provider value={dispatchF}>
      <standardTableContext.Provider value={[state, dispatchF]}>
        {children}
      </standardTableContext.Provider>
    </standardTableDispatch.Provider>
  );
};

export default StandardTableProvider;

export const getSelectedRow = tableData => {
  const index = Object.keys(tableData.selected).find(([k, v]) => k);
  return tableData.data[index * 1];
};

export const getSelectedRows = tableData => {
  const selectedIndices = Object.keys(tableData.selected).reduce((acc, curr) => {
    if (tableData.selected[curr]) {
      acc.push(curr);
    }
    return acc;
  }, []);
  const rows = selectedIndices.map(idx => tableData.data[idx]);
  return rows;
};

export const getNumSelectedRows = tableData => {
  return Object.keys(tableData.selected).length;
};

const getReversalFlagValue = reversalFlag => {
  switch (reversalFlag) {
    case "K":
    case "Reversal K":
      return "K";
    case "R":
    case "Reversal R":
      return "R";
    default:
      return "";
  }
};

export const convertTableToForm = (originalRow, user) => {
  const row = { ...originalRow };
  const newRow = {};
  const { tradeStatus, rejectText } = row;
  const reversalFlag = getReversalFlagValue(row[ApiResponseNames.reversalFlag]);

  // build a dto based on a table row but formatted for server consumption
  Object.entries(row).forEach(([key, value]) => {
    let fieldName = ApiToFieldMap[key];
    if (fieldName) {
      if (typeof fieldName === "function") {
        fieldName = fieldName(row);
      }
      newRow[fieldName] = value;
    }
  });

  let price = "",
    contractAmt = "";
  if (row[ApiResponseNames.priceTradeDigit] === PriceTradeDigit.CONTRACT) {
    contractAmt = row[ApiResponseNames.price];
  } else {
    price = row[ApiResponseNames.price];
  }

  const sellerDays = parseInt(row[ApiResponseNames.sellerDays]);
  Object.assign(newRow, {
    tradeStatus,
    [FieldNames.price]: price,
    [FieldNames.contract]: contractAmt,
    [FieldNames.priceTradeDigit]: row[ApiResponseNames.priceTradeDigit],
    priceOverride: row.priceOverride === "O" ? true : false,
    ...(rejectText && {
      rejectText: (
        <>
          <p>Please correct these errors:</p> <p>{rejectText}</p>
        </>
      ),
    }),
    modifier2: row.entryModifier2,
    modifier3: row.entryModifier3,
    modifier4: row.entryModifier4,
    [FieldNames.reversalOriginalControlNum]: row.originalControlNum,
    [FieldNames.reversalOriginalControlDate]: row.originalControlDate,
    [FieldNames.reversalReferenceReportingVenue]: row.referenceReportingVenue,
    [FieldNames.reversalFlag]: reversalFlag,
    [FieldNames.settlement]:
      SettlementOptions[
        translateSettlementWithT1RegularFlag(
          row[ApiResponseNames.settlement],
          sellerDays,
          user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.t1RegularSettlement]
        )
      ],
    [FieldNames.sellerDays]: sellerDays,
  });
  return newRow;
};
