import { Box, EmptyState, Flex, Tooltip } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { getColor } from "@nef/style-utils";
import {
  calculateRiskPercent,
  getAggProgressAttr,
  getLimoProgressPropsFromPercents,
  SELECT_ALL,
} from "wksConstants";
import { useRightContext } from ".";
import { ApiResponseNames, FieldLoop, FieldNames, rightLimit, SelectOptions } from "../fields";
import { LimoProgress, useAggregateLimitDispatch, useAggregateLimitContext } from "../limitMonitor";
import { getRiskAggregatesKey } from "../limitMonitor/constants";
import { useRightDispatch } from "./context";
import { Status } from "../../wksConstants";
import { messages } from "../../viewConstants";
import styled from "styled-components";
import { abbreviateNumber } from "utils/js.utils";

const FlexMPID = styled(Flex)`
  height: 100%;
  width: 15%;

  & > div {
    font-family: Rubik;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    border-radius: 5.01353px;
  }
`;

const FlexBuySell = styled(Flex)`
  height: 50px;
  width: 33%;
  cursor: default;
  .label {
    font-family: Roboto;
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.785714px;
    text-transform: uppercase;
    border-radius: 2.22028px;
  }
  .value {
    font-family: Roboto;
    font-style: normal;
    font-size: 11px;
    line-height: 17px;

    border-radius: 2.82581px;
  }
`;

const SpanLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.785714px;
  text-transform: uppercase;
  border-radius: 2.22028px;
`;

const SpanValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-size: 11px;
  line-height: 17px;

  border-radius: 2.82581px;
`;

const FlexProgress = styled(Flex)`
  width: 45%;
  padding: 2px 0;
  & > div {
    width: 100%;
    margin-bottom: 2px;
  }
`;

export const RightLimit = memo(() => {
  const [formData] = useFormContext();
  const [user] = useUserContext();
  const rightDispatch = useRightDispatch();
  const previousMPIDs = useRef();
  const aggregatesDispatch = useAggregateLimitDispatch();
  const [userDataLoaded] = useState(false);

  useEffect(() => {
    return () => {
      aggregatesDispatch({ type: "SET_RIGHT_POLLING", payload: false });
    };
  }, [aggregatesDispatch]);

  const noop = useCallback(() => {}, []);
  const mpidsJSON = JSON.stringify(formData[Forms.RIGHT_LIMIT.key].fields[FieldNames.mpid] || []);

  useEffect(() => {
    if (previousMPIDs.current !== mpidsJSON) {
      const fields = formData[Forms.RIGHT_LIMIT.key].fields;

      const clearingRelationships = fields[FieldNames.mpid]
        ? fields[FieldNames.mpid].reduce((acc, curr) => {
            const option = JSON.parse(curr.value);
            const key = getRiskAggregatesKey(option.correspondentMPID, option.clearingNum);
            acc[key] = true;
            return acc;
          }, {})
        : {};

      aggregatesDispatch({
        type: "SET_RIGHT_POLLING",
        payload: Object.keys(clearingRelationships).length > 0,
      });

      rightDispatch({
        type: "SET_CLEARING_RELATIONSHIPS",
        payload: {
          relationships: clearingRelationships,
          [FieldNames.mpid]: JSON.stringify({
            [FieldNames.mpid]: [...(formData[Forms.RIGHT_LIMIT.key].fields[FieldNames.mpid] || [])],
          }),
        },
      });
    }
  }, [mpidsJSON, user.username, noop, formData, rightDispatch, userDataLoaded, aggregatesDispatch]);

  const persistLimitData = useCallback(
    newMPIDs => {
      const options = SelectOptions[FieldNames.mpid](Forms.RIGHT_LIMIT.key);
      const numOptions = options.reduce((acc, curr) => {
        acc++;
        if (Array.isArray(curr.children)) {
          acc += curr.children.length;
        }
        return acc;
      }, 0);
      let selectAll = false;

      if (Array.isArray(newMPIDs.value)) {
        if (numOptions === newMPIDs.value.length) {
          selectAll = true;
        }
      }

      let data = selectAll
        ? JSON.stringify({ [FieldNames.mpid]: SELECT_ALL })
        : JSON.stringify({ [FieldNames.mpid]: newMPIDs.value });

      network().writeSettings(
        {
          data,
          name: "rightLimitMPIDS",
          type: "rightLimitMPIDS",
          user: user.username,
        },
        noop, // onSaveSuccess,
        noop // onSaveError
      );
    },
    [noop, user.username]
  );

  return (
    <>
      <FieldLoop
        form={Forms.RIGHT_LIMIT}
        fields={rightLimit}
        showLabel={false}
        augmentOnChange={persistLimitData}
      />
      <LimitInfo />
    </>
  );
});

const limitTypes = {
  Buy: "BUY",
  Sell: "SELL",
  Net: "NET",
};

const validateBreachProgress = (minValue, current, potential) => {
  return minValue < current || minValue < potential;
};

const getLimitsHaveBreachProgress = aggregateDto => {
  const isAlertActive = aggregateDto.riskConfig[ApiResponseNames.isAlertActive];
  const isHoldActive = aggregateDto.riskConfig[ApiResponseNames.isHoldActive];
  const isRejectActive = aggregateDto.riskConfig[ApiResponseNames.isRejectActive];
  const breachProgress = {
    [limitTypes.Buy]: false,
    [limitTypes.Sell]: false,
    [limitTypes.Net]: false,
  };
  if (isHoldActive || isRejectActive) {
    let minBuyValue = 0,
      minSellValue = 0,
      minNetValue = 0;
    if (isAlertActive) {
      minBuyValue = aggregateDto.riskConfig[ApiResponseNames.buyAlertLimit];
      minSellValue = aggregateDto.riskConfig[ApiResponseNames.sellAlertLimit];
      minNetValue = aggregateDto.riskConfig[ApiResponseNames.netAlertLimit];
    }
    if (aggregateDto.riskConfig[ApiResponseNames.useNetTrading]) {
      breachProgress[limitTypes.Net] = validateBreachProgress(
        minNetValue,
        aggregateDto[ApiResponseNames.totalNet],
        aggregateDto[ApiResponseNames.totalNetProforma]
      );
    } else {
      breachProgress[limitTypes.Buy] = validateBreachProgress(
        minBuyValue,
        aggregateDto[ApiResponseNames.totalBuy],
        aggregateDto[ApiResponseNames.totalBuyProforma]
      );
      breachProgress[limitTypes.Sell] = validateBreachProgress(
        minSellValue,
        aggregateDto[ApiResponseNames.totalSell],
        aggregateDto[ApiResponseNames.totalSellProforma]
      );
    }
  }
  return breachProgress;
};

const LimitInfo = memo(() => {
  const [data] = useRightContext();
  const [aggregateData] = useAggregateLimitContext();

  const viewData = useMemo(() => {
    const { breachProgress, visible } = Object.values(data.risk).reduce(
      (acc, curr) => {
        const limitsWithProgress = getLimitsHaveBreachProgress(curr);
        if (
          limitsWithProgress[limitTypes.Buy] ||
          limitsWithProgress[limitTypes.Sell] ||
          limitsWithProgress[limitTypes.Net]
        ) {
          acc.breachProgress[curr.riskConfig[ApiResponseNames.correspondentMPID]] =
            limitsWithProgress;
          acc.visible.push(curr);
        }
        return acc;
      },
      { breachProgress: {}, visible: [] }
    );
    return { breachProgress, visible: visible.sort(sorter) };
  }, [data.risk]);

  if (Object.keys(data.risk).length === 0 && data.initialRiskPayloadLoaded === false) {
    return (
      <Box paddingLeft={1} paddingRight={1}>
        Initializing Stream...
      </Box>
    );
  } else if (aggregateData.status === Status.ERROR) {
    return <EmptyState titleSize={3} emptyStateTitle={messages.streamDown}></EmptyState>;
  } else if (Object.keys(data.clearingRelationships).length === 0) {
    return (
      <EmptyState titleSize={3} emptyStateTitle={"No MPIDs Selected"}>
        Select an MPID to begin Limit Breach search.
      </EmptyState>
    );
  } else if (Object.keys(data.clearingRelationships).length > 0 && viewData.visible.length === 0) {
    return (
      <EmptyState
        titleSize={3}
        emptyStateTitle={"Selected MPIDs do not have Limit Breaches"}
      ></EmptyState>
    );
  }

  return (
    <>
      <Box paddingLeft={1} paddingRight={1}>
        {viewData.visible.length} of {Object.keys(data.risk).length} MPIDs Approaching a Limit
      </Box>

      {viewData.visible.map((aggregate, i) => (
        <LimitInfoItem
          i={i}
          aggregate={aggregate}
          limitsProgress={
            viewData.breachProgress[aggregate.riskConfig[ApiResponseNames.correspondentMPID]]
          }
          key={`limitInfo_${aggregate.riskConfig[ApiResponseNames.correspondentMPID]}`}
        />
      ))}
    </>
  );
});

const LimitInfoItem = memo(({ i, aggregate, limitsProgress }) => {
  const {
    value: buyValue,
    color: buyColor,
    kind: buyKind,
    limit: buyLimit,
    next: buyNextLimit,
  } = getAggProgressAttr(aggregate, "buy", true);
  const {
    value: sellValue,
    color: sellColor,
    kind: sellKind,
    limit: sellLimit,
    next: sellNextLimit,
  } = getAggProgressAttr(aggregate, "sell", true);

  const {
    value: netValue,
    color: netColor,
    kind: netKind,
    limit: netLimit,
    next: netNextLimit,
  } = getAggProgressAttr(aggregate, "net", true);

  const actualPercentsBuy = calculateRiskPercent(aggregate, "buy", false, true);
  const proformaPercentsBuy = calculateRiskPercent(aggregate, "buy", true, true);
  const actualPercentsSell = calculateRiskPercent(aggregate, "sell", false, true);
  const proformaPercentsSell = calculateRiskPercent(aggregate, "sell", true, true);
  const actualPercentsNet = calculateRiskPercent(aggregate, "net", false, true);
  const proformaPercentsNet = calculateRiskPercent(aggregate, "net", true, true);
  const alertActive = false;
  const holdActive = aggregate.riskConfig[ApiResponseNames.isHoldActive];
  const rejectActive = aggregate.riskConfig[ApiResponseNames.isRejectActive];

  const LimitWrapper = styled(Flex)`
    ${props => `
      padding: 0 4px;
      ${props.isEven ? `background-color: ${getColor("gray", 10)(props)}` : ""};
    `}
  `;

  return (
    <LimitWrapper
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      isEven={i % 2 === 0}
    >
      <FlexMPID flexDirection="column" justifyContent="center">
        <div>{aggregate.riskConfig[ApiResponseNames.correspondentMPID]}</div>
      </FlexMPID>

      <FlexBuySell flexDirection="column" justifyContent="center">
        {limitsProgress[limitTypes.Net] && (
          <BuyOrSell
            label="Net:"
            color={netColor}
            value={aggregate[ApiResponseNames.totalNetProforma]}
            percent={netValue}
            text={netKind[0]}
            target={`netTooltip_${aggregate.riskConfig[ApiResponseNames.correspondentMPID]}`}
            limit={netLimit}
            nextLimit={netNextLimit}
            rejectActive={rejectActive}
          />
        )}
        {limitsProgress[limitTypes.Buy] && (
          <BuyOrSell
            label="Buy:"
            value={aggregate[ApiResponseNames.totalBuyProforma]}
            percent={buyValue}
            color={buyColor}
            text={buyKind[0]}
            target={`buyTooltip_${aggregate.riskConfig[ApiResponseNames.correspondentMPID]}`}
            limit={buyLimit}
            nextLimit={buyNextLimit}
            rejectActive={rejectActive}
          />
        )}
        {limitsProgress[limitTypes.Sell] && (
          <BuyOrSell
            label="Sell:"
            color={sellColor}
            value={aggregate[ApiResponseNames.totalSellProforma]}
            percent={sellValue}
            text={sellKind[0]}
            target={`sellTooltip_${aggregate.riskConfig[ApiResponseNames.correspondentMPID]}`}
            limit={sellLimit}
            nextLimit={sellNextLimit}
            rejectActive={rejectActive}
          />
        )}
      </FlexBuySell>

      <FlexProgress flexDirection="column" justifyContent="center">
        {limitsProgress[limitTypes.Buy] && (
          <LimoProgress
            id={`buyright${aggregate.id}`}
            {...getLimoProgressPropsFromPercents(
              actualPercentsBuy,
              proformaPercentsBuy,
              alertActive,
              holdActive,
              rejectActive,
              "Buy"
            )}
            hideAlert={true}
          />
        )}
        {limitsProgress[limitTypes.Sell] && (
          <LimoProgress
            id={`sellright${aggregate.id}`}
            {...getLimoProgressPropsFromPercents(
              actualPercentsSell,
              proformaPercentsSell,
              alertActive,
              holdActive,
              rejectActive,
              "Sell"
            )}
            hideAlert={true}
          />
        )}
        {limitsProgress[limitTypes.Net] && (
          <LimoProgress
            id={`netright${aggregate.id}`}
            {...getLimoProgressPropsFromPercents(
              actualPercentsNet,
              proformaPercentsNet,
              alertActive,
              holdActive,
              rejectActive,
              "Net"
            )}
            hideAlert={true}
          />
        )}
      </FlexProgress>
    </LimitWrapper>
  );
});
const BuyOrSell = memo(
  ({ value, percent, color, limit, nextLimit, text, label, target, rejectActive }) => {
    const [isOpen, setOpenKill] = useState(false);
    return (
      <>
        <div
          onMouseOver={() => {
            setOpenKill(!isOpen);
          }}
          onMouseOut={() => {
            setOpenKill(!isOpen);
          }}
          id={target}
        >
          <SpanLabel>{label} </SpanLabel>
          <SpanValue>{abbreviateNumber(value)}</SpanValue>
        </div>

        <Tooltip isOpen={isOpen} target={target}>
          <p>
            <b>{Math.floor(percent)}%</b> to {text === "h" ? "Hold" : "Kill"} Limit of{" "}
            {abbreviateNumber(limit)}
          </p>
          {text === "h" && rejectActive ? <p>Kill Limit: {abbreviateNumber(nextLimit)}</p> : null}
        </Tooltip>
      </>
    );
  }
);

const sortPercents = (a, b) => {
  let ret = 0;
  const kinds = {
    primary: 0,
    warning: 1,
    danger: 2,
  };
  if (kinds[a.color] > kinds[b.color]) {
    ret = -1;
  } else if (kinds[a.color] < kinds[b.color]) {
    ret = 1;
  } else {
    if (a.value > b.value) {
      ret = -1;
    } else if (a.value < b.value) {
      ret = 1;
    } else {
      ret = 0;
    }
  }
  return ret;
};

const sorter = (a, b) => {
  let aPercents = [];
  if (a.riskConfig.useNetTrading) {
    aPercents.push(getAggProgressAttr(a, "net", true));
  } else {
    aPercents.push(getAggProgressAttr(a, "buy", true));
    aPercents.push(getAggProgressAttr(a, "sell", true));
    aPercents.sort(sortPercents);
  }

  let bPercents = [];
  if (b.riskConfig.useNetTrading) {
    bPercents.push(getAggProgressAttr(b, "net", true));
  } else {
    bPercents.push(getAggProgressAttr(b, "buy", true));
    bPercents.push(getAggProgressAttr(b, "sell", true));
    bPercents.sort(sortPercents);
  }

  const order = sortPercents(aPercents[0], bPercents[0]);
  if (order !== 0) {
    return order;
  } else {
    if (aPercents[1] && bPercents[1]) {
      return sortPercents(aPercents[1], bPercents[1]);
    } else return bPercents[1] ? 1 : -1;
  }
};
