import React from "react";
import { Section, SectionBody, SectionHeader } from "@nef/core";
import { useUserContext } from "./context";
import styled from "styled-components";

const StyledSection = styled(Section)`
  height: auto;
  width: 550px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const FourOhNine = () => {
  const [user] = useUserContext();

  return (
    <StyledSection>
      <SectionHeader title="409 - User Misconfiguration" />
      <SectionBody>
        {user.hasRolesMisconfigured && (
          <>
            <span>
              Please report this issue to client services and have them resolve the following role
              conflict(s) between:
            </span>
            <ul>
              {user.misconfiguredRoles?.map(roles => (
                <li key={roles.join("-")}>{roles.join(" & ")}</li>
              ))}
            </ul>
          </>
        )}
        {user.hasMissingRoleDeps && (
          <>
            <span>
              Please report this issue to client services and have them resolve the following role
              dependency for:
            </span>
            <ul>
              {Object.entries(user.rolesWithMissingDependencies)?.map(([role, deps]) => (
                <li key={role}>
                  {role}
                  <ul>
                    {Object.values(deps).map(depList => (
                      <li key={`${role}${depList.join("_")}`}>{depList.join(" or ")}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
      </SectionBody>
    </StyledSection>
  );
};

export default FourOhNine;
