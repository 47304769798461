import React from "react";
import { CSSGrid } from "@nef/core";
import Middle from "components/middle";
import { NarrowBarTop, Charts, NarrowBarMiddle, Table } from ".";
import { RecapProvider, TradeDetailRecap, useRecapContext } from "../tradeDetailRecap";

export const StatsMiddleInner = () => {
  const [recapData] = useRecapContext();

  return (
    <Middle>
      <CSSGrid
        cols="100%"
        rows="min-content max(200px,calc(50vh - 212px)) min-content auto"
        gap=".85rem"
        className="stats_middle"
      >
        <>
          <NarrowBarTop />
          <Charts />
          <NarrowBarMiddle />
          <Table />
        </>
      </CSSGrid>

      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};

export const StatsMiddle = () => {
  return (
    <RecapProvider>
      <StatsMiddleInner />
    </RecapProvider>
  );
};
