import { FontAwesomeIcon, Link, Tooltip } from "@nef/core";
import { FormLayoutSaveDropdown } from "components/form";
import React, { useCallback, useReducer, useMemo, useState } from "react";
import { useUserContext } from "../user";
import { colors } from "../../colors";
import { useFormLayoutContext } from "./formLayoutContext";
import { LoadingIcon } from "components/loadingIcon";

const defaultState = {
  showMenu: false,
  saveAs: "",
  inputOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SHOW_MENU": {
      return { ...state, showMenu: action.payload };
    }
    case "SET_SAVE_AS": {
      return { ...state, saveAs: action.payload };
    }
    case "SET_INPUT_OPEN": {
      return { ...state, inputOpen: action.payload };
    }
    case "FINISH_SAVE": {
      return { ...state, saveAs: "", inputOpen: false };
    }
    default:
      return { ...state };
  }
};

export const FormLayoutSaveButton = ({ id, form }) => {
  const [{ showMenu, saveAs, inputOpen }, toolDispatch] = useReducer(reducer, defaultState);
  const [{ isUserDataLoading }] = useUserContext();
  const [formLayoutData] = useFormLayoutContext();
  const [isActive, setActive] = useState(false);

  const setShowMenu = useCallback(
    val => {
      if (!isUserDataLoading) {
        toolDispatch({
          type: "SET_SHOW_MENU",
          payload: val === undefined ? !showMenu : val,
        });
      }
    },
    [isUserDataLoading, showMenu]
  );

  const linkStyles = useMemo(() => {
    const style = {
      marginRight: "1rem",
      textDecoration: "none",
    };
    if (formLayoutData.isDefaultsSavingDisabled) {
      style.pointerEvents = "none";
      style.color = colors.disabled;
    }
    return style;
  }, [formLayoutData.isDefaultsSavingDisabled]);

  const toggleMenu = useCallback(() => {
    if (!showMenu) {
      setShowMenu(!showMenu);
    }
  }, [setShowMenu, showMenu]);

  return (
    <span onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
      <Link id={id} role="button" aria-haspopup="true" onClick={toggleMenu} style={linkStyles}>
        <LoadingIcon isLoading={isUserDataLoading}>
          <FontAwesomeIcon iconClassName={"fa-save"} />
        </LoadingIcon>
        <FormLayoutSaveDropdown
          form={form}
          target={id}
          showMenu={showMenu}
          saveAs={saveAs}
          inputOpen={inputOpen}
          toolDispatch={toolDispatch}
          layouts={{}}
          activeLayout={undefined}
          savingList={[]}
          maxNumLayout={5}
        />
        <Tooltip target={id} isOpen={isActive && formLayoutData.isDefaultsSavingDisabled}>
          Error retrieving user defaults. Please report the issue to WorkX Help Desk.
        </Tooltip>
      </Link>
    </span>
  );
};
