import { Progress, Tooltip } from "@nef/core";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useState } from "react";

const height = 14; //px
const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: ${height}px;
`;

const TriangleDown = styled.div`
  ${({ percent }) => `
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid black;
    left: ${Math.min(100, Math.max(0, percent))}%;
    margin-left: -7px;
    position: absolute;
    top: 0px;
  `}
`;

const TriangleUp = styled.div`
  ${({ percent }) => `
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid black;
    left: ${Math.min(100, Math.max(0, percent))}%;
    margin-left: -7px;
    position: absolute;
    bottom: 0px;
  `}
`;

export const LimoProgress = ({
  id,
  percentTop,
  percentBottom,
  tooltipTop,
  tooltipBottom,
  alertActive,
  holdActive,
  rejectActive,
  hideAlert,
}) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const idTop = useMemo(() => `${id}_top`, [id]);
  const idBottom = useMemo(() => `${id}_bottom`, [id]);
  const numActive = useMemo(
    () => alertActive + holdActive + rejectActive,
    [alertActive, holdActive, rejectActive]
  );

  const Indicators = useMemo(() => {
    return [
      <TriangleDown key="top" id={idTop} percent={percentTop} />,
      <TriangleUp key="bot" id={idBottom} percent={percentBottom} />,
    ];
  }, [idBottom, idTop, percentBottom, percentTop]);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Progress multi={true}>
        {alertActive && !hideAlert && (
          <Progress bar={true} color="primary" value={100 / numActive}>
            A
          </Progress>
        )}
        {holdActive && (
          <Progress
            bar={true}
            color="warning"
            value={100 / (numActive - (alertActive && hideAlert ? 1 : 0))}
          >
            H
          </Progress>
        )}
        {rejectActive && (
          <Progress
            bar={true}
            color="danger"
            value={100 / (numActive - (alertActive && hideAlert ? 1 : 0))}
          >
            K
          </Progress>
        )}
      </Progress>
      {Indicators}
      <Tooltip target={idTop} isOpen={isHovered} placement="top">
        {tooltipTop}
      </Tooltip>
      <Tooltip target={idBottom} isOpen={isHovered} placement="bottom">
        {tooltipBottom}
      </Tooltip>
    </Wrapper>
  );
};
