import { constructQueryPayload } from "./queries";
import { convertSampleAPIData } from "components/standardTable";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "../../utils/js.utils";

export const network = (userMpids = []) => ({
  stats: async (data, callback, errorCallback, signal) => {
    try {
      await doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_STATS_WS, "getStats"),
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(data),
          signal,
        },

        json => {
          callback(json, userMpids);
        },
        errorCallback
      );
    } catch {
      // errorCallback();
    }
  },
  rowsByQuery: async ({ chart, name, context, successCallback, errorCallback }) => {
    const payload = constructQueryPayload(chart, name, context);

    try {
      await doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_QUERY_WS, "tradesForStats"),
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },

        json => {
          successCallback(json);
        },
        errorCallback
      );
    } catch (e) {
      errorCallback(e);
    }
  },

  tradesForStats: async ({ statType, context, successCallback, errorCallback }) => {
    const payload = { statType, ...context };
    try {
      await doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_QUERY_WS, "tradesForStats"),
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },

        json => {
          successCallback(json, userMpids);
        },
        errorCallback
      );
    } catch (e) {
      errorCallback(e);
    }
  },
});
