import { Status } from "wksConstants";
import { BREACHES, CurrentGrossExposure, EXPOSURE_LEVELS } from "./eqrc.types";
import { PairedDataValue } from "@nef/chart-utils";
import { EXCHANGES } from "components/settings/eqrc/eqrcInputContext";

export interface BarItem {
  name?: string | undefined;
  value: number;
  displayValue?: string;
}

export interface StackedBar {
  name: string;
  values: BarItem[];
}

export interface TableRow {
  id: string;
  clientId: string;
  accountName: string;
  groupId: number;
  check: string;
  exchange: string;
  currentExposure: number;
  warn: number;
  watch: number;
  action: number;
}

export type SingleOrderMapDatum = {
  alertType: BREACHES;
  exchange: EXCHANGES;
  exposure: number;
  exposureLimit: number;
  groupID: number;
  level?: EXPOSURE_LEVELS;
  mpid: string;
  orderRefId: string;
  port: string;
  quantity: number;
  side: string;
  symbol: string;
  timestamp: number;
};

export type Option = { label: string; value: string; id?: string };

export type SingleOrderMapData = {
  offset: number;
  data: SingleOrderMapDatum[];
};

export type ExposureMapData = {
  [level: string]: {
    offset: number;
    data: any[];
  };
};

export type CurrentExposureData = {
  offset: number;
  data: CurrentGrossExposure;
};

export type SingleOrderMap = Map<string, SingleOrderMapData>;
export type ExposureMap = Map<string, ExposureMapData>;
export type CurrentExposureMap = Map<string, CurrentExposureData>;

export interface EqrcCacheState {
  singleOrder: SingleOrderMap;
  exposure: ExposureMap;
  currentExposure: CurrentExposureMap;
  status: { table: XHRStatus; charts: XHRStatus };
  isWindowFocused: boolean;
  shouldAbort: boolean;
  tableData: { [key: string]: TableRow };
  exchangeRequestStatuses: boolean[];
  counter: number;
  isDetailsOpen: boolean;
  activeDetails: string[];
  detailsTablesRows: { [alertType: string]: SingleOrderMapDatum[] };
  detailsTitle: string;
}

type XHRStatus = {
  isRequesting: boolean;
  shouldRequest: boolean;
  shouldMakeFirstRequest: boolean;
  feedStatus: (typeof Status)[keyof typeof Status];
  timeout: ReturnType<typeof setTimeout> | undefined;
  currentUsedCacheKeys: string[] | null;
  cacheKeysAsObj: { [alertType: string]: string[] };
};

export const defaultEqrcCacheState: EqrcCacheState = {
  singleOrder: new Map<string, SingleOrderMapData>(),
  exposure: new Map<string, ExposureMapData>(),
  currentExposure: new Map<string, CurrentExposureData>(),
  tableData: {},
  status: {
    charts: {
      isRequesting: false,
      shouldRequest: false,
      shouldMakeFirstRequest: true,
      feedStatus: Status.NO_STATUS,
      timeout: undefined,
      currentUsedCacheKeys: null,
      cacheKeysAsObj: {},
    },
    table: {
      isRequesting: false,
      shouldRequest: false,
      shouldMakeFirstRequest: true,
      feedStatus: Status.NO_STATUS,
      timeout: undefined,
      currentUsedCacheKeys: null,
      cacheKeysAsObj: {},
    },
  },
  isWindowFocused: false,
  shouldAbort: false,
  exchangeRequestStatuses: [],
  counter: 0,
  isDetailsOpen: false,
  activeDetails: [],
  detailsTablesRows: {},
  detailsTitle: "",
};
