import React, { useCallback, useEffect, useMemo } from "react";
import { NotificationHub } from "@nef/core";
import { ActiveForm, EqrcRuleStatus } from ".";
import { EqrcFields, getEqrcIdFromResponse, getRowValuesForAction } from "./constants";
import {
  useStandardTableContext,
  useStandardTableDispatch,
  getSelectedRows,
  getFormValidation,
} from "components/standardTable";
import { useFormContext, useFormDispatch } from "../../form";
import { StandardTables } from "wksConstants";
import { updateRule } from "./network";
import { Forms } from "components/fields/fieldConstants";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";

export const EQRCEditAlertForm = ({
  table,
  header,
  isOpen,
  onClose,
  ruleType,
  form,
  fieldSets,
}) => {
  const [tableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const handleCreate = useCallback(
    (table, json) => {
      const eqrcId = getEqrcIdFromResponse(json);
      json[EqrcFields.eqrcId] = eqrcId;

      onClose();
      formDispatch({ type: "RESET_FORM", payload: { form: Forms.EQRC_EDIT_ALERT } });

      let data = { [EqrcFields.eqrcId]: eqrcId, [EqrcRuleStatus.active]: json };

      if (table !== StandardTables.EQRC_ALERT_CONFIG) {
        data = { [EqrcFields.eqrcId]: eqrcId, [json[EqrcFields.status]]: json };
      }

      standardTableDispatch([
        {
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: { table: table, row: data, idField: EqrcFields.eqrcId },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: table },
        },
      ]);

      NotificationHub.send("success", "Alert configuration has been updated");
    },
    [formDispatch, onClose, standardTableDispatch]
  );

  const handleCreateError = useCallback((table, error, cbArgs) => {
    const message = error?.message ? (
      error.message
    ) : (
      <ul>
        {Object.entries(error).map(([field, message]) => (
          <li key={`${field}_${message.replace(" ", "_")}`}>{`${field} ${message}`}</li>
        ))}
      </ul>
    );

    NotificationHub.send("danger", "Failed to create alert configuration", { subtitle: message });
  }, []);

  const createPutBody = useCallback((row, fields) => {
    let body = {};
    if (row?.[EqrcFields.mpid]) {
      body[EqrcFields.mpid] = row[EqrcFields.mpid];
    }
    if (row?.[EqrcFields.port]) {
      body[EqrcFields.port] = row[EqrcFields.port];
    }
    // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
    if (row.hasOwnProperty(EqrcFields.groupId)) {
      body[EqrcFields.groupId] = row[EqrcFields.groupId];
    }
    if (row?.[EqrcFields.exchange]) {
      body[EqrcFields.exchange] = row[EqrcFields.exchange];
    }
    body[EqrcFields.ruleType] = fields[EqrcFields.ruleType].value;

    body[EqrcFields.emailAddresses] = fields[EqrcFields.emailAddresses].map(email => email.value);

    body[EqrcFields.active] = fields[EqrcFields.active] ? true : false;

    return body;
  }, []);

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );
  const handleSave = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);
    if (!valid) {
      return;
    }

    let fields = formData[form.key].fields;
    const selected = getSelectedRows(tableData[table])[0];

    let body = createPutBody(getRowValuesForAction(selected), fields);

    updateRule(
      userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
      null,
      JSON.stringify(body),
      StandardTables.EQRC_ALERT_CONFIG,
      null,
      handleCreate,
      handleCreateError
    );
  }, [
    form,
    formData,
    formDispatch,
    tableData,
    table,
    createPutBody,
    userData,
    handleCreate,
    handleCreateError,
  ]);

  return (
    <ActiveForm
      table={table}
      header={header}
      form={Forms.EQRC_EDIT_ALERT}
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      includeExchMpidPort={true}
      disableMpidPort={true}
      validate={validate}
    />
  );
};
