import { CSSGrid } from "@nef/core";
import { Middle } from "components/middle";
import { EffectCallback, useEffect, useState } from "react";
import { useLayoutContext } from "components/context";
import { RejectsTable } from "./tables/rejects.table";
import { PorTable } from "./tables/por.table";
import { PORs } from "./tiles/por";
import { PVRs } from "./tiles/pvr";
import { PORProvider, PorOrPvr, usePORCacheContext } from "./context";

export const Monitor = () => {
  const [, layoutDispatch] = useLayoutContext();
  const { state, dispatch } = usePORCacheContext();

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const pendingOrAll = `${state[PorOrPvr.POR].allOrPending}`;
    const mpidOrSymbol = `${state[PorOrPvr.POR].mpidOrSymbol}`;
    if (state?.selected?.length > 0) {
      setTitle(`${pendingOrAll} POR for ${state.selected}`);
    } else {
      setTitle(`${pendingOrAll} POR`);
    }
  }, [state]);

  return (
    <>
      <Middle>
        <CSSGrid
          gap={"1rem"}
          rows={"40% calc(60% - 1rem)"}
          cols={"calc(50% - 1rem) calc(50% - 1rem)"}
        >
          <PORs setTitle={setTitle} />
          <PVRs setTitle={setTitle} />
          <PorTable title={title} />
          <RejectsTable
            isAllOrPending={state[PorOrPvr.POR].allOrPending}
            selected={state.selected}
          />
        </CSSGrid>
      </Middle>
    </>
  );
};

export const MonitorWrapper = () => {
  return (
    <PORProvider>
      <Monitor></Monitor>
    </PORProvider>
  );
};
