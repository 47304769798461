import React from "react";
import { FontAwesomeIcon } from "@nef/core";
import { getColor } from "@nef/style-utils";
import styled from "styled-components";
import { originatingFormKey } from "../../form/constants";
import { EqrcServerActionToRule } from "./types.consts";

export const OPPOSITE_ROW = "oppositeRow";

export const EQRC_SHARES_LOCATED_VALUES = {
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
  ENABLED_SSE: "ENABLED_SSE",
};

export const EQRC_SHARES_LOCATED_OPTIONS = {
  [EQRC_SHARES_LOCATED_VALUES.DISABLED]: {
    id: EQRC_SHARES_LOCATED_VALUES.DISABLED,
    value: EQRC_SHARES_LOCATED_VALUES.DISABLED,
    label: "Disabled",
  },
  [EQRC_SHARES_LOCATED_VALUES.ENABLED]: {
    id: EQRC_SHARES_LOCATED_VALUES.ENABLED,
    value: EQRC_SHARES_LOCATED_VALUES.ENABLED,
    label: "Enabled",
  },
};

export const EQRC_FIELD_PREFIX = "EQRC_";

export const EqrcFields = {
  //Shared
  eqrcId: "eqrcId",
  mpid: "mpid",
  port: "port",
  groupId: "groupId",
  exchange: "exchange",
  status: "status",

  //Short Sale/Restricted Stock
  symbols: "symbols",

  //Alert Configurations
  ruleType: "ruleType",
  emailAddresses: "emailAddresses",
  active: "active",

  //Fat Finger
  rangeOneAmt: "rangeOneAmt",
  rangeOnePct: "rangeOnePct",
  rangeTwoAmt: "rangeTwoAmt",
  rangeTwoPct: "rangeTwoPct",
  rangeThreeAmt: "rangeThreeAmt",
  rangeThreePct: "rangeThreePct",
  rangeFourAmt: "rangeFourAmt",
  rangeFourPct: "rangeFourPct",
  rangeFiveAmt: "rangeFiveAmt",
  rangeFivePct: "rangeFivePct",
  rangeSixAmt: "rangeSixAmt",
  rangeSixPct: "rangeSixPct",

  //ADV
  pctAdv: "pctAdv",
  minVolume: "minVolume",

  //Market Impact Check
  marketImpact: "marketImpactCheckEnabled",

  //Order Type
  isoOrders: "isoOrders",
  shortSaleOrders: "shortSaleOrders",
  nonMarketOrders: "nonAuctionMarketOrders",
  premarketTrading: "preMarketTrading",
  postMarketTrading: "postMarketTrading",
  shortSaleExempt: "shortSaleExempt",

  //Order Rate Thresholds
  duplicateMaxMessages: "duplicateMaxMessages",
  duplicateTimeWindowSize: "duplicateTimeWindowSize",
  portMaxMessages: "portMaxMessages",
  portTimeWindowSize: "portTimeWindowSize",
  symbolMaxMessages: "symbolMaxMessages",
  symbolTimeWindowSize: "symbolTimeWindowSize",

  //Gross Exposure
  openExposure: "openExposure",
  executedExposure: "executedExposure",
  execExposure: "execExposure",
  notionalExposure: "notionalExposure",
  exposureWatch: "watch",
  exposureWarn: "warn",
  exposureAction: "action",
  breachAction: "breachAction",

  // Audit / History Lookup
  dateRange: "dateRange",
  beginDate: "beginDate",
  endDate: "endDate",

  // Audit
  revision: "revision",
  revType: "revType",
  userName: "userName",

  // History
  alertType: "alertType",
  alertLevel: "level",
  timestamp: "timestamp",

  //Actions
  portAction: "portAction",
  cancelAction: "cancelAction",
  allRules: "allRules",
  refDate: "refDate",

  //Current Exposure table
  currentExposure: "currentExposure",
  watch: "watch",
  warn: "warn",
  action: "action",

  //P
  perOrderNotionalLimit: "perOrderNotionalLimit",
  perOrderSharesLimit: "perOrderSharesLimit",
  sharesLocatedSSE: "sharesLocatedSSE",
  sharesLocatedOption: "sharesLocatedOption",
  hasSharesLocateRule: "hasSharesLocateRule",
  hasBrokerList: "hasBrokerList",
  brokersListRule: "brokersListRule",
  brokers: "brokers",
  sharesLocateRule: "sharesLocateRule",
  pageSize: "pageSize",
  pageNumber: "pageNumber",
  allowBrokerList: "allowBrokerList",
};

export const EQRC_RULE_STATUS = {
  Active: "ACTIVE",
  Configured: "CONFIGURED",
};

export const getEqrcIdFromResponse = json => {
  return `${json[EqrcFields.mpid]}${json[EqrcFields.port]}${json[EqrcFields.groupId]}${
    json[EqrcFields.exchange]
  }${json[EqrcFields.ruleType]}`;
};

export const StyledConfigIcon = styled(FontAwesomeIcon)`
  ${props => `
    color: ${getColor("warning", 500)(props)};
  `}
`;

export const configuredIcon = <StyledConfigIcon iconClassName="fa-exclamation-triangle" />;

export const EqrcDoubleStatusContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto;
`;

export const getRowValuesForAction = row => {
  return row[EQRC_RULE_STATUS.Configured]
    ? row[EQRC_RULE_STATUS.Configured]
    : row[EQRC_RULE_STATUS.Active];
};

export const getPropertyFromArray = (row, properties, index) => {
  const property = properties[index];
  const curr = row?.[property];
  if (index < properties.length - 1) {
    return getPropertyFromArray(curr, properties, index + 1);
  } else {
    return curr;
  }
};

export const addGrossExposureFieldsFromTableRow = (row, fields) => {
  fields[`${EqrcFields.openExposure}${EqrcFields.exposureWatch}`] = `${
    row[EqrcFields.openExposure]?.[EqrcFields.exposureWatch]
  }`;
  fields[`${EqrcFields.openExposure}${EqrcFields.exposureWarn}`] = `${
    row[EqrcFields.openExposure]?.[EqrcFields.exposureWarn]
  }`;
  fields[`${EqrcFields.openExposure}${EqrcFields.exposureAction}`] = `${
    row[EqrcFields.openExposure]?.[EqrcFields.exposureAction]
  }`;
  fields[`${EqrcFields.executedExposure}${EqrcFields.exposureWatch}`] = `${
    row[EqrcFields.executedExposure]?.[EqrcFields.exposureWatch]
  }`;
  fields[`${EqrcFields.executedExposure}${EqrcFields.exposureWarn}`] = `${
    row[EqrcFields.executedExposure]?.[EqrcFields.exposureWarn]
  }`;
  fields[`${EqrcFields.executedExposure}${EqrcFields.exposureAction}`] = `${
    row[EqrcFields.executedExposure]?.[EqrcFields.exposureAction]
  }`;
  fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureWatch}`] = `${
    row[EqrcFields.notionalExposure]?.[EqrcFields.exposureWatch]
  }`;
  fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureWarn}`] = `${
    row[EqrcFields.notionalExposure]?.[EqrcFields.exposureWarn]
  }`;
  fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureAction}`] = `${
    row[EqrcFields.notionalExposure]?.[EqrcFields.exposureAction]
  }`;
};

export const covertTableRowToFormValues = (row, includeExchangeMpidPort, oppositeRow, status) => {
  const fields = {};
  let addGrossExposureFields = false;

  // If the opposite row is provided, figure out if broker list should be enabled
  if (oppositeRow && status === EQRC_RULE_STATUS.Active) {
    const theBool = row[EqrcFields.hasBrokerList];
    row[`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`] = theBool;
    fields[`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`] = theBool;
  } else {
    row[`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`] = true;
    fields[`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`] = true;
  }

  Object.entries(row).forEach(([key, rowVal]) => {
    if (key === EqrcFields.exchange || key === EqrcFields.mpid || key === EqrcFields.port) {
      if (includeExchangeMpidPort) {
        fields[key] = { label: rowVal, value: rowVal };
      }
    } else if (key === EqrcFields.sharesLocateRule) {
      switch (rowVal.sharesLocatedOption) {
        case EQRC_SHARES_LOCATED_VALUES.ENABLED: {
          fields[EqrcFields.sharesLocatedOption] =
            EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.ENABLED];
          break;
        }
        case EQRC_SHARES_LOCATED_VALUES.ENABLED_SSE: {
          fields[EqrcFields.sharesLocatedOption] =
            EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.ENABLED];
          fields[EqrcFields.sharesLocatedSSE] = true;
          break;
        }
        case EQRC_SHARES_LOCATED_VALUES.DISABLED: {
          fields[EqrcFields.sharesLocatedOption] =
            EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.DISABLED];
          break;
        }
        default: {
          console.warn("Invalid sharesLocatedOption provided from the table");
          break;
        }
      }
    } else if (key.includes(EqrcFields.breachAction) || key.includes(EqrcFields.groupId)) {
      fields[key] = { label: rowVal, value: rowVal };
    } else if (key.includes(EqrcFields.ruleType)) {
      fields[key] = { label: EqrcServerActionToRule[rowVal], value: rowVal };
    } else if (
      key.includes(EqrcFields.openExposure) ||
      key.includes(EqrcFields.notionalExposure) ||
      key.includes(EqrcFields.executedExposure)
    ) {
      addGrossExposureFields = true;
      return;
    } else if (key.includes(EqrcFields.symbols) || key.includes(EqrcFields.emailAddresses)) {
      fields[key] = rowVal.map(item => ({ label: item, value: item }));
    } else if (key.includes(originatingFormKey)) {
      return;
    } else if (key === EqrcFields.brokersListRule) {
      fields[EqrcFields.brokersListRule] = rowVal?.brokers?.map(item => ({
        label: item,
        value: item,
      }));
    } else {
      if (![undefined, null].includes(rowVal)) {
        fields[key] = typeof rowVal !== "boolean" ? rowVal.toString() : rowVal;
      }
    }
  });
  if (addGrossExposureFields) {
    addGrossExposureFieldsFromTableRow(row, fields);
  }
  return fields;
};
