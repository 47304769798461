import React, { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const DragContainer = styled.div`
  ${props => `
    background-color: ${
      props.isDragging ? getColor("primary", 500)(props) : getColor("gray", "0")(props)
    };
    user-select: none;
    padding: 0;
    margin: 0;
    position: relative;
  `}
`;

const DraggableItem = memo(({ children, id, index, showDragIcon }) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <DragContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          style={provided.draggableProps.style}
        >
          <div
            {...provided.dragHandleProps}
            style={{ position: "absolute", top: "20px", left: "90%", width: "14px" }}
          >
            {showDragIcon && <FontAwesomeIcon iconClassName="fa-bars" />}
          </div>
          {children}
        </DragContainer>
      )}
    </Draggable>
  );
});

export default DraggableItem;
