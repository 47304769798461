import { LIMIT_SIDE_BREACH_VALUES } from "components/fields";
import { PTRA_ALERT_TYPE, PTRA_CONFIGURATION_ENTITY } from "components/settings/ptra/fields";

export const PTRA_BREACH_CACHE_MODEL = {
  counterPartyRole: "counterPartyRole",
  tranId: "tranId",
  symbol: "symbol",
  limitValue: "limitValue",
  side: "side",
  alertTypes: "alertTypes",
  breachPartyRole: "breachPartyRole",
  quantity: "quantity",
  tradeDigit: "tradeDigit",
  notionalAmt: "notionalAmt",
  oldState: "oldState",
  breachPartyClearingNum: "breachPartyClearingNum",
  tranSource: "tranSource",
  counterPartyGU: "counterPartyGU",
  breachPartyMpid: "breachPartyMpid",
  counterPartyMpid: "counterPartyMpid",
  price: "price",
  counterPartyClearingNum: "counterPartyClearingNum",
  breachPartyGU: "breachPartyGU",
  soupSequence: "soupSequence",
  id: "id",
  newState: "newState",
  timestamp: "timestamp",
  streamTime: "streamTime",
};

export const PTRA_CONFIG_CACHE_MODEL = {
  alertTypes: "alertTypes",
  buyAlertLimit: "buyAlertLimit",
  buyAlertLimitChanged: "buyAlertLimitChanged",
  buyDefaultAction: "buyDefaultAction",
  buyDefaultActionChanged: "buyDefaultActionChanged",
  buyHoldLimit: "buyHoldLimit",
  buyHoldLimitChanged: "buyHoldLimitChanged",
  buyRejectLimit: "buyRejectLimit",
  buyRejectLimitChanged: "buyRejectLimitChanged",
  clearingFirmMPID: "clearingFirmMPID",
  clearingNumber: "clearingNumber",
  correspondentMPID: "correspondentMPID",
  id: "id",
  intradayNextDay: "intradayNextDay",
  isAlertActive: "isAlertActive",
  isAlertActiveChanged: "isAlertActiveChanged",
  isDefaultsUpdated: "isDefaultsUpdated",
  isHoldActive: "isHoldActive",
  isHoldActiveChanged: "isHoldActiveChanged",
  isMaxTradeActive: "isMaxTradeActive",
  isMaxTradeActiveChanged: "isMaxTradeActiveChanged",
  isPerTradeActive: "isPerTradeActive",
  isPerTradeActiveChanged: "isPerTradeActiveChanged",
  isRejectActive: "isRejectActive",
  isRejectActiveChanged: "isRejectActiveChanged",
  isT2EntryAllowed: "isT2EntryAllowed",
  lastUpdated: "lastUpdated",
  maxTradeLimit: "maxTradeLimit",
  maxTradeLimitChanged: "maxTradeLimitChanged",
  netAlertLimit: "netAlertLimit",
  netAlertLimitChanged: "netAlertLimitChanged",
  netHoldLimit: "netHoldLimit",
  netHoldLimitChanged: "netHoldLimitChanged",
  netRejectLimit: "netRejectLimit",
  netRejectLimitChanged: "netRejectLimitChanged",
  perTradeBuyAction: "perTradeBuyAction",
  perTradeBuyActionChanged: "perTradeBuyActionChanged",
  perTradeBuyLimit: "perTradeBuyLimit",
  perTradeBuyLimitChanged: "perTradeBuyLimitChanged",
  perTradeSellAction: "perTradeSellAction",
  perTradeSellActionChanged: "perTradeSellActionChanged",
  perTradeSellLimit: "perTradeSellLimit",
  perTradeSellLimitChanged: "perTradeSellLimitChanged",
  sellAlertLimit: "sellAlertLimit",
  sellAlertLimitChanged: "sellAlertLimitChanged",
  sellDefaultAction: "sellDefaultAction",
  sellDefaultActionChanged: "sellDefaultActionChanged",
  sellHoldLimit: "sellHoldLimit",
  sellHoldLimitChanged: "sellHoldLimitChanged",
  sellRejectLimit: "sellRejectLimit",
  sellRejectLimitChanged: "sellRejectLimitChanged",
  updateTrfLimits: "updateTrfLimits",
  useNetTrading: "useNetTrading",
  useNetTradingChanged: "useNetTradingChanged",
};

export const getHashKey = (mpid, clearingNum, limitBreachSide, giveUp, alertType) => {
  return `${mpid}|${clearingNum}|${limitBreachSide}|${giveUp}|${alertType}`;
};

export const getConfigHashKey = (alertType, correspondentMPID, intradayNextDay, clearingNum) => {
  return `${alertType}|${correspondentMPID}|${intradayNextDay}|${clearingNum}`;
};

export const getAlertIdentifier = (hashKey, alertConfigId) => {
  return `${hashKey}|${alertConfigId}`;
};

const getBreachAlertIdentifier = alert => {
  let hashKey;
  if (alert[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own) {
    hashKey = getHashKey(
      alert[PTRA_BREACH_CACHE_MODEL.breachPartyMpid],
      alert[PTRA_BREACH_CACHE_MODEL.breachPartyClearingNum],
      alert[PTRA_CONFIGURATION_ENTITY.limitBreachSide],
      alert[PTRA_BREACH_CACHE_MODEL.breachPartyGU],
      alert[PTRA_BREACH_CACHE_MODEL.alertTypes][0]
    );
  } else {
    hashKey = getHashKey(
      alert[PTRA_BREACH_CACHE_MODEL.counterPartyMpid],
      alert[PTRA_BREACH_CACHE_MODEL.counterPartyClearingNum],
      alert[PTRA_CONFIGURATION_ENTITY.limitBreachSide],
      alert[PTRA_BREACH_CACHE_MODEL.counterPartyGU],
      alert[PTRA_BREACH_CACHE_MODEL.alertTypes][0]
    );
  }
  return getAlertIdentifier(hashKey, alert[PTRA_BREACH_CACHE_MODEL.id]);
};

const getConfigAlertIdentifier = alert => {
  return getAlertIdentifier(
    getConfigHashKey(
      alert[PTRA_CONFIG_CACHE_MODEL.alertTypes][0],
      alert[PTRA_CONFIG_CACHE_MODEL.correspondentMPID],
      alert[PTRA_CONFIG_CACHE_MODEL.intradayNextDay],
      alert[PTRA_CONFIG_CACHE_MODEL.clearingNumber]
    ),
    alert[PTRA_CONFIG_CACHE_MODEL.id]
  );
};

export const getIdentifierFromAlert = alert => {
  const alertType = alert[PTRA_BREACH_CACHE_MODEL.alertTypes][0];
  switch (alertType) {
    case PTRA_ALERT_TYPE.MAX_TRADE:
    case PTRA_ALERT_TYPE.AGG_ALERT:
    case PTRA_ALERT_TYPE.AGG_HOLD:
    case PTRA_ALERT_TYPE.AGG_KILL:
    case PTRA_ALERT_TYPE.PER_TRADE_ALERT:
    case PTRA_ALERT_TYPE.KILL_LIMIT_REJECT:
    case PTRA_ALERT_TYPE.HELD_TRADE:
    case PTRA_ALERT_TYPE.PER_TRADE_HOLD:
      return getBreachAlertIdentifier(alert);
    case PTRA_ALERT_TYPE.SETTING_AGGREGATE:
    case PTRA_ALERT_TYPE.SETTING_PER_TRADE:
    case PTRA_ALERT_TYPE.SETTING_MAX_TRADE:
    case PTRA_ALERT_TYPE.SETTING_DEFAULT_ACTION:
      return getConfigAlertIdentifier(alert);
    default:
      console.error("Invalid alert type provided. Cannot  get identifier.");
      return `INVALID_ALERT_TYPE=${alertType}`;
  }
};
