import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useUserContext } from "components/user/context";
import { INITIAL_DATA_MODEL } from "components/user/initialDataModel";
import { RequestResult } from "wksConstants";
import { eqrcExchangeMpidPortForms, SelectOptions } from "components/fields/fieldConstants";
import { addPrefixToField } from "utils/js.utils";
import { EQRC_FIELD_PREFIX, EqrcFields } from "./constants";
import { Views } from "viewConstants";
import { DispatchAction, SelectOption } from "types";

interface EqrcParentMpid {
  parentMpid: string;
  mpids: string[];
}

interface EqrcMpid {
  mpid: string;
  ports: string[];
}

interface EqrcPort {
  port: string;
  mpids: string[];
  exchange: string;
}

interface EqrcRefData {
  parentMpids: EqrcParentMpid[];
  mpids: EqrcMpid[];
  ports: EqrcPort[];
}

interface EqrcMpidMapValue {
  exchanges: { [exchange: string]: SelectOption };
  ports: { [port: string]: SelectOption };
}

interface EqrcExchangeMapValue {
  mpids: { [mpid: string]: SelectOption };
  ports: { [port: string]: SelectOption };
}

interface EqrcPortMapValue {
  mpids: { [mpid: string]: SelectOption };
  exchanges: { [exchange: string]: SelectOption };
}

export interface EqrcInputContextState {
  mpids: { [mpid: string]: EqrcMpidMapValue };
  exchanges: { [exchange: string]: EqrcExchangeMapValue };
  ports: { [port: string]: EqrcPortMapValue };
  allMpidOptions: { [mpid: string]: SelectOption };
  allExchangeOptions: { [exchange: string]: SelectOption };
  allPortOptions: { [port: string]: SelectOption };
}

export enum EXCHANGES {
  BX = "BX",
  PSX = "PSX",
  ROUTED = "ROUTED",
  NASDAQ = "NASDAQ",
}

const defaultState: EqrcInputContextState = {
  mpids: {},
  exchanges: {},
  ports: {},
  allMpidOptions: {},
  allExchangeOptions: {
    [EXCHANGES.BX]: {
      id: EXCHANGES.BX,
      value: EXCHANGES.BX,
      label: EXCHANGES.BX,
    },
    [EXCHANGES.PSX]: {
      id: EXCHANGES.PSX,
      value: EXCHANGES.PSX,
      label: EXCHANGES.PSX,
    },
    [EXCHANGES.ROUTED]: {
      id: EXCHANGES.ROUTED,
      value: EXCHANGES.ROUTED,
      label: EXCHANGES.ROUTED,
    },
    [EXCHANGES.NASDAQ]: {
      id: EXCHANGES.NASDAQ,
      value: EXCHANGES.NASDAQ,
      label: EXCHANGES.NASDAQ,
    },
  },
  allPortOptions: {},
};

const eqrcInputDispatch = createContext<React.Dispatch<DispatchAction | DispatchAction[]>>(
  () => null
);
eqrcInputDispatch.displayName = "eqrcInputDispatch";
export const useEqrcInputDispatch = () => useContext(eqrcInputDispatch);

const eqrcInputContext = createContext<
  [EqrcInputContextState, React.Dispatch<DispatchAction | DispatchAction[]>]
>([defaultState, () => null]);
eqrcInputContext.displayName = "eqrcInputContext";

export const useEqrcInputContext = () => useContext(eqrcInputContext);

const DispatchFn = (state: EqrcInputContextState, actions: DispatchAction | DispatchAction[]) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (
  state: EqrcInputContextState,
  action: DispatchAction
): EqrcInputContextState => {
  switch (action.type) {
    case "SET_EQRC_DATA": {
      const { mpids: refDataMpids, ports: refDataPorts }: EqrcRefData = action.payload;

      const portToMpidsExchangesMap = refDataPorts.reduce((acc, curr) => {
        if (!acc.hasOwnProperty(curr.port)) {
          acc[curr.port] = {};
        }
        curr.mpids.forEach(mpid => {
          if (!acc[curr.port].hasOwnProperty(mpid)) {
            acc[curr.port][mpid] = new Set<string>();
          }
          acc[curr.port][mpid].add(curr.exchange);
        });
        return acc;
      }, {} as { [port: string]: { [mpid: string]: /* exchanges */ Set<string> } });

      const { mpids, exchanges, ports, allMpidOptions, allPortOptions }: EqrcInputContextState =
        refDataMpids.reduce(
          (acc, curr) => {
            acc.allMpidOptions[curr.mpid] = { id: curr.mpid, value: curr.mpid, label: curr.mpid };
            acc.mpids[curr.mpid] = {
              exchanges: state.allExchangeOptions,
              ports: {},
            };
            Object.values(state.allExchangeOptions).forEach(exchangeOption => {
              if (!acc.exchanges.hasOwnProperty(exchangeOption.value)) {
                acc.exchanges[exchangeOption.value] = { mpids: {}, ports: {} };
              }
              acc.exchanges[exchangeOption.value].mpids[curr.mpid] = acc.allMpidOptions[curr.mpid];
            });
            (curr.ports || []).forEach(currPort => {
              if (!acc.allMpidOptions.hasOwnProperty(curr.mpid)) {
                acc.allMpidOptions[curr.mpid] = {
                  id: curr.mpid,
                  value: curr.mpid,
                  label: curr.mpid,
                };
              }

              if (!acc.allPortOptions.hasOwnProperty(currPort)) {
                acc.allPortOptions[currPort] = { id: currPort, value: currPort, label: currPort };
              }

              acc.mpids[curr.mpid].ports[currPort] = acc.allPortOptions[currPort];

              portToMpidsExchangesMap[currPort][curr.mpid].forEach(exchange => {
                acc.exchanges[exchange].ports[currPort] = acc.allPortOptions[currPort];
                if (!acc.ports.hasOwnProperty(currPort)) {
                  acc.ports[currPort] = {
                    mpids: {},
                    exchanges: {},
                  };
                }
                acc.ports[currPort].mpids[curr.mpid] = acc.allMpidOptions[curr.mpid];
                if (!acc.ports[currPort].exchanges.hasOwnProperty(exchange)) {
                  acc.ports[currPort].exchanges[exchange] = state.allExchangeOptions[exchange];
                }
              });
            });
            return acc;
          },
          {
            mpids: {},
            exchanges: {},
            ports: {},
            allMpidOptions: {},
            allPortOptions: {},
          } as EqrcInputContextState
        );

      eqrcExchangeMpidPortForms.forEach(form => {
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)}${form.key}`] =
          () => Object.values(state.allExchangeOptions);
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)}${form.key}`] = () =>
          Object.values(allMpidOptions);
        SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)}${form.key}`] = () =>
          Object.values(allPortOptions);
      });

      return {
        ...state,
        mpids,
        exchanges,
        ports,
        allMpidOptions,
        allPortOptions,
      };
    }
    default:
      return { ...state };
  }
};

interface EqrcInputContextProps {
  children: React.ReactNode;
  defaultData?: EqrcInputContextState;
}

const EqrcInputProvider: React.FC<EqrcInputContextProps> = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    if (
      user.allowed.views[Views.EQRC_RULES] &&
      user[INITIAL_DATA_MODEL.eqrcDataResult] === RequestResult.success
    ) {
      dispatchF({
        type: "SET_EQRC_DATA",
        payload: user[INITIAL_DATA_MODEL.eqrcData],
      });
    }
  }, [user]);

  return (
    <eqrcInputDispatch.Provider value={dispatchF}>
      <eqrcInputContext.Provider value={[state, dispatchF]}>{children}</eqrcInputContext.Provider>
    </eqrcInputDispatch.Provider>
  );
};

export default EqrcInputProvider;
