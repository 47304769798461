import { CSSGrid } from "@nef/core";
import { PageLoader } from "components/content/pageLoader";
import Middle from "components/middle";
import { useUserContext } from "components/user";
import React, { useEffect, useMemo } from "react";
import { HeldTable, KilledTable, LimitMonitorSelects, SummaryTable } from ".";
import { useLayoutContext } from "../context";

const selectsHeight = "auto";
export const LimitMonitor = () => {
  const [user] = useUserContext();
  const [, layoutDispatch] = useLayoutContext();

  useEffect(() => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () =>
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
  }, [layoutDispatch]);

  if (user.isUserDataLoading) {
    return <PageLoader />;
  }

  return (
    <Middle>
      <CSSGrid cols="repeat(4, 1fr)" rows="30px 1fr 42vh" gap="1rem">
        <LimitMonitorSelects height={selectsHeight} />
        <SummaryTable />
        <HeldTable />
        <KilledTable />
      </CSSGrid>
    </Middle>
  );
};
