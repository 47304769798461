import React, { memo, useCallback, useMemo } from "react";
import { FontAwesomeIcon, Flex, Link, Header } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { colors } from "colors";
import { useLayoutContext } from "../context";
import { FormLayoutSaveButton } from "components/form";
import { useFormContext } from "../form";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const ExpandIconWrapper = styled.div`
  display: inline;

  &:hover i {
    text-shadow: 2px 2px 4px ${getColor("primary", 500)} !important;
  }
`;

const ExpandIcon = styled(FontAwesomeIcon)`
  color: ${props => getColor("primary", 500)(props)};
  width: unset;
  cursor: pointer !important;
`;

const FlexWrapper = styled(Flex)`
  border-bottom: 2px solid ${getColor("gray", 25)};
  padding: 0 0 7px 0;
  box-sizing: border-box;
  width: 100%;
`;

const IconWrapper = styled.div`
  border-right: 2px solid ${getColor("primary", 500)};
  margin-right: 1rem;
  display: flex;
`;

const OpenClose = ({ form, buttons, handleClearAll }) => {
  const [layoutData, layoutDispatch] = useLayoutContext();
  const [formData] = useFormContext();

  const controlNum = useMemo(() => {
    if (form) {
      return formData[form.key].fields.controlNum;
    }
    return "";
  }, [formData, form]);

  const handleClick = useCallback(() => {
    layoutDispatch({
      type: "TOGGLE_LEFT_SIDE",
    });
  }, [layoutDispatch]);

  const handleClickRevert = useCallback(() => {
    if (handleClearAll) {
      handleClearAll(form);
    }
  }, [handleClearAll, form]);

  return (
    <FlexWrapper flexDirection="row" justifyContent="space-between">
      <Header size="3">
        {form.label}
        {[Forms.TR_MODIFY].includes(form) && (
          <>
            <span
              style={{
                fontSize: "1.1rem",
                paddingLeft: ".5rem",
              }}
            >
              {controlNum}
            </span>
          </>
        )}
      </Header>
      <Flex>
        <IconWrapper>
          {
            // maybe this should be added to buttons
            handleClearAll && (
              <Link onClick={handleClickRevert} style={{ marginRight: "1rem" }} title="revert">
                <FontAwesomeIcon iconClassName={`fa-redo`} />
              </Link>
            )
          }
          {[
            Forms.TR_REPORT,
            Forms.TR_SCAN,
            Forms.QUERY,
            Forms.RD_AGU,
            Forms.RD_CLEARING,
            Forms.RD_CUSIP,
            Forms.ORDER_ENTRY,
          ].includes(form) && <FormLayoutSaveButton form={form} id={"formlayoutsavebutton"} />}
          {buttons}
        </IconWrapper>
        <ExpandIconWrapper
          role="button"
          onClick={handleClick}
          aria-pressed={layoutData.isLeftExpanded}
        >
          <ExpandIcon
            iconClassName={layoutData.isLeftExpanded ? "fa-chevron-left" : "fa-chevron-right"}
          />
          <ExpandIcon
            iconClassName={layoutData.isLeftExpanded ? "fa-chevron-left" : "fa-chevron-right"}
          />
        </ExpandIconWrapper>
      </Flex>
    </FlexWrapper>
  );
};

export default memo(OpenClose);
