import {
  Button,
  CSSGrid,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotificationHub,
} from "@nef/core";
import { LimitSettingForm } from "components/settings/limitSettingForm";
import React, { useCallback, useEffect, useReducer } from "react";
import styled from "styled-components";
import {
  ApiResponseNames,
  FieldNames,
  Forms,
  ptrNextDayCheckbox,
  PTR_HOLD_ACTION_OPTIONS,
  PTR_PER_TRADE_ACTION_OPTIONS,
  riskConfigMPIDs,
  SelectOptions,
} from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { getConvertedRiskLimitsForUI } from "./constants";
import { useFormContext, useFormDispatch } from "components/form";
import { AckTypes } from "wksConstants";
import { useRefDataContext } from "components/refData";
import { getDTOForPTRConfigSettings } from "components/limitMonitor/constants";
import { getFormValidation } from "components/standardTable";
import { ConfirmPopover } from "components/confirm";
import { DATA_SOURCE, RiskConfigurationDTO } from "./types";
import { SelectOption } from "types";
import { isFulfilled } from "components/eqrc/utils";
import { NOTIFICATION_COLOR } from "components/notification/constant";

const StyledModalBody = styled(ModalBody)`
  height: 90vh;
  overflow: scroll;
`;

type Action = {
  type: "SET_STATE";
  payload: {
    isLoading?: boolean;
    isSaveDisabled?: boolean;
    isPopoverOpen?: boolean;
    saveNextDay?: boolean;
  };
};

interface PtrIntradayLimitModalState {
  isLoading: boolean;
  isSaveDisabled: boolean;
  isPopoverOpen: boolean;
  saveNextDay: boolean;
}

const dispatchFn = (state: PtrIntradayLimitModalState, action: Action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const defaultState: PtrIntradayLimitModalState = {
  isLoading: true,
  isSaveDisabled: false,
  isPopoverOpen: false,
  saveNextDay: false,
};

interface PtrIntradayLimitModalProps {
  relationshipId: string;
  isVisible: boolean;
  onClose: () => void;
}

const sendSaveResultNotification = (
  dto: RiskConfigurationDTO,
  color: NOTIFICATION_COLOR,
  saveType: string,
  status: string,
  subtitleSuffix?: string
) => {
  NotificationHub.send(color, `${saveType} Limit Update ${status}`, {
    subtitle: `Correspondent: ${dto.correspondentMPID}, Clearer: ${dto.clearingFirmNum}${
      subtitleSuffix ? subtitleSuffix : ""
    }`,
  });
};

const saveId = "ptr-intraday-save-btn";
export const PtrIntradayLimitModal: React.FC<PtrIntradayLimitModalProps> = ({
  onClose,
  isVisible,
  relationshipId,
}) => {
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [refData] = useRefDataContext();
  const [{ isLoading, isSaveDisabled, isPopoverOpen, saveNextDay }, dispatch] = useReducer(
    dispatchFn,
    defaultState
  );

  const handleClose = useCallback(() => {
    formDispatch([
      {
        type: "RESET_FORM",
        payload: { form: Forms.PTR_INTRADAY_LIMITS },
      },
      {
        type: "RESET_FORM",
        payload: { form: Forms.PTR_INTRADAY_AND_NEXTDAY },
      },
    ]);
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose, formDispatch]);

  const getAggregatesCallback = useCallback(
    (json: { data: { riskConfig: RiskConfigurationDTO }[] }) => {
      if (Array.isArray(json?.data) && json.data.length === 1) {
        const riskConfigDto = getConvertedRiskLimitsForUI(json.data[0].riskConfig);
        const dataSources: SelectOption[] = [];
        if (Array.isArray(riskConfigDto[ApiResponseNames.dataSources])) {
          riskConfigDto[ApiResponseNames.dataSources].forEach((source: DATA_SOURCE) => {
            const dataSourceOption = (SelectOptions[FieldNames.dataSources] as SelectOption[]).find(
              (option: SelectOption) => option.value === source
            );
            if (dataSourceOption) {
              dataSources.push(dataSourceOption);
            }
          });
        }
        const formValues = {
          [FieldNames.clearingFirmMPID]: {
            label: riskConfigDto[ApiResponseNames.clearingFirmMPID],
            value: riskConfigDto[ApiResponseNames.clearingFirmMPID],
          },
          [FieldNames.correspondentMPID]: {
            label: riskConfigDto[ApiResponseNames.correspondentMPID],
            value: riskConfigDto[ApiResponseNames.correspondentMPID],
          },
          [FieldNames.isPostTradeRiskServiceActive]:
            riskConfigDto[ApiResponseNames.isRiskConfigActive],
          [FieldNames.dataSources]: dataSources,
          [FieldNames.holdDefaultAction]:
            PTR_HOLD_ACTION_OPTIONS[riskConfigDto[ApiResponseNames.buyDefaultAction]], // sellDefaultAction should be the same..?
          [FieldNames.useNetTrading]: riskConfigDto[ApiResponseNames.useNetTrading].toString(),
          [FieldNames.isAlertActive]: riskConfigDto[ApiResponseNames.isAlertActive],
          [FieldNames.buyAlertLimit]: riskConfigDto[ApiResponseNames.buyAlertLimit],
          [FieldNames.sellAlertLimit]: riskConfigDto[ApiResponseNames.sellAlertLimit],
          [FieldNames.isHoldActive]: riskConfigDto[ApiResponseNames.isHoldActive],
          [FieldNames.buyHoldLimit]: riskConfigDto[ApiResponseNames.buyHoldLimit],
          [FieldNames.sellHoldLimit]: riskConfigDto[ApiResponseNames.sellHoldLimit],
          [FieldNames.isRejectActive]: riskConfigDto[ApiResponseNames.isRejectActive],
          [FieldNames.buyRejectLimit]: riskConfigDto[ApiResponseNames.buyRejectLimit],
          [FieldNames.sellRejectLimit]: riskConfigDto[ApiResponseNames.sellRejectLimit],
          [FieldNames.netAlertLimit]: riskConfigDto[ApiResponseNames.netAlertLimit],
          [FieldNames.netHoldLimit]: riskConfigDto[ApiResponseNames.netHoldLimit],
          [FieldNames.netRejectLimit]: riskConfigDto[ApiResponseNames.netRejectLimit],
          [FieldNames.isPerTradeActive]: riskConfigDto[ApiResponseNames.isPerTradeActive],
          [FieldNames.perTradeBuyLimit]: riskConfigDto[ApiResponseNames.perTradeBuyLimit],
          [FieldNames.perTradeSellLimit]: riskConfigDto[ApiResponseNames.perTradeSellLimit],
          [FieldNames.perTradeBuyAction]:
            PTR_PER_TRADE_ACTION_OPTIONS[riskConfigDto[ApiResponseNames.perTradeBuyAction]], // perTradeSellAction should be the same..?
          [FieldNames.isMaxTradeActive]: riskConfigDto[ApiResponseNames.isMaxTradeActive],
          [FieldNames.maxTradeLimit]: riskConfigDto[ApiResponseNames.maxTradeLimit],
          [FieldNames.trfLimits]: riskConfigDto[ApiResponseNames.trfLimits],
        };

        const formActions = [
          {
            type: "SET_FORM_VALUES",
            payload: {
              form: Forms.PTR_INTRADAY_LIMITS,
              fields: formValues,
            },
          },
          {
            type: "INIT_FORM_VALIDATION",
            payload: { form: Forms.PTR_INTRADAY_LIMITS },
          },
        ];
        formDispatch(formActions);
      } else {
        NotificationHub.send("danger", "Error retrieving intraday risk configuration limits");
        handleClose();
      }
      dispatch({ type: "SET_STATE", payload: { isLoading: false } });
    },
    [formDispatch, handleClose]
  );

  const getAggregatesError = useCallback(() => {
    dispatch({ type: "SET_STATE", payload: { isLoading: false } });
    NotificationHub.send("danger", "Error retrieving intraday risk configuration limits");
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (relationshipId !== null) {
      dispatch({ type: "SET_STATE", payload: { isLoading: true } });
      const body = {
        instId: 0,
        riskClearingIds: [relationshipId],
        timeStamp: 0,
      };
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_LIMO_LVC, "aggregatesByIdList"),
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(body),
        },
        getAggregatesCallback,
        getAggregatesError
      );
    }
  }, [getAggregatesCallback, getAggregatesError, relationshipId]);

  useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        isPopoverOpen: false,
        saveNextDay:
          formData[Forms.PTR_INTRADAY_AND_NEXTDAY.key].fields?.[FieldNames.ptrNextDayCheckbox],
      },
    });
  }, [formData]);

  const handleSave = useCallback(() => {
    dispatch({ type: "SET_STATE", payload: { isPopoverOpen: false } });
    const valid = getFormValidation(Forms.PTR_INTRADAY_LIMITS, formData, formDispatch);
    if (!valid) {
      NotificationHub.send("danger", "Form validation errors must be resolved before saving");
      return;
    }

    const saveConfigs = async () => {
      const { fields } = formData[Forms.PTR_INTRADAY_LIMITS.key];

      const dto: RiskConfigurationDTO = getDTOForPTRConfigSettings(
        fields,
        refData
      ) as RiskConfigurationDTO;

      dispatch({ type: "SET_STATE", payload: { isSaveDisabled: true } });
      const body = JSON.stringify(dto);
      const INTRADAY_URL = "setintradayriskconfig";
      const NEXT_DAY_URL = "setrcamriskconfig";
      const urls = [INTRADAY_URL];
      const urlToLabel: { [key: string]: string } = {
        [INTRADAY_URL]: "Intraday",
        [NEXT_DAY_URL]: "Next Day",
      };

      if (formData[Forms.PTR_INTRADAY_AND_NEXTDAY.key].fields?.[FieldNames.ptrNextDayCheckbox]) {
        urls.push(NEXT_DAY_URL);
      }

      const requests = urls.map(url =>
        fetch(formatUrl(process.env.REACT_APP_URL_LIMO_WS, url), {
          method: "post",
          headers: getHeaders(),
          body,
        })
      );

      const responses = await Promise.allSettled(requests);
      let allSuccess = true;
      responses.forEach((response, idx) => {
        if (isFulfilled(response) && response.value.ok) {
          response.value.json().then(json => {
            switch (json.ackType) {
              case AckTypes.SUCCESS: {
                sendSaveResultNotification(
                  dto,
                  NOTIFICATION_COLOR.SUCCESS,
                  urlToLabel[urls[idx]],
                  "Complete"
                );
                break;
              }
              case AckTypes.REJECT: {
                sendSaveResultNotification(
                  dto,
                  NOTIFICATION_COLOR.DANGER,
                  urlToLabel[urls[idx]],
                  "Rejected",
                  `, Reject: ${json.rejectText}`
                );
                allSuccess = false;
                break;
              }
              default:
                sendSaveResultNotification(
                  dto,
                  NOTIFICATION_COLOR.DANGER,
                  urlToLabel[urls[idx]],
                  "Error",
                  ", Error: No acknowledgement"
                );
                allSuccess = false;
                break;
            }
          });
        } else {
          sendSaveResultNotification(
            dto,
            NOTIFICATION_COLOR.DANGER,
            urlToLabel[urls[idx]],
            "Failed"
          );
          allSuccess = false;
        }
      });
      dispatch({ type: "SET_STATE", payload: { isSaveDisabled: false } });
      if (allSuccess) {
        handleClose();
      }
    };
    saveConfigs();
  }, [formData, formDispatch, handleClose, refData]);

  const setConfirmOpen = useCallback(
    (isOpen: boolean) => () => {
      dispatch({ type: "SET_STATE", payload: { isPopoverOpen: isOpen } });
    },
    []
  );

  return (
    <Modal isOpen={isVisible} closeOnOutsideClick={false} toggle={handleClose} size="md">
      <ModalHeader toggle={handleClose} title="Post - Trade Risk: Intraday Configuration" />
      <StyledModalBody>
        <CSSGrid rows="55px calc(100% - 55px)" gap="1rem">
          <CSSGrid cols="1fr 1fr" gap="1rem">
            <FieldLoop
              isReactFragment={true}
              form={Forms.PTR_INTRADAY_LIMITS}
              fields={riskConfigMPIDs}
              isDisabled={true}
              classNames={undefined}
              augmentOnChange={undefined}
              augmentOnCreate={undefined}
              portalRef={undefined}
              containerRef={undefined}
              showLabel={undefined}
            />
          </CSSGrid>
          <LimitSettingForm
            isLoading={isLoading}
            form={Forms.PTR_INTRADAY_LIMITS}
            showEnabled={false}
            showDataSources={false}
            showToggleBuySellNet={false}
          />
        </CSSGrid>
      </StyledModalBody>
      <ModalFooter>
        <FieldLoop
          form={Forms.PTR_INTRADAY_AND_NEXTDAY}
          fields={ptrNextDayCheckbox}
          classNames={undefined}
          augmentOnChange={undefined}
          augmentOnCreate={undefined}
          portalRef={undefined}
          isReactFragment={undefined}
          isDisabled={undefined}
          containerRef={undefined}
          showLabel={undefined}
        />
        <Button outline={true} onClick={handleClose}>
          Close
        </Button>
        <Button
          id={saveId}
          onClick={saveNextDay ? setConfirmOpen(true) : handleSave}
          disabled={isLoading || isSaveDisabled}
        >
          Save
        </Button>
      </ModalFooter>
      <ConfirmPopover
        target={saveId}
        isOpen={isPopoverOpen}
        onNo={setConfirmOpen(false)}
        onYes={handleSave}
        width={250}
      >
        This will save as Intraday and Next Day changes.
      </ConfirmPopover>
    </Modal>
  );
};
