import React, { createContext, useContext, useReducer } from "react";
import { SelectOption } from "types";
import { FORM_KEY } from ".";

export type FieldOptionAction =
  | {
      type: "ADD_TO_LOADING_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "REMOVE_FROM_LOADING_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "ADD_TO_OPTIONS_CACHE";
      payload: { form: FORM_KEY; key: string; options: SelectOption[] };
    }
  | {
      type: "RESET_OPTIONS_CACHE";
      payload: { form: FORM_KEY };
    };

export interface FieldOptionState {
  loadingMap: { [fieldName: string]: Set<string> };
  optionsCache: { [form in FORM_KEY]?: { [key: string]: SelectOption[] } };
}

const defaultState: FieldOptionState = {
  loadingMap: {},
  optionsCache: {},
};

const FieldOptionDispatch = createContext<React.Dispatch<FieldOptionAction | FieldOptionAction[]>>(
  () => null
);
FieldOptionDispatch.displayName = "FieldOptionDispatch";
export const useFieldOptionDispatch = () => useContext(FieldOptionDispatch);

const FieldOptionContext = createContext<
  [FieldOptionState, React.Dispatch<FieldOptionAction | FieldOptionAction[]>]
>([defaultState, () => null]);
FieldOptionContext.displayName = "FieldOptionContext";
export const useFieldOptionContext = () => useContext(FieldOptionContext);

const DispatchFn = (state: FieldOptionState, actions: FieldOptionAction | FieldOptionAction[]) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state: FieldOptionState, action: FieldOptionAction): FieldOptionState => {
  switch (action.type) {
    case "ADD_TO_LOADING_MAP": {
      const { fieldName, form } = action.payload;
      let loadSet = state.loadingMap[fieldName];
      if (!loadSet) {
        loadSet = new Set();
      }
      loadSet.add(form);
      const newState = { ...state };
      newState.loadingMap = {
        ...state.loadingMap,
        [fieldName]: new Set(loadSet),
      };
      return newState;
    }
    case "REMOVE_FROM_LOADING_MAP": {
      const { fieldName, form } = action.payload;
      let loadSet = state.loadingMap[fieldName];
      if (!loadSet) {
        loadSet = new Set();
      }
      loadSet.delete(form);
      const newState = { ...state };
      newState.loadingMap = {
        ...state.loadingMap,
        [fieldName]: new Set(loadSet),
      };
      return newState;
    }
    case "ADD_TO_OPTIONS_CACHE": {
      const { form, key, options } = action.payload;
      const newState = { ...state };
      const optionsCache = { ...state.optionsCache };
      if (!optionsCache[form]) {
        optionsCache[form] = {};
      }
      optionsCache[form] = {
        ...state.optionsCache[form],
        [key]: options,
      };
      newState.optionsCache = optionsCache;
      return newState;
    }
    case "RESET_OPTIONS_CACHE": {
      const { form } = action.payload;
      const optionsCache = { ...state.optionsCache };
      delete optionsCache[form];
      return { ...state, optionsCache };
    }
    default:
      return { ...state };
  }
};

interface FieldOptionContextProps {
  children: React.ReactNode;
  defaultData?: FieldOptionState;
}

export const FieldOptionProvider: React.FC<FieldOptionContextProps> = ({
  children,
  defaultData,
}) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return (
    <FieldOptionDispatch.Provider value={dispatchF}>
      <FieldOptionContext.Provider value={[state, dispatchF]}>
        {children}
      </FieldOptionContext.Provider>
    </FieldOptionDispatch.Provider>
  );
};
