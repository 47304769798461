import React from "react";
import _ from "lodash";
import { Flex, Box } from "@nef/core";
import { FieldLoop } from "components/fields";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const FieldLoopBox = styled(Box)`
  ${props => `
    border-bottom: ${props.isLast ? "none" : `2px solid ${getColor("gray", 25)(props)}`};
    padding-bottom: 12px;
    margin-bottom: 17px;

    & > div {
      justify-content: space-between;
    }
    & > div > div {
      margin-right: 0 !important;
    }
  `}
`;

export const FormFieldLoop = ({
  style,
  isLast,
  form,
  fields,
  fieldSetName,
  portalRef,
  showLabel,
}) => {
  return (
    <FieldLoopBox style={style} isLast={isLast}>
      <Flex
        flexDirection="rows"
        justify-content="space-evenly"
        wrap="wrap"
        align-items="stretch"
        style={style}
      >
        <FieldLoop
          form={form}
          fields={fields}
          fieldSetName={fieldSetName}
          portalRef={portalRef}
          showLabel={showLabel}
        />
      </Flex>
    </FieldLoopBox>
  );
};
