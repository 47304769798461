import React from "react";
import { ViewForm, ViewForms, ViewTable } from "viewConstants";
import { Views } from "viewConstants";
import {
  AcceptButton,
  BreakButton,
  CancelButton,
  CopyButton,
  DeclineButton,
  ModifyButton,
  RecapButton,
  ReverseButton,
} from "../../../standardTable/buttons";
import { useUserContext } from "../../../user";
import { useMemo } from "react";
import { ViewActions } from "../../../../viewConstants";
import { MatchButton } from "components/standardTable/buttons/match";

export const ScanButtons = () => {
  const [userData] = useUserContext();

  const Buttons = useMemo(() => {
    const buttons = [];
    if (userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
      buttons.push(
        <MatchButton table={ViewTable[Views.SCAN]} form={ViewForms[Views.SCAN][ViewForm.MATCH]} />
      );
      buttons.push(<AcceptButton key="accept" table={ViewTable[Views.SCAN]} />);
      buttons.push(<DeclineButton key="decline" table={ViewTable[Views.SCAN]} />);
      buttons.push(
        <ModifyButton
          key="modify"
          table={ViewTable[Views.SCAN]}
          form={ViewForms[Views.SCAN][ViewForm.MODIFY]}
        />
      );
      buttons.push(<CancelButton key="cancel" table={ViewTable[Views.SCAN]} />);
      buttons.push(<ReverseButton key="reverse" table={ViewTable[Views.SCAN]} />);
      buttons.push(<BreakButton key="break" table={ViewTable[Views.SCAN]} />);
      buttons.push(
        <CopyButton
          key="copy"
          table={ViewTable[Views.SCAN]}
          form={ViewForms[Views.SCAN][ViewForm.COPY]}
        />
      );
    }
    buttons.push(<RecapButton key="recap" table={ViewTable[Views.SCAN]} />);
    return buttons;
  }, [userData.allowed.actions]);

  return Buttons;
};
