import { BarChart } from "@nef/charts";
import { byMpid, bySymbol } from "../../finra/constant";
import { HeaderBox } from "./tile.container";
import { TileBack } from "./tile.back";
import {
  AllOrPending,
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  usePORCacheContext,
} from "../context";
import { useCallback, useMemo } from "react";
import { useStandardTableContext } from "components/standardTable";

export const Back = ({
  toggle,
  isAllOrPending,
  isMpidOrSymbol,
}: {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
  isMpidOrSymbol: Mpid_Or_Security;
}) => {
  const { state, dispatch } = usePORCacheContext();

  const chartData = useMemo(() => {
    if (isMpidOrSymbol === MpidOrSymbol.MPID) {
      return byMpid;
    } else {
      return bySymbol;
    }
  }, [isMpidOrSymbol]);

  const getChartData = useMemo(() => {
    let totalCount = 0;
    let minusLeft = 5;
    const a = Object.entries(chartData)
      .map(([k, v]) => {
        if (isAllOrPending === AllOrPending.PENDING) {
          if (minusLeft > 0 && v > 2) {
            v = v - 2;
            minusLeft = minusLeft - 1;
          }
        }
        return { value: v, name: k };
      })
      .sort((a, b) => {
        return b.value - a.value;
      });

    const b = a.slice(0, 5);

    const other = a.slice(5).reduce((acc, curr) => {
      acc += curr.value;
      return acc;
    }, 0);

    b.push({ value: other, name: "other" });

    dispatch({ type: "SET_OTHER_CHART_DATA", payload: { others: a.slice(5) } });
    return b;
  }, [chartData, dispatch, isAllOrPending]);

  const onChartClick = useCallback(
    (e: React.MouseEvent | React.FocusEvent, data: any) => {
      const { value, name } = data;
      dispatch({ type: "SET_REQUEST_TABLE", payload: { count: value, id: name } });
      dispatch({ type: "SET_SELECTED", payload: { selected: name } });
    },
    [dispatch]
  );

  return (
    <TileBack
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={true}
          hasMpidOrSymbol={true}
          isAllOrPending={isAllOrPending}
          isMpidOrSymbol={isMpidOrSymbol}
          total={state[PorOrPvr.POR].total}
          title={"POR"}
          isPorOrPvr={PorOrPvr.POR}
        />
      }
      title1={`Top 5 By ${isMpidOrSymbol}`}
      chart={<BarChart data={getChartData} onClick={onChartClick} />}
    />
  );
};
