import { Tab, Tabs, Flex } from "@nef/core";
import {
  EqrcFields,
  EQRC_RULE_STATUS,
  getEqrcIdFromResponse,
  getRowValuesForAction,
} from "./constants";
import { Forms } from "components/fields/fieldConstants";
import { NotificationHub } from "@nef/core";
import { useFormContext, useFormDispatch } from "components/form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
  getSelectedRows,
  getColumnHeadersAndAccessorsFromColumns,
  getFormValidation,
} from "components/standardTable";
import { eqrcRuleTypeMap, StandardTables } from "wksConstants";
import { EQRCAlertConfigTableButtons, EQRCEditForm, EQRCNewAlertForm, EQRCNewForm } from ".";
import {
  eqrcGroupId,
  eqrcFatFingerFields,
  eqrcRestrictedStockFields,
  eqrcShortSaleFields,
  eqrcMarketImpactFields,
  eqrcOrderTypeFields,
  eqrcAverageDailyVolumeFields,
  eqrcOrderRateThresholdsFields,
  eqrcGrossExposureFields,
  eqrcAlertConfigurationFields,
} from "../../fields";
import { SettingsWrapper } from "../settingsWrapper";
import { EQRCTableButtons } from "./eqrcTableButtons";
import { alertHistory, auditRule, deleteRule, updateRule, urls } from "./network";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { covertTableRowToFormValues } from "./constants";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import {
  eqrcAdvColumns,
  eqrcAlertHistoryColumns,
  eqrcAuditColumns,
  eqrcFatFingerColumns,
  eqrcGrossExposureColumns,
  eqrcMarketImpactColumns,
  eqrcOrderRateColumns,
  eqrcOrderTypeColumns,
  eqrcRestrictedStockColumns,
  eqrcShortSaleColumns,
  eqrcMaxNotionalOrderColumns,
  eqrcSharesLocatedCheckColumns,
  eqrcMaxSharesPerOrderColumns,
  eqrcBrokerListColumns,
  eqrcSharesLocatedBrokerListColumns,
} from "./columns";
import { useEqrcRuleContext, useEqrcRuleDispatch } from "./context";
import { DAY_TYPES, RULE_TO_ROUTE, RULE_TO_TABLE } from "./constants2";
import {
  All_History_Alert_Types,
  EqrcRules,
  EqrcRulesLabelMap,
  EqrcServerActionToRule,
} from "./types.consts";
import { enumKeys } from "components/eqrc/utils";
import {
  eqrcMaxNotionalOrder,
  eqrcSharesLocatedCheck,
  eqrcMaxSharesPerOrder,
  eqrcActiveOrConfiguredStatus,
  eqrcEditAlertConfigurationFields,
  eqrcBrokerList,
} from "components/fields/fieldSubsetsConstants";
import FieldLoop from "components/fields/fieldLoop";
import styled from "styled-components";
import { EQRCEditAlertForm } from "./eqrcAlertEditForm";

const StyledSpan = styled.span`
  margin-right: 1rem;
`;

const AuditColumnMap = {
  [StandardTables.EQRC_FAT_FINGER]: [...eqrcAuditColumns, ...eqrcFatFingerColumns(true)],
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: [
    ...eqrcAuditColumns,
    ...eqrcRestrictedStockColumns(true),
  ],
  [StandardTables.EQRC_GROSS_EXPOSURE]: [...eqrcAuditColumns, ...eqrcGrossExposureColumns(true)],
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: [
    ...eqrcAuditColumns,
    ...eqrcMarketImpactColumns(true),
  ],
  [StandardTables.EQRC_ORDER_TYPE]: [...eqrcAuditColumns, ...eqrcOrderTypeColumns(true)],
  [StandardTables.EQRC_ADV_CHECK]: [...eqrcAuditColumns, ...eqrcAdvColumns(true)],
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: [...eqrcAuditColumns, ...eqrcOrderRateColumns(true)],
  [StandardTables.EQRC_SHORT_SALE]: [...eqrcAuditColumns, ...eqrcShortSaleColumns(true)],
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: [
    ...eqrcAuditColumns,
    ...eqrcMaxNotionalOrderColumns(true),
  ],

  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: [
    ...eqrcAuditColumns,
    ...eqrcMaxSharesPerOrderColumns(true),
  ],

  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: [
    ...eqrcAuditColumns,
    ...eqrcSharesLocatedCheckColumns(true),
  ],
  [StandardTables.EQRC_BROKER_LIST]: [...eqrcAuditColumns, ...eqrcBrokerListColumns(true)],
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: [
    ...eqrcAuditColumns,
    ...eqrcSharesLocatedBrokerListColumns(true),
  ],
};

export const EQRCAlertSettingsTable = ({ augmentOnChange }) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isNewAlertModalVisible, setNewAlertModalVisible] = useState(false);
  const [isEditAlertModalVisible, setEditAlertModalVisible] = useState(false);
  const [activeTable, setActiveTable] = useState(StandardTables.EQRC_FAT_FINGER);
  const [lastTableRefresh, setLastTableRefresh] = useState(null);
  const [userData] = useUserContext();
  const [isHistoryLoading, setHistoryLoading] = useState(false);
  const [isAuditLoading, setAuditLoading] = useState(false);
  const eqrcRuleDispatch = useEqrcRuleDispatch();
  const [eqrcRuleData] = useEqrcRuleContext();
  const [lastTableState, setTableState] = useState({ isLoading: false, data: null });
  const [lastActiveOrConfigured, setLastActiveOrConfigured] = useState(EQRC_RULE_STATUS.Active);
  const [currentStep, setCurrentStep] = useState(0);

  const activeOrConfigured = useMemo(() => {
    return formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status];
  }, [formData]);

  const refreshTableData = useCallback(
    table => {
      const requestMap = {
        [urls[table]]: new Set([EQRC_RULE_STATUS.Active, EQRC_RULE_STATUS.Configured]),
      };
      eqrcRuleDispatch({
        type: "SET_REQUESTING",
        payload: { isRequesting: true, requestMap },
      });
    },
    [eqrcRuleDispatch]
  );

  const {
    activeCreateFieldSets,
    activeEditFieldSets,
    activeNewForm,
    activeEditForm,
    activeRuleType,
    totalSteps,
  } = useMemo(() => {
    let activeCreateFieldSets;
    let activeEditFieldSets;
    let activeNewForm;
    let activeEditForm;
    let totalSteps = 0;

    switch (activeTable) {
      case StandardTables.EQRC_FAT_FINGER:
        activeCreateFieldSets = [{ fields: eqrcFatFingerFields }];
        activeEditFieldSets = [{ fields: eqrcFatFingerFields }];
        activeNewForm = Forms.EQRC_FAT_FINGER;
        activeEditForm = Forms.EQRC_EDIT_FAT_FINGER;
        break;
      case StandardTables.EQRC_RESTRICTED_STOCK_LIST:
        activeCreateFieldSets = [{ fields: eqrcRestrictedStockFields }];
        activeEditFieldSets = [{ fields: eqrcRestrictedStockFields }];
        activeNewForm = Forms.EQRC_RESTRICTED_STOCK_LIST;
        activeEditForm = Forms.EQRC_EDIT_RESTRICTED_STOCK;
        break;
      case StandardTables.EQRC_SHORT_SALE:
        activeCreateFieldSets = [{ fields: eqrcShortSaleFields }];
        activeEditFieldSets = [{ fields: eqrcShortSaleFields }];
        activeNewForm = Forms.EQRC_SHORT_SALE;
        activeEditForm = Forms.EQRC_EDIT_SHORT_SALE;
        break;
      case StandardTables.EQRC_MARKET_IMPACT_CHECK:
        activeCreateFieldSets = [{ fields: eqrcMarketImpactFields }];
        activeEditFieldSets = [{ fields: eqrcMarketImpactFields }];
        activeNewForm = Forms.EQRC_MARKET_IMPACT_CHECK;
        activeEditForm = Forms.EQRC_EDIT_MARKET_IMPACT;
        break;
      case StandardTables.EQRC_ORDER_TYPE:
        activeCreateFieldSets = [{ fields: eqrcOrderTypeFields }];
        activeEditFieldSets = [{ fields: eqrcOrderTypeFields }];
        activeNewForm = Forms.EQRC_ORDER_TYPE;
        activeEditForm = Forms.EQRC_EDIT_ORDER_TYPE;
        break;
      case StandardTables.EQRC_ADV_CHECK:
        activeCreateFieldSets = [{ fields: eqrcAverageDailyVolumeFields }];
        activeEditFieldSets = [{ fields: eqrcAverageDailyVolumeFields }];
        activeNewForm = Forms.EQRC_ADV_CHECK;
        activeEditForm = Forms.EQRC_EDIT_AVG_DAILY_VOLUME;
        break;
      case StandardTables.EQRC_ORDER_RATE_THRESHOLDS:
        activeCreateFieldSets = [{ fields: eqrcOrderRateThresholdsFields }];
        activeEditFieldSets = [{ fields: eqrcOrderRateThresholdsFields }];
        activeNewForm = Forms.EQRC_ORDER_RATE;
        activeEditForm = Forms.EQRC_EDIT_ORDER_RATE;
        break;
      case StandardTables.EQRC_GROSS_EXPOSURE:
        activeCreateFieldSets = [{ fields: eqrcGroupId }, { fields: eqrcGrossExposureFields }];
        activeEditFieldSets = [{ fields: eqrcGrossExposureFields }];
        activeNewForm = Forms.EQRC_GROSS_EXPOSURE;
        activeEditForm = Forms.EQRC_EDIT_GROSS_EXPOSURE;
        break;
      case StandardTables.EQRC_ALERT_CONFIG:
        activeCreateFieldSets = [{ fields: eqrcGroupId }, { fields: eqrcAlertConfigurationFields }];
        activeEditFieldSets = [{ fields: eqrcEditAlertConfigurationFields }];
        activeNewForm = Forms.EQRC_ALERT_CONFIG;
        activeEditForm = Forms.EQRC_EDIT_ALERT;
        break;
      case StandardTables.EQRC_MAX_NOTIONAL_ORDER:
        activeCreateFieldSets = [{ fields: eqrcMaxNotionalOrder }];
        activeEditFieldSets = [{ fields: eqrcMaxNotionalOrder }];
        activeNewForm = Forms.EQRC_MAX_NOTIONAL_ORDER;
        activeEditForm = Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER;
        break;
      case StandardTables.EQRC_MAX_SHARES_PER_ORDER:
        activeCreateFieldSets = [{ fields: eqrcMaxSharesPerOrder }];
        activeEditFieldSets = [{ fields: eqrcMaxSharesPerOrder }];
        activeNewForm = Forms.EQRC_MAX_SHARES_PER_ORDER;
        activeEditForm = Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER;
        break;
      case StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST:
        activeCreateFieldSets = [
          [{ fields: eqrcSharesLocatedCheck }],
          [{ fields: eqrcBrokerList }],
        ][currentStep];
        activeEditFieldSets = [[{ fields: eqrcSharesLocatedCheck }], [{ fields: eqrcBrokerList }]][
          currentStep
        ];
        activeNewForm = Forms.EQRC_SHARES_LOCATED_CHECK;
        activeEditForm = Forms.EQRC_EDIT_SHARES_LOCATED_CHECK;
        totalSteps = 2;

        break;
      default:
        break;
    }
    return {
      activeCreateFieldSets,
      activeEditFieldSets,
      activeNewForm,
      activeEditForm,
      activeRuleType: eqrcRuleTypeMap[activeTable],
      totalSteps,
    };
  }, [activeTable, currentStep]);

  const refreshCurrentTableData = useCallback(() => {
    const requestMap = {
      [urls[activeTable]]: new Set([EQRC_RULE_STATUS.Active, EQRC_RULE_STATUS.Configured]),
    };
    eqrcRuleDispatch({
      type: "SET_REQUESTING",
      payload: { isRequesting: true, requestMap },
    });
  }, [activeTable, eqrcRuleDispatch]);

  const handleClickNew = useCallback(() => {
    setNewModalVisible(true);
  }, []);

  const copyRowToForm = useCallback(
    (row, form, status, includeExchangeMpidPort, oppositeRow) => {
      const statusRow = row?.[status];

      if (statusRow) {
        const fields = covertTableRowToFormValues(
          statusRow,
          includeExchangeMpidPort,
          oppositeRow,
          status
        );
        formDispatch([
          {
            type: "SET_FORM_VALUES",
            payload: { form, fields },
          },
          { type: "INIT_FORM_VALIDATION", payload: { form } },
        ]);
      }
    },
    [formDispatch]
  );

  const getEditStatus = useCallback(() => {
    return formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status];
  }, [formData]);

  const handleClickEdit = useCallback(() => {
    const index = Object.keys(tableData[activeTable].selected).find(
      key => tableData[activeTable].selected[key] === true
    );
    const row = tableData[activeTable].data[index];
    const status = getEditStatus();
    if (row[status]) {
      copyRowToForm(
        row,
        activeEditForm,
        getEditStatus(),
        true,
        row[
          status === EQRC_RULE_STATUS.Active ? EQRC_RULE_STATUS.Configured : EQRC_RULE_STATUS.Active
        ]
      );
      setEditModalVisible(true);
    } else {
      NotificationHub.send("danger", `${status} rule is not available for edit`);
    }
  }, [activeEditForm, activeTable, copyRowToForm, getEditStatus, tableData]);

  const handleCloseNew = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: activeNewForm } });
    setNewModalVisible(false);
    setCurrentStep(0);
  }, [activeNewForm, formDispatch]);

  const handleCloseEdit = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: activeEditForm } });
    setEditModalVisible(false);
    setCurrentStep(0);
  }, [activeEditForm, formDispatch]);

  const handleRowDelete = useCallback(
    (table, json, cbArgs) => {
      const { eqrcId, body } = cbArgs;
      const { mpid, exchange, port, groupId } = body;

      tableDispatch([
        {
          type: "DELETE_ROW_BY_VALUE",
          payload: {
            table,
            key: EqrcFields.eqrcId,
            value: eqrcId,
          },
        },
        { type: "SET_IS_LOADING", payload: { table, isLoading: false } },
      ]);

      NotificationHub.send("success", "Rule deleted successfully", {
        subtitle: `Exchange: ${exchange || "N/A"}, MPID: ${mpid || "N/A"}, Port ${
          port || "N/A"
        }, Group ID: ${groupId || "N/A"}`,
      });
    },
    [tableDispatch]
  );

  const handleRowUpdate = useCallback(
    (table, json, eqrcId) => {
      const data = { [EqrcFields.eqrcId]: eqrcId, ACTIVE: json };

      tableDispatch({
        type: "UPDATE_TABLE_ROW_WITH_ID",
        payload: {
          table: table,
          row: data,
          idField: EqrcFields.eqrcId,
        },
      });
    },
    [tableDispatch]
  );

  const handleDeleteError = useCallback(
    table => {
      NotificationHub.send("danger", "Delete Failed");
      tableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
    },
    [tableDispatch]
  );

  const handleAlertDisableError = useCallback(() => {
    NotificationHub.send("danger", "Alert Disable Failed");
  }, []);

  const handleAlertEnableError = useCallback(() => {
    NotificationHub.send("danger", "Alert Enable Failed");
  }, []);

  const createDeleteBody = row => {
    let body = {};
    if (row?.[EqrcFields.mpid]) {
      body[EqrcFields.mpid] = row[EqrcFields.mpid];
    }
    if (row?.[EqrcFields.port]) {
      body[EqrcFields.port] = row[EqrcFields.port];
    }
    // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
    if (row.hasOwnProperty(EqrcFields.groupId)) {
      body[EqrcFields.groupId] = row[EqrcFields.groupId];
    }
    if (row?.[EqrcFields.exchange]) {
      body[EqrcFields.exchange] = row[EqrcFields.exchange];
    }
    if (row?.[EqrcFields.ruleType]) {
      body[EqrcFields.ruleType] = row[EqrcFields.ruleType];
    }
    return body;
  };

  const createAuditBody = useCallback(
    pageNumber => {
      const { fields } = formData[Forms.EQRC_AUDIT_MENU.key];
      const bodyArgs = [
        EqrcFields.mpid,
        EqrcFields.exchange,
        EqrcFields.port,
        EqrcFields.groupId,
        EqrcFields.beginDate,
        EqrcFields.endDate,
      ];

      const body = bodyArgs.reduce(
        (acc, curr) => {
          switch (curr) {
            case EqrcFields.mpid:
            case EqrcFields.exchange:
              acc[curr] = fields[curr].value;
              break;
            case EqrcFields.port:
              if (fields[curr]) {
                acc[curr] = fields[curr].value;
              } else {
                acc[curr] = "";
              }
              break;
            case EqrcFields.groupId:
              if (fields[curr]) {
                acc[curr] = fields[curr].value;
              } else {
                acc[curr] = 0;
              }
              break;
            case EqrcFields.beginDate:
            case EqrcFields.endDate:
              acc[curr] = fields[curr];
              break;
            default:
              break;
          }
          return acc;
        },
        { pageNumber, pageSize: 1000 }
      );

      return body;
    },
    [formData]
  );

  const createHistoryBody = useCallback(
    pageNumber => {
      const { fields } = formData[Forms.EQRC_HISTORY_MENU.key];
      const bodyArgs = [
        EqrcFields.mpid,
        EqrcFields.exchange,
        EqrcFields.port,
        EqrcFields.groupId,
        EqrcFields.beginDate,
        EqrcFields.endDate,
        EqrcFields.alertType,
        EqrcFields.alertLevel,
      ];
      const body = bodyArgs.reduce(
        (acc, curr) => {
          switch (curr) {
            case EqrcFields.mpid:
            case EqrcFields.exchange:
            case EqrcFields.alertLevel:
            case EqrcFields.ruleType:
              acc[curr] = fields[curr]?.value;
              break;
            case EqrcFields.port:
              if (fields[curr]) {
                acc[curr] = fields[curr].value;
              }
              break;
            case EqrcFields.groupId:
              if (fields[curr]) {
                acc[curr] = fields[curr].value;
              }
              break;
            case EqrcFields.beginDate:
            case EqrcFields.endDate:
              acc[curr] = fields[curr]?.substr(0, 10);
              break;
            case EqrcFields.alertType:
              acc[curr] = fields[curr]?.value;
              break;
            default:
              break;
          }
          return acc;
        },
        {
          [EqrcFields.pageNumber]: pageNumber,
          [EqrcFields.pageSize]: 1000,
        }
      );

      return body;
    },
    [formData]
  );

  const handleClickDelete = useCallback(() => {
    const selected = getSelectedRows(tableData[activeTable]);

    tableDispatch([
      {
        type: "DESELECT_ALL_ROWS",
        payload: { table: activeTable },
      },
      { type: "SET_IS_LOADING", payload: { table: activeTable, isLoading: true } },
    ]);

    selected.forEach(row => {
      let body = createDeleteBody(getRowValuesForAction(row));
      let eqrcId = row[EqrcFields.eqrcId];
      deleteRule(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
        JSON.stringify(body),
        activeTable,
        { eqrcId, body },
        handleRowDelete,
        handleDeleteError
      );
    });
  }, [tableData, activeTable, tableDispatch, userData, handleRowDelete, handleDeleteError]);

  const handleClickCopy = useCallback(() => {
    const index = Object.keys(tableData[activeTable].selected).find(
      key => tableData[activeTable].selected[key] === true
    );
    const row = tableData[activeTable].data[index];
    let status = getEditStatus();
    copyRowToForm(
      row,
      activeNewForm,
      status,
      false,
      row[
        status === EQRC_RULE_STATUS.Active ? EQRC_RULE_STATUS.Configured : EQRC_RULE_STATUS.Active
      ]
    );
    setNewModalVisible(true);
  }, [tableData, activeTable, getEditStatus, copyRowToForm, activeNewForm]);

  const handleClickNewAlert = useCallback(() => {
    const index = Object.keys(tableData[activeTable].selected).find(
      key => tableData[activeTable].selected[key] === true
    );
    const row = tableData[activeTable].data[index][activeOrConfigured];
    const fields = {
      [EQRC_RULE_STATUS.Active]: {
        [EqrcFields.exchange]: row[EqrcFields.exchange],
        [EqrcFields.mpid]: row[EqrcFields.mpid],
        [EqrcFields.port]: row[EqrcFields.port],
        [EqrcFields.ruleType]: row[EqrcFields.ruleType],
      },
    };

    copyRowToForm(fields, Forms.EQRC_ALERT_CONFIG, EQRC_RULE_STATUS.Active, true);

    setNewAlertModalVisible(true);
  }, [activeOrConfigured, activeTable, copyRowToForm, tableData]);

  const handleCloseNewAlert = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.EQRC_ALERT_CONFIG } });
    setNewAlertModalVisible(false);
  }, [formDispatch]);

  const handleCloseEditAlert = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.EQRC_EDIT_ALERT } });
    setEditAlertModalVisible(false);
  }, [formDispatch]);

  const createAlertUpdateBody = (row, toggleValue) => {
    let body = {};
    if (row?.[EqrcFields.mpid]) {
      body[EqrcFields.mpid] = row[EqrcFields.mpid];
    }
    if (row?.[EqrcFields.port]) {
      body[EqrcFields.port] = row[EqrcFields.port];
    }
    // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
    if (row.hasOwnProperty(EqrcFields.groupId)) {
      body[EqrcFields.groupId] = row[EqrcFields.groupId];
    }
    if (row?.[EqrcFields.exchange]) {
      body[EqrcFields.exchange] = row[EqrcFields.exchange];
    }
    if (row?.[EqrcFields.ruleType]) {
      body[EqrcFields.ruleType] = row[EqrcFields.ruleType];
    }
    if (row?.[EqrcFields.emailAddresses]) {
      body[EqrcFields.emailAddresses] = row[EqrcFields.emailAddresses];
    }
    body[EqrcFields.active] = toggleValue;
    return body;
  };

  const handleAlertToggle = useCallback(
    (toggleValue, errorHandler) => {
      const selected = getSelectedRows(tableData[activeTable]);

      selected.forEach(row => {
        let body = createAlertUpdateBody(row[EQRC_RULE_STATUS.Active], toggleValue);
        let eqrcId = row[EqrcFields.eqrcId];
        updateRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          null,
          JSON.stringify(body),
          activeTable,
          eqrcId,
          handleRowUpdate,
          errorHandler
        );
      });

      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: activeTable },
      });
    },
    [userData, handleRowUpdate, tableData, activeTable, tableDispatch]
  );

  const handleClickDisable = useCallback(() => {
    handleAlertToggle(false, handleAlertDisableError);
  }, [handleAlertToggle, handleAlertDisableError]);

  const handleClickEnable = useCallback(() => {
    handleAlertToggle(true, handleAlertEnableError);
  }, [handleAlertToggle, handleAlertEnableError]);

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.EQRC_AUDIT_MENU },
    });
  }, [formDispatch]);

  const handleClickAudit = useCallback(() => {
    const valid = getFormValidation(Forms.EQRC_AUDIT_MENU, formData, formDispatch);

    if (!valid) {
      return;
    }

    setAuditLoading(true);
    let results = [];
    const handleAudit = (table, json) => {
      results = results.concat(json.results);
      if (json.totalResults === 0) {
        setAuditLoading(false);
        NotificationHub.send("success", "No records matched this query");
        return;
      }

      if (json.pageNumber < json.totalPages - 1) {
        makeRequest(json.pageNumber + 1);
      } else {
        const { fields } = formData[Forms.EQRC_AUDIT_MENU.key];
        const start = fields[EqrcFields.beginDate];
        const end = fields[EqrcFields.endDate];
        let filename = `AUDIT_${table.toLocaleUpperCase()}`;
        if (start && end) {
          filename += `_${start.replace(/\D/g, "").substr(0, 8)}_${end
            .replace(/\D/g, "")
            .substr(0, 8)}`;
        }
        filename += ".csv";

        const { accessors, headers } = getColumnHeadersAndAccessorsFromColumns(
          AuditColumnMap[table],
          false
        );
        createAndDownloadTemplate(filename, convertToCSV(headers, results, accessors)).then(
          () => {
            setAuditLoading(false);
            NotificationHub.send("success", "Export request has been completed");
          },
          () => setAuditLoading(false)
        );
      }
    };

    const handleAuditError = (form, error, cbArgs) => {
      setAuditLoading(false);
      NotificationHub.send("danger", "Error retrieving audit information", {
        subtitle: error?.message,
      });
    };

    const makeRequest = pageNumber => {
      if (activeTable !== StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST) {
        const body = createAuditBody(pageNumber);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          activeTable,
          handleAudit,
          handleAuditError
        );
      } else {
        let body = createAuditBody(pageNumber);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          StandardTables.EQRC_BROKER_LIST,
          handleAudit,
          handleAuditError
        );

        body = createAuditBody(pageNumber);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          StandardTables.EQRC_SHARES_LOCATED_CHECK,
          handleAudit,
          handleAuditError
        );
      }
    };
    makeRequest(0);
  }, [formData, formDispatch, createAuditBody, userData, activeTable]);

  const handleClickEditAlert = useCallback(() => {
    const index = Object.keys(tableData[activeTable].selected).find(
      key => tableData[activeTable].selected[key] === true
    );
    const row = tableData[activeTable].data[index];

    copyRowToForm(row, activeEditForm, getEditStatus(), true);

    setEditAlertModalVisible(true);
  }, [activeEditForm, activeTable, copyRowToForm, getEditStatus, tableData]);
  const titleMap = useMemo(
    () => ({
      [StandardTables.EQRC_FAT_FINGER]: "Fat Finger Rules",
      [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: "Restricted Stock List Rules",
      [StandardTables.EQRC_GROSS_EXPOSURE]: "Gross Exposure Rules",
      [StandardTables.EQRC_MARKET_IMPACT_CHECK]: "Market Impact Rules",
      [StandardTables.EQRC_ORDER_TYPE]: "Order Type Rules",
      [StandardTables.EQRC_ADV_CHECK]: "Average Daily Volume Rules",
      [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: "Order Rate Threshold Rules",
      [StandardTables.EQRC_SHORT_SALE]: "Short Sale Rules",
      [StandardTables.EQRC_ALERT_CONFIG]: "EQRC Alert Configuration",
      [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: "Max Notional Order",
      [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: "Max Shares Per Order",
      [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: "Shares Located / Broker List",
    }),
    []
  );

  const handleClickHistory = useCallback(() => {
    let results = [];
    setHistoryLoading(true);
    const handleHistory = json => {
      results = results.concat(json.results);
      if (json.totalPages === 0) {
        setHistoryLoading(false);
        NotificationHub.send("success", "No records matched this query");
        return;
      }
      if (json.pageNumber < json.totalPages - 1) {
        makeRequest(json.pageNumber + 1);
      } else {
        const { fields } = formData[Forms.EQRC_HISTORY_MENU.key];
        const start = fields[EqrcFields.beginDate];
        const end = fields[EqrcFields.endDate];
        let filename = `EQRC_ALERT_HISTORY`;
        if (start && end) {
          filename += `_${start.replace(/\D/g, "").substr(0, 8)}_${end
            .replace(/\D/g, "")
            .substr(0, 8)}`;
        }
        filename += ".csv";

        const { accessors, headers } = getColumnHeadersAndAccessorsFromColumns(
          eqrcAlertHistoryColumns,
          false
        );

        createAndDownloadTemplate(
          filename,
          convertToCSV(
            headers,
            results.map(row => {
              const newRow = { ...row };
              newRow.alertType = All_History_Alert_Types[row.alertType] || row.alertType;
              return newRow;
            }),
            accessors
          )
        ).then(
          () => {
            setHistoryLoading(false);
            NotificationHub.send("success", "History request has been completed");
          },
          () => setHistoryLoading(false)
        );
      }
    };

    const handleHistoryError = () => {
      setHistoryLoading(false);
      NotificationHub.send("danger", "Error retrieving audit information");
    };

    const makeRequest = pageNumber => {
      const body = createHistoryBody(pageNumber);
      alertHistory(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcHistoryUrl],
        JSON.stringify(body),
        handleHistory,
        handleHistoryError
      );
    };
    makeRequest(0);
  }, [createHistoryBody, formData, userData]);

  const tableButtons = useMemo(() => {
    if (activeTable === StandardTables.EQRC_ALERT_CONFIG) {
      return (
        <EQRCAlertConfigTableButtons
          onClickNew={handleClickNew}
          onClickDelete={handleClickDelete}
          onClickDisable={handleClickDisable}
          onClickEnable={handleClickEnable}
          onClickHistory={handleClickHistory}
          isHistoryLoading={isHistoryLoading}
          onClickEdit={handleClickEditAlert}
        />
      );
    } else {
      const selected = getSelectedRows(tableData[activeTable]);

      return (
        <EQRCTableButtons
          table={activeTable}
          onClickNew={handleClickNew}
          onClickEdit={handleClickEdit}
          onClickDelete={handleClickDelete}
          onClickCopy={handleClickCopy}
          onClickNewAlert={handleClickNewAlert}
          onClickAudit={handleClickAudit}
          onClickRefresh={refreshCurrentTableData}
          isAuditLoading={isAuditLoading}
          activeOrConfigured={activeOrConfigured}
          disableEdit={selected?.[0]?.disabled}
          showActiveConfiguredWarning={selected?.[0]?.showActiveConfiguredWarning}
        />
      );
    }
  }, [
    activeTable,
    handleClickNew,
    handleClickDelete,
    handleClickDisable,
    handleClickEnable,
    handleClickHistory,
    isHistoryLoading,
    handleClickEditAlert,
    tableData,
    handleClickEdit,
    handleClickCopy,
    handleClickNewAlert,
    handleClickAudit,
    refreshCurrentTableData,
    isAuditLoading,
    activeOrConfigured,
  ]);

  const Header = useMemo(() => {
    const todayOrTomorrow = DAY_TYPES[activeOrConfigured];
    const isAlertTable = activeTable === StandardTables.EQRC_ALERT_CONFIG;

    let text = `${titleMap[activeTable]}`;
    if (!isAlertTable) {
      text += ` for ${todayOrTomorrow}`;
    }

    return () => <StandardHeader title={text} />;
  }, [activeOrConfigured, activeTable, titleMap]);

  const setRuleForStatus = (rules, rule, status) => {
    const eqrcId = getEqrcIdFromResponse(rule);

    rule[EqrcFields.eqrcId] = eqrcId;
    if (!rules.hasOwnProperty(eqrcId)) {
      rules[eqrcId] = {};
    }
    rules[eqrcId][EqrcFields.eqrcId] = eqrcId;
    rules[eqrcId][status] = rule;
  };

  useEffect(() => {
    let setLastTableState = false;
    const newTableState = { ...lastTableState };
    const tableActions = [];
    if (
      lastActiveOrConfigured !== activeOrConfigured ||
      lastTableState.data !== eqrcRuleData[urls[activeTable]]
    ) {
      const active = eqrcRuleData[urls[activeTable]][EQRC_RULE_STATUS.Active];
      const configured = eqrcRuleData[urls[activeTable]][EQRC_RULE_STATUS.Configured];
      if (active && configured) {
        const rules = {};
        const inactiveRules = {};

        if (activeOrConfigured === EQRC_RULE_STATUS.Active) {
          active.forEach(rule => {
            setRuleForStatus(rules, rule, EQRC_RULE_STATUS.Active);
          });

          configured.forEach(rule => {
            setRuleForStatus(inactiveRules, rule, EQRC_RULE_STATUS.Configured);
          });
        }
        if (activeOrConfigured === EQRC_RULE_STATUS.Configured) {
          configured.forEach(rule => {
            setRuleForStatus(rules, rule, EQRC_RULE_STATUS.Configured);
          });
          active.forEach(rule => {
            setRuleForStatus(inactiveRules, rule, EQRC_RULE_STATUS.Active);
          });
        }

        if (activeOrConfigured === EQRC_RULE_STATUS.Active) {
          Object.entries(rules).forEach(([ruleKey, data]) => {
            if (
              [
                StandardTables.EQRC_MAX_NOTIONAL_ORDER,
                StandardTables.EQRC_MAX_SHARES_PER_ORDER,
              ].includes(activeTable)
            ) {
              if (!inactiveRules[ruleKey]) {
                switch (activeTable) {
                  case StandardTables.EQRC_MAX_NOTIONAL_ORDER: {
                    if (data[EQRC_RULE_STATUS.Active][EqrcFields.perOrderNotionalLimit] === 0) {
                      delete rules[ruleKey];
                    }
                    break;
                  }
                  case StandardTables.EQRC_MAX_SHARES_PER_ORDER: {
                    if (data[EQRC_RULE_STATUS.Active][EqrcFields.perOrderSharesLimit] === 0) {
                      delete rules[ruleKey];
                    }
                    break;
                  }
                  default:
                    break;
                }
              }
            } else if (activeTable === StandardTables.EQRC_SHARES_LOCATED_CHECK) {
              if (data[EQRC_RULE_STATUS.Active][EqrcFields.hasSharesLocateRule] === false) {
                delete rules[ruleKey];
              }
            }

            if (!inactiveRules[ruleKey] && rules[ruleKey]) {
              rules[ruleKey].disabled = true;
            }
          });
        } else {
          Object.entries(rules).forEach(([ruleKey, data]) => {
            if (inactiveRules[ruleKey]) {
              rules[ruleKey].showActiveConfiguredWarning = true;
            }

            if (activeTable === StandardTables.EQRC_SHARES_LOCATED_CHECK) {
              if (data[EQRC_RULE_STATUS.Configured][EqrcFields.hasSharesLocateRule] === false) {
                delete rules[ruleKey];
              }
            }
          });
        }

        let shouldSwapActiveConfigured = false;
        if (
          activeTable === StandardTables.EQRC_ALERT_CONFIG &&
          activeOrConfigured === EQRC_RULE_STATUS.Configured
        ) {
          shouldSwapActiveConfigured = true;
        }

        const inverseActiveOrConfigured =
          activeOrConfigured === EQRC_RULE_STATUS.Active
            ? EQRC_RULE_STATUS.Configured
            : EQRC_RULE_STATUS.Active;

        // make sure active rules have access to their configured rule
        // if (activeOrConfigured === EQRC_RULE_STATUS.Active) {
        Object.entries(rules).forEach(([ruleKey, data]) => {
          if (inactiveRules[ruleKey]) {
            data[inverseActiveOrConfigured] = inactiveRules[ruleKey][inverseActiveOrConfigured];
          }
          data.status = activeOrConfigured;
        });

        tableActions.push({
          type: "SET_TABLE_DATA",
          payload: {
            table: activeTable,
            data: Object.values(shouldSwapActiveConfigured ? inactiveRules : rules),
          },
        });
      } else {
        NotificationHub.send("danger", "Error parsing rules data");
      }
      setLastTableState = true;
      newTableState.data = eqrcRuleData[urls[activeTable]];
    }

    if (lastTableState.isLoading === false && eqrcRuleData.loadingSet.has(urls[activeTable])) {
      tableActions.push({
        type: "SET_LOADING",
        payload: { table: activeTable },
      });
      setLastTableState = true;
      newTableState.isLoading = true;
    } else if (
      lastTableState.isLoading === true &&
      !eqrcRuleData.loadingSet.has(urls[activeTable])
    ) {
      tableActions.push({
        type: "SET_NOT_LOADING",
        payload: { table: activeTable },
      });
      setLastTableState = true;
      newTableState.isLoading = false;
    }

    if (setLastTableState) {
      tableDispatch(tableActions);
      setTableState(newTableState);
      setLastActiveOrConfigured(activeOrConfigured);
    }
  }, [
    eqrcRuleData,
    activeTable,
    tableDispatch,
    lastTableState,
    lastActiveOrConfigured,
    activeOrConfigured,
  ]);

  useEffect(() => {
    if (activeTable !== lastTableRefresh || activeOrConfigured !== lastActiveOrConfigured) {
      refreshTableData(activeTable);
      setLastTableRefresh(activeTable);
      setLastActiveOrConfigured(activeOrConfigured);
      tableDispatch({ type: "CLEAR_SELECTED", payload: { table: activeTable } });
    }
  }, [
    activeOrConfigured,
    activeTable,
    lastActiveOrConfigured,
    lastTableRefresh,
    refreshTableData,
    tableDispatch,
  ]);

  const handleTabClick = useCallback(
    table => () => {
      setActiveTable(table);
      refreshTableData(table);
      setCurrentStep(0);
    },
    [refreshTableData]
  );

  const generateCreateFormHeader = (table, ruleType) => {
    const steps = totalSteps > 0 ? ` - Step ${currentStep + 1} of ${totalSteps}` : "";

    if (table === StandardTables.EQRC_ALERT_CONFIG) {
      return "Create Alert Configuration";
    } else {
      return `Create ${ruleType.label} Rule${steps}`;
    }
  };

  const generateEditFormHeader = useCallback(
    ruleType => {
      const statusText = getEditStatus() === EQRC_RULE_STATUS.Active ? "Intraday" : "Next Day";
      const steps = totalSteps > 0 ? ` - Step ${currentStep + 1} of ${totalSteps}` : "";
      return `Edit ${ruleType.label} Rule (${statusText})${steps}`;
    },
    [currentStep, getEditStatus, totalSteps]
  );

  const generateNewAlertFormHeader = (table, ruleType) => {
    if (table !== StandardTables.EQRC_ALERT_CONFIG) {
      return "Create " + ruleType.label + " Alert";
    }
  };

  const generateEditAlertFormHeader = (table, ruleType) => {
    const selected = getSelectedRows(tableData[activeTable]);

    return `Edit ${
      EqrcServerActionToRule[selected?.[0]?.[EQRC_RULE_STATUS.Active]?.ruleType]
    } Alert`;
  };

  const HeaderMenu = useMemo(() => {
    if (activeTable === StandardTables.EQRC_ALERT_CONFIG) {
      return <></>;
    } else {
      return (
        <Flex>
          <StyledSpan>Show Rules for</StyledSpan>
          <FieldLoop
            form={Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE}
            fields={eqrcActiveOrConfiguredStatus}
            isReactFragment={true}
          />
        </Flex>
      );
    }
  }, [activeTable]);

  return (
    <>
      <SettingsWrapper
        header={"EQRC Rules"}
        headerMenu={HeaderMenu}
        // subheader="Configure Post-Trade EQRC Alerts"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
        tabs={<Tab2 handleTabClick={handleTabClick} activeTable={activeTable} />}
      >
        {/* the height of the tables is further specified below via style.height  */}
        <StandardTable
          header={Header}
          table={activeTable}
          enableLayoutExport={true}
          id={"eqrc-tables"}
          isSingleSelect={false}
          isColumnsVirtualized={false}
          isFilterable={true}
          headerMenu={tableButtons}
          hideRowCount={false}
          hideSelectedCount={false}
        />
      </SettingsWrapper>
      <EQRCNewForm
        table={activeTable}
        header={generateCreateFormHeader(activeTable, activeRuleType)}
        form={activeNewForm}
        fieldSets={activeCreateFieldSets}
        isOpen={isNewModalVisible}
        onClose={handleCloseNew}
        setStep={setCurrentStep}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
      {activeTable !== StandardTables.EQRC_ALERT_CONFIG ? (
        <EQRCEditForm
          table={activeTable}
          form={activeEditForm}
          header={generateEditFormHeader(activeRuleType)}
          fieldSets={activeEditFieldSets}
          isOpen={isEditModalVisible}
          onClose={handleCloseEdit}
          setStep={setCurrentStep}
          currentStep={currentStep}
          totalSteps={totalSteps}
        />
      ) : (
        <></>
      )}
      <EQRCNewAlertForm
        table={activeTable}
        header={generateNewAlertFormHeader(activeTable, activeRuleType)}
        isOpen={isNewAlertModalVisible}
        onClose={handleCloseNewAlert}
        ruleType={activeRuleType}
        form={Forms.EQRC_ALERT_CONFIG}
        fieldSets={activeCreateFieldSets}
      />
      <EQRCEditAlertForm
        table={activeTable}
        header={generateEditAlertFormHeader(activeTable, activeRuleType)}
        isOpen={isEditAlertModalVisible}
        onClose={handleCloseEditAlert}
        ruleType={activeRuleType}
        form={activeEditForm}
        fieldSets={activeEditFieldSets}
      />
    </>
  );
};

const Tab2 = ({ activeTable, handleTabClick }) => {
  const tabs = [];
  for (const type of enumKeys(EqrcRules)) {
    tabs.push(
      <Tab
        key={type}
        active={activeTable === StandardTables[RULE_TO_TABLE[type]]}
        onClick={handleTabClick(StandardTables[RULE_TO_TABLE[type]])}
      >
        {EqrcRulesLabelMap[RULE_TO_ROUTE[type]]}
      </Tab>
    );
  }

  return <Tabs>{tabs.map(t => t)}</Tabs>;
};
