import { Decimal } from "decimal.js";

export enum RASH_ORDER {
  exchange = "exchange",
  id = "id",
  orderId = "orderId",
  side = "side",
  orderQuantity = "orderQuantity",
  canceledQuantity = "canceledQuantity",
  symbol = "symbol",
  price = "price",
  timeInForce = "timeInForce",
  firm = "firm",
  display = "display",
  minQuantity = "minQuantity",
  maxFloor = "maxFloor",
  pegType = "pegType",
  pegDifference = "pegDifference",
  pegDifferenceSign = "pegDifferenceSign",
  discretionPrice = "discretionPrice",
  discretionPegType = "discretionPegType",
  discretionPegDifferenceSign = "discretionPegDifferenceSign",
  discretionPegDifference = "discretionPegDifference",
  capacity = "capacity",
  randomReserve = "randomReserve",
  route = "route",
  subId = "subId",
  createdBy = "createdBy",
  modifiedBy = "modifiedBy",
  entryDate = "entryDate",
  lastUpdate = "lastUpdate",
  customerType = "customerType",
  intermarketSweep = "intermarketSweep",
  crossType = "crossType",
  reactiveTradeNow = "reactiveTradeNow",
}

interface RashRejectResponse {
  isReject: boolean;
  rejectMsg: string;
}

export interface RashOrder extends RashRejectResponse {
  [RASH_ORDER.exchange]: RASH_EXCHANGE_VALUE;
  [RASH_ORDER.id]: string;
  [RASH_ORDER.orderId]: string;
  [RASH_ORDER.side]: RASH_SIDE_VALUE;
  [RASH_ORDER.orderQuantity]: number;
  [RASH_ORDER.canceledQuantity]: number;
  [RASH_ORDER.symbol]: string;
  [RASH_ORDER.price]: number;
  [RASH_ORDER.timeInForce]: number;
  [RASH_ORDER.firm]: string;
  [RASH_ORDER.display]: string;
  [RASH_ORDER.minQuantity]: number;
  [RASH_ORDER.maxFloor]: number;
  [RASH_ORDER.pegType]: RASH_PEG_TYPE_VALUE;
  [RASH_ORDER.pegDifferenceSign]: PEG_DIFFERENCE_SIGN;
  [RASH_ORDER.pegDifference]: number;
  [RASH_ORDER.discretionPrice]: number;
  [RASH_ORDER.discretionPegType]: RASH_PEG_TYPE_VALUE;
  [RASH_ORDER.discretionPegDifferenceSign]: PEG_DIFFERENCE_SIGN;
  [RASH_ORDER.discretionPegDifference]: number;
  [RASH_ORDER.capacity]: RASH_CAPACITY_VALUE;
  [RASH_ORDER.randomReserve]: number;
  [RASH_ORDER.route]: string;
  [RASH_ORDER.subId]: string;
  [RASH_ORDER.createdBy]: string;
  [RASH_ORDER.modifiedBy]: string;
  [RASH_ORDER.entryDate]: string;
  [RASH_ORDER.lastUpdate]: string;
  [RASH_ORDER.customerType]: string;
  [RASH_ORDER.crossType]: string;
  [RASH_ORDER.intermarketSweep]: string;
  [RASH_ORDER.reactiveTradeNow]: string;
}

export const RASH_FIELD_PREFIX = "RASH_OE_";

export const RASH_IMPLIED_DECIMAL = 10000;

export enum MATH_OPERATOR {
  DIVIDE,
  MULTIPLY,
}

export const convertRashOrderPrices = (order: RashOrder, operator: MATH_OPERATOR) => {
  rashPriceFields.forEach(fieldName => {
    try {
      switch (operator) {
        case MATH_OPERATOR.DIVIDE:
          order[fieldName] = new Decimal(order[fieldName] ? order[fieldName] : 0)
            .dividedBy(RASH_IMPLIED_DECIMAL)
            .toNumber();
          break;
        case MATH_OPERATOR.MULTIPLY:
          order[fieldName] = new Decimal(order[fieldName] ? order[fieldName] : 0)
            .times(RASH_IMPLIED_DECIMAL)
            .toNumber();
          break;
      }
    } catch {
      console.warn(`Cannot convert '${fieldName}'=${order[fieldName]} to decimal.`);
      order[fieldName] = NaN;
    }
  });
};

type RASH_PRICE_FIELD =
  | RASH_ORDER.price
  | RASH_ORDER.discretionPrice
  | RASH_ORDER.discretionPegDifference
  | RASH_ORDER.pegDifference;

export const rashPriceFields: RASH_PRICE_FIELD[] = [
  RASH_ORDER.price,
  RASH_ORDER.discretionPrice,
  RASH_ORDER.discretionPegDifference,
  RASH_ORDER.pegDifference,
];

export interface RashCancelResponse extends RashRejectResponse {
  [RASH_ORDER.id]: string;
  [RASH_ORDER.orderId]: string;
}

export enum RASH_CAPACITY_VALUE {
  AGENCY = "A",
  PRINCIPAL = "P",
  RISKLESS = "R",
}

export const RASH_CAPACITY_OPTIONS = {
  [RASH_CAPACITY_VALUE.AGENCY]: { value: RASH_CAPACITY_VALUE.AGENCY, label: "Agency" },
  [RASH_CAPACITY_VALUE.PRINCIPAL]: { value: RASH_CAPACITY_VALUE.PRINCIPAL, label: "Principal" },
  [RASH_CAPACITY_VALUE.RISKLESS]: { value: RASH_CAPACITY_VALUE.RISKLESS, label: "Riskless" },
};

export enum RASH_SIDE_VALUE {
  BUY = "B",
  SELL = "S",
  SHORT = "T",
  EXEMPT = "E",
}

export const RASH_SIDE_OPTIONS = {
  [RASH_SIDE_VALUE.BUY]: { value: RASH_SIDE_VALUE.BUY, label: "Buy" },
  [RASH_SIDE_VALUE.SELL]: { value: RASH_SIDE_VALUE.SELL, label: "Sell" },
  [RASH_SIDE_VALUE.SHORT]: { value: RASH_SIDE_VALUE.SHORT, label: "Short" },
  [RASH_SIDE_VALUE.EXEMPT]: { value: RASH_SIDE_VALUE.EXEMPT, label: "Short Exempt" },
};

export enum RASH_PEG_TYPE_VALUE {
  MARKET = "P",
  MARKET_MAKER = "Q",
  MIDPOINT = "M",
  NONE = "N",
  PRIMARY = "R",
  INAV_PEG = "I",
}

export const RASH_PEG_TYPE_OPTIONS = {
  [RASH_PEG_TYPE_VALUE.MARKET]: { value: RASH_PEG_TYPE_VALUE.MARKET, label: "Market" },
  [RASH_PEG_TYPE_VALUE.MARKET_MAKER]: {
    value: RASH_PEG_TYPE_VALUE.MARKET_MAKER,
    label: "Market Maker",
  },
  [RASH_PEG_TYPE_VALUE.MIDPOINT]: { value: RASH_PEG_TYPE_VALUE.MIDPOINT, label: "Midpoint" },
  [RASH_PEG_TYPE_VALUE.NONE]: { value: RASH_PEG_TYPE_VALUE.NONE, label: "None" },
  [RASH_PEG_TYPE_VALUE.PRIMARY]: { value: RASH_PEG_TYPE_VALUE.PRIMARY, label: "Primary" },
  [RASH_PEG_TYPE_VALUE.INAV_PEG]: { value: RASH_PEG_TYPE_VALUE.INAV_PEG, label: "INAV Peg" },
};

export enum RASH_EXCHANGE_VALUE {
  BX = "BX",
  NASDAQ = "NASDAQ",
  PSX = "PSX",
}

export const RASH_EXCHANGE_OPTIONS = {
  [RASH_EXCHANGE_VALUE.BX]: { value: RASH_EXCHANGE_VALUE.BX, label: RASH_EXCHANGE_VALUE.BX },
  [RASH_EXCHANGE_VALUE.NASDAQ]: {
    value: RASH_EXCHANGE_VALUE.NASDAQ,
    label: RASH_EXCHANGE_VALUE.NASDAQ,
  },
  [RASH_EXCHANGE_VALUE.PSX]: { value: RASH_EXCHANGE_VALUE.PSX, label: RASH_EXCHANGE_VALUE.PSX },
};

export const ORDER_ENTRY_SELECT_INPUTS = [
  RASH_ORDER.side,
  RASH_ORDER.capacity,
  RASH_ORDER.pegType,
  RASH_ORDER.discretionPegType,
  RASH_ORDER.exchange,
];

export enum PEG_DIFFERENCE_SIGN {
  MINUS = "-",
  PLUS = "+",
}
