import React, { memo } from "react";
import { Section, Flex } from "@nef/core";
import { NarrowBarItem } from "./narrowBarItem";
import { useStatsContext } from "./context";
import { HorizontalShadowedScrollbar } from "../scrollbars";
import { Status } from "../../wksConstants";
import { WarningSection } from "./warningStyled";
import { FlexNarrowBar } from "components/styled";

const NarrowBarTop = () => {
  const [data] = useStatsContext();

  return (
    <WarningSection $isWarning={data.hasData && data.feedStatus === Status.ERROR}>
      <HorizontalShadowedScrollbar style={{ height: "68px", backgroundColor: "#fff" }}>
        <FlexNarrowBar justifyContent="space-between">
          <Flex style={{ margin: "auto" }}>
            <Flex role="list" justifyContent="center" alignItems="center" style={{ flex: 4 }}>
              <p>Exec Capacity:</p>
              {Object.entries(data.narrowBarMiddle_values)
                .slice(0, 3)
                .map(([key, value]) => {
                  const text = data.narrowBarMiddle_meta[key].text;
                  return (
                    <NarrowBarItem
                      text={text}
                      value={value}
                      hasBorder={data.narrowBarMiddle_meta[key].hasBorder}
                      key={`narrowBarMiddle_${text}`}
                    />
                  );
                })}
              <p>Counterparty Capacity:</p>
              {Object.entries(data.narrowBarMiddle_values)
                .slice(3, 6)
                .map(([key, value]) => {
                  const text = data.narrowBarMiddle_meta[key].text;

                  return (
                    <NarrowBarItem
                      text={text}
                      value={value}
                      hasBorder={data.narrowBarMiddle_meta[key].hasBorder}
                      key={`narrowBarMiddle_${text}`}
                    />
                  );
                })}
            </Flex>
            <Flex role="list" justifyContent="center" alignItems="center" style={{ flex: 2 }}>
              <p>Sells:</p>
              {Object.entries(data.narrowBarMiddle_values)
                .slice(6, 8)
                .map(([key, value]) => {
                  const text = data.narrowBarMiddle_meta[key].text;

                  return (
                    <NarrowBarItem
                      text={text}
                      value={value}
                      hasBorder={data.narrowBarMiddle_meta[key].hasBorder}
                      key={`narrowBarMiddle_${text}`}
                    />
                  );
                })}
              <p>Step:</p>
              {Object.entries(data.narrowBarMiddle_values)
                .slice(8, 10)
                .map(([key, value]) => {
                  const text = data.narrowBarMiddle_meta[key].text;

                  return (
                    <NarrowBarItem
                      text={text}
                      value={value}
                      hasBorder={data.narrowBarMiddle_meta[key].hasBorder}
                      key={`narrowBarMiddle_${text}`}
                    />
                  );
                })}
            </Flex>
          </Flex>
        </FlexNarrowBar>
      </HorizontalShadowedScrollbar>
    </WarningSection>
  );
};
export default NarrowBarTop;
