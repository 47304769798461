import React, { useMemo } from "react";
import { ViewForm, ViewForms, ViewTable } from "viewConstants";
import { Views } from "viewConstants";
import { CloseButton, RecapButton, RepairButton } from "components/standardTable/buttons";
import { useUserContext } from "components/user";
import { ViewActions } from "../../../../viewConstants";

export const RejectsButtons = () => {
  const [userData] = useUserContext();

  const Buttons = useMemo(() => {
    const buttons = [];
    if (userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
      buttons.push(
        <RepairButton
          key="repair"
          table={ViewTable[Views.REJECTS]}
          form={ViewForms[Views.REJECTS][ViewForm.REPAIR]}
        />
      );
      buttons.push(<CloseButton key="close" table={ViewTable[Views.REJECTS]} />);
    }
    buttons.push(<RecapButton table={ViewTable[Views.REJECTS]} />);
    return buttons;
  }, [userData.allowed.actions]);

  return Buttons;
};
