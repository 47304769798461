import { Submit, useFormContext, useFormDispatch } from "components/form";
import React, { useCallback, useState } from "react";
import {
  errorCallbackCB,
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { useUserContext } from "components/user";
import { handleAbortCallback, handleOk, setFormLoading, setTableLoading } from ".";
import { actionCallbacks } from "components/standardTable/actionHooks";
import { network } from "components/reportScan/network";
import { FieldNames } from "components/fields/fieldConstants";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const accentName = "primary";
const buttonText = "Scan";

export const GenericScanSubmit = ({ table, form, action }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const [abort, setAbort] = useState(null);

  const errorCallback = useCallback(
    errorCallbackCB({ formDispatch, standardTableDispatch, table, form }),
    [formDispatch, standardTableDispatch, form, table]
  );

  const callback = useCallback(
    (tableData, truncated) => {
      actionCallbacks.genericScan(
        formDispatch,
        standardTableData[table].maxTableSize,
        standardTableDispatch,
        tableData,
        truncated,
        table,
        form
      );
    },
    [formDispatch, standardTableData, standardTableDispatch, form, table]
  );

  const handleAbort = useCallback(() => {
    if (abort !== null) {
      abort.abort();
    }
  }, [abort]);

  const abortCallback = useCallback(() => {
    handleAbortCallback({
      standardTableDispatch,
      formDispatch,
      table,
      form,
    });
  }, [standardTableDispatch, formDispatch, form, table]);

  const onOk = useCallback(() => {
    handleOk({
      formDispatch,
      form,
    });
  }, [formDispatch, form]);

  const handleSubmit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form,
      userData,
    });

    if (!valid) {
      return;
    }

    const fieldData = [{ ...formData[form.key].fields }];
    if (Array.isArray(fieldData[0][FieldNames.stepInOut])) {
      const stepInOutVals = fieldData[0][FieldNames.stepInOut].map(option => option.value);
      delete fieldData[0][FieldNames.stepInOut];
      fieldData[0][FieldNames.stepInOutVals] = stepInOutVals;
    }

    standardTableDispatch({
      type: "CLEAR_SELECTED",
      payload: { table },
    });

    const abortController = new AbortController();
    setAbort(abortController);
    network(userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS])[
      `do${action}`
    ](
      fieldData,
      callback,
      errorCallback,
      abortCallback,
      abortController,
      standardTableData[table].maxTableSize,
      onOk
    );

    setFormLoading(formDispatch, form);
    setTableLoading(standardTableDispatch, table);
  }, [
    formData,
    formDispatch,
    userData,
    standardTableDispatch,
    standardTableData,
    callback,
    errorCallback,
    abortCallback,
    onOk,
    form,
    table,
    action,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      isDisabled={standardTableData[table]?.isLoading}
      isLoading={formData[form.key]?.isLoading}
      errors={Object.keys(formData[form.key].totalFormErrors).length}
      abortText={"Hold to Cancel Scan"}
      onAbort={handleAbort}
    />
  );
};
