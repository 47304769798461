import React, { useReducer, createContext, useContext, useEffect } from "react";
import { useAggregateLimitContext } from "../limitMonitor";
import { RequestResult } from "wksConstants";
import { FieldNames } from "components/fields/fieldConstants";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { useMPIDOptionsContext } from "components/user/mpidContext";

const rightDispatch = createContext();
export const useRightDispatch = () => {
  return useContext(rightDispatch);
};

const rightContext = createContext();
export const useRightContext = () => {
  return useContext(rightContext);
};

export const rightDefaultState = {
  risk: [],
  initialRiskPayloadLoaded: false,
  clearingRelationships: [],
  riskIsFresh: true,
  statsIsFresh: true,
  [FieldNames.mpid]: undefined,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_RISK_DATA": {
      return {
        ...state,
        risk: action.payload,
        initialRiskPayloadLoaded: true,
      };
    }
    case "SET_HAS_FIRST_PAYLOAD": {
      return { ...state, initialRiskPayloadLoaded: true };
    }
    case "SET_CLEARING_RELATIONSHIPS": {
      return {
        ...state,
        clearingRelationships: action.payload.relationships,
        [FieldNames.mpid]: action.payload[FieldNames.mpid],
      };
    }
    case "DUMP_SETTINGS": {
      const { userData, activeRiskMpidOptions } = action.payload;
      const rightRiskMPIDS = userData.find(s => s.name === "rightLimitMPIDS")?.data;
      let clearingReturn = [];
      const tempMPIDs = [];
      let parsed;
      if (rightRiskMPIDS) {
        parsed = JSON.parse(rightRiskMPIDS);
      }

      // if the user doesn't have a saved pref, load all their mpids
      if (parsed && parsed[FieldNames.mpid]?.length > 0) {
        clearingReturn = parsed[FieldNames.mpid];
      } else {
        activeRiskMpidOptions.forEach(({ id, label, value, children }) => {
          clearingReturn.push({ id, value, label });
          children.forEach(c => clearingReturn.push(c));
        });
        activeRiskMpidOptions.forEach(r => {
          tempMPIDs.push(r);
          r.children.forEach(c => tempMPIDs.push(c));
        });
      }

      return {
        ...state,
        clearingRelationships: clearingReturn,
        [FieldNames.mpid]: JSON.stringify({ [FieldNames.mpid]: tempMPIDs }),
      };
    }
    default:
      return { ...state };
  }
};

const RightProvider = ({ children, defaultData = {} }) => {
  const [aggregates] = useAggregateLimitContext();
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, rightDefaultState, defaultData)
  );
  const [user] = useUserContext();
  const [mpids] = useMPIDOptionsContext();

  useEffect(() => {
    if (aggregates.hasMadeInitialRequest) {
      dispatchF({ type: "SET_HAS_FIRST_PAYLOAD" });
    }
  }, [aggregates.hasMadeInitialRequest]);

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.userData] &&
      mpids.clearCorrMPIDOptions.isReady
    ) {
      dispatchF({
        type: "DUMP_SETTINGS",
        payload: {
          userData: user[INITIAL_DATA_MODEL.userData],
          activeRiskMpidOptions: mpids.clearCorrMPIDOptions.activeRiskMpidOptions,
        },
      });
    }
  }, [mpids.clearCorrMPIDOptions.activeRiskMpidOptions, mpids.clearCorrMPIDOptions.isReady, user]);

  useEffect(() => {
    let hasUpdate = false;
    const updatedData = { ...state.risk };

    Object.keys(aggregates.data).forEach(key => {
      if (state.clearingRelationships[key]) {
        // check for update
        if (!updatedData[key]) {
          updatedData[key] = aggregates.data[key];
          hasUpdate = true;
        } else if (
          updatedData[key].rskCfgTS !== aggregates.data[key].rskCfgTS ||
          updatedData[key].agrTS !== aggregates.data[key].agrTS
        ) {
          updatedData[key] = aggregates.data[key];
          hasUpdate = true;
        }
      } else if (updatedData[key]) {
        // delete from updatedData
        delete updatedData[key];
        hasUpdate = true;
      }
    });

    if (hasUpdate) {
      dispatchF({
        type: "SET_RISK_DATA",
        payload: updatedData,
      });
    }
  }, [aggregates.data, state.clearingRelationships, state.risk, state]);

  return (
    <rightDispatch.Provider value={dispatchF}>
      <rightContext.Provider value={[state, dispatchF]}>{children}</rightContext.Provider>
    </rightDispatch.Provider>
  );
};

export default RightProvider;
