import { useRefDataContext } from "components/refData";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { useMPIDOptionsContext } from "components/user/mpidContext";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { safeParseJSON } from "utils/js.utils";
import { RequestResult, SELECT_ALL, SettingNames, Status } from "wksConstants";
import { getRelationships } from "./constants";
import { FieldNames } from "../fields";
import { useAggregateLimitContext } from "./aggregates";

const limitMonitorDispatch = createContext();
limitMonitorDispatch.displayName = "LimitMonitorDispatch";
export const useLimitMonitorDispatch = () => {
  return useContext(limitMonitorDispatch);
};

const limitMonitorContext = createContext();
limitMonitorContext.displayName = "LimitMonitorContext";
export const useLimitMonitorContext = () => {
  return useContext(limitMonitorContext);
};

const defaultState = {
  clearingRelationships: {},
  data: {},
  status: Status.NO_STATUS,
  isLoading: false,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_DATA": {
      return { ...state, data: action.payload };
    }
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_CLEARING_RELATIONSHIPS": {
      return { ...state, clearingRelationships: action.payload };
    }
    default:
      return { ...state };
  }
};

const LimitMonitorProvider = ({ children, defaultData }) => {
  const [aggregates] = useAggregateLimitContext();
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();
  const [mpids] = useMPIDOptionsContext();
  const [refData] = useRefDataContext();

  useEffect(() => {
    const actions = [];
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      Array.isArray(user[INITIAL_DATA_MODEL.userData]) &&
      refData.isClearingReady &&
      mpids.clearCorrMPIDOptions.isReady
    ) {
      const setting = user[INITIAL_DATA_MODEL.userData].find(
        userSetting => userSetting.name === SettingNames.LIMO_MPID
      );
      if (setting && refData.isClearingReady) {
        const fields = safeParseJSON(setting.data);
        let selected = fields[FieldNames.mpid];
        if (selected === SELECT_ALL) {
          selected = mpids.clearCorrMPIDOptions.activeRiskMpidOptions.reduce((acc, curr) => {
            return acc.concat(curr.children);
          }, []);
        }
        if (Array.isArray(selected)) {
          const { clearingRelationships } = getRelationships(
            refData.correspondentMpidRelationships,
            selected
          );
          dispatchF({
            type: "SET_CLEARING_RELATIONSHIPS",
            payload: clearingRelationships,
          });
        }
      }
    }
    dispatchF(actions);
  }, [refData, mpids, user]);

  useEffect(() => {
    let hasUpdate = false;
    const updatedData = { ...state.data };

    Object.keys(aggregates.data).forEach(key => {
      if (state.clearingRelationships[key]) {
        // check for update
        if (!updatedData[key]) {
          updatedData[key] = aggregates.data[key];
          hasUpdate = true;
        } else if (
          updatedData[key].rskCfgTS !== aggregates.data[key].rskCfgTS ||
          updatedData[key].agrTS !== aggregates.data[key].agrTS
        ) {
          updatedData[key] = aggregates.data[key];
          hasUpdate = true;
        }
      } else if (updatedData[key]) {
        // delete from updatedData
        delete updatedData[key];
        hasUpdate = true;
      }
    });
    if (hasUpdate) {
      dispatchF({
        type: "SET_DATA",
        payload: updatedData,
      });
    }
  }, [aggregates.data, state.clearingRelationships, state.data]);

  useEffect(() => {
    dispatchF({
      type: "SET_LOADING",
      payload: aggregates.isLoading,
    });
  }, [aggregates.isLoading]);

  return (
    <limitMonitorDispatch.Provider value={dispatchF}>
      <limitMonitorContext.Provider value={[state, dispatchF]}>
        {children}
      </limitMonitorContext.Provider>
    </limitMonitorDispatch.Provider>
  );
};

export default LimitMonitorProvider;
