import { Tooltip } from "@nef/core";
import { getYesOrNo } from "components/settings/ptra/columns";
import React, { useMemo } from "react";
import { getHoldDefaultActionLabel, getPerTradeDefaultActionLabel } from "wksConstants";
import { PTRAConfigCacheDTO } from "./constant";
import styled from "styled-components";
import { getConvertedRiskLimitsForUI } from "components/limitMonitor/constants";

const TooltipContentWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RiskConfigAlertTooltip: React.FC<{
  alert: PTRAConfigCacheDTO;
  isOpen: boolean;
  target: string;
}> = ({ alert, isOpen, target }) => {
  const convertedDTO: PTRAConfigCacheDTO = useMemo(() => {
    return getConvertedRiskLimitsForUI(alert);
  }, [alert]);

  return (
    <Tooltip target={target} isOpen={isOpen} placement="right-start">
      <TooltipContentWraper>
        {alert.isAlertActiveChanged ? (
          <span>Alert Limits Enabled: {getYesOrNo(alert.isAlertActive)}</span>
        ) : undefined}
        {alert.buyAlertLimitChanged ? (
          <span>BUY Alert Limit: {convertedDTO.buyAlertLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.sellAlertLimitChanged ? (
          <span>SELL Alert Limit: {convertedDTO.sellAlertLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.netAlertLimitChanged ? (
          <span>NET Alert Limit: {convertedDTO.netAlertLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.isHoldActiveChanged ? (
          <span>Hold Limits Enabled: {getYesOrNo(alert.isHoldActive)}</span>
        ) : undefined}
        {alert.buyHoldLimitChanged ? (
          <span>BUY Hold Limit: {convertedDTO.buyHoldLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.sellHoldLimitChanged ? (
          <span>SELL Hold Limit: {convertedDTO.sellHoldLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.netHoldLimitChanged ? (
          <span>NET Hold Limit: {convertedDTO.netHoldLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.isRejectActiveChanged ? (
          <span>Kill Limits Enabled: {getYesOrNo(alert.isRejectActive)}</span>
        ) : undefined}
        {alert.buyRejectLimitChanged ? (
          <span>BUY Kill Limit: {convertedDTO.buyRejectLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.sellRejectLimitChanged ? (
          <span>SELL Kill Limit: {convertedDTO.sellRejectLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.netRejectLimitChanged ? (
          <span>NET Kill Limit: {convertedDTO.netRejectLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.isPerTradeActiveChanged ? (
          <span>Per Trade Limits Enabled: {getYesOrNo(alert.isPerTradeActive)}</span>
        ) : undefined}
        {alert.perTradeBuyLimitChanged ? (
          <span>Per Trade BUY Limit: {convertedDTO.perTradeBuyLimit}</span>
        ) : undefined}
        {alert.perTradeSellLimitChanged ? (
          <span>Per Trade SELL Limit: {convertedDTO.perTradeSellLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.isMaxTradeActiveChanged ? (
          <span>Max Trade Limits Enabled: {getYesOrNo(alert.isMaxTradeActive)}</span>
        ) : undefined}
        {alert.maxTradeLimitChanged ? (
          <span>Max Trade Limit: {convertedDTO.maxTradeLimit?.toLocaleString()}</span>
        ) : undefined}
        {alert.buyDefaultActionChanged ? (
          <span>Hold Default Action: {getHoldDefaultActionLabel(alert.buyDefaultAction)}</span>
        ) : undefined}
        {alert.perTradeBuyActionChanged ? (
          <span>
            Per Trade Default Action: {getPerTradeDefaultActionLabel(alert.perTradeBuyAction)}
          </span>
        ) : undefined}
        {alert.useNetTradingChanged ? (
          <span>Buy/Sell or Net: {alert.useNetTrading ? "Net" : "Buy/Sell"}</span>
        ) : undefined}
      </TooltipContentWraper>
    </Tooltip>
  );
};
