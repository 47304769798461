import React from "react";
import { Header } from "@nef/core";
import { useSettingsContext } from "../../settings";

export const TradesHeader = ({ validRows, invalidRowCount, text }) => {
  return (
    <>
      <Header size={3}>{`${text} ${validRows.length} trade(s)?`}</Header>
      {invalidRowCount > 0 && (
        <p>{`${invalidRowCount} trade(s) ignored due to invalid state`}</p>
      )}
    </>
  );
};

export const useTradesConfirmationSettingsFn = () => {
  const [settings] = useSettingsContext();
  return settings.confirmTableHotButtons;
};
