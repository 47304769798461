import React, { useCallback, useEffect, useMemo } from "react";
import { NotificationHub } from "@nef/core";
import { ActiveForm, EqrcRuleStatus } from ".";
import { EqrcFields, getEqrcIdFromResponse, getRowValuesForAction } from "./constants";
import {
  useStandardTableContext,
  useStandardTableDispatch,
  getSelectedRows,
  getFormValidation,
} from "components/standardTable";
import { useFormContext, useFormDispatch } from "../../form";
import { StandardTables } from "wksConstants";
import { createRule } from "./network";
import { eqrcAlertConfigurationFromRuleFields } from "../../fields";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";

export const EQRCNewAlertForm = ({ table, header, isOpen, onClose, ruleType, form }) => {
  const [tableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const fieldSets = useMemo(() => {
    return [
      {
        fields: eqrcAlertConfigurationFromRuleFields,
      },
    ];
  }, []);

  const handleCreate = useCallback(
    (table, json) => {
      const eqrcId = getEqrcIdFromResponse(json);
      json[EqrcFields.eqrcId] = eqrcId;

      let data = { [EqrcFields.eqrcId]: eqrcId, [EqrcRuleStatus.active]: json };
      if (table !== StandardTables.EQRC_ALERT_CONFIG) {
        data = { [EqrcFields.eqrcId]: eqrcId, [json[EqrcFields.status]]: json };
      }

      standardTableDispatch({
        type: "ADD_TABLE_DATA",
        payload: { table, data },
      });
      NotificationHub.send("success", "Alert configuration has been created");
    },
    [standardTableDispatch]
  );

  const handleCreateError = useCallback((table, error, cbArgs) => {
    const message = error?.message ? (
      error.message
    ) : (
      <ul>
        {Object.entries(error).map(([field, message]) => (
          <li key={`${field}_${message.replace(" ", "_")}`}>{`${field} ${message}`}</li>
        ))}
      </ul>
    );

    NotificationHub.send("danger", "Failed to create alert configuration", { subtitle: message });
  }, []);

  const createPostBody = useCallback(
    (row, fields) => {
      let body = {};
      if (row?.[EqrcFields.mpid]) {
        body[EqrcFields.mpid] = row[EqrcFields.mpid];
      }
      if (row?.[EqrcFields.port]) {
        body[EqrcFields.port] = row[EqrcFields.port];
      }
      // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
      if (row.hasOwnProperty(EqrcFields.groupId)) {
        body[EqrcFields.groupId] = row[EqrcFields.groupId];
      }
      if (row?.[EqrcFields.exchange]) {
        body[EqrcFields.exchange] = row[EqrcFields.exchange];
      }
      if (ruleType?.value) {
        body[EqrcFields.ruleType] = ruleType.value;
      }
      if (fields?.[EqrcFields.emailAddresses]) {
        body[EqrcFields.emailAddresses] = Object.values(fields[EqrcFields.emailAddresses]).map(
          item => item.value
        );
      }
      if (fields?.[EqrcFields.active]) {
        body[EqrcFields.active] = fields[EqrcFields.active];
      }
      return body;
    },
    [ruleType]
  );

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );

  const handleSave = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);

    if (!valid) {
      return;
    }
    let fields = formData[form.key].fields;
    const selected = getSelectedRows(tableData[table]);

    selected.forEach(row => {
      let body = createPostBody(getRowValuesForAction(row), fields);
      createRule(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
        null,
        JSON.stringify(body),
        StandardTables.EQRC_ALERT_CONFIG,
        handleCreate,
        handleCreateError
      );
    });

    standardTableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: table },
    });

    onClose();
    formDispatch({ type: "RESET_FORM", payload: { form } });
  }, [
    form,
    formData,
    formDispatch,
    tableData,
    table,
    standardTableDispatch,
    onClose,
    createPostBody,
    userData,
    handleCreate,
    handleCreateError,
  ]);

  return (
    <ActiveForm
      table={table}
      header={header}
      form={form}
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      includeExchMpidPort={true}
      validate={validate}
    />
  );
};
