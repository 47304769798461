import React, { useMemo } from "react";
import { Header, Popover, PopoverBody } from "@nef/core";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { useUploadCacheContext } from ".";

const QueueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Queue = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 4px solid #f2a900;
  color: #8f6400;
  background-color: #fff3d7;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 12px;
`;

export const UploadQueue = ({ id }) => {
  const [uploadCache] = useUploadCacheContext();
  const [isMouseOver, setMouseOver] = useState(false);

  const { queueLength, files } = useMemo(() => {
    const files = Object.values(uploadCache.queue);
    return { queueLength: files.length, files };
  }, [uploadCache.queue]);

  if (queueLength === 0) {
    return <></>;
  }

  return (
    <QueueWrapper
      id={id}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <Queue>{queueLength > 9 ? "!" : queueLength}</Queue>
      <Popover body="" placement="bottom-start" isOpen={isMouseOver} target={id} width={250}>
        <PopoverBody>
          <Header size={3}>{queueLength} Upload(s) in Queue</Header>
          {files.map(file => (
            <div key={file.id}>{file.fileName}</div>
          ))}
        </PopoverBody>
      </Popover>
    </QueueWrapper>
  );
};
