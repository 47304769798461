import { NotificationHub } from "@nef/core";
import { ApiResponseNames, FieldNames, SelectOptions } from "components/fields";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import { FirmType, getHoldDefaultActionLabel, getPerTradeDefaultActionLabel } from "wksConstants";

export const RISK_LIMIT_HEADERS_MAP = {
  [FieldNames.clearingFirmNum]: "Clearing #",
  [FieldNames.isPostTradeRiskServiceActive]: "Post Trade Risk Service Active",
  [FieldNames.correspondentMPID]: "Correspondent MPID",
  [FieldNames.dataSources]: "Data Sources",
  [FieldNames.updateTrfLimits]: "Update TRF Limits",
  [FieldNames.useNetTrading]: "Use Net Trading",
  [FieldNames.isMaxTradeActive]: "Max Trade Active",
  [FieldNames.maxTradeLimit]: "Max Trade Limit",
  [FieldNames.isPerTradeActive]: "Per Trade Active",
  [FieldNames.perTradeBuyLimit]: "Per Trade Buy Limit",
  [FieldNames.perTradeBuyAction]: "Per Trade Buy Action",
  [FieldNames.perTradeSellLimit]: "Per Trade Sell Limit",
  [FieldNames.perTradeSellAction]: "Per Trade Sell Action",
  [FieldNames.isAlertActive]: "Alert Active",
  [FieldNames.buyAlertLimit]: "Buy Alert Limit",
  [FieldNames.sellAlertLimit]: "Sell Alert Limit",
  [FieldNames.netAlertLimit]: "Net Alert Limit",
  [FieldNames.isHoldActive]: "Hold Active",
  [FieldNames.buyHoldLimit]: "Buy Hold Limit",
  [FieldNames.buyDefaultAction]: "Hold Default Buy Action",
  [FieldNames.sellHoldLimit]: "Sell Hold Limit",
  [FieldNames.sellDefaultAction]: "Hold Default Sell Action",
  [FieldNames.netHoldLimit]: "Net Hold Limit",
  [FieldNames.isRejectActive]: "Kill Limit Active",
  [FieldNames.buyRejectLimit]: "Buy Kill Limit",
  [FieldNames.sellRejectLimit]: "Sell Kill Limit",
  [FieldNames.netRejectLimit]: "Net Kill Limit",
  [FieldNames.trfLimits]: "Update TRF Limits",
};

export const RISK_LIMIT_ACCESSOR_MAP = {
  [FieldNames.correspondentMPID]: config => config[ApiResponseNames.correspondentMPID],
  [FieldNames.clearingFirmNum]: config => config[ApiResponseNames.clearingFirmNum],
  [FieldNames.dataSources]: config =>
    Array.isArray(config[ApiResponseNames.dataSources])
      ? config[ApiResponseNames.dataSources]
          .map(source => {
            return SelectOptions[FieldNames.dataSources].find(option => option.value === source)
              ?.label;
          })
          .join(", ")
      : "",
  [FieldNames.updateTrfLimits]: config => config[ApiResponseNames.updateTrfLimits],
  [FieldNames.useNetTrading]: config => config[ApiResponseNames.useNetTrading],
  [FieldNames.isMaxTradeActive]: config => config[ApiResponseNames.isMaxTradeActive],
  [FieldNames.maxTradeLimit]: config => config[ApiResponseNames.maxTradeLimit],
  [FieldNames.isPerTradeActive]: config => config[ApiResponseNames.isPerTradeActive],
  [FieldNames.perTradeBuyLimit]: config => config[ApiResponseNames.perTradeBuyLimit],
  [FieldNames.perTradeBuyAction]: config =>
    getPerTradeDefaultActionLabel(config[ApiResponseNames.perTradeBuyAction]),
  [FieldNames.perTradeSellLimit]: config => config[ApiResponseNames.perTradeSellLimit],
  [FieldNames.perTradeSellAction]: config =>
    getPerTradeDefaultActionLabel(config[ApiResponseNames.perTradeSellAction]),
  [FieldNames.isAlertActive]: config => config[ApiResponseNames.isAlertActive],
  [FieldNames.buyAlertLimit]: config => config[ApiResponseNames.buyAlertLimit],
  [FieldNames.sellAlertLimit]: config => config[ApiResponseNames.sellAlertLimit],
  [FieldNames.netAlertLimit]: config => config[ApiResponseNames.netAlertLimit],
  [FieldNames.isHoldActive]: config => config[ApiResponseNames.isHoldActive],
  [FieldNames.buyHoldLimit]: config => config[ApiResponseNames.buyHoldLimit],
  [FieldNames.buyDefaultAction]: config =>
    getHoldDefaultActionLabel(config[ApiResponseNames.buyDefaultAction]),
  [FieldNames.sellHoldLimit]: config => config[ApiResponseNames.sellHoldLimit],
  [FieldNames.sellDefaultAction]: config =>
    getHoldDefaultActionLabel(config[ApiResponseNames.sellDefaultAction]),
  [FieldNames.netHoldLimit]: config => config[ApiResponseNames.netHoldLimit],
  [FieldNames.isRejectActive]: config => config[ApiResponseNames.isRejectActive],
  [FieldNames.buyRejectLimit]: config => config[ApiResponseNames.buyRejectLimit],
  [FieldNames.sellRejectLimit]: config => config[ApiResponseNames.sellRejectLimit],
  [FieldNames.netRejectLimit]: config => config[ApiResponseNames.netRejectLimit],
  [FieldNames.isPostTradeRiskServiceActive]: config => config[ApiResponseNames.isRiskConfigActive],
  [FieldNames.trfLimits]: config => config[ApiResponseNames.trfLimits],
};

export const EXPORT_RISK_LIMIT_FAILED_MESSAGE = "Export failed";
export const EXPORT_RISK_LIMIT_RECEIVED_MESSAGE = "Export request received";

export const exportRiskLimitConfigs = (fileName, configs) => {
  const headers = Object.keys(RISK_LIMIT_HEADERS_MAP).map(key => RISK_LIMIT_HEADERS_MAP[key]);
  const accessors = {};
  Object.keys(RISK_LIMIT_ACCESSOR_MAP).forEach(
    key => (accessors[RISK_LIMIT_HEADERS_MAP[key]] = RISK_LIMIT_ACCESSOR_MAP[key])
  );
  createAndDownloadTemplate(fileName, convertToCSV(headers, configs, accessors)).then(() =>
    NotificationHub.send("success", "Export request has been completed")
  );
};

export const getConvertedRiskAggregatesForUI = dto => {
  const riskAggregatesDto = { ...dto };
  const aggregateKeys = [
    ApiResponseNames.totalBuy,
    ApiResponseNames.totalSell,
    ApiResponseNames.totalNet,
    ApiResponseNames.totalBuyProforma,
    ApiResponseNames.totalSellProforma,
    ApiResponseNames.totalNetProforma,
  ];
  if (typeof riskAggregatesDto === "object") {
    aggregateKeys.forEach(key => {
      riskAggregatesDto[key] = parseInt(riskAggregatesDto[key]) / 100;
    });
  }
  return riskAggregatesDto;
};

export const getRiskAggregatesKey = (correspondentMPID, clearingNum) => {
  return `${correspondentMPID}_${clearingNum}`;
};

export const getConvertedRiskLimitsForUI = dto => {
  const riskConfigDto = { ...dto };
  const limitKeys = [
    ApiResponseNames.buyAlertLimit,
    ApiResponseNames.buyHoldLimit,
    ApiResponseNames.buyRejectLimit,
    ApiResponseNames.sellAlertLimit,
    ApiResponseNames.sellHoldLimit,
    ApiResponseNames.sellRejectLimit,
    ApiResponseNames.netAlertLimit,
    ApiResponseNames.netHoldLimit,
    ApiResponseNames.netRejectLimit,
    ApiResponseNames.perTradeBuyLimit,
    ApiResponseNames.perTradeSellLimit,
    ApiResponseNames.maxTradeLimit,
  ];

  if (typeof riskConfigDto === "object") {
    limitKeys.forEach(key => {
      riskConfigDto[key] = parseInt(riskConfigDto[key]) / 100;
    });
  }
  return riskConfigDto;
};

export const getConvertedRiskLimitsForDB = dto => {
  const riskConfigDto = { ...dto };
  const limitKeys = [
    ApiResponseNames.buyAlertLimit,
    ApiResponseNames.buyHoldLimit,
    ApiResponseNames.buyRejectLimit,
    ApiResponseNames.sellAlertLimit,
    ApiResponseNames.sellHoldLimit,
    ApiResponseNames.sellRejectLimit,
    ApiResponseNames.netAlertLimit,
    ApiResponseNames.netHoldLimit,
    ApiResponseNames.netRejectLimit,
    ApiResponseNames.perTradeBuyLimit,
    ApiResponseNames.perTradeSellLimit,
    ApiResponseNames.maxTradeLimit,
  ];

  if (typeof riskConfigDto === "object") {
    limitKeys.forEach(key => {
      riskConfigDto[key] = parseInt(riskConfigDto[key]) * 100;
    });
  }
  return riskConfigDto;
};

export const getRelationships = (correspondentMpidRelationships, selected) => {
  if (typeof correspondentMpidRelationships === "object" && Array.isArray(selected)) {
    return selected.reduce(
      (acc, curr) => {
        const option = JSON.parse(curr.value);
        const key = getRiskAggregatesKey(option?.correspondentMPID, option?.clearingNum);
        acc.clearingRelationships[key] = true;

        if (Array.isArray(correspondentMpidRelationships[option?.correspondentMPID])) {
          const relationship = correspondentMpidRelationships[option?.correspondentMPID].find(
            rel => rel.clearingNum === option?.clearingNum
          );
          if (relationship) {
            const { id } = relationship;
            acc.clearingRelationshipIds[id] = true;
          }
        } // else: relationship not found, do not add... notify?
        return acc;
      },
      { clearingRelationships: {}, clearingRelationshipIds: {} }
    );
  } else {
    return { clearingRelationships: {}, clearingRelationshipIds: {} };
  }
};

export const getTabsForHKTable = (activeAttr, tradeAttr, countAttr, hkCache, refData) => {
  const tabs = {};
  const relationships = [];
  let active = null;
  Object.entries(hkCache.counts).forEach(([id, counts]) => {
    if (counts?.[countAttr] > 0) {
      const relationship = refData.clearing.find(rel => rel.id.toString() === id.toString());
      if (relationship) {
        const mpid = relationship.correspondentMPID;
        const name = `${mpid} (${counts?.[countAttr]})`;
        if (id === hkCache[activeAttr]?.toString()) {
          if (Array.isArray(hkCache?.[tradeAttr])) {
            hkCache[tradeAttr].forEach(trade => {
              trade.mpid = mpid;
              if (trade.executingMPID === mpid) {
                trade.counterpartyMPID = trade.contraMPID;
              } else {
                trade.counterpartyMPID = trade.executingMPID;
              }
            });
          }
          tabs[id] = { name, data: hkCache[tradeAttr] };
          active = id;
        } else {
          tabs[id] = {
            name,
            data: Array(counts?.[countAttr]).fill({}),
          };
        }
        relationships.push(relationship);
      }
    }
  });
  return { tabs, active };
};

export const getEntryMPIDsFromHeldRecord = record => {
  const { contraMPID, contraGiveUpMPID, executingMPID, executingGiveUpMPID } = record;

  let execEntryMPID = executingMPID;
  if (executingGiveUpMPID) {
    execEntryMPID = executingGiveUpMPID;
  }

  let contraEntryMPID = contraMPID;
  if (contraGiveUpMPID) {
    contraEntryMPID = contraGiveUpMPID;
  }
  return { execEntryMPID, contraEntryMPID };
};

export const getDTOForPTRConfigSettings = (fields, refData) => {
  const clearingFirmMPID = fields[FieldNames.clearingFirmMPID]?.value;
  const correspondentMPID = fields[FieldNames.correspondentMPID]?.value;
  if (clearingFirmMPID && correspondentMPID) {
    const clearingFirm = refData.firm.find(
      firm => firm.firmType === FirmType.CLEARING && firm.firmMPID === clearingFirmMPID
    );
    const clearingFirmNum = clearingFirm?.clearingNum;

    let riskConfigDto = {
      clearingFirmMPID,
      clearingFirmNum,
      correspondentMPID,
      active: fields[FieldNames.isPostTradeRiskServiceActive],
      [ApiResponseNames.dataSources]: fields[FieldNames.dataSources]?.map(option => option.value),
      [ApiResponseNames.buyDefaultAction]: fields[FieldNames.holdDefaultAction]?.value,
      [ApiResponseNames.sellDefaultAction]: fields[FieldNames.holdDefaultAction]?.value,
      [ApiResponseNames.useNetTrading]: fields[FieldNames.useNetTrading],
      [ApiResponseNames.isAlertActive]: fields[FieldNames.isAlertActive],
      [ApiResponseNames.buyAlertLimit]: fields[FieldNames.buyAlertLimit],
      [ApiResponseNames.sellAlertLimit]: fields[FieldNames.sellAlertLimit],
      [ApiResponseNames.isHoldActive]: fields[FieldNames.isHoldActive],
      [ApiResponseNames.buyHoldLimit]: fields[FieldNames.buyHoldLimit],
      [ApiResponseNames.sellHoldLimit]: fields[FieldNames.sellHoldLimit],
      [ApiResponseNames.isRejectActive]: fields[FieldNames.isRejectActive],
      [ApiResponseNames.buyRejectLimit]: fields[FieldNames.buyRejectLimit],
      [ApiResponseNames.sellRejectLimit]: fields[FieldNames.sellRejectLimit],
      [ApiResponseNames.netAlertLimit]: fields[FieldNames.netAlertLimit],
      [ApiResponseNames.netHoldLimit]: fields[FieldNames.netHoldLimit],
      [ApiResponseNames.netRejectLimit]: fields[FieldNames.netRejectLimit],
      [ApiResponseNames.isPerTradeActive]: fields[FieldNames.isPerTradeActive],
      [ApiResponseNames.perTradeBuyLimit]: fields[FieldNames.perTradeBuyLimit],
      [ApiResponseNames.perTradeSellLimit]: fields[FieldNames.perTradeSellLimit],
      [ApiResponseNames.perTradeBuyAction]: fields[FieldNames.perTradeBuyAction]?.value,
      // derived from buy action
      [ApiResponseNames.perTradeSellAction]: fields[FieldNames.perTradeBuyAction]?.value,
      [ApiResponseNames.isMaxTradeActive]: fields[FieldNames.isMaxTradeActive],
      [ApiResponseNames.maxTradeLimit]: fields[FieldNames.maxTradeLimit],
      [ApiResponseNames.trfLimits]: fields[FieldNames.trfLimits],
      [ApiResponseNames.lastUpdated]: fields[FieldNames.lastUpdated],
    };
    riskConfigDto = getConvertedRiskLimitsForDB(riskConfigDto);
    return riskConfigDto;
  }
};
