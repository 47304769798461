import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import React, { useCallback, useMemo, useState } from "react";
import { PTRA_ALERT_TYPE, PTRA_CONFIGURATION_ENTITY } from "components/settings/ptra/fields";
import { LIMIT_SIDE_BREACH_VALUES, TRADE_SOURCE_LABELS } from "components/fields";
import { getIdentifierFromAlert, PTRA_BREACH_CACHE_MODEL } from "./constants";
import { usePtraAlertContext, usePtraAlertDispatch } from "./alertCache";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { NotificationHub } from "@nef/core";
import { LoadingIcon } from "components/loadingIcon";

const AlertBodyContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  color: ${getColor("gray", 500)};
  padding: 3px 0px;
`;

const fontSize = "0.875rem";

const AlertDataList = styled.ul`
  grid-column: 1 / 2;
  align-self: end;
  font-size: ${fontSize};
  line-height: ${fontSize};
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 0.25rem;
  position: relative;
`;

export const SharedBtn = `
padding: 0px;
position: relative;
background: none;
border: none;
cursor: pointer;
font-size: ${fontSize};
line-height: ${fontSize};
`;

export const ReadBtn = styled.button`
  ${props => `
    ${SharedBtn}
    grid-column: 2 / 3;
    align-self: ${props.alignSelf};
    justify-self: ${props.justifySelf};
    color: ${
      props.isRead || props.isLoading
        ? getColor("gray", 500)(props)
        : getColor("primary", 500)(props)
    };
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: ${props.isLoading ? "progress" : "pointer"};

    ${
      !props.isLoading
        ? `
      &:hover {
        text-decoration: underline;
        color: ${props.isRead ? getColor("gray", 400)(props) : getColor("primary", 400)(props)}
      }`
        : ""
    }
`}
`;

export const ReadText = styled.span`
  margin-left: 0.25rem;
`;

export const BreachAlertBody = ({ alert, includeMarkAsRead }) => {
  const [ptrAlertData] = usePtraAlertContext();
  const ptrAlertDispatch = usePtraAlertDispatch();
  const [isMarkAsReadLoading, setMarkAsReadLoading] = useState(false);

  const hasPendingRequest = useMemo(() => {
    return isMarkAsReadLoading || ptrAlertData.isMarkAllLoading;
  }, [ptrAlertData.isMarkAllLoading, isMarkAsReadLoading]);

  const isRead = useMemo(() => {
    return !!ptrAlertData.readMap[getIdentifierFromAlert(alert)];
  }, [alert, ptrAlertData.readMap]);

  const handleMarkAsRead = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = json => {
      ptrAlertDispatch({
        type: "SET_READ",
        payload: json,
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as read");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "saveptralertsread"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, ptrAlertDispatch]);

  const handleMarkAsUnread = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = () => {
      ptrAlertDispatch({
        type: "SET_UNREAD",
        payload: [alert],
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as unread");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "deleteunreadptralerts"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, ptrAlertDispatch]);

  const MarkAsRead = useMemo(() => {
    if (includeMarkAsRead) {
      let onClick = undefined;
      if (!hasPendingRequest) {
        if (isRead) {
          onClick = handleMarkAsUnread;
        } else {
          onClick = handleMarkAsRead;
        }
      }
      return (
        <ReadBtn
          alignSelf="end"
          jusfitySelf="end"
          isRead={isRead}
          onClick={onClick}
          isLoading={hasPendingRequest}
        >
          <LoadingIcon isLoading={hasPendingRequest} />
          <ReadText>{isRead ? "Mark as Unread" : "Mark as Read"}</ReadText>
        </ReadBtn>
      );
    } else {
      return undefined;
    }
  }, [includeMarkAsRead, isRead, handleMarkAsUnread, handleMarkAsRead, hasPendingRequest]);

  return (
    <AlertBodyContainer>
      <AlertDataList>
        <li>Risk Entity: {alert[PTRA_BREACH_CACHE_MODEL.breachPartyMpid]}</li>
        <li>
          My Entity:{" "}
          {alert[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own
            ? alert[PTRA_BREACH_CACHE_MODEL.breachPartyMpid]
            : alert[PTRA_BREACH_CACHE_MODEL.counterPartyMpid]}
        </li>
        <li>
          {`Trade ID: ${
            [PTRA_ALERT_TYPE.KILL_LIMIT_REJECT, PTRA_ALERT_TYPE.MAX_TRADE].includes(
              alert[PTRA_BREACH_CACHE_MODEL.alertType]
            )
              ? "N/A"
              : alert[PTRA_BREACH_CACHE_MODEL.tranId]
          }`}
        </li>
        <li>{`Source: ${TRADE_SOURCE_LABELS[alert[PTRA_BREACH_CACHE_MODEL.tranSource]]}`}</li>
      </AlertDataList>
      {MarkAsRead}
    </AlertBodyContainer>
  );
};
