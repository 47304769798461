import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  KeycloakExportView,
  LimitMonitorSettings,
  LimitSettingExport,
  ptraEmailPath,
  RiskUploadSettings,
  StatsSettings,
  TradeReportingSettings,
} from ".";
import { Views } from "../../viewConstants";
import { useUserContext } from "../user";
import { PtraEmailManager } from "./ptra";
import { EQRCAlertSettingsTable } from "./eqrc";
import { UserPreferences } from "./preferences/preferences";
import { ClearCorrespondentRiskAlertSettingsTable } from "./ptra/clearCorrespondent/riskAlertSettingsTable";
import { AguRiskAlertSettingsTable } from "./ptra/agu/riskAlertSettingsTable";
import { LimitSettingAudit } from "./limitSettingAudit";

export const SettingsRoutes = () => {
  const [user] = useUserContext();

  return (
    <Switch>
      <Route exact={true} path={/\/(settings\/tradereporting|settings|)\/?/}>
        <TradeReportingSettings />
      </Route>
      <Route path="/settings/realtimestats">
        <StatsSettings />
      </Route>
      <Route path="/settings/limitmonitor">
        {user?.allowed.views[Views.SETTINGS_LIMO] ? (
          <LimitMonitorSettings />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path="/settings/limitsettingexport">
        {user?.allowed.views[Views.SETTINGS_LIMO] ? <LimitSettingExport /> : <Redirect to="/403" />}
      </Route>
      <Route path={`/settings/${Views.PTR_AUDIT_TRAIL}`}>
        {user?.allowed.views[Views.PTR_AUDIT_TRAIL] ? (
          <LimitSettingAudit />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path="/settings/riskAlertsTable">
        {user?.allowed.views[Views.PTR_ALERTS] ? (
          <ClearCorrespondentRiskAlertSettingsTable />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path="/settings/riskAlertsAGUTable">
        {user?.allowed.views[Views.PTR_ALERTS_AGU] ? (
          <AguRiskAlertSettingsTable />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path={`/settings/${ptraEmailPath}`}>
        {user.allowed.views[Views.PTR_EMAILS] ? <PtraEmailManager /> : <Redirect to="/403" />}
      </Route>
      <Route path="/settings/riskUpload">
        {user.allowed.views[Views.RISK_SETTINGS_UPLOAD] ? (
          <RiskUploadSettings />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path="/settings/eqrcAlertsTable">
        {user?.allowed.views[Views.EQRC_RULES] ? (
          <EQRCAlertSettingsTable />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
      <Route path="/settings/user">
        <UserPreferences />
      </Route>
      <Route path="/settings/keycloakExport">
        {user?.allowed.views[Views.KEYCLOAK_EXPORT] ? (
          <KeycloakExportView />
        ) : (
          <Redirect to="/403" />
        )}
      </Route>
    </Switch>
  );
};
