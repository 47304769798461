import { useUserContext, INITIAL_DATA_MODEL, KeycloakRoles } from "components/user";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { RequestResult, FirmType } from "wksConstants";

const refDataDispatch = createContext();
refDataDispatch.displayName = "RefDataDispatch";
export const useRefDataDispatch = () => {
  return useContext(refDataDispatch);
};

const refDataContext = createContext();
refDataContext.displayName = "RefDataContext";
export const useRefDataContext = () => {
  return useContext(refDataContext);
};

const defaultState = {
  isLimoDisabled: true,
  firm: null,
  isClearingReady: false,
  clearing: null,
  correspondentMpidRelationships: null,
  clearingMpidRelationships: null,
  clearingNumToMpidMap: {},
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_REF_DATA": {
      const { firm, clearing, userMpids } = action.payload;
      return {
        ...state,
        userMpids,
        firm,
        clearing,
      };
    }
    case "SET_LIMO_DISABLED": {
      return {
        ...state,
        isLimoDisabled: action.payload,
      };
    }
    case "SET_FIRM_DATA": {
      const firm = action.payload;
      const clearingNumToMpidMap = {};
      firm.forEach(entry => {
        if (entry.firmType === FirmType.CLEARING) {
          clearingNumToMpidMap[entry.clearingNum] = entry.firmMPID;
        }
      });
      return {
        ...state,
        firm,
        clearingNumToMpidMap,
      };
    }
    case "SET_CLEARING_DATA": {
      const clearing = action.payload;
      const result = (clearing || []).reduce(
        (acc, curr) => {
          if (acc.correspondentMpidRelationships[curr.correspondentMPID] !== undefined) {
            acc.correspondentMpidRelationships[curr.correspondentMPID].push({
              clearingFirmMPID: curr.clearingMPID,
              clearingNum: curr.clearingNum,
              id: curr.id,
              riskStatus: curr.riskStatus,
            });
          } else {
            acc.correspondentMpidRelationships[curr.correspondentMPID] = [
              {
                clearingFirmMPID: curr.clearingMPID,
                clearingNum: curr.clearingNum,
                id: curr.id,
                riskStatus: curr.riskStatus,
              },
            ];
          }

          // add to clearingMpidRelationships object
          if (acc.clearingMpidRelationships[curr.clearingMPID] !== undefined) {
            acc.clearingMpidRelationships[curr.clearingMPID].push({
              correspondentMPID: curr.correspondentMPID,
              id: curr.id,
              riskStatus: curr.riskStatus,
              clearingNum: curr.clearingNum,
            });
          } else {
            acc.clearingMpidRelationships[curr.clearingMPID] = [
              {
                correspondentMPID: curr.correspondentMPID,
                id: curr.id,
                riskStatus: curr.riskStatus,
                clearingNum: curr.clearingNum,
              },
            ];
          }
          // }
          return acc;
        },
        { correspondentMpidRelationships: {}, clearingMpidRelationships: {} }
      );

      return {
        ...state,
        isClearingReady: true,
        clearing,
        correspondentMpidRelationships: result.correspondentMpidRelationships,
        clearingMpidRelationships: result.clearingMpidRelationships,
      };
    }
    case "SET_CORRESPONDENT_RELATIONSHIPS": {
      const correspondentMpidRelationships = action.payload;
      return {
        ...state,
        correspondentMpidRelationships,
      };
    }
    default:
      return { ...state };
  }
};

const RefDataProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    const actions = [];
    if (user[INITIAL_DATA_MODEL.clearingDataResult] === RequestResult.success) {
      actions.push({
        type: "SET_CLEARING_DATA",
        payload: Array.isArray(user[INITIAL_DATA_MODEL.clearingData])
          ? user[INITIAL_DATA_MODEL.clearingData]
          : [],
      });
    }
    if (user[INITIAL_DATA_MODEL.firmDataResult] === RequestResult.success) {
      actions.push({
        type: "SET_FIRM_DATA",
        payload: user[INITIAL_DATA_MODEL.firmData],
      });
    }
    if (
      user[INITIAL_DATA_MODEL.clearingDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.firmDataResult] === RequestResult.success
    ) {
      actions.push({
        type: "SET_LIMO_DISABLED",
        payload: false,
      });
    }
    dispatchF(actions);
  }, [dispatchF, user]);

  return (
    <refDataDispatch.Provider value={dispatchF}>
      <refDataContext.Provider value={[state, dispatchF]}>{children}</refDataContext.Provider>
    </refDataDispatch.Provider>
  );
};

export default RefDataProvider;
