import { getColor } from "@nef/style-utils";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { ReadBtn, ReadText, SharedBtn } from "./breachAlertBody";
import React, { useCallback, useMemo } from "react";
import { usePtraAlertContext, usePtraAlertDispatch } from "./alertCache";
import { NotificationHub } from "@nef/core";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { useAlertDispatch } from "../context";
import { GENERIC_ALERT_ITEM } from "../genericAlerts";
import { LoadingIcon } from "components/loadingIcon";

export const ALERT_FILTER = {
  ALL: "All",
  READ: "Read",
  UNREAD: "Unread",
};

const FilterBtn = styled.button`
  ${props => `
    ${SharedBtn}
    color: ${getColor("primary", 500)(props)};
    text-decoration: ${props.isActive ? "underline" : "none"};
    font-weight: ${props.isActive ? "bold" : "normal"};

    &:hover {
      text-decoration: underline;
      color: ${getColor("primary", 400)(props)}
    }
  `}
`;

const CountWrapper = styled.div`
  color: ${getColor("gray", 300)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AlertFooter = ({ activeFilter, onFilterChange, activeAlerts }) => {
  const [ptrAlertData] = usePtraAlertContext();
  const ptrAlertDispatch = usePtraAlertDispatch();
  const alertDispatch = useAlertDispatch();

  const handleFilterClick = value => () => {
    if (typeof onFilterChange === "function") {
      onFilterChange(value);
    }
  };

  const handleMarkAllAsRead = useCallback(() => {
    ptrAlertDispatch({
      type: "SET_MARK_ALL_LOADING",
      payload: true,
    });
    const cb = json => {
      ptrAlertDispatch([
        {
          type: "SET_READ",
          payload: json,
        },
        {
          type: "SET_MARK_ALL_LOADING",
          payload: false,
        },
      ]);
      alertDispatch({ type: "START_FORCE_REFRESH" });
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking all as unread");
      ptrAlertDispatch({
        type: "SET_MARK_ALL_LOADING",
        payload: false,
      });
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "saveptralertsread"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(
          ptrAlertData.activeAlerts.map(alertItem => alertItem[GENERIC_ALERT_ITEM.ID])
        ),
      },
      cb,
      errorCb
    );
  }, [alertDispatch, ptrAlertData.activeAlerts, ptrAlertDispatch]);

  const handleMarkAllAsUnread = useCallback(() => {
    ptrAlertDispatch({
      type: "SET_MARK_ALL_LOADING",
      payload: true,
    });
    const cb = () => {
      ptrAlertDispatch({
        type: "SET_ALL_UNREAD",
        payload: ptrAlertData.activeAlerts,
      });
      ptrAlertDispatch({
        type: "SET_MARK_ALL_LOADING",
        payload: false,
      });
      alertDispatch({ type: "START_FORCE_REFRESH" });
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking all as unread");
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "deleteunreadptralerts"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(
          ptrAlertData.activeAlerts.map(alertItem => alertItem[GENERIC_ALERT_ITEM.ID])
        ),
      },
      cb,
      errorCb
    );
  }, [alertDispatch, ptrAlertData.activeAlerts, ptrAlertDispatch]);

  const isRead = useMemo(() => {
    return ptrAlertData.activeAlerts.reduce((acc, curr) => {
      return acc && !!ptrAlertData.readMap[curr[GENERIC_ALERT_ITEM.ID]];
    }, true);
  }, [ptrAlertData]);

  const MarkAsRead = useMemo(() => {
    let onClick = undefined;
    if (!ptrAlertData.isMarkAllLoading) {
      if (isRead) {
        onClick = handleMarkAllAsUnread;
      } else {
        onClick = handleMarkAllAsRead;
      }
    }
    return (
      <ReadBtn
        alignSelf="center"
        jusfitySelf="end"
        isRead={isRead}
        onClick={onClick}
        isLoading={ptrAlertData.isMarkAllLoading}
      >
        <LoadingIcon isLoading={ptrAlertData.isMarkAllLoading} />
        <ReadText>{isRead ? "Mark All Unread" : "Mark All Read"}</ReadText>
      </ReadBtn>
    );
  }, [isRead, handleMarkAllAsUnread, handleMarkAllAsRead, ptrAlertData.isMarkAllLoading]);

  return (
    <Footer>
      <CountWrapper>
        <FilterBtn
          alignSelf="center"
          jusfitySelf="end"
          onClick={handleFilterClick(ALERT_FILTER.ALL)}
          isActive={activeFilter === ALERT_FILTER.ALL}
        >{`${ALERT_FILTER.ALL} (${
          Object.keys(ptrAlertData.breachAlerts).length +
          Object.keys(ptrAlertData.configAlerts).length
        })`}</FilterBtn>
        {" | "}
        <FilterBtn
          alignSelf="center"
          jusfitySelf="end"
          onClick={handleFilterClick(ALERT_FILTER.UNREAD)}
          isActive={activeFilter === ALERT_FILTER.UNREAD}
        >{`${ALERT_FILTER.UNREAD} (${
          Object.keys(ptrAlertData.unreadBreachAlerts).length +
          Object.keys(ptrAlertData.unreadConfigAlerts).length
        })`}</FilterBtn>
        {" | "}
        <FilterBtn
          alignSelf="center"
          jusfitySelf="end"
          onClick={handleFilterClick(ALERT_FILTER.READ)}
          isActive={activeFilter === ALERT_FILTER.READ}
        >{`${ALERT_FILTER.READ} (${
          Object.keys(ptrAlertData.readBreachAlerts).length +
          Object.keys(ptrAlertData.readConfigAlerts).length
        })`}</FilterBtn>
      </CountWrapper>
      {MarkAsRead}
    </Footer>
  );
};
