import { FieldLoop, riskAlertEditFields4, riskAlertEditFieldsGiveUp } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { AlertEditForm, Divider, SectionFieldsWrapper, SectionHeader } from "../constants";
import { PtrBreachAlerts } from "../form/breachAlerts";
import { PtrWorkXDeliveryOptions } from "../form/workxDeliveryOptions";
import { PtrSettingAlerts } from "../form/riskLimitSettingAlerts";

export const ClearCorrespondentRiskAlertEditForm = () => {
  return (
    <AlertEditForm>
      <section>
        <SectionHeader>MPID Options</SectionHeader>
        <FieldLoop
          isReactFragment={true}
          form={Forms.PTRA_EDIT}
          fields={riskAlertEditFieldsGiveUp}
          classNames={undefined}
          augmentOnChange={undefined}
          augmentOnCreate={undefined}
          portalRef={undefined}
          isDisabled={undefined}
          containerRef={undefined}
          showLabel={undefined}
        />
      </section>
      <Divider />
      <PtrBreachAlerts form={Forms.PTRA_EDIT} />
      <Divider />
      <PtrSettingAlerts form={Forms.PTRA_EDIT} />
      <Divider />
      <section>
        <SectionHeader>Email Delivery Options</SectionHeader>
        <SectionFieldsWrapper>
          <FieldLoop
            isReactFragment={true}
            form={Forms.PTRA_EDIT}
            fields={riskAlertEditFields4}
            classNames={undefined}
            augmentOnChange={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
          />
        </SectionFieldsWrapper>
      </section>
      <Divider />
      <PtrWorkXDeliveryOptions form={Forms.PTRA_EDIT} />
    </AlertEditForm>
  );
};
