import { DatePicker } from "@nef/core";
import { FieldBox } from "components/styled";
import React, { memo, useCallback, useEffect } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";
import { DATE_DISPLAY_FORMAT, Date_Format } from "./constants";
import moment from "moment";

const WorkXDatePicker = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    portalRef,
    handleInputChange: onInputChange,
    id,
  } = props;

  const handleChange = useCallback(
    e => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        let { value } = e;
        const forceUpperCase = fieldData && fieldData.props.forceUpperCase;
        if (forceUpperCase && typeof value === "string") {
          value = value.toUpperCase();
        }

        if (value?.toDate()) {
          value = moment(value.toDate());
        }

        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [name, form, userData.entitlements, onInputChange, id]
  );

  useEffect(() => {
    const input = document.getElementById(props.id);
    if (input) {
      input.readOnly = true;
    }
  }, [props.id]);

  const realValue = value ? new Date(value.format(Date_Format)) : undefined;

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <DatePicker
        {...props}
        menuPortalTarget={portalRef?.current}
        value={realValue}
        onChange={handleChange}
        autoComplete="off"
        format={DATE_DISPLAY_FORMAT}
        placeholder={DATE_DISPLAY_FORMAT}
        inputId="date-picker-fml"
      />
    </FieldBox>
  );
};

export default memo(WorkXDatePicker);
