export { default as RefDataProvider } from "./context";
export * from "./context";
export * from "./utils";

export const CLEARING_DATA_MODEL = {
  clearingMPID: "clearingMPID",
  clearingNum: "clearingNum",
  correspondentMPID: "correspondentMPID",
  firstValidDay: "firstValidDay",
  id: "id",
  riskStatus: "riskStatus",
};

export const FIRM_DATA_MODEL = {
  clearingNum: "clearingNum",
  firmMPID: "firmMPID",
  firmType: "firmType",
  id: "id",
};
