import { getColor } from "@nef/style-utils";
import styled from "styled-components";
import { Reject_By_Mpid, Reject_By_Symbol } from "../member/constant";
import { PV_Reject_By_Mpid_Security } from "../finra/constant";
import { AllOrPending, All_Or_Pending } from "./context";
import { min } from "moment-timezone";

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

type Data_Array_Row = {
  label: string;
  rejects: { [rejectMsg: string]: number };
  subRows: any[];
  count: number;
  value: React.ReactNode;
  // symbol: string | undefined;
};
const sortByCount = (a: any, b: any) => {
  return parseInt(b["count"]) - parseInt(a["count"]);
};

export type Por_By_Mpid_Or_Symbol = {
  label: string;
  value: React.ReactNode;
  count: number;
  subRows: any[];
  rejects: { [rejectMsg: string]: number };
};

export type Por_By_Mpid_Or_Symbol_And_Total = {
  total: number;
  data: { [mpidOrSymbol: string]: Por_By_Mpid_Or_Symbol };
};

export type Por_By_Mpid_Or_Symbol_And_Total_Array = {
  total: number;
  data: Por_By_Mpid_Or_Symbol[];
};

export const toDataArray = (
  data: { [id: string]: any },
  cb: (arg: string, count: number, type: "mpid" | "symbol") => any,
  type: "mpid" | "symbol",
  isAllOrPending: All_Or_Pending
) => {
  let totalCount = 0;
  let minusLeft = 5;
  const a = Object.entries(data).map(([key, count]) => {
    if (isAllOrPending === AllOrPending.PENDING) {
      if (minusLeft > 0 && count > 2) {
        count = count - 2;
        minusLeft = minusLeft - 1;
      }
    }

    totalCount += count;

    return {
      label: key,
      count,
      value: <ArrayButton onClick={cb(key, count, type)}>{count}</ArrayButton>,
    };
  });

  a.unshift({
    label: "All",
    count: totalCount,
    value: <ArrayButton onClick={cb("All", totalCount, type)}>{totalCount}</ArrayButton>,
  });
  a.sort(sortByCount);

  return a;
};

export const porByMpidOrSymbol = (
  data: PV_Reject_By_Mpid_Security[],
  cb: (arg: Data_Array_Row) => any,
  MpidOrSymbol: "mpid" | "symbol"
) => {
  const porData = data.reduce(
    (acc, curr) => {
      let key;
      if (MpidOrSymbol === "mpid") {
        key = curr["mpid"];
      } else {
        key = curr["symbol"];
      }
      if (acc.data[key] === undefined) {
        if (curr.rejectText === "!REJ - PRICE OUT OF OVERRIDE RANGE") {
          return acc;
        }
        acc.data[curr[MpidOrSymbol]] = {
          label: curr[MpidOrSymbol],
          count: 0,
          rejects: { [curr.rejectText]: 0 },
          value: <ArrayButton>{curr.count}</ArrayButton>,
          subRows: [
            // {
            //   label: curr.rej,
            //   value: (
            //     <ArrayButton
            //       onClick={cb({
            //         label: curr[MpidOrSymbol],
            //         rejects: { [curr.rej]: 0 },
            //         subRows: [],
            //         count: 0,
            //         value: <></>,
            //         // symbol: undefined,
            //       })}
            //     >
            //       {curr.count}
            //     </ArrayButton>
            //   ),
            // },
          ],
        };
      }

      acc.data[curr[MpidOrSymbol]].count += parseInt(curr.count);

      acc.data[curr[MpidOrSymbol]].rejects[curr.rejectText] += parseInt(curr.count);
      acc.data[curr[MpidOrSymbol]].value = (
        <ArrayButton onClick={cb(acc.data[curr[MpidOrSymbol]])}>
          {acc.data[curr[MpidOrSymbol]].count}
        </ArrayButton>
      );

      // acc.data[curr[MpidOrSymbol]].subRows.push({
      //   label: curr.rej,
      //   value: (
      //     <ArrayButton
      //       onClick={cb({
      //         label: curr[MpidOrSymbol],
      //         rejects: { [curr.rej]: parseInt(curr.count) },
      //         subRows: [],
      //         count: parseInt(curr.count),
      //         value: <></>,
      //         // symbol: undefined,
      //       })}
      //     >
      //       {curr.count}
      //     </ArrayButton>
      //   ),
      // });

      return acc;
    },
    { total: 0, data: {} } as Por_By_Mpid_Or_Symbol_And_Total
  );

  const finalData = {
    total: porData.total,
    data: Object.values(porData.data).sort(sortByCount),
  };

  return finalData;
};

export const symbolDataArray = (data: (Reject_By_Symbol | Reject_By_Mpid)[]) => {
  let key = "";

  return Object.values(
    data.reduce((acc, curr) => {
      if ((curr as Reject_By_Mpid).mpid) {
        key = (curr as Reject_By_Mpid).mpid;
      } else {
        key = (curr as Reject_By_Symbol).symbol;
      }

      if (acc[key] === undefined) {
        acc[key] = {
          label: key,
          count: parseInt(curr.count),
          rejects: { [curr.rej]: parseInt(curr.count) },
          value: <ArrayButton>{curr.count}</ArrayButton>,
          subRows: [
            {
              label: curr.rej,
              value: <ArrayButton>{curr.count}</ArrayButton>,
            },
          ],
        };
      } else {
        acc[key].count += parseInt(curr.count);
        acc[key].rejects[curr.rej] = parseInt(curr.count);
        acc[key].value = <ArrayButton>{acc[key].count}</ArrayButton>;
        acc[key].subRows.push({
          label: curr.rej,
          value: <ArrayButton>{curr.count}</ArrayButton>,
        });
      }
      return acc;
    }, {} as { [symbol: string]: { label: string; value: React.ReactNode; count: number; subRows: any[]; rejects: { [rejectMsg: string]: number } } })
  ).sort(sortByCount);
};

export const barChartData = (
  data: Reject_By_Symbol[],
  cb: (arg: Data_Array_Row) => any,
  one: boolean,
  type1: "symbol" | "mpid"
) => {
  const dataParsed = data.reduce((acc, curr) => {
    let key;

    key = curr["symbol"];

    let rej;
    let rejKey;

    rej = curr["rej"];
    rejKey = "rej";

    if (!one) {
      if (rej === "!REJ - PRICE OUT OF RANGE" || rej === "!REJ - PRICE OUT OF OVERRIDE RANGE") {
        if (!acc[key]) {
          acc[key] = {};
        }
        acc[key][rej] = parseInt(curr.count);
      }
    } else {
      if (
        rej === "!REJ - PRICE OUT OF RANGE"
        // ||
        // curr.rej === "!REJ - PRICE OUT OF OVERRIDE RANGE"
      ) {
        if (!acc[key]) {
          acc[key] = {};
        }
        acc[key][rej] = parseInt(curr.count);
      }
    }
    return acc;
  }, {} as { [symbolOrMpid: string]: { [rejMsg: string]: number } });

  const arrData = Object.entries(dataParsed).map(([key, val]) => {
    return { symbol: key, rejects: val };
  });

  arrData.sort((a, b) => {
    return (
      Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
      Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
    );
  });
  const chartData = [];
  for (let i = 0; i < 5 && i < arrData.length; i++) {
    chartData.push({
      name: arrData[i].symbol,
      values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
        name: rejMsg,
        value: count,
      })),
    });
  }
  const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
    Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
      if (!acc[rejMsg]) {
        acc[rejMsg] = 0;
      }
      acc[rejMsg] += count;
    });
    return acc;
  }, {} as { [rejMsg: string]: number });
  const vals = Object.values(countOfAllOthers);
  if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
    chartData.push({
      name: "Other",
      values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
        name: rejMsg,
        value: count,
      })),
    });
  }
  return chartData;
};
