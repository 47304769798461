import { EffectCallback, useCallback, useEffect, useMemo } from "react";
import {
  AllOrPending,
  All_Or_Pending,
  MpidOrSymbol,
  Mpid_Or_Security,
  PorOrPvr,
  usePORCacheContext,
} from "../context";
import { DT } from "./dataArray";
import { HeaderBox } from "./tile.container";
import { TileFront } from "./tile.front";
import { toDataArray } from "../fakeData";
import { byMpid, bySymbol } from "components/pvRejects/finra/constant";
import { useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";

export const Front = ({
  toggle,
  isAllOrPending,
}: {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
}) => {
  const { state, dispatch } = usePORCacheContext();
  const tableDispatch = useStandardTableDispatch();

  const onMpidClick = useCallback(
    (id: any, count: any) => () => {
      dispatch({ type: "SET_REQUEST_TABLE", payload: { count, id } });
      dispatch({ type: "SET_SELECTED", payload: { selected: id } });
    },
    [dispatch]
  );

  const p = state[PorOrPvr.POR];
  const pp = p.allOrPending;
  useEffect(() => {
    dispatch({
      type: "SET_REQUEST_TABLE",
      payload: { id: "All", count: pp === AllOrPending.ALL ? 67 : 57 },
    });
    // setTitle(`POR for Symbol 'All'`);
  }, [dispatch, pp]); // just once

  const td = state[PorOrPvr.POR].tableData;
  useEffect((): ReturnType<EffectCallback> => {
    tableDispatch({
      type: "SET_TABLE_DATA",
      payload: {
        table: StandardTables.PV_SUPERVISOR_MONITOR2,
        data: td,
      },
    });
  }, [state, tableDispatch, td, pp]);
  const data = useMemo(
    () => toDataArray(byMpid, onMpidClick, "mpid", isAllOrPending),
    [isAllOrPending, onMpidClick]
  );
  const data2 = useMemo(
    () => toDataArray(bySymbol, onMpidClick, "symbol", isAllOrPending),
    [isAllOrPending, onMpidClick]
  );

  return (
    <TileFront
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={true}
          hasMpidOrSymbol={false}
          isAllOrPending={isAllOrPending}
          total={state[PorOrPvr.POR].total}
          title={"POR"}
          isPorOrPvr={PorOrPvr.POR}
        />
      }
      title1="By Symbol"
      title2="By MPID"
      data1={<DT data={data} isMpidOrSymbol={MpidOrSymbol.SECURITY} />}
      data2={<DT data={data2} isMpidOrSymbol={MpidOrSymbol.MPID} />}
    />
  );
};
