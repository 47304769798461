import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useRefDataContext } from "../refData/context";
import useWindowFocus from "use-window-focus";
import { useUserContext, KeycloakRoles } from "../user";
import { formatUrl } from "../../utils/js.utils";
import { CLEARING_DATA_MODEL } from "components/refData";

const bellDispatch = createContext();
bellDispatch.displayName = "BellDispatch";
export const useBellDispatch = () => {
  return useContext(bellDispatch);
};

const bellContext = createContext();
bellContext.displayName = "BellContext";
export const useBellContext = () => {
  return useContext(bellContext);
};

const defaultState = {
  makeRequest: false,
  interval: null,
  clearingRelationships: [],
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_MAKE_REQUEST": {
      return { ...state, makeRequest: action.payload };
    }
    case "HANDLE_COMPLETE_REQUEST": {
      return { ...state, makeRequest: true, interval: action.payload };
    }
    case "SET_CLEARING_RELATIONSHIPS": {
      return { ...state, clearingRelationships: action.payload };
    }
    case "REMOVE_CLEARING_RELATIONSHIP": {
      const { clearingMPID, correspondentMPID } = action.payload;
      const clearingRelationships = [...state.clearingRelationships];
      const index = clearingRelationships.findIndex(
        relationship =>
          relationship.clearingMPID === clearingMPID &&
          relationship.correspondentMPID === correspondentMPID
      );
      if (index !== -1) {
        clearingRelationships.splice(index, 1);
      }
      return { ...state, clearingRelationships };
    }
    default:
      return { ...state };
  }
};

const BellProvider = ({ children, defaultData }) => {
  const [user] = useUserContext();
  const isWindowFocused = useWindowFocus();
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [refData] = useRefDataContext();

  const handleRequest = useCallback(() => {
    const getNotificationsCallback = data => {
      const relationships = data?.relationships || [];
      const interval = data?.interval;
      dispatchF([
        { type: "HANDLE_COMPLETE_REQUEST", payload: interval },
        { type: "SET_CLEARING_RELATIONSHIPS", payload: relationships },
      ]);
    };

    const getNotificationsError = () => {};
    const headers = getHeaders();
    if (Array.isArray(refData.clearing)) {
      const clearingNumSet = new Set();
      refData.clearing.forEach(rel => {
        clearingNumSet.add(rel[CLEARING_DATA_MODEL.clearingNum]);
      });
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_LIMO_WS, "getclearingrelswithdefaultsbyclearingnums"),
        {
          method: "post",
          headers,
          body: JSON.stringify({ clearingNums: Array.from(clearingNumSet) }),
        },
        getNotificationsCallback,
        getNotificationsError
      );
    }
  }, [refData.clearing]);

  useEffect(() => {
    dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
  }, [refData.firm]);

  useEffect(() => {
    if (user.entitlements[KeycloakRoles.PTR_RISK_CONFIG] && state.makeRequest && isWindowFocused) {
      if (typeof state.interval === "number") {
        setTimeout(() => {
          handleRequest();
        }, [state.interval]);
      } else {
        handleRequest();
      }
      dispatchF({ type: "SET_MAKE_REQUEST", payload: false });
    }
  }, [state.makeRequest, state.interval, handleRequest, isWindowFocused, user.entitlements]);

  return (
    <bellDispatch.Provider value={dispatchF}>
      <bellContext.Provider value={[state, dispatchF]}>{children}</bellContext.Provider>
    </bellDispatch.Provider>
  );
};

export default BellProvider;
