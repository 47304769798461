import React, { memo, useMemo } from "react";
import { Header, Flex } from "@nef/core";
import { useSpring, animated } from "react-spring";
import { TradesForScanLink } from ".";
import { abbreviateNumber } from "utils/js.utils";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const StyledFlex = styled(Flex)`
  ${props => `${
    props.$hasBorder
      ? `
        border-right: 1px solid ${getColor("gray", 300)(props)};
        padding-right: 0.5rem;
        margin-right: 0.5rem;
    `
      : ""
  }
`}
`;

export const NarrowBarItem = memo(
  ({
    id,
    text,
    value,
    statType,
    hasBorder,
    headerSize = 1,
    isAbbreviated = false,
    className = "",
  }) => {
    // text can contain <br />. So we dangerously set html. but we know and control all values
    // that pass through here
    const spring = useSpring({
      value,
      config: { mass: 1, tension: 170, friction: 26 },
    });

    const TextDisplay = useMemo(() => {
      const lines = text.split("<br />");
      const Content = lines.reduce((acc, curr, idx) => {
        acc.push(<span key={`${id}_inner_${idx}`}>{curr}</span>);
        if (idx < lines.length - 1) {
          acc.push(<br key={`${id}_inner_${idx}_br`} />);
        }
        return acc;
      }, []);

      return <p>{Content}</p>;
    }, [id, text]);

    const HeaderDisplay = useMemo(() => {
      let animatedSpan;
      if (isAbbreviated) {
        animatedSpan = (
          <animated.span>
            {spring.value.interpolate(x => (x === "--" ? x : abbreviateNumber(Math.floor(x))))}
          </animated.span>
        );
      } else {
        animatedSpan = (
          <animated.span>
            {spring.value.interpolate(x => (x === "--" ? x : x.toFixed(0)))}
          </animated.span>
        );
      }

      return (
        <Header size={headerSize}>
          {statType ? (
            <TradesForScanLink statType={statType}>{animatedSpan}</TradesForScanLink>
          ) : (
            animatedSpan
          )}
        </Header>
      );
    }, [isAbbreviated, headerSize, statType, spring.value]);

    return (
      <StyledFlex
        role="listitem"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        $hasBorder={hasBorder}
      >
        {HeaderDisplay}
        {TextDisplay}
      </StyledFlex>
    );
  }
);
