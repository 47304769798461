import { FieldNames } from "components/fields";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { produce } from "immer";
import React, { useEffect, createContext, useContext, useReducer } from "react";
import { safeParseJSON } from "utils/js.utils";
import { RequestResult, DEFAULT_CONFIRM_REPORT_QUANTITY_NUMBER } from "../../wksConstants";

const settingsDispatch = createContext();
export const useSettingsDispatch = () => {
  return useContext(settingsDispatch);
};

const settingsContext = createContext();
export const useSettingsContext = () => {
  return useContext(settingsContext);
};

const defaultState = {
  isSettingsDisabled: true,
  isLoading: false,
  [FieldNames.confirmTableHotButtons]: true,
  [FieldNames.confirmReportQuantity]: true,
  [FieldNames.confirmReportQuantityNumber]: DEFAULT_CONFIRM_REPORT_QUANTITY_NUMBER,
  incContraGUP: false,
  incContraMPID: true,
  incExecGUP: false,
  incExecMPID: true,
  [FieldNames.stickyFieldsSubmit]: false,
  [FieldNames.stickyFieldsSwitch]: false,
  SettingsStatsKeys: ["incContraGUP", "incContraMPID", "incExecGUP", "incExecMPID"],
  SettingsTradeReportingKeys: [
    FieldNames.stickyFieldsSubmit,
    FieldNames.stickyFieldsSwitch,
    FieldNames.confirmTableHotButtons,
    FieldNames.confirmReportQuantity,
    FieldNames.confirmReportQuantityNumber,
  ],
  riskUploadFileName: "",
  riskConfigFilesData: {},
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_SETTING": {
      const { field, value } = action.payload;

      return produce(state, draft => {
        draft[field] = value;
      });
    }

    case "SET_SETTINGS": {
      const { fields } = action.payload;

      return produce(state, draft => {
        Object.assign(draft, fields);
      });
    }

    case "TOGGLE_SETTING":
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return { ...state };

    case "SET_SETTINGS_DISABLED": {
      return {
        ...state,
        isSettingsDisabled: action.payload,
      };
    }

    case "ADD_TO_RISK_CONFIG_FILE_TABLE": {
      const { id, data } = action.payload;
      return { ...state, riskConfigFilesData: { ...state.riskConfigFilesData, [id]: data } };
    }

    case "DUMP_SETTINGS": {
      const { userData } = action.payload;

      return produce(state, draft => {
        userData.forEach((d, i) => {
          if (d.type !== "tableColmmnSet") {
            Object.assign(draft, safeParseJSON(d.data || "{}"));
          }
        });

        draft.isSettingsDisabled = false;
      });
    }
  }
};

const SettingsProvider = ({ children }) => {
  const [state, dispatchF] = useReducer(DispatchFn, defaultState);
  const [user] = useUserContext();

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      user[INITIAL_DATA_MODEL.userData]
    ) {
      dispatchF({
        type: "DUMP_SETTINGS",
        payload: { userData: user[INITIAL_DATA_MODEL.userData] },
      });
    } else if (user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.error) {
      dispatchF({ type: "SET_SETTINGS_DISABLED", payload: true });
    }
  }, [user]);

  return (
    <settingsDispatch.Provider value={dispatchF}>
      <settingsContext.Provider value={[state, dispatchF]}>{children}</settingsContext.Provider>
    </settingsDispatch.Provider>
  );
};

export default SettingsProvider;
