import { Flex, Tab, Tabs, NotificationHub } from "@nef/core";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import {
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { StandardTables } from "wksConstants";
import { UploadRepairButton, network } from ".";
import "./index.css";
import { useUploadContext } from "./uploadContext";
import { createAndDownloadTemplate } from "../standardTable/utils";
import { Forms } from "../fields";
import { ErrorSuccessIssueCount, TabText } from "./constants";

export const ErrorSuccessTables = () => {
  const [table, setTable] = useState(StandardTables.UPLOAD_FILE_ISSUES);
  const standardTableDispatch = useStandardTableDispatch();
  const [standardTableData] = useStandardTableContext();
  const [uploadData] = useUploadContext();

  const exportFileName = useMemo(() => {
    if (table === StandardTables.UPLOAD_SUCCESS && uploadData.activeFilename) {
      const name = uploadData.activeFilename.replace(".csv", "") + "_success.csv";
      return name;
    } else {
      return undefined;
    }
  }, [table, uploadData.activeFilename]);

  useEffect(() => {
    if (uploadData.activeUploadId < 0) {
      setTable(StandardTables.UPLOAD_FILE_ISSUES);
    } else {
      setTable(StandardTables.UPLOAD_ERRORS);
    }
  }, [uploadData.activeUploadId]);

  const handleClick = useCallback(
    t => {
      setTable(t);
    },
    [setTable]
  );

  const exportRejectsSuccess = useCallback((filename, data) => {
    createAndDownloadTemplate(`${filename.replace(".csv", "")}_rejects.csv`, data.join("\n")).then(
      () => NotificationHub.send("success", "Export request has been completed")
    );
  }, []);

  const exportRejectsError = useCallback(error => {
    NotificationHub.send("danger", "A problem occured while exporting rejected trades");
  }, []);

  const handleExportRejects = useCallback(() => {
    network().getAllRejectsForExoprt(
      uploadData.activeFilename,
      uploadData.activeUploadId,
      exportRejectsSuccess,
      exportRejectsError
    );
  }, [
    exportRejectsSuccess,
    exportRejectsError,
    uploadData.activeUploadId,
    uploadData.activeFilename,
  ]);

  const fileIssuesTable = standardTableData[StandardTables.UPLOAD_FILE_ISSUES].data || [];
  const errorTable = standardTableData[StandardTables.UPLOAD_ERRORS].data || [];
  const successTable = standardTableData[StandardTables.UPLOAD_SUCCESS].data || [];

  const additionalRowClick = useCallback(
    e => {
      // e.row.toggleRowSelected();

      standardTableDispatch({
        type: "HANDLE_ROWS_SELECTED",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
          rows: { [e.row.id.toString()]: true },
          isSingleSelect: true,
        },
      });
    },
    [standardTableDispatch]
  );

  const Header = useCallback(
    () => (
      <TableHeader
        fileIssuesLength={fileIssuesTable.length}
        errorLength={errorTable.length}
        successLength={successTable.length}
        handleClick={handleClick}
        table={table}
      />
    ),
    [fileIssuesTable.length, errorTable.length, successTable.length, handleClick, table]
  );

  const tableHeaderFn = useMemo(
    () =>
      table === StandardTables.UPLOAD_ERRORS ? (
        <UploadRepairButton table={StandardTables.UPLOAD_ERRORS} form={Forms.UPLOAD_REPAIR} />
      ) : undefined,
    [table]
  );
  return (
    <StandardTable
      header={Header}
      headerMenu={tableHeaderFn}
      table={table}
      pageSize={10}
      allowMultiSelect={table === StandardTables.UPLOAD_ERRORS}
      enableLayoutExport={true}
      exportCallback={table === StandardTables.UPLOAD_ERRORS ? handleExportRejects : undefined}
      exportFileName={exportFileName}
      additionalRowClick={table === StandardTables.UPLOAD_ERRORS ? additionalRowClick : undefined}
      isLoading={standardTableData[StandardTables.UPLOAD_ERRORS].isLoading}
      isSingleSelect={table === StandardTables.UPLOAD_ERRORS}
      isRowSelectEnabled={table === StandardTables.UPLOAD_ERRORS}
      isColumnsVirtualized={false}
      hideRowCount={true}
      hideQueryDate={true}
    />
  );
};

const TableHeader = ({ fileIssuesLength, successLength, errorLength, handleClick, table }) => {
  return (
    <Tabs style={{ marginTop: 0 }}>
      {table === StandardTables.UPLOAD_FILE_ISSUES && (
        <Tab active={true} onClick={() => handleClick(StandardTables.UPLOAD_FILE_ISSUES)}>
          <ErrorSuccessIssueCount color="warning">{fileIssuesLength}</ErrorSuccessIssueCount>
          <TabText>File Issues</TabText>
        </Tab>
      )}
      {(table === StandardTables.UPLOAD_ERRORS || table === StandardTables.UPLOAD_SUCCESS) && (
        <>
          <Tab
            active={table === StandardTables.UPLOAD_ERRORS}
            onClick={() => handleClick(StandardTables.UPLOAD_ERRORS)}
          >
            <ErrorSuccessIssueCount color="danger">{errorLength}</ErrorSuccessIssueCount>
            <TabText>Errors</TabText>
          </Tab>

          <Tab
            active={table === StandardTables.UPLOAD_SUCCESS}
            onClick={() => handleClick(StandardTables.UPLOAD_SUCCESS)}
          >
            <ErrorSuccessIssueCount color="success">{successLength}</ErrorSuccessIssueCount>
            <TabText>Success</TabText>
          </Tab>
        </>
      )}
    </Tabs>
  );
};
