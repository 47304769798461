import {
  DatePicker,
  FormField,
  FormRadioCheckboxButton,
  FormSelect,
  FormTimeField,
  FormDropdown,
  Switch,
  FormRadioCheckboxGroup,
} from "@nef/core";
import { useFormContext, useFormDispatch } from "components/form";
import _ from "lodash";
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { execOrReturn, isFunction, addPrefixToField } from "utils/js.utils";
import {
  GrayableFields,
  WorkXCheckbox,
  WorkXDateRange,
  WorkXField,
  WorkXDropdown,
  WorkXSelect,
  WorkXTimeField,
  WorkXTimeRangeField,
  WorkXSwitch,
  WorkXRadioGroup,
  WorkXSelectPersistCreatable,
} from ".";
import WorkXNumber from "components/fields/workXNumber";
import { useUserContext } from "../user";
import { clearReportRiskOptions, FieldNames, OptionConstants } from "./fieldConstants";
import { PTRA_CONFIGURATION_ENTITY, PTRA_FIELD_PREFIX } from "../settings/ptra/fields";
import { EqrcFields, EQRC_FIELD_PREFIX } from "components/settings/eqrc/constants";
import moment from "moment-timezone";
import { AUGMENT_ON_CHANGE } from "./constants";
import WorkXDatePicker from "./workXDatePicker";
import { useFieldOptionContext } from "./loadingContext";

const getIsMulti = (Field, form) => {
  let isMulti = Field.props.isMulti === true;
  if (Array.isArray(Field.props.isMulti)) {
    isMulti = Field.props.isMulti.includes(form.key);
  }
  return isMulti;
};

const getKey = (id, name) => (id ? id : name);

const getSharedPropsFromData = (formData, form, Field, i) => {
  Field = execOrReturn(Field, form);

  const {
    style,
    name,
    getTooltip,
    tooltipPlacement,
    options,
    inline,
    prefix,
    maxValue,
    allowNegative,
    allowDecimal,
    id,
    zIndex,
    persistKey,
    isClearable,
    hasSelectAll,
    onCreateValidation,
    createValidationMessage,
    inverseCharacterCase,
    hasImport = false,
    optionCreationFilter,
    rangeType,
  } = Field.props;

  const simpleRequired = _.get(Field, `props.validation.required.onForms.${form.key}`);
  const ifValueRequired = _.get(
    Field,
    `props.validation.requiredIfAnyFieldValue.onForms.${form.key}`
  );
  const isRequired = simpleRequired === true || ifValueRequired === true;
  const feedback = formData[form.key].errors[`${name}_errorText`];
  const shouldShowError = formData[form.key].errors[`${name}_errorStatus`];
  const allowShowError = formData[form.key].allowShowError;
  let disabled =
    (formData[form.key].grayedFields || []).includes(name) || Field.props?.disabled?.[form.key];

  if (Array.isArray(Field.props?.disabledWhen)) {
    Field.props.disabledWhen.forEach(when => {
      if (when.onForms && !when.onForms.includes(form)) {
        disabled = disabled || false;
        return;
      }
      if (typeof when.value === "function") {
        disabled = disabled || when.value(formData[form.key].fields[when.field]);
      } else if (formData[form.key].fields[when.field] === when.value) {
        disabled = true;
      }
    });
  }

  if (Array.isArray(Field.props?.disabledWhenAll)) {
    let disabledAll =
      Array.isArray(Field.props.disabledWhenAll) && Field.props.disabledWhenAll.length > 0;
    Field.props.disabledWhenAll.forEach(when => {
      if (formData[form.key].fields[when.field] !== when.value) {
        disabledAll = false;
      }
    });
    disabled = disabledAll;
  }

  let { helpText } = Field.props;
  if (typeof helpText === "object") {
    helpText = helpText[form.key];
  }

  helpText = execOrReturn(helpText, formData[form.key].fields);
  const value = formData[form.key].fields[name];

  const props = {
    value,
    form,
    style: execOrReturn(style, form),
    name,
    id,
    i,
    invalid:
      (shouldShowError && allowShowError) ||
      (formData[form.key].errors[`${name}_instant`] &&
        formData[form.key].errors[`${name}_errorText`]),
    key: `${getKey(id, name)}i`.replace(/\s/g, ""),
    size: "sm",
  };

  const addProp = (name, value) => {
    if (value !== undefined) {
      props[name] = value;
    }
  };

  addProp("onCreateValidation", onCreateValidation);
  addProp("createValidationMessage", createValidationMessage);
  addProp("hasSelectAll", hasSelectAll);
  addProp("isClearable", isClearable);
  addProp("zIndex", zIndex);
  addProp("persistKey", persistKey);
  addProp("maxValue", maxValue);
  addProp("prefix", prefix);
  addProp("inline", inline);
  addProp("allowDecimal", allowDecimal);
  addProp("allowNegative", allowNegative);
  addProp("options", options);
  addProp("tooltipPlacement", tooltipPlacement);
  addProp("getTooltip", getTooltip);
  addProp("feedback", feedback);
  addProp("isRequired", isRequired);
  addProp("disabled", disabled);
  addProp("helpText", helpText);
  addProp("inverseCharacterCase", inverseCharacterCase);
  addProp("hasImport", hasImport);
  addProp("optionCreationFilter", optionCreationFilter);
  addProp("rangeType", rangeType);

  return props;
};

const getDisabledOptions = async (fields, formData, form, userData, optionsCache) => {
  let optionMap = {};
  const keys = Object.keys(fields);
  for (let i in keys) {
    const key = keys[i];
    const Field = execOrReturn(fields[key], { form });
    if (Field === null) {
      continue;
    }

    const { options, name } = Field.props;
    if (!options) {
      continue;
    } else if (!GrayableFields.includes(name)) {
      if (
        [
          "mpidvals",
          FieldNames.side,
          FieldNames.modifier2,
          FieldNames.modifier3,
          FieldNames.modifier4,
        ].includes(name)
      ) {
        optionMap[name] = isFunction(options)
          ? await options(form, formData, userData, optionsCache)
          : options;
        continue;
      }

      optionMap[name] = isFunction(options)
        ? await options(form, formData, userData, optionsCache)
        : options;

      continue;
    }
    const returnValue = execOrReturn(options, {
      props: { form, formData, entitlements: userData.entitlements },
    });
    returnValue.forEach(option => {
      if ((formData.grayedFields || []).includes(option.id)) {
        option.isDisabled = true;
      } else {
        option.isDisabled = false;
      }
    });

    optionMap[name] = returnValue;
    continue;
  }
  return optionMap;
};

const FieldLoopFn = ({
  form,
  fields,
  classNames,
  augmentOnChange,
  augmentOnCreate,
  portalRef,
  isReactFragment,
  isDisabled,
  containerRef,
  showLabel,
}) => {
  const localRef = useRef();
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const [loadingData] = useFieldOptionContext();
  const [FieldNodes, setFieldNodes] = useState([]);

  const [userData] = useUserContext();

  const onInputChange = useCallback(
    ({ id, name, value, fieldAugment }) => {
      const formActions = [
        {
          type: "UPDATE_FORM_VALUE",
          payload: {
            id,
            form,
            field: name,
            value: value,
            entitlements: userData.entitlements,
          },
        },
      ];
      let augments = fieldAugment;
      if (fieldAugment && !Array.isArray(fieldAugment)) {
        augments = [fieldAugment];
      }

      if (augments) {
        augments.forEach(augment => {
          switch (augment) {
            case AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE:
              formActions.push({
                type: "UPDATE_CLEARING_PRICE",
                payload: { form },
              });
              break;
            case AUGMENT_ON_CHANGE.EMPTY_GIVE_UPS:
              formActions.push({
                type: "EMPTY_GIVE_UPS",
                payload: { form },
              });
              break;
            case AUGMENT_ON_CHANGE.EMPTY_PARTICIPANT_MPID:
            case AUGMENT_ON_CHANGE.EMPTY_CORRESPONDENT_MPID:
              formActions.push({
                type: "EMPTY_CORRESPONDENT_MPID",
                payload: { form },
              });
              break;
            case AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE:
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.price,
                  value: "",
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.contract,
                  value: "",
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.fee,
                  value: "",
                  entitlements: userData.entitlements,
                },
              });
              break;
            case AUGMENT_ON_CHANGE.SET_CONFIRM_MSG: {
              const field = execOrReturn(fields[name], form);
              formActions.push({
                type: "SET_CONFIRM_MESSAGE",
                payload: { form, field, name, value },
              });
              break;
            }
            case AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE: {
              const { clearReportRiskVals } = OptionConstants;
              const prevValue = formData[form.key].fields[FieldNames.clearReportRiskVals];
              let hadClear = false;
              let hadRisk = false;
              if (Array.isArray(prevValue)) {
                prevValue.forEach(opt => {
                  if (opt.value === clearReportRiskVals.clear) {
                    hadClear = true;
                  }
                  if (opt.value === clearReportRiskVals.risk) {
                    hadRisk = true;
                  }
                });
              }

              let hasClear = false;
              let hasRisk = false;
              if (Array.isArray(value)) {
                value.forEach(opt => {
                  if (opt.value === clearReportRiskVals.clear) {
                    hasClear = true;
                  }
                  if (opt.value === clearReportRiskVals.risk) {
                    hasRisk = true;
                  }
                });
              }

              if (hasClear && !hasRisk && !(hadClear || hadRisk)) {
                value.push(clearReportRiskOptions[clearReportRiskVals.risk]);
              }

              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.clearReportRiskVals,
                  value,
                  entitlements: userData.entitlements,
                },
              });
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_PTRA_EMAILS: {
              if (!value) {
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(
                      PTRA_FIELD_PREFIX,
                      PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient
                    ),
                    form,
                    field: PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient,
                    value: false,
                    entitlements: userData.entitlements,
                  },
                });
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.emailId),
                    form,
                    field: PTRA_CONFIGURATION_ENTITY.emailId,
                    value: null,
                    entitlements: userData.entitlements,
                  },
                });
              }
              break;
            }
            case AUGMENT_ON_CHANGE.SET_ALERT_LOG: {
              if (value) {
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(
                      PTRA_FIELD_PREFIX,
                      PTRA_CONFIGURATION_ENTITY.isAlertLogActive
                    ),
                    form,
                    field: PTRA_CONFIGURATION_ENTITY.isAlertLogActive,
                    value: true,
                    entitlements: userData.entitlements,
                  },
                });
              }
              break;
            }
            case AUGMENT_ON_CHANGE.SET_EQRC_START_END_DATE: {
              if (Array.isArray(value) && value.length === 2) {
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.beginDate),
                    form,
                    field: EqrcFields.beginDate,
                    value: value[0]?.format("YYYY-MM-DDT00:00:00Z"),
                    entitlements: userData.entitlements,
                  },
                });
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.endDate),
                    form,
                    field: EqrcFields.endDate,
                    value: value[1]?.format("YYYY-MM-DDT23:59:59Z"),
                    entitlements: userData.entitlements,
                  },
                });
              }
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_PORT: {
              const prevValue = formData[form.key].fields[EqrcFields.mpid]?.value;

              if (prevValue !== value?.value) {
                formActions.push({
                  type: "UPDATE_FORM_VALUE",
                  payload: {
                    id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port),
                    form,
                    field: EqrcFields.port,
                    value: undefined,
                    entitlements: userData.entitlements,
                  },
                });
              }
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS: {
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.sellerDays,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_SYMBOL_AND_CUSIP: {
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.symbol,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.CUSIP,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_REVERSAL_FIELDS: {
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.reversalOriginalControlNum,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.reversalOriginalControlDate,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  form,
                  field: FieldNames.reversalReferenceReportingVenue,
                  value: undefined,
                  entitlements: userData.entitlements,
                },
              });
              break;
            }
            case AUGMENT_ON_CHANGE.EMPTY_DATE_TIME_RANGES:
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.dateRange,
                  value: [],
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.tradeTimeRange,
                  value: [],
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.reportDateRange,
                  value: [],
                  entitlements: userData.entitlements,
                },
              });
              formActions.push({
                type: "UPDATE_FORM_VALUE",
                payload: {
                  id,
                  form,
                  field: FieldNames.reportTimeRange,
                  value: [],
                  entitlements: userData.entitlements,
                },
              });
              break;
            default:
              break;
          }
        });
      }

      formDispatch(formActions);

      if (augmentOnChange) {
        augmentOnChange({ field: name, value });
      }
    },
    [augmentOnChange, formDispatch, form, userData.entitlements, fields, formData]
  );

  useEffect(() => {
    const getFields = async () => {
      const fieldArray = [];
      const keys = Object.keys(fields);
      for (let i in keys) {
        const key = keys[i];
        const Field = execOrReturn(fields[key], {
          form,
          entitlements: userData.entitlements,
          selectAllMpidCount: formData.selectAllMpidCount,
        });

        if (Field !== null) {
          const { Type } = Field;

          const { label, name, id, visible, placeholder, showLabel } = Field.props;
          const reactKey = getKey(id, name);
          if (visible) {
            if (!visible.onForms || Object.keys(visible.onForms).includes(form.key)) {
              if (visible.field) {
                let shouldBeVisible = false;
                if (formData[form.key].fields[visible.field]) {
                  const value = formData[form.key].fields[visible.field]?.value
                    ? formData[form.key].fields[visible.field].value
                    : formData[form.key].fields[visible.field];

                  shouldBeVisible = value === visible?.value;
                }

                if (!shouldBeVisible) {
                  fieldArray.push(<div key={`${reactKey}i`.replace(/\s/g, "")}></div>);
                  continue;
                }
              }
            }
          }
          const realProps = Object.assign(
            {},
            { className: classNames },
            getSharedPropsFromData(formData, form, Field, i, formDispatch),
            {
              handleInputChange: onInputChange,
              label: showLabel !== false ? execOrReturn(label, form) : null,
              placeholder: showLabel !== false ? placeholder : label,
              name,
              optional: true,
              type: Field.props.Type,
            }
          );

          if (isDisabled) {
            realProps.disabled = true;
          }
          switch (Type) {
            case DatePicker:
              if (realProps.rangeType === "range") {
                fieldArray.push(
                  <WorkXDateRange key={reactKey} {...realProps} portalRef={localRef} />
                );
              } else {
                fieldArray.push(
                  <WorkXDatePicker key={reactKey} {...realProps} portalRef={localRef} />
                );
              }
              break;
            case FormField:
              fieldArray.push(<WorkXField key={reactKey} {...realProps} portalRef={localRef} />);
              break;
            case WorkXNumber:
              fieldArray.push(<WorkXNumber key={reactKey} {...realProps} portalRef={localRef} />);
              break;
            case FormRadioCheckboxButton:
              fieldArray.push(<WorkXCheckbox key={reactKey} {...realProps} />);
              break;
            case FormRadioCheckboxGroup:
              fieldArray.push(<WorkXRadioGroup key={reactKey} {...realProps} />);
              break;
            case WorkXSelectPersistCreatable:
              fieldArray.push(
                <WorkXSelectPersistCreatable
                  key={reactKey}
                  {...realProps}
                  portalRef={localRef}
                  augmentOnCreate={augmentOnCreate}
                  isMulti={getIsMulti(Field, form)}
                />
              );
              break;
            case FormSelect:
            case FormDropdown:
              const opts = await getDisabledOptions(
                fields,
                formData[form.key],
                form,
                userData,
                loadingData.optionsCache
              );
              realProps.placeholder = Field.props.placeholder || "Select...";
              realProps.options = opts[name];

              if (typeof opts[name] === "function") {
                realProps.options = await opts[name](
                  form,
                  formData[form.key],
                  userData,
                  loadingData.optionsCache
                );
              }

              realProps.isMulti = getIsMulti(Field, form);
              realProps.isCountingParentsEnabled = false;

              if (Type === FormDropdown) {
                fieldArray.push(
                  <WorkXDropdown key={reactKey} {...realProps} portalRef={localRef} />
                );
              } else {
                fieldArray.push(<WorkXSelect key={reactKey} {...realProps} portalRef={localRef} />);
              }
              break;
            case FormTimeField:
              fieldArray.push(
                <WorkXTimeField key={reactKey} {...realProps} portalRef={localRef} />
              );
              break;
            case "FormTimeRangeField":
              fieldArray.push(
                <WorkXTimeRangeField key={reactKey} {...realProps} portalRef={localRef} />
              );
              break;
            case Switch:
              fieldArray.push(<WorkXSwitch key={reactKey} {...realProps} />);
              break;
            default:
              console.warn(`Unknown field type: ${Type}`);
              fieldArray.push(<></>);
              break;
          }
        }
      }
      setFieldNodes(fieldArray);
    };
    getFields();
  }, [
    augmentOnCreate,
    classNames,
    fields,
    form,
    formData,
    formDispatch,
    isDisabled,
    loadingData.optionsCache,
    onInputChange,
    userData,
  ]);

  return (
    <WhichTag isDiv={!portalRef && !isReactFragment} ref={localRef} classNames={classNames}>
      {FieldNodes}
    </WhichTag>
  );
};
export default memo(FieldLoopFn);

const WhichTag = forwardRef(({ isDiv, children, classNames }, ref) => {
  if (isDiv) {
    return (
      <div ref={ref} className={classNames}>
        {children}
      </div>
    );
  } else {
    return <>{children}</>;
  }
});
