// model for UI WS getInitialData endpoint
export const INITIAL_DATA_MODEL = {
  clearingData: "clearingData",
  clearingDataResult: "clearingDataResult",
  config: "config",
  configDataResult: "configDataResult",
  eqrcData: "eqrcData",
  eqrcDataResult: "eqrcDataResult",
  firmData: "firmData",
  firmDataResult: "firmDataResult",
  userData: "userData",
  userDataResult: "userDataResult",
};

export const EQRC_AUTH_MODEL = {
  parentMpids: "parentMpids",
  mpids: "mpids",
  ports: "ports",
};

export const USER_CONFIG_MODEL = {
  configApiCacheUrl: "configApiCacheUrl",
  eqrcRulesUrl: "eqrcRulesUrl",
  eqrcHistoryUrl: "eqrcHistoryUrl",
  eqrcShowSubOrderTypes: "eqrcShowSubOrderTypes",
  ptraUrl: "ptraUrl",
  ptraCacheUrl: "ptraCacheUrl",
  maxNumStatsMpid: "maxNumStatsMpid",
  selectAllMpidCount: "selectAllMpidCount",
  rashWebServiceUrl: "rashWebServiceUrl",
  t1RegularSettlement: "t1RegularSettlement",
};
