import React, { useEffect } from "react";
import { AuthHeader, useUserContext } from ".";
import { getKeycloak } from "keycloak";
import { useKeycloak } from "@react-keycloak/web";
import { useLayoutContext } from "../context";
import { Views } from "../../viewConstants";
import { USER_MPIDS_ATTRIBUTE } from "./mpidContext";

const parseMPIDs = mpidString => {
  if (typeof mpidString === "string") {
    return Array.from(new Set(mpidString.split(" "))).sort();
  }
  return [];
};

export const HandleLogInCloak = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [userData, userDispatch] = useUserContext();
  const [, layoutDispatch] = useLayoutContext();
  const k2 = getKeycloak();

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      const mpidAttributes = {};
      if (k2?.idTokenParsed) {
        mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS] = parseMPIDs(
          k2?.idTokenParsed?.[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS]
        );
        mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS] = parseMPIDs(
          k2?.idTokenParsed?.[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS]
        );
        mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS] = Array.from(
          new Set([
            ...mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS],
            ...mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS],
          ])
        );
        mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS] = parseMPIDs(
          k2?.idTokenParsed?.[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS]
        );
        mpidAttributes[USER_MPIDS_ATTRIBUTE.EQRC_MPIDS] = parseMPIDs(
          k2?.idTokenParsed?.[USER_MPIDS_ATTRIBUTE.EQRC_MPIDS]
        );
      }

      userDispatch({
        type: "SET_USER_PARAMS",
        payload: {
          mpidAttributes,
          username: k2.idTokenParsed.preferred_username,
          roles: k2?.realmAccess?.roles || [],
          compdb: k2.idTokenParsed.compdb || null,
          userId: k2?.idTokenParsed?.sub,
        },
      });
    }
  }, [k2, userDispatch, keycloak.authenticated, initialized, layoutDispatch]);

  useEffect(() => {
    if (userData.isLoggedIn) {
      layoutDispatch({
        type: "SET_ENABLE_RIGHT",
        payload: { disable: userData.allowed.views[Views.REAL_TIME_STATS] },
      });
    }
  }, [userData.entitlements, userData.isLoggedIn, layoutDispatch, userData.allowed.views]);

  if (!initialized) {
    return <AuthHeader message="Initializing WorkX..." />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return <AuthHeader message="Authenticating..." />;
  }
  return <>{children}</>;
};
