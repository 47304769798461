import {
  CircleButton,
  DataArray,
  FontAwesomeIcon,
  Section,
  SectionBody,
  SectionHeader,
} from "@nef/core";
import html2canvas from "html2canvas";
import React, { useCallback, useState } from "react";
import { useRecapContext } from "./recapContext";
import "./tradeDetailRecap.css";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  align-items: center;
  width: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;

  & > div {
    width: 50%;
  }

  & > div:first-child {
    padding-right: 1em;
    margin-right: 1em;
    border-right: 1px solid #c4c4c4;
  }
`;

export const RecapSection = ({ trade }) => {
  const [, recapDispatch] = useRecapContext();
  const [isImageLoading, setImageLoading] = useState(false);

  const handleRemoveTrade = useCallback(
    e => {
      recapDispatch({
        type: "REMOVE_TRADE",
        payload: e.target.dataset.id,
      });
    },
    [recapDispatch]
  );

  const handleCaptureTrade = useCallback(
    e => {
      const id = e.target.dataset.id;

      setImageLoading(true);

      html2canvas(document.getElementById(id))
        .then(canvas => {
          const img = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = img;
          link.download = `${id}.png`;
          link.click();
        })
        .finally(() => {
          setImageLoading(false);
        });
    },
    [setImageLoading]
  );
  return (
    <Section id={trade.id} key={trade.id}>
      <SectionHeader>
        <HeaderWrapper>
          <span>ID: {trade.id}</span>
          <div>
            <CircleButton
              data-id={trade.id}
              onClick={handleCaptureTrade}
              size="sm"
              ghost={true}
              disabled={isImageLoading}
              isLoading={isImageLoading}
            >
              <FontAwesomeIcon iconSetClassName="fa" iconClassName="fa-camera" />
            </CircleButton>
            <CircleButton data-id={trade.id} onClick={handleRemoveTrade} size="sm" ghost={true}>
              <FontAwesomeIcon iconSetClassName="fa" iconClassName="fa-times-circle" />
            </CircleButton>
          </div>
        </HeaderWrapper>
      </SectionHeader>
      <SectionBody>
        <BodyWrapper>
          <div key="datarray1">
            <DataArray data={trade.data[0]} />
          </div>
          <div key="datarray2">
            <DataArray data={trade.data[1]} />
          </div>
        </BodyWrapper>
      </SectionBody>
    </Section>
  );
};
