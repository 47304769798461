import { NotificationHub } from "@nef/core";
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { UploadErrorNotification, useUploadContext } from ".";
import { network } from "./network";
import { useUploadCacheDispatch } from "./uploadCache";
import { useUploadDispatch } from "./uploadContext";
import { AckTypes } from "../../wksConstants";

export const UploadInput = () => {
  const [uploadData] = useUploadContext();
  const uploadDispatch = useUploadDispatch();
  const uploadCacheDispatch = useUploadCacheDispatch();

  const clearInput = useCallback(e => (e.currentTarget.value = ""), []);

  const handleFileUpload = useCallback(
    e => {
      const files = Array.from(e.currentTarget.files);
      files.forEach(file => {
        const tempId = uuidv4();
        const formData = new FormData();
        formData.append("file", file);
        const uploadCallback = json => {
          const status = json?.status;
          if (status === AckTypes.ERROR) {
            let message = json?.data?.message;
            if (!message) {
              message = "Upload failed for an unknown reason";
            }
            NotificationHub.send("danger", message);
            uploadCacheDispatch({
              type: "DELETE_FILE_WIIH_ID",
              payload: tempId,
            });
          } else if (status === AckTypes.FAILED) {
            const id = (Object.keys(uploadData.fileIssues).length + 1) * -1;
            uploadCacheDispatch({
              type: "UPDATE_FILE_WITH_ID",
              payload: {
                value: tempId,
                isProcessing: false,
                file: { id, fileName: file.name, countCsvRows: 0 },
              },
            });
            if (json?.data) {
              uploadDispatch({
                type: "ADD_FILE_ISSUES_FOR_FILE",
                payload: {
                  id,
                  data: json.data,
                },
              });
            }
            NotificationHub.send(
              "danger",
              <UploadErrorNotification id={id} fileName={file.name} />
            );
          } else {
            uploadCacheDispatch({
              type: "UPDATE_FILE_WITH_ID",
              payload: {
                value: tempId,
                isProcessing: true,
                file: {
                  id: json.data.id,
                  fileName: file.name,
                },
              },
            });
          }
        };
        const uploadReject = json => {
          const message = `Something went wrong while uploading ${file.name}`;
          NotificationHub.send("danger", message);
          uploadCacheDispatch({
            type: "DELETE_FILE_WIIH_ID",
            payload: tempId,
          });
        };
        uploadCacheDispatch({
          type: "ADD_NEW_FILE",
          payload: {
            id: tempId,
            fileName: file.name,
          },
        });
        network().uploadFile(formData, uploadCallback, uploadReject);
      });
    },
    [uploadCacheDispatch, uploadData.fileIssues, uploadDispatch]
  );
  return (
    <input
      //multiple
      id="file-upload"
      type="file"
      accept=".csv"
      style={{ display: "none" }}
      onClick={clearInput}
      onChange={handleFileUpload}
    />
  );
};
