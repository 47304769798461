import { createContext, useContext } from "react";
import { DispatchFn, DispatchFnSwitch } from "./constants";

class ContextFactory<S, A> {
  createContexts(state: S): [React.Context<React.Dispatch<A | A[]>>, React.Context<S>] {
    return [
      createContext<React.Dispatch<A | A[]>>(() =>
        console.error("Context has not been initialized.")
      ),
      createContext<S>(state),
    ];
  }

  createDispatchFn<S, A>(dispatchFnSwitch: DispatchFnSwitch<S, A>): DispatchFn<S, A> {
    return (state: S, action: A | A[]) => {
      if (Array.isArray(action)) {
        return action.reduce(
          (currState, currAction) => dispatchFnSwitch(currState, currAction),
          state
        );
      } else {
        return dispatchFnSwitch(state, action);
      }
    };
  }

  createContextHook<T>(context: React.Context<T>): () => T {
    return () => useContext<T>(context);
  }
}
export default ContextFactory;
