import { Box, FormRadioCheckboxButton, Tooltip } from "@nef/core";
import React, { memo, useCallback, useMemo, useState } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";

const WorkXCheckbox = props => {
  const {
    value,
    id,
    style,
    name,
    form,
    label,
    disabled,
    handleInputChange: onInputChange,
    getTooltip,
    tooltipPlacement,
    invalid,
  } = props;
  const [isHovered, setHovered] = useState(false);
  const [userData] = useUserContext();

  const handleChange = useCallback(
    e => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        const value = e.checked;
        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [name, onInputChange, form, userData.entitlements, id]
  );

  const computedValue = useMemo(() => {
    switch (value) {
      case true:
      case false:
        return value;
      case "N":
        return false;
      case "Y":
        return true;
      default:
        return false;
    }
  }, [value]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const tooltipText = useMemo(() => {
    if (typeof getTooltip === "function") {
      const tooltipText = getTooltip({ value, disabled });
      if (typeof tooltipText === "string") {
        return tooltipText;
      }
    }
    return "";
  }, [getTooltip, value, disabled]);

  return (
    <Box
      marginRight={3}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {tooltipText.length > 0 && (
        <Tooltip target={id} isOpen={isHovered} placement={tooltipPlacement}>
          {tooltipText}
        </Tooltip>
      )}
      <FormRadioCheckboxButton
        checked={computedValue}
        value={computedValue}
        size="sm"
        id={id}
        label={label}
        onClick={handleChange}
        type="checkbox"
        disabled={disabled}
        invalid={invalid}
      />
    </Box>
  );
};

export default memo(WorkXCheckbox);
