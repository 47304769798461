export interface UserData {
    created: string;
    data: string;
    id: number;
    name: string;
    type: string;
    updated: string;
    user: string;
}

export enum USER_PREFEREENCE_NAME {
    THEME = "workXTheme",
    NOTIFICATION_PLACEMENT = "workXNotificationPlacement"
  }