import React, { useReducer, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  PopoverButton,
  Flex,
  FontAwesomeIcon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nef/core";
import { colors } from "colors";
import styled, { css, keyframes } from "styled-components";
import { getColor } from "@nef/style-utils";
import { LoadingIcon } from "components/loadingIcon";

const SaveAnimation = (primaryColor, successColor) => keyframes`
    0% {
      color: ${primaryColor};
      transform: scale(1);
    }

    65% {
      color: ${successColor};
      transform: scale(1.15);
    }

    100% {
      transform: scale(1);
    }
  `;

const SaveIcon = styled(FontAwesomeIcon)`
  ${props => css`
    animation: ${props.$allowAnimation
      ? css`
          ${SaveAnimation(getColor("primary", 500)(props), getColor("success", 500)(props))}
          2s
        `
      : "none"};
  `}
`;

const StyledPopoverButton = styled(PopoverButton)`
  ${props => `
    color: ${props.$isDisabled ? getColor("gray", 300) : getColor("gray", 900)};
    background-color: ${
      props.$isItemHovered && !props.$isDisabled ? getColor("gray", 25) : getColor("gray", 0)
    };
    cursor: pointer;
    text-decoration: none;
    position: relative;
    line-height: 1;
  `}
`;

const initialState = {
  isRemoveHovered: false,
  isItemHovered: false,
  showModal: false,
  allowAnimation: false,
};

const StyledBox = styled(Box)`
  width: calc(100% - 10px);

  & span {
    display: block;
    width: 100%;
    padding-left: 21px;
    padding-right: 18px;
    & p {
      margin: 0;
    }
  }
  & i {
    margin-right: 0 !important;
  }
`;

const ItemWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
`;

const ActiveItemWrapper = styled(ItemWrapper)`
  left: 8px;
  color: ${props => getColor("primary", 500)(props)};
  ${props => (props.isSaving ? "width: 0px" : "")};
`;

const RemoveItemWrapper = styled(ItemWrapper)`
  right: 8px;
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_REMOVE_HOVERED":
      return { ...state, isRemoveHovered: action.payload };
    case "SET_ITEM_HOVERED":
      return { ...state, isItemHovered: action.payload };
    case "SET_SHOW_MODAL":
      return {
        ...state,
        showModal: action.payload,
        isItemHovered: false,
        isRemoveHovered: false,
      };
    case "SET_ANIMATION":
      return { ...state, allowAnimation: action.payload };
    default:
      return { ...state };
  }
};

export const DynamicDropdownItem = ({
  text,
  isActive,
  isSaving,
  isDisabled,
  onClick,
  onRemove,
  modalOptions,
}) => {
  const [{ isRemoveHovered, isItemHovered, showModal, allowAnimation }, itemDispatch] = useReducer(
    reducer,
    initialState
  );

  const modalArgs = useMemo(() => {
    const defaultModalArgs = {
      header: "Delete",
      body: `Please confirm the deletion of '${text}'`,
      confirmText: "Confirm",
      cancelText: "Cancel",
    };
    if (modalOptions) {
      return Object.assign(defaultModalArgs, modalOptions);
    }
    return defaultModalArgs;
  }, [modalOptions, text]);

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      !isDisabled && onClick(text);
    },
    [text, onClick, isDisabled]
  );

  const handleHideModal = useCallback(() => {
    itemDispatch({
      type: "SET_SHOW_MODAL",
      payload: false,
    });
  }, []);

  const handleCancel = useCallback(
    e => {
      handleHideModal();
    },
    [handleHideModal]
  );

  const handleRemove = useCallback(
    e => {
      if (typeof onRemove === "function") {
        onRemove(text);
      }
      handleHideModal();
    },
    [onRemove, text, handleHideModal]
  );

  const handleShowModal = useCallback(e => {
    e.stopPropagation();
    itemDispatch({
      type: "SET_SHOW_MODAL",
      payload: true,
    });
  }, []);

  useEffect(() => {
    if (isSaving) {
      itemDispatch({
        type: "SET_ANIMATION",
        payload: true,
      });
    }
  }, [isSaving]);

  const removeAnimation = useCallback(() => {
    itemDispatch({
      type: "SET_ANIMATION",
      payload: false,
    });
  }, [itemDispatch]);

  const handleMouseOver = useCallback(() => {
    itemDispatch({
      type: "SET_ITEM_HOVERED",
      payload: true,
    });
  }, []);
  const handleMouseOut = useCallback(() => {
    itemDispatch({
      type: "SET_ITEM_HOVERED",
      payload: false,
    });
  }, []);

  const widthStyle = useMemo(() => ({ width: isSaving && "0px" }), [isSaving]);

  const removeIconStyles = useMemo(
    () => ({
      visibility: isItemHovered ? "visible" : "hidden",
      color: isRemoveHovered ? colors.danger : colors.black,
    }),
    [isItemHovered, isRemoveHovered]
  );

  const handleRemoveOnMouseOver = useCallback(() => {
    itemDispatch({
      type: "SET_REMOVE_HOVERED",
      payload: true,
    });
  }, []);

  const handleRemoveOnMouseOut = useCallback(() => {
    itemDispatch({
      type: "SET_REMOVE_HOVERED",
      payload: false,
    });
  }, []);

  return (
    <>
      <StyledPopoverButton
        disabled={isDisabled}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        size="sm"
        $isDisabled={isDisabled}
        $isItemHovered={isItemHovered}
      >
        {(isActive || isSaving || allowAnimation) && (
          <ActiveItemWrapper alignItems="center" isSaving={isSaving}>
            <LoadingIcon isLoading={isSaving}>
              <SaveIcon
                $allowAnimation={allowAnimation}
                iconClassName="fa-check"
                onAnimationEnd={removeAnimation}
              />
            </LoadingIcon>
          </ActiveItemWrapper>
        )}
        <StyledBox paddingHorizontal={2}>
          <Flex justifyContent="space-between">
            {text}
            {onRemove && (
              <RemoveItemWrapper alignItems="center">
                <FontAwesomeIcon
                  style={removeIconStyles}
                  className="removeItem"
                  onClick={handleShowModal}
                  onMouseOver={handleRemoveOnMouseOver}
                  onMouseOut={handleRemoveOnMouseOut}
                  iconClassName="fa-times"
                />
              </RemoveItemWrapper>
            )}
          </Flex>
        </StyledBox>
      </StyledPopoverButton>
      <Modal isOpen={showModal} ref={modalArgs.ref}>
        <ModalHeader title={modalArgs.header} />
        <ModalBody>{modalArgs.body}</ModalBody>
        <ModalFooter>
          <Button color="secondary" outline={true} onClick={handleCancel}>
            {modalArgs.cancelText}
          </Button>
          <Button color="primary" outline={true} onClick={handleRemove}>
            {modalArgs.confirmText}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
