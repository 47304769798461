import React, { memo } from "react";
import { Section, SectionBody, SectionHeader } from "@nef/core";
import { useStatsContext } from "./context";
import { Status } from "../../wksConstants";
import { WarningSection } from "./warningStyled";
import styled from "styled-components";

const StyledSection = styled(WarningSection)`
  ${props => `
    ${
      props.$isRight
        ? `
      padding: 0 !important;
      border-width: 0;
      box-shadow: none;
      height: 270px;
    `
        : ""
    }
  `}
`;

const StyledSectionBody = styled(SectionBody)`
  padding: 0 !important;
  overflow: hidden;
  height: 200px;
`;

const ChartWrapper = memo(
  ({ children, header = "", subtitle = "", shouldShow, testid, isRight }) => {
    const [data] = useStatsContext();

    return (
      <StyledSection
        data-testid={testid}
        $isWarning={data.hasData && data.feedStatus === Status.ERROR}
        $isRight={isRight}
      >
        <SectionHeader title={header} subtitle={subtitle} />
        <StyledSectionBody className={`${header.replace(" ", "")} chartBody`}>
          {children}
        </StyledSectionBody>
      </StyledSection>
    );
  }
);

export default ChartWrapper;
