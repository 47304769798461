import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { AlertProvider } from "../context";
import { PtraAlertsContainer } from "./alerts";

export const PTRA = () => {
  return (
    <AlertProvider>
      <PtraAlertsContainer />
    </AlertProvider>
  );
};
