import React, { useMemo, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, FontAwesomeIcon, Header, Tag, Indicator } from "@nef/core";
import { useRecapContext } from "./recapContext";
import { colors } from "../../colors";
import styled from "styled-components";

const LegendSection = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const LegendList = styled.ul`
  list-style: none;
  padding: 0 0.5rem;

  & > h4 {
    margin-bottom: 0;
  }

  & > li {
    padding: 0 0.5rem;
  }
`;

const LegendIndicator = styled(Indicator)`
  margin-right: 5px;
`;

const unmatchedLabel = "Unmatched";
const lockedInLabel = "Locked-in";
const tapeLabel = "Tape";
const heldLabel = "Held";
const otherLabel = "Other";

export const statusType = {
  warning: unmatchedLabel,
  success: lockedInLabel,
  primary: tapeLabel,
  danger: heldLabel,
  secondary: otherLabel,
};

export const statusColor = {
  [unmatchedLabel]: "warning",
  [lockedInLabel]: "success",
  [tapeLabel]: "primary",
  [heldLabel]: "danger",
  [otherLabel]: "secondary",
  A: "success",
  B: "secondary",
  C: "secondary",
  D: "secondary",
  E: "secondary",
  F: "success",
  G: "success",
  H: "danger",
  I: "secondary",
  K: "secondary",
  L: "success",
  M: "success",
  O: "warning",
  P: "success",
  R: "primary",
  T: "primary",
  U: "warning",
  V: "success",
  X: "secondary",
  "-": "secondary",
};

const statusLegend = {
  [unmatchedLabel]: {
    U: "Unmatched",
    O: "Open",
  },
  [lockedInLabel]: {
    M: "Matched",
    A: "Accepted",
    L: "Automatic Locked-in",
    G: "One-side submission to Clearing",
  },
  [tapeLabel]: {
    R: "QSR Transactions",
    T: "Tape Only",
  },
  [heldLabel]: {
    H: "Held Transactions",
  },
  [otherLabel]: {
    C: "Cancelled Transactions",
    K: "Cancelled Transactions",
    I: "Cancelled Transactions",
    B: "Cancelled Transactions",
    E: "Cancelled Transactions",
    X: "As-of Transactions",
    D: "Declined",
    "-": "Rejected",
  },
};

const eprLabel = "Executing Party Reported";
const crLabel = "Contra Party Reported";
const cpLabel = "Contract Price";

const sellerLegend = {
  [eprLabel]: {
    description: "Indicates trade entered by Executing Party",
    tag: "EPR",
    tagType: "warning",
  },
  [crLabel]: {
    description: "Indicates trade entered by Contra Party",
    tag: "CR",
    tagType: "success",
  },
  [cpLabel]: {
    description: "Identifies contract amount rather than per share",
    tag: "CP",
    tagType: "primary",
  },
};

export const settlementLabel = "Settlement (Byte 1)";
export const tradeThroughXLabel = "Trade Through Exempt (Byte 2)";
export const extendedHoursAndLateLabel = "Extended Hours & Late (Byte 3)";
export const tradeModiferLabel = "Trade Modifier (Byte 4)";

export const modifierInLegend = {
  [settlementLabel]: {
    "@": "Regular / T+1",
    C: "Cash / T",
    R: "Seller's Option / Other",
  },
  [tradeThroughXLabel]: {
    2: "Self Help",
    3: "ISO",
    4: "Derivatively Priced",
    F: "Intermarket Sweep",
    V: "Contingent Trade",
    J: "Sub Penny",
    7: "Error Correct",
    8: "Print Protect",
  },
  [extendedHoursAndLateLabel]: {
    U: "Pre/Post SOOS",
    T: "Pre/Post Trade",
    Z: "Sold",
  },
  [tradeModiferLabel]: {
    W: "Avg Price",
    1: "Stop Stock",
    P: "Prior Ref Price",
    R: "RA px away",
    X: "RX Options Ex",
  },
};

export const modifierOutLegend = {
  [settlementLabel]: {
    "@": "Regular / T+1",
    C: "Cash / T",
    R: "Seller's Option / Other",
  },
  [tradeThroughXLabel]: {
    4: "Derivatively Priced",
    F: "Intermarket Sweep",
    7: "Qualified Contingent",
  },
  [extendedHoursAndLateLabel]: {
    U: "Pre/Post SOOS",
    T: "Pre/Post Trade",
    Z: "Sold",
  },
  [tradeModiferLabel]: {
    I: "Odd Lot",
    V: "Contingent",
    P: "Prior Ref Price",
    B: "CTS Avg Price",
    W: "Avg Price",
  },
};

const statusHeaderStyle = {
  display: "flex",
  alignItems: "center",
};

export const RecapLegend = () => {
  const [recapData, recapDispatch] = useRecapContext();

  const toggleLegend = useCallback(() => {
    recapDispatch({
      type: "SET_LEGEND",
      payload: null,
    });
  }, [recapDispatch]);

  const modalProps = useMemo(
    () => ({
      isOpen: true,
      backdrop: false,
      toggle: toggleLegend,
    }),
    [toggleLegend]
  );

  const legendIconClassName = "fa-info-circle";
  const legendIconStyle = {
    color: colors.primary,
    marginRight: "1rem",
  };

  switch (recapData.currentLegend) {
    case "status":
      return (
        <Modal {...modalProps}>
          <ModalHeader
            toggle={toggleLegend}
            title={
              <>
                <FontAwesomeIcon iconClassName={legendIconClassName} style={legendIconStyle} />
                Status
              </>
            }
          />
          <ModalBody>
            <LegendSection>
              {Object.keys(statusLegend).map(key => (
                <>
                  <LegendList key={`list-${key}`}>
                    <Header size={4} style={statusHeaderStyle}>
                      <LegendIndicator id={`statusIndicator_${key}`} color={statusColor[key]} />
                      {key}
                    </Header>
                    {Object.keys(statusLegend[key]).map(inKey => (
                      <li key={`in-${key}-${inKey}`}>{`${inKey} = ${statusLegend[key][inKey]}`}</li>
                    ))}
                  </LegendList>
                </>
              ))}
            </LegendSection>
          </ModalBody>
        </Modal>
      );
    case "seller":
      return (
        <Modal {...modalProps}>
          <ModalHeader toggle={toggleLegend}>
            <FontAwesomeIcon iconClassName={legendIconClassName} style={legendIconStyle} />
            Seller
          </ModalHeader>
          <ModalBody>
            <LegendSection>
              {Object.keys(sellerLegend).map(key => (
                <>
                  <span
                    style={{
                      marginBottom: "1rem",
                      display: "grid",
                      gridTemplateColumns: "40px auto",
                    }}
                  >
                    <Tag
                      style={{ height: "16px", marginRight: "5px", justifyContent: "center" }}
                      color={sellerLegend[key]?.tagType}
                    >
                      {sellerLegend[key]?.tag}
                    </Tag>
                    {key}
                  </span>
                  <span>{sellerLegend[key]?.description}</span>
                </>
              ))}
            </LegendSection>
          </ModalBody>
        </Modal>
      );
    case "modifier":
      return (
        <Modal {...modalProps}>
          <ModalHeader
            toggle={toggleLegend}
            title={
              <>
                <FontAwesomeIcon iconClassName={legendIconClassName} style={legendIconStyle} />
                Modifier (In/Out)
              </>
            }
          />
          <ModalBody>
            <LegendSection>
              <Header size={3}>Inbound Values</Header>
              <Header size={3}>Outbound Values</Header>
              {Object.keys(modifierInLegend).map(key => (
                <>
                  <LegendList key={`in-${key}`}>
                    <Header size={4}>{key}</Header>
                    {Object.keys(modifierInLegend[key]).map(inKey => (
                      <li
                        key={`in-${key}-${inKey}`}
                      >{`${inKey} = ${modifierInLegend[key][inKey]}`}</li>
                    ))}
                  </LegendList>
                  <LegendList key={`out-${key}`}>
                    <Header size={4}>{key}</Header>
                    {Object.keys(modifierOutLegend[key]).map(outKey => (
                      <li
                        key={`out-${key}-${outKey}`}
                      >{`${outKey} = ${modifierOutLegend[key][outKey]}`}</li>
                    ))}
                  </LegendList>
                </>
              ))}
            </LegendSection>
          </ModalBody>
        </Modal>
      );
    default:
      return <></>;
  }
};
