import faker from "faker";
import * as refaker from "utils/faker.utils";
import { Factory } from "rosie";

Factory.define("clearing_table_demo")
  .sequence("id")
  .attr("side", () =>
    faker.random.arrayElement([
      { value: "B", label: "Buy" },
      { value: "S", label: "Sell" },
      { value: "X", label: "Cross" },
      { value: "D", label: "Short" },
      { value: "E", label: "Short Exempt" },
    ])
  )
  .attr("symbol", () => faker.random.alphaNumeric(4))
  .attr("quantity", () => faker.random.number({ min: 1, max: 99 }))
  .attr("notional", () => faker.random.number({ min: 5000, max: 50000 }))
  .attr("limitBreached", () => () => faker.random.number({ min: 5000, max: 50000 }))
  .attr("contraMPID", () => refaker.arrayElements(refaker.letters, 4).toUpperCase());

export const clearingTableData = (mpid, numRows) => {
  const list = Factory.buildList("clearing_table_demo", numRows);
  list.forEach((row) => (row.mpid = mpid));
  return list;
};
