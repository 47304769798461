import { FontAwesomeIcon, NotificationHub } from "@nef/core";
import { getColor } from "@nef/style-utils";
import { FieldNames, Forms } from "components/fields";
import { useFormDispatch } from "components/form";
import { LoadingIcon } from "components/loadingIcon";
import { useRefDataContext } from "components/refData";
import { useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import moment from "moment-timezone";
import { doFetchWrapper } from "network";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { RiskConfigAlertTooltip } from "./RiskConfigAlertTooltip";
import { usePtraAlertContext, usePtraAlertDispatch } from "./alertCache";
import { PTRA_CONFIG_CACHE_MODEL, getIdentifierFromAlert } from "./constants";

const AlertBodyContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  color: ${getColor("gray", 500)};
  padding: 3px 0px;
`;

const fontSize = "0.875rem";

const AlertDataList = styled.ul`
  grid-column: 1 / 2;
  align-self: end;
  font-size: ${fontSize};
  line-height: ${fontSize};
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 0.25rem;
  position: relative;
`;

export const SharedBtn = `
padding: 0px;
position: relative;
background: none;
border: none;
cursor: pointer;
font-size: ${fontSize};
line-height: ${fontSize};
`;

export const ReadBtn = styled.button`
  ${props => `
    ${SharedBtn}
    grid-column: 2 / 3;
    align-self: ${props.alignSelf};
    justify-self: ${props.justifySelf};
    color: ${
      props.isRead || props.isLoading
        ? getColor("gray", 500)(props)
        : getColor("primary", 500)(props)
    };
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: ${props.isLoading ? "progress" : "pointer"};

    ${
      !props.isLoading
        ? `
      &:hover {
        text-decoration: underline;
        color: ${props.isRead ? getColor("gray", 400)(props) : getColor("primary", 400)(props)}
      }`
        : ""
    }
`}
`;

export const ReadText = styled.span`
  margin-left: 0.25rem;
`;

const LiFlexGap = styled.li`
  display: flex;
  grid-gap: 0.5rem;
`;

const IntradaySpan = styled.span`
  font-weight: 500;
`;

const dateFmt = "MM/DD/yyyy";

export const ConfigAlertBody = ({ alert, includeMarkAsRead, includeTooltip }) => {
  const [ptrAlertData] = usePtraAlertContext();
  const ptrAlertDispatch = usePtraAlertDispatch();
  const [isMarkAsReadLoading, setMarkAsReadLoading] = useState(false);
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [refData] = useRefDataContext();
  const [isHovered, setHovered] = useState(false);

  const hasPendingRequest = useMemo(() => {
    return isMarkAsReadLoading || ptrAlertData.isMarkAllLoading;
  }, [ptrAlertData.isMarkAllLoading, isMarkAsReadLoading]);

  const isRead = useMemo(() => {
    return !!ptrAlertData.readMap[getIdentifierFromAlert(alert)];
  }, [alert, ptrAlertData.readMap]);

  const handleMarkAsRead = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = json => {
      ptrAlertDispatch({
        type: "SET_READ",
        payload: json,
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as read");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "saveptralertsread"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, ptrAlertDispatch]);

  const handleMarkAsUnread = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = () => {
      ptrAlertDispatch({
        type: "SET_UNREAD",
        payload: [alert],
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as unread");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "deleteunreadptralerts"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, ptrAlertDispatch]);

  const MarkAsRead = useMemo(() => {
    if (includeMarkAsRead) {
      let onClick = undefined;
      if (!hasPendingRequest) {
        if (isRead) {
          onClick = handleMarkAsUnread;
        } else {
          onClick = handleMarkAsRead;
        }
      }
      return (
        <ReadBtn
          alignSelf="end"
          jusfitySelf="end"
          isRead={isRead}
          onClick={onClick}
          isLoading={hasPendingRequest}
        >
          <LoadingIcon isLoading={hasPendingRequest} />
          <ReadText>{isRead ? "Mark as Unread" : "Mark as Read"}</ReadText>
        </ReadBtn>
      );
    } else {
      return undefined;
    }
  }, [includeMarkAsRead, isRead, handleMarkAsUnread, handleMarkAsRead, hasPendingRequest]);

  const handleViewRiskConfig = useCallback(() => {
    const formActions = [
      {
        type: "UPDATE_FORM_VALUE",
        payload: {
          form: Forms.SETTINGS_LIMO,
          field: FieldNames.clearingFirmMPID,
          value: {
            label: refData.clearingNumToMpidMap[alert[PTRA_CONFIG_CACHE_MODEL.clearingNumber]],
            value: refData.clearingNumToMpidMap[alert[PTRA_CONFIG_CACHE_MODEL.clearingNumber]],
          },
          entitlements: userData.entitlements,
        },
      },
      {
        type: "UPDATE_FORM_VALUE",
        payload: {
          form: Forms.SETTINGS_LIMO,
          field: FieldNames.correspondentMPID,
          value: {
            label: alert[PTRA_CONFIG_CACHE_MODEL.correspondentMPID],
            value: alert[PTRA_CONFIG_CACHE_MODEL.correspondentMPID],
          },
          entitlements: userData.entitlements,
        },
      },
    ];
    formDispatch(formActions);
  }, [refData.clearingNumToMpidMap, userData.entitlements, alert, formDispatch]);

  const numChanges = useMemo(() => {
    const changeAttributes = [
      PTRA_CONFIG_CACHE_MODEL.buyAlertLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.buyDefaultActionChanged,
      PTRA_CONFIG_CACHE_MODEL.buyHoldLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.buyRejectLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.isAlertActiveChanged,
      PTRA_CONFIG_CACHE_MODEL.isHoldActiveChanged,
      PTRA_CONFIG_CACHE_MODEL.isMaxTradeActiveChanged,
      PTRA_CONFIG_CACHE_MODEL.isPerTradeActiveChanged,
      PTRA_CONFIG_CACHE_MODEL.isRejectActiveChanged,
      PTRA_CONFIG_CACHE_MODEL.maxTradeLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.netAlertLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.netHoldLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.netRejectLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.perTradeBuyActionChanged,
      PTRA_CONFIG_CACHE_MODEL.perTradeBuyLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.perTradeSellLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.sellAlertLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.sellHoldLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.sellRejectLimitChanged,
      PTRA_CONFIG_CACHE_MODEL.useNetTradingChanged,
    ];
    return changeAttributes.reduce((acc, curr) => {
      if (alert[curr]) {
        acc++;
      }
      return acc;
    }, 0);
  }, [alert]);

  const id = useMemo(() => {
    return `${alert[PTRA_CONFIG_CACHE_MODEL.id]}-${includeTooltip}`;
  }, [alert, includeTooltip]);

  const isIntraDay = useMemo(() => {
    return alert[PTRA_CONFIG_CACHE_MODEL.intradayNextDay] === "I";
  }, [alert]);

  return (
    <AlertBodyContainer>
      <AlertDataList>
        <li>
          Clearer: {refData.clearingNumToMpidMap[alert[PTRA_CONFIG_CACHE_MODEL.clearingNumber]]}
        </li>
        <li>Correspondent: {alert[PTRA_CONFIG_CACHE_MODEL.correspondentMPID]}</li>
        <li>
          Effective Date:{" "}
          {isIntraDay
            ? moment(new Date()).format(dateFmt)
            : moment(new Date()).add(1, "days").format(dateFmt)}
        </li>
        <LiFlexGap>
          {includeTooltip ? (
            <>
              <FontAwesomeIcon
                iconClassName={`fa-eye`}
                onMouseEnter={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
              />
              <RiskConfigAlertTooltip alert={alert} target={id} isOpen={isHovered} />
            </>
          ) : (
            <></>
          )}
          <div id={id}>
            {isIntraDay ? (
              <IntradaySpan>{numChanges} change(s)</IntradaySpan>
            ) : (
              <Link to="/settings/limitmonitor" onClick={handleViewRiskConfig}>
                {numChanges} change(s)
              </Link>
            )}
          </div>
        </LiFlexGap>
      </AlertDataList>
      {MarkAsRead}
    </AlertBodyContainer>
  );
};
