import { Tooltip } from "@nef/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ align }) => `
    text-align: ${align};
  `}
`;

export const TooltipTableCell = ({ children, id, tooltipContent, align }) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHovered(false);
  }, []);

  return (
    <Wrapper id={id} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} align={align}>
      <span>{children}</span>
      <Tooltip target={id} isOpen={isHovered}>
        {tooltipContent ? tooltipContent : children}
      </Tooltip>
    </Wrapper>
  );
};
