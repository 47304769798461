import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStandardTableContext } from "components/standardTable";
import { useUploadContext } from ".";
import { useFormDispatch } from "components/form";
import { StandardTables } from "wksConstants";
import { Forms } from "components/fields/fieldConstants";
import { useUserContext } from "../user";
import { useViewModelDispatch } from "components/context";

export const UploadErrorNotification = ({ id, fileName }) => {
  const viewDispatch = useViewModelDispatch();
  const [standardTableData, standardTableDispatch] = useStandardTableContext();
  const [uploadData, uploadDispatch] = useUploadContext();
  const formDispatch = useFormDispatch();
  const [user] = useUserContext();
  const history = useHistory();

  const handleClick = useCallback(() => {
    standardTableDispatch([
      {
        type: "HANDLE_ROWS_SELECTED",
        payload: {
          table: StandardTables.UPLOAD_FILES,
          rows: [
            {
              ...standardTableData[StandardTables.UPLOAD_FILES].data.find(r => r.id === id),
            },
          ],
          originals: [
            {
              ...standardTableData[StandardTables.UPLOAD_FILES].data.find(r => r.id === id),
            },
          ],
        },
      },
      {
        type: "HANDLE_ROWS_SELECTED",
        payload: {
          table: StandardTables.UPLOAD_ERRORS,
          rows: [],
          originals: [],
        },
      },
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.UPLOAD_FILE_ISSUES,
          data: uploadData.fileIssues[id],
        },
      },
    ]);
    viewDispatch({
      type: "DISABLE_FORM",
      payload: { form: Forms.UPLOAD_REPAIR },
    });
    uploadDispatch({
      type: "SET_ACTIVE_ID",
      payload: { uploadId: id, filename: fileName },
    });
    formDispatch({
      type: "RESET_FORM",
      payload: { form: Forms.UPLOAD_REPAIR, entitlements: user.entitlements },
    });
    history.push("/upload");
  }, [
    fileName,
    formDispatch,
    history,
    id,
    standardTableData,
    standardTableDispatch,
    uploadData.fileIssues,
    uploadDispatch,
    user.entitlements,
    viewDispatch,
  ]);

  return (
    <span>
      {`Upload parsing failed for ${fileName}`}
      <br />
      <Link to="/upload" onClick={handleClick}>
        View File Issues
      </Link>
    </span>
  );
};
