import { Box, Button, CSSGrid, Loader, Section, SectionBody, SectionHeader } from "@nef/core";
import {
  FieldLoop,
  isPostTradeRiskServiceActive,
  riskConfig1a,
  riskConfig1b,
  riskConfig2,
  riskConfig2BuySell,
  riskConfig2Net,
  riskConfig3,
  riskConfig4,
  riskConfig5,
  riskConfig6,
} from "components/fields";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ViewActions } from "../../viewConstants";
import { FieldNames } from "../fields/fieldConstants";
import { useFormContext } from "../form/formContext";
import { KeycloakRoles, useUserContext } from "../user";

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSection = styled(Section)`
  overflow: initial !important;
  max-height: 150%;
  height: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
  height: 100%;
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  box-sizing: border-box;
`;

const SettingsSection = styled.section`
  dispay: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  height: 100%;
  max-height: 100%;
  grid-template-rows: auto 30px;
  grid-gap: 1rem;
  overflow: hidden;
`;

const boxPadding = 2;
const gridCols = "1fr 1fr";
const gridGap = "1rem";

export const LimitSettingForm = ({
  isLoading,
  form,
  showEnabled,
  showDataSources,
  showToggleBuySellNet,
}) => {
  const [user] = useUserContext();
  const [formData] = useFormContext();
  const [open, setOpen] = useState([false, false, false, false, false]);

  const isReadOnly = useMemo(() => {
    if (user.entitlements[KeycloakRoles.PTR_RISK_CONFIG_RO]) {
      return true;
    } else {
      return false;
    }
  }, [user.entitlements]);

  const actionsAndSourcesTitle = useMemo(() => {
    let title = "Actions";
    if (showDataSources) {
      title += " & Sources";
    }
    return title;
  }, [showDataSources]);

  const toggleExpandCollapse = useCallback(
    index => () => {
      open[index] = !open[index];
      setOpen(Array.from(open));
    },
    [open]
  );

  const handleExpandAll = useCallback(() => {
    setOpen([true, true, true, true, true]);
  }, []);

  const handleCollapseAll = useCallback(() => {
    setOpen([false, false, false, false, false]);
  }, []);

  return (
    <Grid>
      {isLoading ? (
        <LoaderWrapper>
          <Loader isLoading={true}>Loading...</Loader>
        </LoaderWrapper>
      ) : (
        <>
          <FormWrapper>
            <SettingsWrapper>
              {showEnabled ? (
                <Box padding={boxPadding} paddingBottom={boxPadding + 1}>
                  <SettingsSection>
                    <FieldLoop
                      isReactFragment={true}
                      form={form}
                      fields={isPostTradeRiskServiceActive}
                      isDisabled={isReadOnly}
                    />
                  </SettingsSection>
                </Box>
              ) : (
                <></>
              )}
              <StyledSection
                expandable={true}
                isOpen={open[0]}
                onExpandCollapse={toggleExpandCollapse(0)}
              >
                <SectionHeader title="Aggregate Limits" />
                <SectionBody>
                  {showToggleBuySellNet ? (
                    <Box padding={boxPadding}>
                      <SettingsSection>
                        <FieldLoop
                          isReactFragment={true}
                          form={form}
                          fields={riskConfig2}
                          isDisabled={isReadOnly}
                        />
                      </SettingsSection>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box padding={boxPadding}>
                    <SettingsSection>
                      {formData[form.key].fields[FieldNames.useNetTrading] === "true" ? (
                        <CSSGrid cols={gridCols} gap={gridGap}>
                          <FieldLoop
                            isReactFragment={true}
                            form={form}
                            fields={riskConfig2Net}
                            isDisabled={isReadOnly}
                          />
                        </CSSGrid>
                      ) : (
                        <CSSGrid cols={gridCols} gap={gridGap}>
                          <FieldLoop
                            isReactFragment={true}
                            form={form}
                            fields={riskConfig2BuySell}
                            isDisabled={isReadOnly}
                          />
                        </CSSGrid>
                      )}
                    </SettingsSection>
                  </Box>
                </SectionBody>
              </StyledSection>
              <StyledSection
                expandable={true}
                isOpen={open[1]}
                onExpandCollapse={toggleExpandCollapse(1)}
              >
                <SectionHeader title="Per Trade Limits" />
                <SectionBody>
                  <Box padding={boxPadding}>
                    <SettingsSection>
                      <CSSGrid cols={gridCols} gap={gridGap}>
                        <FieldLoop
                          isReactFragment={true}
                          form={form}
                          fields={riskConfig3}
                          isDisabled={isReadOnly}
                        />
                      </CSSGrid>
                    </SettingsSection>
                  </Box>
                </SectionBody>
              </StyledSection>
              <StyledSection
                expandable={true}
                isOpen={open[2]}
                onExpandCollapse={toggleExpandCollapse(2)}
              >
                <SectionHeader title="Max Trade Limit" />
                <SectionBody>
                  <Box padding={boxPadding}>
                    <SettingsSection>
                      <CSSGrid cols={gridCols} gap={gridGap}>
                        <FieldLoop
                          isReactFragment={true}
                          form={form}
                          fields={riskConfig5}
                          isDisabled={isReadOnly}
                        />
                      </CSSGrid>
                    </SettingsSection>
                  </Box>
                </SectionBody>
              </StyledSection>
              <StyledSection
                expandable={true}
                isOpen={open[3]}
                onExpandCollapse={toggleExpandCollapse(3)}
              >
                <SectionHeader title={actionsAndSourcesTitle} />
                <SectionBody>
                  <FieldLoop
                    isReactFragment={true}
                    form={form}
                    fields={riskConfig1b}
                    isDisabled={isReadOnly}
                  />
                  <FieldLoop
                    isReactFragment={true}
                    form={form}
                    fields={riskConfig4}
                    isDisabled={isReadOnly}
                  />
                  {showDataSources ? (
                    <FieldLoop
                      isReactFragment={true}
                      form={form}
                      fields={riskConfig1a}
                      isDisabled={isReadOnly}
                    />
                  ) : (
                    <></>
                  )}
                </SectionBody>
              </StyledSection>
              {user.allowed.actions[ViewActions.SUPERVISOR] && (
                <StyledSection
                  expandable={true}
                  isOpen={open[4]}
                  onExpandCollapse={toggleExpandCollapse(4)}
                >
                  <SectionHeader title="Supervisor" />
                  <SectionBody>
                    <Box padding={boxPadding} paddingBottom={boxPadding + 1}>
                      <SettingsSection>
                        <FieldLoop
                          isReactFragment={true}
                          form={form}
                          fields={riskConfig6}
                          isDisabled={isReadOnly}
                        />
                      </SettingsSection>
                    </Box>
                  </SectionBody>
                </StyledSection>
              )}
            </SettingsWrapper>
          </FormWrapper>
          <CSSGrid cols="1fr 1fr" gap="1rem">
            <Button size="sm" onClick={handleExpandAll} color="secondary" outline>
              Expand All
            </Button>
            <Button size="sm" onClick={handleCollapseAll} color="secondary" outline>
              Collapse All
            </Button>
          </CSSGrid>
        </>
      )}
    </Grid>
  );
};
