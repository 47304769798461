import React, { memo } from "react";
import { Droppable } from "react-beautiful-dnd";
import { DraggableItem } from ".";

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "" : "",
  padding: 8,
  width: "auto"
});

const InnerList = memo(({ list, showDragIcon }) => {
  return list.map((chart, i) => {
    return (
      <DraggableItem key={chart.id} id={chart.id} index={i} showDragIcon={showDragIcon}>
        {chart.child}
      </DraggableItem>
    );
  });
});
const DroppableItem = ({ id, list, showDragIcon }) => (
  <Droppable droppableId={id}>
    {(provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={getListStyle(snapshot.isDraggingOver)}
      >
        <InnerList list={list} showDragIcon={showDragIcon} />
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default DroppableItem;
