import moment from "moment-timezone";
import {
  SelectOptions,
  ApiToFieldMap,
  ApiResponseNames,
  FieldNames,
  FieldToApiMap,
  final,
  SettlementValues,
} from "components/fields";
import { isFunction, execOrReturn } from "utils/js.utils";
import { Forms, PriceTradeDigit } from "components/fields/fieldConstants";
import { TradeStatusVals } from "wksConstants";
import { MPIDTypes, queryDirectionTypes, queryTypes } from "../fields";
import { FROM_DATE_SUFFIX, TO_DATE_SUFFIX, formatDateForRequest } from "utils/dateUtils";
export const sampleAPIData = [
  {
    account: null,
    actDate: "2020-02-06",
    actReadyFlag: "R",
    actTime: "16:45:38",
    adjustedSpecialTradeFlag: null,
    adjustedStepOut: "null",
    advertisementInstruction: " ",
    asOfFlag: "Y",
    breakState: "0",
    carryOverFlag: " ",
    clearingFlag: "N",
    clearingPrice: 0,
    clearingStatus: "N",
    contraBranchSeqNum: "10020797            ",
    contraCapacity: 1,
    contraClearingNum: "0161",
    contraExecID: null,
    contraGiveUpMPID: "QWER",
    contraMPID: "MLCO",
    contraOrderID: null,
    contraReferenceNum: null,
    contraShortSaleInd: " ",
    contraTradeReportID: null,
    controlNum: "34620005BF",
    correspondentMPID: "WXYZ",
    entryI1I2: "102B",
    mpid: "AUTO",
    entryMethodFlag: "J",
    entrySymbol: "ZVZZT",
    entryTradeModifiers: null,
    execID: "TMLCOSMOKETEST|1    ",
    executingBranchSeqNum: "10020797            ",
    executingCapacity: 1,
    executingClearingNum: "0161",
    executingGiveUpMPID: "ASDF",
    executingMPID: "MLCO",
    executionDate: "2020-02-06",
    executionTime: "16:45:38",
    fee: 0,
    id: 1,
    intendedMarketFlag: "D",
    lastUpdateDate: "2020-02-06",
    lastUpdateI1I2: "102B",
    lastUpdateTime: "16:45:38",
    lateTradeReportFlag: null,
    matchControlNum: null,
    matchFlag: "X",
    memo: null,
    modifier2: 1,
    modifier2Time: "16:45:38",
    modifier3: 1,
    modifier4: 1,
    modifier4Time: "16:45:38",
    notional: 1447600,
    orderID: "0                   ",
    originalControlDate: "2020-02-06",
    originalControlNum: null,
    originalMarketFlag: "L",
    previousTradeStatus: " ",
    price: 20.68,
    priceOverride: " ",
    priceTradeDigit: "A",
    publishFlag: "N",
    quantity: 70000,
    referenceNum: "18346T",
    referenceReportingVenue: " ",
    rejectSource: " ",
    rejectStatus: null,
    rejectText: " ",
    relatedMarketFlag: "A",
    reportingParty: "M",
    reversalFlag: "D",
    riskEligibleFlag: "?",
    securityClass: "?",
    sellerDays: null,
    sentToClearing: null,
    settlement: 4,
    side: "B",
    specialTradeFlag: " ",
    symbol: "ZVZZT",
    tradeModifiers: "@ U ",
    tradeReferenceNum: "493030",
    tradeReportFlag: " ",
    tradeReportID: null,
    tradeStatus: "T",
    tradeThroughExempt: "N",
    trf: [1],
    wasModifiedFlag: "N",
    workstationId: "TMLCOSMOKETEST|1    ",
    workstationUserId: null,
  },
];

const DONT_CONVERT_SELECT_LIST = [
  FieldNames.settlement,
  FieldNames.tradeThrough,
  FieldNames.special,
  FieldNames.priceOverride,
  FieldNames.rejectText,
];
export const convertSampleAPIData = (data, userMPIDs = [], translateSpecialFlag) => {
  const stepOutOpt = SelectOptions[FieldNames.stepInOut].find(opt => opt.value === "SO");
  const stepInOpt = SelectOptions[FieldNames.stepInOut].find(opt => opt.value === "SI");
  const stepOutFeeOpt = SelectOptions[FieldNames.stepInOut].find(opt => opt.value === "SOWF");

  const nasSalesFeeX = SelectOptions[FieldNames.specialInstructions].find(
    opt => opt.value === "NSFX"
  );
  const nasStepOut = SelectOptions[FieldNames.specialInstructions].find(opt => opt.value === "NSO");
  const posX = SelectOptions[FieldNames.specialInstructions].find(opt => opt.value === "PT");
  const clearNonReg = SelectOptions[FieldNames.specialInstructions].find(
    opt => opt.value === "CNR"
  );
  const clearCopy = SelectOptions[FieldNames.specialInstructions].find(opt => opt.value === "CC");

  if (!Array.isArray(data)) {
    return;
  }
  const newData = [...data];
  newData.forEach(d => {
    Object.entries(d).forEach(([key, field]) => {
      let fieldName = isFunction(ApiToFieldMap[key]) ? ApiToFieldMap[key](d) : ApiToFieldMap[key];
      const fieldDefinition = execOrReturn(final[fieldName], { form: Forms.TR_SCAN });
      if (key === ApiResponseNames.tradeStatus) {
        d[key] = {
          label: TradeStatusVals[d[key]]?.label,
          value: d[key],
        };
      } else if (
        // TODO: this should all be converted when its put on the form, not the table
        fieldDefinition?.props?.options &&
        !DONT_CONVERT_SELECT_LIST.includes(fieldName)
      ) {
        let v;
        let options = isFunction(fieldDefinition.props.options)
          ? fieldDefinition.props.options(Forms.TR_SCAN)
          : fieldDefinition.props.options;
        if (Array.isArray(field)) {
          v = field.map(v => {
            return options.find(o => {
              const derivedValue = o.tableValue ? o.tableValue : o.value;
              return derivedValue === v;
            });
          });
        } else {
          v = options.find(o => {
            const derivedValue = o.tableValue ? o.tableValue : o.value;
            return derivedValue === field;
          });
        }
        if (!!v) {
          d[key] = v;
        }
        // we couldn't match to a select option
        else if (!v && field) {
          d[key] = {
            value: field.value ? field.value : field,
            label: field.label ? field.label : field,
          };
        }
      }
    });

    Object.assign(d, {
      [ApiResponseNames.executionDate]:
        d[FieldToApiMap[FieldNames.executionDate]] &&
        moment(d[FieldToApiMap[FieldNames.executionDate]]),

      [ApiResponseNames.modifier2Time]: timeIn(d[FieldToApiMap[FieldNames.modifier2Time]], "2time"),
      [ApiResponseNames.modifier4Time]: timeIn(d[FieldToApiMap[FieldNames.modifier4Time]], "4time"),
      [ApiResponseNames.symbol]: d[FieldToApiMap[FieldNames.symbol]],
      [ApiResponseNames.adjustedStepOut]: d[FieldToApiMap[FieldNames.adjustedStepOut]],
      [ApiResponseNames.workstationId]: d[FieldToApiMap[FieldNames.workstationId]],
      [ApiResponseNames.priceOverride]: d[FieldToApiMap[FieldNames.priceOverride]],
    });
    d[ApiResponseNames.executionTime] = timeIn(d[ApiResponseNames.executionTime], "exec");

    d[FieldToApiMap[FieldNames.reportDateRange]] =
      d[FieldToApiMap[FieldNames.reportDateRange]] &&
      moment(d[FieldToApiMap[FieldNames.reportDateRange]]);

    if (d[FieldToApiMap[FieldNames.originalControlDate]] === null) {
      delete d[FieldToApiMap[FieldNames.originalControlDate]];
    } else {
      d[FieldToApiMap[FieldNames.originalControlDate]] =
        d[FieldToApiMap[FieldNames.originalControlDate]] &&
        moment(d[FieldToApiMap[FieldNames.originalControlDate]]);
    }

    if (typeof d[FieldToApiMap[FieldNames.referenceReportingVenue]] === "string") {
      d[FieldToApiMap[FieldNames.referenceReportingVenue]] = SelectOptions[
        FieldNames.referenceReportingVenue
      ].find(opt => opt.value === d[FieldToApiMap[FieldNames.referenceReportingVenue]]);
    }

    // begin parsing of act values into ui multi values
    d[ApiResponseNames.clearReportRiskVals] = [];
    if (d[ApiResponseNames.tradeReportFlag] === " ") {
      d[ApiResponseNames.clearReportRiskVals].push({ value: "RPT", label: "Report" });
    }

    if (d[ApiResponseNames.riskEligibleFlag] === "Y") {
      d[ApiResponseNames.clearReportRiskVals].push({ value: "RSK", label: "Risk" });
    }

    switch (d[ApiResponseNames.clearingFlag]) {
      case " ":
        d[ApiResponseNames.clearReportRiskVals].push({ value: "CLR", label: "Clear" });
        break;
      case "G":
        d[ApiResponseNames.clearReportRiskVals].push({ value: "CLR", label: "Clear" });
        d[ApiResponseNames.agreement] = { value: "G", label: "AGU" };
        break;
      case "Z":
        d[ApiResponseNames.agreement] = { value: "Q", label: "QSR" };
        d[ApiResponseNames.clearReportRiskVals].push({ value: "CLR", label: "Clear" });
        break;
      case "Q":
        d[ApiResponseNames.agreement] = { value: "Q", label: "QSR" };
        break;
      default:
        break;
    }

    if (
      userMPIDs.includes(d[FieldToApiMap["counterMPID"]]) &&
      d[FieldToApiMap["reportingParty"]].value === "M" &&
      d[FieldToApiMap["tradeStatusVals"]].value === "U"
    ) {
      d[ApiResponseNames.alleged] = "Y";
    } else {
      d[ApiResponseNames.alleged] = "N";
    }

    switch (d[FieldNames.source]) {
      case "T":
        d[FieldNames.source] = "Report";
        break;
      case "R":
        d[FieldNames.source] = "Repair";
        break;
      case "M":
        d[FieldNames.source] = "Modify";
        break;
      case "V":
        d[FieldNames.source] = "Reverse";
        break;
      case "U":
        d[FieldNames.source] = "Upload";
        break;
      case "O":
        d[FieldNames.source] = "Overtage";
        break;
      case "X":
        d[FieldNames.source] = "Reverse For Modify";
        break;
      case "Z":
        d[FieldNames.source] = "Reverse";
        break;
      default:
        break;
    }
    switch (d["asOfFlag"]?.value) {
      case "Y":
        d["asOfFlag"] = "As-Of";
        break;
      case " ":
        d["asOfFlag"] = "T Day";
        break;
      default:
        break;
    }

    switch (d["reversalFlag"]) {
      case " ":
      case "D":
        d["reversalFlag"] = "";
        break;
      case "K":
        d["reversalFlag"] = "Reversal K";
        break;
      case "R":
        d["reversalFlag"] = "Reversal R";
        break;
      default:
        break;
    }

    if (translateSpecialFlag) {
      switch (d["specialTradeFlag"]) {
        case "Y":
          d[FieldNames.special] = "Yes";
          break;
        case "S":
          d[FieldNames.stepInOut] = stepOutOpt;
          break;
        case "B":
          d[FieldNames.stepInOut] = stepOutFeeOpt;
          break;
        case "X":
          d[FieldNames.stepInOut] = stepOutOpt;
          d[FieldNames.special] = "Yes";
          break;
        case "C":
          d[FieldNames.stepInOut] = stepOutFeeOpt;
          d[FieldNames.special] = "Yes";
          break;
        case "2":
          d[FieldNames.stepInOut] = stepInOpt;
          break;
        case "A":
          d[FieldNames.stepInOut] = stepInOpt;
          d[FieldNames.special] = "Yes";
          break;
        case "F":
          d[FieldNames.specialInstructions] = nasSalesFeeX;
          break;
        case "Q":
          d[FieldNames.specialInstructions] = nasStepOut;
          break;
        case "D":
          d[FieldNames.specialInstructions] = posX;
          break;
        case "M":
          d[FieldNames.specialInstructions] = clearNonReg;
          break;
        case "O":
          d[FieldNames.specialInstructions] = clearCopy;
          break;
        case "P":
          d[FieldNames.special] = "Yes";
          d[FieldNames.specialInstructions] = clearCopy;
          break;
        default:
          break;
      }
    }
  });
  return newData;
};

const stringOrArrayToAPI = (field, d) => {
  if ([null, undefined].includes(d[field])) {
    return null;
  } else if (typeof d[field] === Array) {
    return FieldNames[field].map(v => v.value);
  } else {
    return Object.entries(d[field]).map(([k, v]) => v.value);
  }
};

const dealWithMultiSelect = value => {
  return value && value?.length > 0
    ? value.map(val => {
        if (typeof val === "string") {
          return val;
        } else {
          return val.value;
        }
      })
    : undefined;
};
export const convertDataToAPI = data => {
  const arrayTranformKeys = [FieldNames.mpid, FieldNames.trf, FieldNames.tradeStatusVals];
  data.forEach(d => {
    if (d.converted) {
      return d;
    }
    arrayTranformKeys.forEach(key => {
      if (d[key] && !Array.isArray(d[key])) {
        d[key] = [d[key]];
      }
    });

    const getPriceOverride = priceOverride => {
      let newPO = priceOverride;
      if (priceOverride && priceOverride.label) {
        newPO = undefined;
        if (d[FieldNames.priceOverride].value) {
          if (d[FieldNames.priceOverride].value === "true") {
            newPO = true;
          }
          if (d[FieldNames.priceOverride].value === "false") {
            newPO = false;
          }
        }
      }
      return newPO;
    };

    if (d[FieldNames.isReversal]) {
      d[FieldNames.originalControlNum] = d[FieldNames.reversalOriginalControlNum];
      d[FieldNames.originalControlDate] = moment(d[FieldNames.reversalOriginalControlDate]).format(
        "YYYY-MM-DD"
      );
      d[FieldNames.referenceReportingVenue] = d[FieldNames.reversalReferenceReportingVenue]?.value;
    }
    if (d[FieldNames.MPIDType]) {
      if (d[FieldNames.MPIDType]?.value === MPIDTypes.CLEARER) {
        d.clearingMPID = d[FieldNames.clearingScanMPID].map(o => o.value);
      } else if (d[FieldNames.MPIDType]?.value === MPIDTypes.BROKER) {
        d.brokerMPID = d[FieldNames.clearingScanMPID].map(o => o.value);
      }
    }
    if (d[FieldNames.agreementQueryType]) {
      switch (d[FieldNames.agreementQueryType].value) {
        case "AGU_MPID":
          d.queryType = queryTypes.AGU;
          d.direction = queryDirectionTypes.EXECUTING;
          break;
        case "AGU_Participant":
          d.queryType = queryTypes.AGU;
          d.direction = queryDirectionTypes.PARTICIPANT;
          break;
        case "QSR_MPID":
          d.queryType = queryTypes.QSR;
          d.direction = queryDirectionTypes.EXECUTING;
          break;
        case "QSR_Participant":
          d.queryType = queryTypes.QSR;
          d.direction = queryDirectionTypes.PARTICIPANT;
          break;
        default:
          break;
      }

      d.mpid = d[FieldNames.mpid][0]?.value;
    }

    Object.assign(d, {
      [FieldNames.portUser]:
        d[FieldNames.portUser] &&
        (d[FieldNames.portUser].value ? d[FieldNames.portUser].value : d[FieldNames.portUser]),
      [FieldNames.actDate]: d[FieldNames.actDate], // && d[FieldNames.actDate].format(`Y-MM-DD`),
      [FieldNames.actTime]:
        d[FieldNames.actTime] && timeOut(d[FieldNames.actTime], d.originatingForm),
      [FieldNames.trf]: dealWithMultiSelect(d[FieldNames.trf]),
      [FieldNames.agreement]: d[FieldNames.agreement] && d[FieldNames.agreement].label,
      [FieldNames.mpid]: dealWithMultiSelect(d[FieldNames.mpid]),
      [FieldNames.specialInstructions]:
        d[FieldNames.specialInstructions] && d[FieldNames.specialInstructions].value,
      [FieldNames.stepInOut]: d[FieldNames.stepInOut] && d[FieldNames.stepInOut].value,
      [FieldNames.special]:
        d[FieldNames.special] && d[FieldNames.special].label
          ? d[FieldNames.special].value === "true"
          : d[FieldNames.special],
      [FieldNames.priceOverride]: getPriceOverride(d[FieldNames.priceOverride]),
      [FieldNames.price]:
        d[FieldNames.priceTradeDigit] === PriceTradeDigit.CONTRACT
          ? d[FieldNames.contract]
          : d[FieldNames.price],
      [FieldNames.clearingFlag]: d[FieldNames.clearingFlag] && d[FieldNames.clearingFlag].value,
      [FieldNames.reportingParty]:
        d[FieldNames.reportingParty] && d[FieldNames.reportingParty].value,
      [FieldNames.clearReportRiskVals]: dealWithMultiSelect(d[FieldNames.clearReportRiskVals]),
      [FieldNames.modifier2Time]:
        d[FieldNames.modifier2Time] && timeOut(d[FieldNames.modifier2Time], d.originatingForm),
      [FieldNames.modifier4Time]:
        d[FieldNames.modifier4Time] && timeOut(d[FieldNames.modifier4Time], d.originatingForm),
      [FieldNames.side]: d[FieldNames.side] && d[FieldNames.side].value,
      entrySymbol: d[FieldNames.symbol] && d[FieldNames.symbol],
      [FieldNames.capacity]: d[FieldNames.capacity] && d[FieldNames.capacity].value,
      [FieldNames.counterCapacity]:
        d[FieldNames.counterCapacity] && d[FieldNames.counterCapacity].value,
      [FieldNames.relatedMarketFlag]:
        d[FieldNames.relatedMarketFlag] && d[FieldNames.relatedMarketFlag].value,
      [FieldNames.intendedMarketFlag]:
        d[FieldNames.intendedMarketFlag] && d[FieldNames.intendedMarketFlag].value,
      [FieldNames.adjustedStepOut]:
        d[FieldNames.adjustedStepOut] && d[FieldNames.adjustedStepOut].value,

      [FieldNames.tradeStatusVals]: dealWithMultiSelect(d[FieldNames.tradeStatusVals]),
      [FieldNames.asOf]: stringOrArrayToAPI(FieldNames.asOf, d),
      [FieldNames.modifier2]: d[FieldNames.modifier2] && d[FieldNames.modifier2].value,
      [FieldNames.modifier3]: d[FieldNames.modifier3] && d[FieldNames.modifier3].value,
      [FieldNames.modifier4]: d[FieldNames.modifier4] && d[FieldNames.modifier4].value,
      [FieldNames.modifier2Input]:
        d[FieldNames.modifier2Input] && d[FieldNames.modifier2Input].value,
      [FieldNames.modifier3Input]:
        d[FieldNames.modifier3Input] && d[FieldNames.modifier3Input].value,
      [FieldNames.modifier4Input]:
        d[FieldNames.modifier4Input] && d[FieldNames.modifier4Input].value,
      fromExecDate:
        d[FieldNames.dateRange] &&
        d[FieldNames.dateRange][0] &&
        formatDateForRequest(d[FieldNames.dateRange][0], FROM_DATE_SUFFIX),
      fromExecTime:
        d[FieldNames.tradeTimeRange] &&
        d[FieldNames.tradeTimeRange][0] &&
        timeOut(d[FieldNames.tradeTimeRange][0], Forms.TR_SCAN),
      toExecDate:
        d[FieldNames.dateRange] &&
        d[FieldNames.dateRange][1] &&
        formatDateForRequest(d[FieldNames.dateRange][1], TO_DATE_SUFFIX),
      toExecTime:
        d[FieldNames.tradeTimeRange] &&
        d[FieldNames.tradeTimeRange][1] &&
        timeOut(d[FieldNames.tradeTimeRange][1], Forms.TR_SCAN),
      fromRptDate:
        d[FieldNames.reportDateRange] &&
        d[FieldNames.reportDateRange][0] &&
        formatDateForRequest(d[FieldNames.reportDateRange][0], FROM_DATE_SUFFIX),
      fromRptTime:
        d[FieldNames.reportTimeRange] &&
        d[FieldNames.reportTimeRange][0] &&
        timeOut(d[FieldNames.reportTimeRange][0], Forms.TR_SCAN),
      toRptDate:
        d[FieldNames.reportDateRange] &&
        d[FieldNames.reportDateRange][1] &&
        formatDateForRequest(d[FieldNames.reportDateRange][1], TO_DATE_SUFFIX),
      toRptTime:
        d[FieldNames.reportTimeRange] &&
        d[FieldNames.reportTimeRange][1] &&
        timeOut(d[FieldNames.reportTimeRange][1], Forms.TR_SCAN),
      [FieldNames.executionTime]:
        d[FieldNames.executionTime] && timeOut(d[FieldNames.executionTime], d.originatingForm),
      [FieldNames.executionDate]:
        d[FieldNames.executionDate] && d[FieldNames.executionDate].format("YYYY-MM-DD"),
      [FieldNames.originalControlDate]:
        d[FieldNames.originalControlDate] && moment.isMoment(d[FieldNames.originalControlDate])
          ? d[FieldNames.originalControlDate].format("YYYY-MM-DD")
          : d[FieldNames.originalControlDate],
      [FieldNames.referenceReportingVenue]:
        d[FieldNames.referenceReportingVenue] &&
        d[FieldNames.referenceReportingVenue].value !== undefined
          ? d[FieldNames.referenceReportingVenue].value
          : d[FieldNames.referenceReportingVenue],
      [FieldNames.tradeThrough]:
        d[FieldNames.tradeThrough] && d[FieldNames.tradeThrough].label
          ? d[FieldNames.tradeThrough].value
          : d[FieldNames.tradeThrough],
      [FieldNames.tradeThroughExempt]: d[FieldNames.tradeThroughExempt],
      [FieldNames.submissionDate]:
        d[FieldNames.submissionDate] && d[FieldNames.submissionDate].value
          ? d[FieldNames.submissionDate].value
          : d[FieldNames.submissionDate],
      [FieldNames.rejectText]:
        d[FieldNames.rejectText] && d[FieldNames.rejectText]?.value
          ? d[FieldNames.rejectText].value
          : d[FieldNames.rejectText],
    });

    if (typeof d[FieldNames.settlement]?.value === "string") {
      d[FieldNames.settlement] = d[FieldNames.settlement]?.value;
    }

    if (d[FieldNames.settlement] !== SettlementValues.Other) {
      delete d[FieldNames.sellerDays];
    }

    if (d[FieldNames.tradeThrough] === "Y") {
      d[FieldNames.tradeThrough] = true;
    } else if (d[FieldNames.tradeThrough] === "N") {
      d[FieldNames.tradeThrough] = undefined;
    }

    if (d[FieldNames.special] === "Y") {
      d[FieldNames.special] = true;
    } else if (d[FieldNames.special] === "N") {
      d[FieldNames.special] = undefined;
    }
  });
  return data; //newData;
};

const timeIn = (time, attr) => {
  if (Array.isArray(time)) {
    return time;
  }

  // if we are not a number, use old array and string processing
  // else use new
  if (time) {
    if (isNaN(time)) {
      time = time.split(/[.:]/);
      while (time.length < 4) {
        time.push("00");
      }
      if (time[3] === "00") {
        time[3] = "000000000";
      }
    } else {
      time = `${time}`.padStart(15, "0");
      time = [
        `${time[0]}${time[1]}`,
        `${time[2]}${time[3]}`,
        `${time[4]}${time[5]}`,
        `${time.slice(6)}`,
      ];
    }
    return time;
  } else if (time === 0) {
    return ["00", "00", "00", "000000000"];
  }
  return undefined;
};

export const timeOut = (time, form = "") => {
  if (!time) {
    return null;
  }

  // rebuild the 4 index array in case nef gives us a short one
  const tempArray = [null, null, null, null];
  // if every time slice is empty string, we are invalid
  const parsedTime = tempArray.map((v, i) => ([null, undefined].includes(time[i]) ? "" : time[i]));

  if (typeof time === "string") {
    return time;
  }
  // TODO: jeff maybe we do need this case? it should match time validation rule
  // if we end up throwing here, it usually means we tried to convert a data object twice
  // if (time.every(t => ["","0","00","000"].includes(t))) {
  //   return undefined;
  // }

  if (Array.isArray(time)) {
    const values = time.filter(t => t?.length > 0);
    if (values.length === 0) {
      return undefined;
    }
  }

  const amtPadding = [Forms.TR_SCAN, Forms.TR_REJECTS, Forms.QUERY].includes(form) ? 3 : 9;
  const regex = [Forms.TR_SCAN, Forms.QUERY].includes(form)
    ? /(\d{0,2}:\d{0,2}:\d{0,2})(:)(\d{0,3})/
    : /(\d{0,2}:\d{0,2}:\d{0,2})(:)(\d{0,9})/;
  return parsedTime
    .map((v, i) => (i !== 3 ? `${v}`.padStart(2, 0) : `${v}`.padEnd(amtPadding, 0)))
    .join(":")
    .replace(regex, "$1.$3");
};
