import { Box, FormRadioCheckboxButton, FormRadioCheckboxGroup, Tooltip } from "@nef/core";
import React, { memo, useCallback, useMemo, useState } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from ".";
import { useUserContext } from "../user";

const WorkXRadioGroup = props => {
  const {
    value,
    id,
    style,
    name,
    label,
    disabled,
    handleInputChange: onInputChange,
    getTooltip,
    form,
    tooltipPlacement,
    inline,
    options,
    type,
  } = props;
  const [isHovered, setHovered] = useState(false);
  const [userData] = useUserContext();

  const handleChange = useCallback(
    e => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        let newValue;
        if (type === "checkbox") {
          if (Array.isArray(value)) {
            const target = e.currentTarget;
            newValue = Array.from(value);
            if (target.checked) {
              newValue.push(e.value);
            } else {
              newValue.splice(newValue.indexOf(target.value), 1);
            }
          } else {
            newValue = [e.value];
          }
        } else {
          newValue = e.value;
        }

        if (onInputChange) {
          onInputChange({
            id,
            name,
            value: newValue,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [name, onInputChange, form, userData.entitlements, type, value, id]
  );

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const tooltipText = useMemo(() => {
    if (typeof getTooltip === "function") {
      const tooltipText = getTooltip({ value, disabled });
      if (typeof tooltipText === "string") {
        return tooltipText;
      }
    }
    return "";
  }, [getTooltip, value, disabled]);

  return (
    <Box
      marginRight={3}
      paddingBottom={3}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {tooltipText.length > 0 && (
        <Tooltip target={id} isOpen={isHovered} placement={tooltipPlacement}>
          {tooltipText}
        </Tooltip>
      )}
      <FormRadioCheckboxGroup
        value={value}
        inline={inline}
        id={id}
        label={label}
        onChange={handleChange}
        type={type}
        disabled={disabled}
      >
        {Array.isArray(options) &&
          options.map(option => (
            <FormRadioCheckboxButton
              key={`${option.value}`}
              value={option.value}
              label={option.label}
              type={type}
              disabled={disabled}
            />
          ))}
      </FormRadioCheckboxGroup>
    </Box>
  );
};

export default memo(WorkXRadioGroup);
