import { default as Settings2 } from "./settings2";
export * from "./limitMonitorSettings";
export * from "./limitSettingExport";
export * from "./links";
export * from "./network";
export * from "./rightSidePanelSettings";
export * from "./routes";
export * from "./settingsContext";
export { default as SettingsProvider } from "./settingsContext";
export * from "./settingsWrapper";
export * from "./statsSettings";
export * from "./tradeReportingSettings";
export * from "./riskUploadSettings";
export * from "./riskUploadSettings.input";
export * from "./eqrc";
export * from "./ptra";
export * from "./keycloakExport";

export default Settings2;

export const PTR_UPLOAD_STATUS = {
  Validation: "Failed Validation",
  Reject: "Rejected",
  Partial: "Partial Success",
  Success: "Success",
  Error: "Error",
};

export const ptraEmailPath = "ptraEmails";
