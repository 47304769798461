export const CANCEL_Actions = {
  REJECT: "REJECT ",
  CANCEL_NON_CROSS: "CANCEL_NON_CROSS",
  CANCEL_ALL: "CANCEL_ALL",
};

export const Port_Actions = {
  DISABLE: "DISABLE",
  ENABLE: "ENABLE",
  UNBREACH: "UNBREACH",
};

export const toTitleCase = (title: string) => {
  if (title.length > 0) {
    return title
      .toLowerCase()
      .split(" ")
      .map(s => {
        let letterToCapitalize = s.match(/\w/)?.[0] || "";
        return s.replace(letterToCapitalize, letterToCapitalize.toUpperCase());
      })
      .join(" ");
  }
};
