import { NotificationHub } from "@nef/core";
import { ApiResponseNames } from "components/fields/fieldConstants";
import { useRefDataContext } from "components/refData";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Status } from "wksConstants";
import {
  getRiskAggregatesKey,
  getConvertedRiskAggregatesForUI,
  getConvertedRiskLimitsForUI,
} from "./constants";
import { RiskStatus } from "../../wksConstants";
import { formatUrl } from "../../utils/js.utils";

const aggregateLimitDispatch = createContext();
aggregateLimitDispatch.displayName = "AggregateLimitDispatch";
export const useAggregateLimitDispatch = () => {
  return useContext(aggregateLimitDispatch);
};

const aggregateLimitContext = createContext();
aggregateLimitContext.displayName = "AggregateLimitContext";
export const useAggregateLimitContext = () => {
  return useContext(aggregateLimitContext);
};
const defaultState = {
  data: {},
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isSummaryPolling: false,
  isRightPolling: false,
  isRequesting: false,
  timeStamp: 0,
  instId: 0,
  isLoading: false,
  missingKeys: [],
  requestAbort: false,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_SUMMARY_POLLING": {
      const isSummaryPolling = action.payload;
      let isPolling = false;
      if (isSummaryPolling || state.isRightPolling) {
        isPolling = true;
      }
      let makeInitialRequest = false;
      if (isPolling && !state.hasMadeInitialRequest) {
        makeInitialRequest = true;
      }
      return {
        ...state,
        isSummaryPolling,
        isPolling,
        makeInitialRequest,
      };
    }
    case "SET_RIGHT_POLLING": {
      const isRightPolling = action.payload;
      let isPolling = false;
      if (isRightPolling || state.isSummaryPolling) {
        isPolling = true;
      }
      let makeInitialRequest = false;
      if (isPolling && !state.hasMadeInitialRequest) {
        makeInitialRequest = true;
      }
      return {
        ...state,
        isRightPolling,
        isPolling,
        makeInitialRequest,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...state,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...state,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_REQUEST_ABORT": {
      return { ...state, requestAbort: action.payload };
    }
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...state, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...state, status: action.payload };
    }
    case "SET_TIMESTAMP": {
      return { ...state, timeStamp: action.payload };
    }
    case "SET_INSTANCE_ID": {
      return { ...state, instId: action.payload };
    }
    case "SET_DATA": {
      return { ...state, data: action.payload };
    }
    case "UPDATE_DATA": {
      const newData = action.payload;
      const updatedData = { ...state.data };
      Object.keys(newData).forEach(key => {
        updatedData[key] = newData[key];
      });
      return { ...state, data: updatedData };
    }
    case "UPDATE_COUNTS": {
      const { counts } = action.payload;
      if (state.status !== Status.SUCCESS) {
        return state;
      } else {
        const newData = Object.assign({}, state.data);
        // keys that are in stats, but not in limo-lvc - indicates ref data issue
        const missingKeys = Array.from(state.missingKeys);
        const foundKeyRels = [];
        const missingKeyRels = [];
        let hasUpdate = false;
        Object.values(counts).forEach(countSet => {
          const key = getRiskAggregatesKey(
            countSet[ApiResponseNames.correspondentMPID],
            countSet[ApiResponseNames.clearingFirmNum]
          );
          if (state.data[key]) {
            const missingKeyIdx = missingKeys.indexOf(key);
            if (missingKeyIdx !== -1) {
              missingKeys.splice(missingKeyIdx, 1);
              foundKeyRels.push(countSet);
              hasUpdate = true;
            }
            if (
              state.data[key][ApiResponseNames.heldCountPerTrade] !==
              countSet[ApiResponseNames.heldCountPerTrade]
            ) {
              hasUpdate = true;
              newData[key][ApiResponseNames.heldCountPerTrade] =
                countSet[ApiResponseNames.heldCountPerTrade];
            }
          } else if (!missingKeys.includes(key)) {
            missingKeys.push(key);
            missingKeyRels.push(countSet);
            hasUpdate = true;
          }
        });
        // TODO - clean this up after we relay stream status to UI
        // if (foundKeyRels.length > 0) {
        //   NotificationHub.send("success", "Information has been recovered for the following correspondent(s).",
        //     { subtitle: foundKeyRels.map(rel => `${rel[ApiResponseNames.correspondentMPID]} (${rel[ApiResponseNames.clearingFirmNum]})`).join(", ")}
        //   );
        // }
        if (missingKeyRels.length > 0) {
          NotificationHub.send(
            "danger",
            `Missing information for ${missingKeyRels.length} correspondent(s)`,
            { subtitle: "Please report the issue to WorkX Help Desk" }
          );
        }
        if (hasUpdate) {
          return { ...state, data: newData, missingKeys };
        } else {
          return state;
        }
      }
    }
    default:
      return { ...state };
  }
};

// correspondentMpidRelationships: {corrrespondentMPID: [{clearingFirmMPID, id}, ...]}
const AggregateLimitProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [abort, setAbort] = useState(new AbortController());
  const [refData] = useRefDataContext();

  const clearingRelationshipIds = useMemo(() => {
    if (refData?.correspondentMpidRelationships) {
      return Object.keys(refData.correspondentMpidRelationships).reduce((acc, curr) => {
        refData.correspondentMpidRelationships[curr].forEach(relationship => {
          if (relationship.riskStatus === RiskStatus.ACTIVE) {
            acc.push(relationship.id);
          }
        });
        return acc;
      }, []);
    } else {
      return [];
    }
  }, [refData]);

  useEffect(() => {
    if (state.requestAbort) {
      if (abort) {
        abort.abort();
      }
      dispatchF([
        { type: "SET_REQUEST_ABORT", payload: false },
        {
          type: "SET_REQUESTING",
          payload: true,
        },
      ]);
    }
  }, [abort, state.requestAbort]);

  const getAggregatesData = useCallback(
    isTimeStampZero => {
      const getAggregatesCallback = json => {
        setTimeout(() => {
          dispatchF({ type: "SET_REQUESTING", payload: true });
        }, 2000);

        const actions = [
          {
            type: "SET_TIMESTAMP",
            payload: json.timeStamp,
          },
          {
            type: "SET_INSTANCE_ID",
            payload: json.instId,
          },
          {
            type: "SET_LOADING",
            payload: false,
          },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.SUCCESS,
        });

        const { data } = json;
        const convertedData = (data || []).reduce((acc, curr) => {
          const key = getRiskAggregatesKey(
            curr?.riskConfig?.correspondentMPID,
            curr?.riskConfig?.clearingFirmNum
          );
          const node = getConvertedRiskAggregatesForUI(curr);
          node.riskConfig = getConvertedRiskLimitsForUI(curr.riskConfig);
          acc[key] = node;
          return acc;
        }, {});

        if (isTimeStampZero) {
          actions.push({
            type: "SET_DATA",
            payload: convertedData,
          });
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        } else {
          if (data?.length > 0) {
            actions.push({
              type: "UPDATE_DATA",
              payload: convertedData,
            });
          }
        }
        dispatchF(actions);
      };

      const getAggregatesError = () => {
        const actions = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        actions.push({
          type: "SET_TIMESTAMP",
          payload: 0,
        });
        actions.push({
          type: "SET_INSTANCE_ID",
          payload: 0,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send(
            "danger",
            "An error occurred while retrieving Risk Limit Summary data."
          );
        }

        dispatchF(actions);
      };

      const getAggregatesAbortCb = () => {
        dispatchF({
          type: "SET_LOADING",
          payload: false,
        });
      };

      const bodyArgs = {
        timeStamp: isTimeStampZero ? 0 : state.timeStamp,
        instId: state.instId,
        riskClearingIds: clearingRelationshipIds,
      };

      const abortController = new AbortController();
      setAbort(abortController);
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_LIMO_LVC, "aggregatesByIdList"),

        {
          method: "post",
          mode: "cors",
          signal: abortController.signal,
          headers: getHeaders(),
          body: JSON.stringify(bodyArgs),
        },
        getAggregatesCallback,
        getAggregatesError,
        getAggregatesAbortCb
      );
    },
    [state.timeStamp, state.instId, state.requestFailedCount, state.status, clearingRelationshipIds]
  );

  // -- initial request --
  // should only have to run once as long as timeStamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        setTimeout(() => {
          getAggregatesData(true);
        }, 2000);
      } else {
        dispatchF({ type: "SET_LOADING", payload: true });
        getAggregatesData(true);
      }
    }
  }, [state.makeInitialRequest, getAggregatesData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatchF({ type: "SET_REQUESTING", payload: false });
      getAggregatesData(false);
    } else if (!state.isPolling) {
      dispatchF({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, getAggregatesData, state.requestAbort]);

  return (
    <aggregateLimitDispatch.Provider value={dispatchF}>
      <aggregateLimitContext.Provider value={[state, dispatchF]}>
        {children}
      </aggregateLimitContext.Provider>
    </aggregateLimitDispatch.Provider>
  );
};

export default AggregateLimitProvider;
