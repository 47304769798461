import React, { memo, useEffect } from "react";
import { StatsLeft, StatsMiddle } from ".";
import { useStatsContext } from "components/stats";
import { LeftView } from "components/reportScan";
import { useViewModelContext } from "../context";

const Stats = memo(() => {
  const [data, statsDispatch] = useStatsContext();
  const [viewData] = useViewModelContext();

  useEffect(() => {
    statsDispatch({ type: "SET_IS_POLLING_MAIN", payload: true });

    return () => {
      statsDispatch({ type: "SET_IS_POLLING_MAIN", payload: false });
    };
  }, [statsDispatch]);
  return (
    <>
      {viewData.form && <LeftView view={viewData.view} form={viewData.form} />}
      <StatsMiddle />
    </>
  );
});
export default Stats;
