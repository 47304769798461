import React from "react";
import { Section, SectionBody, SectionHeader } from "@nef/core";

const NoAccess = () => {
  return (
    <>
      <Section
        style={{
          height: "auto",
          width: "350px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <SectionHeader title="No Access" />
        <SectionBody>
          <p>
            Your account is not permissioned to access WorkX. Please contact the help desk by phone
            or email to request access.
            <br />
            <br />
            Phone: (212) 231-5180
            <br />
            Email: TradingServices@nasdaq.com
          </p>
        </SectionBody>
      </Section>
    </>
  );
};

export default NoAccess;
