import { CircleButton, EmptyState, FontAwesomeIcon, Header, Loader } from "@nef/core";
import interact from "interactjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RecapLegend, RecapSection } from "./";
import { useRecapContext } from "./recapContext";
import "./tradeDetailRecap.css";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import _ from "lodash";

const TradeRecap = styled.div`
  color: ${getColor("gray", 500)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(745px, 1fr));
  grid-gap: 0.5rem;
  background-color: ${getColor("gray", 0)};
  margin-bottom: 1rem;
  padding-bottom: 1.5rem;
`;

export const TradeDetailRecapWrapper = styled.div`
  ${props => `
      height: ${props.height}px;
      max-width: ${props.maxWidth};
      position: fixed;
      z-index: 1030;
      bottom: 0;
      right: 0;
      background-color: ${getColor("gray", 0)(props)};
      padding: 0.5rem 0 2rem 0;
      width: ${props.width};
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      box-shadow: rgba(129, 129, 129, 0.2) 0px 1px 3px, rgba(129, 129, 129, 0.12) 0px 2px 1px,
        rgba(129, 129, 129, 0.14) 0px 1px 1px;
      transition: all 0.5s;
      max-height: ${props.maxHeight};
      border-radius: 6px;
      overflow: hidden;
    ${
      props.isOpen
        ? `
            min-height: ${props.minHeight};
          `
        : `
            height: 0px !important;
            padding: 0px !important;
            border: none !important;
          `
    }
  `}
`;

const RecapLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (props.$isTall ? "height: calc(85vh - 49px - 1rem)" : "100px")};
`;

const RecapEmptyState = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-end;
  font-size: 20px;

  & > i {
    margin-left: 5px;
  }
`;

const InnerDrawer = styled.div`
  overflow-y: scroll;
  overflow-y: overlay;
  height: 100%;
  padding: 0 1em;
  /* margin-right: 3px; */
  position: relative;
`;

export const WorkXBottomDrawer = ({
  minHeight = "60",
  widthFactor = 1,
  widthFunction = factor => `${Math.max(1, factor) * 770}px`,
  maxWidth = "calc(100vw - 300px - 1em)",
  title = "Trade Detail Recap",
  children,
}) => {
  const drawer = useRef(null);
  const [recapData, recapDispatch] = useRecapContext();

  const maxHeight = document.documentElement.clientHeight - 60;
  useEffect(() => {
    recapDispatch({ type: "SET_MAX_HEIGHT_VALUE", payload: maxHeight });
  });

  const [height, setHeight] = useState(maxHeight);

  useEffect(() => {
    recapDispatch([
      {
        type: "SET_MAX_HEIGHT",
        payload: true,
      },
      {
        type: "SET_HEIGHT",
        payload: maxHeight,
      },
    ]);
  }, [maxHeight, recapDispatch]);

  useEffect(() => {
    if (drawer?.current) {
      interact(drawer.current).resizable({
        edges: { top: true },
        listeners: {
          move: _.throttle(function (event) {
            const targetHeight = event.rect.height;
            if (targetHeight >= maxHeight) {
              recapDispatch([
                {
                  type: "SET_MAX_HEIGHT",
                  payload: true,
                },
                {
                  type: "SET_HEIGHT",
                  payload: `${Math.min(maxHeight, targetHeight)}`,
                },
              ]);
            } else {
              recapDispatch([
                {
                  type: "SET_MAX_HEIGHT",
                  payload: false,
                },
                {
                  type: "SET_HEIGHT",
                  payload: `${Math.max(targetHeight, minHeight)}`,
                },
              ]);
            }
          }, 300),
        },
      });
    }
  }, [drawer, maxHeight, minHeight, recapDispatch]);

  const handleCloseRecap = useCallback(() => {
    recapDispatch([
      {
        type: "SET_OPEN",
        payload: false,
      },
      {
        type: "EMPTY_OUT_TRADES",
      },
      {
        type: "ABORT_REQUEST",
      },
    ]);
  }, [recapDispatch]);

  const handleToggleHeight = useCallback(() => {
    recapDispatch([
      {
        type: "SET_MAX_HEIGHT",
        payload: !recapData.isMaxHeight,
      },
      {
        type: "SET_HEIGHT",
        payload: !recapData.isMaxHeight ? maxHeight : minHeight,
      },
    ]);
  }, [recapData.isMaxHeight, recapDispatch]);

  useEffect(() => {
    setHeight(recapData.height);
  }, [recapData.height]);

  useEffect(() => handleCloseRecap, [handleCloseRecap]);
  return (
    <>
      <TradeDetailRecapWrapper
        ref={drawer}
        isOpen={recapData.isOpen}
        height={height}
        maxHeight={maxHeight}
        minHeight={minHeight}
        width={widthFunction(widthFactor)}
        maxWidth={maxWidth}
      >
        <HeaderWrapper>
          <Header size={2}>{title}</Header>
          <IconContainer>
            <CircleButton onClick={handleToggleHeight} size="sm" ghost={true}>
              <FontAwesomeIcon
                iconSetClassName="fa"
                iconClassName={recapData.isMaxHeight ? "fa-arrow-down" : "fa-arrow-up"}
              />
            </CircleButton>
            <CircleButton onClick={handleCloseRecap} size="sm" ghost={true}>
              <FontAwesomeIcon iconSetClassName="fa" iconClassName="fa-window-close" />
            </CircleButton>
          </IconContainer>
        </HeaderWrapper>
        <InnerDrawer>{children}</InnerDrawer>
      </TradeDetailRecapWrapper>
      <RecapLegend />
    </>
  );
};

export const TradeDetailRecapInner = () => {
  const [recapData] = useRecapContext();

  return (
    <TradeRecap>
      {recapData.trades.map(trade => (
        <RecapSection key={`${trade.id}_recapSection`} trade={trade} />
      ))}
      {recapData.isLoading && (
        <div className={`${recapData.trades.length === 0 ? "recapLoaderTall " : ""}recapLoader`}>
          <Loader isLoading={true}>Loading...</Loader>
        </div>
      )}
      {recapData.trades.length === 0 && !recapData.isLoading && (
        <div className="recapEmptyState">
          <EmptyState emptyStateTitle="No Trades Selected" icon="documents">
            Add trades with the 'Recap' button
          </EmptyState>
        </div>
      )}
    </TradeRecap>
  );
};

export const TradeDetailRecap = ({ widthFactor = 1 }) => {
  return (
    <WorkXBottomDrawer widthFactor={widthFactor}>
      <TradeDetailRecapInner />
    </WorkXBottomDrawer>
  );
};
