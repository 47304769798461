import React, { useCallback, useEffect, useMemo } from "react";
import { useRefDataContext } from "components/refData";
import { StandardHeader } from "components/standardTable";
import { TabTable } from "components/tabTable";
import { StandardTables } from "wksConstants";
import { useStandardTableDispatch } from "../standardTable";
import { useTabTableDispatch } from "../tabTable";
import { useHKCacheContext, useHKCacheDispatch } from "./heldKilledCache";
import { LimoLVCIndicator } from ".";
import { getTabsForHKTable } from "./constants";
import { ApiResponseNames } from "../fields/fieldConstants";
import { CSSGrid } from "@nef/core";

export const KilledTable = () => {
  const [hkCache] = useHKCacheContext();
  const hkCacheDispatch = useHKCacheDispatch();
  const standardTableDispatch = useStandardTableDispatch();
  const tabTableDispatch = useTabTableDispatch();
  const [refData] = useRefDataContext();

  useEffect(() => {
    let type = "SET_LOADING";
    if (!hkCache.isKilledLoading) {
      type = "SET_NOT_LOADING";
    }
    standardTableDispatch({
      type,
      payload: { table: StandardTables.CV_KILL_REJECT },
    });
  }, [hkCache.isKilledLoading, standardTableDispatch]);

  useEffect(() => {
    if (Object.keys(hkCache.counts).length > 0 && !hkCache.isLoading && !hkCache.shouldAbort) {
      const { active, tabs } = getTabsForHKTable(
        ApiResponseNames.activeKilledId,
        ApiResponseNames.killedTrades,
        ApiResponseNames.killedCount,
        hkCache,
        refData
      );
      tabTableDispatch([
        {
          type: "POST_TRADE_RISK_SUCCESS",
          payload: { table: StandardTables.CV_KILL_REJECT, tabs, active },
        },
        { type: "SET_KILLED_LOADING", payload: true },
      ]);
    } else if (Object.keys(hkCache.counts).length === 0) {
      tabTableDispatch({
        type: "RESET_TABLE",
        payload: {
          table: StandardTables.CV_KILL_REJECT,
        },
      });
    }
  }, [
    hkCache.killedTrades,
    hkCache.isLoading,
    standardTableDispatch,
    tabTableDispatch,
    hkCache.counts,
    refData.clearing,
    hkCache.activeKilledId,
    hkCache.shouldAbort,
    hkCache,
    refData,
  ]);

  const handleTabClick = useCallback(
    id => {
      hkCacheDispatch([
        { type: "SET_ACTIVE_KILLED_ID_AND_ABORT", payload: id },
        { type: "SET_KILLED_LOADING", payload: true },
      ]);
      standardTableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.CV_KILL_REJECT },
      });
    },
    [hkCacheDispatch, standardTableDispatch]
  );

  const Header = useCallback(
    () => (
      <StandardHeader
        title={
          <CSSGrid cols="auto auto" gap="1rem" alignItems="center">
            Killed / Rejected
            <LimoLVCIndicator id="risk-killed-data-feed-status" status={hkCache.status} />
          </CSSGrid>
        }
      />
    ),
    [hkCache.status]
  );

  const killedTableStyle = useMemo(() => {
    return { gridColumn: "3 / 5" };
  }, []);

  return (
    <TabTable
      table={StandardTables.CV_KILL_REJECT}
      header={Header}
      enableLayoutExport={true}
      showRowCountOnTab={false}
      onTabClick={handleTabClick}
      hideRowCount={true}
      hideQueryDate={true}
      style={killedTableStyle}
    />
  );
};
