import { default as Upload } from "./upload";
import { ApiToFieldMap, ApiResponseNames } from "components/fields/fieldConstants";
import { PriceTradeDigit, FieldNames, SettlementOptions } from "../fields";

export * from "./downloadMenuItem";
export * from "./ErrorSuccessTables";
export { default as FileTable } from "./fileTable";
export * from "./fileTable";
export * from "./fileTableHeaderMenu";
export * from "./network";
export * from "./repairButton";
export * from "./repairSubmit";
export * from "./uploadCache";
export { default as UploadCacheProvider } from "./uploadCache";
export * from "./uploadContext";
export { default as UploadProvider } from "./uploadContext";
export * from "./uploadErrorNotification";
export { UploadInput } from "./uploadInput";
export * from "./uploadMiddle";
export * from "./uploadProcessing";
export * from "./uploadQueue";
export * from "./uploadSuccessNotification";
export * from "./circleProgress";
export default Upload;

export const convertUploadTableToForm = requestData => {
  const row = { ...requestData };
  const newRow = {};

  const { tradeStatus, rejectText } = requestData;

  Object.entries(row).forEach(([key, value]) => {
    let fieldName = ApiToFieldMap[key];
    if (typeof fieldName === "function") {
      fieldName = fieldName(row);
    }
    if (fieldName) {
      newRow[fieldName] = value;
    }
  });

  if (row[ApiResponseNames.priceTradeDigit] === PriceTradeDigit.CONTRACT) {
    newRow[FieldNames.priceTradeDigit] = PriceTradeDigit.CONTRACT;
    newRow[FieldNames.contract] = row[ApiResponseNames.price];
  }

  Object.assign(newRow, {
    tradeStatus,
    [FieldNames.priceOverride]: row.priceOverride === "O" ? true : false,
    [FieldNames.sellerDays]: row.sellerDays,
    [FieldNames.settlement]: SettlementOptions[row.settlement],
    [FieldNames.rejectText]: rejectText,
    [FieldNames.modifier2]: row.entryModifier2,
    [FieldNames.modifier3]: row.entryModifier3,
    [FieldNames.modifier4]: row.entryModifier4,
  });
  return newRow;
};
