import React, { useMemo } from "react";
import { ViewForm, ViewForms, ViewTable } from "viewConstants";
import {
  AcceptButton,
  BreakButton,
  CancelButton,
  CloseButton,
  CopyButton,
  DeclineButton,
  ModifyButton,
  RecapButton,
  RepairButton,
  ReverseButton,
} from "components/standardTable/buttons";
import { Views } from "viewConstants";
import { useUserContext } from "components/user";
import { ViewActions } from "../../viewConstants";
import { MatchButton } from "components/standardTable/buttons/match";

export const StatsButtons = () => {
  const [userData] = useUserContext();

  const Buttons = useMemo(() => {
    const buttons = [];
    if (userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
      buttons.push(
        <MatchButton
          table={ViewTable[Views.REAL_TIME_STATS]}
          form={ViewForms[Views.REPORT][ViewForm.MATCH]}
        />
      );
      buttons.push(<AcceptButton key="accept" table={ViewTable[Views.REAL_TIME_STATS]} />);
      buttons.push(<DeclineButton key="decline" table={ViewTable[Views.REAL_TIME_STATS]} />);
      buttons.push(
        <ModifyButton
          key="modify"
          table={ViewTable[Views.REAL_TIME_STATS]}
          form={ViewForms[Views.REAL_TIME_STATS][ViewForm.MODIFY]}
        />
      );
      buttons.push(<CancelButton key="cancel" table={ViewTable[Views.REAL_TIME_STATS]} />);
      buttons.push(<ReverseButton key="reverse" table={ViewTable[Views.REAL_TIME_STATS]} />);
      buttons.push(<BreakButton key="breek" table={ViewTable[Views.REAL_TIME_STATS]} />);
      buttons.push(
        <CopyButton
          key="copy"
          table={ViewTable[Views.REAL_TIME_STATS]}
          form={ViewForms[Views.REAL_TIME_STATS][ViewForm.COPY]}
        />
      );
      buttons.push(
        <RepairButton
          key="repair"
          table={ViewTable[Views.REAL_TIME_STATS]}
          form={ViewForms[Views.REAL_TIME_STATS][ViewForm.REPAIR]}
        />
      );
      buttons.push(<CloseButton key="close" table={ViewTable[Views.REAL_TIME_STATS]} />);
    }
    buttons.push(
      <RecapButton
        key="recap"
        table={ViewTable[Views.REAL_TIME_STATS]}
        form={ViewForms[Views.REAL_TIME_STATS][ViewForm.REPAIR]}
      />
    );
    return buttons;
  }, [userData.allowed.actions]);

  return Buttons;
};
