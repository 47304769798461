import { StandardTables } from "wksConstants";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { EqrcFields, EQRC_SHARES_LOCATED_VALUES } from "./constants";
import { originatingFormKey } from "../../form/constants";
import { formatUrl } from "utils/js.utils";
import { FIELDS_WITH_IMPLICIT_DECIMAL, IMPLICIT_DECIMAL_DIVISOR } from "./constants2";
import { Decimal } from "decimal.js";
import _ from "lodash";
import { NotificationHub } from "@nef/core";
import { StandardTable } from "components/standardTable";

export const urls = {
  [StandardTables.EQRC_FAT_FINGER]: "fatFinger/",
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: "restrictedStockList/",
  [StandardTables.EQRC_GROSS_EXPOSURE]: "grossExposure/",
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: "marketImpactCheck/",
  [StandardTables.EQRC_ORDER_TYPE]: "orderType/",
  [StandardTables.EQRC_ADV_CHECK]: "advCheck/",
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: "orderRateThresholds/",
  [StandardTables.EQRC_SHORT_SALE]: "shortSale/",
  [StandardTables.EQRC_ALERT_CONFIG]: "alert/configuration/",
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: "maxNotionalOrder/",
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: "maxSharesPerOrder/",
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: "sharesLocatedBroker/",
  [StandardTables.EQRC_BROKER_LIST]: "brokerList/",
  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: "sharesLocatedCheck/",
};
const methods = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const updateBodyForGrossExposure = (body, fields) => {
  body[EqrcFields.openExposure] = {
    [EqrcFields.exposureWatch]: new Decimal(
      fields[`${EqrcFields.openExposure}${EqrcFields.exposureWatch}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureWarn]: new Decimal(
      fields[`${EqrcFields.openExposure}${EqrcFields.exposureWarn}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureAction]: new Decimal(
      fields[`${EqrcFields.openExposure}${EqrcFields.exposureAction}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
  };
  body[EqrcFields.executedExposure] = {
    [EqrcFields.exposureWatch]: new Decimal(
      fields[`${EqrcFields.executedExposure}${EqrcFields.exposureWatch}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureWarn]: new Decimal(
      fields[`${EqrcFields.executedExposure}${EqrcFields.exposureWarn}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureAction]: new Decimal(
      fields[`${EqrcFields.executedExposure}${EqrcFields.exposureAction}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
  };
  body[EqrcFields.notionalExposure] = {
    [EqrcFields.exposureWatch]: new Decimal(
      fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureWatch}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureWarn]: new Decimal(
      fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureWarn}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
    [EqrcFields.exposureAction]: new Decimal(
      fields[`${EqrcFields.notionalExposure}${EqrcFields.exposureAction}`]
    ).times(IMPLICIT_DECIMAL_DIVISOR),
  };
};

export const createBody = fields => {
  let body = {};
  let addGrossExposureFields = false;

  Object.entries(fields).forEach(([key, fieldVal]) => {
    if (
      key === EqrcFields.exchange ||
      key === EqrcFields.breachAction ||
      key === EqrcFields.ruleType ||
      key === EqrcFields.mpid ||
      key === EqrcFields.port ||
      key === EqrcFields.groupId
    ) {
      body[key] = fieldVal?.value;
    } else if (
      !body.hasOwnProperty(EqrcFields.sharesLocatedOption) &&
      (key === EqrcFields.sharesLocatedOption || key === EqrcFields.sharesLocatedSSE)
    ) {
      let value = fields[EqrcFields.sharesLocatedOption]?.value;
      if (value === EQRC_SHARES_LOCATED_VALUES.ENABLED && fields[EqrcFields.sharesLocatedSSE]) {
        value = EQRC_SHARES_LOCATED_VALUES.ENABLED_SSE;
      }
      body[EqrcFields.sharesLocateRule] = { [EqrcFields.sharesLocatedOption]: value };
      body[EqrcFields.sharesLocatedOption] = value;
    } else if (
      key.includes(EqrcFields.openExposure) ||
      key.includes(EqrcFields.notionalExposure) ||
      key.includes(EqrcFields.executedExposure)
    ) {
      addGrossExposureFields = true;
      return;
    } else if (key.includes(EqrcFields.symbols) || key.includes(EqrcFields.emailAddresses)) {
      body[key] = Object.values(fieldVal).map(item => item.value);
    } else if (key.includes(originatingFormKey)) {
      return;
    } else if (key === EqrcFields.brokersListRule) {
      body[key] = { [EqrcFields.brokers]: fieldVal?.map(field => field.value) };
      body[EqrcFields.brokers] = fieldVal?.map(field => field.value);
    } else {
      body[key] = fieldVal;
    }

    if (!body[EqrcFields.brokersListRule]) {
      body[EqrcFields.brokersListRule] = { [EqrcFields.brokers]: [] };
    }

    if (!body[EqrcFields.brokers]) {
      body[EqrcFields.brokers] = [];
    }
  });

  if (addGrossExposureFields) {
    updateBodyForGrossExposure(body, fields);
  }

  Object.entries(fields).forEach(([key, fieldVal]) => {
    if (FIELDS_WITH_IMPLICIT_DECIMAL.includes(key)) {
      const d = new Decimal(fields[key]);
      body[key] = d.times(IMPLICIT_DECIMAL_DIVISOR).toString();
    }
  });

  return body;
};

const genericAddRemove = async (
  url,
  table,
  body,
  cbArgs,
  newSymbols,
  currentSymbols,
  callback,
  urlSubRoutes,
  payloadKey,
  responseTransform
) => {
  const added = _.without(newSymbols, ...(currentSymbols || []));
  const removed = _.without(currentSymbols, ...(newSymbols || []));
  const promises = [];
  const failed = [];
  const success = [];

  if (added.length) {
    const addedURL = url.replace(/(^.+)(\?.+)/, `$1${urlSubRoutes.add}$2`);
    promises.push(
      doFetchWrapper(
        addedURL,
        {
          method: methods.put,
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(Object.assign(body, { [payloadKey]: added })),
        },
        json => {
          success.push(json);
        },
        json => {
          failed.push(json);
        }
      )
    );
  }

  if (removed.length) {
    if (promises[0]) {
      await promises[0];
    }

    const removedURL = url.replace(/(^.+)(\?.+)/, `$1${urlSubRoutes.remove}$2`);
    promises.push(
      doFetchWrapper(
        removedURL,
        {
          method: methods.put,
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(Object.assign(body, { [payloadKey]: removed })),
        },
        json => {
          success.push(json);
        },
        json => {
          failed.push(json);
        }
      )
    );
  }

  await Promise.allSettled(promises);

  success.forEach((row, i) => {
    if (responseTransform) success[i] = responseTransform(row);
  });

  return { success, failed, length: promises.length };
};

const addRemoveSymbols = async (url, table, body, cbArgs, callback, errorCallback) => {
  const { symbols: newSymbols, status } = body;
  const { symbols: currentSymbols } = cbArgs?.originalRow?.[status] || {
    originalRow: { [status]: { symbols: [] } },
  };

  const { success, failed, length } = await genericAddRemove(
    url,
    table,
    body,
    cbArgs,
    newSymbols,
    currentSymbols,
    callback,
    { add: "addSymbols", remove: "removeSymbols" },
    "symbols"
  );

  if (success.length === length) {
    callback(table, success[0], cbArgs, {
      showNotification: false,
      resetForm: true,
      doOnClose: true,
    });

    NotificationHub.send("success", "Symbols updated successfully");
  } else {
    const messages = (
      <ul>
        {failed.map(response => {
          return <li key={response.message}>{response.message}</li>;
        })}
      </ul>
    );
    callback(table, failed[0], cbArgs, {
      showNotification: false,
      resetForm: false,
      doOnClose: false,
      deselectRows: false,
    });
    NotificationHub.send("danger", "Symbols update failed", { subtitle: messages });
  }
};

const addRemoveSymbolsUpdateLocateCheck = async (
  url,
  table,
  body,
  cbArgs,
  callback,
  errorCallback
) => {
  const { status } = body;

  let newBrokers;
  if (body.brokerListRule) {
    newBrokers = body.brokersListRule;
  } else {
    newBrokers = body.brokers;
  }

  const { brokers: currentBrokers } = cbArgs?.originalRow?.[status]?.brokersListRule || {
    originalRow: {
      [status]: { brokersListRule: { brokers: [] } },
    },
  };

  let success = [];
  let failed = [];
  let length = 0;

  if (
    (newBrokers?.length > 0 || (newBrokers?.length === 0 && currentBrokers?.length > 0)) &&
    body[EqrcFields.sharesLocatedOption] !== EQRC_SHARES_LOCATED_VALUES.DISABLED
  ) {
    if (body[EqrcFields.hasBrokerList] === false) {
      await genericEQRCRequest(
        url.replace(
          urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
          urls[StandardTables.EQRC_BROKER_LIST]
        ),
        methods.post,
        body,
        table,
        cbArgs,
        (table, json, cbArgs) => {
          length++;
          success.push(json);
        },
        (table, json, cbArgs) => {
          length++;
          failed.push(json);
        }
      );
    } else {
      const result = await genericAddRemove(
        url.replace(
          urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
          urls[StandardTables.EQRC_BROKER_LIST]
        ),
        table,
        body,
        cbArgs,
        newBrokers,
        currentBrokers,
        callback,
        { add: "addBrokers", remove: "removeBrokers" },
        "brokers",
        row => {
          row[EqrcFields.brokersListRule] = { [EqrcFields.brokers]: row[EqrcFields.brokers] };

          return row;
        }
      );

      success = result.success;
      failed = result.failed;
      length = result.length;
    }
  }

  let sharesLocateMessage = undefined;
  await genericEQRCRequest(
    url.replace(
      urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
      urls[StandardTables.EQRC_SHARES_LOCATED_CHECK]
    ),
    methods.put,
    body,
    table,
    cbArgs,
    (table, json, cbArgs) => {
      length++;

      success.push(json);
    },
    (table, json, cbArgs) => {
      length++;
      failed.push(json);

      sharesLocateMessage = json.message;
    }
  );

  if (success.length === length && sharesLocateMessage === undefined) {
    if (success?.[1]?.[EqrcFields.sharesLocatedOption]) {
      success[0][EqrcFields.sharesLocatedOption] = success[1][EqrcFields.sharesLocatedOption];
    } else if (success?.[2]?.[EqrcFields.sharesLocatedOption]) {
      success[0][EqrcFields.sharesLocatedOption] = success[2][EqrcFields.sharesLocatedOption];
    }

    if (success?.[1]?.[EqrcFields.brokers]) {
      success[0][EqrcFields.brokersListRule] = success[1][EqrcFields.brokers];
      success[0][EqrcFields.brokers] = success[1][EqrcFields.brokers];
    }

    callback(table, success[0], cbArgs, {
      showNotification: false,
      resetForm: true,
      doOnClose: true,
    });
    NotificationHub.send("success", "Shares Locate update successful");
  } else {
    sharesLocateMessage && failed.push({ message: sharesLocateMessage });
    const messageNode = (
      <ul>
        {failed.map(message =>
          message.message ? (
            <li key={message.message}>{message.message}</li>
          ) : (
            Object.entries(message).map(([key, innerMessage]) => (
              <li li key={innerMessage}>
                {innerMessage}
              </li>
            ))
          )
        )}
      </ul>
    );

    errorCallback(table, failed[0], cbArgs, {
      showNotification: false,
      resetForm: false,
      doOnClose: false,
      deselectRows: false,
    });

    NotificationHub.send("danger", "Shares Locate update not successful", {
      subtitle: messageNode,
    });
  }
};

const genericEQRCRequest = async (
  url,
  method,
  body,
  table,
  cbArgs,
  callback,
  errorCallback,
  onClose
) => {
  let preparedBody = body;
  if (typeof body !== "string") {
    preparedBody = JSON.stringify(body);
  }

  let parsedUrl = url;

  if (
    (url.includes(urls[StandardTables.EQRC_SHORT_SALE]) ||
      url.includes(urls[StandardTables.EQRC_RESTRICTED_STOCK_LIST])) &&
    method === methods.put
  ) {
    addRemoveSymbols(url, table, body, cbArgs, callback, errorCallback, onClose);
  } else if (
    url.includes(urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]) &&
    method === methods.put
  ) {
    addRemoveSymbolsUpdateLocateCheck(url, table, body, cbArgs, callback, errorCallback, onClose);
  } else {
    await doFetchWrapper(
      parsedUrl,
      {
        method: method,
        mode: "cors",
        headers: getHeaders(),
        body: preparedBody,
      },
      json => {
        callback(table, json, cbArgs);
      },
      json => errorCallback(table, json, cbArgs)
    );
  }
};

export const getAlertConfigs = async (baseUrl, callback, errorCallback) => {
  let url = `${baseUrl}${urls[StandardTables.EQRC_ALERT_CONFIG]}`;
  genericEQRCRequest(
    url,
    methods.get,
    null,
    StandardTables.EQRC_ALERT_CONFIG,
    null,
    callback,
    errorCallback
  );
};

const getRuleErrorMsg = "Error retrieving rules";
const getRules = async (baseUrl, status, table, callback, errorCallback) => {
  const url = formatUrl(baseUrl, `${urls[table]}?status=${status}`);
  genericEQRCRequest(url, methods.get, null, table, null, callback, errorCallback);
};
export const getRulesPromise = async (baseUrl, status, table) => {
  return new Promise((resolve, reject) => {
    const callback = (table, json) => {
      resolve({ status, table, json });
    };
    const error = () => {
      reject(getRuleErrorMsg);
    };
    getRules(baseUrl, status, table, callback, error);
  });
};
export const createRule = async (baseUrl, intraday, body, table, callback, errorCallback) => {
  intraday = intraday != null ? intraday : false;
  const url = formatUrl(baseUrl, `${urls[table]}?intraday=${intraday}`);
  genericEQRCRequest(url, methods.post, body, table, null, callback, errorCallback);
};
export const updateRule = async (
  baseUrl,
  intraday,
  body,
  table,
  cbArgs,
  callback,
  errorCallback
) => {
  intraday = intraday != null ? intraday : false;
  const url = formatUrl(baseUrl, `${urls[table]}?intraday=${intraday}`);
  genericEQRCRequest(url, methods.put, body, table, cbArgs, callback, errorCallback);
};
export const deleteRule = async (baseUrl, body, table, cbArgs, callback, errorCallback) => {
  const url = formatUrl(baseUrl, urls[table]);
  genericEQRCRequest(url, methods.delete, body, table, cbArgs, callback, errorCallback);
};
export const getOneRule = async (baseUrl, body, table, callback, errorCallback) => {
  const url = formatUrl(baseUrl, urls[table]);
  genericEQRCRequest(url, methods.get, body, table, null, callback, errorCallback);
};
export const auditRule = async (baseUrl, body, table, callback, errorCallback) => {
  const url = formatUrl(formatUrl(baseUrl, urls[table]), "audit");
  genericEQRCRequest(url, methods.post, body, table, null, callback, errorCallback);
};
export const alertHistory = async (baseUrl, body, callback, errorCallback) => {
  const url = formatUrl(baseUrl, "alert/dateAlert/filter");
  doFetchWrapper(
    url,
    {
      method: methods.post,
      mode: "cors",
      headers: getHeaders(),
      body,
    },
    json => {
      callback(json);
    },
    () => errorCallback()
  );
};
