import React, { useCallback } from "react";
import { DropdownItem } from "@nef/core";
import { getHeaders } from "../../keycloak";

export const DownloadMenuItem = ({ filename, url }) => {
  const handleDownloadDoc = useCallback(
    e => {
      e.preventDefault();
      fetch(url, {
        headers: { Authorization: getHeaders().Authorization },
      })
        .then(res => {
          return res.blob();
        })
        .then(data => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = filename;
          a.click();
        });
    },
    [filename, url]
  );

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleDownloadDoc}>
      <DropdownItem>{filename}</DropdownItem>
    </a>
  );
};
