import React, { useEffect } from "react";
import { useLayoutContext } from "components/context";
import { SettingsLinks, SettingsRoutes } from ".";
import Middle from "components/middle";
import { Section, SectionHeader } from "@nef/core";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const SettingsMain = styled.main`
  display: grid;
  grid-template-columns: ${props =>
    props.$fullWidth ? "280px minmax(400px, auto)" : "280px minmax(400px, 600px)"};
  grid-gap: 0.5rem;
  height: 100%;
  box-sizing: border-box;
`;

const MiddleSection = styled(Section)`
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  border: none !important;
  padding-bottom: 0 !important;
`;

const Settings2 = () => {
  const [, layoutDispatch] = useLayoutContext();
  const { pathname } = useLocation();
  useEffect(() => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () =>
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
  }, [layoutDispatch]);

  // TODO - change how we do fullwidth.. this is so bad..
  return (
    <Middle>
      <Section>
        <SettingsMain
          $fullWidth={
            pathname.indexOf("riskUpload") !== -1 ||
            pathname.indexOf("riskAlertsTable") !== -1 ||
            pathname.indexOf("eqrcAlertsTable") !== -1 ||
            pathname.indexOf("ptraEmail") !== -1 ||
            pathname.indexOf("keycloakExport")
          }
        >
          <Section>
            <SectionHeader title="Settings" />
            <SettingsLinks />
          </Section>
          <MiddleSection>
            <SettingsRoutes />
          </MiddleSection>
        </SettingsMain>
      </Section>
    </Middle>
  );
};

export default Settings2;
