import React from "react";
import {
  ApiResponseNames,
  getSettlementLabelFromDTO,
  PriceTradeDigit,
  SettlementValues,
} from "../fields/fieldConstants"; // use full path to avoid circular dependency, maybe just move const files to const dir
import {
  statusFilter,
  StatusFilter,
  IndicatorWrapper,
} from "../standardTable/columnDefinitions.statuses"; // use full path to avoid circular dependency, maybe just move const files to const dir
import moment from "moment";
import { formatNumberToCurrencyString, multiplyFloats } from "../../utils/js.utils";
import { CellCopy } from "./cellCopy";
import { TooltipTableCell } from "./tooltipCell";

export const sortFormattedNumber = (a, b) => {
  const aNum = parseFloat(a);
  const bNum = parseFloat(b);
  if (aNum > bNum) {
    return 1;
  } else if (aNum < bNum) {
    return -1;
  }
  return 0;
};

export const sortMomentDates = (aDate, bDate) => {
  if (!aDate.isValid()) {
    return -1;
  }
  if (!bDate.isValid()) {
    return 1;
  }
  if (aDate.isAfter(bDate)) {
    return 1;
  } else if (aDate.isBefore(bDate)) {
    return -1;
  }
  return 0;
};

export const sortDateColumn = fieldName => (a, b) => {
  const aDate = moment(a.original[ApiResponseNames[fieldName]]);
  const bDate = moment(b.original[ApiResponseNames[fieldName]]);
  return sortMomentDates(aDate, bDate);
};

export const getNotionalValue = row => {
  let num;
  if (row[ApiResponseNames.priceTradeDigit] === "B") {
    // contract price
    num = row[ApiResponseNames.price];
  } else {
    num = multiplyFloats(row[ApiResponseNames.price] || 0, row[ApiResponseNames.quantity] || 0);
  }
  return num;
};

export const PriceType = {
  [PriceTradeDigit.PER_SHARE]: "Price",
  [PriceTradeDigit.CONTRACT]: "Contract Amt",
};

const columns = [
  {
    Header: "Action Result",
    accessor: data => (data["status"] === "danger" ? "reject" : data["status"]),
    headerClassName: "tableWordwrap",
    width: 70,
    Cell: ({ row }) => {
      return (
        <IndicatorWrapper
          statusText={row.original.statusText}
          status={row.original.status}
          tradeActionType={row.original?.statusText?.tradeActionType}
          ackType={row.original?.statusText?.ackType}
          index={row.id}
        />
      );
    },
    id: "status",
  },
  {
    id: "tradeStatus",
    Header: "Trade Status",
    accessor: data => data[ApiResponseNames.tradeStatus]?.label,
    filter: statusFilter,
    Filter: StatusFilter,
    canFilter: true,
    filterable: true,
    width: 90,
  },
  {
    Header: "Exec MPID",
    accessor: data =>
      data[ApiResponseNames.executingMPID]?.label
        ? data[ApiResponseNames.executingMPID]?.label
        : data[ApiResponseNames.executingMPID],
    id: "mpid",
    width: 80,
  },
  {
    Header: "TRF",
    accessor: data => data[ApiResponseNames.trf]?.label,
    id: "trf",
    width: 70,
  },
  {
    id: "reportingParty",
    Header: "Reporting Party Role",
    accessor: data => data[ApiResponseNames.reportingParty]?.label,
    width: 120,
  },
  {
    id: "reportingMpid",
    Header: "Reporting MPID",
    accessor: data => data[ApiResponseNames.entryMPID],
    width: 80,
  },
  {
    Header: "Report Type",
    accessor: data => {
      const a = (data[ApiResponseNames.clearReportRiskVals] || []).map(val => val.label);
      return a.join(", ");
    },
    width: 120,

    id: "ClearReport",
  },
  {
    Header: "Agreements",
    accessor: data => data[ApiResponseNames.agreement]?.label,

    id: "clearingFlag",
  },
  {
    id: "contraMPID",
    Header: "Contra MPID",
    accessor: data =>
      data[ApiResponseNames.contraMPID]?.label
        ? data[ApiResponseNames.contraMPID]?.label
        : data[ApiResponseNames.contraMPID],
    width: 90,
  },
  {
    id: "correspondentMPID",
    Header: "Correspondent",
    accessor: data => data[ApiResponseNames.correspondentMPID],
    width: 130,
  },
  {
    id: "asOf",
    Header: "As Of",
    accessor: data => {
      return data[ApiResponseNames.asOf];
    },
    width: 80,
  },

  {
    id: "side",
    Header: "Side",
    accessor: data => data[ApiResponseNames.side]?.label,
    width: 120,
  },
  {
    id: "symbol",
    Header: "Symbol",
    accessor: data => data[ApiResponseNames.symbol],
    width: 80,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: row => row[ApiResponseNames.quantity]?.toLocaleString(),
    sortType: (a, b) =>
      sortFormattedNumber(
        a.original[ApiResponseNames.quantity],
        b.original[ApiResponseNames.quantity]
      ),
    isNumeric: true,
    width: 120,
  },
  {
    id: "price",
    Header: "Price",
    accessor: row => row[ApiResponseNames.price],
    Cell: ({ row }) => formatNumberToCurrencyString(row.original[ApiResponseNames.price]),
    sortType: "basic",
    isNumeric: true,
    width: 120,
  },
  {
    id: "priceTradeDigit",
    Header: "Price Type",
    accessor: row => PriceType[row[ApiResponseNames.priceTradeDigit]],
    width: 120,
  },
  {
    id: "notionalValue",
    Header: "Notional",
    accessor: data => getNotionalValue(data),
    Cell: ({ row }) => formatNumberToCurrencyString(getNotionalValue(row.original)),
    sortType: "basic",
    isNumeric: true,
    width: 120,
  },
  {
    Header: "Price Override",
    accessor: data => (data[ApiResponseNames.priceOverride] === "O" ? "Yes" : "No"),
    id: "priceOverride",
    width: 130,
  },
  {
    Header: "Trade Date",
    accessor: data => {
      try {
        const ret =
          data[ApiResponseNames.executionDate] &&
          data[ApiResponseNames.executionDate].format("MM/DD/YYYY");
        return ret;
      } catch {
        return "";
      }
    },
    sortType: sortDateColumn(ApiResponseNames.executionDate),
    id: "actDate",
    width: 90,
  },
  {
    Header: "Trade Time",
    accessor: row => {
      // TODO: there is no way to abstrally do this
      if (row.executionTimeNanos) {
        return row.executionTimeNanos;
      }

      return (
        row[ApiResponseNames.executionTime] &&
        `${row[ApiResponseNames.executionTime].slice(0, 3).join(":")}${
          row[ApiResponseNames.executionTime].slice(3).length > 0
            ? `.${row[ApiResponseNames.executionTime].slice(3)}`
            : ""
        }`
      );
    },
    id: "actTime",
    width: 160,
  },
  {
    Header: "Report Date",
    //TODO: jeff fix this mapping so we don't need this code here
    accessor: data => {
      if (data[ApiResponseNames.reportDateRange] === null) {
        return "";
      }

      let date =
        data[ApiResponseNames.reportDateRange] && data[ApiResponseNames.reportDateRange].format
          ? data[ApiResponseNames.reportDateRange].format("MM/DD/YYYY")
          : moment(data[ApiResponseNames.reportDateRange]).format("MM/DD/YYYY");
      return date;
    },
    sortType: sortDateColumn(ApiResponseNames.reportDateRange),
    id: "reportDate",
    width: 90,
  },
  {
    Header: "Report Time",
    accessor: "actTime",
    id: "reportTime",
    width: 120,
  },
  {
    Header: "Reversal Flag",
    widrth: 70,
    id: "reversalFlag",
    accessor: data => data.reversalFlag,
  },
  {
    Header: "Late Trade Report Flag",
    id: "lateTradeReportFlag ",
    accessor: data => data.lateTradeReportFlag,
    width: 120,
  },

  {
    Header: "Settlement",
    accessor: data => {
      return getSettlementLabelFromDTO(
        data,
        data[ApiResponseNames.settlement] === SettlementValues.Other
      );
    },
    width: 150,
    id: "settlement",
  },
  {
    Header: "Contra Capacity",
    accessor: data => data[ApiResponseNames.contraCapacity]?.label,
    width: 150,
    id: "contraCapacity",
  },
  {
    Header: "Contra Give Up",
    accessor: data => data[ApiResponseNames.contraGiveUpMPID],
    width: 90,
    id: "contraGiveUpMPID",
  },
  {
    Header: "Contra Branch Seq #",
    accessor: data => data[ApiResponseNames.contraBranchSeqNum],
    width: 130,
    id: "contraBranchSeqNum",
  },
  {
    Header: "Contra Clearing #",
    accessor: data => data[ApiResponseNames.contraClearingNum],
    width: 110,
    id: "contraClearingNum",
  },
  {
    Header: "Exec Capacity",
    accessor: data => data[ApiResponseNames.executingCapacity]?.label,
    width: 130,
    id: "executingCapacity",
  },
  {
    Header: "Exec Give Up",
    accessor: data => data[ApiResponseNames.executingGiveUpMPID],
    width: 110,
    id: "executingGiveUpMPID",
  },
  {
    Header: "Exec Branch Seq #",
    accessor: data => data[ApiResponseNames.executingBranchSeqNum],
    width: 120,
    id: "executingBranchSeqNum",
  },
  {
    Header: "Exec Clearing #",
    accessor: data => data[ApiResponseNames.executingClearingNum],
    width: 130,
    id: "executingClearingNum",
  },
  {
    Header: "Out Mod2",
    accessor: data => data[ApiResponseNames.modifier2]?.label,
    width: 120,
    id: "modifier2",
  },
  {
    Header: "Time Mod2",
    accessor: data => {
      // TODO: there is no way to abstrally do this
      if (data.modifier2TimeNanos) {
        return data.modifier2TimeNanos;
      }

      return (
        data[ApiResponseNames.modifier2Time] &&
        `${data[ApiResponseNames.modifier2Time].slice(0, 3).join(":")}${
          data[ApiResponseNames.modifier2Time].slice(3).length > 0
            ? `.${data[ApiResponseNames.modifier2Time].slice(3)}`
            : ""
        }`
      );
    },
    id: "modifier2Time",
    width: 160,
  },
  {
    Header: "Inp Mod2",
    accessor: data => data[ApiResponseNames.modifier2Input]?.label,
    width: 120,
    id: "modifier2Input",
  },
  {
    Header: "Out Mod3",
    accessor: data => data[ApiResponseNames.modifier3]?.label,
    id: "modifier3",
    width: 120,
  },
  {
    Header: "Inp Mod3",
    accessor: data => data[ApiResponseNames.modifier3Input]?.label,
    id: "modifier3Input",
    width: 120,
  },
  {
    Header: "Out Mod4",
    accessor: data => data[ApiResponseNames.modifier4]?.label,
    id: "modifier4",
    width: 120,
  },
  {
    Header: "Time Mod4",
    accessor: data => {
      // TODO: there is no way to abstrally do this
      if (data.modifier4TimeNanos) {
        return data.modifier4TimeNanos;
      }

      return (
        data[ApiResponseNames.modifier4Time] &&
        `${data[ApiResponseNames.modifier4Time].slice(0, 3).join(":")}${
          data[ApiResponseNames.modifier4Time].slice(3).length > 0
            ? `.${data[ApiResponseNames.modifier4Time].slice(3)}`
            : ""
        }`
      );
    },
    id: "modifier4Time",
    width: 160,
  },
  {
    Header: "Inp Mod4",
    accessor: data => data[ApiResponseNames.modifier4Input]?.label,
    id: "modifier4Input",
    width: 120,
  },
  {
    Header: "Related Market",
    accessor: data => data[ApiResponseNames.relatedMarketFlag]?.label,
    width: 95,
    id: "relatedMarketFlag",
  },
  {
    Header: "Intended Market",
    accessor: data => data[ApiResponseNames.intendedMarketFlag]?.label,
    id: "intendedMarketFlag",
    width: 95,
  },
  {
    Header: "Step in/out",
    accessor: data => data[ApiResponseNames.stepInOut]?.label,
    id: "stepInOut",
    width: 120,
  },
  {
    Header: "Fee",
    accessor: data => data[ApiResponseNames.fee],
    id: "fee",
    width: 90,
  },
  {
    Header: "Clearing Price",
    accessor: row => row[ApiResponseNames.clearingPrice],
    Cell: ({ row }) => formatNumberToCurrencyString(row.original[ApiResponseNames.clearingPrice]),
    sortType: "basic",
    isNumeric: true,
    width: 130,
    id: "clearingPrice",
  },
  {
    Header: "Special",
    accessor: data => data[ApiResponseNames.special],
    id: "special",
  },
  {
    Header: "Special Instructions",
    accessor: data => data[ApiResponseNames.specialInstructions]?.label,
    id: "specialInstructions",
    width: 150,
  },
  {
    Header: "Additional Instructions",
    accessor: data => data[ApiResponseNames.memo],
    id: "memo",
    width: 150,
  },
  {
    Header: "Trade Ref #",
    accessor: data => data[ApiResponseNames.tradeReferenceNum],
    id: "tradeReferenceNum",
    width: 120,
  },

  {
    Header: "Control #",
    accessor: data => data[ApiResponseNames.controlNum],
    Cell: ({ row }) => {
      if (row?.original[ApiResponseNames.controlNum]) {
        const id = `copy-${row.original[ApiResponseNames.controlNum]}`;
        return <CellCopy id={id} value={row.original[ApiResponseNames.controlNum]} />;
      } else {
        return "";
      }
    },
    // width: 130,
    id: "controlNum",
  },
  {
    Header: "Port/User",
    accessor: data => data[ApiResponseNames.portUser]?.label,
    width: 95,
    id: "portUser",
  },
  {
    Header: "Alleged",
    accessor: data => data[ApiResponseNames.alleged],
    id: "alleged",
    width: 90,
  },
  {
    Header: "Trade Through Exempt",
    accessor: row => {
      return row[ApiResponseNames.tradeThrough];
    },
    id: "tradeThrough",
    width: 150,
  },
  {
    Header: "Break State",
    accessor: data => {
      switch (data[ApiResponseNames.breakState]) {
        case "0":
          return "";
        case "1":
          return "Part MM";
        case "2":
          return "Part OE";
        case "3":
          return "Done MM";
        case "4":
          return "Done OE";
        default:
          break;
      }
    },
    id: "breakState",
    width: 90,
  },
  {
    Header: "Reject Count",
    accessor: row =>
      row[ApiResponseNames.rejectCount] > 0 ? row[ApiResponseNames.rejectCount] : "",
    id: "rejectCount",
    width: 90,
  },
  {
    Header: "Source",
    accessor: data => data[ApiResponseNames.source]?.label,
    id: "source",
    width: 90,
  },
  //{ Header: "Report Date", accessor: ApiResponseNames.reportDate },
  {
    Header: "Original Control #",
    accessor: data => data[ApiResponseNames.originalControlNum],
    id: "originalControlNum",
    width: 130,
  },
  {
    Header: "Original Control Date",
    accessor: data =>
      data[ApiResponseNames.originalControlDate] &&
      moment(data[ApiResponseNames.originalControlDate]).format("MM/DD/YYYY"),
    sortType: sortDateColumn(ApiResponseNames.originalControlDate),
    id: "originalControlDate",
    width: 130,
  },
  {
    Header: "Reference Reporting Facility",
    accessor: data => data[ApiResponseNames.referenceReportingVenue]?.label,
    id: "referenceReportingVenue",
    width: 170,
  },
  {
    Header: "Reject Reason",
    accessor: data => data[ApiResponseNames.rejectText],
    Cell: ({ row }) => {
      return (
        <TooltipTableCell id={`tooltip_message_${row.original.id}`}>
          {row.original[ApiResponseNames.rejectText]}
        </TooltipTableCell>
      );
    },
    id: "rejectText",
    width: 200,
  },
];

export default () => columns;
export const lateTradeCols = () => {
  const cols = [...columns];
  cols.push(
    { Header: "Late Trades", accessor: "lateTrades" },
    { Header: "Dissemination", accessor: "dissemination" },
    { Header: "Trade Accuracy", accessor: "tradeAccuracy" }
  );
  return cols;
};
