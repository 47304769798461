import {
  PEG_DIFFERENCE_SIGN,
  RashOrder,
  RASH_CAPACITY_OPTIONS,
  RASH_EXCHANGE_OPTIONS,
  RASH_ORDER,
  RASH_PEG_TYPE_OPTIONS,
  RASH_SIDE_OPTIONS,
} from "./constants";

const getPegDifference = (difference: number, sign: PEG_DIFFERENCE_SIGN) => {
  return difference * (sign === PEG_DIFFERENCE_SIGN.MINUS ? -1 : 1);
};

export const OrderEntryColumns = [
  {
    id: RASH_ORDER.orderId,
    accessor: (row: RashOrder) => {
      return row[RASH_ORDER.orderId];
    },
    Header: "Order ID",
  },
  {
    id: RASH_ORDER.exchange,
    accessor: (row: RashOrder) => RASH_EXCHANGE_OPTIONS[row[RASH_ORDER.exchange]]?.label,
    Header: "Exchange",
  },
  {
    id: RASH_ORDER.route,
    accessor: (row: RashOrder) => row[RASH_ORDER.route],
    Header: "Route",
  },
  {
    id: RASH_ORDER.symbol,
    accessor: (row: RashOrder) => row[RASH_ORDER.symbol],
    Header: "Symbol",
  },
  {
    id: RASH_ORDER.price,
    accessor: (row: RashOrder) => row[RASH_ORDER.price],
    Header: "Price",
  },
  {
    id: RASH_ORDER.side,
    accessor: (row: RashOrder) => RASH_SIDE_OPTIONS[row[RASH_ORDER.side]]?.label,
    Header: "Side",
  },
  {
    id: RASH_ORDER.orderQuantity,
    accessor: (row: RashOrder) => row[RASH_ORDER.orderQuantity],
    Header: "Order Quantity",
  },
  {
    id: RASH_ORDER.canceledQuantity,
    accessor: (row: RashOrder) => row[RASH_ORDER.canceledQuantity],
    Header: "Canceled Quantity",
  },
  {
    id: RASH_ORDER.capacity,
    accessor: (row: RashOrder) => RASH_CAPACITY_OPTIONS[row[RASH_ORDER.capacity]]?.label,
    Header: "Capacity",
  },
  {
    id: RASH_ORDER.pegType,
    accessor: (row: RashOrder) => RASH_PEG_TYPE_OPTIONS[row[RASH_ORDER.pegType]]?.label,
    Header: "Peg Type",
  },
  {
    id: RASH_ORDER.pegDifference,
    accessor: (row: RashOrder) =>
      getPegDifference(row[RASH_ORDER.pegDifference], row[RASH_ORDER.pegDifferenceSign]),
    Header: "Peg Difference",
  },
  {
    id: RASH_ORDER.discretionPegType,
    accessor: (row: RashOrder) => RASH_PEG_TYPE_OPTIONS[row[RASH_ORDER.discretionPegType]]?.label,
    Header: "Disc. Peg Type",
  },
  {
    id: RASH_ORDER.discretionPegDifference,
    accessor: (row: RashOrder) =>
      getPegDifference(
        row[RASH_ORDER.discretionPegDifference],
        row[RASH_ORDER.discretionPegDifferenceSign]
      ),
    Header: "Disc. Peg Difference",
  },
  {
    id: RASH_ORDER.discretionPrice,
    accessor: (row: RashOrder) => row[RASH_ORDER.discretionPrice],
    Header: "Disc. Price",
  },
  {
    id: RASH_ORDER.subId,
    accessor: (row: RashOrder) => row[RASH_ORDER.subId],
    Header: "Sender Sub ID",
  },
];
